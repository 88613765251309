import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {pageAccessLog} from "../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const PageTracker = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(async () => {
        const pal = await pageAccessLog({pageName: location.pathname}, () => {
            console.log(location.pathname);
        });
        await dispatch(pal);
    }, [location.pathname]);

    return (props.children);
}

export default PageTracker;
