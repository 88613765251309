import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonStitchesFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonStitches">Care of Stitches (Sutures)</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonStitches/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonStitches/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Is it ok to wash a wound or stitches?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Always know the doctor’s directions for wound care. The doctor will tell you if the person should shower or if it is ok to cleanse a wound. After cleansing a wound keep it clean and dry. (Check with the doctor to see if a special brand of soap / cleanser is to be used).</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Can I put an antibiotic ointment or cream on the sutures?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>No, not unless the doctor orders it. Some doctors recommend use of a Vaseline type ointment to keep a suture line moist. Know what the doctor has ordered for the person you care for.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Is there anything a person should not do if they have stitches?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The person should not do any heavy lifting, sudden turning or twisting, or bearing down (such as during a bowel movement). These movements can cause a wound to reopen. As the wound begins to heal it may cause itching. Do not scratch the wound as it may damage the stitches or the wound.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/familydoctor.com_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American Academy of Family Physicians</h2>
                                                        <p>Website provides health information for patients and families, including wound care.</p>
                                                        <p className="resourceLink">visit: <a href="https://familydoctor.org/" target="_blank">http://familydoctor.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/nih_usnlm_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">United States National Library of Medicine</h2>
                                                        <p>The Library collects materials and provides information and research services in all areas of biomedicine and health care. Includes resources on wound care.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nlm.nih.gov/about/index.html" target="_blank">http://www.nlm.nih.gov/about/index.html</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/EPA_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Environmental Protection Agency</h2>
                                                        <p>Provides information on hazardous and non-hazardous waste.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.epa.gov/environmental-topics/land-waste-and-cleanup-topics" target="_blank">www.epa.gov/osw</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer greenBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonStitchesFAQResources;
