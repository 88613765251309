import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Accordion from "react-bootstrap/Accordion";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

const expand = "expand";
const collapse = "collapse";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className="accordionExpand"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const LivingWellCenter_9 = () => {
  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/55d6001ebd538e95275545fa9b4dc8e0.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#9546B5;",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Frequently Asked Questions
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  <Accordion
                    defaultActiveKey={["0", "1", "2", "3", "4"]}
                    alwaysOpen
                    className="faqAccordion"
                  >
                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>Where are you located?</h2>{" "}
                        </div>
                        <ContextAwareToggle eventKey="0">
                          expand{" "}
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="lego l00paragraph2 legoMargin2">
                            <p className="legoMargin2">
                              The Washington University Orthopedics Living Well
                              Center is located on the Barnes-Jewish West County
                              Hospital campus:
                            </p>
                            <p>
                              <span className="paragraph500px">
                                Washington University Living Well Center®
                              </span>{" "}
                              <br />
                              <a
                                className="blueLinkfont400"
                                href="https://www.ortho.wustl.edu/content/Patient-Care/2321/Appointment-Information/Office-Locations.aspx"
                                target="_blank"
                              >
                                Barnes-Jewish West County Hospital <br />
                                Medical Office Building 4 
                                <br />
                              </a>
                              <a
                                className="blueLinkfont400"
                                href="https://www.ortho.wustl.edu/content/Patient-Care/2321/Appointment-Information/Office-Locations.aspx"
                                target="_blank"
                              >
                                1044 North Mason Road, Suite 210
                                <br />
                                St. Louis, MO 63141
                                <br />
                              </a>
                              Free onsite parking is available{" "}
                            </p>
                          </div>
                          <ContextAwareToggle eventKey="0">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>What will my program include?</h2>{" "}
                        </div>
                        <ContextAwareToggle eventKey="1">
                          expand
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          {/* L14 Paragraph */}
                          <div className="lego l00paragraph2">
                            <p>
                              Your personal care plan is designed based on your
                              detailed assessment and evaluation and will
                              empower you to use lifestyle as medicine. Your
                              care team includes medical providers, therapists
                              and a dietitian — experts in musculoskeletal
                              health and its link to general health.
                            </p>
                            <p>
                              <span className="paragraph22px legoMargin3">
                                Your coordinated care program may include:
                              </span>{" "}
                            </p>

                            {/* L53 Bullet List */}
                            <div className="lego l053 legoMargin2">
                              <ul className="w-100">
                                <li className="w-100 legoMargin1">
                                  Behavioral health counseling
                                </li>
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8493/Services/Living-Well-Center/WashU-Living-Well-Center-Movement-and-Exercise-Program.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Exercise classes
                                  </a>
                                </li>
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8445/Services/Living-Well-Center/Living-Well-Center-Long-Covid-Program.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Long COVID Program
                                  </a>
                                </li>
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8358/Services/Living-Well-Center/Nutritional-Counseling.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Nutritional counseling
                                  </a>
                                </li>
                                <li className="w-100 legoMargin1">
                                  Physical therapy
                                </li>
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8431/Services/Living-Well-Center/Shared-Medical-Appointments.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Shared medical appointments
                                  </a>
                                </li>
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/6940/Services/Living-Well-Center/Smoking-Cessation.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Smoking cessation
                                  </a>
                                </li>
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8357/Services/Living-Well-Center/Living-Well-Center-Weight-Loss-Program.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Weight loss
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* End L53 Bullet List */}
                          </div>
                          {/* End L14 Paragraph */}

                          {/* L14 Paragraph */}
                          <div className="lego l00paragraph">
                            <p>
                              <span className="paragraph22px legoMargin2">
                                For patients with select pain conditions, their
                                care plan also might include:
                              </span>{" "}
                            </p>

                            {/* L53 Bullet List */}
                            <div className="lego l053 smallFont F">
                              <ul className="w-100">
                                <li className="w-100 legoMargin1">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8326/Services/Living-Well-Center/Acupuncture.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Acupuncture*
                                  </a>
                                </li>
                                <li className="w-100">
                                  <a
                                    className="blueLinkfont22_500"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/8446/Services/Living-Well-Center/Medical-Massage-Therapy.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    Medical massage*
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* End L53 Bullet List */}
                            <p>
                              *Medical Massage is performed at STAR Physical
                              Therapy. At this time they are unable to bill this
                              service to insurance. Some insurances might cover
                              acupuncture services; please check with your
                              insurance carrier to verify coverage.
                            </p>
                          </div>
                          {/* End L14 Paragraph */}
                          <ContextAwareToggle eventKey="1">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>What will my first visit include?</h2>{" "}
                        </div>
                        <ContextAwareToggle eventKey="1">
                          expand
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <div className="lego l00paragraph2">
                            <p>
                              Though some specifics of your first visit will, of
                              course, vary based on your specific needs, there
                              are some aspects that you can be certain of.
                            </p>
                            <p>
                              <span
                                className="paragraph22px legoMargin2"
                                style={{ fontWeight: "400px" }}
                              >
                                You will be heard.
                              </span>{" "}
                            </p>
                            <p className="legoMargin2">
                              Each patient who comes to the Living Well Center
                              will be evaluated in a holistic way.  That means
                              we assess the nature of any musculoskeletal
                              concerns, but we also look at how your diet,
                              lifestyle, sleep patterns, and more could be
                              affecting an injury or condition.
                            </p>
                            <p className="legoMargin2">
                              We’ll talk about what motivates you, what brought
                              you here, what your frustrations may be, what's
                              worked for you in the past and what hasn't.
                            </p>
                            <p className="legoMargin2">
                              And from there, from that common understanding of
                              your needs from your perspective, we develop your
                              plan together that will treat your symptoms and
                              the root cause of any issues, with an eye toward
                              your long-term success.
                            </p>
                          </div>
                          <ContextAwareToggle eventKey="1">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>What insurance plans do you accept?</h2>{" "}
                        </div>
                        <ContextAwareToggle eventKey="1">
                          expand
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <div className="lego l00paragraph2">
                            <p>
                              Washington University Orthopedics participates
                              with most medical insurance plans. However,
                              private or company insurance plans and plan
                              networks change frequently. Please verify coverage
                              before your appointment.
                            </p>
                            <p>
                              For an updated listing of accepted health
                              insurance plans and networks, please visit the{" "}
                              <a
                                className="blueLinkfont22_500"
                                href="https://physicians.wustl.edu/for-patients/health-insurance/"
                                target="_blank"
                              >
                                Washington University Physicians website
                              </a>
                            </p>
                          </div>
                          <ContextAwareToggle eventKey="1">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>

                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>
                            How can I request medical information like my
                            medical records?
                          </h2>{" "}
                        </div>
                        <ContextAwareToggle eventKey="2">
                          expand
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <div className="lego l00paragraph2">
                            <p>
                              You can request your medical records{" "}
                              <a
                                className="blueLinkfont22_500"
                                href="https://www.ortho.wustl.edu/content/Patient-Care/3504/APPOINTMENT-INFORMATION/Request-Medical-Records.aspx"
                                target="_blank"
                              >
                                here
                              </a>
                            </p>
                            <p>
                              If you need hospital records from Barnes-Jewish
                              Hospital or St. Louis Children’s Hospital, you
                              will need to request them directly from the
                              hospital.
                            </p>
                            <p className="legoMargin3">
                              <span className="paragraph600px">
                                Barnes-Jewish Hospital
                                <br /> Health Information Management Department
                              </span>
                              <br />
                              <a href="tel:3144545934" className="phoneLink">
                                314-454-5934
                              </a>{" "}
                              (Option 1)
                            </p>
                            <p className="legoMargin3">
                              <span className="paragraph600px">
                                St. Louis Children’s Hospital Medical Records
                              </span>
                              <br />
                              <a href="tel:3144546060" className="phoneLink">
                                314-454-6060
                              </a>
                            </p>
                            <p>
                              If you have additional questions on this, you can
                              contact:
                              <br />
                              <span className="paragraph500px">
                                Phone:
                              </span>{" "}
                              <a href="tel:3142730453" className="phoneLink">
                                314-273-0453
                              </a>
                              <br />
                              <span className="paragraph500px">
                                Email:
                              </span>{" "}
                              <a
                                className="blueLinkfont22_500"
                                href="mailto:hirs@wustl.edu"
                              >
                                hirs@wustl.edu
                              </a>
                            </p>
                          </div>
                          <ContextAwareToggle eventKey="2">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>What are your billing policies?</h2>
                        </div>
                        <ContextAwareToggle eventKey="3">
                          expand
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <div className="lego l00paragraph2">
                            <p>
                              Our mission is to meet the needs of those we
                              serve. We have established a policy of
                              communicating financial expectations to our
                              patients. You can review our billing and payment
                              policies on payment options for self-pay patients,
                              patients with insurance, and additional services
                              such as radiology and durable medical equipment{" "}
                              <a
                                className="blueLinkfont22_500"
                                href="https://www.ortho.wustl.edu/content/Patient-Care/8167/Appointment-Information/Billing-and-Payment-Information.aspx"
                                target="_blank"
                              >
                                here
                              </a>
                            </p>
                          </div>
                          <ContextAwareToggle eventKey="3">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="legoMargin5">
                      <Card.Header>
                        <div className="lego l00paragraph2">
                          <h2>What are your COVID-19 policies?</h2>
                        </div>
                        <ContextAwareToggle eventKey="4">
                          expand
                        </ContextAwareToggle>
                      </Card.Header>

                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <div className="lego l00paragraph2">
                            <p>
                              You can access our current COVID-19 policies{" "}
                              <a
                                className="blueLinkfont22_500"
                                href="https://www.ortho.wustl.edu/content/About-Us/8294/Keeping-You-Safe.aspx"
                                target="_blank"
                              >
                                here
                              </a>
                            </p>
                          </div>
                          <ContextAwareToggle eventKey="4">
                            collapse
                          </ContextAwareToggle>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_9;
