import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LessonNebulizersWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className=""
                                                                     href="/LessonNebulizers">Nebulizers</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonNebulizers/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonNebulizers/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonNebulizers/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonNebulizers/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Preventing a Yeast Infection of the
                                                    Mouth</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If the person you provide care for takes steroid medicines, you can
                                                    help to prevent a yeast infection of the mouth by having the person
                                                    gargle with warm water after each nebulizer treatment.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Check the Heart Rate</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When the person you care for is using a bronchodilator, such as any
                                                    Beta agonist, he or she is at risk of having a rapid heart rate.
                                                    Learn how to take the person’s pulse before and after taking the
                                                    nebulized medicine.</p>
                                                <p>Knowing the person’s normal pulse rate helps you judge if the
                                                    medicine is causing an increase in their rate.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Clean the Nebulizer</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Moisture left in a nebulizer will cause the growth of germs and mold.
                                                    It is important to regularly cleanse the equipment to protect the
                                                    person you care for from infection.</p>
                                                <p className="boldLeadIn">Follow these steps for cleansing a
                                                    nebulizer:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>Rinse the nebulizer parts with clear water and air-dry after
                                                        each use.
                                                    </li>
                                                    <li>Wash the parts of the nebulizer at the end of each day in warm,
                                                        soapy water. Rinse well and allow to air dry before storing.
                                                    </li>
                                                    <li>Store in a plastic Zip-Loc bag.</li>
                                                    <li>Twice a week soak the nebulizer parts in a solution of vinegar
                                                        and water (one part white vinegar to four parts water) for 30
                                                        minutes. Then rinse thoroughly with clean water, and air-dry.
                                                    </li>
                                                    <li>Follow manufacturer’s recommendations for maintenance of
                                                        small-volume nebulizer machine, including changing the filters
                                                        when they become discolored (grayish).
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>Always give any prescribed medicine on time.</li>
                                                    <li>Never store a dose of medicine in the nebulizer cup for later
                                                        use.
                                                    </li>
                                                    <li>Always rinse out the nebulizer cup after each use. Then wash
                                                        with soapy water, rinse and dry at the end of each day.
                                                    </li>
                                                    <li>Discard the vinegar solution after cleansing the pieces of
                                                        equipment. Always start with a clean, unused solution.
                                                    </li>
                                                    <li>If you plan to reuse the eye dropper for drawing up the
                                                        medicine, clean it after each use with warm water and allow it
                                                        to dry before using it again.
                                                    </li>
                                                    <li>Never store nebulizer parts until they are totally dried. Wet
                                                        equipment may become the site for germs and mold to grow.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Learn how to use nebulizers safely and correctly.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="videoFlexContainer">
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="-2XSFbb_6I4" opts={opts}/>
                                                    </div>
                                                    <p className="captionRegular videoCaptionBox w-100 text-center">Using
                                                        a Nebulizer 8:19
                                                    </p>
                                                </div>
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="Pmb_CYoe1y4" opts={opts}/>
                                                    </div>
                                                    <p className="captionRegular videoCaptionBox w-100 text-center">Cleaning
                                                        the Nebulizer 02:46
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-10  col-12 imgCol noPaddingLeft">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="" target="_blank">Open File<i
                                                    className="fa fa-chevron-right">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Medicine Administration</h2>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100 text-center">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NEB_4.png"
                                                         className="img-responsive floatNone"/>
                                                    <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>Medicine</li>
                                                    <li>Diluent (liquid) for dissolving the medicine (if needed)</li>
                                                    <li>Air compressor</li>
                                                    <li>Nebulizer cup and tubing assembly</li>
                                                    <li>Mask or mouthpiece</li>
                                                    <li>Clean eye droppers or other measuring device to dispense the
                                                        medicine (optional)
                                                    </li>
                                                    <li>Glass with warm water (option if taking steroids)</li>
                                                    <li>Pair of disposable gloves</li>
                                                    <li>Plastic grocery bag</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Cleaning and Disinfecting</h2>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100 text-center">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NEB_5.png"
                                                         className="img-responsive floatNone"/>
                                                    <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>White Vinegar</li>
                                                    <li>Water</li>
                                                    <li>Tap water</li>
                                                    <li>Paper Towels</li>
                                                    <li>Zip lock plastic bag</li>
                                                    <li>Plastic basin or bowl</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer orangeBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonNebulizersWhatToDoAndSafety;
