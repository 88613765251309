import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const divStyle = {
    paddingRight: "1rem"
};
const SitemanENTTL1_5 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideoLivingYourLife = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Living Your Life After Total Laryngectomy'}, () => {
            console.log('Advice from Patients & Families: Living Your Life After Total Laryngectomy');
        });
        await dispatch(pal);
    }

    const playVideoPreparingYourself = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Preparing Yourself'}, () => {
            console.log('Advice from Patients & Families: Preparing Yourself');
        });
        await dispatch(pal);
    }

    const playVideoThePowerOfSupport = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: The Power of Support and How Families Can Help'}, () => {
            console.log('Advice from Patients & Families: The Power of Support and How Families Can Help');
        });
        await dispatch(pal);
    }

    const playVideoTakingAction = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Taking Action'}, () => {
            console.log('Advice from Patients & Families: Taking Action');
        });
        await dispatch(pal);
    }

    const playVideoOvercomingFears = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Overcoming Fears'}, () => {
            console.log('Advice from Patients & Families: Overcoming Fears');
        });
        await dispatch(pal);
    }

    const playVideoFeeding = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Feeding'}, () => {
            console.log('Advice from Patients & Families: Feeding');
        });
        await dispatch(pal);
    }

    const playVideoNutrition = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Nutrition'}, () => {
            console.log('Advice from Patients & Families: Nutrition');
        });
        await dispatch(pal);
    }

    const playVideoWorkingWithTherapists = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Working with Therapists'}, () => {
            console.log('Advice from Patients & Families: Working with Therapists');
        });
        await dispatch(pal);
    }

    const playVideoCaregivers = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Caring for the Caregivers'}, () => {
            console.log('Advice from Patients & Families: Caring for the Caregivers');
        });
        await dispatch(pal);
    }

    const playVideoSurvivorGroups = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Survivor Groups'}, () => {
            console.log('Advice from Patients & Families: Survivor Groups');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center orangeColor orangeColorBorder">Advice
                                                from Patients & Families</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 d-flex flex-row">
                                            <div className="col-6" style={divStyle}>
                                                <p className="bold">You are not alone.</p>
                                                <p>Today, there are hundreds of thousands of people around the world who
                                                    have undergone a total laryngectomy.</p>
                                                <p>In this region, there have been many patients and families who have
                                                    found themselves on journeys similar to the one you face. As people
                                                    who have experienced surgery, chemotherapy, radiation, and recovery,
                                                    they can be incredible sources of relevant advice, useful everyday
                                                    guidance and compassionate understanding.</p>
                                                <p>In fact, past patients and family members are actually part of the
                                                    team that is there for you throughout your journey here. Be sure to
                                                    speak with your care team to know how you can best reach out to
                                                    others who may have shared some parts of your experience.</p>
                                            </div>
                                            <div className="col-6">
                                                <div className="w-100">
                                                    <div className="sidbarFill entBrightOrangeBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Nu Voice
                                                            Club of Greater St. Louis</h2>
                                                        <p>The Nu Voice Club of Greater St. Louis hosts social and
                                                            educational
                                                            events throughout the year (and even help in the early days
                                                            with new patients to give an insider’s perspective on what
                                                            you may face, and how they have succeeded).</p>
                                                        <p>You can find out more at <a className="whiteLink"
                                                                                       href="https://nuvoicestl.com"
                                                                                       target="_blank">nuvoicestl.com</a> and
                                                            be sure to get on their mailing list for upcoming events by
                                                            emailing them at <a className="whiteLink"
                                                                                href="mailto:nuvoicestl@gmail.com">nuvoicestl@gmail.com</a>.
                                                        </p>
                                                        <img className="img-responsive"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nu_voice_club_stl.jpg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 videoPage">
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="iaEm5VCI1iM" opts={opts} onPlay={playVideoLivingYourLife}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Living Your Life After Total Laryngectomy</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="K1B3PslFT1g" opts={opts} onPlay={playVideoPreparingYourself}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Preparing Yourself</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="8VZutRnnMLk" opts={opts} onPlay={playVideoThePowerOfSupport}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">The Power of Support and How Families Can Help</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4Oh6LlmVBDA" opts={opts} onPlay={playVideoTakingAction}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Taking Action</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="yNRfLR6o4sQ" opts={opts} onPlay={playVideoOvercomingFears}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Overcoming Fears</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="WhCgjmMFPFo" opts={opts} onPlay={playVideoFeeding}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Feeding</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="rWoDuNFFYtY" opts={opts} onPlay={playVideoNutrition}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Nutrition</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="PVEM_dAd1hQ" opts={opts} onPlay={playVideoWorkingWithTherapists}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Working With Therapists</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4t08QXZpQmg" opts={opts} onPlay={playVideoCaregivers}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for the Caregiver</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="I8WFvUGJq_A" opts={opts} onPlay={playVideoSurvivorGroups}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Survivor Groups</span>
                                            </h3>
                                        </div>
                                    </div>
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100">*/}
                                    {/*            What I would tell a newly-diagnosed person.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*        /!*     <div className="embedContainer">*!/*/}

                                    {/*        /!*</div>*!/*/}
                                    {/*            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100">*/}
                                    {/*            What helped us the most.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*        /!*     <div className="embedContainer">*!/*/}

                                    {/*        /!*</div>*!/*/}
                                    {/*            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100">*/}
                                    {/*            Our challenges and how we dealt with them.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*        /!*     <div className="embedContainer">*!/*/}

                                    {/*        /!*</div>*!/*/}
                                    {/*            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100">*/}
                                    {/*            Managing expectations.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*        /!*     <div className="embedContainer">*!/*/}

                                    {/*        /!*</div>*!/*/}
                                    {/*            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i
                                                    className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL1_5;
