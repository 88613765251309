export const validateUserName = (userName) => {
    if (!userName || !(userName.length >= 6 && userName.length <= 255))
        return 'UserName field should be between 6 and 255 characters long.';

    return '';
}

export const validateEmailAddress = (emailAddress) => {
    if (!emailAddress || !(emailAddress.length >= 6 && emailAddress.length <= 255))
        return 'UserName/Email Address field should be between 6 and 255 characters long.';

    return '';
}

export const validatePassword = (password) => {
    if (!password || !(password.length >= 6 && password.length <= 255))
        return 'Password field should be between 6 and 255 characters long.';

    return '';
}
