import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonNebulizersFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonNebulizers">Nebulizers</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonNebulizers/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonNebulizers/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What should I do if the person starts coughing constantly?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Coughing is not uncommon when giving a nebulized medicine. The medicine can easily irritate the back of the throat. Be sure the person is holding the nebulizer correctly in the mouth. Also, you can stop the nebulizer for a few minutes until the person stops coughing, then restart the treatment. If the coughing continues with each treatment, tell the person’s doctor.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What does a yeast infection of the mouth look like?</h2>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100 text-center">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NEB_3.png" className="img-responsive floatNone" />
                                                    <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When a person inhales steroids through a nebulizer, there is the risk of a yeast infection of the mouth. It appears as a white patchy coating of the tongue with some swelling of the tongue. It looks a bit like cottage cheese.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo-mayoclinic.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Mayo Clinic</h2>
                                                        <p>PThe website is owned by the Mayo Foundation for Medical Education and Research. The Mayo Clinic’s mission is to empower people to manage their health. Mayo Clinic health professionals provide valuable information for using inhalers and a spacer.</p>
                                                        <p>visit: <a href="http://www.mayoclinic.com/health-information" target="_blank">www.mayoclinic.com/health-information</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Ohio State University Medical Center, Patient Education</h2>
                                                        <p>This web site offers a library of information on a variety of health topics.</p>
                                                        <p>visit: <a href="http://medicalcenter.osu.edu/patientcare/patient_education" target="_blank">medicalcenter.osu.edu/patientcare/patient_education</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/USP_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">United States Pharmacopoeia (USP)</h2>
                                                        <p>An official public standards–setting authority for all prescription and over–the–counter medicines and other health care products manufactured or sold in the United States.</p>
                                                        <p>visit: <a href="http://www.usp.orgs" target="_blank">www.usp.orgs</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Drugs.com_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Drugs.com</h2>
                                                        <p>The largest, most widely visited, independent medicine information website available on the Internet. It provides accurate and independent information on more than 24,000 prescription drugs, over-the-counter medicines & natural products for education purposes.</p>
                                                        <p>visit: <a href="http://www.drugs.com" target="_blank">www.drugs.com</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ISMP_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Institute for Safe Medication Practices (ISMP)</h2>
                                                        <p>The nation’s only nonprofit organization devoted entirely to medication error prevention and safe medication use. The organization educates consumers and medical professionals about safe medicine practices.</p>
                                                        <p>visit: <a href="http://www.ismp.org" target="_blank">www.ismp.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Medline Plus</h2>
                                                        <p>A service of the national library of medicine and the National Institutes of Health. Provides information on a variety of health topics.</p>
                                                        <p>visit: <a href="http://www.nlm.nih.gov/medlineplus/" target="_blank">www.nlm.nih.gov/medlineplus/</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer orangeBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonNebulizersFAQResources;
