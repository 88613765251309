import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic2 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Understanding Your
                                                Diagnosis</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps centered">YOUR DIAGNOSIS: CHOLANGIOCARCINOMA</h2>
                                            <p>Cholangiocarcinoma is a cancer of the bile ducts that drain bile from
                                                the liver.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Risk Factors</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Cholangiocarcinoma is more common in Asians</li>
                                                <li>Age 60 – 70</li>
                                                <li>Autoimmune disease primary sclerosing cholangitis</li>
                                                <li>Choledochal cysts of the bile duct</li>
                                                <li>In Asia, certain infections (liver flukes) are associated with
                                                    an increased risk of cholangiocarcinoma
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Signs/Symptoms:
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Jaundice (yellowing of the skin and eyes)</li>
                                                <li>Inflammation of the pancreas (pancreatitis)</li>
                                                <li>Diarrhea due to fat malabsorption</li>
                                                <li>Weight loss</li>
                                                <li>Abdominal pain</li>
                                                <li>Nausea</li>
                                                <li>Fullness</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                TO TOP
                                                <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps centered">THE PANCREAS</h2>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h2 className="subheadUpperandLower">Introductory Video</h2>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="blueColor videoTitle width100">*/}
                                    {/*            THE ROLE AND ANATOMY OF THE PANCREAS*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            <div className="embedContainer originalPadding">*/}
                                    {/*                <YouTube videoId="NZ4zcrTzUjA" opts={opts}  />*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Anatomy</p>
                                            <p>The pancreas is an organ located in the upper part of the abdomen. It
                                                sits behind the stomach and lies up against your back. It is
                                                surrounded on either side by (1) the first part of the small
                                                intestine (the duodenum, the part of the intestines after the
                                                stomach), and (2) the spleen.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer  flexContainer justifyCenter">
                                                <img className="maxWidth700 paddingBottomSpacer" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Procedure_caption1.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps centered paddingBottomSpacer26px">THE PANCREAS HAS FOUR REGIONS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 organInfoGraphic paddingBottomSpacer26px">
                                        <div className="w-100 contentRow justifyContentStart">
                                            <div className="itemText">
                                                <p className="bold">1 Head:</p>
                                                <p>where the pancreas connects to the bile duct and small
                                                    intestine</p>
                                            </div>
                                            <div className="itemText m-auto">
                                                <p className="bold">2 Body:</p>
                                                <p>the middle portion of the pancreas</p>
                                            </div>
                                        </div>
                                        <div className="w-100 contentRow imgContainerOrganInfo">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pancreas_diagram.svg" className="img-responsive width100" alt="" />
                                        </div>
                                        <div className="w-100 contentRow justifyContentEnd">
                                            <div className="itemText inInfoGraphic">
                                                <p className="bold">3 Tail:</p>
                                                <p>the end located closest to the spleen</p>
                                            </div>
                                        </div>
                                        <div className="w-100 contentRow justifyContentCenter">
                                            <div className="itemText">
                                                <p className="bold">4 Uncinate Process:</p>
                                                <p>a portion of the pancreas typically underneath the head of the
                                                    pancreas</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps centered paddingBottomSpacer26px">THE PANCREAS HAS TWO MAIN FUNCTIONS:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxPriority cyanTableBackgroundColor pancreaticContentBox">
                                                <h1 className="">Exocrine</h1>
                                                <p><strong><em>(releasing products directly where they will be used in your body)</em></strong></p>
                                                <p><strong>The pancreas releases digestive enzymes
                                                    through the pancreatic duct into the small intestine. These
                                                    enzymes are made in cells called acinar cells.</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxPriority cyanTableBackgroundColor pancreaticContentBox">
                                                <h1 className="">Endocrine</h1>
                                                <p><strong><em>(releasing products into your
                                                    bloodstream for use farther away)</em></strong></p>
                                                <p><strong>The pancreas releases many factors
                                                    involved in the digestion and utilization of food. Foremost
                                                    among these are insulin and glucagon, two factors that regulate
                                                    blood sugar.</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">The pancreas can be damaged for any
                                                number of reasons.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Chronic inflammation can affect all functions of the pancreas. If a
                                                tumor blocks the pancreatic duct, the tumor may therefore block the
                                                secretions of pancreatic enzymes. This could result in pancreas
                                                inflammation (pancreatitis) and digestive issues.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">The pancreas is a complex organ
                                                with many different types of cells in it.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Each of these cell types may give rise to different types of tumors.
                                                Often the type of tumor that is present in the pancreas can be
                                                diagnosed from specialized studies such as radioisotope studies, CT
                                                scans and/or an Endoscopic Ultrasound (EUS) with biopsy.</p>
                                            <p>Approximately 85% of patients have a tumor arising from the cells
                                                that line the duct of the pancreas; this tumor is called
                                                adenocarcinoma. In about 15% of patients, other tumors in the
                                                pancreas, called pancreatic neuroendocrine (PNETs) are found.</p>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP
                                                    <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                                <hr className="width100" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadAllCaps centered">TREATMENTS:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Surgery</p>
                                                <p>Pancreatic cancer can be treated with several approaches. Early
                                                    stage pancreatic cancer can often be treated and even cured with
                                                    surgery.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Types of Surgery:</h2>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ol className="bulletsCustom">
                                                        <li><strong><em>Whipple Procedure:</em></strong> Pancreatic
                                                            Head/Neck Tumors
                                                        </li>
                                                        <li><strong><em>Distal Pancreatectomy and Splenectomy and
                                                            RAMPS procedure:</em></strong><br />
                                                            Pancreatic Body/Tail tumors</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The complexity of this area plays a major factor in what types of
                                                    surgery can be performed on the pancreas. In addition to the
                                                    pancreas, other areas potentially affected by tumors
                                                    include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>The Bile Duct and Gallbladder.<br />
                                                        Because the bile duct connects to the intestine by going
                                                        through the head of the pancreas, a portion of the bile duct
                                                        is often removed during surgery that involves the head of
                                                        the pancreas. The gallbladder is removed as well.</li>
                                                    <li>The Spleen.<br />
                                                        Because the tail of the pancreas is so close to the spleen,
                                                        it often shares small blood vessels with the spleen. Surgery
                                                        involving removal of the tail of the pancreas therefore
                                                        often requires removal of the spleen.</li>
                                                    <li>The Duodenum.<br />
                                                        Because the blood supply to the duodenum and pancreas is
                                                        shared, it is common that a portion of the duodenum be
                                                        removed along with the pancreas, even if the tumor is not
                                                        located in the duodenum.</li>
                                                    <li>Arteries and Veins.<br />
                                                        Because the pancreas is so close to important arteries and
                                                        veins, these may sometimes be involved by a tumor and may
                                                        have to be partially resected and reconstructed during
                                                        surgery. However, if these arteries and veins are surrounded
                                                        by too much tumor, the tumor may not be able to be
                                                        completely removed.</li>
                                                    <li>Lymph Nodes.<br />
                                                        Lymph nodes are common sites of spread of tumor cells. To
                                                        determine if a tumor has spread and to give the cancer a
                                                        “stage”, many lymph nodes are removed during surgery and are
                                                        later examined for the presence or absence of tumor cells.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Adjuvant Therapy: Chemotherapy and
                                                    Radiation</p>
                                                <p>After surgery, further treatment, called adjuvant therapy, is
                                                    often recommended. This might include chemotherapy or radiation
                                                    therapy.</p>
                                                <p><span className="bold"><em>Neoadjuvant therapy</em></span> refers to
                                                    chemotherapy given before surgery, usually in an attempt to
                                                    shrink the tumor away from important blood vessels (in the hope
                                                    of making surgery easier) or prevent further spread of cancer.
                                                </p>
                                                <p><span className="bold"><em>Adjuvant (additional) therapy</em></span> refers to
                                                    chemotherapy, radiation, or a combination of both that is
                                                    recommended for people who are thought to be at high risk of
                                                    having cancer reappear after a tumor has been removed
                                                    surgically.</p>
                                                <p>If surgery is not possible, radiation therapy, chemotherapy, or a
                                                    combination of both (called chemoradiotherapy) is often used to
                                                    shrink the cancer, reduce symptoms, and prolong life.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <hr className="width100" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Article: <a href="http://pancan.org/" target="_blank">Pancreatic
                                                    Cancer Action Network: What Is The Pancreas?</a></p>
                                                <p>PDF: <a href="http://nccn.org/patients" target="_blank">NCCN
                                                    Guideline for Patients: Pancreatic Cancer</a></p>

                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP
                                                    <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic2;
