import {getRequest, postRequest} from "../../src/api/index.js";
import {
    FORGOT_PASSWORD_ERROR,
    HOME,
    ORBIT_CLUSTER_ERROR,
    RESET_PASSWORD_ASSIGN_OTP,
    TILE_PAGE_ERROR
} from "../../src/actions/types.js";

export const tilePage = async (data, dispatch_type, cb) => async (
    dispatch
) => {
    try {
        const res = await getRequest(`/tilepage/${data.id}`, "orbit", undefined, (status) => {
            return ((status >= 200 && status < 300) || (status === 404));
        });
        dispatch({
            type: dispatch_type,
            payload: res.data
        })

        cb(res.data);
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response && err.response.status !== 404) {
                if (err.response.data && err.response.data.errors[0]) {
                    dispatch({
                        type: TILE_PAGE_ERROR,
                        payload: err.response.data.errors[0]
                    });
                } else {
                    const error = err.response.data;
                    dispatch({
                        type: TILE_PAGE_ERROR,
                        payload: err.message
                    });
                }
            } else {
                dispatch({
                    type: dispatch_type,
                    payload: []
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: TILE_PAGE_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: TILE_PAGE_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: TILE_PAGE_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};
