import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonOxygenWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center wdith400">
                                                <p>
                                                    <Nav.Link className="" href="/LessonOxygen">Giving Oxygen</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Let the doctor know if you notice any signs of hypoxia, such as:
                                                <br /> <strong>Anxiety
                                                    <br /> Inability to concentrate
                                                    <br /> Confusion
                                                    <br /> Increased fatigue
                                                    <br /> Dizziness
                                                    <br /> Rapid pulse rate and/or increased respiratory rate</strong></li>
                                            <li>Share your care diary with the doctor at every visit, especially your observations of how long and how often the person you are caring for uses his or her oxygen (intermittent oxygen therapy).</li>
                                            <li>Show the doctor the person’s pulse and breathing rates in your care diary.</li>
                                            <li>Let the doctor know if the person receiving oxygen tries to readjust or change their oxygen flow rate or does not follow instructions regarding his or her oxygen therapy.</li>
                                            <li>Call the doctor if the person’s breathing worsens, such as shortness of breath or difficulty breathing while lying down flat in bed.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10118.jpg" className="img-responsive maxWidth300 floatNone" />
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer blueBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonOxygenWhatToTellYourDoctor;