import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanPancreaticAll2_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1">
                                        <div
                                            className="tile t_013 t_013_3x2 tileWithCenterBanner Height2RowTall width3Column yourStayAtTheHosptialBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Treatments & Daily Activities</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>

                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-2">
                                        <div className="tile Height1RowTall width2Column t_009 t_009_2x1 lightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Watch for Complications</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div className="tile Height1RowTall width1Column t_001 t_001_1x1 paleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">When You're<br/>Discharged</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll2_3;