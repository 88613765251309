import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin1_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderdDarkBlue">The
                                                Benefits of Cardiac Rehab</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="imageLeftCopyRight contentCentered">
                                            <div className="imgCol">
                                                <img className="floatLeft wrapImage"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/102002.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="typeCol">
                                                <p className="floatNone clearNone boldLeadIn">Cardiac rehabilitation, or
                                                    cardiac rehab, is a comprehensive program designed to improve
                                                    cardiovascular health following a cardiac event or procedure.</p>
                                                <p className="floatNone clearNone">In fact, actively participating in
                                                    your cardiac rehab program may be just as essential to your
                                                    long-term recovery as your medical procedure or medications.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn btmMargin30">Cardiac rehabilitation is focused on
                                                helping you:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Live longer and reduce your chances of another heart attack</li>
                                                <li>Control heart disease symptoms such as chest pain or shortness of
                                                    breath
                                                </li>
                                                <li>Stop or reverse damage to your blood vessels in your heart</li>
                                                <li>Lessen the physical and emotional effects of heart disease</li>
                                                <li>Improve your stamina and strength, getting you back to your usual
                                                    activities including work, hobbies, and regular exercise
                                                </li>
                                                <li>Improve your confidence and well-being</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Studies have shown that participation in a cardiac rehab program can
                                                reduce the risk of death -- from any cause and from cardiac causes.
                                                Studies have also shown that cardiac rehab decreases hospital
                                                readmission rates.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="imageLeftCopyRight contentCentered smallImageLeft">
                                            <div className="imgCol">
                                                <img className="floatLeft wrapImage w-100"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/MH_Logo.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="typeCol">
                                                <p className="floatNone clearNone"><span className="bold">Find more information</span> on
                                                    the benefits of cardiac rehabilitation at the <a
                                                        href="https://millionhearts.hhs.gov/about-million-hearts/optimizing-care/cardiac-rehabilitation.html"
                                                        target="_blank">Million Hearts website</a>.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar pritikinBackgroundDarkBlue centered">The
                                                POWER of Pritikin</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Pritikin Intensive Cardiac Rehab (ICR) is based on the Pritikin Program,
                                                which has been taught to more than 100,000 individuals at the Pritikin
                                                Longevity Center since 1975. More than 100 peer-reviewed, published
                                                studies, performed by UCLA medical researchers, have established the
                                                Pritikin Program’s remarkable results.</p>
                                            <p className="boldLeadIn btmMargin30">Clinical studies have shown the
                                                Pritikin program delivers proven health benefits, including:</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="contentBlock4elements">
                                                <div className="contentBlock contentOne">
                                                    <div className="pill pritikinBackgroundBlue">
                                                        <p>Lower Blood Pressure</p>
                                                    </div>
                                                    <p>Among hypertensives, systolic and diastolic blood pressure each
                                                        fell on average -9% within 3 weeks of beginning the Pritikin
                                                        Program.</p>
                                                </div>
                                                <div className="contentBlock contentTwo">
                                                    <div className="pill pritikinBackgroundBlue">
                                                        <p>Lower LDL Cholesterol + Triglycerides</p>
                                                    </div>
                                                    <p>LDL (bad) cholesterol fell an average -23% in 3 weeks.
                                                        Triglycerides fell -33%.</p>
                                                </div>
                                                <div className="contentBlock contentThree">
                                                    <div className="pill pritikinBackgroundBlue">
                                                        <p>Lower Inflammation</p>
                                                    </div>
                                                    <p>Markers of chronic inflammation, notably C-reactive protein, fell
                                                        about -40% in 2 to 3 weeks.</p>
                                                </div>
                                                <div className="contentBlock contentFour">
                                                    <div className="pill pritikinBackgroundBlue">
                                                        <p>Weight -7 to -11 lbs.</p>
                                                    </div>
                                                    <p>Within 2 to 3 weeks of starting the Pritikin Program, overweight
                                                        adults lost on average 7 to 11 pounds.</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className=""></div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin1_2;