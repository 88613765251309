import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTTGSP2_1 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Your Anatomy</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower">Mouth and throat (Oral cavity and oropharynx)</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-md-12">
                                                    <div className="w-100 flexContainer justifyCenter column">
                                                        <div className="imageContainer flexContainer justifyCenter">
                                                            <img className="maxWidth500" alt="Soft palate diagram" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/40-1-soft_palate-1a.jpg" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>Your <strong>oral cavity</strong> refers to the inside of your mouth - your lips, your tongue, your gums, the roof of your mouth, and the inside lining of your cheek. Your
                                                            oropharynx or throat refers to the back of your tongue, soft palate, tonsils and surrounding tissue.  You may not think about it much, but your mouth, your throat, and your voice box work together to allow you to speak and to swallow normally. Cancer can arise from any of these areas. When someone has a cancer of the mouth or the throat, it can be difficult to speak and swallow normally.</p>
                                                        <p>On top of speaking and eating/drinking, a lot more happens inside your mouth than you might be aware of. Saliva (the watery liquid produced by glands under your tongue and in your cheeks) keeps your mouth moist so that food is easier to chew and swallow. Saliva also protects your teeth and keeps germs from overgrowing in your mouth. Depending on where your cancer is, surgery, radiation, and chemotherapy can all cause you to make less saliva, which leads to dry mouth.</p>
                                                        <p className="paddingBottomSpacer10px">The bones in your upper and lower jaw hold your teeth in place and allow you to chew. They also give shape to your cheeks, jawline, and chin. Cancers of the mouth can invade into these bones. </p>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower">The parotid gland and the facial nerve</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-md-12">
                                                    <div className="w-100 flexContainer justifyCenter column">
                                                        <div className="imageContainer flexContainer justifyCenter">
                                                            <img className="maxWidth500" alt="Parotid gland diagram" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/salivary-glands-and-facial-nervev2_3x2.jpg" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="paddingBottomSpacer10px">Your parotid glands are two large glands that sit under your skin on each side of your face, wrapped around the angle of your jaw. The parotid gland makes saliva that flows to your mouth through a duct in your cheek near your upper second molar. The facial nerve goes through the middle of your parotid gland and you have one on each side of your face. Your facial nerve controls the “muscles of facial expression” (i.e., the muscles that allow you to smile, frown, close
                                                            your eyes tight, raise your eyebrows, etc.). Some parotid gland cancers can invade into the facial nerve and cause weakness of the facial muscles, or even complete paralysis.</p>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                             <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower">The skin on your scalp and face</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-md-12">
                                                    <div className="w-100 flexContainer justifyCenter column">
                                                        <div className="imageContainer flexContainer justifyCenter">
                                                            <img className="maxWidth500" alt="Skin diagram" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/60_skin-20190410.jpg" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="paddingBottomSpacer10px">The skin on your scalp, face and neck serves a number of important functions. It acts as a barrier, keeping the rest of your body safe from infections and injury. It helps you regulate your body temperature (for example, through sweating when your body is hot). It also provides sensation (for example, sensing the heat near a hot pan or feeling a bug landing on your neck).</p>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP2_1;
