import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin4_1 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorBrightGreen pritikinBorderBrightGreen">Jump-Starting
                                                My Recovery</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="imageLeftCopyRight contentCentered">
                                            <div className="imgCol">
                                                <img className="floatLeft wrapImage"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/102024.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="typeCol">
                                                <p className="floatNone clearNone boldLeadIn">As you begin your
                                                    recovery, pause for a moment and think about all the great reasons
                                                    for wanting to live a longer, healthier life—</p>
                                                <p className="floatNone clearNone boldLeadIn">Time with family? The
                                                    freedom to travel? Reduced medical costs?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>No matter the reasons, your cardiac rehab team of experts in the fields
                                                of exercise, medicine, nutrition, and mental health are ready to help
                                                you achieve the health and wellness goals that can help make it
                                                happen.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight pritikinBackgroundBrightGreen">
                                                <h2 className="subheadUpperAndLower">You can jump-start your recovery
                                                    along this path, increasing the positive effects
                                                    Pritikin ICR can provide, with these simple commitments and
                                                    understandings.</h2>
                                                <h2 className="subheadUpperAndLower mb-0">Think of them as your “Do’s”
                                                    at the start of your journey!</h2>
                                            </div>
                                            <div className="withBorder pritikinBorderBrightGreen">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>As part of your sessions, you’ll learn many ways to make a
                                                            real difference in your life, and get the most from your
                                                            cardiac rehab. Here are a few to get you started!:</p>
                                                        <p className="boldLeadIn">Do develop your own list of daily
                                                            Pritikin motivators, focusing on “progress,”
                                                            rather than “perfection.”</p>
                                                        <p>Every step you take to improve your health, no matter how
                                                            small, is progress. Identify some of those small steps and
                                                            changes and give yourself credit for every move in the right
                                                            direction – whether it’s reaching your daily steps goals,
                                                            preparing a healthy meal, or dealing well with a stressful
                                                            situation during the day!</p>
                                                        <p className="boldLeadIn">Do identify a support system.</p>
                                                        <p>Your support system may include friends, family, co-workers,
                                                            and other patients – individuals who are there to motivate
                                                            you and encourage your positive efforts.</p>
                                                        <p className="boldLeadIn">Do take charge.</p>
                                                        <p>Always remember that you are in charge. You are the one who
                                                            can get yourself moving, who can insist on a well-stocked
                                                            pantry, who can add healthy habits with patience and
                                                            persistence! This is your life and, with the incredible help
                                                            and support all around you on this cardiac rehab journey,
                                                            you can get to where you want to be, living the life you
                                                            have envisioned for yourself</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin4_1;