import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTTL4_2_1 = () => {
const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideoLivingYourLife = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Living Your Life After a Total Laryngectomy'}, () => {
            console.log('Advice from Patients & Families On Recovery: Living Your Life After a Total Laryngectomy');
        });
        await dispatch(pal);
    }

    const playVideoOvercomingFears = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Overcoming Fears'}, () => {
            console.log('Advice from Patients & Families On Recovery: Overcoming Fears');
        });
        await dispatch(pal);
    }

    const playVideoPreparingYourself = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Preparing Yourself'}, () => {
            console.log('Advice from Patients & Families On Recovery: Preparing Yourself');
        });
        await dispatch(pal);
    }

    const playVideoThePowerOfSupport = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: The Power of Support and How Families Can Help'}, () => {
            console.log('Advice from Patients & Families On Recovery: The Power of Support and How Families Can Help');
        });
        await dispatch(pal);
    }

    const playVideoTherapists = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Working With Therapists'}, () => {
            console.log('Advice from Patients & Families On Recovery: Working With Therapists');
        });
        await dispatch(pal);
    }

    const playVideoFeeding = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Feeding'}, () => {
            console.log('Advice from Patients & Families On Recovery: Feeding');
        });
        await dispatch(pal);
    }

    const playVideoNutrition = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Nutrition'}, () => {
            console.log('Advice from Patients & Families On Recovery: Nutrition');
        });
        await dispatch(pal);
    }

    const playVideoCaregivers = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Caring for the Caregivers'}, () => {
            console.log('Advice from Patients & Families On Recovery: Caring for the Caregivers');
        });
        await dispatch(pal);
    }

    const playVideoSurvivorGroups = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families On Recovery: Survivor Groups'}, () => {
            console.log('Advice from Patients & Families On Recovery: Survivor Groups');
        });
        await dispatch(pal);
    }
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Advice from Patients & Families On Recovery</h1>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="iaEm5VCI1iM" opts={opts} onPlay={playVideoLivingYourLife} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Living Your Life After a Total Laryngectomy</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="yNRfLR6o4sQ" opts={opts} onPlay={playVideoOvercomingFears} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Overcoming Fears</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="K1B3PslFT1g" opts={opts} onPlay={playVideoPreparingYourself} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Preparing Yourself</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="8VZutRnnMLk" opts={opts} onPlay={playVideoThePowerOfSupport} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">The Power of Support and How Families Can Help</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="PVEM_dAd1hQ" opts={opts} onPlay={playVideoTherapists} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Working With Therapists</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="WhCgjmMFPFo" opts={opts}   onPlay={playVideoFeeding}/>
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Feeding</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="rWoDuNFFYtY" opts={opts} onPlay={playVideoNutrition} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Nutrition</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="4t08QXZpQmg" opts={opts} onPlay={playVideoCaregivers} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Caring for the Caregivers</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="I8WFvUGJq_A" opts={opts} onPlay={playVideoSurvivorGroups} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Survivor Groups</span></h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    {/*<hr className="w-100" />*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTL4_2_1;
