import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";

const LessonFallPreventionWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFallPrevention">Fall
                                                            Prevention</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/fall_prevention_couple.png"
                                                        className="img-responsive maxWidth300 floatNone"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Fear of Falling</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Once a person has fallen, the fear of falling again may worry them
                                                    daily.
                                                    This fear often causes a person to move about less often, which
                                                    causes
                                                    greater problems in their gait or the way they walk. Many older
                                                    adults
                                                    may not report a fall because the injury was minimal, or they may
                                                    think
                                                    their fall was due to “getting older.” Always be alert for signs
                                                    that
                                                    the person you care for is fearful of falling.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i
                                                    className="fa fa-exclamation-circle">&nbsp;</i>These signs include:
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Concern or worry during walking</li>
                                                    <li>Sweating or shaking while walking</li>
                                                    <li>Clutching persons or objects while walking</li>
                                                    <li>Reluctance to change position or walk</li>
                                                    <li>Becoming wobbly or less mobile after a fall</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder blueBorder position-relative">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Factors that Increase the Risk of
                                                    Falling:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Know if the person you care for has any of these conditions that
                                                    place
                                                    them at risk for falling:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li><span className="bold">Reduced Vision</span><br/>
                                                        When a person has reduced vision, is sensitive to glare, or has
                                                        poor
                                                        depth perception (difficulty judging distance of near and far
                                                        objects), he or she may have trouble seeing a walking pathway,
                                                        obstacles in the way or the edges of stairs.
                                                    </li>
                                                    <li><span className="bold">Becomes Dizzy when Standing</span><br/>
                                                        <span className="bold italic">Postural hypotension</span> is a
                                                        drop
                                                        in blood pressure that occurs when a person stands. When this
                                                        happens, persons can become very dizzy quickly, lose their
                                                        balance,
                                                        and fall to the ground. Postural hypotension is more common in
                                                        persons who do not drink enough fluids and become <span
                                                            className="bold italic">dehydrated</span>. This is a problem
                                                        in
                                                        older adults who do not always sense being thirsty.
                                                    </li>
                                                    <li><span className="bold">Urine and Bladder Problem</span><br/>
                                                        The frequent urge to pass urine will cause a person to wake up
                                                        at
                                                        night to go to the bathroom. This urge to pass urine can cause
                                                        people to rush to the bathroom or leak urine that they can slip
                                                        in.
                                                        If the person awakens and the bedroom or bath is dark and there
                                                        is
                                                        not a clear pathway to reach the bathroom, there is the risk of
                                                        falling.
                                                    </li>
                                                    <li><span className="bold">Gait Problem</span><br/>
                                                        A person’s gait, or how one walks, can affect their risk for
                                                        tripping, losing balance, and falling. For example, Parkinson’s
                                                        patients are at a high risk for falls because of their shuffling
                                                        gait. As the person shuffles across a room, it is easy to trip
                                                        over
                                                        uneven door thresholds or clutter in their walking path. Another
                                                        example is a person with a painful foot, who may partially limp
                                                        or
                                                        have trouble bearing weight on a foot. Other people who commonly
                                                        have gait problems are those with stroke, partial paralysis,
                                                        cerebral palsy, and osteoarthritis.
                                                    </li>
                                                    <li><span className="bold">Loss of Sensation</span><br/>
                                                        When a person loses the ability to feel in the feet and legs,
                                                        this
                                                        is called <span className="bold italic">paresthesia</span>. With
                                                        a
                                                        loss of feeling, it is easy to stumble, trip and fall. This may
                                                        occur in people with diabetes, serious vitamin B12 deficiency,
                                                        and
                                                        after taking chemotherapy for cancer.
                                                    </li>
                                                    <li><span
                                                        className="bold">Using Canes, Crutches or Walkers</span><br/>
                                                        Assist devices such as a cane, crutch or walker, help people who
                                                        are
                                                        less mobile walk more safely. The devices offer more support and
                                                        stability when rising from a chair and walking. However, if a
                                                        device
                                                        is not used correctly, a person can fall. For example, when
                                                        using a
                                                        cane a person must always stand straight, and not bend over. If
                                                        a
                                                        person uses a walker, leaning over the walker or walking too far
                                                        behind it can lead to a fall.
                                                    </li>
                                                    <li><span className="bold">Medicines</span><br/>
                                                        Some medicines have side effects that cause a person to become
                                                        dizzy, weak, confused or sick to their stomach. These effects
                                                        can
                                                        also happen if a person takes several medicines for their
                                                        condition
                                                        or they take more medicines than they actually need. People
                                                        should
                                                        check with their doctor, nurse, or pharmacist about a drug’s
                                                        potential to cause risk for falls.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Who Most Often Has an Injury from a
                                                    Fall?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li><span
                                                        className="bold">People with Osteoporosis</span><br/> Osteoporosis
                                                        is a disease of the bones that leads to low bone mass and a loss
                                                        of
                                                        bone tissue. The disease leaves bones very brittle, increasing
                                                        the
                                                        risk for broken bones (fractures), even if a person does not
                                                        fall.
                                                        Bone fractures typically affect the wrist, arms, hip, and spine.
                                                    </li>
                                                    <li><span
                                                        className="bold">People with Bleeding Disorders</span><br/> Normally,
                                                        when there is an injury to blood vessels in the skin and
                                                        tissues,
                                                        the blood clots quickly to prevent serious bleeding. Blood
                                                        contains
                                                        cells called platelets and clotting factors. However, there are
                                                        diseases, conditions and medicines that affect the number or
                                                        action
                                                        of platelets and clotting factors, thus causing people to bleed
                                                        more
                                                        easily and for a longer time.
                                                        <br/><br/>
                                                        These include:
                                                    </li>
                                                </ul>
                                                <ul className="bulletsCustom">
                                                    <li>Severe liver disease</li>
                                                    <li>Certain cancers like leukemia and lymphoma</li>
                                                    <li>Inherited conditions like hemophilia</li>
                                                    <li>Medicine side effects from chemotherapy drugs and drugs
                                                        containing
                                                        excess amounts of aspirin
                                                    </li>
                                                    <li>Medicines that act on clotting factors such as heparin, warfarin
                                                        (Coumadin) and low-molecular weight heparin (Lovenox)
                                                    </li>
                                                    <li>Radiation therapy that can reduce platelet counts temporarily
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer blueBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i
                                                        className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default LessonFallPreventionWhatYouNeedToKnow;
