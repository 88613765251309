import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic27 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader orangeColor orangeColorBorderColor">Getting Help and Support</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">You and your family are never alone.</h2>
                                            <p>Siteman Cancer Center has anticipated and planned for providing you all the services surrounding a cancer diagnosis, treatment plan and ongoing health that patients and their families might need.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Blue_Man_Cluster.png" className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Your Care Team</p>
                                            <p>A good first step for any question or concern is to contact your doctor’s office to discuss</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon orangeBackgroundColor widthAuto">
                                                <i className="fa fa-square-check"></i>Tip
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your doctor’s office can discuss with you the various options for support offered by our system and outside partners. Don’t hesitate to begin your planning with them, then reach out to any individual resources that can help you and your family best.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                            <div className="col-md-6 col-sm-12 paddingRight7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor doctorCard borderColorGray">
                                                    <div className="doctorImgAndInfo noBorder mb-0">
                                                        <div className="leftCol">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/hawkins.png" className="img-responsive img-circle mb-0" />
                                                        </div>
                                                        <div className="rightCol">
                                                            <p className="doctorName">Dr. William Hawkins</p>
                                                            <p className="doctorCredentials"><em><a href="tel:3143627046">314-362-7046</a></em></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 paddingLeft7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor doctorCard borderColorGray">
                                                    <div className="doctorImgAndInfo noBorder mb-0">
                                                        <div className="leftCol">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fields.png" className="img-responsive img-circle mb-0" />
                                                        </div>
                                                        <div className="rightCol">
                                                            <p className="doctorName">Dr. Ryan Fields</p>
                                                            <p className="doctorCredentials"><em><a href="tel:3142861694">314-286-1694</a></em></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                            <div className="col-md-6 col-sm-12 paddingRight7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray doctorCard">
                                                    <div className="doctorImgAndInfo noBorder mb-0">
                                                        <div className="leftCol">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Hammill.jpg" className="img-responsive img-circle mb-0" />
                                                        </div>
                                                        <div className="rightCol">
                                                            <p className="doctorName">Dr. Chet Hammill</p>
                                                            <p className="doctorCredentials"><em><a href="tel:3142731809">314-273-1809</a></em></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 paddingLeft7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray doctorCard">
                                                    <div className="doctorImgAndInfo noBorder mb-0">
                                                        <div className="leftCol">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Sanford.jpg" className="img-responsive img-circle mb-0" />
                                                        </div>
                                                        <div className="rightCol">
                                                            <p className="doctorName">Dr. Dominic Sanford</p>
                                                            <p className="doctorCredentials"><em><a href="tel:3142732720">314-273-2720</a></em></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                        <div className="col-md-6 col-sm-12 paddingRight7">
                                            <div className="sidebarNoFill sidebarNoFillborderColor doctorCard borderColorGray">
                                                <div className="doctorImgAndInfo noBorder mb-0">
                                                    <div className="leftCol">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Leigh.jpg" className="img-responsive img-circle mb-0" />
                                                    </div>
                                                    <div className="rightCol">
                                                        <p className="doctorName">>Dr. Natasha Leigh</p>
                                                        <p className="doctorCredentials"><em><a href="tel:3143627147">314-362-7147</a></em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 paddingLeft7">
                                            <div className="sidebarNoFill sidebarNoFillborderColor doctorCard borderColorGray">
                                                <div className="doctorImgAndInfo noBorder mb-0">
                                                    <div className="leftCol">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Chapman.jpg" className="img-responsive img-circle mb-0" />
                                                    </div>
                                                    <div className="rightCol">
                                                        <p className="doctorName">Dr. William Chapman</p>
                                                        <p className="doctorCredentials"><em><a href="tel:3143622538">314-362-2538</a></em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                        <div className="col-md-6 col-sm-12 paddingRight7">
                                            <div className="sidebarNoFill sidebarNoFillborderColor doctorCard borderColorGray">
                                                <div className="doctorImgAndInfo noBorder mb-0">
                                                    <div className="leftCol">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Doyle.jpg" className="img-responsive img-circle mb-0" />
                                                    </div>
                                                    <div className="rightCol">
                                                        <p className="doctorName">Dr. Maria Majella Doyle</p>
                                                        <p className="doctorCredentials"><em><a href="tel:3143622880">314-362-2880</a></em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 paddingLeft7">
                                            <div className="sidebarNoFill sidebarNoFillborderColor doctorCard borderColorGray">
                                                <div className="doctorImgAndInfo noBorder mb-0">
                                                    <div className="leftCol">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Khan.jpg" className="img-responsive img-circle mb-0" />
                                                    </div>
                                                    <div className="rightCol">
                                                        <p className="doctorName">Dr. Adeel Khan</p>
                                                        <p className="doctorCredentials"><em><a href="tel:3143622820">314-362-2820</a></em></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-caret-right hightLight redColor"></i>
                                                If you have a medical emergency, dial <em>911</em>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>During your hospital stay:</strong> if you have any questions or concerns about your health care, you can begin the discussion at any time by talking to your nurse or the nurse manager responsible for the unit where you are a patient.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Expert Guides For Patients and Families</p>
                                            <p>We offer a wide range of experts here to help with your preparations and ongoing successful experiences throughout the process and your recovery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon orangeBackgroundColor widthAuto">
                                                <i className="fa fa-square-check"></i>Tip
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Familiarize yourself with the support desks and offices listed below which connect you with expert guides of various types. These guides can help make the logistics of the process, along with your family’s experience during a stressful time, smoother and less confusing so you can focus on being there for each other.</p>
                                            <p>Find the guides who best fit your situation and involve them in your plan. That’s exactly what they are here for.</p>
                                            <p className="boldLeadIn">Office of Patient &amp; Family Affairs</p>
                                            <p><strong><em><a href="tel:8006003606">800-600-3606</a></em></strong> (8am – 5pm M-F)</p>
                                            <p>The Office of Patient & Family Affairs can provide a “patient liaison” to serve as a connection between you and the various resources within our system. The office can also play a role in helping address any unresolved concerns or issues involving your care.</p>
                                            <p className="boldLeadIn">Social Work Services</p>
                                            <p>To take advantage of available social services, contact your Surgeon's office for a referral.</p>
                                            <p>The Office of Patient & Family Affairs can provide a “patient liaison” to serve as a connection between you and the various resources within our system. The office can also play a role in helping address any unresolved concerns or issues involving your care.</p>
                                            <p>Social workers are an integral part of your health care team, available to help patients and families cope with the challenges and problems caused by illness and life changes.</p>
                                            <p>Social workers facilitate support groups for patients and families that provide education and support, and offer ways to cope.</p>
                                            <p>Social workers are also available to help issues including:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Help in planning your admission and discharge from the hospital</li>
                                                <li>Rehabilitation</li>
                                                <li>Medicare and other insurance questions</li>
                                                <li>Community resources related to housing, employment, legal issues and financial concerns</li>
                                                <li>Lodging and transportation</li>
                                                <li>Advance directives</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            {/*<p className="boldLeadIn">Expert Guides For Patients and Families</p>*/}
                                            {/*<p><strong><em><a href="tel:3147475587">314-747-5587</a></em></strong> or visit Barnard Health and Cancer Information Center</p>*/}
                                            {/*<p>The Siteman Cancer Center provides state-of-the-art medical care. And because we believe in treating the whole patient, we also provide counseling support to help patients and families through the cancer journey. Cancer patients and their family members can schedule an appointment to talk about their situation and discuss which services might suit their needs best. One appointment may be all that is needed to receive the necessary support.</p>*/}
                                            <p className="boldLeadIn">Expert Guides, Counseling and Emotional Support</p>
                                            <p><strong><em><a href="tel:3147475587">314-747-5587</a></em></strong> or visit Barnard Health and Cancer Information Center</p>
                                            <p>The Siteman Cancer Center provides state-of-the-art medical care. And because we believe in treating the whole patient, we also provide counseling support to help patients and families through the cancer journey. Cancer patients and their family members can schedule an appointment to talk about their situation and discuss which services might suit their needs best. One appointment may be all that is needed to receive the necessary support.</p>
                                            <p>Our psychologists:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Provide education, counseling and referrals to specific resources.</li>
                                                <li>Teach coping skills, help with problem solving and provide an outlet for talking about difficulties.</li>
                                                <li>Information about emotional concerns associated with cancer treatment.</li>
                                                <ul>
                                                    <li>Anxiety Disorder</li>
                                                    <li>Depression</li>
                                                    <li>Normal Adjustment and the Adjustment Disorders</li>
                                                    <li>Post-traumatic Stress Disorder</li>
                                                </ul>
                                                <li>Offer counseling services to address grief or concerns for couples or families</li>
                                                <li>Help connect patients with support groups, peer support and community resources.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>To fully meet patient and family needs, we collaborate with specialists in case management, financial counseling, genetic counseling and spiritual care. We also work with the Oncologic Psychiatry Service, which provides treatment for Siteman patients who need psychiatric care.</p>
                                            <p>Counseling services are also available on a limited basis at our satellite facilities in west and south St. Louis County and in St. Charles County.</p>
                                            <p className="boldLeadIn">Spiritual Care<br /><em><a href="tel:3143625200">314-362-5200</a></em></p>
                                            <p>At Barnes-Jewish Hospital, spiritual support is available to patients, families, and staff 24/7. Spiritual Care Services is an interfaith department that provides spiritual care to patients, families and staff of all faith traditions and with no specific religious affiliation. Our department’s team members are interfaith staff chaplains, chaplain residents, interns and externs. Spiritual care volunteers are also a part of the department. They offer support, prayer and access to local religious groups and sacraments. Two chapels are located on the Barnes-Jewish Hospital campus. Religious services – including Catholic masses, interdenominational prayer services, Muslim Juma prayer services and Jewish Sabbath services – are offered on a weekly basis and on special religious occasions.</p>
                                            <p>Spiritual values and experiences are not the sole possession of a few or even of the traditionally religious. Our staff respects your unique spiritual practices and beliefs. We invite you to call upon our staff whether you have a religious tradition or not. Human support and compassion know no religious boundaries.</p>
                                            <p className="boldLeadIn">Financial Support<br /><em><a href="tel:3143625574">314-362-5574</a></em></p>
                                            <p>A Siteman Cancer Center financial specialist is available to answer questions about health insurance and identify financial resources for expenses related to cancer care. Health insurance may help cover some of the costs related to the diagnosis and treatment of cancer. Whatever type of insurance you have, you want to get the most from your plan. You will receive the best services if you know the benefits, coverage and limits of your plan.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Hospital Guest Services</h2>
                                            <p>Before and during your procedure and hospital stay, there are many details you and your family will have to manage each day, including simple logistical tasks and options, as well as all the various services that can help make your time more productive and useful. Be sure to take full advantage of our many services offered to hospital guests specifically to make all that easier.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor orangeBackgroundColor noMinHeight">
                                                <h2 className="subheadUpperAndLower mb-0"><i className="fa fa-square-check"></i>&nbsp;Concierge Services</h2>
                                            </div>
                                            <div className="withBorder orangeColorBorderColor">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>While visiting patients at Barnes-Jewish Hospital, concierge representatives are available to help assist you with information, services and special requests, including:</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                                    <div className="w-100">
                                                        <div className="imageContainer">
                                                            <div className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                                <p><strong>Bank locations</strong></p>
                                                                <p><strong>Cafeterias and restaurants</strong></p>
                                                                <p><strong>Campus maps and directions</strong></p>
                                                                <p><strong>Hotels</strong></p>
                                                                <p><strong>Long-term accommodations</strong></p>
                                                                <p><strong>Local attractions</strong></p>
                                                                <p><strong>Laundry facilities</strong></p>
                                                            </div>
                                                            <div className="rightCol col-md-5 paddingLeft7">
                                                                <p><strong>Parks</strong></p>
                                                                <p><strong>Pharmacies nearby</strong></p>
                                                                <p><strong>Postal services</strong></p>
                                                                <p><strong>Shopping</strong></p>
                                                                <p><strong>Shower facilities</strong></p>
                                                                <p><strong>Transportation services</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>While visiting patients at Barnes-Jewish Hospital, concierge representatives are available to help assist you with information, services and special requests, including:</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="width100" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperAndLower"><em><a href="tel:3143625194">314-362-5194</a> <a href="mailto:conciergeservices@bjc.org" target="_blank">conciergeservices@bjc.org</a></em></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Meet and Greet Escorts</p>
                                            <p><em><strong><a href="tel:3143625194">314-362-5194</a></strong> or toll-free <strong><a href="tel:8004514892">800-451-4892</a></strong></em></p>
                                            <p>We can help make your visit more pleasant and convenient by having someone meet you at the door, escort you to your appointment and help you with any other special guest services. This free service is available to anyone.</p>
                                            <p className="boldLeadIn">Shuttle Service<br /><em><a href="tel:3143620750">314-362-0750</a></em></p>
                                            <p>The hospital offers a free, wheelchair-accessible shuttle to patients and visitors from 7 a.m.-11 p.m. Monday through Friday. The route includes the hospital’s north and south campus, Barnes Lodge, Hope Lodge and the Ronald McDonald House. The shuttle picks up every 20 minutes.</p>
                                            <p>On weekends and holidays, transportation is available via a hospital security vehicle every two even hours beginning at 8 a.m. until 8 p.m. Please call to confirm a pick-up at a certain time. Weekend and holiday hours do not include Hope Lodge or the Ronald McDonald House.</p>
                                            <p className="boldLeadIn">Pharmacy<br /><em><a href="tel:3144547666">314-454-7666</a></em></p>
                                            <p>Mon - Fri 6:30 am to 5:30 pm, Sat 7 am to 3 pm, Closed Sundays and most major holidays.</p>
                                            <p>Barnes-Jewish Hospital's outpatient pharmacy provides services for outpatients from Barnes-Jewish Hospital's south and north campuses, for outpatients from the Center for Advanced Medicine physician offices, and for BJC HealthCare employees and their dependents. <a href="https://barnesjewish.org/patients-visitors/pharmacy" target="_blank">Barnesjewish.org/patients-visitors/pharmacy</a></p>
                                            <p className="boldLeadIn">Sending Flowers or Gifts to Patients<br /><em><a href="tel:3143624300">314-362-4300</a> or <a href="tel:8004094485">800-409-4485</a></em></p>
                                            <p>To send a gift to a patient during their hospital stay, contact our gift shop at the numbers listed, or go online to order at shop. <a href="https://www.barnesjewish.org/Patients-Visitors/Gifts-and-Flowers" target="_blank">barnesjewish.org</a></p>
                                            <p className="boldLeadIn">Internet Access</p>
                                            <p>Patients, family members and guests can enjoy the Internet from most patient rooms at Barnes-Jewish Hospital. Service is free and can be accessed with most properly configured wireless devices. Patients and visitors without access to a wireless device can take advantage of free Internet access in our Concierge Services offices.</p>
                                            <p className="boldLeadIn">Computers and Office Services</p>
                                            <p>Computers are available for use in the offices on the South campus and in the Center for Advanced Medicine. Both offer, at no charge, Internet service, printing, email and other office services.</p>
                                            <p className="boldLeadIn">Laptop and DVD Loaner Program<br /><em><a href="tel:3143625196">314-362-5196</a></em></p>
                                            <p>Laptop computers, ipads and a selection of DVD shows are available for loan with a current ID. Please see Concierge Services or call for availability Monday through Friday.</p>
                                            <p className="boldLeadIn">Bank and ATMs</p>
                                            <p>9am to 5pm Mon —Thurs, Fri 7am to 5pm</p>
                                            <p>A full-service U.S. Bank branch is located on the first floor of Barnes-Jewish Hospital North adjacent to the Center for Advanced Medicine.</p>
                                            <p>An automatic teller machine (ATM) is located near the entrance to the bank. In addition, ATMs can be found in the lobby of the Center for Advanced Medicine and on the street level of Barnes-Jewish Hospital South near the lobby.</p>
                                            <p className="boldLeadIn">Lost & Found<br />
                                                <em><a href="tel:3147475678">314-747-LOST (5678)</a></em><br />
                                                <span>Or, Security at </span><em><a href="tel:3143620750">314-362-0750</a></em></p>
                                            <p>Contact us to report a lost or found item. We will make every effort to locate a patient’s lost property; however we are not responsible for lost or stolen property.</p>
                                            <p className="boldLeadIn">Interpreter Services<br />
                                                <em><a href="tel:3147475682">314-747-5682</a></em></p>
                                            <p>Interpreters are available 24 hours a day for patients who need help with language assistance or have special cultural needs. Deaf interpretation, on-site foreign language interpretation and telephone foreign language interpretation are available.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Expert Information and Guidance</p>
                                            <p>A diagnosis of cancer can be frightening for patients and their loved ones. Patients may feel overwhelmed. They may have questions about the future. By becoming well-informed about the disease, they can become a more active participant in healthcare decisions.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon orangeBackgroundColor widthAuto">
                                                <i className="fa fa-square-check"></i>Tip
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>If you haven’t already, familiarize yourself and take advantage of the Barnard Center’s resources by visiting during days spent at the hospital, using them to help inform and build your plan for recovery after your procedure.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 doctorInfo oneSideBar">
                                        <div className="w-100">
                                            <div className="sidebarNoFill sidebarNoFillborderColor width100 borderColorGray addressInfoContent">
                                                <div className="imgCol">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/barnard_center.jpg" className="img-responsive noPadding" />

                                                </div>
                                                <div className="addressCol">

                                                        <h2 className="subheadUpperandLower">Barnard Health and Cancer<br />Information Center<br /><em><a href="tel:3143627844">314-362-7844</a></em></h2>
                                                        <p>Lobby of the Center for<br />
                                                            Advanced Medicine<br />
                                                            4921 Parkview Place<br />
                                                            St. Louis, MO 63110</p>
                                                        <p>Open 9am to 4pm M-F</p>
                                                    </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>The Barnard Health and Cancer Information Center at Siteman Cancer Center’s main campus in St. Louis is open on a walk-in basis to assist anyone whose life has been touched by cancer. The center serves patients, their families, medical professionals and others who need current information on cancer prevention, early detection, diagnosis and treatment, coping and community resources.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Our center provides:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom orangeBullets">
                                                <li>A computer with Internet access for patients</li>
                                                <li>Educational programs</li>
                                                <li>Printed materials</li>
                                                <li>A smoking cessation program</li>
                                                <li>Referral to cancer support groups</li>
                                                <li>Volunteer opportunities</li>
                                                <li>Resources for low-income or underinsured patients</li>
                                            </ul>
                                        </div>
                                    </div></div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Genetic Testing and Counseling</p>
                                            <p>About 5 percent to 10 percent of all cancers are hereditary. Geneticists and genetic counselors at Siteman help people who have a family history of a particular type of cancer assess their cancer risk. You surgeon will help you determine if genetic testing is warranted. </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Support Communities</p>
                                            <p>We also partner with local organizations to offer cancer support groups for patients and families. Learn more about those groups by clicking below:</p>
                                            <p className="boldLeadIn">Cancer Support Community of Greater St. Louis</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom orangeBullets">
                                                <li>Weekly support groups for patients and their families</li>
                                                <li>Monthly networking groups</li>
                                                <li>Monthly educational programs</li>
                                                <li>Stress management programs</li>
                                                <li>Gentle movement programs</li>
                                                <li>A full year’s calendar of additional support programs and offers</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a href="https://www.cancer.org/treatment/caregivers/index" target="_blank">Learn more here.</a></p>
                                            <p className="boldLeadIn">Additional Support for Caregivers and Family</p>
                                            <p>visit: <a href="https://cancer.org/treatment/caregivers/index" target="_blank">cancer.org/treatment/caregivers/index</a></p>
                                            <p className="boldLeadIn mb-4">Recommended Liver and Pancreas Cancer Patient Resources</p>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                            <div className="col-md-6 col-sm-12 paddingRight7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nci-logo-full.svg" className="img-responsive brandLogo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>The National Cancer Institute website.</strong><br />Search for your cancer type for detailed information regarding the diagnosis, staging, treatment and follow-up for patients with tumors of the liver and pancreas. Includes information about clinical trials.</p>
                                                            <p>visit: <a href="https://cancer.gov/types/pancreatic" target="_blank">cancer.gov/types/pancreatic</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 paddingLeft7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/American_Cancer_Society_Logo.png" className="img-responsive brandLogo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>American Cancer Society website</strong><br />For over 100 years, the American Cancer Society has worked relentlessly to save lives and create a world with less cancer. Visit their website for a full look at what they can offer you as they work with millions of supporters worldwide to help people stay well and get well, find cures and fight back against cancer.</p>
                                                            <p>visit: <a href="https://cancer.org/" target="_blank">cancer.org</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                            <div className="col-md-6 col-sm-12 paddingRight7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/logo.png" className="img-responsive brandLogo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>PanCan (Pancreatic Cancer Action Network)</strong><br />An excellent place for patients to learn about the diagnosis and treatment of pancreatic cancer. Has detailed information about surgical management, chemotherapy and research activities.</p>
                                                            <p>visit: <a href="https://www.pancan.org/" target="_blank">pancan.org</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 paddingLeft7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/asco-logo.jpg" className="img-responsive brandLogo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>Gastrointestinal Cancers of the American Society of Clinical Oncology (ASCO) and Journal of Clinical Oncology (JCO) website</strong><br />Latest information about results of clinical trails, new information, new treatments and news about gastrointestinal (including tumors of the liver and pancreas). This can be more technical/medical than the other websites, but contains an extensive amount of information.</p>
                                                            <p>visit: <a href="https://gi.jco.org/" target="_blank">gi.jco.org</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">

                                            <div className="col-md-6 col-sm-12 paddingRight7">
                                                <div className="sidebarNoFill sidebarNoFillborderColor borderColorGray">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/CaringBridge_logo.jpg" className="img-responsive brandLogo" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>CaringBridge</strong><br />CaringBridge is a free, not-for-profit, easy-to-use Internet service developed to make communication easier so patients and families can stay in touch with loved ones. In minutes, families can create a secure, personalized CaringBridge webpage that displays journal entries and photographs.</p>
                                                            <p>visit: <a href="https://caringbridge.org/" target="_blank">caringbridge.org</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12 paddingLeft7">
                                                &nbsp;
                                            </div>

                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Health and Lifestyle Services Nutrition<br /><em><a href="tel:3144547619">314-454-7619</a></em></p>
                                            <p>Sometimes patients undergoing cancer treatment have side effects that affect how they are able to eat. Licensed registered dietitians at Siteman are available to provide general nutritional counseling during treatment and recovery, assessment of nutritional needs, techniques for coping with the side effects of cancer treatment, and information about nutritional supplements, complementary nutritional therapy, prevention of weight loss and the role of diet and nutrition in reducing cancer risk.</p>
                                            <p className="boldLeadIn">BJC WellAware Center<br /><em><a href="tel:3142860525">314-286-0525</a></em></p>
                                            <p>The BJC WellAware Center <a href="https://www.barnesjewish.org/Patients-Visitors/Fitness-Center" target="_blank">(barnesjewish.org/wellaware-fitness)</a> is a state-of-the-art fitness facility located in the heart of the Central West End with five-star service providing exercise programs, classes and services.</p>
                                            <p className="boldLeadIn">Pain Management Center<br /><em><a href="tel:3143628820">314-362-8820</a></em></p>
                                            <p>Patients with cancer may experience pain for a variety of reasons – as a result of tumor growth, advanced cancer or treatment side effects. For patients who experience persistent, chronic pain, help is available through the Pain Management Center. The center is staffed by Washington University School of Medicine anesthesiologists who specialize in pain management.</p>
                                            <p>Services available include evaluation and treatment in a coordinated care setting. When needed, patients are seen by teams that may include an anesthesiologist, psychologist, physical therapist and registered nurse.</p>
                                            <p>Because chronic pain is a complex problem, individualized treatment plans may include the following:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom orangeBullets">
                                                <li>Medications and other medical treatments</li>
                                                <li>Nerve blocks, vertebroplasty, spinal analgesics or other procedures</li>
                                                <li>Relaxation/biofeedback therapies</li>
                                            </ul>
                                        </div>
                                    </div></div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>The Pain Management Center is located in the Center for Advanced Medicine on Siteman’s main campus. Referrals are accepted from physicians only.</p>
                                            <p className="boldLeadIn">Tobacco Cessation</p>
                                            <p>Although quitting the habit seems a simple solution, the highly addictive nature of nicotine makes that an overwhelming challenge for many smokers. For this reason, the Siteman Cancer Center offers a multilayered approach to combating nicotine addiction. Our efforts range from helping people stop smoking to finding out why they start, and continue to use, nicotine. Options include:</p>
                                            <p className="boldLeadIn">Freedom from Smoking</p>
                                            <p>Group Sessions: <strong><em><a href="tel:3143627844">314-362-7844</a></em></strong></p>
                                            <p>This free, six-week program covers behavior modification, stress reduction and relapse prevention. Participants must attend all sessions. Sessions are held at the Barnard Health and Cancer Information Center on Siteman’s main campus</p>
                                            <p className="boldLeadIn">One-on-one smoking cessation</p>
                                            <p><strong><em><a href="tel:3147477848">314-747-QUIT (7848)</a></em></strong> or email <strong><em><a href="mailto:quitsmoking@wustl.edu">quitsmoking@wustl.edu</a></em></strong></p>
                                            <p>Help yourself quit and stay smoke-free with the Washington University smoking cessation program. Patients are seen at the Center for Advanced Medicine and Barnes Jewish-West County Hospital. A referral from a Washington University physician is required.</p>
                                            <p>In the program you’ll receive:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom orangeBullets">
                                                <li>One-on-one help:In-person, one-on-one smoking or tobacco cessation coaching sessions.</li>
                                                <li>Smoking cessation aids:Information about nicotine replacement products and medica tions, if physician-approved for your use.</li>
                                                <li>Continued support:Support and counseling to help you quit and stay smoke free.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Sexual Health and Fertility<br /><em><a href="tel:3142862400">314-286-2400</a></em></p>
                                            <p>Fertility, or being able to be pregnant with your own biologic children, may not seem important if you are facing chemotherapy or radiation for a cancer diagnosis or other disease process, but it may become an important quality of life issue once your treatment is complete.</p>
                                            <p>Chemotherapy or radiation can damage a woman’s oocytes (eggs) as well as a man’s ability to make sperm. For many patients facing these treatments, though, fertility can be preserved prior to, or sometimes during, treatment. The specialists at the Washington University Infertility and Reproductive Medicine Center will work with your oncologist to help you understand the options that are available to you.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic27;
