import React from "react";

function L11ComponentCode() {

    return (

        <div className="lego l011">
            <img className="imageW100" alt=""
                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/011aa6ac16b87325b00ea43f2cb9e9bc.png"/>
        </div>

    )
};


export default L11ComponentCode;