import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonPulseWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonTemperature">Taking a Temperature</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatYouNeedToKnow#needToKnow">
                                        <p className="whiteColor">NEED TO KNOW <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatYouNeedToKnow#fevers">
                                        <p className="whiteColor">FEVERS <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatYouNeedToKnow#thermometers">
                                        <p className="whiteColor">THERMOMETERS <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatYouNeedToKnow#risks">
                                        <p className="whiteColor">RISKS <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="needToKnow">NEED TO KNOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Factors that Affect Body Temperature</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>No one temperature is normal for all people. Many factors affect body temperature, including:</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Age</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Normally an adult’s body temperature ranges between 96.8 to 100.4 degrees Fahrenheit. (That’s 36 to 38 degrees on the Centigrade scale.)</li>
                                            <li>Older adults have a narrow range of body temperature, averaging around 96.8 degrees Fahrenheit (36 degrees Centigrade). Low body temperatures, 95 degrees Fahrenheit (35 degrees Centigrade), are normal for older adults in cold weather.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Exercise</p>
                                        <p>Exercise increases body temperature by increasing our metabolism. A long distance runner might have a body temperature as high as 105.8 degrees Fahrenheit (41 degrees Centigrade) after just finishing a long run.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Hormones – Women usually have greater changes in body temperature than men.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Menstruation – When a woman has her monthly period (menstruates), body temperatures go up and down. The hormone progesterone affects this cycle. When progesterone levels are low, body temperature is lower than normal. This continues until ovulation.</li>
                                            <li>During ovulation, progesterone levels rise, thus the body temperature rises. When the body temperature goes up, a woman is more fertile, a time to try to become pregnant. Taking a temperature to check for ovulation requires a special thermometer that measures temperature to 1/100th of a degree.</li>
                                            <li>Menopause – During menopause, women often have hot flashes, lasting from 30 seconds to up to 5 minutes. The temperature of a woman’s skin will rise quickly and there will be sweating.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/TT_daily_cycle.png" className="img-responsive maxWidth300 floatNone" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Daily Cycle</p>
                                        <p>During a normal 24-hour day, the body temperature goes up and down (See Graph). Normally the body temperature is lowest between 1:00 and 4:00 am. The temperature rises during the day, to reach its highest point at about 6:00 pm.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Stress</p>
                                        <p>Physical and emotional stress increase body temperature. A person who is anxious will likely have an increase in temperature.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Environment</p>
                                        <p>The outside temperature affects body temperature. Exposure to extreme heat, such as working out in the hot sun, will increase the body temperature. Similarly, if you are out in the cold weather, without proper clothing, the body temperature will fall.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Fever</p>
                                        <p>A fever is an abnormally high body temperature. It is the body’s way to fight infection.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>In adults, a temperature taken by mouth that is above 100.5 degrees Fahrenheit (38 degrees Centigrade) or higher is a fever.</li>
                                            <li>In adults, a rectal or ear temperature above 101.5 degrees Fahrenheit (38.6 degrees Centigrade) or higher is a fever.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar text-center">Most doctors will not treat an adult’s fever until it is <br />
                                            over 102.2 degrees Fahrenheit (39° Centigrade). <br /><br />
                                            <span className="fw-normal">However, call the doctor for any fever that lasts longer than 48 to 72 hours, especially in older adults</span>.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="fevers">FEVERS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Causes of Fever</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Causes of fever include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Infection, the most common cause of a fever.</li>
                                            <li>Severe injury or trauma to the body, such as a heart attack, stroke, or burn.</li>
                                            <li>Overexposure to the heat resulting in heat stroke or heat exhaustion.</li>
                                            <li>Cancer – Certain cancers such as leukemia, Hodgkin’s lymphoma, and lung cancer result in fever.</li>
                                            <li>Other medical conditions such as arthritis and hyperthyroidism.</li>
                                            <li>Drugs – Drug fever is becoming more commonly recognized when a person starts a new medicine. The risk for developing drug fever increases with the number of drugs a person takes, especially in the elderly. Drugs known to cause fever include:</li>
                                            <ul className="bulletsCustom checkBulletsSafeCare bulletsSafeCareIndent">
                                                <li>Antibiotics (fight infection)</li>
                                                <li>Anticholinergics (medicines that treat Parkinson’s disease)</li>
                                                <li>Antihistamines (most allergy medicines)</li>
                                                <li>Amphetamines (stimulant drugs)</li>
                                                <li>Anticonvulsants (treat seizures)</li>
                                                <li>Anti-tuberculosis(TB) drugs</li>
                                                <li>Barbiturates (drugs that make you relaxed or sleepy)</li>
                                                <li>Interferon (drugs given to improve immune function)</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Signs of Fever</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Skin feels warm and dry to touch</li>
                                            <li>Skin is flushed</li>
                                            <li>Lack of energy</li>
                                            <li>Restlessness</li>
                                            <li>Developing confusion</li>
                                            <li>Lack of interest in eating</li>
                                            <li>Remember in the very old, an increase in temperature may not be the first sign of infection.</li>
                                            <li>Chills may occur when people have a temperature, indicating a serious infection.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Ways to Measure Temperature</h2>
                                    </div>
                                </div>
                                <div className="col-md-12 threecolumnTable">
                                    <div className="w-100">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th colSpan="3">
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="leftCol">
                                                        <h4>Route</h4>
                                                    </th>
                                                    <th className="centerCol cyanTableBackgroundColor">
                                                        <h4>Pros</h4>
                                                    </th>
                                                    <th className="rightCol">
                                                        <h4>Cons</h4>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Oral</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Easy to insert and accurate</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Do not use if person had mouth surgery, has a history of seizures, or has nasal stuffiness or shaking chills.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Wait 15 minutes to take temperature if person has just had anything to eat or drink, has chewed gum or smoked.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Rectum</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Do not use if person has diarrhea, bleeding from rectum, hemorrhoids or recent rectal surgery.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Arm pit (axilla)</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Safe and easy to insert. </p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Less accurate than oral or rectal. May be more accurate than oral for persons who breathe with their mouth open or cannot close their mouth tightly around a thermometer.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Caregiver must hold thermometer the entire time during measurement.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Skin</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Safe to use</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Do not use if person is sweating.<br />
                                                            Do not use if person has allergy to adhesive.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Ear</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Accurate, rapid measurement </p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Earwax distorts the reading.<br />
                                                            Must be placed correctly in the ear.<br />
                                                            Do not use if person has had ear surgery.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Temporal</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        &nbsp;
                                                    </td>
                                                    <td className="rightCol">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        &nbsp;
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>More accurate than the ear thermometer in infants, and it is better tolerated by infants that the rectal.</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Not as accurate as the rectal temperature. Should only be used for screening. Thermometer is used infrequently in hospitals.</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="thermometers">THERMOMETERS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/20003.jpg" alt="" />
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p><span className="fontWeight700">Digital thermometer:</span><br />
                                                Use for the mouth, rectum and the armpit. The thermometer is plastic with a narrow temperature probe at one end and a display window at the other. It contains a computer chip. Place the narrow probe in the mouth, rectum or armpit. The thermometer will show a reading in seconds often with an alarm when the reading is complete.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10222I.jpg" alt="" />
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p><span className="fontWeight700">Disposable thermometer:</span><br />
                                                Use for the mouth and the armpit. Thermometer is a disposable thin strip of plastic with a temperature sensor on one end. The dots change colors at different temperatures. You can take an oral reading in about 60 seconds. A temperature in the armpit will take about 3 minutes. Some can be reused, but most are single use.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Ear thermometer:</p>
                                        <p>Use for the ear. An infrared sensor detects heat from the ear drum. Place the cone shaped sensor in the ear. The thermometer registers a temperature in 2 to 3 seconds after you place it in the ear canal.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/274117_1285_detail.jpg" alt="" />
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p><span className="fontWeight700">Skin thermometer:</span><br />
                                                Use on the skin. Press the soft disc against the forehead. It digitally displays the temperature. Other skin thermometers have thin pieces of plastic with numbers on them. Place the strip against the person’s forehead and the temperature makes numbers change colors or light up. Skin thermometers are not as accurate as digital or ear thermometers but may be used to determine if a temperature is present. If you get a high reading, recheck the temperature with one of the other thermometers listed above.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Temporal Artery Thermometer:</p>
                                        <p>This has not been shown to be as accurate as the rectal thermometer in children. Not commonly used in adults.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Fahrenheit versus Centigrade</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A thermometer has either a Fahrenheit or Centigrade (Celsius) scale. Use the same scale consistently so that you can easily detect an abnormal temperature.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="risks">RISKS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Heat-Related Illness</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person develops a heat-related illness when the body’s ability to control temperature becomes overloaded. The body suddenly cannot cool itself by sweating. This occurs most often when a person works or exercises in very hot weather. The body temperature rises rapidly. A hot, humid climate poses the greatest risk.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">Adults most at risk for fever </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Adults over age 65</li>
                                            <li>People overweight</li>
                                            <li>People who are chronically ill (Heart disease, high blood pressure)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonPulseWhatYouNeedToKnow;