import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanPancreaticAll2_3_1 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-1">
                                        <div className="tile t_003 Height1RowTall width1Column middleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-day-by-day.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Day-By-Day</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-2">
                                        <div
                                            className="tile t_013 t_013_2x1 tileWithCenterBanner Height1RowTall width2Column workingWithYourTeamBackgroundImg">
                                            <div className="contentCenter">
                                                <div
                                                    className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Working With Your Team</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-3">
                                        <div className="tile Height1RowTall width1Column t_001 t_001_1x1 lightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Medication and<br/>Managing Pain</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-4">
                                        <div className="tile t_003 Height1RowTall width1Column deepBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-activity-plan.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Activity Plan</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-5">
                                        <div className="tile t_003 Height1RowTall width1Column lightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-nutrition-during-stay.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Nutrition During Your Stay</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-6">
                                        <div className="tile Height1RowTall width1Column t_001 t_001_1x1 middleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Your Care<br/>Schedule</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-7">
                                        <div className="tile Height1RowTall width1Column t_001 t_001_1x1 middleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Possible<br/>Complications</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3-1-8">
                                        <div className="tile t_003 Height1RowTall width1Column middleGreenBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-warning-signs-maroon-hov.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Signs to Watch Out For</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll2_3_1;