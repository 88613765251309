import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonSubcutaneousInjectionsWhatToTellYourDoctor = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonSubcutaneousInjections">Subcutaneous Injections</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Tell the doctor if you are having any trouble giving the injection. It might help to make an appointment for your family member and then show the doctor during the appointment how you are giving the injection.</li>
                                                    <li>Tell the doctor if your family member is having any side effects and how long they have lasted.</li>
                                                    <li>Show the doctor your record of injection sites.</li>
                                                    <li>Inform the doctor if a lump develops with redness, pain, and/or drainage at the injection site.</li>
                                                    <li>Tell the doctor if injection sites continue to bleed or the injections are painful. This would be occurring after more than one injection.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">When to Call the Doctor:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Call the doctor if the person develops a rash around the injection site or develops a fever.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead26px d-inline-block"><i className="fa fa-exclamation-circle coralColorMargin" ></i>&nbsp;If you find a person with diabetes unconscious or not responding to you, check their blood glucose (See our lesson on <Nav.Link className="text-white d-inline p-0 h3Link" href="/LessonGlucose">Checking Blood Glucose</Nav.Link>) and call 911.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonSubcutaneousInjectionsWhatToTellYourDoctor;
