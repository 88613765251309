import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {register} from "../../../actions/register.js";
import {InputWithErrorMessage} from "../../forms/inputWithErrorMessage.js";
import {InputAdornment, IconButton} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ToastContainer, toast } from 'react-toastify';


import {
    validateConfirmPassword,
    validateEmailAddress,
    validateFirstName,
    validateLastName,
    validatePassword,
    validatePhoneNumber,
    validateUserName
} from "./validation.js";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {registerInfo} from "../../../actions/registerInfo.js";
import {logout} from "../../../middleware/auth/index.js";
import {registerFromQrCode} from '../../../actions/registerFromQrCode';
import {getUser} from "../../../helpers/index.js";
import {registerFromQrCodeTempUser} from "../../../actions/registerFromQrCodeTempUser.js";
import {Navbar, Nav, Dropdown} from "react-bootstrap";
import styles from "../../routing/topNavLoggedOut/TopNavtopNavLoggedOut.module.scss";
import {REGISTER_ERROR, REGISTER_INFO_ERROR} from "../../../actions/types.js";
import {patientActivationHeadingText} from "../../../actions/patientActivationHeadingText";

const Register = () => {
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [activationHeadingText, setActivationHeadingText] = useState("");
    const [isDirty, setIsDirty] = useState(false);
    const {userActivityLogId, encString} = useParams();
    const {errorMessage} = useSelector(state => state.register);
    const {errorMessage: registerInfoErrorMessage} = useSelector(state => state.registerInfo);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const [show, setShow] = useState(false);
    const [currentAccessToken, setCurrentAccessToken] = useState('');
    const [hideEmailAddressField, setHideEmailAddressField] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(showPassword);
    }
    const handleClickConfirmShowPassword = () => {
        setShowConfirmPassword(showConfirmPassword);
    }
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isTemporaryOrbitAccessUser, accessToken} = useParams();

    useEffect(() => {
        if (errorMessage || registerInfoErrorMessage) {
            toast.error(registerInfoErrorMessage ? registerInfoErrorMessage : errorMessage)
        }

    }, [registerInfoErrorMessage, errorMessage])

    useEffect(async () => {
        dispatch({
            type: REGISTER_ERROR,
            payload: ''
        });

        dispatch({
            type: REGISTER_INFO_ERROR,
            payload: ''
        });

        if (Boolean(isTemporaryOrbitAccessUser ? isTemporaryOrbitAccessUser : false))
            setShow(isTemporaryOrbitAccessUser);

        setCurrentAccessToken(accessToken);

        if (encString === undefined) {
            const ca = await registerInfo({id: userActivityLogId}, (data) => {
                setActivationHeadingText(data.activationHeadingText);
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmailAddress(data.emailAddress);
                setPhoneNumber(data.phoneNumber);

                if (data && data.firstName && data.lastName && data.emailAddress && data.phoneNumber) {
                    setIsDirty(true);
                }
            });
            await dispatch(ca);
        }
        else {
            const ca = await patientActivationHeadingText({encString}, (data) => {
                setActivationHeadingText(data.activationHeadingText);

                if (data && data.firstName && data.lastName && data.emailAddress && data.phoneNumber) {
                    setIsDirty(true);
                }
            });
            await dispatch(ca);
        }

        //get default orbit for logged in user
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        dispatch({
            type: REGISTER_ERROR,
            payload: ''
        });

        dispatch({
            type: REGISTER_INFO_ERROR,
            payload: ''
        });

        if (!await validateAllFields())
            return;

        if (encString) {
            const registerUserFromQrCode = await registerFromQrCode({
                userName,
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                password,
                encString
            }, () => {
                dispatch({
                    type: 'RESET'
                });
                localStorage.clear();
                navigate('/');
            })
            await dispatch(registerUserFromQrCode);
        } else if (accessToken) {
            const registerUserFromQrCodeTempUser = await registerFromQrCodeTempUser({
                userName,
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                password,
                accessToken
            }, () => {
                dispatch({
                    type: 'RESET'
                });
                localStorage.clear();
                navigate('/');
            })
            await dispatch(registerUserFromQrCodeTempUser);
        } else {
            const registerUser = await register({
                userName,
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                password,
                userActivityLogId
            }, () => {
                dispatch({
                    type: 'RESET'
                });
                localStorage.clear();
                navigate('/');
            })
            await dispatch(registerUser);
        }
    }

    const changeUserName = (e) => setUserName(e.target.value);
    const changeFirstName = (e) => setFirstName(e.target.value);
    const changeLastName = (e) => setLastName(e.target.value);
    const changeEmailAddress = (e) => setEmailAddress(e.target.value);
    const changePhoneNumber = (e) => setPhoneNumber(e.target.value);
    const changePassword = (e) => setPassword(e.target.value);
    const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);

    const verifyIsEmailAddress = (userName) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (emailRegex.test(userName)) {
            setEmailAddress(userName);
            setHideEmailAddressField(true);
        } else {
            setHideEmailAddressField(false);
        }
    }

    const validateUserNameField = async (e) => {
        await setIsDirty(true);
        await setUserNameError(validateUserName(userName));
        verifyIsEmailAddress(userName);
    }

    const validateFirstNameField = async (e) => {
        await setIsDirty(true);
        await setFirstNameError(validateFirstName(firstName));
    }

    const validateLastNameField = async (e) => {
        await setIsDirty(true);
        await setLastNameError(validateLastName(lastName));
    }

    const validateEmailAddressField = async (e) => {
        await setIsDirty(true);
        await setEmailAddressError(validateEmailAddress(emailAddress));
    }

    const validatePhoneNumberField = async (e) => {
        await setIsDirty(true);
        await setPhoneNumberError(validatePhoneNumber(phoneNumber));
    }

    const validatePasswordField = async (e) => {
        await setIsDirty(true);
        setConfirmPasswordError(validateConfirmPassword(password, confirmPassword));
        setPasswordError(validatePassword(password, confirmPassword));
    }

    const validateConfirmPasswordField = async (e) => {
        setConfirmPasswordError(validateConfirmPassword(password, confirmPassword));
        setPasswordError(validatePassword(password, confirmPassword));
    }

    const validateAllFields = async () => {
        await setIsDirty(true);
        const invalidUserName = validateUserName(userName);
        const invalidFirstName = validateFirstName(firstName);
        const invalidLastName = validateLastName(lastName);
        const invalidEmailAddress = validateEmailAddress(emailAddress);
        const invalidPhoneNumber = validatePhoneNumber(phoneNumber);
        const invalidPassword = validatePassword(password, confirmPassword);
        const invalidConfirmPassword = validateConfirmPassword(password, confirmPassword);

        setUserNameError(invalidUserName);
        setFirstNameError(invalidFirstName);
        setLastNameError(invalidLastName);
        setEmailAddressError(invalidEmailAddress);
        setPhoneNumberError(invalidPhoneNumber);
        setPasswordError(invalidPassword);
        setConfirmPasswordError(invalidConfirmPassword);

        if (invalidUserName.length > 0 ||
            invalidFirstName.length > 0 ||
            invalidLastName.length > 0 ||
            invalidEmailAddress.length > 0 ||
            invalidPhoneNumber.length > 0 ||
            invalidPassword.length > 0 ||
            invalidConfirmPassword.length > 0)
            return false;
        else
            return true;
    }

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <ToastContainer />
                <Container className="registration">
                    <div className="pageContainer">
                        {/*<div className="topContainer" style={{fontSize: '18px', marginTop: '36px'}}>*/}
                        <h1 className="text-center welcomeText text-dark">Welcome!
                            Let's get started.</h1>
                        <h1 className="text-center title headlineText" style={{width: "50%", wordWrap: "break-word"}}>
                        {activationHeadingText ? activationHeadingText : ''}</h1>
                        <Box className="material-box" component="form" onSubmit={submitForm} autoComplete="off">
                            <div className="card-body">
                                <div className="formFlexContainer">
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={userNameError}>
                                            <TextField className="w-100" name="userName or Email Address" id="userName" label="User Name"
                                                       variant="outlined" value={userName} onChange={changeUserName}
                                                       onBlur={validateUserNameField}
                                                       error={!!userNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={firstNameError}>
                                            <TextField className="w-100" name="firstName" id="firstName"
                                                       label="First Name"
                                                       variant="outlined" value={firstName} onChange={changeFirstName}
                                                       onBlur={validateFirstNameField}
                                                       error={!!firstNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={lastNameError}>
                                            <TextField className="w-100" name="lastName" id="lastName" label="Last Name"
                                                       variant="outlined" value={lastName} onChange={changeLastName}
                                                       onBlur={validateLastNameField}
                                                       error={!!lastNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput" style={{display: (hideEmailAddressField ? 'none' : 'block')}}>
                                        <InputWithErrorMessage errorMessage={emailAddressError}>
                                            <TextField className="w-100" name="email" id="email" type="email"
                                                       label="Email"
                                                       variant="outlined" value={emailAddress}
                                                       onChange={changeEmailAddress}
                                                       onBlur={validateEmailAddressField}
                                                       error={!!emailAddressError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={phoneNumberError}>
                                            <InputMask
                                                className="w-100" name="phone" id="phone"
                                                variant="outlined"
                                                mask="(999) 999-9999"
                                                value={phoneNumber} onChange={changePhoneNumber}
                                                onBlur={validatePhoneNumberField}
                                                maskChar=" "
                                            >
                                                {() => <TextField className="w-100" type="phone"
                                                                  variant="outlined" label="Phone" required/>}
                                            </InputMask>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={passwordError}>
                                            <TextField type={showPassword ? "text" : "password"} className="w-100"
                                                       name="password" id="password" label="Password"
                                                       variant="outlined" onChange={changePassword}
                                                       autoComplete="new-password"
                                                       onBlur={validatePasswordField}
                                                       error={!!passwordError} required InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <a
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            style={{color: '#3399cc'}}

                                                        >
                                                            {showPassword ? 'hide' : 'show'}
                                                        </a>
                                                    </InputAdornment>
                                                )
                                            }}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={confirmPasswordError}>
                                            <TextField type={showConfirmPassword ? "text" : "password"}
                                                       className="w-100"
                                                       name="password" id="password" label="Re-Enter Password"
                                                       variant="outlined" onChange={changeConfirmPassword}
                                                       onBlur={validateConfirmPasswordField}
                                                       error={!!confirmPasswordError}
                                                       required InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <a
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickConfirmShowPassword}
                                                            onMouseDown={handleMouseDownConfirmPassword}
                                                            style={{color: '#3399cc'}}
                                                        >
                                                            {showConfirmPassword ? 'hide' : 'show'}
                                                        </a>
                                                    </InputAdornment>
                                                )
                                            }}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="buttonContainer loginButtonContainer">
                                        <Button type="submit" variant="contained" className="loginUIComp center"
                                                disabled={userNameError.length > 0 || firstNameError.length > 0 || lastNameError.length > 0 || phoneNumberError.length > 0 || emailAddressError.length > 0 || passwordError.length > 0 || confirmPasswordError.length > 0}>
                                            Activate
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                        {/*</div>*/}
                    </div>
                </Container>
            </div>
            <div className="gradientBottom">
            </div>
        </div>

    )
};

export default Register;
