import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from 'react-youtube';

const LessonInfection = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-12  hvr-grow">
                                    <Nav.Link href="/LessonInfection/Introduction">
                                        <div className="tile t_025 t_025_3x1 Height1RowTall width3Column">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="col-4 imgColumn infectionBackgroundImg">
                                                    </div>
                                                    <div className="col-8 typeColorColumn safecareBlueBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-left whiteColor">Infection Control:<br/>
                                                                    <span className="fontWeight300">Introduction</span>
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 hvr-grow">
                                    <Nav.Link href="/LessonInfection/WhatToTellYourDoctor">
                                        <div className="tile Height1RowTall width1Column safecareBlueBackground t_001">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">What to Tell</h3>
                                                    <h3 className="text-center whiteColor">Your Doctor</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 hvr-grow">
                                    <Nav.Link href="/LessonInfection/WhatYouNeedToKnow">
                                        <div className="tile Height1RowTall width2Column safecareMedBlueBackground t_001 t_001_2x1">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">What You Need to Know</h3>
                                                    <h3 className="text-center whiteColor">About Infection Control</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-4 hvr-grow">
                                    <Nav.Link href="/LessonInfection/FAQResources">
                                        <div className="tile t_003 Height1RowTall width1Column safecareMedBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/K0031_FAQs-icon.svg" />
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">FAQs & Resources</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 hvr-grow">
                                    <Nav.Link href="/LessonInfection/WhatToDoAndSafety">
                                        <div
                                            className="tile Height1RowTall width2Column safecareBlueBackground t_009 t_009_2x1">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">What To Do and Safety <i
                                                            className="fa fa-chevron-right">&nbsp;</i></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonInfection;
