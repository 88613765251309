import React from "react";

function L84ComponentCode() {

    return (

        <div className="lego l084 legoMargin5">
            <h3 className="contentTitle">Billing for Injection Procedures</h3>
            <p>Our Washington University orthopedic specialists treat patients at BJC
                HealthCare facilities, which means you may receive two separate bills. One
                bill is for the physician and the other is for the facility charges. </p>

            {/* L82? Content 2 sides 50% width */}
            <div className="lego l082 p-0">
                <div className="contentLeftSide">
                    {/* L11 Image */}
                    <div className="lego l011 legoMargin3">
                        <img className="imageW100 noBorderRadius"
                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"/>
                    </div>
                    {/* End L11 Image */}

                </div>
                <div className="contentRightSide">
                    <div className="lego l00paragraph legoMargin1">
                        <p>If you have questions regarding a price estimation for the
                            services or a recently received bill, please contact:</p>
                        <p>BJC HealthCare Price Estimation:<br/>
                            <span
                                className="bold"><a className="phoneLink" href="tel:3147478845">314-747-8845</a> or toll free <a className="phoneLink" href="tel:18447478845">844-747-8845</a></span>
                        </p>
                        <p>Washington University Patient Services:<br/>
                            <span
                                className="bold"><a className="phoneLink" href="tel:3142730500">314-273-0500</a> or toll free <a className="phoneLink" href="tel:18008629980">800-862-9980</a></span>
                        </p>
                        <p>BJC HealthCare Patient Billing Services:<br/>
                            <span
                                className="bold"><a className="phoneLink" href="tel:3143628400">314-362-8400</a> or toll free <a className="phoneLink" href="tel:18553628400">855-362-8400</a></span>
                        </p>
                    </div>
                </div>
            </div>
            {/* End L82? Content 2 sides 50% width */}

        </div>

    )
};


export default L84ComponentCode;