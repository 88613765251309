import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_6_3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 greenColorAndBorder legoMargin5">
                    <h1 className="tileTitle">Ultrasound-Guided Injection </h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin3">
                    <h2 className="subHeadUpperAndLowerCase2">
                      About the Procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      An Ultrasound-Guided Injection is a procedure in which an
                      ultrasound is used to guide a needle for accuracy.
                      Commonly injected medications include cortisone (steroid),
                      local anesthetic, hyaluronic acid, and platelet-rich
                      plasma.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L85 Color Bar Top Content Block */}
                  <div className="lego l085 legoMargin5">
                    <div className="headerColorBar">
                      <h3>Vaccinations and your Steroid Injection</h3>
                    </div>
                    <div className="contentContainer">
                      <p>
                        To ensure effectiveness of your flu or COVID-19 vaccine
                        or booster and to improve your ability to monitor
                        potential side effects, you should speak with your
                        provider if:
                      </p>
                      <ul>
                        <li className="legoMargin1">
                          You had the vaccine in the last 2 weeks.
                        </li>
                        <li className="legoMargin1">
                          You plan to have the vaccine.
                        </li>
                        <li>You are immunocompromised.</li>
                      </ul>
                    </div>
                  </div>
                  {/* End L85 Color Bar Top Content Block */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">PRIOR TO THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L88 List with Background Color */}
                  <div className="lego l088 legoMargin5">
                    <div className="contentContainer">
                      {/* L53 Bullet List */}
                      <div className="lego l053 smallFont">
                        <ul>
                          <li>
                            You do <span className="bold">NOT </span> need to
                            stop any other medication (blood pressure meds,
                            diabetes meds, etc.).
                          </li>
                        </ul>
                      </div>
                      {/* End L53 Bullet List */}
                    </div>
                  </div>
                  {/* End L88 List with Background Color */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">DAY OF THE PROCEDURE: </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Please arrive 15 minutes prior to your injection
                          time. 
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin5">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_driving.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldItalic">
                          Driving: Please consider having someone drive you
                        </span>
                        &nbsp;if you are receiving an injection in your lower
                        extremity, the anesthetic may cause temporary numbness.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">AFTER THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83D Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          You will be given written discharge instructions
                        </span>{" "}
                        after your procedure along with a pain diary to track
                        your response to the injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin3">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_meds.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Resume your medicine
                        </span>
                        &nbsp;including any blood thinner you held prior to the
                        injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}
                  {/* L85 Color Bar Top Content Block */}
                  <div className="lego l085 legoMargin4">
                    <div className="headerColorBar">
                      <h3>For Diabetic patients:</h3>
                    </div>
                    <div className="contentContainer">
                      <ul>
                        <li className="legoMargin1">
                          Steroid injections may lead to higher blood glucose
                          (sugar) levels temporarily. Most commonly, the higher
                          levels will return to normal within 1-3 days, though
                          effects may last longer. Rises in blood glucose levels
                          may be more significant in patients with poorly
                          controlled type 2 diabetes (those with HbA1c levels
                          greater than 7) and those with type 1 diabetes.
                        </li>
                        <li className="legoMargin1">
                          Check fasting (early morning prior to first meal of
                          the day) and post-prandial (following meals) blood
                          glucose levels.
                        </li>
                        <li className="legoMargin1">
                          Contact the physician who manages your diabetes if
                          your blood sugar is significantly elevated (for
                          example, over 100mg/dL higher than your pre-injection
                          level) or if blood sugar levels remain significantly
                          elevated 2 days after receiving the injection, to
                          discuss whether a change in medication dosing is
                          needed.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End L85 Color Bar Top Content Block */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">WHEN YOU GET HOME:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin5 w-80 m-auto">
                    <p>
                      For questions during normal working hours, call our office
                      at&nbsp;
                      <span className="bold">
                        <a className="phoneLink" href="tel:3147472823">
                          314-273-1832.
                        </a>
                      </span>
                      <br />
                      <span className="bold">
                        For urgent concerns after hours
                      </span>
                      , call our exchange at{" "}
                      <span className="bold">
                        <a className="phoneLink" href="tel:314-388-5550">
                          314-388-5550
                        </a>
                        .
                      </span>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_6_3;
