import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic17 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Key Contact Information</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar middleGreenBackground widthAuto">MAIN NUMBERS</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Dr. William Hawkins: <span className="tel-style"><a href="tel:3143627046">314-362-7046</a></span></p>
                                            <p>Dr. Ryan Fields: <span className="tel-style"><a href="tel:3142861694">314-286-1694</a></span></p>
                                            <p>Dr. Chet Hammill: <span className="tel-style"><a href="tel:3142731809">314-273-1809</a></span></p>
                                            <p>Dr. Dominic Sanford: <span className="tel-style"><a href="tel:3142732720">314-273-2720</a></span></p>
                                            <p>Dr. Natasha Leigh: <span className="tel-style"><a href="tel:3143627147">314-362-7147</a></span></p>
                                            <p>Dr. William Chapman: <span className="tel-style"><a href="tel:3143622538">314-362-2538</a></span></p>
                                            <p>Dr. Maria Majella Doyle: <span className="tel-style"><a href="tel:3143622880">314-362-2880</a></span></p>
                                            <p>Dr. Adeel Khan: <span className="tel-style"><a href="tel:3143622820">314-362-2820</a></span></p>


                                            {/*<p>Concierge Services: <span className="tel-style"><a href="tel:3143625196">314-362-5196</a></span></p>*/}
                                            {/*<p>Office of Patient & Family Affairs:<br />*/}
                                            {/*    <em><span className="tel-style"><a href="tel:8006003606">800-600-3606</a></span> or <span className="tel-style"><a href="tel:3143626100">314-362-6100</a></span> (8am – 5pm, M-F)<br />*/}
                                            {/*        <span className="tel-style"><a href="tel:3143625000">314-362-5000</a></span> (evenings/weekends, ask for Nurse Supervisor)</em></p>*/}
                                            {/*<p>In-Hospital Emergencies: <span className="tel-style"><a href="tel:3143620911">314-362-0911</a></span> or Security at <span className="tel-style"><a href="tel:3143620750">314-362-0750</a></span></p>*/}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                If you have a medical emergency, please call <em><a href="tel:911">911</a></em>&nbsp;
                                                or report to the nearest emergency room.
                                            </h2>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>Normal business hours</strong>: Monday - Friday 8am - 4pm.<br />
                                                For after-hours concerns, contact the physician on-call at&nbsp;
                                                <span className="tel-style"><a href="tel:3143621242">314-362-1242</a></span></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar middleGreenBackground widthAuto">OTHER PATIENT AND FAMILY SERVICES</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Barnard Health and Cancer Information Center: <span className="tel-style"><a href="tel:3143627844">314-362-7844</a></span></p>
                                            <p>Pharmacy: <span className="tel-style"><a href="tel:3144547666">314-454-7666</a></span></p>
                                            <p>In-Route Appointment Hotline: <span className="tel-style"><a href="tel:8665149940">866-51-HWY40</a></span> (<span className="tel-style"><a href="tel:8665149940">866-514-9940</a></span>)<br />
                                                We will notify your physician that you may be late if you are unexpectedly delayed due to traffic</p>
                                            <p>Social Worker Services: to take advantage of available social services, contact your Surgeon's office for a referral.</p>
                                            <p>Patient Liaisons: <span className="tel-style"><a href="tel:3143625196">314-362-5196</a></span> (8am – 5pm, M-F)<br /><span className="tel-style"><a href="tel:3143625000">314-362-5000</a></span> (evenings/weekends, ask for Nurse Supervisor)</p>
                                            <p>Support Group Information: <span className="tel-style"><a href="tel:3143625574">314-362-5574</a></span></p>
                                            <p>Spiritual Care: <span className="tel-style"><a href="tel:3143625200">314-362-5200</a></span></p>
                                            <p>Genetic Testing and Counseling: <span className="tel-style"><a href="tel:3144546093">314-454-6093</a></span></p>
                                            <p>Billing and Financial Assistance: <span className="tel-style"><a href="tel:3143620710">314-362-0710</a></span> or <span className="tel-style"><a href="tel:8008330604">800-833-0604</a></span></p>
                                            <p>Shuttle Services: <span className="tel-style"><a href="tel:3143620750">314-362-0750</a></span></p>
                                            <p>Interpreter Services: <span className="tel-style"><a href="tel:3147475682">314-747-5682</a></span></p>
                                            <p>Flower and Gift Shop: <span className="tel-style"><a href="tel:3143624300">314-362-4300</a> or <a href="tel:8004094485">800-409-4485</a></span></p>
                                            <p>Laptop/DVD Loaner Program: <span className="tel-style"><a href="tel:3143625196">314-362-5196</a></span></p>
                                            <p>Lost and Found: <span className="tel-style"><a href="tel:3147475678">314-747-LOST</a></span> (<span className="tel-style"><a href="tel:3147475678">314-747-5678</a>)</span></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar middleGreenBackground widthAuto">ADDITIONAL TREATMENT SERVICES</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Nutrition Discussions with Licensed Dieticians: <span className="tel-style"><a href="tel:3144547619">314-454-7619</a></span></p>
                                            <p>BJC WellAware Center (fitness facility): <span className="tel-style"><a href="tel:3142860525">314-286-0525</a></span></p>
                                            <p>Pain Management Center: <span className="tel-style"><a href="tel:3143628820">314-362-8820</a></span></p>
                                            <p>Sexual Health and Fertility: <span className="tel-style"><a href="tel:3142862400">314-286-2400</a></span></p>
                                            <p><strong>Tobacco Cessation Programs:</strong><br />
                                                Group sessions: <span className="tel-style"><a href="tel:3143627844">314-362-7844</a></span><br />
                                                One-on-one sessions: <span className="tel-style"><a href="tel:3147477848">314-747-QUIT</a></span> (<span className="tel-style"><a href="tel:3147477848">314-747-7848</a></span>)</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar middleGreenBackground widthAuto">OTHER BARNES LOCATIONS</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Barnes West:</p>
                                                <p>Medical Oncology and Chemotherapy: <a href="tel:3149968270">(314) 996-8270</a><br/>
                                                Radiation: <a href="tel:3149963335">(314) 996-3335</a></p>
                                            <p className="boldLeadIn">Barnes South County:</p>
                                                <p>Main: <a href="tel:3142862500">(314) 286-2500</a><br/>
                                                Appointments: <a href="tel:3147477222">(314) 747-7222</a><br/>
                                                Toll Free: <a href="tel:8006003606">(800) 600-3606</a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic17;
