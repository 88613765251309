import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonEnemaWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonEnema">Giving an Enema</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Causes of Constipation</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Knowing the causes of constipation may help you prevent it in the person you provide care for. One thing to avoid is this: Do not give enemas regularly for constipation as this may cause the person to become dependent on an enema to have a bowel movement.</p>
                                        <p className="boldLeadIn">Common causes of constipation are:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Irregular bathroom habits and ignoring the urge to have a bowel movement. (This occurs with a person in pain or a person who is busy and in a rush with daily activities.)</li>
                                            <li>Chronic illnesses such as Parkinson’s disease, multiple sclerosis, depression, and chronic bowel disease.</li>
                                            <li>Eating a low-fiber diet high in animal fats (such as meats, cheese, and eggs).</li>
                                            <li>Not drinking enough liquids. A person should drink at least 8-10 eight-ounce glasses of liquids each day (unless not doctor recommended). Liquids should not include coffee or caffeinated drinks.</li>
                                            <li>Mental status changes preventing the person from being aware of the need to have a bowel movement (e.g. dementia, depression)</li>
                                            <li>Lack of regular exercise, restriction to bed</li>
                                            <li>Misuse of laxatives. Taking laxatives daily can lead to constipation.</li>
                                            <li>Older age</li>
                                            <li>Spinal cord disease</li>
                                            <li>Medicines such as iron supplements, pain relievers that contain codeine, antacids, calcium, anticholinergics, and antihistamines.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who Should Not Receive a Disposable Enema?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Enemabottle.png" className="img-responsive maxWidth300 floatNone" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Any person who has:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Ascites (fluid around the liver)</li>
                                            <li>Congestive heart failure (fluid backs up in the lungs and body causing swelling)</li>
                                            <li>Unstable angina (chest pain)</li>
                                            <li>Perforated bowel (hole in the bowel)</li>
                                            <li>Bowel obstruction (blockage of the bowel) or severe constipation</li>
                                            <li>Colitis - inflammation of the colon</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you think the person you care for has any of these conditions, talk with their doctor before giving an enema. The risk is created if the enema they receive contains sodium biphosphate and sodium phosphate, which cause the body to retain fluid and may cause life-threatening side effects.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Impaction</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When hardened stool (feces) wedges in the rectum, a person cannot pass the stool. The person will still have the urge to have a bowel movement. Often the person oozes liquid, diarrhea stool around the impaction. Thinking a person has diarrhea can result in the wrong treatment, worsening the impaction.</p>
                                        <p>Persons who are debilitated, confused and too weak or unaware of the need to have a bowel movement are most at risk for an impaction. If you suspect an impaction, call the person’s doctor.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonEnemaWhatYouNeedToKnow;