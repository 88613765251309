import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { useNavigate } from 'react-router-dom';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

const LwcInjectionSupportDrSookochoffLandingPage = () => {
	const encString = "f83680f9c32560cc767934a5d000ff5c:e18d0c591dc0f887d030e3e328ec7c2ded9bea817e0a92c8dcd22d49d097ab881d7e3dbea04c8d715475475a775edb9c5fd2f9872db2a0f8b65528efaa36c6d919db86eab637217176d3bf813bdb58d816286585a4de57835988832f7f78209a26186f3e1ecb2d681865b69345a5b0a0e32c926f98a6fe0bff750e0c189cba1c8e75077b29cc82ebbe980b9babd4c489";

	const opts = {
		height: '390',
		width: '640',
		playerVars: {
			autoplay: 0
		}
	};
	let navigate = useNavigate();
	const routeChange = () => {
		let path = `/register/qr/${encString}`;
		navigate(path);
	};

	/*
	if (window?.location.pathname === '/lwcinjectionsupportdrsookochoff') {
		import('./lwcinjectionsupportdrsookochoff.scss');
		require('./lwcinjectionsupportdrsookochoff.scss');
	}
	 */
	return (
		<div>
			<div className="w-100">
				<Container className="landingPage">
					<div className="w-100">
						<div className="col-12 m-auto">
							<div className="whiteBackground floatLeft clearLeft">
								<section>
									<div className="landingPage">
										<div className="landingLogo w-100 bg-white">
											<img
												src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/WashU+Ortho.png"
												className="img-responsive centerImage w-100 maxWidth300"
											/>
										</div>
										<div className="landingHero">
											<div className="col-12 landingHero lwcGeneralLandingBackgroundImg">
												<div className="w-100 content-center text-center landingHeader">
													<div className="col-12">
														<div className="w-100 d-flex content-center">

														</div>
													</div>
													<h1 className="">
														Receive your{" "}
														<span className="italic">
                                                            Injection Procedure Support Guide
                                                        </span>{" "}
														today!
													</h1>
													<h4 className="">
														This easy-to-use guide walks you through Injection Procedures
														offered by Washington University Orthopedics — including what to
														expect and key information that will help you on your journey
														toward a successful procedure and better health outcomes.
													</h4>
													<Nav.Link>
														<button
															onClick={routeChange}
															className="guideButton"
														>
															Activate My Guide{" "}
															<i className="fa-solid fa-chevron-right"></i>
														</button>
													</Nav.Link>
												</div>
											</div>
										</div>

										<div className="grayBG">
											<div className="col-12 graySection">
												<div className="w-100 grayContent text-center alignRight">
													<p>
														<AccessTimeIcon
															style={{fontSize: 40}}
															className="fa-icon"
														/>
														<br/>
														The{" "}
														<span className="italic">
                                                            Guide
                                                        </span>{" "}
														is your always-there source of information.
													</p>
												</div>
												<div className="w-100 grayContent text-center alignLeft">
													<p>
														<LaptopMacIcon
															style={{fontSize: 40}}
															className="fa-icon"
														/>
														<TabletMacIcon
															style={{fontSize: 40}}
															className="fa-icon"
														/>
														<SmartphoneIcon
															style={{fontSize: 40}}
															className="fa-icon"
														/>
														<br/>
														Available on your desktop, <br/>
														tablet and smartphone.
													</p>
												</div>
											</div>
										</div>

										<div className="col-12 subheadAndBullets footerSection">
											<div className="w-100">&nbsp;</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
export default LwcInjectionSupportDrSookochoffLandingPage;
