import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonPulseIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonPulse">Taking a Temperature</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonPulse/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonPulse/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A common measure of a person’s health is their body temperature. Normally an adult’s body temperature ranges between 96.8 to 100.4 degrees Fahrenheit (36 to 38 degrees on the Centigrade scale) depending on time of day and a person’s activity. Many different factors affect body temperature. No single temperature is normal for all people.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Why Measure Body Temperature?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are many reasons for taking a person’s temperature:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>To detect fever if a person feels ill</li>
                                            <li>To monitor the effects of medicine for lowering fever</li>
                                            <li>To check for an abnormally low temperature (hypothermia) of a person exposed to extreme cold</li>
                                            <li>To check for an abnormally high temperature (hyperthermia) of a person exposed to extreme heat</li>
                                            <li>To check the ovulation cycle of a woman wishing to become pregnant</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Normally the body maintains a balance of heat loss and gain, keeping a fairly constant temperature range.</p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                        Video: Taking A Tempurature
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="b9EIq9GSuvo" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonPulseIntroduction;