import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonGettingReadyWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonGettingReady">Getting Ready For Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonGettingReady/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonGettingReady/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonGettingReady/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonGettingReady/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Talk About the Surgery</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>After visiting the surgeon and preparing for surgery, talk with the person you care for about any questions or concerns he or she still has. Just being able to talk with someone else helps a person sort out any final thoughts or questions. Often, you will be able to answer questions because of what you have been able to learn.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Learn Exercises for After Surgery</h2>
                                    </div>
                                </div>
                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                    <div className="w-100">
                                        <div className="imageContainer">
                                            <div className="leftCol col-5 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GRS_02.png" className="img-responsive maxWidth300" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">&nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="rightCol col-5 paddingLeft7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GRS_03.png" className="img-responsive maxWidth300" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a person has general anesthesia and extensive surgery, there are limits to how quickly he or she will be able to resume normal activity. The nurses in the hospital will have the person do what are called Postoperative Exercises. These are activities or exercises that help prevent problems with breathing and circulation after surgery. The information in this lesson explains how to perform each of the exercises.</p>
                                        <p>The exercises include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Deep Breathing</li>
                                            <li>Incentive Spirometry</li>
                                            <li>Coughing</li>
                                            <li>Turning</li>
                                            <li>Leg Exercises</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder greenBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Why Exercises?</h2>
                                                <p>During general anesthesia the lungs do not expand fully and a person cannot cough, so mucous builds up in the airways. After surgery, many persons have incisions in their chest or belly, making it painful and thus difficult to cough the mucous out.</p>
                                                <p>This is an even bigger problem for a smoker who will have more mucous.</p>
                                                <p>Anesthesia also slows circulation.</p>
                                                <p>Because a person cannot move during surgery, the circulation in the legs slows, increasing the risk for clots to form in the legs.</p>
                                                <p>Clots are a serious risk for heart attack, stroke or a pulmonary embolus that blocks blood flow to the lung.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Benefits of Exercises</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Improve the expansion of the lungs</li>
                                            <li>Make it easier to cough</li>
                                            <li>Maintain good circulation in the legs</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The type of surgery affects the type of exercises the nurses will have a person perform. For example, a person who has lung surgery will be coached to perform all of the postoperative exercises. If a person is having surgery of the leg, he or she might not perform the leg exercises in their affected leg. Sometimes after surgery the doctor will have a person wear special elastic hose or compression stockings that improve circulation and decrease the risk of blood clots forming.</p>
                                        <p className="boldLeadIn">Practice Exercises</p>
                                        <p>Have the person you care for practice the exercises and help them understand their purpose before going to surgery. It will then be easier for them after surgery. Practice the exercises together 3 times a day. In the hospital, you can be a coach, reminding the person when and how to do their exercises correctly.</p>
                                        <p>Once a person goes home after surgery, have them continue performing the exercises. Each exercise helps a person to move towards full recovery.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Activities for The Day Before Surgery</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The surgeon will order activities for a person to follow on the day before surgery. Preparation for surgery depends on the type of surgery. Make sure that you understand what the surgeon has ordered so that you can help the person you care for complete the activities correctly and at the proper time.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="greenTextBackground boldLeadIn w-100">Food and Fluids</p>
                                        <p>Food and fluids are routinely held for a period of time before surgery. Holding food and fluids reduces the chance of the person vomiting during surgery.</p>
                                        <p><span className="bold">Know what the surgeon has ordered.</span> For example, a person having surgery on the bowel may be on a special diet several days before surgery and then placed on clear liquids a day before surgery. In cases of minor elective surgery, a person may be able to have clear liquids up to 2 hours before surgery and a light breakfast (e.g. tea and toast) 6 hours before surgery.</p>
                                        <p><span className="bold">Clear liquids include:</span> water, clear broth (chicken, beef or vegetable), apple juice, lemonade, clear beverages (Sodas, Tea, Coffee), Italian ices, plain Jell-O, and Popsicles without fruit. Avoid any red liquids, for example, red Jell-O or popsicles. These can be confused as blood if vomiting occurs.</p>
                                        <p>Most persons are not allowed to have food and fluids 4 to 8 hours before surgery if a general anesthetic is to be given. Often a nurse from a surgery center will call the day before to remind you what the person can eat or drink. Surgery can be cancelled if a person eats or drinks too soon before surgery.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="greenTextBackground boldLeadIn w-100">Medicines</p>
                                        <p>Follow the surgeon’s instructions and know if a person should take his or her regular medicines the day before surgery. The surgeon will instruct you on which medicines the person should take and not take. Sometimes a surgeon will have the person take antibiotics before surgery.</p>
                                        <p>When taking needed medicine, take it as long a time before the surgery as possible and with the least amount of water (not juice, coffee, etc.).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="greenTextBackground boldLeadIn w-100">Skin Preparation</p>
                                        <p>Depending on the surgery, some persons must bathe or shower the evening before and/or morning of surgery. Cleansing the skin reduces the number of germs on the skin and helps to reduce the risk of infection after surgery.</p>
                                        <p>The surgeon may order a person to bathe or shower more than once or to use a special antiseptic soap. If a person is having surgery to the head, neck, or upper chest, it is not unusual for the surgeon to order a shampoo. Be sure the person bathes or showers completely and follows the surgeon’s orders.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="greenTextBackground boldLeadIn w-100">Cleaning the Bowel</p>
                                        <p>A person having surgery on the bowel (colon),will have to take medicines (e.g. Miralax, GoLytely, Dulcolax tablets) to clean the bowel out before surgery. This prevents leaking of stool during surgery that could cause infection. It also prevents constipation after surgery.</p>
                                        <p><span className="bold">Take all of the medicines.</span> Make sure the person takes all of the medicines ordered and at the correct time, usually in the afternoon or evening. Some of the medicines require a person to drink a large amount of water or Gatorade over a short period of time. This is difficult if a person has trouble swallowing or becomes full easily. However, be sure the person drinks all of the liquids.</p>
                                        <p><span className="bold">If the person has indigestion, cramps, nausea, or vomiting</span> have them lie down and rest. Continue to have them drink fluids, but not coffee. After drinking the fluid the person will develop diarrhea, which will become almost clear once the bowel is cleaned out. This can happen quickly, or it could take several hours.</p>
                                        <p>After cleaning the bowel it is ok to drink clear liquids, unless otherwise ordered by the doctor, because the person should stay hydrated.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Pain control is needed for a person’s recovery. Be sure to talk with the person before surgery about the need to take pain medicine as prescribed by the doctor.</li>
                                            <li>Pain control makes it easier for a person to move, walk about, and begin normal day-to-day activities.</li>
                                            <li>Be aware that pain medicines may make a person sleepy, less steady when walking, or confused. Tell the nurses in the hospital if you notice any of these changes. The person may need extra assistance when getting out of bed to prevent a fall.
                                            </li>
                                            <li>The postoperative exercises cannot hurt a person, unless they are done incorrectly. If your friend or family member has surgery over the chest or abdomen (belly), be sure they splint or support the incision when they cough.</li>
                                            <li>When the person returns home after surgery, be sure the person follows all activity and diet instructions and takes their medicines as prescribed. See our lesson on <a href="/LessonAfterSurgery" target="_blank">Care After Surgery</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100 videoFlexContainer">
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Postoperative Exercises 1:19
                                            </p>
                                        </div>
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="O1UwrUW-HWk" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Breathing Exercises 03:37
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100 videoFlexContainer">
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Incentive Spirometry 01:36
                                            </p>
                                        </div>
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Controlled Coughing 03:34
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100 videoFlexContainer">
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Turning and Moving in Bed 03:37
                                            </p>
                                        </div>
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Leg Exercises 02:31
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right"></i></a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="equipment">EQUIPMENT</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GRS_05.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p><span className="fontWeight700">Incentive Spirometry</span></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom greenBullets">
                                                            <li>Incentive spirometer</li>
                                                            <li>Tissue</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GRS_06.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p><span className="fontWeight700">Controlled Coughing and Deep Breathing</span></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom greenBullets">
                                                            <li>Small Pillow</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonGettingReadyWhatToDoAndSafety;