import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import { Modal, Button } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css'
const imagesPool = [
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-PARK_062014.png'},
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-South_062014.png'},
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Day_of%20_Surgery_Map_Detail.jpg'},
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16th_Floor_Map_Detail.jpg'},
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-North_3rdLevelCam_062014.png'},
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-LINK_062014.png'},
    { src: 'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap_Extended062014.png'}
];



const SitemanPancreaticAll7_4 = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id) => setShow(true);

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Directions and Maps</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJC_signage.jpg" className="img-responsive w-100" />
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">GETTING TO BARNES-JEWISH HOSPITAL</h2>
                                            <p><em>1 Barnes Jewish Hospital Plaza St. Louis, MO 63110</em></p>
                                            <p>Barnes-Jewish Hospital is conveniently located in St. Louis, Missouri, one block north of the I-64/US40 and Kingshighway Boulevard intersection, across from Forest Park in the neighborhood known as the Central West End.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-PARK_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>Barnes-Jewish Hospital Parking Map</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-South_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>Hospital South</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Day_of_Surgery_Map_Detail.jpg" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>Day of Surgery Location Map</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16th_Floor_Map_Detail.jpg" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>16th Floor Map</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-North_3rdLevelCam_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>Barnes-Jewish Hospital Center for Advanced Medicine and Center for Outpatient Health, Floor 3</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-LINK_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>Map of Campus "Link"</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                                <p className="mobileMarginBottom30">The “Link” is a convenient way to navigate through the Campus’s many buildings. It stretches from South Campus parking garage up to the Forest Park Laclede parking garage.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap_Extended062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn"><a onClick={handleShow}>The Medical Center Campus</a>
                                                </p>
                                                <p className="mobileMarginBottom30"><em>click to view/download</em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <Modal show={show} onHide={handleClose} id={1}>

                <Modal.Header closeButton>
                    <Modal.Title>Barnes-Jewish Hospital Parking Map</Modal.Title>
                </Modal.Header>

                <Modal.Body><img className="w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-PARK_062014.png" alt="" /></Modal.Body>

                <Modal.Footer>

                    <Button variant="secondary" onClick={handleClose}>Close</Button>


                </Modal.Footer>

            </Modal>
            <Modal show={show} onHide={handleClose} id={1}>

                <Modal.Header closeButton>
                    <Modal.Title>Barnes-Jewish Hospital Parking Map</Modal.Title>
                </Modal.Header>

                <Modal.Body><img className="w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-PARK_062014.png" alt="" /></Modal.Body>

                <Modal.Footer>

                    <Button variant="secondary" onClick={handleClose}>Close</Button>


                </Modal.Footer>

            </Modal>
        </div>
    )



};

export default SitemanPancreaticAll7_4;
