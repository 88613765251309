import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonDressingWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDressing">Dressing A Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/DP_dressing.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Effects of Disease or Injury</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A variety of medical conditions can affect peoples’ ability to dress themselves. For example, a person who has had surgery or injury to an arm or leg will find it difficult to dress the affected body part. Diseases of the joints such as arthritis, make it painful to bend or twist an arm or leg during dressing. The joints of the hands are often limited in motion and it is painful to try to grasp and pull on clothing.</p>
                                                <p><span className="bold italic">Neurological diseases such as stroke, Parkinson’s, or Multiple sclerosis</span>, cause either a loss of strength, balance or function of the arms or legs, making it difficult to dress. In Parkinson’s disease, rigidity and stiffness tend to freeze movement, which makes it difficult to put on and remove clothing. Also, Parkinson’s and its treatment can cause choreiform movement or rapid jerky movements that also make dressing difficult.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead26px"><i className="fa fa-exclamation-circle coralColor"></i>&nbsp;A person who has had a stroke may be unable to move an arm or leg or have serious problems with balance when standing to put on their pants. Neurological conditions can affect the hands, making it difficult to fasten buttons, zippers or closures on clothing.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p><span className="bold italic">Dementia affects a person’s ability to think</span> and make decisions. The condition usually progresses, and at first a person will likely not need help with dressing or other daily care activities. Gradually over time, people with dementia might begin to not keep clothes clean or they will select clothes that do not go together, like a pair of shorts and a sweater when it is cold and raining outside. The simple chore of making choices about what to wear can become too much. A person may forget to change clothes when dirty or forget how to put on a clothing item as their dementia progresses.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Occupational Therapy</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A person who needs help with dressing might also require help with other self-care activities (e.g toileting, eating), work activities or leisure activities (e.g. writing, performing a hobby). If so, the person you care for may benefit from a referral to an occupational therapist (OT).</p>
                                                <p>An OT helps people improve their ability to perform daily tasks in their home and at work. A therapist will be able to provide ways that help a person to regain function, maintain level of functioning, or adjust to any deficits he or she have. For example, an OT might suggest adaptive devices that make it easier for a person to dress. The goal of an OT is to help clients have independent, productive, and satisfying lives.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDressingWhatYouNeedToKnow;
