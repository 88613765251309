import {ORBIT_LIST_BY_ORGANIZATION, ORBIT_ERROR, SET_DEFAULT_ORBIT} from "../actions/types";

const INITIAL_STATE = {
    errorMessage: '',
    orbitList: [],
    defaultOrbit: null
};

export const orbitReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORBIT_LIST_BY_ORGANIZATION:
            return {
                ...state,
                errorMessage: '',
                orbitList: action.payload.orbitList
            };

        case SET_DEFAULT_ORBIT:
            return {
                ...state,
                errorMessage: '',
                defaultOrbit: action.payload.defaultOrbit
            }

        case ORBIT_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
