import {TILE_PAGE, TILE_PAGE_ERROR} from "../actions/types";

const INITIAL_STATE = {
    errorMessage: '',
    fileName: ''
};

export const tilePageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TILE_PAGE:
            return {
                ...state,
                errorMessage: '',
                fileName: action.payload.fileName
            };

        case TILE_PAGE_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
