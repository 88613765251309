import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll8_5 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Audiology</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <center>
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Cochlea.jpg" className="img-responsive maxWidth500 centered" />
                                            </center>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><br/>The cochlea (inner ear) is very sensitive to certain life-saving drugs (like chemotherapy) and radiation used to treat cancer and other diseases. These drugs may cause permanent sensorineural hearing loss.</p>
                                            <h2 className="subheadUpperAndLowerColorBarIcon"><i className="far fa-check-circle entBaseBlueColor"></i> <span>Some hearing loss can be restored with hearing aids, surgery, or assistive devices.</span></h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If hearing loss does occur, our Audiology team will work with you for a thorough evaluation and determine the appropriate treatment specific to your needs. </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll8_5;
