import React from "react";

function L5ComponentCode({title}) {
    title = 'Your Journey';

    return (

        <div className="lego l005">
            <div className="pillWithBackground">
                <h2 className="l5SubheadWithBackground">Subhead Goes Here</h2>
            </div>
        </div>

    )
};


export default L5ComponentCode;