import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTALL4_1_2 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Drains: Tube Feeding'}, () => {
            console.log('Drains: Tube Feeding');
        });
        await dispatch(pal);
    }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center">Feeding
                                        Tubes</h1>
                                </div>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                <div className="w-100">
                                    <div className="type3AcrossContainer boxes">
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link className="" to="#typesFeedingTubes">Types
                                                of Feeding Tubes</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link className="" to="#feedingMethods">Feeding
                                                Methods</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link className="" to="#givingMedicines">Giving
                                                Medicines</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link className="" to="#oralCare">Oral
                                                Care</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link className="" to="#cloggedFeedingTubes">Clogged
                                                Feeding Tubes</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link className="" to="#otherIssues">Other
                                                Issues</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Feeding Tube Use and Home Care</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="italic">For review only if you are using a feeding tube as
                                        part of your care. </p>
                                    <p>Tube feedings help many people get their daily nutrition needs or add to
                                        their oral diet. Some people take only tube feedings, while others add
                                        tube feedings to their diet. Tube feedings help us heal by providing
                                        adequate nutrition and calorie intake. This will help you with wound
                                        healing, gaining strength and maintaining a healthy weight. </p>
                                    <p>In regular eating, food goes into the mouth, down the esophagus, and into
                                        the stomach. With tube feedings, formula goes directly into the stomach
                                        or small intestine through a feeding tube bypassing your surgical
                                        sites.</p>
                                    <p className="italic">Upon discharge </p>
                                    <p>You’ll be sent home with enough tube feeding supplies to last a couple of
                                        days while waiting for your home health delivery.</p>
                                    <p>These include:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Two Tube Feeding Bags</li>
                                        <li>2 60 ml GU Syringes</li>
                                        <li>2 Graduated Cylinders</li>
                                        <li>Tube Feeding Education Book and CD</li>
                                        <li>Gauze Dressings and Tape if indicated</li>
                                        <li> A bottle of wound cleanser</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>The tube feeding formula will be delivered to the room or to the home
                                        depending on the home health company.</p>
                                    <p>If the patient is leaving on a continuous tube feeding the pump will
                                        either be delivered to the room or to the home depending on the home
                                        health company.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="typesFeedingTubes" className="subheadUpperAndLowerColorBarIcon">Type
                                                    of Feeding Tubes</h3>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="headline w-100">Naso-Gastric Tube
                                        (NG-Tube)</h2>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="subheadAndBulletsWithFloat">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <div className="col-4 imgCol noPaddingLeft">
                                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nasogastric%2Btube-png.png" alt="" />
                                                            </div>
                                                            <div className="col-8 typeCol noPaddingLeft">
                                                                <p className="captionBold">This is a Naso-Gastric Tube
                                                                    (“NG-tube”)</p>
                                                                <p className="mobileMarginBottom30">This tube is often used
                                                                    for short-term use. It is inserted into one of the
                                                                    nostrils of the nose, down the back of the throat,
                                                                    through the esophagus and into the stomach. Medicine,
                                                                    water, and tube feedings can be given through the
                                                                    tube. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100 text-center">
                                                            <h2 className="subheadUpperandLower text-center w-100">If you have an NG tube:</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom">
                                                                <li>Please <strong><em>avoid blowing your
                                                                            nose.</em></strong></li>
                                                                <li>Always check for a change in length of the tube before
                                                                    giving medicines or tube feedings. If the tube looks
                                                                    longer than normal, the tip may not be in the
                                                                    stomach. <strong><em>You should not use the tube if this
                                                                            occurs.</em></strong> Call your doctor. The tube may
                                                                    need to be replaced by your doctor before you can use it
                                                                    again.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperandLower text-center w-100">To
                                                                prevent the tube from moving, secure
                                                                it to the nose with tape.</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100 text-center">
                                                            <p className="text-center w-100">Please <strong><em>do not
                                                                        tape</em></strong> the tube so it is pressing up against the
                                                                nostril.<br />This can cause a sore to form on your nostril.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperandLower text-center w-100">If the
                                                                tape is soiled or becoming loose,
                                                                change the tape:</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom">
                                                                <li>Wash your hands and have a roll of 1” silk tape ready.
                                                                </li>
                                                                <li>Gently remove the old tape from the nose and tube taking
                                                                    extra care not to pull on the tube.
                                                                </li>
                                                                <li>Clean the tip of the nose with soap and water or
                                                                    alcohol. Rinse well.
                                                                </li>
                                                                <li>Pat the area dry.</li>
                                                                <li>Cut the tape: using 1” silk tape, cut about a 4-inch
                                                                    strip. Tear it down the middle about halfway forming a
                                                                    piece that looks like a pair of pants. Cuff the tape at
                                                                    the ends (like legs of pants) by folding it back onto
                                                                    itself forming ½” cuffs. Using a “cuff” on the tape will
                                                                    make it easier to remove the tape at the next tape
                                                                    change.<br />
                                                                    <strong><em>Note:</em></strong> the length of the tape
                                                                    may vary depending on the size of your nose
                                                                </li>
                                                                <li>Secure the tape to the nose.</li>
                                                                <li>Wrap the strips (legs) of the tape around the tube to
                                                                    secure the tube to the nose.<br />
                                                                    <strong><em>Note:</em></strong> some people prefer
                                                                    another piece of tape across the top of the nose to help
                                                                    secure
                                                                    the tube.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p>This tape should be changed whenever loose, wet or dirty,
                                                                taking extra care not to pull the tube out.</p>
                                                            <p><strong><em>Note:</em></strong> if your NG tube has stitches
                                                                in the nostrils, keep them clean and watch for signs of
                                                                infection: increased redness, swelling, pain, drainage, or
                                                                fever of 101<sup>◦</sup> or higher.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 video">
                                                        <div className="w-100">
                                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/NG-adhesive-tape.jpg" alt="this image was pulled from google" className="img-responsive w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="text-center w-100">
                                                            <h2 className="subheadUpperandLower text-center w-100">Care of
                                                                the Nose</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom">
                                                                <li>Wash your hands</li>
                                                                <li>Moisten a cotton swab with warm water and clean the
                                                                    edges of both nostrils
                                                                </li>
                                                                <li>Apply a water-based lubricant, like K-Y Jelly, to the
                                                                    nostril around the tube if you wish. Call your doctor if
                                                                    the nostril is red or bleeding or feels numb.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                        <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="headline w-100">Gastrostomy Tube
                                        (G-Tube)</h2>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="subheadAndBulletsWithFloat">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <div className="col-4 imgCol noPaddingLeft">
                                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/G%2Btube%2Bpercutaneous%2Bdiagram.png" alt="" />
                                                            </div>
                                                            <div className="col-8 typeCol noPaddingLeft">
                                                                <p className="captionBold">This is a Gastrostomy Tube
                                                                    (“G-tube”)</p>
                                                                <p className="mobileMarginBottom30">This tube is used for
                                                                    longer-term use. It is inserted through the abdominal
                                                                    wall directly into the stomach. Medicine, water, and
                                                                    tube feedings can be given through the tube. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperandLower text-center w-100">The
                                                                dressing changes every day </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>1.</strong> Wash your hands with soap and water to
                                                                prevent infection. </p>
                                                            <p><strong>2.</strong> Assemble equipment:</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom">
                                                                <li>4” x 4” gauze or drain sponge (cut in half)</li>
                                                                <li>Scissors (if needed to cut gauze square)</li>
                                                                <li>Tape</li>
                                                                <li>Mild soap and clean water</li>
                                                                <li>Clean towel and washrag</li>
                                                                <li>Hydrogen peroxide (if ordered by your doctor)</li>
                                                                <li>Antibiotic ointment (if ordered by your doctor)</li>
                                                            </ul>
                                                            <br/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p><strong>3. </strong>Remove
                                                                the old dressing carefully taking care not to pull the tube
                                                                forward.</p>
                                                            <p>
                                                                <strong>Note:</strong> check to be sure the stitches are
                                                                secure. Do not use the tube if the stitches are not holding
                                                                the tube in place. If it has slipped outward, call the
                                                                doctor. </p>
                                                            <p><strong>4.</strong> Clean around the tube with mild soap and
                                                                water using a Q-tip or clean wash cloth to prevent crusting
                                                                around the tube. Start at the tube and work outward in
                                                                circles. </p>
                                                            <p><strong>5.</strong> Rinse with warm water and allow to dry
                                                                completely</p>
                                                            <p><strong>6.</strong> Pat skin dry.</p>
                                                            <p><strong>7.</strong> Place a small amount of antibiotic
                                                                ointment around the tube only if ordered by your doctor.</p>
                                                            <p><strong>8.</strong> Put a
                                                                4” x 4” gauze pad on the skin where the tube goes in.</p>
                                                            <p><strong>Note:</strong> if a drain
                                                                sponge is not available, you may make one by cutting a slit
                                                                halfway through the 4” x 4” gauze. Cut the gauze away from
                                                                the tube to prevent cutting the tube or the skin. </p>
                                                            <p><strong>9.</strong> Tape the gauze in place, making sure the
                                                                tube is not kinked</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperandLower text-center w-100 mb-0">Bathing</h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom">
                                                                <li>Take showers only – no tub baths.</li>
                                                                <li>Do not get insertion site wet.</li>
                                                                <li>Cover G-tube dressing with 2 layers of plastic wrap. You
                                                                    can shower regularly after 14 days.
                                                                </li>
                                                                <li>Tape the edges before you shower.</li>
                                                                <li>Remove plastic wrap, and change dressing after you
                                                                    finish your shower.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h3 className="subheadUpperAndLowerColorBarIcon entYellowColorBarBackground text-black">
                                                                <i className="fa fa-exclamation-circle "></i>&nbsp;&nbsp;While
                                                                caring for the tube, watch for signs of infection:
                                                            </h3>
                                                            <div className="col-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom">
                                                                        <li>Increased redness</li>
                                                                        <li>Swelling</li>
                                                                        <li>Pain</li>
                                                                        <li>Drainage</li>
                                                                        <li> Fever of 101<sup>◦</sup> or higher</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperandLower text-center w-100">Activities </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <p className="text-center w-100">A feeding tube
                                                                does not limit your usual activities. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="feedingMethods" className="subheadUpperAndLowerColorBarIcon">Feeding
                                                    Methods</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>After the feeding tube is placed, your doctor will decide which tube
                                        feeding method will meet your needs. Feeding schedules may vary from
                                        one person to another.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center w-100">Types of Feeding
                                        Methods:</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower entBlueColor">BOLUS
                                        feedings:</h2>
                                    <p>Formula is given several times a day over short periods of time. This
                                        may be done by dripping the feeding through a feeding bag or through
                                        a syringe. Bolus feeding is most common type of feeding method.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower entBlueColor">CONTINUOUS
                                        feedings:</h2>
                                    <p>A pump moves the formula through the feeding tube and into the
                                        stomach or small intestine. If you have a feeding tube that goes
                                        into the small intestine, you must use a pump to deliver formula at
                                        a slow, continuous feeding rate. This is because the small intestine
                                        cannot hold as much formula as the stomach. These feedings are given
                                        slowly over the course of the day and/ or night. Some feedings are
                                        given only during the night. </p>
                                    <p><em><strong>Note:</strong> since the feeding pump you use at home is
                                            different than the one used in the hospital, your home health nurse
                                            will teach you how to use it.</em></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower entBlueColor">SYRINGE
                                        feedings: </h2>
                                    <p>Feeding method in which formula flows from a syringe into the feeding
                                        tube or is injected slowing into the feeding tube with syringe. </p>
                                    <p><em><strong>Note:</strong> this method is sometimes used when a
                                            person has been on tube feedings for a long time. Your home health
                                            nurse will teach you this method should you change from the gravity
                                            drip method to this method.</em></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center w-100">How
                                        to Perform a Tube Feed.</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">A) Preparing for the feeding: </h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>1) Check proper location of
                                        feeding tube.</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Check to make sure the feeding tube is in the stomach before
                                            each feeding.
                                        </li>
                                        <li>Check the placement mark on the tube before starting the feeding
                                            to be sure the tube has not gotten longer. If the tube is coming
                                            out, it will be longer than it was the last time you checked. Do
                                            not use it. Call your doctor.
                                        </li>
                                        <li>Some people use a ruler to measure the tube from the insertion
                                            site to the end of the tube. The new measurement should be about
                                            the same as the day before. If the measurement is different, do
                                            not give the feeding. Call your doctor.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>2) Wash your hands. Always wash your hands with soap and water before
                                        fixing the formula and before touching the feeding tube, tube site,
                                        or feeding equipment. Rinse well and dry with a clean towel. </p>
                                    <p>3) Before starting your next tube feeding, you should check the
                                        residual amount of left over tube feeding within your stomach. To
                                        complete this you will aspirate the contents of your stomach through
                                        your G-Tube using the GU Syringe. </p>
                                    <p>4) Prepare equipment:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Measuring container</li>
                                        <li>60ml catheter tip syringe</li>
                                        <li>Formula</li>
                                        <li>Feeding bag</li>
                                        <li>Clean, warm water</li>
                                        <li>Pole</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>5) Shake the formula can well.</p>
                                    <p>6) Wipe the top of the can with a clean cloth before opening. </p>
                                    <p>7) Pour and measure the formula
                                        in a clean, marked canister provided, or a measuring cup</p>
                                    <p><em><strong>Note:</strong> the formula
                                            should be at room temperature. Take it out of the refrigerator 15 to
                                            30 minutes before the next feeding. It should be kept out of direct
                                            sunlight. If only part of the formula is used,
                                            the rest of the product should be covered, dated and refrigerated.
                                            Formula should not be left out at room temperature for more than 4
                                            hours. Throw away refrigerated formula after 24 hours. If unable to
                                            refrigerate, throw away leftover formula.</em></p>
                                    <p>8) Close the roller clamp by rolling the clamp downward. </p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="vJ2lT23UAk8" opts={opts} onPlay={playVideo} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Tube Feeding</span></h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center">B) Giving the feeding: </h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>1) Open the cap on the top of the bag and pour the proper amount of
                                        formula into the feeding bag. Replace the cap. </p>
                                    <p>2) Hang the bag on the pole. If you don’t have a pole, the bag must be
                                        hung over your head. </p>
                                    <p>3) Remove the tubing cap. </p>
                                    <p>4) Squeeze the bag until the tube feeding formula meets the tip of the
                                        tube feeding line.</p>
                                    <p>5) Fill the tubing by pushing the roller clamp upward. This will allow
                                        the formula to drip. </p>
                                    <p>6) Close the roller clamp to stop the
                                        flow by pushing the roller clamp downward. </p>
                                    <p><em><strong>Note:</strong> the air in the
                                            tubing will not hurt you. It will, however, be easier to control the
                                            drip rate with the air first removed from the tubing.</em></p>
                                    <p>7) Replace the tubing cap. </p>
                                    <p>8) Sit upright in a chair. Do not
                                        feed yourself while lying flat. If unable to sit up in a chair, you
                                        should be sitting upright in bed. </p>
                                    <p><em><strong>Note:</strong> if you have a
                                            tracheostomy tube, you should suction or cough out secretions before the
                                            feeding. Strong coughing with a full stomach might cause vomiting.</em>
                                    </p>
                                    <p>9) Attach the gravity feeding tube set to the port (or larger port of the
                                        feeding tube if there are two).</p>
                                    <p>10) Open the roller clamp to start
                                        the formula dripping by rolling the clamp upward. </p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li> The feeding tube should take 30 to 60 minutes to complete depending
                                            on the amount of the feeding to be given.
                                        </li>
                                        <li>Giving the feeding too fast may cause you to be sick to your stomach
                                            or may cause vomiting, diarrhea, and/or cramping.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>11) Give the water flush</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>When the formula is all in, push down the roller clamp to stop the
                                            flow.
                                        </li>
                                        <li>Cap the feeding bag tubing.</li>
                                        <li>Pour the water flush into the feeding bag. Squeeze the chamber a few
                                            times to half fill it.
                                        </li>
                                        <li>Uncap the feeding bag and push up the roller clamp to fill the
                                            tubing.
                                        </li>
                                        <li>Push down the roller clamp when water has filled the tubing to stop
                                            the drip.
                                        </li>
                                        <li>Put the feeding bag tubing on the feeding tube port.</li>
                                        <li>Push the roller clamp to start the flow.</li>
                                        <li>Flush at the same rate you gave the formula.</li>
                                        <li>You also have the option of not disconnecting the feeding bag and
                                            adding the water flush directly to the tube feeding bag while still
                                            connected to your feeding tube to complete this process.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>12) When the water is all in, push the roller clamp down to stop the
                                        flow. </p>
                                    <p>13) Remove the tubing from the feeding tube. </p>
                                    <p>14) Re-cap the feeding tube. </p>
                                    <p>15) Continue to sit up or remain
                                        upright for 30 to 60 minutes after the feeding is completed.</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>If able to, get up and walk around as that may help to empty your
                                            stomach out.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>16) Clean the tube feeding bag:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Flush and rinse out the feeding bag and tubing with warm water.</li>
                                        <li>Hang it upside down to dry.</li>
                                        <li>Recap and store the dry bag in the refrigerator until needed for the
                                            next feeding. Use a new feeding bag every day.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="givingMedicines" className="subheadUpperAndLowerColorBarIcon ">Giving
                                                    Medicine Through Your Feeding Tube</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Medicine is given through the feeding tube when it cannot be
                                                taken by mouth. Medicines may be given through tubes if they are
                                                in liquid form or crushed and dissolved in water.</p>
                                            <p><em><strong>Note:</strong> some medicines cannot be given per
                                                    tube as they should not be crushed. For example, do not crush
                                                    enteric coated, sublingual (under the tongue), and time-release
                                                    medicines or capsules.</em></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="givingMedicines" className="subheadUpperAndLowerColorBarIcon entYellowColorBarBackground text-black"><i className="fa fa-exclamation-circle "></i>&nbsp;&nbsp;Do not
                                                crush or give any medicines before first checking with your
                                                doctor, nurse or pharmacist</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>How to give medicine through your feeding tube:</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>1) Wash your hands</p>
                                            <p>2) Gather the equipment:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>60ml catheter tip syringe</li>
                                                <li>Medicine crusher</li>
                                                <li>Clean cup</li>
                                                <li>Fresh warm water</li>
                                                <li>Towel or pad</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>3) Crush the medicine into a
                                                fine powder using:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Rolling pin and envelope or zip lock bag, or mortar and
                                                    pestle.
                                                </li>
                                                <li><em><strong>Note:</strong> you may get a medicine crusher
                                                        from a drug store or medical supply company.</em></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>4) Mix the powder in clean
                                                cup using 30-60ml warm water. Dissolve well. </p>
                                            <p>
                                                <em><strong>Note:</strong> do not dissolve crushed medicine in
                                                    tube feeding formula. <strong>Do not ever put medicine in
                                                        the feeding bag.</strong></em></p>
                                            <p>5) Draw up all of the solution (crushed medicine + water) into
                                                the syringe by placing the syringe tip in the medicine and
                                                pulling the plunger back. </p>
                                            <p>6) Remove cap from the tube and slowly push the solution into the
                                                larger opening of the feeding tube. </p>
                                            <p>7) Flush the tube again with
                                                30-60ml of warm water.</p>
                                            <p>
                                                <em><strong>Note:</strong> remember to always flush the tube
                                                    before and after giving medicine to avoid clogging the
                                                    feeding tube.</em></p>
                                            <p>8) Re-cap the tube.</p>
                                            <p>9) Rinse your syringe out well with hot water to clean it. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">Important things to
                                                    know
                                                    BEFORE giving medication in your tube. </h2>
                                            </div>
                                            <div className="withBorder entBlueBorderColor">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>Some medicines can be given together. Some must be
                                                            given alone. Ask your pharmacist about this before
                                                            mixing together.</p>
                                                        <p>Some medicines may need to be given on an empty
                                                            stomach. Some medicines need to be given with food.
                                                            If you have questions, ask your doctor, nurse or
                                                            pharmacist. </p>
                                                        <p>Medicines that are to be given under the tongue
                                                            (sublingual) or extended release medications should
                                                            not be crushed and given in the feeding tube. </p>
                                                        <p>Exact measuring of medicine is important. Always read
                                                            the amount of solution drawn into the syringe
                                                            properly. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="oralCare" className="subheadUpperAndLowerColorBarIcon ">Oral
                                                    Care</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Good oral care is a must when not eating by mouth. <em><strong>When
                                                        not eating orally, the inside of the mouth can change.
                                                        Infections can occur.</strong></em> Daily brushing of your
                                                teeth, gums and tongue must be done to prevent this. Your lips
                                                should be kept moist with lip balm to prevent cracking.</p>
                                            <p>If you have had oral surgery, ask your doctor when and how oral
                                                care can be done. </p>
                                            <p>Report to your doctor if you notice a new sore in your mouth,
                                                bleeding, a white coating on your tongue, or any other mouth
                                                problems.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="cloggedFeedingTubes" className="subheadUpperAndLowerColorBarIcon ">Clogged Feeding
                                                    Tube</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you are unable to push water with a syringe or drip formula
                                                through a feeding tube, the feeding tube may be clogged.</p>
                                            <p>Things that can clog a
                                                feeding tube: </p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Medicine that is not properly crushed or dissolved.</li>
                                                <li>Formula or medicine that has not been flushed from the
                                                    feeding tube properly.
                                                </li>
                                                <li>Formula or medicine that has been sitting in the tube for a
                                                    long period of time.
                                                </li>
                                                <li>Giving medicine mixed with other medicines that should not
                                                    be mixed together.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder">
                                                <p><strong>How to fix a clogged feeding tube:</strong></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom">
                                                            <li><strong><em>Remove the feeding tube
                                                                        cap.</em></strong> Draw out any feeding formula
                                                                or medicine solution in the feeding tube using a
                                                                60ml catheter tip syringe.
                                                            </li>
                                                            <li>Try to gently <strong><em>inject 10ml fresh warm
                                                                        water</em></strong> into the larger opening of
                                                                the tube. Gently pull back on the syringe
                                                                plunger and remove as much fluid as possible.
                                                                Gently move the plunger back and forth to clear
                                                                the clog. Do not force water into the tube.
                                                                Recap the tube. Wait 5 minutes. Try to gently
                                                                flush with water again until the clog is
                                                                cleared.
                                                            </li>
                                                            <li><strong><em>Attempt to unclog the feeding tube
                                                                        every 10 minutes.</em></strong> If the tube does
                                                                not unclog in 1 hour, notify your home health
                                                                nurse or doctor for help. The tube will have to
                                                                be replaced if it cannot be unclogged.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p><em><strong>Note:</strong> trying to push the water in the
                                                        tube too hard can damage the feeding tube. You may need to
                                                        ask your home health nurse for assistance.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="otherIssues" className="subheadUpperAndLowerColorBarIcon ">Other
                                                    Issues</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100 entBlueColor">High
                                                residuals</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Definition:</strong> a large amount of fluid in your
                                                    stomach left over from the last feeding.</em></p>
                                            <p><em><strong>Possible causes:</strong> stomach not emptying fast
                                                    enough.</em></p>
                                            <p><em><strong>What to do:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Check residuals as instructed before feeding. Do not feed if
                                                    residuals are over 120mls. Wait an hour and try again. If
                                                    the residual is still over 120mls, wait until the next
                                                    feeding.
                                                </li>
                                                <li>If able, get up and walk around. Walking may help the
                                                    stomach to empty, lowering the residual.
                                                </li>
                                                <li>If high residuals are keeping you from taking 1 or more of
                                                    your daily feedings, ask your home health nurse or doctor
                                                    about this.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100 entBlueColor">Diarrhea</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Definition:</strong> tube feeding formula can make
                                                    usual bowel movements soft. Diarrhea is frequent, or more
                                                    frequent than usual, passing of watery bowel movements, often
                                                    larger in size. </em></p>
                                            <p><em><strong>Diarrhea can cause
                                                        dehydration. Signs of dehydration are:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>More thirst than usual</li>
                                                <li>Dry mouth or eyes</li>
                                                <li>Sunken eyes</li>
                                                <li>Urinating less</li>
                                                <li>Smaller amounts of urine</li>
                                                <li>Dark urine</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Call your doctor or home health nurse if you have these signs.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Possible
                                                        Causes:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Medicines such as stool softeners</li>
                                                <li>Antibiotics</li>
                                                <li>Liquid medicines that contain sorbitol</li>
                                                <li>Bacteria in the feeding from spoiled or contaminated
                                                    formula
                                                </li>
                                                <li>Contaminated tube feeding equipment</li>
                                                <li>A virus or infection of your bowels</li>
                                                <li>Feedings given too fast</li>
                                                <li>Lack of fiber in tube feeding formula</li>
                                                <li>Certain bowel diseases</li>
                                                <li>Feeding tube that has moved from the stomach into the small
                                                    intestine.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>What to
                                                        do: </strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Be sure to use good handwashing</li>
                                                <li>If taking laxatives or stool softener, ask your doctor if
                                                    you should stop taking them.
                                                </li>
                                                <li>Be sure to dilute medicines with water as instructed.</li>
                                                <li>Be sure to clean and store supplies as instructed.</li>
                                                <li>Keep unused, opened formula covered in the refrigerator.
                                                    Discard if not used in 24 hours.
                                                </li>
                                                <li> Feed formula more slowly.</li>
                                                <li>Check proper location of feeding tube (see <strong><em>“How
                                                            to Perform a Tube Feed”</em></strong> on this page).
                                                </li>
                                                <li>Change to a formula with fiber or to a different formula.
                                                    Ask your dietician for help.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><i className="fa fa-exclamation-circle entDiagnosisSurgeryRedColor" aria-hidden="true"></i>&nbsp;&nbsp;If diarrhea continues for 1
                                                to 2 days, call your doctor. </p>
                                            <p><em><strong>Note:</strong> if you have heart, kidney, liver or
                                                    bowel problems, ask your doctor before increasing or decreasing
                                                    fluids.</em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100 entBlueColor">Constipation</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Definition:</strong> no bowel movements for more than
                                                    3 days; hard or difficult to pass stools, or any pattern that is
                                                    unusual for you. Constipation can make you feel full and
                                                    uncomfortable, and it can cause a loss of appetite.</em></p>
                                            <p><em><strong>Possible
                                                        Causes:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Not enough fluid intake</li>
                                                <li>Skipping feedings</li>
                                                <li>Not enough activity</li>
                                                <li>Too much or too little fiber</li>
                                                <li>Some medicines <em>(for example: narcotic pain
                                                        medication)</em></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>What to
                                                        do:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Be sure that you are taking full amounts of tube feeding and
                                                    water flushes
                                                </li>
                                                <li>Exercise or walk around if you can. If unable to get out of
                                                    bed or change positions, discuss with your doctor or
                                                    physical therapist what exercises you might do.
                                                </li>
                                                <li>If your doctor says it is okay, try ½ cup of prune juice
                                                    flushed with ½ cup water down the tube 1 to 2 times daily.
                                                </li>
                                                <li>Call your dietician for ideas for changing feeding formulas,
                                                    flushes, or fiber sources.
                                                </li>
                                                <li>Decrease narcotic pain medication if possible.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Call your doctor or
                                                        home health nurse:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>If you have not had a bowel movement in over 5 days despite
                                                    the above efforts.
                                                </li>
                                                <li>If your abdomen is firm or larger than usual.</li>
                                                <li>If you have signs of dehydration: dark urine, smaller
                                                    amounts of urine, urinating less, dry mouth and/or eyes, or
                                                    more thirst than usual.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Note:</strong></em>if
                                                you have heart, kidney, liver or bowel problems, ask your doctor
                                                before increasing or decreasing fluids. </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100 entBlueColor">Nausea
                                                and vomiting</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>Definition:</strong> nausea is feeling sick to your
                                                    stomach. Vomiting is throwing up stomach fluids. Vomiting can
                                                    lead to dehydration. </em></p>
                                            <p><em><strong>Possible
                                                        Causes:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Slow emptying of the stomach</li>
                                                <li>Some medicines</li>
                                                <li>Blockage in the bowel</li>
                                                <li>Tube feeding given too fast</li>
                                                <li>Kind of tube feeding formula</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em><strong>What to
                                                        do:</strong></em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Do not give the tube feeding if you are full or sick to your
                                                    stomach. Wait 1 hour and see how you feel.
                                                </li>
                                                <li>Check residual before the feeding and do not take feeding if
                                                    residual is over 120ml.
                                                </li>
                                                <li>Note what led to nausea and vomiting.</li>
                                                <li> Do not give tube feeding if vomiting.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>
                                                <em><strong>Notes:</strong></em></p>
                                            <p>If you feel you are going to vomit, uncap your tube and let
                                                stomach fluid drain out.</p>
                                            <p>Call your home health nurse or dietician if nausea or vomiting
                                                keep you from getting your full feedings and water amount for
                                                the day.</p>
                                            <p>If you have heart, kidney, liver or bowel problems, call your
                                                doctor before increasing or decreasing fluids or changing
                                                formula.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTALL4_1_2;
