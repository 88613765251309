import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonSuppositories = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonSuppositories">Suppositories</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonNebulizers/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonNebulizers/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are times when a person has a health problem that requires a medicine to be taken by way of the rectum. A rectal suppository is a medicine mixed with a wax-like substance to form a semi-solid, bullet-shaped form that melts after being placed into the rectum. Rectal suppositories are very safe, because they cause little irritation to the walls of the rectum and have few side effects.</p>
                                        <p className="bold">What Conditions Might Require a Rectal Suppository?</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>A person who is sick to the stomach or too ill to take medicines by mouth</li>
                                            <li>A person who has an infection or inflammation of the rectum</li>
                                            <li>A person who needs medicine for constipation, to help them have a bowel movement</li>
                                            <li>A person who needs medicine for hemorrhoids that are swollen and painful</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Giving a rectal suppository is easy, but it must be done correctly. Improper placement often results in the person passing the medicine out of the rectum before it has time to dissolve and take effect. Whenever giving medicine to someone, be sure you know the purpose of the medicine, the correct dose, and the expected effects.</p>
                                        <p>For another reference, see our lesson on <a href="/LessonGivingMedicines" target="_blank">Giving Medicines Safely</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="hearTheExpert">HEAR THE EXPERT</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Giving a rectal suppository is easy, but it must be done correctly. Improper placement often results in the person passing the medicine out of the rectum before it has time to dissolve and take effect.</p>
                                    </div>
                                </div>
                                <div className="col-12 video">
                                    <div className="w-100">
                                        <h3 className="blueColor videoTitle w-100 text-center">Video: Rectal Suppositories</h3>
                                        <div className="floatLeft w-100 captionBelow">
                                            <div className="embedContainer">
                                                <YouTube videoId="-hMZDePH1n8" opts={opts} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonSuppositories;