import React from "react";

function L14CComponentCode() {

    return (

        <div className="lego l00paragraph">
            <p><a href="tel:636-667-7493">636-667-7493</a></p>
        </div>

    )
}

export default L14CComponentCode;