import { CURRENT_USER, UPDATE_CURRENT_USER } from "../actions/types";

const INITIAL_STATE = {
  user: {},
};

export const currentUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };

    case UPDATE_CURRENT_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }

    default:
      return state;
  }
};
