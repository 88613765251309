//added line to force a commit
const dev_server = {
    url: {
        ORBIT: "https://apidev.careorbit.health",
        ORGANIZATION: "https://apidev.careorbit.health",
        USER: "https://apidev.careorbit.health"
    }
};

//added line to force a commit
const staging_server = {
    url: {
        ORBIT: "https://apistaging.careorbit.health",
        ORGANIZATION: "https://apistaging.careorbit.health",
        USER: "https://apistaging.careorbit.health"
    }
};

//added line to force a commit
const dev_local = {
    url: {
        ORBIT: "http://localhost:3000",
        ORGANIZATION: "http://localhost:3001",
        USER: "http://localhost:3002"
    }
};

const dev_local_ui = {
    url: "http://localhost:8080"
};

const staging_server_ui = {
    url: "https://staging.careorbit.health"
};

const dev_server_ui = {
    url: "https://dev.careorbit.health"
};

const prod = {
    url: {
        ORBIT: "https://api.careorbit.health",
        ORGANIZATION: "https://api.careorbit.health",
        USER: "https://api.careorbit.health"
    }
};

const prod_ui = {
    url: "https://careorbit.health"
};


export const config =
   process.env.REACT_APP_NODE_ENV === "development" ? dev_server :
       process.env.REACT_APP_NODE_ENV === "development_local" ? dev_local :
        process.env.REACT_APP_NODE_ENV === "production" ? prod :
            process.env.REACT_APP_NODE_ENV === "staging" ? staging_server :
            dev_local;

export const ui_config =
    process.env.REACT_APP_UI_ENV === "development" ? dev_server_ui :
        process.env.REACT_APP_UI_ENV === "development_local" ? dev_local_ui :
            process.env.REACT_APP_UI_ENV === "production" ? prod_ui :
                process.env.REACT_APP_UI_ENV === "staging" ? staging_server_ui :
                dev_local_ui;
