import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import { HashLink as Link } from 'react-router-hash-link';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic4 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Checklists</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-2#preSurgeryClinicAppointmentChecklist">PRE-SURGERY TEAM MEETING CHECKLIST</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-2#dayBeforeDayOfSurgeryChecklist">DAY BEFORE/DAY OF SURGERY CHECKLIST</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-2#familyHospitalStayChecklist">FAMILY HOSPITAL STAY CHECKLIST</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-2#successAtHomeChecklist">SUCCESS AT HOME CHECKLIST</Link></p>
                                                </div>
                                                {/*<div className="typeContainer text-center">*/}
                                                {/*    <p><Link to="/Pancreatic_2-1-2#nutritionAtHome">NUTRITION AT HOME*/}
                                                {/*        CHECKLIST</Link></p>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">
                                                Planning for a successful
                                                procedure and hospital stay can help make your experience and outcome
                                                better. We’ve included some handy checklists here you can use for quick
                                                reference before, during and after your surgery.
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="preSurgeryClinicAppointmentChecklist">PRE-SURGERY CLINIC APPOINTMENT CHECKLIST</h2>
                                            <p>A key meeting prior to the procedure is the "Team Meeting" you'll be
                                                conducting with key members of your care team. Here is the checklist of
                                                things to bring to that meeting.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom checkBullets">
                                                <li>Call the Center for Preoperative Assessment and Planning (CPAP) 314-362-4275 to schedule your preoperative assessment. Bring an up-to-date medication and allergy list</li>
                                                <li>It is important that you bring your medications or a Home Medication
                                                    Record (with dose and how often you take them). Include all over the
                                                    counter items – vitamins, herbs, aspirin, inhalers, etc.
                                                </li>
                                                <li>Any advanced testing (e.g. stress test or echocardiogram) results or
                                                    the testing site’s phone numbers.
                                                </li>
                                                <li>Your implant/information card for devices such as a pacemaker,
                                                    defibrillator, or blood vessel stent.
                                                </li>
                                                <li>If you have recently seen your cardiologist, ask him/her for a
                                                    letter regarding your heart history, any implanted heart devices,
                                                    and your most recent test results.
                                                </li>
                                                <li>Your Primary Care Physician's office phone &amp; fax numbers.</li>
                                                <li>The name and/or phone number of your laboratory, MD office or
                                                    hospital where any recent blood testing, EKG or chest x-rays have
                                                    been done. If you have a copy of your own, please bring.
                                                </li>
                                                <li>Your Advanced Directive or Living Will.</li>
                                                <li>If applicable, the paperwork showing Power of Attorney, guardianship
                                                    or adoption for the patient. This is necessary for us to render
                                                    care.
                                                </li>
                                                <li>All current insurance cards.</li>
                                                <li>A completed Health Questionnaire that you have done in advance. You
                                                    can print a copy from this link: <a href="https://anesthesiology.wustl.edu/pdf/about/CPAPHealthQuestionnaire.pdf"
                                                                                        target="_blank">Questionnaire</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="dayBeforeDayOfSurgeryChecklist">DAY BEFORE + DAY OF SURGERY CHECKLIST</h2>
                                            <p>The day before and day of surgery can become a hectic time. Here is a
                                                simple reference for the various topics you should address to help make
                                                the day go smoothly and with less stress.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom checkBullets">
                                                <li>Call us in ADVANCE with any changes in your medical condition, including sickness. </li>
                                                <li>Receive and follow "Day Before/Day Of Surgery" doctors
                                                    instructions
                                                </li>
                                                <li>Bring photo I.D. and insurance card.
                                                </li>
                                                <li>Determine any family lodging needs during post-surgery hospital
                                                    stay (approximately 7 days). You can find family lodging information <a href="https://www.barnesjewish.org/Patients-Visitors/Lodging" target="_blank">here</a>.

                                                </li>
                                                <li>Know where you plan to park (South Parking Garage)</li>
                                                <li>Know where to go first upon arrival (Surgery Registration) View Your
                                                    "Day Of Surgery" <Link to="/Pancreatic_7-4">Map here</Link><br/>
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Day_of_Surgery_Map_Detail.jpg"
                                                         className="img-responsive"/>
                                                </li>
                                                <li>Pack family member books/computers/etc. (be ready for hours of
                                                    waiting during the surgery) (note: wifi is available in the waiting
                                                    area)
                                                </li>
                                                <li>Understand the schedule of events the day of surgery (Check-in,
                                                    Procedure, Post-Procedure Observation, Transfer To Recovery Room,
                                                    Visiting Times and Rules)
                                                </li>
                                                <li>Determine your family’s “Designated Contact” (the person the care
                                                    team will inform with updates during the day; the Designated Contact
                                                    then updates family and friends)
                                                </li>
                                                <li>Know what Concierge Services you can use to make things easier
                                                    (valet parking, help getting around, advice on available services at
                                                    the hospital and nearby)
                                                </li>
                                                <li>Arrive at Barnes-Jewish Hospital 1 Barnes-Jewish Plaza, St. Louis, MO 63110 at the surgery time provided.</li>
                                                <li>Check in at Surgery Registration at the time provided.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="familyHospitalStayChecklist">FAMILY HOSPITAL STAY CHECKLIST</h2>
                                            <p>During the days spent by the patient recovering from the procedure, here
                                                are some things the family can start to focus on to make their stay more
                                                accommodating, supportive and productive.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom checkBullets">
                                                <li>Know the visiting hours and policies of the floor the patient is
                                                    staying.
                                                </li>
                                                <li>Take steps to ensure that no sick family members visit the recovery
                                                    floor and that all family and friends who do visit practice good
                                                    hand-washing hygiene (hand sanitizer stations are available
                                                    throughout the recovery room areas).
                                                </li>
                                                <li>Know the services (laptop rental, advice on area activities and
                                                    dining, help in lodging/transportation, etc.) that the Concierge
                                                    Service can help provide.
                                                </li>
                                                <li>Know the locations of family support resources like Guest Services,
                                                    the Office Of Patient and Family Affairs, Information Desks,
                                                    Spirtual Care services/chapels, Cafeterias/Gift Shops, the free
                                                    Barnard Health and Cancer Information Center, Shuttles, etc.
                                                </li>
                                                <li>Begin to plan which family members and friends can take what
                                                    “duties” to help the patient’s recovery once they leave the
                                                    hospital. See “Success At Home” Checklist below for a list of ways
                                                    to help.
                                                </li>
                                                <li>Begin to familiarize yourself with additional resources within
                                                    Siteman and Barnes-Jewish Hospital which could be of use in the
                                                    patient’s recovery and ongoing healthy living.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="col-8 listIndented">
                                            <div className="w-100">
                                                <h2 className="subheadAllCaps">This includes areas like:</h2>
                                            </div>
                                        </div>
                                        <div className="col-8 subheadAndBullets listIndented">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Nutrition guidance</li>
                                                    <li>Cancer Support groups for patients and families</li>
                                                    <li>Emotional health resources and outreach</li>
                                                    <li>Ideal discharge planning</li>
                                                    <li>Post-procedure cancer education and additional treatment
                                                        options
                                                    </li>
                                                    <li>Community resources</li>
                                                    <li>Wellness programs</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="successAtHomeChecklist">SUCCESS AT HOME CHECKLIST</h2>
                                            <p>Having a strong, successful recovery comes in part from making sure
                                                you’re doing the things that will best affect your outcome. These
                                                include things you should understand about what to look for and what to
                                                do, as well as making plans for involving your family and friends, and
                                                making your home environment supportive of your goals (like making sure
                                                the proper foods are stocked up and ready for when you come home).</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom checkBullets">
                                                <li>Understanding your discharge instructions, including your
                                                    medications and options for care available to you
                                                </li>
                                                <li>Keeping all contact information handy and reaching out whenever
                                                    needed
                                                </li>
                                                <li>Knowing and adhering to your follow-up schedule</li>
                                                <li>Knowing your support resources and how to reach out to them</li>
                                                <li>Knowing any warnings signs to look out for and letting us know
                                                    promptly if any arise
                                                </li>
                                                <li>Understanding the kinds of possible complications and inconveniences
                                                    you’ll face and how to best act to help lower their severity. Some
                                                    common issues like constipation, pain and nausea can be helped
                                                    greatly by knowing what to do as soon as they arise and how to help
                                                    avoid symptoms in the first place.
                                                </li>
                                                <li>Proper care of your drains and incision</li>
                                                <li>Planning (and stocking up for) proper nutrition at home</li>
                                                <li>Being active in ways that speed your recovery</li>
                                                <li>Following any additional instructions relating to the use of
                                                    medications, medical devices, diabetes treatments, needed therapies
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h2 className="subheadAllCaps" id="nutritionAtHome">NUTRITION AT HOME CHECKLIST</h2>*/}
                                    {/*        <p>The nature of your procedure means your digestive system will take some*/}
                                    {/*            time to recover. During that time, you’ll need to focus on maintaining*/}
                                    {/*            the proper nutrition while avoiding foods that could cause you distress*/}
                                    {/*            and create complications that interfere with your recovery. These are*/}
                                    {/*            detailed in easy-to-follow ways in your “Success At Home” section of*/}
                                    {/*            this orbit reference tool. The lists below are included in that*/}
                                    {/*            content:</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-12 subheadAndBullets">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <ul className="bulletsCustom checkBullets">*/}
                                    {/*            <li>Make a goal of eating an adequate amount of a variety of foods.</li>*/}
                                    {/*            <li>Foods high in fat or sugar may cause distress.</li>*/}
                                    {/*            <li>Try 6 small meals that are high in calories and protein.</li>*/}
                                    {/*            <li>To help with healing, eat foods high in protein, such as tender*/}
                                    {/*                meats, poultry, fish, dairy products, eggs, peanut butter, and*/}
                                    {/*                beans.*/}
                                    {/*            </li>*/}
                                    {/*            <li>Relax at mealtime and chew food well. Stop eating when you feel*/}
                                    {/*                full.*/}
                                    {/*            </li>*/}
                                    {/*            <li>For the first few weeks, avoid drinking large amounts of fluid with*/}
                                    {/*                meals. Small sips are okay. Drink most fluids 30 minutes before and*/}
                                    {/*                after meals. Drink 48 to 64 oz. (6 to 8 cups) of fluid throughout*/}
                                    {/*                the day.*/}
                                    {/*            </li>*/}
                                    {/*            <li>Keep healthy snacks and supplements (e.g. Boost, Ensure, Glucerna,*/}
                                    {/*                Carnation Instant Breakfast) handy for easy small meals.*/}
                                    {/*            </li>*/}
                                    {/*            <li>Take enzymes properly to help supplement your digestion and*/}
                                    {/*                absorption of food when needed.*/}
                                    {/*            </li>*/}
                                    {/*            <li>Try not to lose weight, even if you are overweight, because it can*/}
                                    {/*                make you feel weaker and can delay healing. Your registered*/}
                                    {/*                dietitian or nutritionist can help you with ideas for maintaining*/}
                                    {/*                your weight if needed.*/}
                                    {/*            </li>*/}
                                    {/*        </ul>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-12 threecolumnTable">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <table className="table table-responsive">*/}
                                    {/*            <thead>*/}
                                    {/*            <tr>*/}
                                    {/*                <th colSpan="3">*/}
                                    {/*                    */}
                                    {/*                </th>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <th className="leftCol">*/}
                                    {/*                    <h4>Dairy</h4>*/}
                                    {/*                </th>*/}
                                    {/*                <th className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <h4>Recommended in the first few weeks:</h4>*/}
                                    {/*                    <p>Milk products as tolerated, lactose- free or lactose-reduced*/}
                                    {/*                        products, sugar-free yogurt, sugar- free pudding, cheese,*/}
                                    {/*                        sugar- free ice cream, low-fat cottage cheese, low-fat*/}
                                    {/*                        yogurt, cheese</p>*/}
                                    {/*                </th>*/}
                                    {/*                <th className="rightCol">*/}
                                    {/*                    <h4>May cause distress:</h4>*/}
                                    {/*                    <p>Heavy creams, cheese sauce, cocoa mixes, regular ice cream,*/}
                                    {/*                        chocolate milk, sweetened custard or pudding, regular*/}
                                    {/*                        yogurt, milkshakes</p>*/}
                                    {/*                </th>*/}
                                    {/*            </tr>*/}
                                    {/*            </thead>*/}
                                    {/*            <tbody>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Protein</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Lean chicken and turkey breast, beef (round, loin or*/}
                                    {/*                        tenderloin), pork (tenderloin loin, chop, Canadian bacon),*/}
                                    {/*                        fish, eggs, cooked dry beans, smooth peanut butter, peas,*/}
                                    {/*                        cheese, cottage cheese*/}
                                    {/*                    </p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>All fried or highly-seasoned meat, poultry, pork and fish,*/}
                                    {/*                        lunch meats, bologna, salami, hot dogs, bacon, tough/stringy*/}
                                    {/*                        meats, nuts, chunky peanut butter</p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Grains (Breads, Cereals)</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Crackers, pasta, plain breads and rolls, pretzels, rice,*/}
                                    {/*                        unsweetened cereals (dry or cooked), English muffin, bagels,*/}
                                    {/*                        baked chips</p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>Pastries, doughnuts, muffins, biscuits, sugar-coated cereals,*/}
                                    {/*                        sweet rolls*/}
                                    {/*                    </p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Vegetables</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Cooked vegetables (other than those listed to avoid),*/}
                                    {/*                        vegetable juice</p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>Fried vegetables, French fries, raw vegetables, cooked*/}
                                    {/*                        vegetables including beets, broccoli, brussel sprouts,*/}
                                    {/*                        cabbage, mustard and turnip greens, cauliflower, corn,*/}
                                    {/*                        potato skins</p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Fruits</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Soft fresh fruit, fruit canned in natural juices, unsweetened*/}
                                    {/*                        fruit juice</p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>Tough fresh fruits, avocado, dried fruits, canned or frozen*/}
                                    {/*                        fruits in syrup, sweetened fruit</p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Desserts</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Low-fat ice cream, sherbet, angel food cake, vanilla wafers,*/}
                                    {/*                        low-calorie gelatin, low-calorie popsicles, sugar-free*/}
                                    {/*                        desserts</p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>Regular popsicles, regular gelatin, high-sugar desserts,*/}
                                    {/*                        cake, pie. Use fatty desserts in moderation</p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Beverages</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Non-carbonated/sugar-free or low sugar beverages, water,*/}
                                    {/*                        diluted fruit juice, 2%-1%-1/2% or skim milk if tolerated,*/}
                                    {/*                        unsweetened iced tea</p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>Carbonated beverages (even diet) due to gas formation,*/}
                                    {/*                        regular soft drinks, sugared drink mixes, sugar- containing*/}
                                    {/*                        fruit-flavored beverages, sweetened iced tea or similar*/}
                                    {/*                        drinks, alcohol, chocolate or high-fat milks, eggnog,*/}
                                    {/*                        regular coffee</p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            <tr>*/}
                                    {/*                <td className="leftCol">*/}
                                    {/*                    <h4>Condiments</h4>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="centerCol cyanTableBackgroundColor">*/}
                                    {/*                    <p>Salt, pepper, mild-flavored sauces and gravies, other spices*/}
                                    {/*                        as tolerated, low-fat salad dressing, mustard, catsup,*/}
                                    {/*                        marinara sauce, artificial sweeteners, low-calorie jelly</p>*/}
                                    {/*                </td>*/}
                                    {/*                <td className="rightCol">*/}
                                    {/*                    <p>Heavy gravies, hollandaise sauce, mayonnaise, cheese sauce,*/}
                                    {/*                        white sauce, sugar, regular jams and jellies</p>*/}
                                    {/*                </td>*/}
                                    {/*            </tr>*/}
                                    {/*            </tbody>*/}
                                    {/*        </table>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic4;
