import React from "react";

function L98ComponentCode() {
  return (
    <div className="lego l098 legoMargin2">
      <p>
        Center Director:{" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/2695/Find-a-Physician/Physician-Directory/Devyani-Hunt-MD/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
          Devyani Hunt, MD
        </a>
        <br />
        Acupuncture:{" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/2717/Find-a-Physician/Physician-Directory/Chi-Tsai-Tang-MD/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
          Chi Tang,MD
        </a>
        <br />
        Manual Medicine:{" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/2705/Find-a-Physician/Physician-Directory/John-Metzler-MD/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
           John Metzler,MD
        </a>
        <br />
        Massage Therapy:{" "}
        <a href="" rel="noreferrer noopener" target="_blank">
          Amy Whitelaw, MT
        </a>
        <br />
        Massage Therapy: {" "}
        <a href="" rel="noreferrer noopener" target="_blank">
          Mary Bouse, MT
        </a>
        <br />
        Nutrition Counseling:{" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/8321/Find-a-Physician/Physician-Directory/Nartana-Mehta-RD-LD/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
           Nartana Mehta, RD, LD
        </a>
        <br />
        Physical Therapy:{" "}
        <a href="" rel="noreferrer noopener" target="_blank">
           Susan Peck, PT
        </a>
        <br />
        Physical Therapy:{" "}
        <a href="" rel="noreferrer noopener" target="_blank">
           Matthew Foster, PT, DPT
        </a>
        <br />
        Licensed Clinical Social Worker:{" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/8497/Find-a-Physician/Physician-Directory/Kayla-Hambleton-MSW-LCSW/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
           Kayla Hambleton, MSW, LCSW
        </a>
        <br />
        Licensed Clinical Social Worker: {" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/8453/Find-a-Physician/Physician-Directory/Juliana-Varela-LCSW/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
          Juliana Varela, LCSW
        </a>
        <br />
        Smoking Cessation:{" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/3113/Find-a-Physician/Physician-Directory/Corri-Payton-BSN-MSN-ANP/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
          Corri Payton, NP
        </a>
        <br />
        Nurse Practitioner:  {" "}
        <a
          href="https://www.ortho.wustl.edu/content/Patient-Care/8462/Find-a-Physician/Physician-Directory/Sara-Kellahan-MSN-APRN-AGPCNP-C/Bio.aspx"
          rel="noreferrer noopener"
          target="_blank"
        >
          Sara Kellahan, MSN, APRN, AGPCNP
        </a>
        <br />
        Head Athletic Trainer:{" "}
        <a href="" rel="noreferrer noopener" target="_blank">
          Lauren Baumer, MSEd, ATC
        </a>
        <br />
      </p>
    </div>
  );
}

export default L98ComponentCode;
