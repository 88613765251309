import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonOxygenWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center width400">
                                                <p>
                                                    <Nav.Link className="" href="/LessonOxygen">Giving Oxygen</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What To Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Need to Know</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/turning_on_oxygen.png" className="img-responsive floatNone maxWidth300" />
                                        <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>An oxygen gauge shows you the amount of oxygen in the system.</li>
                                            <li>A flow control valve allows you to turn on the flow of oxygen at the ordered flow rate, such as 2 liters/minute.</li>
                                            <li>Some oxygen systems come with a humidifier bottle. When the bottle is filled with distilled water and connected to the oxygen tubing, the water bubbles, creating moist humidified oxygen that flows through the person’s nose, mouth or tracheostomy. Oxygen can dry the nasal tissue and throat. Humidified oxygen benefits people who need to receive oxygen continuously.</li>
                                            <li>A cannula or connecting tubing will connect the humidifier and oxygen delivery system to the delivery device, such as a nasal cannula or mask.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Watch for Signs of Hypoxemia (Not enough oxygen in the blood)</p>
                                        <p>A person who requires oxygen can develop low oxygen levels in their blood easily, because of their health condition. Any change in activity or increase in oxygen needs might cause hypoxemia.</p>
                                        <p>For example, too much exercise, removing an oxygen mask during bathing, a fever, or removing a mask because it becomes uncomfortable can trigger hypoxemia.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Apprehension</li>
                                            <li>Anxiety</li>
                                            <li>Decreased ability to concentrate</li>
                                            <li>Changes in consciousness</li>
                                            <li>Increased fatigue</li>
                                            <li>Drowsiness</li>
                                            <li>Dizziness</li>
                                            <li>Increased pulse</li>
                                            <li>Faster breathing</li>
                                            <li>Pale skin</li>
                                            <li>Bluish tinge to fingernails or lips</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Skin Care)</p>
                                        <p>When an oxygen mask or cannula is left in place for several hours, the skin of the face, nose and behind the ears can become irritated.</p>
                                        <p>Routine care is needed to prevent injury to the skin. Use caution when caring for the skin as you do not want to discontinue oxygen use for any length of time. Consult with your home health nurse about the best approach to use.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Moisten the inside of the person’s nose, using a normal saline spray or a water-soluble jelly, such as the brand KY Jelly. (Nasal cannulas without humidity can cause irritation, dryness and nose bleeding.)</li>
                                            <li>Place cotton padding or a small gauze square over the ears or any areas of soreness. If an ulcer forms, call your home health nurse.</li>
                                            <li><span className="italic bold">Do not use petroleum-based jelly</span> – such as Vaseline. These products do not cause fires, but they <span className="italic bold">will support burning if a fire occurs.</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Maintaining and Cleaning Equipment</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A Home Oxygen Provider (HOP) is usually a durable medical equipment (DME) or home health service that offers 24-hour emergency service and can be reached through an answering service. This gives you the ability to contact the HOP in an emergency, such as a power or equipment failure.</p>
                                        <p>The HOP will provide a backup system or extra oxygen cylinders in the event of a power outage. This is important if the person is on an oxygen concentrator and there is a chance that the power may be out for a long period of time. When using a concentrator, contact the electric company and tell them that you are caring for someone who relies on electricity for their oxygen. The electric company will keep this information on file and it allows for priority when re-establishing service.</p>
                                        <p>When a person is on liquid oxygen, the reservoir is a back-up system since liquid oxygen does not use electricity to operate.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Check an Oxygen System Daily</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When you care for someone receiving oxygen, make these daily checks:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Check your equipment for any damage, including tears or leaks in tubing, signs of wear or problems with any of the electrical components, or damage to any parts of the equipment.</li>
                                            <li>If you have compressed oxygen tanks, check to see that they are properly secured.</li>
                                            <li>If the person has an ambulatory container, check the carrier regularly for signs of wear and tear.</li>
                                            <li>Check the pressure gauge. Note any unusual changes in oxygen use. For example, is the amount remaining in a tank less than what you expect? The HOP will tell you if you need to order more oxygen.</li>
                                            <li>Make sure the flow meter shows oxygen is being given at the proper rate. <span className="bold italic">Do not adjust the flow</span> meter except under the doctor’s orders.</li>
                                            <li>Always refill the humidifier with the recommended type of water (usually distilled). Only fill to the proper level. When oxygen is at a flow rate of 1-4 liters/minute by mask or nasal cannula, the body will usually have enough moisture in the mouth, nose and throat to provide for adequate humidification (moisture). However, when a person has a respiratory infection, wears the oxygen continuously, needs oxygen at higher flow rates, or when oxygen is delivered through a tracheostomy, humidification is needed.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Clean the System</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The HOP will tell you how best to clean oxygen equipment.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Clean a nasal cannula daily. Take a clean, damp cloth and wipe down the cannula tubing after removing it from the person’s nose. Start cleansing at the entrance to the nose and wipe down toward the base of the tubing.</li>
                                            <li>If you are using a humidifier, clean the bottle twice a week in warm soapy water and rinse with clean hot water. Do not just swish the suds around; use a soft cloth and clean thoroughly. Then soak in a 1 part to 3 part mixture of white vinegar and water for a minute. Always rinse the bottle thoroughly with water after soaking and shake off excess water. Do not reuse the vinegar and water mixture.</li>
                                            <li>Refill the humidifier with distilled water as needed, but do NOT overfill the bottle. Too much water in the bottle causes water to collect in the oxygen tubing.</li>
                                            <li>Always allow pieces of equipment to dry thoroughly before continuing use.</li>
                                            <li>Wipe off oxygen tubing daily with a clean, damp cloth.</li>
                                            <li>The extension tubing does not need to be washed. Check regularly for major kinks or possible splitting.</li>
                                            <li>Use a damp cloth to dust off oxygen tanks and concentrators. A dry cloth may cause static electricity and create a fire risk.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Have an Adequate Oxygen Supply</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The amount of oxygen in a system varies by the type of system. Talk with your HOP about how to figure the length of time a tank or cylinder will last. The provider will give you a timetable to use to estimate the number of hours of oxygen left in a system.</p>
                                        <p>The amount of oxygen available will vary by how much oxygen the person uses. For example, a full 625 liter compressed tank will provide oxygen for 10 hrs at a rate of 1 liter per minute. The same tank will provide oxygen for 3 hrs at a flow rate of 4 liters per minute.</p>
                                        <p>Always be sure you have an ambulatory tank which will allow you to get where you are going and back, without running out of oxygen. This may mean carrying an extra tank with you to switch out, in case the first one runs low.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Fire Safety</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Do not allow smoking in the home. Have a No Smoking sign in clear view for visitors. Always alert others that oxygen is present. (Because oxygen is colorless, odorless and tasteless, many people are unaware that it is in use.)</li>
                                            <li>If the person goes to a restaurant, sit in the no smoking section.</li>
                                            <li>Do not use petroleum-based ointments (like Vaseline) around the person’s nose. These ointments could cause burns in the case of a fire.</li>
                                            <li>Also avoid use of flammable products such as aerosols, hair sprays, body lotions, face creams, cleaning products and rubbing alcohol around oxygen.</li>
                                            <li>Do not use electrical appliances (such as razor, hair dryer) around the person using oxygen. Appliances could produce sparks.</li>
                                            <li>Keep oxygen containers a minimum of 8 feet away from open flame (e.g. stove, matches, fireplaces, space heaters, candles).</li>
                                            <li>Tell your local fire department and electric company that oxygen is in the home. Post a sign on your door or window.</li>
                                            <li>Make sure you have a working smoke detector and fire extinguisher in the home.</li>
                                            <li>If the person wears oxygen while cooking, make sure that the oxygen tubing does not come into contact with hot burners or pans which could cause the plastic tubing to melt.</li>
                                            <li>Keep concentrators at least 18 inches away from drapes, bedspreads, or walls which may block the inlet ports.</li>
                                            <li>Use cotton blankets and fabrics versus wool blankets and synthetic fabrics which may generate static electricity. Also, do not wear nylon or woolen clothing that is more likely to cause static electricity.</li>
                                            <li>If a person wears an oxygen mask, ask the doctor if a nasal cannula can be placed temporarily, and at what rate, so that the person can eat.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Oxygen Storage and Handling</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Do not place oxygen tubing under rugs or furniture.</li>
                                            <li>Store oxygen containers upright in carts or stands provided by the home oxygen provider. Make sure they are secured to a fixed object, so that they will not tip over. Option: Place cylinders or tanks flat on the floor when not in use.</li>
                                            <li>Store oxygen containers in well ventilated rooms.</li>
                                            <li>Do not store paper, fabric, or plastic near an oxygen system.</li>
                                            <li>Fasten an oxygen tank upright in the car. Do not put a tank in the trunk.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Concentrator Safety</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Plug a concentrator into a properly grounded outlet.</li>
                                            <li>Do not use extension cords, power strips, or multi-outlet adapters with concentrators.</li>
                                            <li>Test the alarm system on the concentrator daily.</li>
                                            <li>Ensure the power supply or circuit meets or exceeds the electrical requirements of the concentrator.
                                            </li>
                                            <li>Tell the electric company if the person has a concentrator.</li>
                                            <li>Some people may want to invest in a generator to use as a dedicated power source for their concentrator.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Liquid Oxygen Safety</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Do not touch connectors that are frosted or icy. This can injure your skin.</li>
                                            <li>Do not touch the liquid oxygen, as this can cause frostbite.</li>
                                            <li>Keep ambulatory tanks upright; do not lay them down or place on their side.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Basic Safety</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Watch for kinks in oxygen tubing.</li>
                                            <li>The home care provider will often give you a 50-foot supply of tubing for oxygen delivery. Be careful and do not get caught in or trip over the tubing.</li>
                                            <li>Never leave home with less than half a tank of oxygen.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                        Video: Giving Oxygen Correctly
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="0EXzgHDdzMw" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Giving Oxygen</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Care diary and pen</li>
                                            <li>Oxygen delivery system – cylinder, concentrator or liquid oxygen unit</li>
                                            <li>Watch or clock with second hand</li>
                                            <li>Oxygen device – cannula, mask or collar</li>
                                            <li>Oxygen extension tubing</li>
                                            <li>Small gauze pad (option)</li>
                                            <li>Humidifier and solution</li>
                                            <li>Cup of water</li>
                                            <li>1 part to 3 part mixture of white vinegar and water</li>
                                            <li>Paper towels</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Refilling a Liquid Oxygen System</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Liquid oxygen tank</li>
                                            <li>Oxygen reservoir</li>
                                            <li>Clean, dry, lint-free cloth</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonOxygenWhatToDoAndSafety;