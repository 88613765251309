import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll9_1 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Contact Information</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Barnes-Jewish Hospital</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp; Main:</span><a type="tel" className="" href="tel:3147473000"> (314) 747-3000</a></p>
                                                </div>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <a id="otolaryngologist"></a>
                                                <h2 className="headline">Otolaryngologist Head & Neck Surgeons</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp; Main:</span><a type="tel" className="" href="tel:3143627509"> (314) 362-7509</a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><a href="https://oto.wustl.edu" className="" target="_blank"><i className="fa fa-laptop"></i>&nbsp;&nbsp;oto.wustl.edu</a></p>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">Office Hours:</span>&nbsp;M-F 8:00 am – 4:30 pm</p>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">After hours and on weekends:</span><br/>For urgent questions, call Barnes-Jewish Hospital centralized paging at <a href="tel:314.362.1242">314.362.1242</a> and ask the operator to page the ENT Surgery Resident on-call.</p>
                                            </div>

                                            <div className="medicalCenterLink">
                                                <p><span className="bold">Meet the </span><a href="https://siteman.wustl.edu/treatment/cancer-types/head-and-neck/specialist-team/" target="_blank">Siteman Head and Neck Cancer Specialist Team ></a></p>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Center for Preoperative Assessment and Planning (CPAP) Surgery Evaluation Center</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp; Main:</span><a type="tel" className="" href="tel:3143624275"> (314) 362-4275</a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp; Toll Free:</span><a type="tel" className="" href="tel:8008283783"> (800) 828-3783</a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">Office Hours:</span>&nbsp;M-F 8:00 am – 5:30 pm</p>
                                                <p><span className="bold">More information on the </span><a href="https://anesthesiology.wustl.edu/patient-care/perioperative-medicine/center-for-preoperative-assessment-and-planning/" target="_blank">Center for Preoperative Assessment and Planning (CPAP) Surgery Evaluation Center</a></p>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">In-Patient Therapy Services</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp; Main:</span><a type="tel" className="" href="tel:3143622389"> (314) 362-2389</a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">Office Hours:&nbsp;</span>M-F 7:00 am – 4:00 pm</p>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">16300 PCU Nurses Station<br/>
                                                    (step down unit immediately after surgery)</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:3147471161"> (314) 747-1161</a></p>
                                                </div>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">17300 Nurses Station</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:314364075"> (314) 362-4075
                                                        </a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">24 hours a day</span></p>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Barnes-Jewish Hospital outpatient pharmacy at Center for Advanced Medicine (3rd floor)</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:3146579006"> (314) 657-9006</a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">Hours:&nbsp;</span>M-F 8:00 am – 5:30 pm</p>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Concierge Services</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:3143625196"> (314) 362-5196</a></p>
                                                </div>
                                                <div className="medicalCenterLink">
                                                    <p><a type="email" href="mailto:conciergeservices@bjc.org" className="" target="_blank"><i className="fa fa-envelope"></i>&nbsp;&nbsp;conciergeservices@bjc.org</a></p>
                                                </div>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p><span className="bold">Hours:&nbsp;</span>M-F 8:00 am – 4:30 pm</p>
                                            </div>
                                            <div className="medicalCenterLink">
                                                <p>While visiting patients at Barnes-Jewish Hospital, the hospital’s concierge representatives are available to help assist you with information, services and special requests, including:</p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom checkboxBullets">
                                                            <li>bank locations</li>
                                                            <li>cafeterias and restaurants</li>
                                                            <li>campus maps and directions</li>
                                                            <li>hotels and long-term accommodations</li>
                                                            <li>laundry facilities</li>
                                                            <li>local attractions</li>
                                                            <li>parks</li>
                                                            <li>pharmacies nearby</li>
                                                            <li>postal services</li>
                                                            <li>shopping</li>
                                                            <li>shower facilities</li>
                                                            <li>transportation services</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>Concierge assistance is offered at no charge. Services provided by outside vendors may include a fee. Concierge representatives are located at the information desks on the hospital’s South campus and in the Center for Advanced Medicine.</p>

                                                <p><span className="bold">More information on </span><a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Hospitality Services</a></p>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Office of Patient & Family Affairs</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:3143626100"> (314) 362-6100</a></p>
                                                    <p><span className="bold">More information on </span><a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Hospitality Services</a></p>
                                                </div>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Patient Information</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:3143625460"> (314) 362-5460</a></p>
                                                </div>
                                            </div>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="medicalCenterName infoPaddingRight">
                                                <h2 className="headline">Security/Parking/Patient Family Shuttle</h2>
                                            </div>
                                            <div className="medicalCenterInfoSection">
                                                <div className="medicalCenterPhone">
                                                    <p><span className="bold"><i className="fa fa-phone" aria-hidden="true"></i>&nbsp;</span><a type="tel" className="" href="tel:3143620750"> (314) 362-0750</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll9_1;
