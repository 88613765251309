import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import YouTube from 'react-youtube';
import '@fortawesome/fontawesome-svg-core/styles.css'
import {HashLink as Link} from "react-router-hash-link";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const ContentPancreatic19 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Caring For Your JP Drain'}, () => {
            console.log('Caring For Your JP Drain');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Incision and Drain
                                                Care</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">CARING FOR YOUR INCISION</h2>

                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive w-100"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/oparation1.jpg" alt=""/>
                                            </div>
                                            {/*<div className="col-10 typeCol noPaddingLeft">*/}
                                            {/*    <p className="mobileMarginBottom30">If your staples or stitches are not*/}
                                            {/*        removed before you leave the hospital, they will be removed at*/}
                                            {/*        follow up clinic visits. Before then, you can shower every day. Your*/}
                                            {/*        staples or stitches can get wet. Keeping the wound clean is*/}
                                            {/*        important for optimal healing.*/}
                                            {/*    </p>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom w-100">
                                                <li>Sutures or staples that were left in at time of discharge will be removed at your clinic visit.
                                                </li>
                                                <li>If there is wound glue on your incision, do not pick at the incision. The glue will come off when the incision is healed.
                                                </li>
                                                <li>You can take a shower, let the water run over the incision. <strong>Do NOT scrub your incision. AVOID submerging the incision in water. AVOID baths, hot tubs, pools, etc.</strong>
                                                </li>
                                                <li><strong>Do not apply any lotions, creams, etc. to your incision.</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">If your incision was sealed with Dermabond&reg; or other liquid skin adhesive: </p>
                                            <p>In some cases, incisions may be sealed with a liquid skin adhesive instead of sutures. If you have a wound sealed with liquid skin adhesive, after 24 hours you can allow water to wash gently over the incision line. </p>
                                            <p>Do not scrub the incision. It is fine for soap to wash over the incisions, but it must be rinsed off. It is fine to keep the wound open. No other wound care is needed for these incisions. In 7 to 10 days it will begin to peel up around the edges. You can gently remove the adhesive if it peels after 7 days. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Call us right away if you notice any of
                                                the following with your incision:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                Spreading redness or warmth
                                            </h2>
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                Increased pain
                                            </h2>
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                Increased drainage
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h2 className="subheadAllCaps mb-4">CARING FOR YOUR DRAIN</h2>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="middleGreenColor videoTitle width100">
                                                CARING FOR YOUR JP DRAIN
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="vrNcoBahNpE" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxPriority middleGreenBackground text-center">
                                                <h2 className="subheadUpperandLower">Why you have a drain:</h2>
                                                <hr/>
                                                    <p>After the tumor is removed from the pancreas, the cut end of the
                                                        pancreas is sutured back into the intestine so that pancreatic
                                                        juices can go back into the intestine. The pancreas is a very
                                                        soft organ and in some patients this suture line may not heal
                                                        very well. If this happens, then patients develop leakage of
                                                        pancreatic juice.</p>
                                                    <p>Usually the surgeon leaves behind a drainage catheter in the
                                                        abdomen during the surgery. Any leakage of pancreatic juice
                                                        after the surgery is usually removed from the body by this
                                                        drainage catheter. In almost all patients who develop leakage of
                                                        pancreatic juice after the surgery, the leakage heals on its
                                                        own.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic19;
