import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const PrivacyPolicy = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="w-100">
                                        <h1 className="signUph1 text-center">Privacy Policy</h1>
                                    </div>
                                    <div className="w-100">
                                        <p className="text-dark w-100">CareOrbit understands how important the privacy of personal information is
                                            to our users. This Privacy Policy will tell you what information we collect
                                            about you and about your use of CareOrbit and its services. It will
                                            explain the choices you have about how your personal information is used and
                                            how we protect that information. We urge you to read this Privacy Policy
                                            carefully.</p>
                                        <p className="text-dark w-100">This Privacy Policy applies to any websites owned and operated by CareOrbit
                                            that are intended for use by consumers (non-professionals) for
                                            non-commercial personal, family or household purposes, including
                                            CareOrbit.com and including the mobile optimized versions of this site and
                                            the Mobile Device Applications or "Apps" (we refer to these sites and Apps
                                            collectively as the "CareOrbit Sites"). We refer to the CareOrbit Sites
                                            and Apps, along with the information and services made available to you
                                            through the CareOrbit Sites and Apps, as the "Services."</p>
                                        <p className="text-dark w-100">Except where noted, statements in this Privacy Policy with respect to the
                                            CareOrbit Site also apply to the Apps. If you do not want us to collect,
                                            use or disclose information about you and your use of the Services as
                                            described in this Privacy Policy, then you should not use the Services. By
                                            using the Services, you must agree to the CareOrbit Terms of Use, which is
                                            the contract between us and users of the Services. By accepting the
                                            CareOrbit Terms of Use, you confirm that you have read and understand this
                                            Privacy Policy and the CareOrbit Cookie Policy and you acknowledge that we
                                            will store, use and otherwise process your information in the United States
                                            where we are located.</p>
                                        <p className="text-dark w-100">References to "CareOrbit," "we" or "us" mean CareOrbit LLC, including any
                                            company that CareOrbit LLC controls (for example, a subsidiary that
                                            CareOrbit LLC owns). CareOrbit may share information among its
                                            subsidiaries or websites that it owns or controls, but information collected
                                            under this Privacy Policy is always protected under the terms of this
                                            Privacy Policy. Except as otherwise noted in this Privacy Policy,
                                            CareOrbit LLC is the data controller responsible for the processing of
                                            your personal information as described in this Privacy Policy.</p>
                                        <h2 className="text-dark w-100">Information Collected About You</h2>
                                        <p className="text-dark w-100">Most of our Services (such as certain quizzes or forms) do not retain your
                                            personal information, while others store your personal information in
                                            accordance with this Privacy Policy. Even if you do not register with or
                                            provide any personal information to CareOrbit, we collect information
                                            about your use of the CareOrbit Sites and Apps and the Services. We may
                                            also acquire information about our users from external sources.</p>
                                        <h2 className="text-dark w-100">When you use the Services, we collect information as follows:</h2>
                                        <h3 className="text-dark w-100">Registration</h3>
                                        <p className="text-dark w-100">While you may use some Services without registering, certain Services do
                                            require that you register with CareOrbit for them to function properly. If
                                            you choose to register or update an existing account with CareOrbit or
                                            access certain Services, you may be required to provide certain personal
                                            information, such as your name, address, telephone number, gender, email
                                            address and date of birth, and a username and password to access your
                                            CareOrbit account. You are responsible for ensuring the accuracy of the
                                            personal information that you submit to CareOrbit.</p>
                                        <h3 className="text-dark w-100">Interactive Services</h3>
                                        <p className="text-dark w-100">Some of our Services, such as CareOrbit Symptom Checker, are interactive
                                            and may request that you submit health-related information, along with other
                                            information such as your age and gender. You are not required to register
                                            with CareOrbit to use CareOrbit Symptom Checker and if you are
                                            registered with CareOrbit, we do not associate information that you submit
                                            to the Symptom Checker with registration information that you have
                                            previously provided.</p>
                                        <h3 className="text-dark w-100">Email Newsletters</h3>
                                        <p className="text-dark w-100">At registration and at various times as you use the CareOrbit Sites, you
                                            will be given the option of providing us with personal information in order
                                            to receive informational/promotional newsletters – such as a newsletter
                                            relating to a specific health condition – via email from CareOrbit and/or
                                            directly from third parties. From time to time CareOrbit may offer users
                                            of third party websites to the opportunity to subscribe to our newsletters
                                            through those websites. If you elect to subscribe to one of our newsletters
                                            on a third party website, the operator of that website will provide us with
                                            the personal information you have provided in connection with the
                                            subscription request, which we may use in accordance with this Privacy
                                            Policy as though you had provided it directly to CareOrbit. In CareOrbit
                                            Profile Manager, if you turn on the Receive InApp Messages, you'll
                                            periodically receive an pop-up messages and emails when there has been
                                            specific activity related to your account. If you later decide you no longer
                                            want to receive email updates, you can turn it off by navigating to the
                                            Profile Manager Allow In App Messaging section and unchecking.</p>
                                        <h3 className="text-dark w-100">Email/Invite a Friend</h3>
                                        <p className="text-dark w-100">If you use our Email or Invite a Friend service to tell a friend about a
                                            feature on a CareOrbit Site or a newsletter, we will ask you for your
                                            name, your email address and your friend's email address. We will
                                            automatically send your friend a one-time email inviting him or her to visit
                                            the site using your name and email address as the "from" email address.
                                            CareOrbit does not use your friend's email information for any other
                                            purpose. We will only use the information you provide to send the requested
                                            email to your friend.</p>
                                        <p className="text-dark w-100">If you believe that one of your contacts has provided us with your personal
                                            information and you would like to request that it be removed from our
                                            database, please use the Support link provided at the bottom of every page
                                            of the CareOrbit Sites.</p>
                                        <h3 className="text-dark w-100">Market Research</h3>
                                        <p className="text-dark w-100">From time to time, CareOrbit may conduct online research surveys on behalf
                                            of itself and third parties through email invitations, pop-up surveys and
                                            online focus groups. When participating in a survey, we may require you to
                                            provide your location, age and if there's a sweepstakes associated with the
                                            market research survey, your name and contact information for purposes of
                                            sweepstakes administration. The information you submit in a survey may be
                                            used by CareOrbit for research and measurement purposes, as described
                                            below, including to measure the effectiveness of content, advertising or
                                            programs. The personal information you provide in connection with a
                                            sweepstakes entry will be used for the sole purpose of administering the
                                            sweepstakes and as required by applicable law. We will not knowingly invite
                                            individuals who are under the age of 13 to participate in market research
                                            surveys. Market research surveys conducted by or on behalf of CareOrbit
                                            will contain a link to this Privacy Policy.</p>
                                        <h3 className="text-dark w-100">Blogs</h3>
                                        <p className="text-dark w-100">CareOrbit features several links to existing Blog sites areas and other
                                            public forums where users with similar interests including with respect to
                                            certain medical conditions can share information and support one another or
                                            where users can post questions for experts to answer.</p>
                                        <p className="text-dark w-100"><strong>Any information (including personal information) you share in any
                                            online blog or other forum is by design open to the public and is not
                                            private. You should think carefully before posting any personal information
                                            in any public forum. What you post can be seen, disclosed to or collected by
                                            third parties and may be used by others in ways we cannot control or
                                            predict, including to contact you for unauthorized purposes. As with any
                                            public forum on any site, the information you post may also show up in
                                            third-party search engines.</strong></p>
                                        <h3 className="text-dark w-100">Emails You Send to CareOrbit</h3>
                                        <p className="text-dark w-100">This Privacy Policy does not apply to information, content, business
                                            information, ideas, concepts or inventions that you send to CareOrbit by
                                            email. If you want to keep content or business information, ideas, concepts
                                            or inventions private or proprietary, do not send them in an email to
                                            CareOrbit.</p>
                                        <h3 className="text-dark w-100">Services and Device Information</h3>
                                        <p className="text-dark w-100">When you access and use the Services, CareOrbit automatically collects and
                                            stores in its server logs information from your browser or mobile device
                                            such as your IP address or unique device identifier, browser information
                                            (including referring URL), your preferences and settings, cookies and
                                            information about the content you have viewed and actions taken (e.g.,
                                            search queries, ad engagement, clicks and the associated dates and times).
                                            CareOrbit may also collect device-specific information when you install
                                            and use an App including your device model, operating system information,
                                            advertising ID (which is a unique, user-resettable identification number for
                                            advertising associated with a mobile device) and App version and usage
                                            information. When enabled by you, we collect precise location information
                                            provided by your mobile device, which you may disable through the device
                                            settings.</p>
                                        <h3 className="text-dark w-100">Cookies and Other Tracking Technologies</h3>
                                        <p className="text-dark w-100">We and our partners use cookies to collect information about your use of the
                                            Services. "Cookies" are small data files assigned to your browser when you
                                            visit a CareOrbit Site which enable recognition of your browser and
                                            collect and store information about your use of the Services, as described
                                            above. In addition to cookies, we and our partners use other tracking
                                            technologies that collect information about your use of the Services,
                                            including mobile identifiers and "web beacons" which are small graphic files
                                            (sometimes called "clear GIFs" or "web pixels") embedded in a web page or
                                            email typically used to monitor activity and send relevant information back
                                            to a home server (which can belong to the host site, a network advertiser or
                                            some other third party).</p>
                                        <p className="text-dark w-100">Our advertising service partners may use cookies and other tracking
                                            technologies to collect information about your use of the CareOrbit Sites,
                                            including content you have viewed. These third parties may use this
                                            information to help CareOrbit deliver advertising on the CareOrbit Sites
                                            and on other third party websites based on your browsing activity on the
                                            CareOrbit Sites. CareOrbit may further tailor the advertising on the
                                            CareOrbit Sites and these other third party websites based on additional
                                            information to the extent known by CareOrbit or these third parties. Two
                                            of the third parties that CareOrbit works with are Google and Facebook. In
                                            addition to using the information it collects performing services for
                                            CareOrbit, Google may also use such information as described in its
                                            privacy policy. To see how Google may use information collected through our
                                            use of the Google services on the CareOrbit Sites visit <a
                                                href="https://www.google.com/policies/privacy/partners"
                                                target="_blank">https://www.google.com/policies/privacy/partners</a>.
                                            While Facebook does not provide any personal information to CareOrbit,
                                            Facebook may use information that it has about you and that it collects
                                            performing services for CareOrbit (which may be associated with personal
                                            information that Facebook has about you) as described in its privacy policy
                                            available at <a href="https://www.facebook.com/about/privacy"
                                                            target="_blank">https://www.facebook.com/about/privacy</a>.
                                        </p>
                                        <p className="text-dark w-100">We also work with third party ad networks to display advertising on our
                                            CareOrbit Sites and on third party websites. Our ad network vendors use
                                            technologies to collect information about your activities on the CareOrbit
                                            Sites and in our flagship CareOrbit App to provide you cookie-based
                                            targeted advertising on our CareOrbit Sites and on third party websites
                                            based upon your browsing activity and your interests.</p>
                                        <p className="text-dark w-100">For more information about our how cookies and other tracking technologies
                                            are used in connection with the Services, please read our <Link
                                                to="../cookie-policy" target="_blank">Cookie Policy.</Link></p>
                                        <p className="text-dark w-100">Our use of cookies and other tracking technologies on our mobile optimized
                                            sites and our flagship CareOrbit WebApp are similar to our use on our
                                            desktop sites. Your choices to reject cookies, use the Network Advertising
                                            Initiative and the opt-out mechanism described below in the section "Your
                                            Choices and Rights" are available on our flagship CareOrbit App and on our
                                            mobile optimized sites.</p>
                                        <h2 className="text-dark w-100">How Information Collected About You Is Used</h2>
                                        <p className="text-dark w-100">Information about your use of the Services may be used for the following
                                            purposes:</p>
                                        <ul>
                                            <li>to provide, improve and create new Services,</li>
                                            <li>to respond to your inquiries and to send you administrative
                                                communications about the CareOrbit Sites and Services,
                                            </li>
                                            <li>to obtain your feedback about the CareOrbit Sites and Services,</li>
                                            <li>to send you secure electronic messages and personalized emails
                                                pertaining to your interests as inferred from your use of the Services,
                                                including news, announcements, reminders and opportunities from
                                                CareOrbit,
                                            </li>
                                            <li>to statistically analyze trends and user behavior and activity including
                                                how frequently areas of the CareOrbit Sites are visited, how the
                                                Services are being used and how many emails are received and opened,
                                            </li>
                                            <li>to provide you and people with similar demographic characteristics and
                                                interests with more relevant content including advertising both on and
                                                off the CareOrbit Sites and Apps,
                                            </li>
                                            <li>to offer lead generation services,</li>
                                            <li>to detect and defend against fraud and other threats to the Services and
                                                our users,
                                            </li>
                                            <li>to identify issues with the Services,</li>
                                            <li>to conduct research and measurement activities, including those
                                                described below, and
                                            </li>
                                            <li>to administer your account.</li>
                                        </ul>
                                        <p className="text-dark w-100">In addition, CareOrbit may use personal information about you for other
                                            purposes that are disclosed to you at the time we collect the information
                                            and/or with your consent.</p>
                                        <p className="text-dark w-100">CareOrbit may combine your personal information and other information
                                            collected about your use of the Services, and also supplement with
                                            information from external sources for the purposes described in this Privacy
                                            Policy. For example, information that CareOrbit collects about you may be
                                            combined by CareOrbit with other information available to CareOrbit
                                            through third parties for research and measurement purposes, including
                                            measuring the effectiveness of content, advertising or programs. This
                                            information from other sources may include age, gender, demographic,
                                            geographic, personal interests, product purchase activity or other
                                            information. We may report aggregate information, which is not able to be
                                            identified back to an individual user of the CareOrbit Sites, to our
                                            current or prospective advertisers and other business partners.</p>
                                        <p className="text-dark w-100">CareOrbit will not sell your personal information to third parties for
                                            their use without your consent.</p>
                                        <h2 className="text-dark w-100">Sharing Your Information</h2>
                                        <h3 className="text-dark w-100">CareOrbit Subsidiaries and Corporate Affiliates</h3>
                                        <p className="text-dark w-100">We may share your information with our subsidiaries, affiliates and companies
                                            acquired by or merged with us and our affiliates. In the event of a
                                            corporate change in control resulting from, for example, a sale to, or
                                            merger with, another entity, or in the event of a sale of assets or a
                                            bankruptcy, CareOrbit reserves the right to transfer your personal
                                            information to the new party in control or the party acquiring assets. In
                                            the event of such a change, your personal information will continue to be
                                            treated in accordance with this Privacy Policy unless any changes to the
                                            Privacy Policy are made in accordance with the section below that discusses
                                            "Changes to This Privacy Policy."</p>
                                        <h3 className="text-dark w-100">Companies and Contractors that Work with CareOrbit</h3>
                                        <p className="text-dark w-100">CareOrbit works with third party companies and contractors that help us
                                            provide the Services and to otherwise assist in the operation of the
                                            CareOrbit Sites and Apps, including those that provide services relating
                                            to technology, data analysis, research, email management and deployment,
                                            sweepstakes and contest administration, advertising and marketing and/or
                                            content. CareOrbit contractors sometimes have limited access to your
                                            information in the course of providing products or services to CareOrbit.
                                            We contractually require that our contractors not use or disclose your
                                            information for any purpose other than providing the limited service or
                                            function for CareOrbit.</p>
                                        <h3 className="text-dark w-100">Third Party Advertisers and Third Party Websites</h3>
                                        <p className="text-dark w-100">We may share information that we have about you, such as a cookie ID or IP
                                            address, with third party advertising service providers who may use this
                                            information, on our behalf, to help CareOrbit deliver advertising on the
                                            CareOrbit Sites as well as on third party websites, as further described
                                            in our <Link to="../cookie-policy" target="_blank">Cookie Policy</Link>.</p>
                                        <p className="text-dark w-100">Certain content, services and advertisements offered to you through the
                                            CareOrbit Sites are served on, or contain links to, websites hosted and
                                            operated by a company other than CareOrbit ("Third Party Websites").
                                            CareOrbit does not share your personal information with these Third Party
                                            Websites without your consent, but you should be aware that any information
                                            you disclose to these Third Party Websites once you access these Third Party
                                            Websites is not subject to this Privacy Policy. CareOrbit does not endorse
                                            and is not responsible for the privacy practices of these Third Party
                                            Websites. You should review the privacy policy posted on the Third Party
                                            Website to understand how that Third Party Website collects and uses your
                                            information. CareOrbit makes an effort to make it obvious to you when you
                                            leave a CareOrbit Site and enter a Third Party Website, either by
                                            requiring you to click on a link or by notifying you on the CareOrbit Site
                                            before you visit the third party site. In addition, if you see a phrase such
                                            as "Powered by" or "in association with" followed by the name of a company
                                            other than CareOrbit, then you are on a website hosted by a company other
                                            than CareOrbit. When you use a co-branded service (a service operated with
                                            a partner of CareOrbit, such as CareOrbit Short Survey), or register or
                                            otherwise provide information on a co-branded site, where applicable, we may
                                            pass the collected information back to that partner, which may include third
                                            party service providers whose services are embedded into and/or appear
                                            within the Services.</p>
                                        <p className="text-dark w-100">We may also include social widgets on the CareOrbit Sites which enable you
                                            to interact with the associated social media services, e.g., to share an
                                            article. These widgets may collect browsing data which may be received by
                                            the third party that provided the widget, and are controlled by these third
                                            parties. You may be able to manage your privacy preferences directly with
                                            the applicable social network platform.</p>
                                        <h3 className="text-dark w-100">Compliance with Law, Regulation, and Law Enforcement Requests</h3>
                                        <p className="text-dark w-100">To cooperate with government and law enforcement officials and private
                                            parties to enforce and comply with law, we may release personal information
                                            to third parties: (1) to comply with legal requirements such as a law,
                                            regulation, search warrant, subpoena or court order; (2) when we believe in
                                            good faith that disclosure is necessary to protect our rights, protect your
                                            safety or the safety of others, investigate fraud, or respond to a
                                            government request; or (3) in special cases, such as in response to a
                                            physical threat to you or others, to protect property or defend or assert
                                            legal rights. In addition, we may disclose personal information as described
                                            below.</p>
                                        <h3 className="text-dark w-100">How Your Information is Secured and Retained</h3>
                                        <p className="text-dark w-100">We take reasonable security measures to protect the security of your personal
                                            information. Despite CareOrbit's efforts to protect your personal
                                            information, there is always some risk that an unauthorized third party may
                                            find a way around our security systems or that transmissions of your
                                            information over the Internet may be intercepted.</p>
                                        <p className="text-dark w-100">The security of your personal information is important to us. When you enter
                                            personal information (including personal health information in various
                                            Services), we encrypt the transmission of that information or use SSL
                                            connections (Secure Socket Layer) technology.</p>
                                        <p className="text-dark w-100">We will retain your personal information as long as your account is active or
                                            as needed to provide you Services. At any time you can remove your personal
                                            information or instruct us to remove it, but you should be aware that it is
                                            not technologically possible to remove each and every record of the
                                            information you have provided to CareOrbit from our servers. See "Your
                                            Choices and Rights" below for more information on how you can update or
                                            remove your personal information. We will also retain your personal
                                            information as necessary to comply with legal obligations, resolve disputes
                                            and enforce our agreements.</p>
                                        <h3 className="text-dark w-100">Your Choices and Rights</h3>
                                        <p className="text-dark w-100">Updating/Removing Your Personal Information</p>
                                        <p className="text-dark w-100">If you do not want your personal information used by CareOrbit as provided
                                            in this Privacy Policy, you should not use the CareOrbit Sites, Apps and
                                            Services. You can correct, update or review personal information you have
                                            previously submitted by going back to the specific Service, logging-in and
                                            making the desired change. You can also update any personal information you
                                            have submitted by contacting us using the contact information listed below
                                            or through the Support link provided at the bottom of every page of the
                                            CareOrbit Sites.</p>
                                        <p className="text-dark w-100">If you have registered and desire to delete any of your registration
                                            information you have provided to us from our systems please contact us using
                                            the contact information listed below in the "Contacting CareOrbit About
                                            Your Personal Information or Privacy" section or through the SUpport link
                                            provided at the bottom of every page of the CareOrbit Sites. Upon your
                                            request, we will delete your registration information from our active
                                            databases and where feasible from our back-up media. You should be aware
                                            that it is not technologically possible to remove each and every record of
                                            the information you have provided to the CareOrbit Sites from our
                                            servers.</p>
                                        <p className="text-dark w-100">When you sign up to receive email communications for any of our Services,
                                            including our email newsletters, or at any time, you can choose to <Link
                                                to="../privacy-policy">opt
                                                out</Link> of receiving additional newsletters or other email
                                            communications from CareOrbit or our advertisers or sponsors.</p>
                                        <p className="text-dark w-100">You can unsubscribe from an email newsletter by:</p>
                                        <ul>
                                            <li>Following the directions included at the bottom of the newsletter and
                                                using the "Unsubscribe" link found in the emails.
                                            </li>
                                            <li>Logging in to the CareOrbit Sites and unchecking the newsletters you
                                                no longer wish to receive under the "My Profile" section.
                                            </li>
                                        </ul>
                                        <p className="text-dark w-100">If you decide that you would prefer not to receive personalized email or
                                            secure electronic messages from the CareOrbit Health Manager tool, you may
                                            also "Opt-out" of the Service by clicking on the Profile Manager Tile
                                            located in the center of your Orbit and changing your email preference or
                                            Receive InApp Messages.</p>
                                        <p className="text-dark w-100">All promotional emails that CareOrbit sends with opportunities from
                                            CareOrbit or with offers or informational materials from CareOrbit on
                                            behalf of our sponsors will contain an opportunity to unsubscribe from
                                            getting additional emails of that type in the future from CareOrbit unless
                                            you re-subscribe.</p>
                                        <h3 className="text-dark w-100">Cookies</h3>
                                        <p className="text-dark w-100">Most browser software can be set to reject Cookies. Most browsers offer
                                            instructions on how to reset the browser to reject Cookies in the "Help" or
                                            "Settings" or "Preferences" section of your browser's toolbar. Refer to
                                            our <Link to="../cookie-policy" target="_blank">Cookie Policy</Link> for additional information
                                            about opting out of Cookies.</p>
                                        <h3 className="text-dark w-100">Interest-Based Advertising Opt-Out</h3>
                                        <p className="text-dark w-100">In order to give you more control over your privacy choices we have
                                            implemented a DAA (Digital Advertising Alliance) approved opt-out mechanism
                                            for interest-based advertising. At the bottom of every page of the
                                            CareOrbit Sites you will see an AdChoices logo (which looks like this:).
                                            In our flagship CareOrbit App you can find the AdChoices option in the
                                            "Settings" screen. By clicking on that logo on the CareOrbit Sites or
                                            following the instructions in the flagship CareOrbit WebApp, you will be
                                            presented with a window that will identify all of the companies that
                                            CareOrbit uses to direct tailored advertisements to you, both on the
                                            CareOrbit Sites and in the flagship CareOrbit App as well as on
                                            third-party websites. If you would like to stop this tailored advertising
                                            from CareOrbit, you can manage these advertisements through the choices in
                                            that window. Please note that even if you opt-out of interest-based
                                            advertising, you will continue to receive generic advertisements through the
                                            Services. Please note that your choice to stop tailored advertising is
                                            specific to the browser or mobile device that you are using so if you make a
                                            choice to opt out from one computer or mobile device and you want your
                                            opt-out to apply to a different computer or mobile device as well please opt
                                            out from that computer or mobile device too. By clicking the + symbol next
                                            to the name of a vendor in that window you will get information about how
                                            and where that vendor assists us with the delivery of advertisements. The
                                            CareOrbit Sites do not respond to web browser "do not track" signals.</p>
                                        <h3 className="text-dark w-100">Mobile Opt-out</h3>
                                        <p className="text-dark w-100">You may also control interest-based advertising on your mobile device by
                                            enabling the "Limit Ad Tracking" setting in your iOS device's settings or
                                            "Opt out of Ads Personalization" in your Android device's settings. This
                                            will not prevent you from seeing advertisements, but will limit the use of
                                            device advertising identifiers to personalize ads based on your interests.
                                            If you have questions about how to reject cookies on your specific device
                                            please check with the device manufacturer.</p>
                                        <h3 className="text-dark w-100">California Residents</h3>
                                        <p className="text-dark w-100">FOR RESIDENTS OF CALIFORNIA ONLY. Section 1798.83 of the California Civil
                                            Code requires select businesses to disclose policies relating to the sharing
                                            of certain categories of your personal information with third parties. If
                                            you reside in California and have provided your personal information to us,
                                            you may request information about our disclosures of certain categories of
                                            personal information to third parties for direct marketing purposes in the
                                            preceding calendar year. You can submit such request by sending an email by
                                            using the Support link at the bottom of every page of the CareOrbit
                                            Sites.</p>
                                        <h3 className="text-dark w-100">Children</h3>
                                        <p className="text-dark w-100">We are committed to protecting the privacy of children. The CareOrbit Sites
                                            are not designed or intended to attract children under the age of 13. The
                                            CareOrbit Sites do not collect personal information from any person we
                                            actually know is under the age of 13. A parent or guardian, however, may use
                                            CareOrbit Health Manager to establish a personal health record and a
                                            CareOrbit Health Manager home page for a minor. The parent or guardian
                                            assumes full responsibility for ensuring that the registration information
                                            is kept secure and that the information submitted is accurate. The parent or
                                            guardian also assumes full responsibility for the interpretation and use of
                                            any information or suggestions provided through CareOrbit Oribt for the
                                            minor.</p>
                                        <h3 className="text-dark w-100">Note to Users Outside of the United States</h3>
                                        <p className="text-dark w-100">CareOrbit and our technical infrastructure are located in the United
                                            States. The personal information that you provide to us is stored on servers
                                            located in the United States. If you are located in another jurisdiction,
                                            you should be aware that in order to provide the Services to you, we must
                                            transfer your personal information to the United States where it will be
                                            stored and processed in accordance with this Privacy Policy. We may transfer
                                            your information outside the United States to service providers with
                                            operations in other countries. By using the Services, you consent to such
                                            collection, storage and processing in the United States and elsewhere,
                                            though the United States and other jurisdictions may not afford the same
                                            level of data protection as considered adequate in your own country. We will
                                            take reasonable steps to protect your personal information. Note that your
                                            personal information may be available to the United States government or its
                                            agencies under legal process made in the United States.</p>
                                        <h3 className="text-dark w-100">Additional Information for CareOrbit's Visitors from the European Economic
                                            Area ("EEA")</h3>
                                        <p className="text-dark w-100">When you use the Services, we collect, store, use and otherwise process your
                                            personal information as described in this Privacy Policy. We rely on a
                                            number of legal bases to process your information, including where: (i)
                                            necessary for our legitimate interests in providing and improving the
                                            Services including offering you content and advertising that may be of
                                            interest to you; (ii) necessary for our legitimate interest in keeping the
                                            Services, Sites and Apps safe and secure; (iii) necessary for the legitimate
                                            interests of our service providers and partners; (iv) necessary to perform
                                            our contractual obligations in the CareOrbit Terms of Use; (v) you have
                                            consented to the processing, which you can revoke at any time (however a
                                            revocation does not affect the lawfulness of processing of your personal
                                            data that occurred prior to the date of revocation); (vi) you have expressly
                                            made the information public, e.g., in a CareOrbit Orbit Survey or other
                                            public forums; (vii) necessary to comply with a legal obligation such as a
                                            law, regulation, search warrant, subpoena or court order or to exercise or
                                            defend legal claims; and (viii) necessary to protect your vital interests,
                                            or those of others.</p>
                                        <p className="text-dark w-100">If you are a CareOrbit user in the EEA, you can: (i) access personal
                                            information we have about you (we will try to provide information within 30
                                            days of your request); (ii) have your personal information corrected or
                                            deleted (in most cases you can correct personal information you have
                                            submitted to us by going back to the specific Service, logging-in and making
                                            the desired change); (iii) in certain circumstances, you can object to our
                                            processing of your personal information and we will discontinue such
                                            processing unless we have compelling legitimate grounds to continue; (iv)
                                            withdraw consent previously provided (however a withdrawal does not affect
                                            the lawfulness of processing of your personal data that occurred prior to
                                            the date of withdrawal); or (v) if you believe that we have not complied
                                            with applicable data protection laws, you may lodge a complaint with your
                                            local supervisory authority. If you wish to inquire as to whether we
                                            maintain any of your personal information and if so, whether you wish to
                                            exercise any of those rights that are available to you with respect to such
                                            personal information, you may contact us as described in the Contacting
                                            CareOrbit About Your Personal Information or Privacy section below. We
                                            will respond to your request within a reasonable timeframe.</p>
                                        <p className="text-dark w-100">Where we process your personal information for direct marketing purposes, you
                                            can opt-out through the unsubscribe link in the email communications we send
                                            to you, by changing your subscription preferences in your account settings
                                            or as otherwise specified in this Privacy Policy.</p>
                                        <p className="text-dark w-100">We will retain your personal information for as long as necessary to provide
                                            the Services to you and fulfill the purposes described in this Privacy
                                            Policy. This is also the case for third parties within whom we share your
                                            information to perform services on our behalf. When we no longer need to use
                                            your personal information and there is no need for us to keep it to comply
                                            with our legal or regulatory obligations, we will either remove it from our
                                            systems or anonymize it. If you have registered with CareOrbit and you no
                                            longer want us to use your registration information to provide the Services
                                            to you, you may close your account.</p>
                                        <h3 className="text-dark w-100">Contacting CareOrbit About Your Personal Information or Privacy</h3>
                                        <p className="text-dark w-100">Please send us an email by using the Support link at the bottom of every page
                                            of the CareOrbit Sites if you have any questions about this Privacy Policy
                                            or the personal information we maintain about you.</p>
                                        <p className="text-dark w-100">You can also contact CareOrbit's Privacy Office at:</p>
                                        <p className="text-dark w-100">CareOrbit, LLC</p>
                                        <p className="text-dark w-100">Attn: Office of Privacy</p>
                                        <p className="text-dark w-100">6315 Delmar Blvd., Suite 2E</p>
                                        <p className="text-dark w-100">St. Louis, Missouri 63130</p>
                                        <p className="text-dark w-100"><a href="mailto:copyright@CareOrbit.com">copyright@CareOrbit.com</a></p>
                                        <p className="text-dark w-100">Or call: <a type="tel" href="3145404827">314-540-4827</a></p>
                                        <p className="text-dark w-100">If you have an unresolved privacy or data use concern that we have not
                                            addressed satisfactorily, please contact our U.S.-based third party
                                            dispute resolution provider (free of charge) at <a
                                                href="https://feedback-form.truste.com/watchdog/request"
                                                target="_blank">https://feedback-form.truste.com/watchdog/request</a>.
                                            CareOrbit users from the EEA may also contact our Data Protection
                                            Officer at <a href="mailto:DPO@webmd.net"
                                                          target="_blank">DPO@webmd.net</a>.</p>
                                        <h3 className="text-dark w-100">Changes to this Privacy Policy</h3>
                                        <p className="text-dark w-100">We reserve the right to change or modify this Privacy Policy and any of
                                            our Services at any time and any changes will be effective upon being
                                            posted unless we advise otherwise. If we make any material changes to
                                            this Privacy Policy we will notify you by means of a notice on the
                                            CareOrbit Sites and/or by email to our registered users (sent to the
                                            email address specified when you register) prior to the change becoming
                                            effective. By continuing to use the Services after changes are made to
                                            this Privacy Policy, you agree to such changes. We encourage you to
                                            periodically review this Privacy Policy for the latest information on
                                            our privacy practices. If you do not accept the terms of this Privacy
                                            Policy, we ask that you do not register with us and that you do not use
                                            the CareOrbit Sites. Please exit the CareOrbit Sites immediately if
                                            you do not agree to the terms of this Privacy Policy.</p>
                                        <p className="text-dark w-100">Last updated on March12, 2019</p>
                                        <p className="text-dark w-100"> &copy; 2018 CareOrbit, LLC. All rights reserved.</p>
                                    </div>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p className="text-dark w-100"><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default PrivacyPolicy;