import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";
import { useDispatch } from "react-redux";
import { videoAccessLog } from "../../actions/videoAccessLog";

const LivingWellCenter2_1 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const dispatch = useDispatch();
  const playTeamAprroach = async (e) => {
    const pal = await videoAccessLog(
      { videoName: "About The Living Well Center" },
      () => {
        console.log("About The Living Well Center");
      }
    );
    await dispatch(pal);
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/78618bfd6954d2b22a7ff46b05aee093.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#75B8E4",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Our Team Approach</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L31 Video Box Large */}
                  <div className="lego l031 legoMargin2">
                    <div className="videoContainer">
                      <div className="embedContainer originalPadding">
                        <YouTube
                          videoId="TODLURdvXV8"
                          opts={opts}
                          onPlay={playTeamAprroach}
                        />
                      </div>
                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          HOW OUR TEAM CAN WORK WITH YOU TO MEET YOUR GOALS
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      The Living Well Center will work with you to help you
                      reach your health and wellness goals by creating a
                      personalized plan to help you reach them. We work through
                      lifestyle medicine, which addresses nutrition, physical
                      activity, and mental wellness. We work on stress
                      reduction. We work on improving your sleep and your
                      overall health through different members of our team. 
                      <br />
                    </p>
                    <p>
                      <span className="boldItalic">
                        Success can look different for everyone.
                      </span>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      That’s why your personalized plan will focus on
                      building the team and providing the services that best fit
                      your needs.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps2">
                      THESE PROFESSIONALS INCLUDE
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L95 4 horizontal color boxes with titles */}
                  <div className="lego l095">
                    <div className="contentBox box5">
                      <p>PHYSICIANS</p>
                    </div>
                    <div className="contentBox box3">
                      <p>DIETITIAN</p>
                    </div>
                    <div className="contentBox box6">
                      <p>
                        NURSE
                        <br />
                        PRACTITIONER
                      </p>
                    </div>
                    <div className="contentBox box7">
                      <p>
                        NURSE
                        <br />
                        COORDINATOR
                      </p>
                    </div>
                  </div>
                  {/* L95 4 horizontal color boxes with titles */}

                  {/* L95 4 horizontal color boxes with titles */}
                  <div className="lego l095">
                    <div className="contentBox box8">
                      <p>
                        PHYSICAL <br />
                        THERAPIST
                      </p>
                    </div>
                    <div className="contentBox box2">
                      <p>
                        MEDICAL <br /> MASSAGE EXPERT
                      </p>
                    </div>
                    <div className="contentBox box4">
                      <p>
                        ATHLETIC <br />
                        TRAINER
                      </p>
                    </div>
                    <div className="contentBox box9">
                      <p>PSYCHOLOGIST</p>
                    </div>
                  </div>
                  {/* L95 4 horizontal color boxes with titles */}

                  {/* L95 4 horizontal color boxes with titles */}
                  <div className="lego l095 legoMargin3">
                    <div className="contentBox box10">
                      <p>
                        MENTAL HEALTH
                        <br /> THERAPIST
                      </p>
                    </div>
                    <div className="contentBox box1">
                      <p>ACUPUNCTURIST</p>
                    </div>
                    <div className="contentBox box11">
                      <p style={{ fontSize: "21px" }}>
                        OTHER SPECIALISTS BASED ON YOUR GOALS
                      </p>
                    </div>
                  </div>
                  {/* L95 4 horizontal color boxes with titles */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter2_1;
