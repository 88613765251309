import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport4 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 yellowColorAndBorder legoMargin5">
                    <h1 className="tileTitle">Preparing for Success </h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      The key to success with your procedure and ongoing health
                      journey is being prepared with the knowledge, the plan and
                      the support you’ll need at each step of the way. How well
                      you know what to do, what to watch for, what to expect and
                      who to turn to can have a big effect on your success and
                      the quality of your experience.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L12 Head, Lead In Photo Small with body copy */}
                  <div className="lego l012">
                    {/* L4 Subhead Upper */}
                    <div className="lego l004 legoMargin3">
                      <h2 className="subHeadAllCaps">
                        Understanding what to expect.
                      </h2>
                    </div>
                    {/* End L4 Subhead Upper */}
                    <div className="contentContainer">
                      <div className="imgContainer">
                        <img
                          className=""
                          alt="Image of doctors smiling"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2f4b3bdde70c43672e7baa3167f723e6.png"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p>
                            <span className="bold italic">
                              Procedures come with a lot of information to take
                              in, sometimes in a short amount of time.
                            </span>
                          </p>
                          <p>
                            It’s easy to forget key information and
                            instructions. And that can interfere with your
                            treatment plan, and can even lower your chance of
                            the healthiest outcomes.
                          </p>
                          <p>
                            Make sure you{" "}
                            <span className="bold italic">
                              understand the key information
                            </span>{" "}
                            relevant to your treatment plan.
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12 Head, Lead In Photo Small with body copy */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph legoMargin1">
                    <p className="boldLeadInNewUI">
                      <span className="italic">This includes:</span>
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}
                  {/* L53 Bullet List */}
                  <div className="lego l053 smallFont">
                    <ul className="w-100">
                      <li className="w-100 legoMargin1">
                        Knowing the nature of your procedure(s)
                      </li>
                      <li className="w-100 legoMargin1">
                        How to come into the procedure as healthy and primed for
                        success as possible
                      </li>
                      <li className="w-100 legoMargin1">
                        Completing all required pre-meetings and logistics needs
                      </li>
                      <li className="w-100 legoMargin1">
                        Knowing what to expect on the day of your procedure –
                        for you and any family members
                      </li>
                      <li className="w-100 legoMargin1">
                        Understanding what to watch for in terms of
                        complications and progress
                      </li>
                      <li className="w-100 legoMargin1">
                        Understanding how to speed your recovery and make sure
                        your environment and activities serve your health
                      </li>
                      <li className="w-100 legoMargin1">
                        Understanding and making sure you make all follow-up
                        meetings and milestones
                      </li>
                      <li className="w-100 legoMargin1">
                        Knowing what resources you have at your disposal that
                        can help you or your family
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}
                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L12 Head, Lead In Photo Small with body copy */}
                  <div className="lego l012 legoMargin3">
                    {/* L3 Subhead Upper and Lower */}
                    <div className="lego l003">
                      <h2 className="subHeadUpperAndLowerCase">
                        Working with your team.{" "}
                      </h2>
                    </div>
                    {/* End L3 Subhead Upper and Lower */}
                    <div className="contentContainer">
                      <div className="imgContainer">
                        <img
                          className=""
                          alt="Image of doctors smiling"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/20a4be810f762845fc68010524845879.png"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p>
                            Your care team is here to provide world-class health
                            care in a compassionate, respectful and responsive
                            way. We are committed to help you during this
                            journey.
                          </p>
                          <p>
                            Teams are made up of a variety of professionals who
                            work in combination toward your healthy outcomes.
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12 Head, Lead In Photo Small with body copy */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">
                        Registered Nurse (RN) and Patient Care Tech (PCT)
                      </span>
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your care team is trained in orthopedics and will follow
                      your surgeon’s plan for your rapid recovery. They will
                      teach you and your joint coach how to stay safe and
                      healthy during every step of your recovery.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">
                        Physical Medicine and Rehabilitation (Physiatry) Team
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your physiatrist (also known as a physical medicine and
                      rehabilitation specialist) uses nonsurgical approaches to
                      pain and injury treatment with a focus on the whole person
                      and their physical, psychological, social, and vocational
                      potential.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">Anesthesia Team</span>
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      An anesthesia physician or advanced practice nurse will
                      evaluate your surgery needs. This team is specially
                      trained to keep you safe during surgery and in recovery.
                      They are key team members to manage your pain. You will
                      meet a member of this team at your surgical evaluation at
                      the Center for Preoperative Assessment and Planning
                      (CPAP).
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">
                        Physical Therapist (PT)
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your physical therapy team plans the best way for you to
                      build your strength before and after receiving your new
                      joint. This team utilizes special training to help get you
                      moving and boost your abilities with your new joint.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">
                        Nurse Practitioner (NP)
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your advanced practice registered nurse will work with
                      your surgeon to manage your care. Your nurse practitioner
                      will see you on the floor after your surgery.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">
                        Occupational Therapist (OT)
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your occupational therapist will help you plan to safely
                      complete daily activities, like dressing and bathing. They
                      will teach you how to be independent at home.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">Case Manager</span>{" "}
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your case manager is trained as a registered nurse or
                      social worker. Your case manager will plan with your care
                      team to best assist your needs regarding discharge, home
                      equipment and help you with your insurance questions.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L10A Bold Lead In */}
                  <div className="lego l00paragraph">
                    <p>
                      {" "}
                      <span className="boldLeadInNewUI">
                        Orthopedic Concierge (available at Barnes-Jewish
                        Hospital)
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L10A Bold Lead In */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin5">
                    <p>
                      The orthopedic concierge will help with any lodging needs
                      for your loved ones during or after your stay. They will
                      visit you and your family during your stay and explain
                      services offered, such as computer use, notaries,
                      restaurant suggestions, attractions, transportation and
                      explaining the medical campus. The concierge focuses on
                      providing an exceptional experience for you and your loved
                      ones while you are at the hospital.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport4;
