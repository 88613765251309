import React from "react";

function L96ComponentCode() {
  return (
    <div className="lego l096 legoMargin3">
      <div className="contentContainer">
        {/* L53 Bullet List */}
        <div className="lego l053 customBulletList">
          <ul className="w-100">
            <li className="w-100">
              <p className="withBorderBottom">
                Do not use the system while driving, operating machinery, or
                during any activity in which sudden muscle tension may put you
                at risk of injury
              </p>
            </li>
          </ul>
        </div>
        {/* End L53 Bullet List */}
        {/* L53 Bullet List */}
        <div className="lego l053 customBulletList">
          <ul className="w-100">
            <li className="w-100">
              <p className="withBorderBottom">
                Do not use the system while driving, operating machinery, or
                during any activity in which sudden muscle tension may put you
                at risk of injury
              </p>
            </li>
          </ul>
        </div>
        {/* End L53 Bullet List */}

        {/* L53 Bullet List */}
        <div className="lego l053 customBulletList">
          <ul className="w-100">
            <li className="w-100">
              <p className="withBorderBottom">
                Do not use the system while driving, operating machinery, or
                during any activity in which sudden muscle tension may put you
                at risk of injury
              </p>
            </li>
          </ul>
        </div>
        {/* End L53 Bullet List */}

        {/* L53 Bullet List */}
        <div className="lego l053 customBulletList">
          <ul className="w-100">
            <li className="w-100">
              <p className="withBorderBottom">
                Do not use the system while driving, operating machinery, or
                during any activity in which sudden muscle tension may put you
                at risk of injury
              </p>
            </li>
          </ul>
        </div>
        {/* End L53 Bullet List */}
      </div>
    </div>
  );
}

export default L96ComponentCode;
