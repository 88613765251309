import { AUTH_USER, AUTH_ERROR } from "../actions/types";

const INITIAL_STATE = {
    authenticated: "",
    errorMessage: "",
    expiresIn: "",
};

export const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                authenticated: action.payload.jwt,
                expiresIn: action.payload.expiresIn
            };

        case AUTH_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
