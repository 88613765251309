import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL12AComponent({ code, language }) {
    code = `{/* L12A Head, Lead In Photo Larger with body copy */}
                                    <div className="lego l012A">
            {/* L3 Subhead Upper and Lower */}
            <div className="lego l003">
                <h2 className="subHeadUpperAndLowerCase">TAI CHI</h2>
            </div>
            {/* End L3 Subhead Upper and Lower */}
            <div className="contentContainer">
                <div className="imgContainer">
                    <img className="" alt="Adults of Mulitple Ethnicities doing Tai Chi" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2f23ab9a6de41bfefce057874fd73722.png" />
                </div>
                <div className="copyContainer">
                    {/* L14 Paragraph */}
                    <div className="lego l00paragraph legoMargin3">
                        <p>Tai Chi is a mindfulness approach that employs slow and fast intentional movements, breathing techniques, and a rich array of cognitive tools to relax, center and strengthen the body and mind. Tai Chi is practiced for health, personal development, and self-defense.
                        </p>
                        <p className="legoMargin3"><span className="bold italic">Medical research supports that Tai Chi and Qigong can improve balance, musculoskeletal strength and flexibility, cardio-respiratory health, cognitive function, immune system response, and emotional well-being.</span></p>
                        <p>Click to <a href="https://www.ortho.wustl.edu/content/Patient-Care/8422/Services/Living-Well-Center/Tai-Chi-Program.aspx" target="_blank" rel="noreferrer noopener">Learn More</a></p>
                    </div>
                    {/* End L14 Paragraph */}
                </div>
            </div>
        </div>
                                    {/* End L12A Head, Lead In Photo Larger with body copy */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL12AComponent;