import {
    ACTIVATION_ERROR,
    ACTIVATION_HEADING_TEXT,
    REGISTER_INFO,
    REGISTER_INFO_BOTTOM_FORM_ERROR,
    REGISTER_INFO_ERROR
} from "../actions/types";

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    activationHeadingText: "",
    errorMessage: "",
    bottomFormErrorMessage: ""
};

export const registerInfoReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER_INFO:
            return {
                ...state,
                firstName: action.payload?.firstName,
                lastName: action.payload?.lastName,
                emailAddress: action.payload?.emailAddress
            };

        case REGISTER_INFO_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        case ACTIVATION_HEADING_TEXT:
            return {
                ...state,
                activationHeadingText: action.payload?.activationHeadingText
            };

        case ACTIVATION_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        case REGISTER_INFO_BOTTOM_FORM_ERROR:
            return {
                ...state,
                bottomFormErrorMessage: action.payload
            };

        default:
            return state;
    }
};
