import React from 'react';
import Container from 'react-bootstrap/Container';
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";

const LessonMovingFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonMoving">Moving Safely in the Home</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonMoving/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonMoving/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I help prevent a person from falling?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Have the person exercise regularly and have their eyes checked by an eye doctor each year. When you take the person to the doctor, ask for a review of their medicines to reduce any drug side effects. </p>
                                                <p>You can make changes to remove fall hazards in the home. If the person uses a cane or walker, be sure he or she uses it correctly. Have a person get up slowly from a lying or a sitting position, wear shoes inside and outside the house, and avoid wearing slippers or going barefoot.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If a person has fallen once, is there a good chance they will fall again?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Yes, a person who has a history of falling is at risk to fall again.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow centered"><i className="fa fa-exclamation-circle"></i>When should I call 911 if a person falls?</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li><strong>Call 911</strong> if the person is unconscious, difficult to arouse or is having a hard time breathing.</li>
                                                    <li><strong>Call 911</strong> if you know the person hit their head or if the person has:</li>
                                                    <ul className="listIndent">
                                                        <li>Bleeding from the head or face </li>
                                                        <li>Severe headache </li>
                                                        <li>Slurred speech </li>
                                                        <li>Repeated vomiting </li>
                                                        <li>Seizures</li>
                                                    </ul>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-6 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill sidebarNoFillborderColor marginRight">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/cdc_logo.jpg" />
                                                        <p className="resourcePartner"><strong>Centers for Disease Control and Prevention (CDC)</strong></p>
                                                        <p>The CDC Injury Center is a national program to reduce injury, disability, death, and costs associated with injuries outside the workplace. The website offers information on a wide range of injury topics and injury prevention.</p>
                                                        <p>visit: <a href="https://www.cdc.gov/homeandrecreationalsafety/falls/index.html" target="_blank">www.cdc.gov/homeandrecreationalsafety/falls/index.html</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill sidebarNoFillborderColor  marginRight">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Unknown-1.png" />
                                                        <p className="resourcePartner"><strong>Mayo Clinic</strong></p>
                                                        <p>The Mayo Clinic website offers information on numerous health topics. In the Senior Health Section, you can access information on fall prevention and ways to reduce fall risk.</p>
                                                        <p>visit: <a href="http://www.mayoclinic.org/ fall-prevention/art-20047358" target="_blank">http://www.mayoclinic.org/ fall-prevention/art-20047358</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-6 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill sidebarNoFillborderColor marginRight">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/National_Safety_Council.svg.png" />
                                                        <p className="resourcePartner"><strong>National Safety Council</strong></p>
                                                        <p>The Council is a non-for-profit charitable organization that has the mission to educate people on ways to prevent accidental injury and death. The website contains numerous resources on falls and fall prevention tips.</p>
                                                        <p>visit: <a href="https://www.nsc.org/safety_home/Resources/Pages/Falls.aspx" target="_blank">www.nsc.org/safety_home/Resources/Pages/Falls.aspx</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonMovingFAQResources;
