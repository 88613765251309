import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanPancreaticAll2_1 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-1-1">
                                        <div
                                            className="tile t_013 t_013_3x2 tileWithCenterBanner Height2RowTall width3Column maintainingYourHealthBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Maintaining Your Health</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>

                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-1-2">
                                        <div className="tile t_003 Height1RowTall width2Column lightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-checklist-hov.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Resources ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-1-3">
                                        <div className="tile t_003 Height1RowTall width2Column paleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-help-in-planning-hov.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Resources ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll2_1;