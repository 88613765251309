import React from "react";

function L90ComponentCode() {

    return (

        <div className="lego l090 legoMargin3">
            <div className="headerColorBar">
                <h3>COMPLICATIONS:</h3>
            </div>
            <div className="contentContainer">
                <ul>
                    <li>The most common risks <span className="bold italic">are skin irritation and redness</span> that
                        usually
                        go away within a few days without the need for medical care.
                    </li>
                    <li><span className="bold italic">Infection</span> (may be indicated by
                        a fever of 100.4o or greater, heat,
                        redness, or drainage at the injection site)
                    </li>
                    <li>Review the <span className="bold italic">Warnings, Precautions & Risks section</span> in
                        the <span className="bold italic">Patient
                                                    Instructions for Use</span> for potential hazard that may cause
                        injury,
                        discomfort, or damage to your system. Examples include MRI &
                        diathermy.
                    </li>
                </ul>
            </div>
        </div>

    )
};


export default L90ComponentCode;