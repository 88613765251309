import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonSuppositoriesWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation orangeImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonSuppositories">Recal Suppositories</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonSuppositories/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonSuppositories/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonSuppositories/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonSuppositories/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Watch for Expected Effects of Medicine</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>People take medicines for an expected response. A drug called an anti-emetic (such as Compazine&nbsp;) relieves vomiting and nausea, the sensation of feeling sick to the stomach.</p>
                                        <p>When you know the expected <span className="bold">effect of a medicine, you can judge how a person responds to it. This is important.</span></p>
                                        <p>For example, if a medicine is for pain relief, you can watch a person and later ask if they become more comfortable. If a medicine is for nausea, ask the person 30 minutes after taking the medicine if their stomach feels less queasy and has the vomiting lessened?</p>
                                        <p>As a family caregiver, observe the person to whom you provide care. When he or she <span className="bold">does not respond to a medicine as expected, report this to the doctor</span> so that the treatment may be adjusted as needed.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/RS_woman_with_medication.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Proper Storage</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Always <span className="bold">store a suppository in the refrigerator until you are ready to give one</span>. If not stored properly, the medicine will melt.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Vagal Stimulation</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If the person becomes dizzy, pale or sweaty after getting a suppository, this might mean you stimulated the vagus nerve. The nerve is located close to the rectal wall. When it is stimulated, the person’s pulse rate might slow.</p>
                                        <p>When dizziness occurs, have the person lie down. This helps the dizziness to go away. Stay with the person and if the symptoms remain for more than 10 minutes, call their doctor. You should take their pulse as they lie down. <a href="/LessonPulse" target="_blank">See our lesson on Taking a Pulse</a>.</p>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        <hr className="w-100" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="safetytips">SAFETY TIPS</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/RS_suppositoires_in_foil.png" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom orangeBullets checkBulletsSafeCare ">
                                                <li>Always <span className="bold">take the foil wrapper off</span> a suppository before inserting it.</li>
                                                <li>Check with the person’s doctor about giving a suppository <span className="bold">when the person has had a recent heart attack or has an irregular heart beat.</span> Insertion of a suppository can irritate a nerve in the rectum (vagus nerve), causing the heart rate to slow. If the vagus nerve is irritated the person may become dizzy, pale or sweaty.</li>
                                                <li><span className="bold">Never let a person take a rectal medicine by mouth.</span> Only medicines labeled as “rectal preparations” should be placed in the rectum.</li>
                                                <li><span className="bold">Ask if the person to whom you provide care has a latex allergy.</span> If so, you need to use non-latex gloves when giving a suppository.</li>
                                                <li><span className="bold">Always wear clean gloves</span> when giving a suppository to prevent the chance of you getting an infection.</li>
                                                <li>After insertion, remove gloves and <span className="bold">wash or disinfect your hands thoroughly.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick="scrollToTop();return false">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="width100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100 text-center">Giving a Rectal Suppository</h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="z03U-jWz8DY" opts={opts} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/RS_equipment.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Preparing Medicines</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Rectal suppository</li>
                                                    <li>Pair of clean disposable gloves</li>
                                                    <li>Water-based jelly, like KY&reg; jelly</li>
                                                    <li>Toilet Tissue</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer orangeBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonSuppositoriesWhatToDoAndSafety;