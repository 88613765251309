import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_6_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 greenColorAndBorder legoMargin5">
                    <h1 className="tileTitle">Viscosupplementation</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin3">
                    <h2 className="subHeadUpperAndLowerCase2">
                      About the Procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      Viscosupplementation is a procedure that involves
                      injecting hyaluronic acid, a thick gel-like fluid, into a
                      joint.  It is commonly used to treat joint pain,
                      especially in osteoarthritis, by providing cushioning and
                      lubrication.
                    </p>
                    <p className="legoMargin2">
                      The treatment involves a series of injections. Brand Names
                      for this medicine could include: Durolane, Euflexxa,
                      Gel-One, GelSyn-3, GenVisc 850, Hyalgan, Hymovis,
                      Monovisc, Orthovisc, Supartz FX, TriLURON, TriVisc,
                      and Visco-3.
                    </p>
                    <p>
                      <span className="bold italic">
                        When Not to Use This Medicine:
                      </span>
                    </p>{" "}
                    <p>
                      <ul>
                        <li className="legoMargin1">
                          Do not use if you're allergic to hyaluronic acid.
                        </li>
                        <li>
                          Not suitable for people with bleeding disorders.
                        </li>
                      </ul>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin1">
                    <h2 className="subHeadUpperAndLowerCase2">
                      Prior to the Procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin1">
                    <p>
                      <span className="bold">Scheduling Appointments</span>
                    </p>{" "}
                    <p className="legoMargin2">
                      <ul>
                        <li className="legoMargin1">
                          Your healthcare provider will schedule your injection
                          appointments at least 8 weeks following when it is
                          ordered to give time for them to obtain authorization
                          from insurance.
                        </li>
                        <li>
                          Ensure you understand the treatment timeline and any
                          follow-up required.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <span className="bold">
                        Before you schedule your injection appointment
                      </span>
                    </p>{" "}
                    <p className="legoMargin1">
                      <ul>
                        <li className="legoMargin1">
                          Tell your doctor if you are pregnant or breastfeeding,
                          or if you have any allergies, including to birds,
                          feathers, or eggs.
                        </li>
                        <li>
                          <span className="bold italic">Things to Avoid.</span>{" "}
                          Talk to your doctor before using other medicines,
                          vitamins, or herbal supplements.
                        </li>
                      </ul>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L88C List with Background Color */}
                  <div className="lego l088C legoMargin3">
                    <div className="contentContainer">
                      <h4>
                        Insurance
                        <span className="headingContent"></span>
                      </h4>
                      <p>
                        <span className="subHeadingContent">
                          Preauthorization (Precertification):
                        </span>
                        <br />
                        It is likely we will need to request preauthorization
                        from your insurance to ensure the treatment is covered.
                        This process can take several days to weeks and delays
                        can occur. It is crucial to be patient, stay in
                        communication with our office and be proactive in
                        checking in with your insurance company.
                      </p>
                      <br />
                      <p>
                        <span className="subHeadingContent">
                          Insurance Denials
                        </span>
                        <br />
                        If your insurance denies coverage, ask your provider
                        about an appeal process or alternative treatment
                        options. Consider appointing a family member or friend
                        to help manage communications with your insurance if the
                        process becomes overwhelming for you.
                      </p>
                    </div>
                  </div>
                  {/* End L88C List with Background Color */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin1">
                    <p>
                      <span className="bold ">
                        Specialty Pharmacy Coordination:
                      </span>
                    </p>{" "}
                    <div className="lego l053 smallFont legoMargin2">
                      <ul className="w-100">
                        <li className="w-100 legoMargin1">
                          Hyaluronic acid is often sourced from specialty
                          pharmacies due to its specific storage and handling
                          requirements.
                        </li>
                        <li className="w-100 legoMargin1">
                          You may need to provide your healthcare provider with
                          your specialty insurance information to facilitate the
                          ordering process.
                        </li>
                        <li className="w-100 legoMargin1">
                          You might need to call the specialty pharmacy once
                          your prescription is approved to confirm they can ship
                          to your provider’s office. Often the specialty
                          pharmacy may call you too, please make sure to pick up
                          your calls, or check voicemails to return these
                          calls. Your medication will not be shipped without
                          your approval.
                        </li>
                        <li className="w-100 legoMargin1">
                          Ensure the pharmacy has the correct address and
                          details to ship the medication directly to your
                          doctor's office.
                        </li>
                        <li className="w-100 legoMargin1">
                          Please note that there is a possibility of a co-pay or
                          deductible before the pharmacy will ship the
                          medication to your doctor’s office.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin1">
                    <h2 className="subHeadUpperAndLowerCase2">
                      After the procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L53 Bullet List */}
                  <div className="lego l053 smallFont legoMargin3">
                    <ul className="w-100">
                      <li className="w-100 legoMargin1">
                        Rest the injected area for 48 hours after you receive an
                        injection. Do not do strenuous, weight bearing
                        activities, such as jogging or tennis.
                      </li>
                      <li className="w-100 legoMargin1">
                        Avoid activities that keep you engaging the joint for
                        longer than 1 hour. 
                      </li>
                      <li className="w-100 legoMargin1">
                        Avoid standing too long.
                      </li>
                      <li className="w-100 legoMargin1">
                        Report any unusual symptoms or reactions to your
                        provider immediately. Some side effects from the
                        medication include pain, redness, or swelling where the
                        medicine is injected.
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004">
                    <h2 className="subHeadAllCaps700">
                      Possible Side Effects:
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003A">
                    <p>
                      <span className="subheadingContent">
                        Contact your doctor if you notice any side effects
                      </span>
                    </p>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L82? Content 2 sides 50% width */}
                  <div className="lego l082A legoMargin5">
                    <div className="contentLeftSide">
                      {/* L85 Color Bar Top Content Block */}
                      <div className="lego l085A ">
                        <div
                          className="headerColorBar"
                          style={{ background: "#FF2E00" }}
                        >
                          <h3>Serious:</h3>
                        </div>
                        <div className="contentContainer">
                          <p>
                            Allergic reactions like itching, hives, swelling in
                            your face or hands, tingling in your mouth or
                            throat, chest tightness, or trouble breathing.
                          </p>
                        </div>
                      </div>
                      {/* End L85 Color Bar Top Content Block */}
                    </div>
                    <div className="contentRightSide">
                      {/* L85 Color Bar Top Content Block */}
                      <div className="lego l085A ">
                        <div
                          className="headerColorBar"
                          style={{
                            background: "#FFB800",
                            borderColor: "#FFB800",
                            borderWidth: "1px",
                          }}
                        >
                          <h3>Less Serious:</h3>
                        </div>
                        <div className="contentContainer">
                          <p>
                            Slight increase in knee pain or swelling, pain,
                            redness, or swelling at the injection site.
                          </p>
                        </div>
                      </div>
                      {/* End L85 Color Bar Top Content Block */}
                    </div>
                  </div>
                  {/* End L82? Content 2 sides 50% width */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">WHEN YOU GET HOME:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin5 w-80 m-auto">
                    <p>
                      For questions during normal working hours, call our office
                      at&nbsp;
                      <span className="bold">
                        <a className="phoneLink" href="tel:3147472823">
                          314-273-1832.
                        </a>
                      </span>
                      <br />
                      <span className="bold">
                        For urgent concerns after hours
                      </span>
                      , call our exchange at{" "}
                      <span className="bold">
                        <a className="phoneLink" href="tel:314-388-5550">
                          314-388-5550
                        </a>
                        .
                      </span>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_6_2;
