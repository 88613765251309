import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll4_2_9 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Surveillance</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p><span className="bold">Surveillance</span> means that your doctors will be watching you closely to see if your cancer comes back or not.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight">
                                        <h2 className="subheadUpperAndLower mb-0">What to expect during surveillance</h2>
                                    </div>
                                    <div className="withBorder borderAccentBlueColor">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Clinic visits:</p>
                                                <p>Your surgeon and medical providers will see you in the clinic on a regular basis. The longer it has been since your treatment, the more spaced apart your appointments will be.</p>
                                                <p className="boldLeadIn">Tests:</p>
                                                <p>Scans and other tests will likely be ordered to see if the cancer has come back. Your surgeon and medical providers will discuss the types of tests and how often you will have them performed. The further you get out from your treatment, the fewer number of scans you will need.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center floatLeft">Important Milestones</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">2 years: </p>
                                    <p>For most cancers, if a cancer is going to come back, it will happen within the first 2 years following treatment. You will be watched most closely during these first 2 years. This means you may have more clinic visits and tests during this time. After 2 years, the visits will usually not be as frequent.</p>
                                    <p className="boldLeadIn">5 years:</p>
                                    <p>For most cancers, if a cancer has not come back after 5 years following treatment, then you are considered cured of the cancer.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll4_2_9;