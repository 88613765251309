import {getRequest, postRequest, putRequest} from "../../src/api/index.js";
import {ORBIT_LIST_BY_ORGANIZATION, ORBIT_ERROR} from "../../src/actions/types.js";

export const orbit = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await getRequest(`/orbit/all${data.organizationId ? '/' + data.organizationId : ''}${data.organizationDepartmentId ? '?organizationDepartmentId=' + data.organizationDepartmentId : ''}`, "orbit", data);

        cb(res.data);
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                dispatch({
                    type: ORBIT_ERROR,
                    payload: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                dispatch({
                    type: ORBIT_ERROR,
                    payload: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: ORBIT_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: ORBIT_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: ORBIT_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};
