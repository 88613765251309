import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTL6_0 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideoWelcome = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Welcome to the Journey'}, () => {
            console.log('Learning Gallery: Welcome to the Journey');
        });
        await dispatch(pal);
    }

    const playVideoHelpingYourself = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Helping Yourself on Your Journey'}, () => {
            console.log('Learning Gallery: Hlping Yourself on Your Journey');
        });
        await dispatch(pal);
    }

    const playVideoWhatToExpect = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: What to expect as an In-patient'}, () => {
            console.log('Learning Gallery: What to expect as an In-patient');
        });
        await dispatch(pal);
    }

    const playVideoTubeFeeding = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Tube Feeding'}, () => {
            console.log('Learning Gallery: Tube Feeding');
        });
        await dispatch(pal);
    }

    const playVideoDrainCare = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Caring for your JP Drain'}, () => {
            console.log('Learning Gallery: Caring for your JP Drain');
        });
        await dispatch(pal);
    }

    const playVideoSkinGraft = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Caring for Your Skin Graft'}, () => {
            console.log('Learning Gallery: Caring for Your Skin Graft');
        });
        await dispatch(pal);
    }

    const playVideoWoundPacking = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Wound Packing'}, () => {
            console.log('Learning Gallery: Wound Packing');
        });
        await dispatch(pal);
    }

    const playVideoSpeechSwallow = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Speech and Swallow Therapy After A Tracheostomy'}, () => {
            console.log('Learning Gallery: Speech and Swallow Therapy After A Tracheostomy');
        });
        await dispatch(pal);
    }

    const playVideoWorkingWithTherapists = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Working With Therapists'}, () => {
            console.log('Learning Gallery: Working With Therapists');
        });
        await dispatch(pal);
    }

    const playVideoOurJourney = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Our Journey'}, () => {
            console.log('Learning Gallery: Our Journey');
        });
        await dispatch(pal);
    }

    const playVideoAskingQuestions = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Asking Questions'}, () => {
            console.log('Learning Gallery: Asking Questions');
        });
        await dispatch(pal);
    }

    const playVideoTakingAction = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Taking Action'}, () => {
            console.log('Learning Gallery: Taking Action');
        });
        await dispatch(pal);
    }

    const playVideoOvercomingFears = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Overcoming Fears'}, () => {
            console.log('Learning Gallery: Overcoming Fears');
        });
        await dispatch(pal);
    }

    const playVideoCommunicating = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Communicating'}, () => {
            console.log('Learning Gallery: Communicating');
        });
        await dispatch(pal);
    }

    const playVideoFeeding = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Feeding'}, () => {
            console.log('Learning Gallery: Feeding');
        });
        await dispatch(pal);
    }

    const playVideoNutrition = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Nutrition'}, () => {
            console.log('Learning Gallery: Nutrition');
        });
        await dispatch(pal);
    }

    const playVideoCaregiver = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Caring for the Caregiver'}, () => {
            console.log('Learning Gallery: Caring for the Caregiver');
        });
        await dispatch(pal);
    }

    const playVideoSurvivorGroups = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Survivor Groups'}, () => {
            console.log('Learning Gallery: Survivor Groups');
        });
        await dispatch(pal);
    }

    const playVideoPowerOfSupport = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: The Power of Support'}, () => {
            console.log('Learning Gallery: The Power of Support');
        });
        await dispatch(pal);
    }

    const playVideoCaringForYourTracheostomy = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Caring for Your Tracheostomy'}, () => {
            console.log('Learning Gallery: Caring for Your Tracheostomy');
        });
        await dispatch(pal);
    }

    const playVideoCaringForYourLaryngectomy = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Caring for Your Laryngectomy'}, () => {
            console.log('Learning Gallery: Caring for Your Laryngectomy');
        });
        await dispatch(pal);
    }

    const playVideoSpeechAndSwallowAfterATotalLaryngectomy = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery:Speech and Swallow after a Total Laryngectomy'}, () => {
            console.log('Learning Gallery: Speech and Swallow after a Total Laryngectomy');
        });
        await dispatch(pal);
    }

    const playVideoLivingYourLifeAfterTotalLaryngectomy = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Living Your Life After Total Laryngectomy'}, () => {
            console.log('Learning Gallery: Living Your Life After Total Laryngectomy');
        });
        await dispatch(pal);
    }

    const playVideoPreparingYourself = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: Preparing Yourself'}, () => {
            console.log('Learning Gallery: Preparing Yourself');
        });
        await dispatch(pal);
    }

    const playVideoThePowerOfSupportAndHowFamiliesCanHelp = async (e) => {
        const pal = await videoAccessLog({videoName: 'Learning Gallery: The Power of Support and How Families Can Help'}, () => {
            console.log('Learning Gallery: The Power of Support and How Families Can Help');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entLearningGalleryPurpleColor entLearningGalleryPurpleBorderColor">Learning
                                                Gallery</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>For your convenience, this page contains all the videos found throughout
                                                this guide that help patients and their family caregivers better
                                                understand, plan and work toward better outcomes.</p>
                                        </div>
                                    </div>

                                    <div className="col-12 videoPage">

                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperAndLower w-100 text-center">Preparations</h2>
                                            </div>
                                        </div>

                                        {/*Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="_syHcBLHtxA" opts={opts} onPlay={playVideoWelcome}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Welcome to the Journey</span>
                                            </h3>

                                        </div>

                                        {/* End Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="umWIvIe9144" opts={opts} onPlay={playVideoHelpingYourself}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Helping Yourself on Your Journey</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="pNjD3WTIbmQ" opts={opts} onPlay={playVideoWhatToExpect}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">What to expect as an In-patient</span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12 videoPage">

                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperAndLower w-100 text-center">Care At Home</h2>
                                            </div>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="vJ2lT23UAk8" opts={opts} onPlay={playVideoTubeFeeding} />
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Tube Feeding</span></h3>

                                        </div>

                                        {/*Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="ziaHMkGOW3E" opts={opts} onPlay={playVideoDrainCare}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for your JP Drain</span>
                                            </h3>

                                        </div>

                                        {/* End Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="HVxdryxalMg" opts={opts} onPlay={playVideoSkinGraft}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for Your Skin Graft</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="iMHbcg67DnU" opts={opts} onPlay={playVideoWoundPacking}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Wound Packing</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="lHOrCmfq1pE" opts={opts} onPlay={playVideoSpeechAndSwallowAfterATotalLaryngectomy}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Speech and Swallow Therapy After A Tracheostomy</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="PVEM_dAd1hQ" opts={opts} onPlay={playVideoWorkingWithTherapists}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Working With Therapists</span>
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-12 videoPage">

                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperAndLower w-100 text-center">Additional Advice
                                                    from Past Patients and Spouses</h2>
                                            </div>
                                        </div>

                                        {/*Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="hPLZeU27pdU" opts={opts} onPlay={playVideoOurJourney}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Our Journey</span>
                                            </h3>

                                        </div>

                                        {/* End Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="V50Lt2yetOk" opts={opts} onPlay={playVideoAskingQuestions}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Asking Questions</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4Oh6LlmVBDA" opts={opts} onPlay={playVideoTakingAction}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Taking Action</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="yNRfLR6o4sQ" opts={opts} onPlay={playVideoOvercomingFears}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Overcoming Fears</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="Ob1-mNcoOMM" opts={opts} onPlay={playVideoCommunicating}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Communicating</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="WhCgjmMFPFo" opts={opts} onPlay={playVideoFeeding}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Feeding</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="rWoDuNFFYtY" opts={opts} onPlay={playVideoNutrition}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Nutrition</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4t08QXZpQmg" opts={opts} onPlay={playVideoCaregiver}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for the Caregiver</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="I8WFvUGJq_A" opts={opts} onPlay={playVideoSurvivorGroups}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Survivor Groups</span>
                                            </h3>
                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="8VZutRnnMLk" opts={opts} onPlay={playVideoPowerOfSupport}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">The Power of Support</span>
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="col-12 videoPage">

                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperAndLower w-100 text-center">Additional
                                                    learning for patients with a Tracheostomy</h2>
                                            </div>
                                        </div>

                                        {/*Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="7qoFQTna1Ec" opts={opts} onPlay={playVideoCaringForYourTracheostomy}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for Your Tracheostomy</span>
                                            </h3>

                                        </div>
                                    </div>

                                    <div className="col-12 videoPage">

                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperAndLower w-100 text-center">Additional
                                                    learning for patients with a Total Laryngectomy</h2>
                                            </div>
                                        </div>

                                        {/*Create Video Component that loops through videos*/}

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="P1bBtCzc-3w" opts={opts} onPlay={playVideoCaringForYourLaryngectomy}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for Your Laryngectomy</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="D77iIGYdX9w" opts={opts} onPlay={playVideoSpeechAndSwallowAfterATotalLaryngectomy}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Speech and Swallow after a Total Laryngectomy</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="iaEm5VCI1iM" opts={opts} onPlay={playVideoLivingYourLifeAfterTotalLaryngectomy}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Living Your Life After Total Laryngectomy</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="K1B3PslFT1g" opts={opts} onPlay={playVideoPreparingYourself}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Preparing Yourself</span>
                                            </h3>

                                        </div>

                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="8VZutRnnMLk" opts={opts} onPlay={playVideoThePowerOfSupportAndHowFamiliesCanHelp}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">The Power of Support and How Families Can Help</span>
                                            </h3>

                                        </div>

                                    </div>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entLearningGalleryPurpleColor"
                                                  href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default SitemanENTTL6_0;
