import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonGauzeDressingWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Gauze: Show Me How'}, () => {
            console.log('Gauze: Show Me How');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGauzeDressing">Care of Gauze Dressings</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGauzeDressing/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGauzeDressing/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGauzeDressing/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGauzeDressing/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Handwashing</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Washing your hands thoroughly before and after you change a dressing, is one of the <span className="bold">most important steps to prevent infection</span>. Always wash your hands at least 15 seconds before caring for a person’s wound. See our lesson on <a href="safecare_1-3-SubCluster.html" target="_blank">Infection Control</a> for the video on how to wash your hands.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Do Not Place Stress on a Wound</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GD_02.png" className="img-responsive maxWidth300" />
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Any physical stress, especially on a surgical wound, can cause the edges of the skin to separate. This is very important during the first 24 to 72 hours after surgery. Protect a person’s wound by following these tips:.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Have the person place a pillow (or folded towel) over their wound, and hold it firmly with their hand over the wound to splint it when coughing. If the person cannot splint a wound themselves, you can position a pillow or take your hands and place them flat over the wound, and apply light pressure to splint as the person coughs.</li>
                                                    <li>Vomiting places stress on a wound. Have the person take any prescribed anti-emetic medicines.</li>
                                                    <li>Movement places stress on a wound. Have the person move slowly and not try to exercise past the level ordered by their doctor.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Observe the Dressing and Wound</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The person’s doctor will decide whether you should change a dressing or not at home. If you do, observe the wound carefully each time you change a dressing. Follow the directions of the doctor or the home health nurse for how often to change a dressing.</p>
                                                <p className="boldLeadIn">Dressing:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Look for signs of any drainage – you will notice a change in the color of the gauze when drainage is present.</li>
                                                    <li>Notice the size of the area discolored on the gauze, this gives you an idea of the amount of drainage. Also – when you change a gauze dressing, count the number of gauze pads that contain drainage.</li>
                                                    <li>What color is the drainage? Clear, bloody, yellowish or greenish?</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Wound:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Does the wound look different since you last looked at it? Has the size changed or does it look deeper?</li>
                                                    <li>Are the wound edges closed together?</li>
                                                    <li>Are the wound edges reddened or swollen?</li>
                                                    <li>Is there any drainage? If so what color: clear, yellowish, or greenish?</li>
                                                    <li>Does the person tell you there is pain or discomfort when you remove the dressing?</li>
                                                    <li>Are you needing to change the dressing more often than instructed? If so, why?</li>
                                                    <li>If the wound has stitches or staples – are they all in place?</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Write down your observations of the dressing and wound in your care diary. This information will be helpful when the person visits their doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How to Apply and Remove Tape</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If you do not remove tape correctly from a gauze dressing, there is the chance of pulling and placing stress on the wound edges. Tape comes in ½, 1, 2, and 3 inches, and there are several types: adhesive, plastic, paper and non-allergenic. <span className="bold">Know if the person you care for has an allergy to latex.</span> Your doctor or home health nurse can help you choose the right size and type.</p>
                                                <p className="boldLeadIn">To Apply Tape:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Choose a size that secures the dressing over a wound.</li>
                                                    <li>Places edges of tape over several inches of skin on both sides of the dressing.</li>
                                                    <li>Place the tape over the middle of the dressing.</li>
                                                    <li>Press the tape gently to secure it over the dressing, but do not place pressure directly on the wound.</li>
                                                    <li>Never place tape on broken or irritated skin.</li>
                                                    <li>Do not tape all the way around any body part. If applied too tightly, this may cut off circulation to the area.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">To Remove Tape:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>First loosen the tape ends.</li>
                                                    <li>Use one hand to apply light traction to the skin away from the wound.</li>
                                                    <li>Then with the other hand, pick up the loose end and pull it gently toward the dressing.</li>
                                                    <li>If tape covers an area of hair growth, pull the tape in the same direction the hair grows.</li>
                                                    <li>If the person has a lot of hair, trim the hair with scissors. Do not shave the area as this leaves tiny scrapes that can lead to infection.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">A Montgomery Tie:</p>
                                                <p>If the person you care for has fragile skin (common in older adults and persons taking large doses of steroid medicines) use caution in applying tape. A Montgomery Tie is one option to use, avoiding the need to reapply tape each time you change a gauze dressing. The Montgomery Tie is best suited for large wounds requiring several layers of gauze.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Apply the tape edges of the ties along the sides of the wound, leaving space for the gauze.</li>
                                                    <li>Then secure the wound by tying the ribbon ties.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">When to Change a Dressing</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The outer part of a gauze dressing should always be dry. Your doctor or home health nurse will suggest how often to change a dressing.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>A gauze dressing is usually changed routinely (every day) to keep a wound clean and protected.</li>
                                                    <li>Change a gauze dressing if it becomes saturated with drainage.</li>
                                                    <li>Change a gauze dressing if it becomes soiled from bath water, or spills.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Prevent infection by thorough handwashing. When caring for a wound, your biggest risk is spreading germs from the person’s wound to your hands. If you then place your hands on any open area on your skin, such as a cut or scrape, you could get an infection. Wash Your Hands before and after changing a dressing.</li>
                                                    <li>Be sure the person you provide care for is not allergic or sensitive to:
                                                        <ul className="bulletsCustom chevronRightBulletsSafeCare greenBullets">
                                                            <li>antiseptic solution used to clean the wound</li>
                                                            <li>the tape placed over the dressing</li>
                                                            <li>the rubber (latex) gloves</li>
                                                        </ul>
                                                    </li>
                                                    <li>If you or the person you provide care for is allergic to latex, wear latex-free gloves.</li>
                                                    <li>Removal of tape can cause a skin burn in a person with fragile skin. If the person has fragile skin, use paper tape or try a Montgomery tie.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When a person has a wound, of any type, there is a risk for infection, because germs easily enter through a break in the skin.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 video">
                                            <div className="w-100">
                                                <h3 className="blueColor videoTitle w-100 text-center">Video: Dry Gauze Dressings.</h3>
                                                <div className="floatLeft w-100 captionBelow">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="Oc0SHoBvlnI" opts={opts} onPlay={playVideo}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right"></i></a></p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="navTopicButtonPageHref">
                                                    <a id="equipment">EQUIPMENT</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GD_03.jpg" className="img-responsive maxWidth300" />
                                                    <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom greenBullets">
                                                        <li><span className="bold">Option:</span> Clean washcloth and towel</li>
                                                        <li>Cleansing fluid: bottle of sterile normal saline, antiseptic swab, or soap and warm water</li>
                                                        <li>Sterile 4 X 4 gauze pads, ABD gauze pad</li>
                                                        <li><span className="bold">Tape:</span> adhesive, paper or non-allergenic</li>
                                                        <li>Plastic trash bag</li>
                                                        <li><span className="bold">Option:</span> Antibiotic ointment or cream ordered by the doctor</li>
                                                        <li>2 Pairs of clean disposable gloves</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGauzeDressingWhatToDoAndSafety;
