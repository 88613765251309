import React, {useEffect, useState} from 'react';
import styles from './OrbitCluster.module.scss';
import Container from 'react-bootstrap/Container';
import TopNav from "../routing/topNav/index.js";
import Cluster from "../cluster/index.js";
import {orbitClusterTileList} from '../../actions/orbitClusterTileList.js';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {ORBIT_CLUSTER} from "../../actions/types.js";
import {getUser} from "../../helpers/index.js";
import {setOrbitCluster} from "../../actions/orbitCluster.js";
import {DEPARTMENT_ADMIN, ORBIT_ADMIN, ORGANIZATION_ADMIN, SYS_ADMIN} from "../../constants/roles.js";

const OrbitCluster = (props) => {
    const user = JSON.parse(getUser());
    const {errorMessage, tileAttributes, clusterType, useFlexContainer, clusterCssArray} = useSelector(state => state.orbitCluster);
    const [isLoading, setIsLoading] = useState(true);
    const {diagnosticId} = useSelector(state => {
        return state.home
    });
    const dispatch = useDispatch();
    const { id } = useParams();
    let selectedDiagnosisId = [];

    useEffect(async () => {
        const ca = await orbitClusterTileList({id, orbitCluster: true}, ORBIT_CLUSTER, (data) => {
            dispatch(setOrbitCluster(data));
            setIsLoading(false);
        });
        await dispatch(ca);
        //get default orbit for logged in user
    }, []);

    if (user.roles[0] === ORGANIZATION_ADMIN ||
        user.roles[0] === SYS_ADMIN ||
        user.roles[0] === DEPARTMENT_ADMIN ||
        user.roles[0] === ORBIT_ADMIN)
        selectedDiagnosisId = Array.isArray(diagnosticId) && diagnosticId.length > 0 ? diagnosticId : diagnosticId ? [diagnosticId] : user?.dIdList ? user?.dIdList : null;
    else
        selectedDiagnosisId = user?.dIdList;

    return (
        <div className="w-100 authTrue">
            <div className="w-100">
                {!isLoading &&
                    <Container key="clusterContainer">
                        <Cluster
                            clusterType={clusterType}
                            tileAttributes={tileAttributes}
                            useFlexContainer={useFlexContainer}
                            clusterCssArray={clusterCssArray}
                            dIdList={selectedDiagnosisId}
                            key="orbitCluster"
                        />
                    </Container>}
            </div>
        </div>
    )
};

export default OrbitCluster;
