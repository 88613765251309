import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonFootCareFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonFootCare">Dressing A Person</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonFootCare/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonFootCare/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Is it ok to soak a person’s feet in water?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Normally, soaking a person’s feet in water for 10 minutes makes it much easier to give foot care. However, if the person has diabetes mellitus, never soak their feet. Soaking will increase their chance of infection.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What are the risks when giving a person foot care?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Soaking or cleansing the feet in water that is too hot will cause blisters or burns to the feet.</li>
                                            <li>If you file too hard around the corners of a nail, you might injure the skin around the toenail or damage the toe itself, which could lead to infection.</li>
                                            <li>Cutting and injuring the skin from using a clipper is a risk which can also lead to an infection.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who is most at risk for a foot problem?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>People who have diabetes mellitus</li>
                                            <li>Aging persons</li>
                                            <li>People with circulatory problems</li>
                                            <li>People who suffer the loss of feeling, or have numbness or tingling in their feet</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What should I do if the person I care for gets a cut on their foot?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Wash a cut or scrape with soap and water, then cover with a sterile gauze bandage. See our lesson on <a href="/LessonGauzeDressing" target="_blank">Changing a Gauze Dressing</a>.</li>
                                            <li>Treat a skin injury quickly</li>
                                            <li>Check the injury daily and call the person’s doctor if it doesn’t heal</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/American_Diabetes_logo.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American Diabetes Association</h2>
                                                <p>The American Diabetes Association is the nation’s leading nonprofit charity providing diabetes research, information and consumer advocacy. Patient education materials and professional journal articles can be found through the ADA website.</p>
                                                <p className="resourceLink">visit: <a href="http://www.diabetes.org/" target="_blank">www.diabetes.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/APMA Color Logo.JPG" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American Podiatric Medical Association</h2>
                                                <p>The American Podiatric Medical Association is the premier professional organization representing the nation’s Doctors of Podiatric Medicine (podiatrists). The APMA represents a majority of the estimated 15,000 podiatrists in the country. The website provides information on foot health and foot care products.</p>
                                                <p className="resourceLink">visit: <a href="https://www.apma.org/" target="_blank">www.apma.org/</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonFootCareFAQResources;