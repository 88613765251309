import React from "react";

function L28ComponentCode() {

    return (

        <div className="lego l028 backToTop">
            <div className="w-100">
                <p><a className="specialUseBackToTop"
                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                    TO
                    TOP </a></p>
            </div>
        </div>

    )
};


export default L28ComponentCode;