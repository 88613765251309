import {getRequest, postRequest} from "../../src/api/index.js";
import {HOME_ERROR, ORBIT_CLUSTER_ERROR, REGISTER, REGISTER_ERROR} from "../../src/actions/types.js";

export const orgDepList = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await getRequest(`/organization/${data.organizationId}/department/list?showAll=true`, "organization", data, (status) => {
            return ((status >= 200 && status < 300) || (status === 404));
        });

        cb(res.data);
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response && err.response.status !== 404) {
                if (err.response.data && err.response.data.errors[0]) {
                    dispatch({
                        type: HOME_ERROR,
                        payload: err.response.data.errors[0]
                    });
                } else {
                    const error = err.response.data;
                    dispatch({
                        type: HOME_ERROR,
                        payload: err.message
                    });
                }
            } else {
                dispatch({
                    type: HOME_ERROR,
                    payload: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: HOME_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: HOME_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: HOME_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};
