import React from "react";

function L4ComponentCode({title}) {
    title = 'Your Journey';

    return (

        <div className="lego l004">
            <h2 className="subHeadAllCaps">{title}</h2>
        </div>

    )
};


export default L4ComponentCode;