import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin9_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderdDarkBlue">Advice
                                                from Past Patients</h1>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 contentBoxWithTopBorder pritikinBorderBlue pritikinBackgroundLightBlue">
                                        <div className="w-100">
                                            <p>Thousands of past patients and their families have experienced the full
                                                journey to a longer, healthier life through Pritikin cardiac rehab.
                                                These are some of the thoughts they’ve shared with us over the
                                                years.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100 text-center">Keep fighting. You’re
                                                worth the trouble.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;Don’t
                                                beat yourself up.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>It’s not the end of the world if you’re not where you’d like to be. Don’t
                                                think of it as a failure.</p>
                                            <p>You have a new chance to start again today and still gain the benefits
                                                from a healthier lifestyle. I’m really preaching to myself here. I tend
                                                to get really down on myself when I eat junk food or don’t exercise
                                                enough. It’s natural for us to feel bad, but you can’t let it trap you
                                                in a downward spiral. You’re not a bad person; maybe you just need some
                                                help to get on track again. It’s ok.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;Admit
                                                that you have weaknesses.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Then, face it head-on. Maybe there are foods that you’re really tempted
                                                by, or maybe you struggle with exercising regularly. Acknowledging the
                                                areas that you would like to improve is the first step towards finding
                                                new ways to deal with those issues. Every day is a new chance to improve
                                                your health. Maybe I wasn’t great over the weekend because my family had
                                                a barbecue. While it’s not great to indulge, it’s far worse to continue
                                                indulging.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;Remember
                                                your goals.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>I love to play golf, and my goal is to be able to walk 18 holes while
                                                carrying my bag. I’m not there yet, but I’m closer than I was. Walking
                                                18 holes means that I can play some great courses that don’t allow
                                                carts. I want to be able to do that, so I’m really inspired to get
                                                stronger. It’s hard to stay motivated to make these lifestyle changes
                                                without having a clear goal in mind. I know of a patient whose goal is
                                                to be able to play with his grandkids without being tired. Whatever
                                                motivates you, it’s important to remind yourself WHY you’re changing the
                                                way you live.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;Adopt
                                                strategies to stay on track.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Having someone else to encourage you as you work towards making new
                                                habits can make all the difference. My wife and I are both making these
                                                healthy changes and are each other’s accountability partners. She
                                                encourages me to take a walk in the evening or makes sure that I have a
                                                big salad with dinner. We keep each other motivated.</p>
                                            <p><span className="bold">“Don’t Break the Chain”</span> is another strategy
                                                that can help you make lasting positive changes in your life: you commit
                                                to completing a daily goal, and you add a checkmark to your calendar
                                                when you complete the goal each day. You start to build a chain of
                                                checkmarks and thereby build a long-term habit. Building a new habit is
                                                hard and feels unrewarding but, with this method, the reward is making
                                                that check mark to keep the chain (and new habit) going. Accountability
                                                partners, giving yourself small goals every day, and finding healthy
                                                rewards for motivation are all ways to keep on track.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;Even
                                                small changes are good for you.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>It’s easy to think that you have to be perfect from day one, but that’s
                                                so far from the truth. My mom always said that everyone fails but the
                                                key is to keep trying. So, I do my best. I may fail sometimes but I try
                                                not to focus on that. I have to keep moving forward, a little bit better
                                                today than I was yesterday.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;You
                                                are not alone.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>I know that it can feel like everyone else in the world gets to eat pizza
                                                and you don’t. I know that sometimes it feels like you are fighting a
                                                constant battle and it’s just too much. But there are people all around
                                                who are rooting for you to succeed, and they want to see you happy and
                                                healthy, living a great life. Maybe it’s people who did cardiac rehab
                                                with you, your cardiac rehab staff “family,” or other people in your
                                                life that are rooting for you. Remember that we’re all in this
                                                together.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100"><i
                                                className="fa fa-check-circle pritikinColorMediumBlue"></i>&nbsp;&nbsp;Your
                                                life is different now, but it’s still a great life.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>I can’t work 12 hours a day and then eat at McDonald’s for dinner. I
                                                don’t eat steak nearly as much as I would like. I don’t really like
                                                exercising some days. But instead of complaining because of what I’ve
                                                lost, I’ve chosen to focus on making the best of the life that I have.
                                                Knowing that I’m giving myself the best shot at living a long and
                                                healthy life is wonderful.</p>
                                            <p>I’m not perfect. Not by a long shot. But I really want to be healthy and
                                                to be able to make memories with the people that I love. The only way I
                                                can do that is if I keep at it every day and try to be a little better
                                                with my food choices and putting my health first. You need to do the
                                                same.</p>
                                            <p>Maybe you’ve had a cardiac event, but this is your chance to make changes
                                                and get healthy.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin9_0;