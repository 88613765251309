import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGlucoseFAQResources = () => {

    return (

        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGlucose">Measuring Blood
                                                            Glucose</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGlucose/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGlucose/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What do I do if the skin prick
                                                    continues to bleed?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_FAQ.png"
                                                     className="img-responsive maxWidth300 floatNone"/>
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>This is not uncommon, especially if the person you care for takes any
                                                    type of blood thinner (anticoagulant). Simply take a cotton ball and
                                                    lightly hold the area for about 5 minutes. Bleeding should stop.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What do I do if the person’s blood
                                                    glucose reading is higher or lower than normal?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>This will depend on the health of the person. If he or she simply
                                                    needs you to check their blood glucose, they will usually know
                                                    whether they need to adjust their medicine, diet or exercise. If the
                                                    reading is high and the person is not able to care for herself or
                                                    himself, call their doctor or home health nurse. If the blood
                                                    glucose is low, see the “<a
                                                        href="safecare_2-5-3-What_You_Need_to_Know_page.html">Need to
                                                        Know</a>” section. After treatment call the home health nurse or
                                                    doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What can I do to be sure I measure
                                                    the blood sugar correctly?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>There are several things that you can do.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Make sure that the antiseptic has dried on the skin before you
                                                        puncture the skin. Alcohol can cause incorrect results.
                                                    </li>
                                                    <li>Always have a good “hanging” drop of blood to apply to the test
                                                        strip.
                                                    </li>
                                                    <li>Make sure that the test strip is fully inserted into the
                                                        meter.
                                                    </li>
                                                    <li>Never allow your finger or any other surface to touch the
                                                        testing pad on the testing strip.
                                                    </li>
                                                    <li>Wash your hands throughly in warm water before checking the
                                                        blood glucose.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Is there one meter better than
                                                    another?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>All current meters are accurate if operated correctly. Most important
                                                    is to know if the person’s insurance has a preferred meter.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What do I do if the meter displays
                                                    a message that I am unfamiliar with?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>This is likely an error code that indicates that there was a problem
                                                    with the blood sample. Refer to the owner’s manual or call the 800
                                                    number for the meter company for assistance.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/American_Diabetes_logo.png"/>
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American
                                                            Diabetes Association</h2>
                                                        <p>The association provides objective and credible information
                                                            about current therapies in diabetes and recommendations for
                                                            a normal lifestyle.</p>
                                                        <p className="resourceLink">visit: <a
                                                            href="http://www.diabetes.org"
                                                            target="_blank">www.diabetes.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png"/>
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Ohio State
                                                            University Medical Center</h2>
                                                        <p>The Center has a website for Patient Education resources.
                                                            This site offers tips on selecting a meter and how to use a
                                                            meter.</p>
                                                        <p className="resourceLink">visit: <a
                                                            href="http://medicalcenter.osu.edu/patient_education"
                                                            target="_blank">http://medicalcenter.osu.edu/patient_education</a>
                                                        </p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png"/>
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Local Home
                                                            Medical Equipment Companies</h2>
                                                        <p>Your local home medical equipment company will have people
                                                            who can explain the various types of meters and lancets that
                                                            are available.</p>
                                                        <p className="resourceLink">visit: <a
                                                            href="http://medicalcenter.osu.edu/patient_education"
                                                            target="_blank">http://medicalcenter.osu.edu/patient_education</a>
                                                        </p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png"/>
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Meter
                                                            Companies</h2>
                                                        <p>All meter companies provide a “1-800” Consumer Help number
                                                            printed on the back of the meter and or the user guide.</p>
                                                        <p className="resourceLink">visit: <a
                                                            href="http://medicalcenter.osu.edu/patient_education"
                                                            target="_blank">http://medicalcenter.osu.edu/patient_education</a>
                                                        </p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGlucoseFAQResources;
