import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_6_1_long_covid = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin4">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/90f39272d576e590cf2f6c8d6e445e5c.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#75B8E4",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Long COVID-19 Program</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin3">
                    <p>
                      The WashU Living Well Center has developed a short-term
                      supportive program to provide lifestyle medicine
                      interventions for patients who have been diagnosed with
                      Long COVID by Washington University’s{" "}
                      <a
                        className="blueLinkfont22"
                        href="https://physicians.wustl.edu/specialties/infectious-diseases/post-covid-19-clinic/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Post COVID-19 Clinic
                      </a>
                      .
                    </p>
                    <p>
                      Washington University’s Post COVID-19 Clinic supports and
                      treats patients who have continued symptoms or
                      complications after COVID-19 illness, also called Long
                      COVID or post-acute sequelae of COVID (PASC). This clinic
                      is located within Washington University’s Infectious
                      Disease Clinic.{" "}
                    </p>
                    <p>
                      Long-term symptoms of Long COVID issues can include
                      fatigue, "brain fog" or changes in thinking or
                      concentration, shortness of breath or breathlessness,
                      worsening of symptoms with increased activity, changes in
                      sleep, changes in mood, and many others.
                    </p>
                    <p>
                      There can also be lingering issues that affect the
                      musculoskeletal system (the bones, joints and muscles of
                      our body). To treat Long COVID, the Living Well Center
                      provides opportunities for patients from the WashU Post
                      COVID-19 Clinic to get lifestyle medicine support for
                      their Long COVID symptoms.{" "}
                    </p>
                    <p className="legoMargin3">
                      We’re also a proud partner of the{" "}
                      <a
                        className="blueLinkfont22"
                        href="https://stlouisihn.org/long-covid-care/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        St. Louis Long COVID Initiative
                      </a>
                       . This Initiative is a newly developed, grant-funded
                      program that provides Long COVID patients in St. Louis and
                      rural Missouri and Illinois with resources, support and
                      improved access to care.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}
                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">PROGRAM DETAILS</h2>
                  </div>
                  {/* End L4 Subhead Upper */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin3">
                    <p>
                      The WashU Living Well Center identifies and addresses
                      lifestyle strategies with a goal of managing and improving
                      symptoms of Long COVID.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin2">
                    <p>
                      <span className="boldLeadInNewUI">
                        The Living Well Center uses lifestyle changes to help
                        patients address:
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L14 Paragraph */}
                  {/* L53 Bullet List */}
                  <div className="lego l053 legoMargin5">
                    <ul className="w-100">
                      <li className="w-100">Fatigue</li>
                      <li className="w-100">Brain fog</li>
                      <li className="w-100">
                        Stress and mood disorders such as anxiety and depression
                      </li>
                      <li className="w-100">Sleep problems</li>
                      <li className="w-100">Pain and deconditioning</li>
                      <li className="w-100">
                        Co-existing chronic medical conditions (such as high
                        blood pressure or diabetes)
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin2">
                    <p>
                      <span className="paragraph22px">
                        Care plans and treatment may include:
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L14 Paragraph */}
                  {/* L53 Bullet List */}
                  <div className="lego l053 legoMargin3">
                    <ul className="w-100">
                      <li className="w-100 legoMargin1">
                        <a
                          className="blueLinkfont22"
                          href="https://www.ortho.wustl.edu/content/Patient-Care/8431/Services/Living-Well-Center/Shared-Medical-Appointments.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Shared medical appointments
                        </a>
                      </li>
                      <li className="w-100 legoMargin1">
                        <a
                          className="blueLinkfont22"
                          href="https://www.ortho.wustl.edu/content/Patient-Care/8358/Services/Living-Well-Center/Nutritional-Counseling.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Nutritional counseling
                        </a>
                      </li>
                      <li className="w-100 legoMargin1">
                        <a
                          className="blueLinkfont22"
                          href="https://www.ortho.wustl.edu/content/Patient-Care/7140/Services/Living-Well-Center.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Behavioral health counseling{" "}
                        </a>
                      </li>
                      <li className="w-100 legoMargin1">
                        Sleep hygiene counseling
                      </li>
                      <li className="w-100 legoMargin1">Physical therapy</li>
                      <li className="w-100 legoMargin1">
                        Occupational therapy
                      </li>
                      <li className="w-100 legoMargin1">Speech therapy</li>
                      <li className="w-100 legoMargin1">
                        Musculoskeletal care
                      </li>
                      <li className="w-100 legoMargin1">
                        Pacing and energy management
                      </li>

                      <li className="w-100 legoMargin1">
                        <a
                          className="blueLinkfont22"
                          href="https://www.ortho.wustl.edu/content/Patient-Care/6940/Services/Living-Well-Center/Smoking-Cessation.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          Smoking cessation
                        </a>
                      </li>
                      <li className="w-100 legoMargin1">
                        For select painful musculoskeletal conditions, treatment
                        may also include:
                        <br />
                        - Medical massage*
                        <br />-{" "}
                        <a
                          className="blueLinkfont22"
                          href="https://www.ortho.wustl.edu/content/Patient-Care/8326/Services/Living-Well-Center/Acupuncture.aspx"
                          rel="noreferrer noopener"
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          Acupuncture*
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin5">
                    <p>
                      <span className="paragraph18">
                        *These services are not typically covered by insurance.
                        Because coverage varies, please check with
                        your insurance carrier to verify coverage.
                      </span>{" "}
                    </p>
                  </div>
                  {/* End L14 Paragraph */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin5">
                    <p>
                      <span className="boldLeadInNewUI">
                        Long COVID Shared Medical Appointments 
                      </span>{" "}
                    </p>
                    <p>
                      Shared medical appointments (SMAs) are medical
                      office visits led by a medical provider and conducted in a
                      group setting. These visits function to manage symptoms
                      or conditions among multiple people with similar goals.
                      The Long COVID shared medical appointments are group
                      visits with other people who have Long COVID.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin5">
                    <p>
                      <span className="boldLeadInNewUI">
                        Upcoming SMA Session:
                        <br />
                        Physical and Cognitive Therapies for Long COVID Shared
                        Medical Appointments 
                      </span>{" "}
                    </p>
                    <p>
                      This session is designed to address physical activity,
                      self-care and communication challenges faced by patients
                      with Long COVID. Each session will involve a physical,
                      occupational and/or speech therapist to help patients
                      effectively manage their symptoms.
                    </p>
                    <p>
                      This session consists of six weekly one-hour classes that
                      can be attended virtually or in-person. It is covered by
                      Missouri and Illinois Medicaid.{" "}
                      <a
                        className="blueLinkfont22"
                        href="https://www.ortho.wustl.edu/Calendar.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        View our events calendar for session dates
                      </a>
                        or call <span className="phoneLink">314-514-3565 </span>
                      for more information.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph2 legoMargin5">
                    <p>
                      <span className="boldLeadInNewUI">
                        How do I sign up? 
                      </span>
                    </p>
                    <p>
                      A referral is required from the WU CARE clinic or from a
                      primary care provider with a diagnosis of Long COVID.{" "}Call  {" "}
                      <span className="phoneLink">314-514-3565 </span>
                      for more information.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_6_1_long_covid;
