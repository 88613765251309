import React from "react";

function L12BComponentCode() {
  return (
    <div className="lego l012B p-0">
      <div className="contentLeftSide">
        {/* L11 Image */}
        <div
          className="lego l011 legoMargin3"
          style={{ justifyContent: "flex-end" }}
        >
          <img
            className="imageW100 noBorderRadius maxWidth300"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/9162560e08f725f61b013117564ddfe3.jpeg"
          />
        </div>
        {/* End L11 Image */}
      </div>
      <div className="contentRightSide">
        <div className="legoMargin5">
          <p>
            “At the{" "}
            <span className="boldText">
              Washington University Living Well Center
            </span>{" "}
            we're changing the way healthcare is delivered. With a
            multidisciplinary team of experts, we will provide you with
            comprehensive care and treat the root cause of your problem — not
            just the symptoms — with the principles of lifestyle medicine. 
          </p>
          <p>
            We offer you your ideal range of coordinated services to treat the
            whole you, including nutritional counseling, physical therapy,
            behavioral health counseling, and more. 
          </p>
          <p>
            And, we will make sure those services are available in ways that can
            fit your health and wellness needs, your lifestyle, your schedule
            and, in cases where insurance may not cover all services, your
            budget.”
            <br />
          </p>
          <p>
            <span className="boldSignature"> Dr. Devyani Hunt</span>
            <br />
            <span className="boldSignatureTitle">
              {" "}
              Medical Director, Living Well Center
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default L12BComponentCode;
