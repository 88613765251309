import React from "react";

function L14ComponentCode() {

    return (

        <div className="lego l00paragraph">
            <p>You can take steps right now to guide you to be your healthiest for
                your
                new start. Your success begins with creating a true understanding of
                your path through this journey – the nature of your procedure, how
                to
                help ensure the best outcomes, and what your recovery will entail.
                Everyone involved – you, your family, your care team and other
                resources
                available to you – can all come together to help you through this
                journey.
            </p>
        </div>

    )
};


export default L14ComponentCode;