import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll9_4 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Directions and Maps</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJC_signage.jpg" className="img-responsive" />
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">GETTING TO BARNES-JEWISH HOSPITAL</h2>
                                            <p><em>1 Barnes Jewish Hospital Plaza St. Louis, MO 63110</em></p>
                                            <p>Barnes-Jewish Hospital is conveniently located in St. Louis, Missouri, one block north of the I-64/US40 and Kingshighway Boulevard intersection, across from Forest Park in the neighborhood known as the Central West End.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-PARK_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 typeCol noPaddingLeft">
                                                <p className="boldLeadIn">Barnes-Jewish Hospital Parking Map
                                                </p>
                                                <p className="mobileMarginBottom30"><em><a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap_Park-20.pdf" download target="_blank">click to view/download</a></em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/*<div className="col-12 imgColLeftContentColRight">*/}
                                    {/*    <div className="w-100 vertMiddle">*/}
                                    {/*        <div className="col-2 col-3 imgCol noPaddingLeft">*/}
                                    {/*            <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-South_062014.png" alt="" />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-10 col-9 typeCol noPaddingLeft">*/}
                                    {/*            <p className="boldLeadIn"><a href="pdf/BJH_CampusMap-South_062014.pdf" target="_blank">Hospital South</a>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="mobileMarginBottom30"><em>click to view/download</em>*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="col-12 imgColLeftContentColRight">*/}
                                    {/*    <div className="w-100 vertMiddle">*/}
                                    {/*        <div className="col-2 col-3 imgCol noPaddingLeft">*/}
                                    {/*            <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Barnes_Jewish_South_Lobby_Map.png" alt="" />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-10 col-9 typeCol noPaddingLeft">*/}
                                    {/*            <p className="boldLeadIn"><a href="Barnes_Jewish_South_Lobby_Map.pdf" target="_blank">Day of Surgery Location Map</a>*/}
                                    {/*            </p>*/}
                                    {/*            <p className="mobileMarginBottom30"><em>click to view/download</em>*/}
                                    {/*            </p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-North_3rdLevelCam_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn">Barnes-Jewish Hospital Center for Advanced Medicine and Center for Outpatient Health, Floor 3
                                                </p>
                                                <p className="mobileMarginBottom30"><em><a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-North3_2017.pdf" download target="_blank">click to view/download</a></em>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap-LINK_062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn">Map of Campus Link
                                                </p>
                                                <p className="mobileMarginBottom30"><em><a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap_Link-20.pdf" download target="_blank">click to view/download</a></em>
                                                </p>
                                                <p className="mobileMarginBottom30">The “Link” is a convenient way to navigate through the Campus’s many buildings. It stretches from South Campus parking garage up to the Forest Park Laclede parking garage.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 vertMiddle">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap_Extended062014.png" alt="" />
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <p className="boldLeadIn">The Medical Center Campus
                                                </p>
                                                <p className="mobileMarginBottom30"><em><a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_CampusMap_Extended_2017.pdf" download target="_blank">click to view/download</a></em>
                                                </p>
                                                {/*<p className="mobileMarginBottom30">The “Link” is a convenient way to navigate through the Campus’s many buildings. It stretches from South Campus parking garage up to the Forest Park Laclede parking garage.*/}
                                                {/*</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"  href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll9_4;
