import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonGettingReadyWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonGettingReady">Getting Ready For Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell the Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How Can You Help?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When helping a person prepare for surgery, there are many things you will observe. Keep a list or diary of your observations so that you can share what you k</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Report if the person has followed any medicine restrictions or if the person has taken any medicines that morning.</li>
                                            <li>Report how long it has been since the person has had any liquids or solid food to eat.</li>
                                            <li>If the person was required to take medicines to clean the bowel, report the results.</li>
                                            <li>If the person was required to have an enema at home, report the results.</li>
                                            <li>Take a complete list of the person’s medicines with you when you go to the hospital or surgery center.</li>
                                            <li>Report if the person has experienced nausea, vomiting, or change in pain prior to coming for surgery.</li>
                                            <li>Report if the person has developed any cold or breathing problems or fever.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonGettingReadyWhatToTellYourDoctor;