import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import Accordion from "react-bootstrap/Accordion";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTAll4_1_4 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideoCaringForSkinGraftSites = async (e) => {
        const pal = await videoAccessLog({videoName: 'Wound Care: Caring for Skin Graft Sites'}, () => {
            console.log('Wound Care: Caring for Skin Graft Sites');
        });
        await dispatch(pal);
    }

    const playVideoWoundPacking = async (e) => {
        const pal = await videoAccessLog({videoName: 'Wound Care: Wound Packing'}, () => {
            console.log('Wound Care: Wound Packing');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Wound
                                                Care</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You will be caring for multiple wounds in different locations on the body
                                                after surgery. The instructions for caring for the various wound areas
                                                are different. Please make sure you understand and follow the
                                                instructions for each wound area.</p>
                                            <p>Below are general instructions for your wound care after surgery. If your
                                                doctor gives you different instructions, follow the specific
                                                instructions you are given.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="typesFeedingTubes"
                                                className="subheadUpperAndLowerColorBarIcon">GENERAL DEFINITIONS</h3>
                                        </div>
                                    </div>

                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong>Wound:</strong> Refers to any area where
                                                                    surgery has been performed.</p>
                                                                <p><strong>Incision:</strong> A surgical cut made in the
                                                                    skin during surgery and then closed with sutures or
                                                                    staples. Incisions can be on the head, neck, arms,
                                                                    legs, back, chest or in your mouth and throat
                                                                    depending on your specific surgery.</p>
                                                                <p><strong>Sutures/Staples:</strong> Sutures are like
                                                                    stitches that are used to close incisions. They can
                                                                    be absorbable (dissolve and fall out on their own)
                                                                    or non-absorbable (will not dissolve and need to be
                                                                    removed by your surgeon about 1 to 2 weeks after
                                                                    surgery). Staples are metal clips used to close
                                                                    incisions. Staples are also removed by your surgeon
                                                                    1 to 2 weeks after surgery.</p>
                                                                <p>Some incisions may be covered in surgical glue (e.g.
                                                                    Dermabond). These incisions have been closed with
                                                                    absorbable sutures underneath the glue, which will
                                                                    dissolve with time (more info below).</p>
                                                                <p><strong>Skin Graft:</strong> A thin outer layer of
                                                                    skin that is taken from one part of the body
                                                                    (usually from the thigh) and placed on another part
                                                                    of the body to cover a wound.</p>
                                                                <p><strong>Free Flap:</strong> Tissue that is
                                                                    transplanted from one part of the body to another
                                                                    part in order to reconstruct an area in the head and
                                                                    neck. Free flap tissue can contain one or more of
                                                                    the following: skin, muscle, bone. Blood vessels
                                                                    (artery and vein) that supply the free flap tissue
                                                                    are connected to blood vessels in the neck to allow
                                                                    the free flap to survive in its new location,
                                                                    similar to a transplant.</p>
                                                                <p><strong>Donor Site:</strong> The location where a
                                                                    skin graft or free flap is removed.</p>
                                                                <p><strong>Recipient Site:</strong> The location where a
                                                                    skin graft or free flap is placed for
                                                                    reconstruction.</p>
                                                            </div>
                                                        </div>
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 id="typesFeedingTubes"
                                                className="subheadUpperAndLowerColorBarIcon">GENERAL CARE</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="w-100 text-center">Applies to all wounds at all times.</p>
                                        </div>
                                    </div>

                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li><strong>Keeping the wound(s) clean</strong> is
                                                                        important for optimal healing.
                                                                    </li>
                                                                    <li><strong>Avoid smoking.</strong> Smoking limits
                                                                        blood flow to the graft and edges of the healing
                                                                        skin.
                                                                    </li>
                                                                    <li><strong>Avoid drinking alcohol.</strong> Alcohol
                                                                        increases your risk of bleeding and bruising.
                                                                    </li>
                                                                    <li><strong>Always thoroughly wash your
                                                                        hands</strong> before removing and replacing
                                                                        your dressing.
                                                                    </li>
                                                                    <li>Alcohol pads can be used to clean scissors and
                                                                        to degrease your skin if medical tape does not
                                                                        stick well to your skin. <strong>DO NOT USE ALCOHOL PADS TO CLEAN YOUR SURGERY SITE BECAUSE IT IS TOO DRYING AND IRRITATING TO YOUR SURGERY SITE.</strong></li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div
                                                                className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                                                <h3 className="subheadUpperAndLowerColorBarIcon backgroundAccentYellow"><i className="fa fa-exclamation"></i>&nbsp;Call us right away if you notice any of the following with your incision:</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Increased redness, swelling, or warmth in the
                                                                        skin around the wound
                                                                    </li>
                                                                    <li>Bad smell or drainage coming from the wound</li>
                                                                    <li>Fever above 100.4&deg;F (38&deg;C) or chills</li>
                                                                    <li>Increasing pain</li>
                                                                    <li>Bleeding</li>
                                                                    <li>Your incision has opened up and come apart</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">INCISION CARE</h3>
                                        </div>
                                    </div>

                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        {/*<div className="col-12 video">*/}
                                                        {/*    <div className="w-100">*/}
                                                        {/*        <div className="embedContainer originalPadding">*/}
                                                        {/*            <YouTube videoId="HVxdryxalMg" opts={opts}/>*/}
                                                        {/*        </div>*/}
                                                        {/*        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Caring for Skin Graft Sites</span></h3>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        <div className="col-12 floatLeft ClearLeft">
                                                            <div className="w-100">
                                                                <p className="w-100">An incision is a surgical cut made in the skin during surgery and then closed with sutures or staples. Incisions can be on the head, neck, arms, legs, back, chest or mouth depending on your specific surgery.</p>
                                                                <p className="w-100">Sutures are like stitches that are used to close incisions. They can be absorbable (dissolve and fall out on their own) or non-absorbable (will not dissolve and need to be removed by your surgeon about 1 to 2 weeks after surgery).</p>
                                                                <p className="w-100">Staples are metal clips used to close incisions. Staples are removed by your surgeon about 1 to 2 weeks after surgery. Sutures in the mouth are absorbable and will dissolve and fall out on their own with time.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <h2 className="subheadAllCaps w-100 text-center colorCareOrbitBlue">CARING FOR YOUR SKIN INCISIONS:</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 w-100 subheadAndBullets floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom w-100 floatLeft clearLeft">
                                                                    <li className="floatLeft clearLeft w-100"><strong>Keeping your skin incision clean: incisions on the skin will often develop crusting</strong>. It is important to remove crusting. Clean the skin incisions by gently applying half-strength hydrogen peroxide (hydrogen peroxide mixed half-and-half with distilled water) to the area with a cotton tipped applicator (Q-Tip&reg;) twice daily. You should be able to see individual stitches or staples.
                                                                        <ul className="bulletsCustom chevronRight colorAccentBlueGreenBullets floatLeft clearLeft">
                                                                            <li className="w-100"><strong>If staples were used on your scalp</strong>. Clean scalp incision with dilute hydrogen peroxide as discussed above. Once cleared by your surgeon, you may cleanse with a gentle shampoo and pat dry. <strong>Be careful not to shower or wash your hair with hot water and do not use the “HOT” or “HIGH” setting on a hairdryer as both of these can cause the staples to get hot and cause burns to the scalp.</strong></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li className="floatLeft clearLeft w-100"><strong>Showering: your surgeon will tell you when it is safe to shower.</strong> When allowed to shower, you can let water gently run over the suture line, but do not scrub the sutures or incisions. It is fine for soap to wash over the incisions, but it must be rinsed off. You can use a gentle baby shampoo around incisions. Do not soak your incision in a bath.
                                                                    </li>
                                                                    <li className="floatLeft clearLeft w-100"><strong>Applying ointment to the incision:</strong> Apply topical double or triple antibiotic ointment (Polysporin&reg;, Neosporin&reg;, etc.) to the skin incisions twice daily for the first two days. Then stop the antibiotic ointment and start applying Vaseline&reg; or Aquaphor&reg; to the incision twice daily until your post-operative appointment (many patients will develop a rash if antibiotic ointment is used longer than two days). Applying ointment (antibiotic or Vaseline&reg;) keeps the wound moist, facilitates healing, and prevents infection.
                                                                    </li>
                                                                    <li className="floatLeft clearLeft w-100"><strong>Keep the wound covered when outside</strong> or when in a place where the wound might get dirty. It is otherwise fine to leave the wound open to the air as long as ointment is used to keep it covered.
                                                                    </li>
                                                                    <li className="floatLeft clearLeft w-100"><strong>Do not expose the wound to water from a pool, lake, or ocean</strong> until the wound has completely healed. This can cause infection. Ask your surgeon when you can get into a pool, lake, or ocean.</li>
                                                                    <li className="floatLeft clearLeft w-100"><strong>Take extra care to avoid sun exposure until the wounds are healed</strong> and then protect the incision sites with sunscreen (SPF 50 or greater). Sun exposure slows healing and causes discoloration of the scar that may never disappear.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                                    <h2 className="subheadUpperAndLower mb-0">If your skin incision was sealed with Dermabond&reg; or other liquid skin adhesive:</h2>
                                                                </div>
                                                                <div className="withBorder entBlueBorderColor">
                                                                    <div className="col-12">
                                                                        <div className="w-100">
                                                                            <p>In some cases, incisions may be sealed with a liquid skin adhesive instead of sutures. If you have a wound sealed with liquid skin adhesive, after 24 hours you can allow water to wash gently over the incision line.</p>
                                                                            <p>Do not scrub the incision. It is fine for soap to wash over the incisions, but it must be rinsed off. It is fine to keep the wound open. No other wound care is needed for these incisions. In 7 to 10 days the skin adhesive will begin to peel up around the edges. You can gently remove the adhesive if it peels after 7 days.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <h2 className="subheadAllCaps w-100 text-center colorCareOrbitBlue">CARING FOR YOUR ORAL (MOUTH) INCISIONS:</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>If you have had surgery in your mouth, your surgeon will have used absorbable sutures to close the incisions. You do not need to clean or take care of oral incisions in the same way you take care of skin incisions. The saliva in the mouth is able to keep the incisions from crusting.</p>
                                                                <p>You can swab your mouth with a special antibacterial mouthwash called chlorhexidine (Peridex) three times a day to keep your mouth clean.</p>
                                                                <p>If you are eating by mouth, you can use the chlorhexidine mouthwash after each meal.</p>
                                                                <p>Sometimes oral incisions can open and come apart. If you see this has happened, contact your surgeon.</p>
                                                            </div>
                                                        </div>
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">SKIN GRAFT DONOR SITE CARE</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="w-100 text-center">Where the skin graft was taken from</p>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>

                                                        <div className="col-12 floatLeft ClearLeft">
                                                            <div className="w-100">
                                                                <p className="w-100">A skin graft is a procedure where skin is taken from one part of the body and used to reconstruct an area on another part of your body.</p>
                                                                <p className="w-100">A <strong>split</strong> thickness skin graft is when the outer layer of the skin is shaved off, leaving the deeper layer of skin in place. The outer layer of skin is then placed on another part of the body. This leaves a large superficial wound similar to a bad scrape or road rash where the skin was taken from.</p>
                                                                <p className="w-100">This area will heal over time, but it can be painful and requires special care during the healing process. Split thickness skin grafts are usually taken from the thigh skin.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <h2 className="subheadAllCaps w-100">Caring for Your Skin Graft Donor Site:</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 w-100 subheadAndBullets floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom w-100 floatLeft clearLeft">
                                                                    <li className="floatLeft clearLeft w-100">You will usually leave the hospital with a yellow dressing (Xeroform) over the skin graft donor site (where the skin was taken from, this is usually from the thigh)</li>
                                                                    <li className="floatLeft clearLeft w-100">Do not get the yellow dressing wet, but allow it to dry out. You do not need to place another dressing over the yellow dressing, but keep it open and dry. The yellow dressing will protect the wound and make it less painful.</li>
                                                                    <li className="floatLeft clearLeft w-100">Once the dressing is dry, you can wear your clothes over the top of it.</li>
                                                                    <li className="floatLeft clearLeft w-100">As your skin heals under the yellow dressing, the dressing will start to peel up around the edges. As it does this, you can trim the free edges off with scissors.</li>
                                                                    <li className="floatLeft clearLeft w-100">The dressing should completely come off about 2 weeks after surgery.</li>
                                                                    <li className="floatLeft clearLeft w-100">If you notice foul smelling drainage, bleeding, or increasing redness or pain around the wound, notify your surgeon.</li>
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">SKIN GRAFT RECIPIENT SITE CARE</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="w-100 text-center">Where the skin graft was placed for reconstruction</p>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>

                                                        <div className="col-12 floatLeft ClearLeft">
                                                            <div className="w-100">
                                                                <p className="w-100">A skin graft is a procedure where skin is taken from another part of the body and used to reconstruct an area on another part of your body. The skin graft may be placed on your head and neck to reconstruct where a tumor was removed, or it may be placed on your wrist or lower leg to cover where a free flap was taken.</p>
                                                                <p className="w-100"><strong>Bolsters:</strong> Bolsters are a type of dressing that hold the skin graft down to allow it to heal to the underlying wound. This can be a dressing that is affixed with sutures or stables, or it may be a “wound vac” that uses suction to hold the skin graft down.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 coloredContentBox">
                                                            <div className="w-100">
                                                                <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder">
                                                                    <div className="col-12 video">
                                                                        <div className="w-100">
                                                                            <div className="embedContainer originalPadding">
                                                                                <YouTube videoId="HVxdryxalMg" opts={opts} onPlay={playVideoCaringForSkinGraftSites}/>
                                                                            </div>
                                                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Caring for Skin Graft Sites</span></h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="w-100">
                                                                            <p><strong className="colorCareOrbitBlue">1.</strong> Remove the old dressing.</p>
                                                                            <p><strong className="colorCareOrbitBlue">2.</strong> Gently clean <strong>around</strong> the skin graft using wound cleanser or normal saline. <strong>DO NOT RUB THE SKIN GRAFT DIRECTLY OR IT MAY RUB OFF.</strong></p>
                                                                            <p>If the surgical site has <strong>old ointment or scabbing that needs to be removed from the graft, use the wound cleanser</strong> that was sent home with you from the hospital. If you were <strong>not sent home with wound cleanser</strong>, you can use saline wound spray or bottles of normal saline that can be purchased at local pharmacies.</p>
                                                                            <p><strong>If you are using wound cleanser</strong>, spray the wound cleanser onto a 4x4 piece of gauze until it is saturated but not dripping (the gauze can be purchased from most pharmacies like Walgreens, Walmart or CVS). Lightly dab or pat the surgical site. Then pat dry with new gauze, making sure the surgical area is no longer wet.</p>
                                                                            <p><strong>If you are using normal saline</strong> (found at most pharmacies or online at sites like amazon.com), pour the saline over the gauze and wring out excess saline so the gauze is wet but not dripping. Gently pat the surgical site with the saline soaked gauze and pat dry with new gauze, making sure the surgical site is no longer wet.</p>
                                                                            <p><strong className="colorCareOrbitBlue">3.</strong> Cut the Xeroform to fit the size of the skin graft. Use clean scissors to cut dressing materials. You can clean scissors with alcohol wipes.</p>
                                                                            <p><strong className="colorCareOrbitBlue">4.</strong> Apply a generous amount of Vaseline&reg; to the Xeroform that has been cut or folded along the edges to size. Spread the Vaseline out smoothly onto the Xeroform using a gloved fingertip. Do not rub Vaseline directly on the skin graft, as this may cause the skin graft to rub off the skin graft.</p>
                                                                            <p><strong className="colorCareOrbitBlue">5.</strong> Place the Xeroform dressing onto the skin graft with the Vaseline side down. Cover with a non-stick pad such as Telfa&trade;. This can be cut to be slightly larger than the skin graft. The dressing can be held in place with medical tape, mesh netting or any other creative method you discover.</p>
                                                                            <p><strong>The goals for your Skin Graft Recipient Site are to keep the wound moist and protect it from trauma.</strong></p>
                                                                            <p><strong>IMPORTANT—</strong></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 subheadAndBullets">
                                                                        <div className="w-100">
                                                                            <ul className="bulletsCustom">
                                                                                <li>Always check the wound for signs of infection, like redness, swelling, drainage, or a bad smell.</li>
                                                                                <li>Change the dressing as instructed by your healthcare provider.</li>
                                                                                <li>If the wound is dry or the dressing sticks to the skin graft when you change the dressing, you may need to apply more ointment and/or change the dressing twice a day.</li>
                                                                                <li>As the wound heals, use smaller pieces of the Xeroform to cover the remaining wound.</li>
                                                                                <li>Use clean scissors that you have wiped off with an alcohol swab for cutting the dressing material.</li>
                                                                                <li>Most dressing supplies can be purchased online (e.g. Amazon)</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                                    <h2 className="subheadUpperAndLower mb-0">Wet to dry dressing changes:</h2>
                                                                </div>
                                                                <div className="withBorder entBlueBorderColor">
                                                                    <div className="col-12">
                                                                        <div className="w-100">
                                                                            <p>At times patients may have an area on their body that won’t heal after surgery. This can happen at times where a skin graft was placed on the wrist or ankle. In these cases, your surgeon may ask you to do “wet to dry dressing changes”.</p>
                                                                            <p>Wet to dry dressing changes help a wound heal by removing dead tissue and promoting the generation of healthy new tissue.</p>
                                                                            <p><strong>The dressing changes are very easy and are as follows:</strong></p>
                                                                            <p><strong className="colorCareOrbitBlue">1.</strong> Remove the old, dried gauze.</p>
                                                                            <p><strong className="colorCareOrbitBlue">2.</strong> Open the new gauze fully.</p>
                                                                            <p><strong className="colorCareOrbitBlue">3.</strong> Wet the new gauze with saline.</p>
                                                                            <p><strong className="colorCareOrbitBlue">4.</strong> Squeeze the gauze until it is almost dry (it should NOT be dripping wet).</p>
                                                                            <p><strong className="colorCareOrbitBlue">5.</strong> Pack or place the moistened gauze into the open areas of the wound.</p>
                                                                            <p><strong className="colorCareOrbitBlue">6.</strong> Cover the wound with a dry gauze.</p>
                                                                            <p><strong className="colorCareOrbitBlue">7.</strong> Secure the dressing with tape.</p>
                                                                            <p><strong className="colorCareOrbitBlue">8.</strong> Repeat this process 1 to 3 times a day based on your surgeon’s instructions.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">WOUND PACKING</h3>
                                        </div>
                                    </div>

                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body className="flex-column">

                                                        <div className="col-12 video">
                                                            <div className="w-100">
                                                                <div className="floatLeft clearLeft w-100">
                                                                    <div className="embedContainer originalPadding">
                                                                        <YouTube videoId="iMHbcg67DnU" opts={opts} onPlay={playVideoWoundPacking}/>
                                                                    </div>
                                                                    <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Wound Packing</span></h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets floatFix">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom floatLeft">
                                                                    <li>Some deeper wounds may require daily packing to heal. Packing helps the wound heal from
                                                                        the inside out.</li>
                                                                    <li>If you have a wound that needs to be packed at home, you will be given specific instructions
                                                                        by your surgeon on how often to pack and what material to pack the wound with.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong>Below are general instructions for wound packing.</strong></p>
                                                                <p><strong className="colorCareOrbitBlue">1.</strong> Wash your hands.</p>
                                                                <p><strong className="colorCareOrbitBlue">2.</strong> Remove old packing from the wound and discard.</p>
                                                                <p><strong className="colorCareOrbitBlue">3.</strong> Examine the wound. It is normal to have some liquid in the wound. Call your surgeon if there is a lot of discharge, bleeding, it is more tender, or smells bad.</p>
                                                                <p><strong className="colorCareOrbitBlue">4.</strong> Clean around the wound using wound cleanser or dilute hydrogen peroxide (equal parts of hydrogen peroxide and distilled water).</p>
                                                                <p><strong className="colorCareOrbitBlue">5.</strong> Repack the wound with the appropriate packing. Usually a cotton-tipped applicator (q-tip) can be used to insert the packing. Lightly pack the wound. Placing too much packing in the wound may slow down healing.</p>
                                                                <p><strong className="colorCareOrbitBlue">6.</strong> Place a gauze dressing over the packing and secure with tape.</p>
                                                                <p><strong className="colorCareOrbitBlue">7.</strong> Over time the wound will shrink and you will be using less and less packing at each dressing change.</p>
                                                                <p><strong className="colorCareOrbitBlue">8.</strong> Once you cannot fit packing material into the wound, you can stop packing and place a bandaid with antibiotic ointment over the wound until it has fully healed over.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower w-100 text-center">Keep your care team informed!</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100 iconRowCentered">
                                                                <i className="fa fa-camera-retro"></i>
                                                                <i className="fa fa-chevron-right"></i>
                                                                <i className="fa fa-envelope"></i>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p>Your care team may ask you to take a picture of the wound at least once a week and share that photo with them via email or MyChart. You can also call your surgeons’ office if you have any concerns.</p>
                                                                <p>This is done to help ensure proper healing. In the event that the wound is beginning to become infected, it can often be noticed when viewing the images. The proper course of action can then be taken.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadAndCopyWithIcon"><i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                                    To repeat: call us right away if you notice any of the following:
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Increased redness, swelling, or warmth in the skin around the wound</li>
                                                                    <li>Bad smell coming from the wound</li>
                                                                    <li>Fever above 100.4&deg;F (38&deg;C) or chills</li>
                                                                    <li>Increasing pain</li>
                                                                    <li>Bleeding</li>
                                                                    <li>Your incision has opened up or coming apart.</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop blueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default SitemanENTAll4_1_4;
