import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 legoMargin5 blueColorAndBorder">
                    <h1 className="tileTitle">Our Specialties</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin5">
                    <p>
                      With{" "}
                      <a
                        className="fluroscopyLink"
                        href="https://www.ortho.wustl.edu/content/Patient-Care/2295/Find-a-Physician/Physician-Directory.aspx"
                        target="_blank"
                      >
                        over 80 orthopedic specialists
                      </a>{" "}
                      at{" "}
                      <a
                        className="fluroscopyLink"
                        href="https://www.ortho.wustl.edu/content/Patient-Care/2321/APPOINTMENT-INFORMATION/Office-Locations.aspx"
                        target="_blank"
                      >
                        nine locations
                      </a>
                      , our practice provides trusted, personalized and
                      convenient care for patients of all ages. Our physicians
                      have advanced fellowship training in ten different
                      musculoskeletal subspecialty areas, which means you
                      receive a precise diagnosis and treatment plan for any
                      bone or muscle injury or condition.{" "}
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin4">
                    <h2 className="subHeadAllCaps">OUR SPECIALTIES INCLUDE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin1">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6b5f8c90ce5a8d5b03616188c3394d70.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/2489/Services/Foot-Ankle/Overview.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">Foot & Ankle</h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/782e2dbbadcf939ae6e7cb5e51481bfe.png" +
                                ")",
                            }}
                          >
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/2490/Services/Hand-Microsurgery/Overview.aspx"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                  >
                                    <h5 className="title">HAND & WRIST</h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin1">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/e38a67f2bf39136ef80850881abca9f7.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/2337/Services/Hip-Knee/Adult-Reconstruction-and-Hip-Preservation-Overview.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">Hip & knee</h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fb6924af46ed2140ef7f891c19cb6680.png" +
                                ")",
                            }}
                          >
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/7140/Services/Living-Well-Center.aspx"
                                    target="_blank"
                                  >
                                    <h5 className="title">
                                      LIVing well center
                                    </h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin1">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/3a8f7c9e4fd18ead2124331f8bd48a94.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/2491/Services/Musculoskeletal-Oncology/Overview.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">musculoskeletal</h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cc7141b954ed043fc9d7ac9683b554ea.png" +
                                ")",
                            }}
                          >
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/3575/Services/Orthopedic-Injury-Clinic/Overview.aspx"
                                    target="_blank"
                                  >
                                    <h5 className="title">
                                      orthopedic injury clinic
                                    </h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin1">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6c35ccb6517ac094f35c25e8cb9caa3b.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/2493/Services/Pediatric-and-Adolescent-Orthopedic-Surgery/Overview.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">
                                    pediatric/adolesCent
                                    <br />
                                    orthopedicS
                                  </h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7feaf6f8085c3920df9b9a166cfd80a7.png" +
                                ")",
                            }}
                          >
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/2347/Services/Physical-Medicine-and-Rehabilitation-Musculoskeletal/Overview.aspx"
                                    target="_blank"
                                  >
                                    <h5 className="title">
                                      physical medicine & rehabilitatioN <br />
                                      musculoskeletal
                                    </h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin1">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/b3842c6a36397c0d4579659a781c2cbd.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/8403/Services/Physical-Medicine-and-Rehabilitation-Neuro-Rehabilitation.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">
                                    physical medicine & rehabilitatioN
                                    <br />
                                    neuro rehab
                                  </h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7bb210b47824a21038650bf58773a597.png" +
                                ")",
                            }}
                          >
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/2494/Services/Shoulder-Elbow/Overview.aspx"
                                    target="_blank"
                                  >
                                    <h5 className="title">shoulder & elbow</h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin1">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4ceea26f3bc13dd439fc2ae1d21900d8.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/2345/Services/Spine/Overview.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">spine</h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/9d4d9675aaf93d5944f7cc79ebfc90bd.jpeg" +
                                ")",
                            }}
                          >
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/2495/Services/Sports-Medicine/Overview.aspx"
                                    target="_blank"
                                  >
                                    <h5 className="title">sports medicine</h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  {/* L93 Two equal elements side by side Specialty Lego */}
                  <div className="lego l093 legoMargin5">
                    <div className="contentContainer">
                      <div className="contentLeft">
                        <div
                          className="specialtyImageTitleLink legoMargin1"
                          style={{
                            backgroundImage:
                              "url(" +
                              "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/77d286b0f87a551f26fae01da8b060df.png" +
                              ")",
                          }}
                        >
                          <div className="shadeOverlay">
                            <div className="titleAndLink">
                              <p>
                                <a
                                  className="fluroscopyLink"
                                  href="https://www.ortho.wustl.edu/content/Patient-Care/2492/Services/TraumaFracture-Care/Overview.aspx"
                                  target="_blank"
                                >
                                  <h5 className="title">TRaUMA</h5>
                                  visit page
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contentRight">
                        <div className="specialtyImageTitleLink">
                          <div
                            className="specialtyImageTitleLink legoMargin1"
                            style={{
                              backgroundImage:
                                "url(" +
                                "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2cf964fd802e6b8c7bd723ebc4a2606b.png" +
                                ")",
                            }}
                          >
                            {" "}
                            <div className="shadeOverlay">
                              <div className="titleAndLink">
                                <p>
                                  <a
                                    className="fluroscopyLink"
                                    href="https://www.ortho.wustl.edu/content/Patient-Care/2834/Appointment-Information/Workers-Compensation.aspx"
                                    target="_blank"
                                  >
                                    <h5 className="title">
                                      workers’ compensation
                                    </h5>
                                    visit page
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End L93 Two equal elements side by side Specialty Lego */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport3;
