import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LessonFeedingAPersonWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFeedingAPerson">Feeding A
                                                            Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Let People Help Themselves as Much as They Can</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When you help to feed a person follow these tips:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Know the foods a person prefers to eat</li>
                                                    <li>Serve food at the temperature the person prefers</li>
                                                    <li>Provide meals at a time when a person is rested and has energy to eat</li>
                                                    <li>Let a person decide on the order of food he or she wants to eat and how fast</li>
                                                    <li>Cut food in small bite size pieces</li>
                                                    <li>If a person is receiving chemotherapy, avoid foods that have strong odors. Food tastes change often as a side effect of the chemotherapy drugs. Always check what foods are more tolerable than others.</li>
                                                    <li>Serve plates one at a time. Too many plates can be overwhelming and confusing.</li>
                                                    <li>If the person has poor vision, tell him or her where the foods are on the plate as if it was the face of a clock (see photo). Place foods, drinks, and dishes in the same place at each meal.</li>
                                                    <li>Use adaptive devices, if needed</li>
                                                    <li>Straws can be helpful with drinks, unless the person you are feeding has dysphagia.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Make Mealtime Pleasant</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_mealtime.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>There are ways you can increase a person’s appetiteor desire to eat.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Remove or control any bad odors in the area where the person eats.</li>
                                                    <li>Have the person brush their teeth or rinse their mouth before eating.</li>
                                                    <li>Have the person go to the bathroom and wash their hands before eating so that he or she is comfortable.</li>
                                                    <li>Make mealtime pleasant by talking with the person, have a good conversation.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow centered"><i className="fa fa-exclamation-circle"></i>If a Person Chokes While Eating</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If a person begins to choke be sure they are sitting up straight. If they can speak, the choking is not life threatening.
                                                </p>
                                                <p>Have them remove any food in their mouth and wait till the choking stops. Do not force them to eat or drink while choking. In some cases you might care for a person who uses oral suction routinely. Oral suction is a good way to remove secretions.
                                                </p>
                                                <p className="boldLeadIn">Heimlich Maneuver</p>
                                                <p>If the person choking cannot speak, cough, or breathe, call 911 immediately. While waiting for emergency responders stay calm and perform the Heimlich maneuver.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                            <div className="w-100">
                                                <div className="imageContainer">
                                                    <div className="leftCol col-5 paddingRight7">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_choking_1.png" className="img-responsive w-100" />
                                                        <div className="imgCaption">
                                                            <p className="captionBold">&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                    <div className="rightCol col-5 paddingLeft7">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_choking_2.png" className="img-responsive w-100" />
                                                        <div className="imgCaption">
                                                            <p className="captionBold">&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Stand or kneel behind the person. Wrap your arms around his or her waist.</li>
                                                    <li>If the person is standing, place one of your legs between his or her legs so you can support the person if he or she faints.</li>
                                                    <li>Make a fist with one hand. Place the thumb side of your fist against the person’s upper abdomen, below the ribcage, but above the belly button.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                            <div className="w-100">
                                                <div className="imageContainer">
                                                    <div className="leftCol col-5 paddingRight7">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_choking_3.png" className="img-responsive w-100" />
                                                        <div className="imgCaption">
                                                            <p className="captionBold">&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                    <div className="rightCol col-5 paddingLeft7">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_choking_4.png" className="img-responsive w-100" />
                                                        <div className="imgCaption">
                                                            <p className="captionBold">&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Grasp your fist with the other hand. Give a quick upward thrust up into the upper abdomen. This may cause the food to pop out. You may need to use more force for a large person and less for a child or small adult.</li>
                                                    <li>Repeat thrusts until the object pops out or the person faints.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If you cannot reach around the person, call 911 and do the following:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AP_heimlich.png" className="img-responsive floatNone maxWidth300" />
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Ease the person to the floor and place them on their back.</li>
                                                    <li>Facing the person, kneel astride the victim’s hips.</li>
                                                    <li>With one of your hands on top of the other, place the heel of your bottom hand on the upper abdomen below the rib cage and above the belly button.</li>
                                                    <li>Use your body weight to press into the victim’s upper abdomen with a quick upward thrust.</li>
                                                    <li>Repeat until object is expelled.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If the person stops breathing and loses their heartbeat (pulse), CPR may be necessary.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead16px"><i className="fa fa-exclamation-circle coralColorMargin" ></i>&nbsp;Do not do CPR on someone if you have not been trained.</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow centered"><i className="fa fa-exclamation-circle"></i>When feeding a person, never use straws if the person has dysphagia.</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_washing_hands.png" className="img-responsive floatNone maxWidth300" />
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>As a family caregiver it is important for you to follow food safety guidelines.</p>
                                                <p>Food safety is a public health issue. Germs enter food from improper cleansing, preparation or poor hand washing practices. Eating foods containing these germs can make a person very ill. Persons most at risk for developing food borne illnesses are the elderly, very young, and those with lowered resistance to infection.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder redBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower coralColor">Follow these Food Safety Tips</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare redBullets">
                                                    <li>Wash your hands with warm soapy water before touching food</li>
                                                    <li>Wash all fresh fruits and vegetables thoroughly</li>
                                                    <li>Do not eat raw meats or unpasteurized milk</li>
                                                    <li>Do not buy or eat food that has passed the expiration date on the package</li>
                                                    <li>Keep foods properly refrigerated at 40º Fahrenheit. Frozen foods should be kept at 0º Fahrenheit</li>
                                                    <li>Wash dishes and cutting boards with hot soapy water. Use a dishwasher if available</li>
                                                    <li>Do not save leftovers for more than 2 days in the refrigerator</li>
                                                    <li>Wash dishrags, towels and sponges regularly or run them thru a dishwasher cycle. The cleanest option is paper towels</li>
                                                    <li>Clean the inside of your refrigerator and microwave every month</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                            <div className="w-100">
                                                <div className="imageContainer">
                                                    <div className="leftCol col-5 paddingRight7">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_cleaning_sink.png" className="img-responsive w-100" />
                                                        <div className="imgCaption">
                                                            <p className="captionBold">&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                    <div className="rightCol col-5 paddingLeft7">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_cleaning_counter.png" className="img-responsive w-100" />
                                                        <div className="imgCaption">
                                                            <p className="captionBold">&nbsp;&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">You can play a key role in preventing food borne illnesses such as Salmonella, E Coli and staphylococcus in the home.</p>
                                                <p>Most people do not think about food borne illness until the person they care for or they themselves become ill. While the food supply in the United States is one of the safest in the world, the CDC estimates that each year 76 million cases of food borne illness occur.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle w-100 text-center">Video: Learn to feed a person in a way that prevents choking.</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="WmAfFlz6FOk" opts={opts}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right"></i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_equipment.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Special utensils (optional): two-handled cup, plate with plate guard, utensils with large handles</li>
                                                    <li>Regular utensils, plate</li>
                                                    <li>Damp washcloth and towel</li>
                                                    <li>Napkin or towel</li>
                                                    <li>Toothbrush and toothpaste or denture cup and cleaner</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonFeedingAPersonWhatToDoAndSafety;
