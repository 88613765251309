import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonInhalers = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInhalers">Inhalers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonNebulizers/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonNebulizers/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Using a Nebulizer</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>People who have chronic lung disease such as asthma, emphysema and bronchitis frequently use inhalers on a daily basis. Medicines breathed in (inhaled) through the mouth travel to the lungs to widen the airways and thin mucous. These effects make it easier for a person to breathe and to cough up thick mucus.</p>
                                        <p>Because these persons depend on their medicines for disease control, they must learn how to use inhalers safely and correctly.</p>
                                        <p className="boldLeadIn">The medicines are delivered in one of two ways:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Through a metered dose inhaler, called a MDI</li>
                                            <li>Through a dry powdered inhaler, called a DPI</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>To use inhalers correctly, a person needs to be able to hold the device and handle the parts correctly. In addition, the correct use of an MDI requires a person to be able to depress the canister and to take a deep breath at the same time. As the caregiver for a person who uses an inhaler, you need to be able to decide if the person uses an inhaler correctly. If not, you’ll need to inform the person’s doctor so that a different way to deliver the medicine can be chosen. If a person does not use an inhaler correctly, problems with breathing can quickly lead to visits to the doctor or even a hospital.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="hearTheExpert">HEAR THE EXPERT</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>To use inhalers correctly, a person needs to be able to hold the device and handle the parts correctly. In addition, the correct use of an MDI requires a person to be able to depress the canister and to take a deep breath at the same time.</p>
                                    </div>
                                </div>
                                <div className="col-12 video">
                                    <div className="w-100">
                                        <h3 className="blueColor videoTitle w-100 text-center">Video: Use of Inhalers</h3>
                                        <div className="floatLeft w-100 captionBelow">
                                            <div className="embedContainer">
                                                <YouTube videoId="-hMZDePH1n8" opts={opts} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInhalers;