import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.module.scss";
import Container from "react-bootstrap/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Input } from "@mui/material";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { loginUser } from "../../../actions/login.js";
import { Navbar, Nav, Dropdown } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { setLoginScreenStatus } from "../../../helpers/index.js";
import { validatePassword, validateUserName } from "./validation.js";
import { InputWithErrorMessage } from "../../forms/inputWithErrorMessage.js";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  validateEmailAddress,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
} from "../register/validation.js";
import styles from "../../routing/topNavLoggedOut/TopNavtopNavLoggedOut.module.scss";
import TopNavLoggedOut from "../../routing/topNavLoggedOut";
import InternalServerError from "../../routing/Errors/InternalServerError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AUTH_ERROR } from "../../../actions/types.js";

const Login = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { errorMessage } = useSelector((state) => state.auth);
  const [errorMessageLocal, setErrorMessageLocal] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickShowPassword = () => {
    setShowPassword(showPassword);
  };

  useEffect(() => {
    dispatch({
      type: AUTH_ERROR,
      payload: null,
    });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  setLoginScreenStatus(true);

  const submitForm = async (e) => {
    e.preventDefault();
    dispatch({
      type: AUTH_ERROR,
      payload: null,
    });

    if (!(await validateAllFields())) return;

    const login = await loginUser({ username: userName, password }, () => {
      setLoginScreenStatus(false);
      navigate("/");
    });
    await dispatch(login);
  };

  const changeUserName = (e) => setUserName(e.target.value);
  const changePassword = (e) => setPassword(e.target.value);

  const validateUserNameField = async (e) => {
    await setUserNameError(validateUserName(userName));
  };

  const validatePasswordField = async (e) => {
    setPasswordError(validatePassword(password));
  };

  const validateAllFields = async () => {
    const invalidUserName = validateUserName(userName);
    const invalidPassword = validatePassword(password);

    setUserNameError(invalidUserName);
    setPasswordError(invalidPassword);

    if (invalidUserName.length > 0 || invalidPassword.length > 0) return false;
    else return true;
  };

  const forgotPassword = (e) => navigate("/forgotpassword");

  return (
    <div className="w-100 gradient">
      <div className="w-100">
        <ToastContainer />
        <Container className="registration">
          <div className="pageContainer">
            <h1 className="text-center title">Log In</h1>
            <Box
              className="material-box"
              component="form"
              onSubmit={submitForm}
            >
              <div className="card-body">
                <div className="formFlexContainer">
                  <div className="formInput">
                    <TextField
                      className="w-100"
                      id="userName"
                      label="User Name or Email Address"
                      name="userName"
                      variant="outlined"
                      onChange={changeUserName}
                      onBlur={validateUserNameField}
                      helperText=""
                      error={!!userNameError}
                      required
                    />
                    <InputWithErrorMessage
                      errorMessage={userNameError}
                    ></InputWithErrorMessage>
                  </div>
                  <div className="formInput">
                    <TextField
                      type={showPassword ? "text" : "password"}
                      className="w-100"
                      id="password"
                      label="Password"
                      name="password"
                      variant="outlined"
                      onChange={changePassword}
                      onBlur={validatePasswordField}
                      helperText=""
                      error={!!passwordError}
                      required
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <a
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              style={{ color: "#3399cc" }}
                            >
                              {showPassword ? "hide" : "show"}
                            </a>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <InputWithErrorMessage
                      errorMessage={passwordError}
                    ></InputWithErrorMessage>
                    <span className="validationError">{}</span>
                    </div>
                    <a style={{}} className="btn-link" onClick={forgotPassword}>
                      Forgot your username and/or password?
                    </a>

                </div>
                <div className="buttonContainer loginButtonContainer">
                  <Button
                    className="loginUIComp center"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!!userNameError || !!passwordError}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        </Container>
      </div>
      <div className="gradientBottom"></div>
    </div>
  );
};

export default Login;
