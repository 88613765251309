import React from "react";

function L91ComponentCode() {

    return (

        <div className="lego l091">
            {/* L10A Bold Lead In */}
            <div className="lego l00paragraph">
                <p className="boldLeadInNewUI">Cleaning and Care
                </p>
            </div>
            {/* End L10A Bold Lead In */}

            {/* L53 Bullet List */}
            <div className="lego l053 legoMargin3 smallFont">
                <ul className="w-100">
                    <li className="w-100">A MicroLead (thin, thread-like wire) exits your
                        skin
                        under the Waterproof Bandage. Do NOT pull on it.
                    </li>
                    <li className="w-100">Change the Waterproof Bandage & Cradle when they
                        become soiled, damp, or no longer adhere well to the skin (about
                        every
                        4-5 days). Take care when removing these to prevent pulling the
                        MicroLead out.
                    </li>
                    <li className="w-100">Clean skin under the bandage with mild soap &
                        water OR
                        wipe skin with an alcohol pad. Allow skin to dry.
                    </li>
                    <li className="w-100">Review the Changing the Bandage and Cradle section
                        of
                        the QUICK START GUIDE for detailed instructions.
                    </li>
                    <li className="w-100">Check the skin under the bandage for redness,
                        swelling, drainage, or bleeding during bandage changes.
                    </li>
                    <li className="w-100">If any of these signs are present, or if your
                        MicroLead(s) pull out, notify your doctor and an SPR Therapeutics
                        representative.
                    </li>
                </ul>
            </div>
            {/* End L53 Bullet List */}
        </div>

    )
};


export default L91ComponentCode;