import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonPainIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Pain: Hear the Expert'}, () => {
            console.log('Pain: Hear the Expert');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center width400">
                                                        <p><Nav.Link className="" href="/LessonPain">Recognizing and Relieving Pain</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation ">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonPain/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonPain/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/PN_comforting1.png" className="img-responsive maxWidth300 floatNone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Each of us has had some type of pain in the past. When a person has pain, they feel distress and want relief. No two people experience pain the same. For that reason, it is often difficult for a family caregiver to understand and know what to do when the person they care for has pain. How people deal with pain also differs greatly from person to person. We cannot judge someone else’s pain based on our own experience of pain.</p>
                                                <p><span className="bold italic">Pain is very real to a person</span>. Thus, when you care for a person in pain:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Respect their feelings</li>
                                                    <li>Accept their pain</li>
                                                    <li>Do what you can to make them more comfortable</li>
                                                    <li>Determine the level of pain they are having</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Listen to the Person</p>
                                            <p>A person’s report of pain is the most reliable sign that he or she is in pain. However, many persons choose not to report or talk about their pain. If a person thinks you doubt he or she is having pain, then they will not talk about it. It is very important for the person to feel like he or she can tell you how they feel.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Show That You Care</p>
                                            <p>Simply sitting down and talking with a person about how they feel, lets them know you do care and want to help.</p>
                                            <p>By knowing when a person is in pain, you can act and decide if you need to call a doctor or emergency medical services. This lesson will give you useful information on how to know a person is having pain and some very basic ways that can help to make them feel more comfortable.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>By knowing when a person is in pain, you can act and decide if you need to call a doctor or emergency medical services.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Video: Pain.
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="fv4FcD8oM-k" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonPainIntroduction;
