import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter4_1 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a5bad22dbca04a5d88957e35816ad470.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#91651E",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Making Your Plan</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L12A Head, Lead In Photo Larger with body copy */}
                  <div className="lego l012A">
                    <div className="contentContainer">
                      <div className="imgContainer1">
                        <img
                          className=""
                          alt=""
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/94ebedd43b0d25f09934453b9b5f75aa.jpeg"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p
                            className="legoMargin3"
                            style={{ fontSize: "22px", lineHeight: "33px" }}
                          >
                            <span className="paragraph22px">
                              Determining how the Living Cell Center can best
                              work with you is easy.
                            </span>
                            <br />
                            It all begins with a consultation that you can
                            schedule simply by calling 
                            <span
                              className="paragraph22px"
                              style={{ fontStyle: "normal" }}
                            >
                              314.514.3565
                            </span>
                          </p>
                          <p
                            className="legoMargin3 "
                            style={{ fontSize: "22px", lineHeight: "33px" }}
                          >
                            When you come for that first visit at the Living
                            Well Center, we’ll spend time listening and
                            discussing what is important to you, what you’re trying
                            to accomplish, and what your barriers to your health
                            have been.
                          </p>
                          <p
                            className="legoMargin3"
                            style={{ fontSize: "22px", lineHeight: "33px" }}
                          >
                            Then we'll work together to design a specific plan,
                            whether that’s to accomplish some weight loss, or to
                            work on improving your exercise regimen or simply
                            just managing your stress a little bit better.  And
                            you'll leave with specific actionable items and a
                            good understanding of how our team can support you. 
                          </p>
                          <p
                            className="legoMargin3"
                            style={{ fontSize: "22px", lineHeight: "33px" }}
                          >
                            And the Living Well Center initial visit is billed
                            to insurance just like a specialist medical visit. 
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12A Head, Lead In Photo Larger with body copy */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter4_1;
