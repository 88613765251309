import {
    HOME,
    HOME_DIAGNOSTIC_FILTER_LIST,
    HOME_DIAGNOSTIC_ID,
    HOME_DIAGNOSTIC_LIST,
    HOME_DISPLAY_TILES,
    HOME_ERROR,
    HOME_DISPLAY_DIAGNOSTICS,
    HOME_ORBIT_ID,
    HOME_ORBIT_LIST,
    HOME_DEFAULT_ORBIT,
    INITIAL_TOP_NAV_DISPLAY,
    NAVIGATE_TO_HOME_PAGE,
    TERMS_CONDITIONS_COMPLETED,
    REFRESH_TILE_ATTRIBUTE_LIST,
    HOME_ORGANIZATION_DEPARTMENT_LIST,
    HOME_ORGANIZATION_DEPARTMENT_ID, HOME_DISPLAY_ORGANIZATION_DEPARTMENT
} from "../actions/types";
import {setRefreshTileAttributeList} from "../actions/home.js";

const INITIAL_STATE = {
    errorMessage: '',
    tileAttributes: [],
    diagnosticId: [],
    diagnosticList: [],
    diagnosticFilterList: [],
    clusterType: '',
    useFlexContainer: false,
    displayDiagnostics: false,
    displayTiles: false,
    displayOrganizationDepartment: false,
    orbitId: [],
    orbitList: [],
    defaultOrbit: [],
    clusterCssArray: [],
    initialTopNavDisplay: false,
    navigateToHomePage: false,
    termsConditionsCompleted: false,
    refreshTileAttributeList: false,
    organizationDepartmentId: null,
    organizationDepartmentList: null
};

export const homeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HOME:
            return {
                ...state,
                errorMessage: '',
                tileAttributes: action.payload.tileAttributes,
                clusterType: action.payload.clusterType,
                useFlexContainer: action.payload.useFlexContainer,
                clusterCssArray: action.payload.clusterCssArray
            };



        case HOME_DIAGNOSTIC_LIST:
            return {
                ...state,
                diagnosticList: action.payload.diagnosticList
            }

        case HOME_ORGANIZATION_DEPARTMENT_LIST:
            return {
                ...state,
                organizationDepartmentList: action.payload.organizationDepartmentList
            }

        case TERMS_CONDITIONS_COMPLETED:
            return {
                ...state,
                termsConditionsCompleted: action.payload.termsConditionsCompleted
            }

        case HOME_DEFAULT_ORBIT:
            return {
                ...state,
                defaultOrbit: action.payload.defaultOrbit
            }

        case HOME_ORGANIZATION_DEPARTMENT_ID:
            return {
                ...state,
                organizationDepartmentId: action.payload.organizationDepartmentId
            }

        case HOME_ORBIT_ID:
            return {
                ...state,
                orbitId: action.payload.orbitId
            }

        case HOME_DISPLAY_TILES:
            return {
                ...state,
                displayTiles: action.payload.displayTiles
            }

        case HOME_DISPLAY_ORGANIZATION_DEPARTMENT:
            return {
                ...state,
                displayOrganizationDepartment: action.payload.displayOrganizationDepartment
            }

        case HOME_ORBIT_LIST:
            return {
                ...state,
                orbitList: action.payload.orbitList
            }

        case HOME_DISPLAY_DIAGNOSTICS:
            return {
                ...state,
                displayDiagnostics: action.payload.displayDiagnostics
            }

        case HOME_DIAGNOSTIC_FILTER_LIST:
            return {
                ...state,
                diagnosticFilterList: action.payload.diagnosticFilterList
            }

        case HOME_DIAGNOSTIC_ID:
            return {
                ...state,
                diagnosticId: action.payload.diagnosticId
            }

        case NAVIGATE_TO_HOME_PAGE:
            return {
                ...state,
                navigateToHomePage: action.payload.navigateToHomePage
            }

        case INITIAL_TOP_NAV_DISPLAY:
            return {
                ...state,
                initialTopNavDisplay: action.payload.initialTopNavDisplay
            }

        case REFRESH_TILE_ATTRIBUTE_LIST:
            return {
                ...state,
                refreshTileAttributeList: action.payload
            }

        case HOME_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
