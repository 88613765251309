import React, {useEffect, useState} from 'react';
import styles from './TopNavtopNavLoggedOut.module.scss';
import Container from "react-bootstrap/Container";
import {Navbar, Nav, Dropdown} from "react-bootstrap";
import logo from "../../../images/careorbitLogo.svg";


const TopNavLoggedOut = () => {
  return (
      <Navbar className="navbarLoggedOut" variant="light" expand="lg">
            <div className="navLogoContainer">
              <Navbar.Brand className="logoContainer">
                <img alt="Powered by CareOrbit Logo" src={'https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/powered_by_CareOrbit-1.svg'}/>
              </Navbar.Brand>
            </div>
            {/*<Navbar.Toggle aria-controls="basic-navbar-nav"/>*/}
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
  );
};

export default TopNavLoggedOut;
