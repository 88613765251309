import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {useEffectForVideoAccess, videoAccessLog} from "../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin1_4 = () => {

    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'The Pritikin Story'}, () => {
            console.log('The Pritikin Story');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderdDarkBlue">The
                                                Pritikin Story</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="">
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="nDWroECs7NA" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower">You can become part of the story.</h2>
                                            <p>Nathan Pritikin, a gifted inventor and engineer, was diagnosed with heart
                                                disease in the late 1950s. He was given a little encouragement by his
                                                doctors, as cholesterol-lowering drugs and bypass surgery were not
                                                available to treat coronary disease at the time. </p>
                                            <p>Undeterred, and instead focused on <span className="bold italic">determining the root cause of his condition</span>,
                                                Pritikin decided to research cultures around the world <span
                                                    className="bold italic">not</span> suffering from major epidemics,
                                                such as heart disease. <span className="bold italic">He found that these populations were physically active and consumed a largely plant-based diet, with limited amounts of animal protein and very little sodium.</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidbarFill pritikinBackgroundDarkBlue">
                                                        <h2 className="subheadUpperAndLower withBodyCopy mb-0">Pritikin was
                                                            convinced this lifestyle was the solution to preventing
                                                            heart disease and a multitude of other chronic health
                                                            conditions, and his own personal health outcomes proved his
                                                            theory.</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>He described these findings in his first book, <span
                                                className="bold italic">Live Longer Now</span>, published in 1974, and
                                                he opened the first Pritikin Longevity Center one year later. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 d-flex content-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pritikin-gallery.jpg"
                                                 className="img-responsive centerImage w-100
                                 maxWidth400"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>In the decades since, more than 100 studies have been published
                                                documenting the success of the Pritikin program in preventing and
                                                reversing not only heart disease, but also several other conditions,
                                                including hypertension, type 2 diabetes, metabolic syndrome, and
                                                obesity.</p>
                                            <h2 className="subheadUpperAndLower">The story behind the Pritikin
                                                Program.</h2>
                                            <p>You can join tens of thousands of cardiac rehab patients who have
                                                participated in Pritikin programs from coast-to-coast. You have a
                                                committed team of cardiac rehab professionals behind you, and they cannot
                                                wait to see what you accomplish as you continue your recovery and
                                                achieve your health goals!</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin1_4;