import { FORGOT_PASSWORD_ERROR, CLEAR_ERROR, FORGOT_PASSWORD_ASSIGN_USER, RESET_PASSWORD_ASSIGN_OTP } from "../actions/types";

const INITIAL_STATE = {
    errorMessage: '',
    userName: '',
    passwordCode: ''
};

export const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        case CLEAR_ERROR:
            return {
                ...state,
                errorMessage: ''
            };

        case FORGOT_PASSWORD_ASSIGN_USER:
            return {
                ...state,
                userName: action.payload,

            };

        case RESET_PASSWORD_ASSIGN_OTP:
            return {
                ...state,
                passwordCode: action.payload,

            };

        default:
            return state;
    }
};
