import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonStockingsIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonStockings">Applying Elastic Stockings</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonStockings/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonStockings/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Exercising each day and staying active helps circulation in our legs. You might have heard a doctor or nurse talk about “getting your circulation going” to prevent blood clots from forming in the legs. A blood clot can form in a vein of the body if circulation slows or if there is damage or irritation to a vein. A deep vein thrombosis or DVT is a clot in a deep vein, usually in the legs.</p>
                                        <p>A number of conditions can cause a DVT. When a person develops a DVT, the clot in the vein can easily break off and travel up to the person’s lungs and then block blood flow to the lung. This condition is called a Pulmonary Embolus (PE) and it is life threatening.</p>
                                        <p>If you provide care for someone who is at risk for forming a DVT, you need to understand why the person is at risk and how you can lower those risks. One approach to reducing the risk for DVT is wearing compression or elastic stockings.</p>
                                        <p>Compression stockings provide support and firm pressure around the veins on the outside of the legs. The stockings prevent blood from pooling to lessen the chance of clots forming. This lesson will show you how to apply compression stockings correctly and other tips for preventing a DVT.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                        Video: Preventing Deep Vein Thrombosis& Applying Elastic Stockings
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="hhAPGnx5T0Y" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer greenBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonStockingsIntroduction;