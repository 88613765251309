import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTL3_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center sitemanGreenColor sitemanGreenBorderColor">Pre-Surgery
                                                Health</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="headline w-100 text-center text-dark">BEING
                                                HEALTHY FOR SURGERY</h3>
                                            <p>Coming into your procedure as healthy as possible increases your chances
                                                for the best results and minimizes the chance of complications. The
                                                healthier you are, the smoother the journey and the faster the healing
                                                as you progress toward your new life.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon sitemanGreenBackground text-center">Healthy
                                                Teeth</h3>
                                            <p>Schedule an appointment with your dentist before surgery to make sure
                                                your teeth are in the best health. If you will be having radiation
                                                therapy, you may need unhealthy teeth repaired or removed prior to starting.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon sitemanGreenBackground text-center">Stop
                                                Smoking</h3>
                                            <p className="w-100">Stopping smoking is important to make your cancer treatment as effective
                                                as possible in killing your cancer and also preventing your cancer from coming back.
                                                Please try out the “<a href="https://www.freedomfromsmoking.org" target="_blank">Freedom From Smoking</a>“ program. This free program
                                                will help you change habits, reduce stress and teach you how to prevent
                                                relapses. </p>
                                            <p className="w-100"><em>Call <a href="tel:3143627844">314.362.7844</a></em></p>
                                            <p className="w-100">Or visit <a
                                                href="https://siteman.wustl.edu/prevention/risk-factors/tobacco/" target="_blank">www.siteman.wustl.edu/quitsmoking.aspx</a>
                                            </p>
                                            <p className="w-100">If this program does not work for you, please call your local hospital or
                                                one of the programs offered by the state of Missouri/Illinois by calling&nbsp;
                                                <a href="tel:18007848669">1-800-QUIT-NOW (1.800.784.8669)</a>.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon sitemanGreenBackground text-center">Diabetes</h3>
                                            <p>It is very important to control your blood sugar levels during your
                                                journey. When you control your blood sugar, you will heal faster,
                                                minimize wound complications and assist in preventing infection.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon sitemanGreenBackground text-center">Nutrition</h3>
                                            <p>Swallowing may be difficult, but maintaining nutrition is important to
                                                help you heal your surgical wounds. You may require supplemental
                                                nutrition by mouth or via a feeding tube.</p>
                                        </div>
                                    </div>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entPreparingSurgeryGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL3_2;
