import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonMouthCareWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonMouthCare">Mouth Care</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i className="fa fa-exclamation-circle">&nbsp;</i>When you give mouth care to someone at home, it helps to understand the causes of mouth problems.
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who Is at Risk for Dry Mouth</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A dry mouth means that there is less saliva (spit) in the mouth, which can cause the soft tissues in the mouth to get irritated. The irritation often leads to infection. With less saliva, a person is more likely to have a sore throat, problems speaking, trouble swallowing and dry nose. Cavities of the teeth are also more common in persons with dry mouth.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon subhead26px">Who is at Risk for Stomatitis</h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Older Age: As a person gets older, the glands in the mouth secrete less saliva, so dry mouth is common. The sense of thirst also decreases with age.</li>
                                            <li>Medicines: A number of medicines, even those you buy over-the-counter, can cause a dry mouth. Examples are:</li>
                                            <ul className="bulletsCustom checkBulletsSafeCare bulletsSafeCareIndent">
                                                <li>Antihistamines – drugs used for allergies</li>
                                                <li>Chemotherapy – drugs used to treat cancer</li>
                                                <li>Medicines for high blood pressure</li>
                                                <li>Medicines for depression and anxiety</li>
                                                <li>Medicines for urinary incontinence</li>
                                                <li>Medicines for Parkinson’s disease</li>
                                            </ul>
                                            <li>Smoking, chewing tobacco</li>
                                            <li>Persons who snore, or breathe through their mouth</li>
                                        </ul>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Stomatitis</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>An inflammation (redness and swelling) of the mucous tissues in the mouth. A person will have burning in the mouth, pain and a change in the amount and type of foods they can eat.</p>
                                        <p>Mouth ulcers are also common, ranging from mild to very severe. Stomatitis is often caused by infection, an allergic reaction and physical irritation of the mouth. Certain diseases can cause stomatitis.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/MC_stomatitis.png" className="img-responsive maxWidth300 floatNone" />
                                        <p className="captionItalic text-center w-100">Stomatitis of the mouth</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon subhead26px">Risks for Stomatitis </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Persons on chemotherapy</li>
                                            <li>Persons who receive radiation therapy to the head and neck</li>
                                            <li>Cheek biting, broken teeth, ill-fitting dentures, and mouth breathing</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Keeping People Healthy</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Help a person stay as independent as possible. If the person can hold a toothbrush and move it correctly in the mouth, let him or her brush their own teeth, and give assistance only as needed.</li>
                                            <li>Let the person also tell you how often he or she wants mouth care. Many people find comfort in frequent brushing and rinsing of the teeth.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonMouthCareWhatYouNeedToKnow;