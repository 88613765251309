import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import { pageAccessLog } from "../../actions/pageAccessLog.js";

const TermsOfUse = () => {
  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft">
                <section>
                  <div className="w-100">
                    <h1 className="signUph1 text-center">Terms of Service</h1>
                  </div>
                  <div className="w-100">
                    <h3 className="text-dark w-100">
                      PLEASE READ THESE TERMS OF SERVICE OF USE CAREFULLY BEFORE
                      USING THIS SITE.
                    </h3>
                    <p className="text-dark w-100">
                      By using this site, you signify your assent to these Terms
                      and Conditions. If you do not agree to all of these Terms
                      and Conditions of use, do not use this site!
                    </p>
                    <p className="text-dark w-100">
                      CareOrbit.com ("CareOrbit") may revise and update these
                      Terms of Service at any time. Your continued usage of the
                      CareOrbit website ("CareOrbit Site," or the "Site,") will
                      mean you accept those changes.
                    </p>
                    <h2 className="text-dark w-100">
                      The Site Does Not Provide Medical Advice
                    </h2>
                    <p className="text-dark w-100">
                      The contents of the CareOrbit Site, such as text,
                      graphics, images, information obtained from CareOrbit's
                      licensors, and other material contained on the CareOrbit
                      Site ("Content") are for informational purposes only. The
                      Content is not intended to be a substitute for
                      professional medical advice, diagnosis, or treatment.
                      Always seek the advice of your physician or other
                      qualified health provider with any questions you may have
                      regarding a medical condition. Never disregard
                      professional medical advice or delay in seeking it because
                      of something you have read on the CareOrbit Site!
                    </p>
                    <p className="text-dark w-100">
                      If you think you may have a medical emergency, call your
                      doctor or 911 immediately. CareOrbit does not recommend or
                      endorse any specific tests, physicians, products,
                      procedures, opinions, or other information that may be
                      mentioned on the Site. Reliance on any information
                      provided by CareOrbit, CareOrbit employees, others
                      appearing on the Site at the invitation of CareOrbit, or
                      other visitors to the Site is solely at your own risk.
                    </p>
                    <h2 className="text-dark w-100">Children's Privacy</h2>
                    <p className="text-dark w-100">
                      We are committed to protecting the privacy of children.
                      You should be aware that this Site is not intended or
                      designed to attract children under the age of 13. We do
                      not collect personally identifiable information from any
                      person we actually know is a child under the age of 13.
                    </p>
                    <h2 className="text-dark w-100">
                      Services, User Obligations and Registration
                    </h2>
                    <p className="text-dark w-100">
                      CareOrbit.com (together with its subdomains) (the “Site”)
                      is a website owned by Total Orbit, Inc. (“CareOrbit”) that
                      allows end-users to purchase licenses to use the Content
                      (as defined below) accessible via the use of the CareOrbit
                      website. By accessing the Site, clicking the “purchase” or
                      “pay” button, and/or by installing, using or downloading
                      the Software you agree to enter into a binding legal
                      agreement with Total Orbit, Inc. and agree to comply with,
                      and be legally bound by, the terms and conditions of these
                      Terms of Service, the{" "}
                      <Link to="../privacy-policy" target="_blank">
                        Privacy Policy
                      </Link>{" "}
                      and the CareOrbit{" "}
                      <Link to="../cookie-policy" target="_blank">
                        Cookie Policy
                      </Link>{" "}
                      (collectively, the “Terms”). To the extent not prohibited
                      under applicable law, you hereby waive any applicable
                      rights to require a non-electronic signature or delivery
                      of non-electronic records. If you do not agree to be bound
                      by these Terms in their entirety, you are not permitted to
                      access the Site, download, install or use the Software
                      and/or Content, nor use the Services. The Service (as
                      defined below) is provided for a fee, where CareOrbit may
                      provide some of it without any charge. When using the
                      Site, you can purchase licenses, receive information,
                      Content, updates, services, download Software or access
                      your Account (as defined below) (the “Service”), but their
                      availability is provided on an “as-is” basis and without
                      any warranty to its quality.
                    </p>
                    <p className="text-dark w-100">
                      When using the Site, CareOrbit may retain some personally
                      identifiable information about you in accordance with our
                      Privacy Policy , as well as some other aggregated,
                      non-personally identifiable information . In no case shall
                      your personal information be transferred to third-parties
                      for purposes of marketing such third parties or their
                      products or services. CareOrbit may send you newsletters
                      from time to time. We hope you enjoy your use of the Site,
                      Software and Services and request that you carefully read
                      these Terms. By confirming these Terms, you warrant that
                      you adhere to the following eligibility terms, and that if
                      you are acting on behalf of a corporation, that all the
                      end-users and employees of said corporation which shall be
                      permitted to access the Site, Services and license the
                      Content and/or Software which shall be purchased or
                      accessible through the Site, shall accept these Terms as
                      well.
                    </p>
                    <ul className="text-dark floatLeft clearLeft width100">
                      <li className="floatLeft clearLeft width100">
                        <strong>Eligibility</strong>: You are eligible to enter
                        into this agreement, and (1) you were not prohibited by
                        any competent authority, judicial act or law to enter in
                        any agreement; (2) you are at least 18 years of age, or
                        if the Services are used by a user under the age of
                        eighteen (18) or otherwise under the age of legal
                        eligibility and capacity in the jurisdiction applicable
                        to such user you hereby confirm that you are the legal
                        guardian of the user and both you and the User have read
                        and agreed to these Terms and approved of the user’s
                        continued use of the Service subject to these Terms; (3)
                        you were not previously prohibited by CareOrbit from
                        entering into an agreement with CareOrbit nor was your
                        account and/or use of the Services previously blocked,
                        suspended or terminated by CareOrbit for any reason; (4)
                        you do not reside in any proscribed state and you are
                        not a member of a terror organization; (5) you are using
                        the Services and/or entering these Term on behalf of a
                        corporation you have the authority to bind such are
                        authorized by such corporation and hold all the required
                        consents to enter into and be bound by these Terms on
                        behalf of such corporation.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>Personal Use Only</strong>: The Subscription
                        Plan(s) and the associated license is solely for your
                        personal and non-commercial use and you shall not sell,
                        rent, convey, distribute, commercialize or publish the
                        license and related Services to any third parties. This
                        does not mean that you cannot use our framework to
                        produce and generate websites for others, but it means
                        that you cannot resell your Subscription Plan and the
                        associated license to third parties so they can access
                        to the Site and/or the Services.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>Acceptable and Restricted Use</strong>: You
                        shall use the Services only in a bone-fide manner and in
                        accordance with these Terms. You shall not, nor allow a
                        third party to use the Services in any manner which may
                        (i) impair, interfere disrupt or delay the operation of
                        the Services ; (ii) send spam, unsolicited messages,
                        harass, stalk or threaten any person or computer system;
                        (iii) interfere with or violate any other person’s
                        right, including right to privacy or intellectual
                        property rights, or harvest or collect personally
                        identifiable information about any users of the Service
                        without their express consent,; (iv) use automated means
                        such as computer software, crawlers, robots or scripts
                        to harvest, process, cache, scrape, export or extract or
                        otherwise bulk-download and/or store, any Content,
                        information, or other materials or information made
                        available via the Services; (v) create a database of, or
                        offer as a service, create or offer via a hosting
                        service, provide to others as part of a “Software
                        As-a-Service” (SaaS), or otherwise distribute, offer or
                        resell, the Services or the Content, or information, or
                        other materials or information made available via the
                        Services; (vi) allow, cause or authorize or permit any
                        third party to do any of the foregoing; (vii) use the
                        Services for any illegal, immoral or unauthorized
                        purpose, or which harms or may harm the goodwill of
                        CareOrbit (in CareOrbit’s reasonable discretion). You
                        shall not create any script, software, code or program
                        which impairs, disrupts, diminishes or adds
                        functionality to the Services, including transmitting or
                        otherwise make available in connection with the Service
                        any virus, worm, Trojan Horse, time bomb, web bug,
                        spyware, or any other computer code, file, or program
                        that may or is intended to damage or hijack the
                        operation of any hardware, software, or
                        telecommunications equipment, or any other actually or
                        potentially harmful, disruptive, or invasive code or
                        component.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>No License</strong>: Except as explicitly
                        licensed under these Terms, you are prohibited from any
                        use of CareOrbit’s, or any end-user, intellectual
                        property.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>Verifying Your Account</strong>: When
                        registering with the Service, CareOrbit may authenticate
                        your identity through different services and may,
                        according to its sole discretion, require additional
                        identification. If you register through electronic mail,
                        then CareOrbit may authenticate your email address and
                        send an electronic notification to the address you
                        provided. In said message, CareOrbit may include
                        information or links to authenticate your identity as
                        the owner of the email address. Once verified CareOrbit
                        will set up a registered account for you (“Account”).
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>Securing Your Account</strong>: According to the
                        sensitivity of the information stored on the service and
                        the potential risk of using the Service, you shall be
                        required to set up and use a password to access your
                        Account. You are solely responsible for maintaining the
                        confidentiality of your Account, including your username
                        and password. You agree to accept responsibility for all
                        activities that occur under your Account.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>One Account for One Person</strong>: It is
                        CareOrbit’s policy to have one Account for one person,
                        and one person for one Account. You cannot hold more
                        than one Account and you cannot allow others to access
                        your Account. If you are a corporation (or using the
                        Account on behalf of a corporation), then you can allow
                        access to your corporate Account for one authorized
                        employee on your behalf. You hereby represent and
                        acknowledge that unless you have otherwise explicitly
                        notified us anyone accessing your corporate Account is
                        acting on your behalf having all required authorizations
                        and consents, and that you remain responsible and liable
                        for any act or omission in connection with such use as
                        if they were yours, including any loss or damage which
                        such use, act or omission may cause.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>Sharing your Account Credentials</strong>: You
                        are expressly prohibited from sharing your username and
                        password with third-parties.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>Notification of Abuse</strong>: You are required
                        to inform CareOrbit, immediately, at
                        support@CareOrbit.com in any case where you have reason
                        to believe that your account was hacked, is no longer
                        secure and/or in any case you are informed of
                        unauthorized use of your CareOrbit account.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>CareOrbit’s Liability</strong>: Without
                        derogating any limitation of liability under these
                        Terms, CareOrbit shall not be liable in any case where
                        you do not adhere to these security standards and shall
                        not be able to assist you in any manner if you refrain
                        from applying its security guidelines, as published from
                        time to time.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        <strong>User Submissions</strong>: The personal
                        information you submit to CareOrbit is governed by the
                        CareOrbit Privacy Policy. To the extent there is an
                        inconsistency between this Agreement and the
                        CareOrbitPrivacy Policy, this Agreement shall govern.
                        The Site contains functionality{" "}
                        <strong>(including blogs, user surveys, etc.)</strong>{" "}
                        that allows users to upload content to the Site
                        (collectively "Public Areas") and users may also upload
                        content via our official brand presence on social media
                        platforms and branded hashtags (including, without
                        limitation Facebook, Twitter, Google Plus, YouTube,
                        Instagram, and Pinterest, collectively "Social Media
                        Platforms"). You agree that you will not upload or
                        transmit any communications or content of any type to
                        the Public Areas or Social Media Platforms that infringe
                        or violate any rights of any party. By submitting
                        communications or content to the Public Areas or Social
                        Media Platforms,{" "}
                        <strong>
                          you agree that such submission is non-confidential for
                          all purposes. It is important to note that CareOrbit
                          is not responsible for the operation, terms of use or
                          policies of any Social Media Platform. Before using
                          any Social Media Platform you should review its terms
                          of use and policies, including its privacy policy. If
                          you make any such submission you agree that you will
                          not send or transmit to CareOrbit by email, (including
                          through the email addresses listed on the "Support"
                          page) any communication or content that infringes or
                          violates any rights of any party. If you submit any
                          business information, idea, concept or invention to
                          CareOrbit by email, you agree such submission is
                          non-confidential for all purposes. If you make any
                          submission to a Public Area or a Social Media Platform
                          or if you submit any business information, idea,
                          concept or invention to CareOrbit by email
                        </strong>
                        , you automatically grant-or warrant that the owner of
                        such content or intellectual property has expressly
                        granted-CareOrbit a royalty-free, perpetual,
                        irrevocable, world-wide nonexclusive license to use,
                        reproduce, create derivative works from, modify,
                        publish, edit, translate, distribute, perform, and
                        display the communication or content in any media or
                        medium, or any form, format, or forum now known or
                        hereafter developed. CareOrbit may sublicense its rights
                        through multiple tiers of sublicenses.{" "}
                        <strong>
                          If you wish to keep any business information, ideas,
                          concepts or inventions private or proprietary, do not
                          submit them to the Public Areas or Social Media
                          Platforms or to CareOrbit by email. We try to answer
                          every email in a timely manner, but are not always
                          able to do so.
                        </strong>
                        <br />
                        <br />
                        <strong>
                          User Submissions — Image, Video, Audio Files:
                        </strong>{" "}
                        You agree to only post or upload media (like photos,
                        videos or audio) on the CareOrbit site or a Social Media
                        Platform that you have taken yourself or that you have
                        all rights to transmit and license and which do not
                        violate trademark, copyright, privacy or any other
                        rights of any other person. Photos or videos of
                        celebrities and cartoon or comic images are usually
                        copyrighted by the owner.{" "}
                        <strong>
                          To protect your privacy, you agree that you will not
                          submit any media that contains Personally Identifiable
                          Information
                        </strong>{" "}
                        (like name, phone number, email address or website URL)
                        of you or of anyone else. Uploading media like images or
                        video of other people without their permission is
                        strictly prohibited. By uploading any media on the
                        CareOrbit site or a Social Media Platform, you warrant
                        that you have permission from all persons appearing in
                        your media for you to make this contribution and grant
                        rights described herein.{" "}
                        <strong>
                          Never post a picture or video of or with someone else
                          unless you have their explicit permission. It is
                          strictly prohibited to upload media of any kind that
                        </strong>{" "}
                        contain expressions of hate, abuse, offensive images or
                        conduct, obscenity, pornography, sexually explicit or
                        any material that could give rise to any civil or
                        criminal liability under applicable law or regulations
                        or that otherwise may be in conflict with these Terms
                        and Conditions and the CareOrbit Privacy Policy.{" "}
                        <strong>
                          You agree that you will not upload any material that
                          contains software viruses or any other computer code
                        </strong>
                        , files or programs designed to interrupt, destroy or
                        limit the functionality of any computer software or this
                        website.{" "}
                        <strong>
                          By uploading any media on the CareOrbit site or a
                          Social Media Platform like a photo or video, (a) you
                          grant to CareOrbit
                        </strong>{" "}
                        a perpetual, non-exclusive, worldwide, royalty-free
                        license to use, copy, print, display, reproduce, modify,
                        publish, post, transmit and distribute the media and any
                        material included in the media; (b) you certify that any
                        person pictured in the submitted media (or, if a minor,
                        his/her parent/legal guardian) authorizes CareOrbit to
                        use, copy, print, display, reproduce, modify, publish,
                        post, transmit and distribute the media and any material
                        included in such media; and (c) you agree to indemnify
                        CareOrbit and its affiliates, directors, officers and
                        employees and hold them harmless from any and all claims
                        and expenses, including attorneys' fees, arising from
                        the media and/or your failure to comply with these the
                        terms described in this document. CareOrbit reserves the
                        right to review all media prior to submission to the
                        site and to remove any media for any reason, at any
                        time, without prior notice, at our sole discretion.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        Passwords: CareOrbit has several tools that allow you to
                        record and store information. You are responsible for
                        taking all reasonable steps to ensure that no
                        unauthorized person shall have access to your CareOrbit
                        passwords or accounts. It is your sole responsibility to
                        (1) control the dissemination and use of sign-in name,
                        screen name and passwords; (2) authorize, monitor, and
                        control access to and use of your CareOrbit account and
                        password; (3) promptly inform CareOrbit if you believe
                        your account or password has been compromised or if
                        there is any other reason you need to deactivate a
                        password.
                        <br />
                        <br />
                        To send us an email, use the "Support" links located at
                        the bottom of every page of our site. You grant
                        CareOrbit and all other persons or entities involved in
                        the operation of the Site the right to transmit,
                        monitor, retrieve, store, and use your information in
                        connection with the operation of the Site. CareOrbit
                        cannot and does not assume any responsibility or
                        liability for any information you submit, or your or
                        third parties' use or misuse of information transmitted
                        or received using CareOrbit tools and services.
                        CareOrbit Community and Member to Member Areas ("Public
                        Areas") and Social Media Platforms. If you use a Public
                        Area, such as blogs or post on any Social Media
                        Platform, you are solely responsible for your own
                        communications, the consequences of posting those
                        communications, and your reliance on any communications
                        found in the Public Areas or on the Social Media
                        Platforms. CareOrbit and its licensors are not
                        responsible for the consequences of any communications
                        in the Public Areas or on Social Media Platforms. In
                        cases where you feel threatened or believe someone else
                        is in danger, you should contact your local law
                        enforcement agency immediately. If you think you may
                        have a medical emergency, call your doctor or 911
                        immediately. In consideration of being allowed to use
                        the Public Areas and Social Media Platforms, you agree
                        that the following actions shall constitute a material
                        breach of these Terms and Conditions:
                        <ol className="floatLeft clearLeft width100">
                          <li className="floatLeft clearLeft width100">
                            Using a Public Area or Social Media Platform for any
                            purpose in violation of local, state, national, or
                            international laws;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Posting material that infringes on the intellectual
                            property rights of others or on the privacy or
                            publicity rights of others;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Posting material that is unlawful, obscene,
                            defamatory, threatening, harassing, abusive,
                            slanderous, hateful, or embarrassing to any other
                            person or entity as determined by CareOrbit in its
                            sole discretion;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Posting advertisements or solicitations of business;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            After receiving a warning, continuing to disrupt the
                            normal flow of dialogue, or posting comments that
                            are not related to the topic being discussed (unless
                            it is clear the discussion is free-form);
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Posting chain letters or pyramid schemes;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Impersonating another person;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Distributing viruses or other harmful computer code;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Harvesting, scraping or otherwise collecting
                            information about others, including email addresses,
                            without their identification for posting or viewing
                            comments;
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Allowing any other person or entity to use your
                            identification for posting or viewing comments
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Posting the same note more than once or "spamming";
                            or Engaging in any other conduct that restricts or
                            inhibits any other person from using or enjoying the
                            Public Area, Social Media Platform or the Site, or
                            which, in the judgment of CareOrbit, exposes
                            CareOrbit or any of its customers or suppliers to
                            any liability or detriment of any type.
                          </li>
                        </ol>
                        <p className="text-dark w-100">
                          <strong>
                            CareOrbit Reserves the Right (but is Not Obligated)
                            to Do Any or All of the Following:
                          </strong>
                        </p>
                        <ol className="floatLeft clearLeft width100">
                          <li className="floatLeft clearLeft width100">
                            Record the dialogue in public chat rooms.
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Investigate an allegation that a communication(s)
                            do(es) not conform to the terms of this section and
                            determine in its sole discretion to remove or
                            request the removal of the communication(s).
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Remove communications which are abusive, illegal, or
                            disruptive, or that otherwise fail to conform with
                            these Terms and Conditions.
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Terminate a user's access to any or all Public Areas
                            and/or the CareOrbit Site upon any breach of these
                            Terms and Conditions.
                          </li>
                          <li className="floatLeft clearLeft width100">
                            Monitor, edit, or disclose any communication in the
                            Public Areas or on a Social Media Platform. Edit or
                            delete any communication(s) posted on the CareOrbit
                            Site, regardless of whether such communication(s)
                            violate these standards.
                          </li>
                        </ol>
                        <p className="text-dark w-100">
                          <strong>
                            CareOrbit or its licensors have no liability or
                            responsibility to users of the CareOrbit Site or any
                            other person or entity for performance or
                            nonperformance of the aforementioned activities.
                          </strong>
                        </p>
                      </li>
                    </ul>
                    <h2 className="text-dark w-100">
                      Advertisements, Searches, and Links to Other Sites
                    </h2>
                    <p className="text-dark w-100">
                      CareOrbit may provide links to third-party websites.
                      CareOrbit also may select certain sites as priority
                      responses to search terms you enter and CareOrbit may
                      agree to allow advertisers to respond to certain search
                      terms with advertisements or sponsored content. CareOrbit
                      does not recommend and does not endorse the content on any
                      third-party websites. CareOrbit is not responsible for the
                      content of linked third-party sites, sites framed within
                      the CareOrbit Site, third-party sites provided as search
                      results, or third-party advertisements, and does not make
                      any representations regarding their content or accuracy.
                      Your use of third-party websites is at your own risk and
                      subject to the terms and conditions of use for such sites.
                      CareOrbit does not endorse any product, service, or
                      treatment advertised on the CareOrbit Site.
                    </p>

                    <h2 className="text-dark w-100">Content Ownership</h2>
                    <p className="text-dark w-100">
                      The content contained in and/or accessible via the
                      Software, Services and the Site, including without
                      limitation, the graphics, design, elements artwork, logos,
                      icons, images, sounds, videos, fonts, interactive features
                      as well as the selection, assembly and arrangement thereof
                      (including, without limitation, the “Look and Feel” of the
                      Software or the Site and any part thereof), and any and
                      all Intellectual Property Rights (defined below)
                      pertaining thereto (collectively, the “Content”) is the
                      property of CareOrbit and/or its licensors and is
                      protected by applicable copyright or other intellectual
                      property laws and treaties. “Intellectual Property Rights”
                      means any and all rights in and to copyrights, trade
                      secrets, service marks, trademarks, know-how, patents, or
                      similar intellectual property rights, as well as any and
                      all moral rights of any type under the laws or regulations
                      of any governmental, regulatory, or judicial authority,
                      whether foreign or domestic.
                    </p>
                    <p className="text-dark w-100">
                      <strong>Restrictions</strong>: Except as explicitly
                      permitted under these Terms the Content may not be used,
                      copied, broadcasted, transmitted, distributed, licensed,
                      displayed, sold, licensed, made available in any manner,
                      de-compiled, or otherwise exploited for any purpose
                      whatsoever.
                    </p>

                    <h2 className="text-dark w-100">Support</h2>
                    <p className="text-dark w-100">
                      Support is provided for CareOrbit under your Subscription
                      Plan through CareOrbit’s primary{" "}
                      <a
                        href="https://CareOrbit.com/login/support.html"
                        target="blank"
                      >
                        Support Page
                      </a>{" "}
                      on a reasonable commercial effort basis. CareOrbit shall
                      use reasonable commercial efforts to respond to any
                      support requests in a timely manner.
                    </p>

                    <h2 className="text-dark w-100">No Liability</h2>
                    <h3 className="text-dark w-100">
                      The use of the CareOrbit Site and the Content is at your
                      own risk.
                    </h3>
                    <p className="text-dark w-100">
                      When using the CareOrbit Site, information will be
                      transmitted over a medium that may be beyond the control
                      and jurisdiction of CareOrbit and its suppliers.
                      Accordingly, CareOrbit assumes no liability for or
                      relating to the delay, failure, interruption, or
                      corruption of any data or other information transmitted in
                      connection with the use of the CareOrbit Site.
                    </p>
                    <p className="text-dark w-100">
                      The CareOrbit Site and the content are provided on an "as
                      is" basis. CareOrbit, ITS LICENSORS, AND ITS SUPPLIERS, TO
                      THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL
                      WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR
                      OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED
                      WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD
                      PARTIES' RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE.
                      Without limiting the foregoing, CareOrbit, its licensors,
                      and its suppliers make no representations or warranties
                      about the following:
                    </p>
                    <ul className="text-dark floatLeft clearLeft width100">
                      <li className="floatLeft clearLeft width100">
                        The accuracy, reliability, completeness, currentness, or
                        timeliness of the Content, software, text, graphics,
                        links, or communications provided on or through the use
                        of the CareOrbit Site or CareOrbit.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        The satisfaction of any government regulations requiring
                        disclosure of information on prescription drug products
                        or the approval or compliance of any software tools with
                        regard to the Content contained on the CareOrbit Site.
                      </li>
                    </ul>
                    <p className="text-dark w-100">
                      In no event shall CareOrbit, its licensors, its suppliers,
                      or any third parties mentioned on the CareOrbit Site be
                      liable for any damages (including, without limitation,
                      incidental and consequential damages, personal
                      injury/wrongful death, lost profits, or damages resulting
                      from lost data or business interruption) resulting from
                      the use of or inability to use the CareOrbit Site or the
                      Content, whether based on warranty, contract, tort, or any
                      other legal theory, and whether or not CareOrbit, its
                      licensors, its suppliers, or any third parties mentioned
                      on the CareOrbit Site are advised of the possibility of
                      such damages. To the extent permissible under the law
                      applicable to you and/or your use of the Service, Site,
                      Software or Content, and without derogating from the
                      aforementioned, no action may be brought by you against
                      CareOrbit or any employee, officer, affiliate or its end
                      users for any cause whatsoever including the breach of
                      these terms more than one (1) year after the occurrence of
                      such cause of action.
                    </p>
                    <p className="text-dark w-100">
                      The foregoing limitations, exclusions and disclaimers
                      shall apply to all claims for damages, whether based in an
                      action of contract, warranty, strict liability,
                      negligence, tort, or otherwise. You hereby acknowledge and
                      agree that these limitations of liability are agreed
                      allocations of risk constituting in part the consideration
                      for CareOrbit’s Site, Content or Services provided to you,
                      and such limitations will apply notwithstanding the
                      failure of essential purpose of any limited remedy, and
                      even if CareOrbit and/or any CareOrbit affiliates have
                      been advised of the possibility of such liabilities and/or
                      damages. Notwithstanding anything to the contrary (and
                      without derogating from the foregoing limitations) in no
                      event shall CareOrbit’s cumulative liability to you exceed
                      amounts paid by you to CareOrbit for a Subscription Plan
                      over the 12 months immediately preceding the occurrence of
                      the cause of action. CareOrbit, its licensors, its
                      suppliers, or any third parties mentioned on the CareOrbit
                      Site are not liable for any personal injury, including
                      death, caused by your use or misuse of the Site, Content,
                      or Public Areas (as defined below). Any claims arising in
                      connection with your use of the Site, any Content, or the
                      Public Areas must be brought within one (1) year of the
                      date of the event giving rise to such action occurring.
                      Remedies under these Terms and Conditions are exclusive
                      and are limited to those expressly provided for in these
                      Terms and Conditions.
                    </p>

                    <h2 className="text-dark w-100">Amending These Terms</h2>
                    <p className="text-dark w-100">
                      CareOrbit may amend or change these Terms from time to
                      time with or without notification and such changes will
                      become effective immediately. If you decide that any
                      change is not acceptable to you, you may terminate these
                      Terms. You are required to inspect, from time to time,
                      these Terms.
                    </p>

                    <h2 className="text-dark w-100">Termination</h2>
                    <p className="text-dark w-100">
                      CareOrbit may, according to its sole discretion, terminate
                      these Terms, the service or any service provided by it for
                      any reason, for all the users or just to you, for any
                      cause and for no cause, including, without limitation, due
                      to lack of use or if CareOrbit believes that you breached
                      these Terms or acted in a manner which may harm CareOrbit
                      or the Services. CareOrbit may, moreover and according to
                      its sole discretion, refrain from providing the Services
                      or any part thereof, with or without any prior notice.
                    </p>

                    <p className="text-dark w-100">
                      You hereby warrant that any termination of your access to
                      the service or services may apply without prior notice,
                      and acknowledge that CareOrbit may terminate the services
                      immediately or remove your Content. Moreover, CareOrbit
                      shall not be liable for any damage caused to you or any
                      third-party as a result of terminating these Terms or by
                      suspending, or otherwise limiting your use of the
                      Services.
                    </p>

                    <h2 className="text-dark w-100">Indemnification</h2>
                    <p className="text-dark w-100">
                      You hereby warrant and agree to indemnify, defend and hold
                      CareOrbit, and its affiliates, and respective officers,
                      directors, employees and agents, harmless for any claims,
                      damages, obligations, losses, liabilities, costs and
                      expenses (including but not limited to attorney’s fees)
                      arising due to your breach of representations and/or
                      obligations under these Terms or related to your use, or
                      inability to use, of the Service, Content, Software or the
                      Site. This indemnification shall be made promptly,
                      according to CareOrbit’s request.
                    </p>

                    <p className="text-dark w-100">
                      You hereby warrant that any termination of your access to
                      the service or services may apply without prior notice,
                      and acknowledge that CareOrbit may terminate the services
                      immediately or remove your Content. Moreover, CareOrbit
                      shall not be liable for any damage caused to you or any
                      third-party as a result of terminating these Terms or by
                      suspending, or otherwise limiting your use of the
                      Services.
                    </p>

                    <h2 className="text-dark w-100">Assignment</h2>
                    <p className="text-dark w-100">
                      CareOrbit may assign any and all rights or obligations
                      under these Terms and/or under any Subscription Plan to
                      any third party (including without limitation to an
                      affiliate and/or upon a merger, acquisition, sale of all
                      or substantially all of its shares or assets or such part
                      of its assets underlying these Terms) with or without
                      notice. These terms will insure to the benefit of any
                      CareOrbit successor.
                    </p>

                    <h2 className="text-dark w-100">No Class Action</h2>
                    <p className="text-dark w-100">
                      You undertake not to engage in any class action against
                      CareOrbit and solely to claim your losses personally
                    </p>

                    <h2 className="text-dark w-100">General</h2>
                    <p className="text-dark w-100">
                      CareOrbit is based in St. Louis, Missouri in the United
                      States of America. CareOrbit makes no claims that
                      CareOrbit, or any Site within the CareOrbit Network and
                      the Content are appropriate or may be downloaded outside
                      of the United States. Access to the Content may not be
                      legal by certain persons or in certain countries. If you
                      access the CareOrbit Network from outside the United
                      States, you do so at your own risk and are responsible for
                      compliance with the laws of your jurisdiction.
                    </p>

                    <p className="text-dark w-100">
                      The following provisions survive the expiration or
                      termination of this Agreement for any reason whatsoever:
                      Liability, User Submissions, User Submissions – image,
                      video, audio files, Indemnity, Jurisdiction, and Complete
                      Agreement.
                    </p>

                    <h2 className="text-dark w-100">Jurisdiction</h2>
                    <p className="text-dark w-100">
                      You expressly agree that exclusive jurisdiction for any
                      dispute with CareOrbit, or in any way relating to your use
                      of the CareOrbit Site, resides in the courts of the State
                      of Missouri and you further agree and expressly consent to
                      the exercise of personal jurisdiction in the courts of the
                      State of Missouri in connection with any such dispute
                      including any claim involving CareOrbit or its affiliates,
                      subsidiaries, employees, contractors, officers, directors,
                      telecommunication providers, and content providers.
                    </p>

                    <p className="text-dark w-100">
                      These Terms and Conditions are governed by the internal
                      substantive laws of the State of MIssouri, without respect
                      to its conflict of laws principles. If any provision of
                      these Terms and Conditions is found to be invalid by any
                      court having competent jurisdiction, the invalidity of
                      such provision shall not affect the validity of the
                      remaining provisions of these Terms and Conditions, which
                      shall remain in full force and effect. No waiver of any of
                      these Terms and Conditions shall be deemed a further or
                      continuing waiver of such term or condition or any other
                      term or condition.
                    </p>

                    <h2 className="text-dark w-100">
                      Notice and Takedown Procedures; and Copyright Agent
                    </h2>
                    <p className="text-dark w-100">
                      If you believe any materials accessible on or from the
                      Site infringe your copyright, you may request removal of
                      those materials (or access thereto) from this website by
                      contacting CareOrbit's copyright agent (identified below)
                      and providing the following information:
                    </p>

                    <ol className="floatLeft clearLeft width100">
                      <li className="floatLeft clearLeft width100">
                        Identification of the copyrighted work that you believe
                        to be infringed. Please describe the work, and where
                        possible include a copy or the location (e.g., URL) of
                        an authorized version of the work.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        Identification of the material that you believe to be
                        infringing and its location. Please describe the
                        material, and provide us with its URL or any other
                        pertinent information that will allow us to locate the
                        material.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        Your name, address, telephone number and (if available)
                        e-mail address. A statement that you have a good faith
                        belief that the complaint of use of the materials is not
                        authorized by the copyright owner, its agent, or the
                        law.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        A statement that the information that you have supplied
                        is accurate, and indicating that "under penalty of
                        perjury," you are the copyright owner or are authorized
                        to act on the copyright owner's behalf.
                      </li>
                      <li className="floatLeft clearLeft width100">
                        A signature or the electronic equivalent from the
                        copyright holder or authorized representative.
                        CareOrbit's agent for copyright issues relating to this
                        website is as follows:
                      </li>
                    </ol>

                    <p className="text-dark w-100">CareOrbit, LLC</p>
                    <p className="text-dark w-100">Attn: Office of Privacy</p>
                    <p className="text-dark w-100">4240 Duncan Ave, Ste 200</p>
                    <p className="text-dark w-100">St. Louis, Missouri 63110</p>
                    <p className="text-dark w-100">
                      <a href="mailto:copyright@CareOrbit.com">
                        copyright@CareOrbit.com
                      </a>
                    </p>
                    <p className="text-dark w-100">
                      In an effort to protect the rights of copyright owners,
                      CareOrbit maintains a policy for the termination, in
                      appropriate circumstances, of subscribers and account
                      holders of the Site who are repeat infringers.
                    </p>
                    <h2 className="text-dark w-100">Complete Agreement</h2>
                    <p className="text-dark w-100">
                      Except as expressly provided in a particular "legal
                      notice" on the CareOrbit Site, these{" "}
                      <Link target="_blank" to="../terms-of-use">
                        Terms of Service
                      </Link>{" "}
                      the{" "}
                      <Link to="../privacy-policy">
                        CareOrbit Privacy Policy
                      </Link>{" "}
                      and <Link to="../cookie-policy">Cookie Policy</Link>{" "}
                      constitute the entire agreement between you and CareOrbit
                      with respect to the use of the CareOrbit Site, and
                      Content.
                    </p>
                    <p className="text-dark w-100">
                      <strong>
                        Thank you for your cooperation. We hope you find the
                        CareOrbit.com Site helpful and convenient to use!
                        Questions or comments regarding this website, including
                        any reports of non-functioning links, should be
                        submitted using our Contact Us Form or via U.S. mail to:
                        CareOrbit.com Office of Privacy, 4240 Duncan Ave, Ste
                        200, Saint Louis, MO 63110.
                      </strong>
                    </p>
                    <p className="text-dark w-100">
                      Last Updated on 1 October 2024
                    </p>
                    <p className="text-dark w-100">
                      &copy;2019 CareOrbit.com All rights reserved.
                    </p>
                  </div>

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p className="text-dark w-100">
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default TermsOfUse;
