import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonPulseWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonPulse">Taking a Pulse</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/TP_diagram.png" className="img-responsive maxWidth300 floatNone" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Locating a Pulse</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The pulse that you will learn to take is the radial pulse. To find a radial pulse:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Have the person lie down or sit, relaxing their arm at their side, or comfortably across their lap.</li>
                                            <li>Place the tips of your first two fingers over what is called the radial groove. The groove is along the thumb side of the wrist between the hard tendon and long bone of the lower arm. There, you will feel a soft spot, where the radial artery is located.</li>
                                            <li>You will feel the pulse wave or throbbing sensation against your fingertips. Do not use your thumb to check the pulse, because you can feel your own pulse in your thumb.</li>
                                            <li>It helps to extend the person’s wrist just a bit with their palm side down to make it easier to feel the pulse.</li>
                                            <li>The normal pulse is about 70 to 80 beats per minute at rest; however, the heart rate can range from 60 to 100 beats a minute.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">When to Take a Pulse</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Take a pulse in the following situations:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>When you are starting a new exercise or fitness program.</li>
                                            <li>When you are caring for someone who is having physical distress, such as dizziness, shortness of breath, or feeling “racing or funny heartbeats”. When a person feels like their heart is beating too fast and he or she can feel it beating differently than normal. This is called having heart palpitations.</li>
                                            <li>When you are caring for someone who has a history of blocked arteries, veins, or has blood clots.</li>
                                            <li>To find out how a person is reacting to medicines that can affect the heart.</li>
                                            <li>To decide if there is a change in a person that requires calling a doctor.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When you take a pulse – you should be able to easily feel the pulse wave push against your fingers in an even, regular pattern, without any missed beats. It feels like a tap against your fingertips.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">Devices for Maintaining a Heart Rate</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Some people with heart disease have surgery to place special devices under the skin in the chest. These devices keep the heart rate and rhythm regular and within the normal range of beats per minute. These devices are called a pacemaker and an implantable cardioverter-defibrillator (ICD).</p>
                                        <p>A pacemaker’s wires pass through a large vein into a chamber of the heart. The pacemaker sends out mild electrical pulses that keep the heart from beating too slow. A doctor will prescribe the minimum rate that the pacemaker is set.</p>
                                        <p>An implantable cardioverter-defibrillator (ICD) is placed under a person’s skin, usually below the left collarbone. A wire threaded through a large vein connects the device to the heart. An ICD is always checking the person’s heart rate. If the heart starts beating dangerously fast, the ICD sends a strong electrical shock to the heart to return it to a normal rhythm. If the heart is beating too slow, the ICD acts as a pacemaker, sending mild electrical pulses to make the heart beat at a faster rate. The doctor will give guidelines for having each of these devices checked on a regular basis.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">What Affects a Person’s Heart Rate?</h3>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Factors that normally raise <i className="fa-solid fa-arrow-up colorCareOrbitBlue"></i> a heart rate:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Exercise</li>
                                                <li>Stress</li>
                                                <li>Fever</li>
                                                <li>Extreme heat</li>
                                                <li>Standing or sitting down</li>
                                                <li>Anxiety</li>
                                                <li>Medicines such as “cold medicine”, decongestants, diet pills, amphetamines, asthma medicines</li>
                                                <li>Cigarettes</li>
                                                <li>Caffeine</li>
                                                <li>Alcohol</li>
                                                <li>Anemia – not enough blood cells to carry oxygen to all parts of your body</li>
                                                <li>Overactive thyroid gland</li>
                                                <li>Heart disease – With some conditions, the heart has to work harder, so it beats faster</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Factors that normally lower <i className="fa-solid fa-arrow-down colorCareOrbitBlue"></i> a heart rate:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Lying down</li>
                                                <li>Exposure to the cold</li>
                                                <li>Some heart and blood pressure medicines</li>
                                                <li>Heart disease – problems with the heart’s pumping ability</li>
                                                <li>Underactive thyroid gland</li>
                                                <li>Regular exercise that increases the heart rate (such as running or walking) conditions the heart muscle to pump a larger amount of blood to the body, requiring fewer beats per minute</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonPulseWhatYouNeedToKnow;