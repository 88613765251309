import React from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTTGSP2_5 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Your Hospital Stay: What to expect as an In-patient'}, () => {
            console.log('Your Hospital Stay: What to expect as an In-patient');
        });
        await dispatch(pal);
    }


return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Your Hospital Stay</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Your Surgical Care Team</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Your primary surgical team is a group of physicians (medical doctors) and physician extenders that will manage care while you are in the hospital. Your care team members are listed below. At night, a team of physicians are on-call and available for urgent issues.</p>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Attending Surgeon</p>
                                                        <p>A physician who has completed training. An attending
                                                            physician supervises resident physicians and fellow
                                                            physicians. They are ultimately responsible for your
                                                            care.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Fellows</p>
                                                        <p>A physician who has completed medical school and their
                                                            residency. A fellowship is a year of additional training in
                                                            the area of head and neck cancer surgery and free flap
                                                            surgery.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Residents</p>
                                                        <p>A resident is a physician who has completed their medical
                                                            degree and is now training in the field of Otolaryngology
                                                            (ENT). Residents work closely with your attending surgeon in
                                                            the operating room as well as on the hospital floor. They
                                                            will round on you daily and provide post-operative care.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Physician Extender</p>
                                                        <p>Physician extenders (also known as advanced practice
                                                            providers) are physician assistants or nurse practitioners.
                                                            They work closely with your attending physician and resident
                                                            physicians to manage your care while in the hospital. Your
                                                            physician extender will see you daily and be your main
                                                            contact during your hospital stay.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Nurse</p>
                                                        <p>You will have nurses who are specialized and have experience
                                                            in caring for patients who have had head and neck surgeries
                                                            similar to yours. They will provide care for you during your
                                                            hospitalization as well as provide education to you and your
                                                            caregivers during your stay.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Speech-Language Pathologist (SLP)</p>
                                                        <p>Speech-language pathologists are professionals trained to
                                                            identify, assess, and rehabilitate your speech and
                                                            swallowing ability. They perform bedside evaluation and
                                                            education as well as radiologic swallow studies. </p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Physical Therapist (PT)</p>
                                                        <p>Physical therapists are health care professionals who provide
                                                            services to help you regain or improve your physical
                                                            abilities through exercises, hands-on care, and education.
                                                            It is very important for you to begin moving and walking as
                                                            soon as possible after surgery.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Occupational Therapist (OT)</p>
                                                        <p>Occupational therapists are health care professionals who
                                                            provide services designed to restore your self-care, work,
                                                            and leisure skills.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Dietician</p>
                                                        <p>Dieticians are experts making sure you have adequate
                                                            nutrition during your hospital stay and when you go home.
                                                            Dieticians manage all tube feeding regimens for
                                                            patients.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Case Manager/Social Worker</p>
                                                        <p>These are specially trained professionals who work with you
                                                            and your caregivers to ensure that you have the appropriate
                                                            support following your discharge from the hospital.
                                                            They will assist you in facility placement, transportation needs,
                                                            and arranging home health support and supplies. </p>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">What to expect during your stay</h2>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="pNjD3WTIbmQ" opts={opts} onPlay={playVideo} />
                                        </div>
                                    </div>
                                    <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">What to expect as an In-patient</span></h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will see your team of doctors every morning and evening.</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkboxBullets colorAccentRedBullets">
                                            <li>If you have questions throughout the day, write them down so you remember to ask your team when they come to visit you.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You will begin your stay in the Progressive Care Unit (PCU) on 16300 or an Intensive Care Unit (ICU) where you will be watched closely by nurses who are specially trained to take care of patients who have had your type of surgery.</p>
                                        <p>After a few days you will move to our ENT floor (17300) where nurses are also specially trained to take care of patients who have had your type of surgery. </p>
                                        <p>If everything goes as expected, you will be in the hospital about 5 to 7 days.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder entDiagnosisSurgeryRedBorderColor">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2>Medications</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkboxBullets colorAccentRedBullets">
                                            <li>Bring a list of your current home medications with you to the hospital (do not bring the actual medications; you will not need them).</li>
                                            <li>Your nurse will give you your medications.</li>
                                            <li>Pain medications will be available to you as needed.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Pain</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>We will work with you to best manage your pain. We will ask you to rate the pain you feel after surgery on a scale of 1 to 10. It is important to let us know when you first start feeling uncomfortable. Telling us right away will help keep the uncomfortable feeling from getting worse. For more details <em><strong>see</strong></em> <a href="/siteman-ent-all-4-4">Managing Pain.</a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentRed ">
                                        <h2 className="subheadUpperAndLower">Key Changes You’ll Notice After Surgery.</h2>
                                    </div>
                                    <div className="withBorder entDiagnosisSurgeryRedBorderColor">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkboxBullets colorAccentRedBullets">
                                                    <li>You will have multiple surgical drains placed to remove fluid and blood from your surgical wounds. These will be removed when the amount of fluid coming out decreases to a level that is safe. </li>
                                                    <li>At first, the free flap will appear swollen and bulky. This usually improves in the first one to two weeks after surgery.</li>
                                                    <li>If you have had surgery on your mouth or throat and had a tracheostomy, at first you will only be able to breath through the tracheostomy tube in your neck. </li>
                                                    <li>If a tracheostomy is performed, you will not be able to speak for the first few days. You will be able to communicate through writing. Around 3 days after surgery, your tracheostomy tube will be replaced with a smaller tube that will make it easier to speak.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">‘Flap Checks’</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will have nurses and doctors checking on your free flap frequently while you are in the hospital, especially the first 3 days after surgery. Flap checks involve looking at the flap, feeling it to make sure it is warm and soft, and checking its pulse.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Nutrition</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p><strong><em>If you are NOT having surgery on your mouth or throat,</em></strong> you should be able to return to your normal diet after surgery. Some patients have a sore throat after surgery from the breathing tube. This pain usually goes away in a couple of days. </p>
                                    <p><strong><em>If you have had surgery on your mouth or throat, you will be unable to eat by mouth</em></strong> right after surgery and must get your nutrition through a temporary feeding tube.</p>
                                    <p className="bottomSpacer">While you are asleep during surgery, your surgeon will place a feeding tube, also called a nasogastric tube, that goes through your nose and down into your stomach. This tube allows your mouth or throat to heal properly after surgery. Most people are able to swallow within a 1 to 2 weeks of surgery.</p>
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nasogastric_tube_png.png" className="img-responsive maxWidth400 centerImage" />
                                    <div className="imgCaption">
                                        <p className="captionItalic centered"><strong>Nasogastric tube (NG tube)</strong></p>
                                    </div>
                                    <p className="bottomSpacer"><strong><em>If you require nutrition through a feeding tube for a longer period,</em></strong> you may undergo a procedure to place the tube directly into the stomach through the skin (called a “G-tube” or “gastrostomy tube”).</p>
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/G_tube_percutaneous_diagram.png" className="img-responsive maxWidth500 centerImage" />
                                    <p>Your doctor will discuss how long you will have your feeding tube based on your specific case.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Preventing blood clots</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Blood clots are a possible complication after surgery. Ways we decrease those chances include:</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentRedBullets">
                                            <li>Heparin or Lovenox administered by your nurse</li>
                                            <li>Walking early in your hospital stay</li>
                                            <li>A Sequential Compression Device (SCD) (Special leg wraps that will massage your calves while you’re in bed). </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP2_5;
