import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTL4_1_6 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Devices &amp; Supplies</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <div className="question">
                                                <p>To help your successful recovery, you will be sent home with a
                                                    variety of equipment and supplies for the first couple of days. Make
                                                    sure you arrange to replenish these supplies as soon as you get
                                                    home. We can help you identify where you can obtain the equipment
                                                    you need. </p>
                                                <p>There are also a variety of devices available that help aid
                                                    communication once you are able to begin those efforts. Your speech
                                                    therapist can help you review the options that are of best use for
                                                    you.</p>
                                                {/*<p>Find out <a href="/siteman-ent-all-7-6">more here</a>.</p>*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Upon Discharge</p>
                                            <p>You will be sent home with some supplies for the first couple of days
                                                once you get home. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn"><em>These include:</em></p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom ">
                                                    <li>Suction Machine <br/>
                                                        <div className="imageLeftCopyRight">
                                                            <div className="imgCol maxWidth300">
                                                                <img
                                                                    className="floatLeft wrapImage w-100 clearLeft"
                                                                    src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Suction_machine.png"
                                                                    alt=""/>
                                                            </div>
                                                            <div className="typeCol smallerImage">
                                                                <p className="floatNone clearNone italic">A
                                                                    machine that uses suction (like a vacuum) to remove
                                                                    mucus. A suction machine will be delivered to the
                                                                    hospital or directly to your home before you
                                                                    discharge. </p>
                                                                {/*<p className="floatNone clearNone italic">If*/}
                                                                {/*    the concern remains unresolved the office of Patient*/}
                                                                {/*    & Family Affairs staff is available Monday-Friday, 8*/}
                                                                {/*    am-5 pm to help you.</p>*/}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>Humidified Oxygen<br/><em>This is extremely important to use
                                                        after your surgery to keep mucus plugs from forming that would
                                                        block your breathing.</em></li>
                                                    <li>Laryngectomy tube</li>
                                                    <li>Spare Laryngectomy tube</li>
                                                    <li>Soft Velcro collar</li>
                                                    <li>Humidification valves</li>
                                                    <li>2 Yankauer suction devices (used to remove mucus from the
                                                        mouth)
                                                    </li>
                                                    <li>9 suction catheters (used to remove mucus from the trachea)</li>
                                                    <li>1 emesis basin (to soak metal lary tube)</li>
                                                    <li>Box of gloves</li>
                                                    <li>9 saline bullets (to install in trach for mucus plugs)</li>
                                                    <li>1 shower guard</li>
                                                    <li>1 bottle of wound cleaner</li>
                                                    <li>10 drain sponges</li>
                                                    <li>30 Heat Moisture Exchangers (HMEs)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">To supplement these provided supplies, you should
                                                also have handy:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom ">
                                                    <li>Pipe cleaners</li>
                                                    <li>Brushes/pipe cleaners</li>
                                                    <li>Small basin or clean bowl</li>
                                                    <li>Clean wash cloth</li>
                                                    <li>Mild dishwashing liquid soap and tap water</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Supplies if you have a feeding tube</h3>
                                            <p>To help you with your tube feeding, you will be sent home with a variety of supplies for
                                                the first couple of days. Make sure you work with your home health company to replenish
                                                these supplies. Your home health company and case manager can help you identify where
                                                you can obtain the supplies you need.</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom ">
                                                    <li>Tube feeding bags</li>
                                                    <li>Tube feeding pump (if applicable)</li>
                                                    <li>Tube feeding syringes</li>
                                                    <li>Medication straw</li>
                                                    <li>G-tube dressings (if applicable)</li>
                                                    <li>Tube feeding formula</li>
                                                    <li>Toothbrush</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <p>Learn more about sources of supplies <a href="/siteman-ent-all-7-6">here</a>.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                            {/*<hr className="w-100 blueColorBorder"/>*/}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL4_1_6;
