import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    validateOrganizationDepartment,
    validateEmailAddress,
    validateFirstName,
    validateLastName,
    validateOrganization,
    validateRole
} from "./validation.js";
import Select from "@material-ui/core/Select";
import {InputWithErrorMessage} from "../forms/inputWithErrorMessage.js";
import styles from "./userinvite.module.scss";
import {getOrbitList, getUser} from "../../helpers/index.js";
import {orgList} from "../../actions/orgList.js";
import {DEPARTMENT_ADMIN, ORBIT_ADMIN, ORGANIZATION_ADMIN, REGULAR_USER, SYS_ADMIN} from "../../constants/roles.js";
import {diagnosisUserList} from "../../actions/diagnosisUserList.js";
import {orgDepList} from "../../actions/orgDepList.js";
import {rolesList} from "../../actions/rolesList.js";
import {relativeList} from "../../actions/relativeList.js";
import {inviteUser} from "../../actions/inviteUser.js";
import {validateRelation} from "../patientinvite/validation.js";
import {invitePatient} from "../../actions/invitePatient.js";
import {inviteFamily} from "../../actions/inviteFamily.js";
import { ToastContainer, toast } from 'react-toastify';
import {AUTH_ERROR, REGISTER_ERROR} from "../../actions/types.js";

const PatientInvite = () => {

    const user = JSON.parse(getUser());
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [invitedRole, setInvitedRole] = useState("");
    const [roleList, setRoleList] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const [roleTempList, setRoleTempList] = useState([]);
    const [organizationId, setOrganizationId] = useState("");
    const [organizationList, setOrganizationList] = useState([]);
    const [organizationDepartmentId, setOrganizationDepartmentId] = useState("");
    const [organizationDepartmentList, setOrganizationDepartmentList] = useState([]);
    const [organizationDepartmentListModified, setOrganizationDepartmentListModified] = useState(false);
    const [orbitIdList, setOrbitIdList] = useState([user.defaultOrbit]);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");
    const [organizationDepartmentError, setOrganizationDepartmentError] = useState("");
    const [roleError, setRoleError] = useState("");
    const [organizationIdError, setOrganizationIdError] = useState("");
    const [relationError, setRelationError] = useState("");
    const [isDirty, setIsDirty] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [relation, setRelation] = useState("");
    const {errorMessage} = useSelector(state => state.register);
    const [userMessage, setUserMessage] = useState('');
    const [firstNameLabel, setFirstNameLabel] = useState("");
    const [lastNameLabel, setLastNameLabel] = useState("");
    const [firstNameHelperText, setFirstNameHelperText] = useState("");
    const [lastNameHelperText, setLastNameHelperText] = useState("");
    const [emailAddressHelperText, setEmailAddressHelperText] = useState("");
    const [titleText, setTitleText] = useState("Family Member");
    const [subTitleText, setSubTitleText] = useState("family member");
    const {defaultOrbit} = useSelector(state => {
        return state.home
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (errorMessage || errorMessage.length > 0) {
            toast.error(errorMessage)
        }

    }, [errorMessage]);

    useEffect(() => {
        if (userMessage || userMessage.length > 0) {
            toast.success(userMessage);
        }
    }, [userMessage]);

    const submitForm = async (e) => {
        let ip = null;
        dispatch({
            type: REGISTER_ERROR,
            payload: ''
        });
        e.preventDefault();


        if (!await validateAllFields())
            return;

        if (user.roles.indexOf(REGULAR_USER) === -1) {
            ip = await inviteUser({
                defaultOrbit: orbitIdList,
                invitedRole,
                organizationDepartmentId,
                organizationId,
                firstName,
                lastName,
                emailAddress
            }, (data) => {
                if (data?.errors)
                    return;
                setUserMessage('Invitation Sent!');
                navigate('/');
                // setTimeout(() => {
                //     navigate('/');
                // }, 3000);
            });
        } else {
            ip = await inviteFamily({
                defaultOrbit: orbitIdList,
                relation,
                organizationDepartmentId,
                organizationId,
                firstName,
                lastName,
                emailAddress
            }, (data) => {
                if (data?.errors)
                    return;
                setUserMessage('Invitation Sent!');
                navigate('/');
                // setTimeout(() => {
                //     navigate('/');
                // }, 3000);
            });
        }
        await dispatch(ip);
    }

    const swapList = async (list1, list2) => {
        const index1 = list1.findIndex((item2) => item2.roleId === DEPARTMENT_ADMIN);
        const index2 = list2.findIndex((item2) => item2.roleId === DEPARTMENT_ADMIN);

        /*
        if (!isInitialLoad) {
            if (window.confirm('You are about to leave the page.  Do you wish to continue?')) {
                navigate('/');
            } else {
                return;
            }
        } else {
            setIsInitialLoad(false);
        }
         */
        /*
        if (!await validateAllFields())
            return;

        const ip = await inviteUser({defaultOrbit: orbitIdList, invitedRole, organizationDepartmentId, organizationId, firstName, lastName, emailAddress}, (data) => {
            if (data?.error)
                return;

            navigate('/');
        })
        await dispatch(ip);
        */
    }

    useEffect(async () => {
        dispatch({
            type: REGISTER_ERROR,
            payload: ''
        });

        if (!orbitIdList || orbitIdList.length === 0 || (!Array.isArray(orbitIdList) && orbitIdList === '00000000-0000-0000-0000-000000000000') || (Array.isArray(orbitIdList) && orbitIdList[0] === '00000000-0000-0000-0000-000000000000') || (orbitIdList.length > 0 && (orbitIdList[0]?.length === 0 || orbitIdList[0] === null))) {
            setOrbitIdList([defaultOrbit && defaultOrbit.length > 0 ? defaultOrbit : user?.defaultOrbit]);
        }

        if (!(user.roles && user.roles.indexOf(REGULAR_USER) !== -1)) {
            let organizationIdToUse = (organizationId && organizationId.length > 0 ? organizationId : user.organizationId);

            const ro = await rolesList({organizationId: organizationIdToUse}, async (data) => {
                setRoleList(data?.map((item) => ({roleId: item.roleId, name: item.name.replace('_', ' ')})));
                await swapList(data?.map((item) => ({
                    roleId: item.roleId,
                    name: item.name.replace('_', ' ')
                })), roleTempList);
            });
            await dispatch(ro);
        }

        if (user.roles.indexOf(SYS_ADMIN) !== -1) {
            const ol = await orgList({}, (data) => {
                setOrganizationList(data);
            });
            await dispatch(ol);
        }

        if (user.roles.indexOf(SYS_ADMIN) !== -1 ||
            user.roles.indexOf(ORGANIZATION_ADMIN) !== -1 ||
            user.roles.indexOf(DEPARTMENT_ADMIN) !== -1 ||
            user.roles.indexOf(ORBIT_ADMIN) !== -1) {

            setFirstNameLabel("Admin First Name");
            setLastNameLabel("Admin Last Name");
            setFirstNameHelperText("");
            setLastNameHelperText("");
            setEmailAddressHelperText("");
            setTitleText("Admin")
            setSubTitleText("admin");

            /*
            let organizationIdToUse = (organizationId && organizationId.length > 0 ? organizationId : undefined);
            const ro = await rolesList({ organizationId: organizationIdToUse }, async (data) => {
                setRoleList(data?.map((item) => ({roleId: item.roleId, name: item.name.replace('_', ' ')})));
                await swapList(data?.map((item) => ({roleId: item.roleId, name: item.name.replace('_', ' ')})), roleTempList);
            });

            await dispatch(ro);
             */
        } else {
            setFirstNameLabel("First Name");
            setLastNameLabel("Last Name");
            setFirstNameHelperText("");
            setLastNameHelperText("");
            setEmailAddressHelperText("");
            setTitleText("Family Member")
            setSubTitleText("family member");

            const rl = await relativeList({}, (data) => {
                setRelationList(data);
            })

            await dispatch(rl);
        }
    }, []);

    useEffect(async () => {
        if (!(user.roles && user.roles.indexOf(REGULAR_USER) !== -1)) {
            let organizationIdToUse = (organizationId && organizationId.length > 0 ? organizationId : user.organizationId);
            const ro = await rolesList({organizationId: organizationIdToUse}, async (data) => {
                setRoleList(data?.map((item) => ({roleId: item.roleId, name: item.name.replace('_', ' ')})));
                await swapList(data?.map((item) => ({
                    roleId: item.roleId,
                    name: item.name.replace('_', ' ')
                })), roleTempList);
            });
            await dispatch(ro);

            if (organizationId && organizationId.length > 0) {
                const od = await orgDepList({organizationId: organizationId ? organizationId : user.organizationId}, (data) => {
                    if (data && Array.isArray(data)) {
                        setOrganizationDepartmentList(data);
                        setOrganizationDepartmentListModified(true);
                    } else {
                        setOrganizationDepartmentList([]);
                        setOrganizationDepartmentListModified(false);
                    }
                });
                await dispatch(od);
            }
        }
    }, [organizationId])

    useEffect(async () => {
        if (roleList && Array.isArray(roleList) && roleList.length > 0) {
            if (!organizationDepartmentList || !Array.isArray(organizationDepartmentList) || organizationDepartmentList.length === 0) {
                await swapList(roleList, roleTempList);
            } else {
                await swapList(roleTempList, roleList);
            }
        }
    }, [organizationDepartmentList, organizationDepartmentListModified])

    const changeRelation = (e) => {
        setRelation(e.target.value);
    }
    const changeFirstName = (e) => setFirstName(e.target.value);
    const changeLastName = (e) => setLastName(e.target.value);
    const changeEmailAddress = (e) => setEmailAddress(e.target.value);
    const changeOrganization = (e) => {
        setOrganizationId(e.target.value);
    }
    const changeOrganizationDepartment = (e) => setOrganizationDepartmentId([e.target.value]);
    const changeRole = (e) => setInvitedRole(e.target.value);


    const validateFirstNameField = async (e) => {
        await setIsDirty(true);
        await setFirstNameError(validateFirstName(firstName));
    }

    const validateLastNameField = async (e) => {
        await setIsDirty(true);
        await setLastNameError(validateLastName(lastName));
    }

    const validateEmailAddressField = async (e) => {
        await setIsDirty(true);
        await setEmailAddressError(validateEmailAddress(emailAddress));
    }

    const validateOrganizationField = async (e) => {
        if (user.roles && user.roles.indexOf(SYS_ADMIN) !== -1) {
            await setIsDirty(true);
            await setOrganizationIdError(validateOrganization(organizationId));
        }
    }

    const validateOrganizationDepartmentField = async (e) => {
        if ((user.roles && user.roles.indexOf(SYS_ADMIN) !== -1 || user.roles.indexOf(ORGANIZATION_ADMIN) !== -1) && organizationDepartmentList.length > 0) {
            await setIsDirty(true);

            if (invitedRole && parseInt(invitedRole) === DEPARTMENT_ADMIN)
                await setOrganizationDepartmentError(validateOrganizationDepartment(organizationDepartmentId));
        }
    }

    const validateRoleField = async (e) => {
        await setIsDirty(true);
        await setRoleError(validateRole(invitedRole));
        await setOrganizationDepartmentError("");
    }

    const validateRelationField = async (e) => {
        if (user.roles && user.roles.indexOf(REGULAR_USER) !== -1) {
            await setIsDirty(true);
            await setRelationError(validateRelation(relation));
        }
    }

    const validateAllFields = async () => {
        await setIsDirty(true);
        const invalidFirstName = validateFirstName(firstName);
        const invalidLastName = validateLastName(lastName);
        const invalidEmailAddress = validateEmailAddress(emailAddress);
        let invalidRelation = '';
        let invalidOrganizationDepartment = '';
        let invalidateOrganization = '';
        let invalidRole = '';

        if (user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1)) {
            invalidateOrganization = validateOrganization(organizationId);
            setOrganizationIdError(invalidateOrganization);
        }

        if (user.roles && organizationDepartmentList && organizationDepartmentList.length > 0 &&
            (user.roles.indexOf(SYS_ADMIN) !== -1 || user.roles.indexOf(ORGANIZATION_ADMIN) !== -1)) {
            invalidOrganizationDepartment = validateOrganizationDepartment(organizationDepartmentId);
            setOrganizationDepartmentError(invalidOrganizationDepartment);
        }

        if (user.roles && user.roles.indexOf(REGULAR_USER) !== -1) {
            invalidRelation = validateRelation(relation);
            setRelationError(invalidRelation);
        }

        if (user.roles && user.roles.indexOf(REGULAR_USER) === -1) {
            invalidRole = validateRole(invitedRole);
        }

        setFirstNameError(invalidFirstName);
        setLastNameError(invalidLastName);
        setEmailAddressError(invalidEmailAddress);
        setRoleError(invalidRole);


        if (invalidFirstName.length > 0 ||
            invalidLastName.length > 0 ||
            invalidEmailAddress.length > 0 ||
            invalidRelation.length > 0 ||
            invalidateOrganization.length > 0 ||
            invalidRole.length > 0 ||
            invalidOrganizationDepartment.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <ToastContainer />
                <Container className="registration">
                    <div className="pageContainer">
                        <h1 className="text-center title">Invite a {titleText}</h1>
                        <h2 className="text-center title">The {subTitleText} will receive an invitation link to activate
                            their guide.</h2>
                        <Box className="material-box" component="form" onSubmit={submitForm} autoComplete="off">
                            <div className="card-body">
                                <div className="formFlexContainer">
                                    <h3 className="text-center title">Invite by Email</h3>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={firstNameError}>
                                            <TextField className="w-100" name="firstName" id="firstName"
                                                       label={firstNameLabel} variant="outlined"
                                                       helperText={firstNameHelperText} value={firstName}
                                                       onChange={changeFirstName} onBlur={validateFirstNameField}
                                                       error={!!firstNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={lastNameError}>
                                            <TextField className="w-100" name="lastName" id="lastName"
                                                       label={lastNameLabel} variant="outlined"
                                                       helperText={lastNameHelperText} value={lastName}
                                                       onChange={changeLastName} onBlur={validateLastNameField}
                                                       error={!!lastNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    {user.roles && user.roles.indexOf(REGULAR_USER) !== -1 &&
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={relationError}>
                                                <Select multiple={false} native={true} autoWidth={true}
                                                        className="w-100"
                                                        name="relation" id="relation" key="relation" label="Relation"
                                                        value={relation} variant="outlined" onChange={changeRelation}
                                                        onBlur={validateRelationField}>
                                                    <option value={0} key="relationOption">Please specify your
                                                        relationship to the family member you are inviting
                                                    </option>
                                                    {
                                                        relationList.map((item, i) => (
                                                            <option value={item.relativeId}
                                                                    key={"relationOption" + (i + 1)}>{item.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </InputWithErrorMessage>
                                        </div>
                                    }
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={emailAddressError}>
                                            <TextField className="w-100" name="email" id="email" type="email"
                                                       label="Email" variant="outlined"
                                                       helperText={emailAddressHelperText} value={emailAddress}
                                                       onChange={changeEmailAddress} error={!!emailAddressError}
                                                       onBlur={validateEmailAddressField}
                                                       required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    {
                                        user.roles && user.roles.indexOf(SYS_ADMIN) !== -1 && Array.isArray(organizationList) && organizationList.length > 0 &&
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={organizationIdError}>
                                                <Select multiple={false} native={true} autoWidth={true}
                                                        className="w-100"
                                                        name="organization" id="organization" key="organization"
                                                        label="Organization" value={organizationId} variant="outlined"
                                                        onChange={changeOrganization}
                                                        onBlur={validateOrganizationField}>
                                                    <option value={0} key="organization0">Please select an
                                                        organization
                                                    </option>
                                                    {
                                                        Array.isArray(organizationList) && organizationList?.length > 0 ? organizationList?.map((item, i) =>
                                                            <option value={item.organizationId}
                                                                    key={"organization" + (i + 1)}>{item.orgName}</option>
                                                        ) : ''
                                                    }
                                                </Select>
                                            </InputWithErrorMessage>
                                        </div>
                                    }
                                    {
                                        user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1 || user.roles.indexOf(ORGANIZATION_ADMIN) !== -1) &&
                                        organizationId && organizationId.length > 0 && Array.isArray(organizationDepartmentList) && organizationDepartmentList.length > 0 &&
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={organizationDepartmentError}>
                                                <Select multiple={false} native={true} autoWidth={true}
                                                        className="w-100"
                                                        name="organizationDepartment" id="organizationDepartment"
                                                        key="organizationDepartment"
                                                        label="Department"
                                                        value={organizationDepartmentId && organizationDepartmentId?.length > 0 ? organizationDepartmentId[0] : 0}
                                                        variant="outlined"
                                                        onChange={changeOrganizationDepartment}
                                                        onBlur={validateOrganizationDepartmentField}>
                                                    <option value={0} key="organizationDepartment0">Please select an
                                                        organization department
                                                    </option>
                                                    {
                                                        Array.isArray(organizationDepartmentList) && organizationDepartmentList?.length > 0 ? organizationDepartmentList?.map((item, i) =>
                                                            <option value={item.organizationDepartmentId}
                                                                    key={"organizationDepartment" + (i + 1)}>{item.departmentName}</option>
                                                        ) : ''
                                                    }
                                                </Select>
                                            </InputWithErrorMessage>
                                        </div>
                                    }
                                    {
                                        user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1 || user.roles.indexOf(ORGANIZATION_ADMIN) !== -1 ||
                                            user.roles.indexOf(DEPARTMENT_ADMIN) !== -1 || user.roles.indexOf(ORBIT_ADMIN) !== -1) &&
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={roleError}>
                                                <Select multiple={false} native={true} autoWidth={true}
                                                        className="w-100 roleSelect"
                                                        name="invitedRole" id="invitedRole" key="invitedRole"
                                                        label="Role" value={invitedRole} variant="outlined"
                                                        onChange={changeRole} onBlur={validateRoleField}>
                                                    <option value={0} key="invitedRole0">Please select a user role
                                                    </option>
                                                    {
                                                        Array.isArray(roleList) && roleList?.length > 0 ? roleList?.map((item, i) => (
                                                            <option value={item.roleId}
                                                                    key={"invitedRole" + (i + 1)}>{item.name}</option>
                                                        )) : ''
                                                    }
                                                </Select>
                                            </InputWithErrorMessage>
                                        </div>
                                    }
                                    <div className="buttonContainer loginButtonContainer">
                                        <Button type="submit" variant="contained" className="loginUIComp center"
                                                disabled={firstNameError?.length > 0 || lastNameError?.length > 0 || emailAddressError?.length > 0 || errorMessage?.length > 0}>
                                            Invite This {titleText}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </Container>
            </div>
            <div className="gradientBottom">
            </div>
        </div>
)
};

export default PatientInvite;
