import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css'
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTTGSP4_2_4 = () => {
const opts = {
        height: '390',
        width: '640',
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Communicating: Speech and Swallow Therapy After A Tracheostomy'}, () => {
            console.log('Communicating: Speech and Swallow Therapy After A Tracheostomy');
        });
        await dispatch(pal);
    }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Communicating</h1>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLowerColorBar limeGreenBackground centered text-center w-100 justify-content-center">IF YOU WILL NOT BE HAVING A TRACHEOSTOMY PERFORMED <br />as part of your surgery, then you will usually not have difficulty communicating after surgery.</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <Accordion className="colorBarExpand">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <h3 className="subheadUpperAndLowerColorBar centered text-center" style={{display: "block !important"}}><span style={{float: "left", width: "100%"}}>IF YOU WILL BE HAVING A TRACHEOSTOMY PERFORMED,
                                                    you will have changes to how you communicate after surgery<br/><em>Please click here for further information ></em></span></h3>
                                            </Accordion.Header>
                                            <Accordion.Body className="flex-column">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower floatNone">What to Expect After Your Procedure.</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>During the few days after your operation, you will have a special type of tracheostomy tube with a balloon inflated in your windpipe called a “cuffed” tracheostomy tube.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom bulletsSafeCareNoIndent">
                                                            <li>This balloon (cuff) prevents mucous and other secretions from going into your lungs while you heal.</li>
                                                            <li>While this balloon is inflated, you will not be able to speak.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                                    <div className="w-100">
                                                        <div className="imageContainer">
                                                            <div className="leftCol col-5 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Cuffed-tracheostomy_FPO.png" className="img-responsive" />
                                                                <div className="imgCaption">
                                                                    <p className="captionBold">Cuffed tracheostomy</p>
                                                                </div>
                                                            </div>
                                                            <div className="rightCol col-5 paddingLeft7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Cuffed_trach_tube.png" className="img-responsive" />
                                                                <div className="imgCaption">
                                                                    <p className="captionBold">Cuffed tracheostomy tube</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>When your surgeon thinks you do not need the balloon (cuff), the “cuffed” tracheostomy tube will be removed and a “cuffless” tracheostomy tube placed. This tracheostomy tube does not have an inflatable balloon. With this new trach, you will be able to speak again. This usually occurs 3 to 5 days after surgery. Your tracheostomy tube will be removed when it has been deemed safe by your care team. Most patients will have their tracheostomy tube removed before they go home, though some will need to keep theirs for longer.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                                    <div className="w-100">
                                                        <div className="imageContainer">
                                                            <div className="leftCol col-5 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cuffless_trach_tube.png" className="img-responsive" />
                                                                <div className="imgCaption">
                                                                    <p className="captionBold">Cuffless tracheostomy</p>
                                                                </div>
                                                            </div>
                                                            <div className="rightCol col-5 paddingLeft7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/uncuffed_trachea_FPO.png" className="img-responsive" />
                                                                <div className="imgCaption">
                                                                    <p className="captionBold">Cuffless tracheostomy tube</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower floatNone">Communicating After Your Procedure. </h2>
                                                        <p>Depending on the location of your cancer and the type of reconstruction, your speech may not be as clear as before surgery due to changes in the anatomy of your mouth and/or throat. This will improve with time.</p>
                                                        <p>If your speech changes are severe, you will benefit from working with one of our speech language pathologists (SLP) after you leave the hospital.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 video">
                                                    <div className="">
                                                        <h3 className="blueColor videoTitle w-100 text-center">Speech and Swallow Therapy After A Tracheostomy
                                                        </h3>
                                                        <div className="floatLeft w-100 captionBelow">
                                                            <div className="embedContainer originalPadding">
                                                                <YouTube videoId="lHOrCmfq1pE" opts={opts} onPlay={playVideo} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP4_2_4;
