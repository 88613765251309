import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGivingMedicinesWhatToTellYourDoctor = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGauzeDressing">Changing a Gauze Dressing</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Take a Medicine at the specific times prescribed</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Each time you go to the doctor with the person you care for, ask these questions about their medicines.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>What exactly does this medicine do?</li>
                                                    <li>What’s the best time or times of day to take this?</li>
                                                    <li>Are there foods to avoid when taking this?</li>
                                                    <li>Should the medicine be taken with foods or on an empty stomach?</li>
                                                    <li>What about any supplements?</li>
                                                    <li>What are side effects I should watch for?</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Take a Medicine at the specific times prescribed</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Have the person you take care of keep a list of current medicines on a wallet card that is with them at all times. List all medicines that are prescribed and those bought over-thecounter. Be sure you have an updated list on each doctor’s visit.</li>
                                                    <li>If the person has had any expected side effects, report those to the doctor and tell him or her how long the side effects have lasted.</li>
                                                    <li>If you know a person is having trouble taking the prescribed number of doses of a medicine each day, tell the doctor.</li>
                                                    <li>If the person refuses to take their medicine, tell the doctor.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SCCG_doctor.png" className="img-responsive maxWidth300 floatNone" />
                                             </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGivingMedicinesWhatToTellYourDoctor;
