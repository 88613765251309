import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonStitchesWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonStitches">Care of Stitches (Sutures)</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell the Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>During the next visit to the doctor take your care diary or any notes you made of what the sutures and wound looked like.</li>
                                        <li>Call the doctor immediately if you see these changes:</li>
                                            <ul className="bulletsCustom greenBullets chevronRightBulletsSafeCareIndent">
                                                <li>The drainage from the wound does not decrease after the first few days</li>
                                                <li>Drainage becomes bright red with blood</li>
                                                <li>Drainage contains yellow, green or brown pus and it is foul smelling</li>
                                                <li>The sutures (some or all) fall out</li>
                                                <li>There are spaces between the sutures</li>
                                                <li>The person has a fever, 100.5 degrees Fahrenheit or greater or the fever continues more than 1 to 2 days</li>
                                                <li>A firm or hard area that is tender develops along or near the suture line</li>
                                            </ul>
                                            <li>Let the doctor know if you are having difficulty cleansing the wound.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonStitchesWhatToTellYourDoctor;