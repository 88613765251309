import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";
import { useDispatch } from "react-redux";
import { videoAccessLog } from "../../actions/videoAccessLog";

const LivingWellCenter2_4 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const dispatch = useDispatch();
  const playWhatToExpect = async (e) => {
    const pal = await videoAccessLog(
      {
        videoName:
          "What to Expect at the Living Well Center (Dr. John Metzler)",
      },
      () => {
        console.log(
          "What to Expect at the Living Well Center (Dr. John Metzler)"
        );
      }
    );
    await dispatch(pal);
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/437cb146af6df16026a8a4137929cdbe.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#75B8E4",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">What to Expect</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L31 Video Box Large */}
                  <div className="lego l031 legoMargin2">
                    <div className="videoContainer">
                      <div className="embedContainer originalPadding">
                        <YouTube
                          videoId="yRn5TKGnE70"
                          opts={opts}
                          onPlay={playWhatToExpect}
                        />
                      </div>
                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          We’re here to work with you
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  {/* L14 Paragraph */}

                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      At the Living Well Center, we understand that a positive
                      patient experience begins with active listening. With
                      every moment you work with us, you can expect to be heard.
                    </p>
                  </div>

                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      From your first visit, you can expect to be treated as a
                      whole person, with care taken to understand all aspects of
                      your life that could effect your health and success. Your
                      experience will begin with a simple conversation with a
                      team deeply committed to finding the right path for you to
                      achieve exactly what you’re hoping for.
                    </p>
                  </div>

                  <div className="lego l00paragraph legoMargin5">
                    <p>
                      From that first visit, you’ll receive our assessment of
                      what combination of services can best help you reach your
                      goals. And then we’ll sit down and develop your plan
                      together.
                    </p>
                  </div>

                  {/* End L14 Paragraph */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter2_4;
