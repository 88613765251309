import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
const LessonWalkersWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWalkers">Correct Use of Walkers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWalkers/WhatToDoAndSafety#introduction#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWalkers/WhatToDoAndSafety#introduction#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWalkers/WhatToDoAndSafety#introduction#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWalkers/WhatToDoAndSafety#introduction#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Recognizing Problems</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A walker provides needed support and stability to allow a person with a weakness or injury to walk and move about safely. However, at times a person has difficulty using a walker. This may be due to fear of falling, pain, upper body muscles are too weak to use the walker, or the legs are too weak to support the body.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Are there obstacles in their walking path making it hard to move about? (e.g. Furniture, piles of newspapers, children’s toys)</li>
                                            <li>Is the person using the walker incorrectly as they walk, sit, or stand from a chair?</li>
                                            <li>Are they showing signs of fatigue or unsteadiness as they walk?</li>
                                            <li>Does the person have difficulty lifting the walker?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If the person continues to have difficulty, call the physical therapist or responsible doctor.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">To Keep a Walker in Good Condition</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Routinely check the condition of a walker to be sure it is safe to use.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Look closely at the frame each day.</li>
                                            <li>Look for any signs of bending or deformation of the frame.</li>
                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent">
                                                <li>Are there any loose or missing screws that will weaken the joints of the frame?</li>
                                                <li>Are there screws that stick out?</li>
                                            </ul>
                                            <li>Take a close look at the handgrips.</li>
                                            <ul class="bulletsCustom chevronRightBulletsSafeCareIndent">
                                                <li>Handgrips are plastic, foam or soft grip.</li>
                                                <li>Look for signs of cracked or loose grips.</li>
                                                <li>You want a grip to be secure so that it fits the hands and won’t slip during use.</li>
                                                <li>If a person has trouble grasping a grip with the fingers, it might help to have a larger grip.</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Here are some tips for preventing falls when using any type of walker:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom">
                                            <li>Keep the tips of the walker clean. Use mild soap and water to remove dirt, hair, and gravel.</li>
                                            <li>Remove all throw rugs, electrical cords and unneeded objects (e.g. children’s toys, pet toys) out of the common walking pathways in the home.</li>
                                            <li>Clean up spills on the floor immediately to prevent slipping.</li>
                                            <li>Door thresholds between rooms should be flat and smooth, not raised.</li>
                                            <li>Always keep any items that the person needs close at hand to avoid reaching or repeatedly having to stand.</li>
                                            <li>Rearrange furniture to provide open space through hallways and major rooms.</li>
                                            <li>Have the person use a small backpack, fanny pack or purse to help carry things around.</li>
                                            <li>In the bathroom use non-slip mats, grab bars, a raised toilet seat, and a shower tub seat.</li>
                                            <li>For more information, see our lesson on <a href="/LessonFallPrevention">Fall Prevention</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Video: </h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="ZPox1yeev5E" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WLK_woman_with_walker.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom">
                                            <li>Properly fitted, sturdy walker.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    {/*//
                                    <hr className="w-100" />*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWalkersWhatToDoAndSafety;