import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonPainWhatToTellYourDoctor = () => {

     return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center wdith400">
                                                        <p><Nav.Link className="" href="/LessonPain">Recognizing and Relieving Pain</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Talk to the doctor about changing a pain medicine from PRN (as needed) to a regular around-the-clock (ATC) schedule, if the person needs the medicine frequently. This gives the person better pain control hour by hour. This also alerts the doctor that the plan of treatment may need to be adjusted or changed due to the level of pain that the person is having.</li>
                                                    <li>Show the doctor your care diary that describes the person’s pain and steps taken to relieve the pain. Include details about the pain and any behaviors you have noticed, such as pain scale measures, body movements or expressions.</li>
                                                    <li>Let the doctor know about any new type of pain.</li>
                                                    <li>Let the doctor know what, if anything, relieves the person’s pain.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive maxWidth300 floatNone" />
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer blueBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonPainWhatToTellYourDoctor;
