import React from "react";

function L88AComponentCode() {
  return (
    <div className="lego l088A">
      <div className="contentContainer">
        <p>
          Experience has shown that engaged participants in our
          <br />
          programs can expect to see health improvements within <br />
          <span className="highlightedText">90-120 days. </span>
        </p>
        {/* <p style={{ fontWeight: 400 }}>90-120 days. </p>*/}
      </div>
    </div>
  );
}

export default L88AComponentCode;
