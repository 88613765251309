import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonDrainageWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDrainage">Care of Drainage Bags</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Drainage Devices</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Wound drainage is removed either by an open or closed drain system. An <strong><em>open system</em></strong> lets fluid drain straight onto a gauze dressing. A <strong><em>closed drain</em></strong> system lets fluid drain into a bag or collection device.</p>
                                                <p>A doctor inserts the drains through a small puncture next to the wound or incision. Any fluid that collects in the wound bed can then exit thru the drain, allowing the wound to heal by closing from the inside out.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower text-left">Penrose Drain&nbsp;</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/penrose_drain_2.jpg" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p>A Penrose drain is an open drain system.<br /><br />
                                                        The drain is a flat hollow rubber tube which exits the incision and drains onto a gauze dressing. The surgeon will often place a safety pin through the drain to prevent it from moving back into the wound.<br /><br />
                                                        As the wound heals and drainage decreases, the doctor pulls the drain further out from the wound. The amount of drainage will determine how often you need to change the gauze dressing.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower text-left">Jackson-Pratt Drain&nbsp;</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Jackson_Pratt_Drain.png" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p>
                                                        A Jackson-Pratt drain is a closed drain system.<br /><br />
                                                        The drain uses vacuum to pull drainage out from the wound bed and into a bulb-shaped collection device. The device collects fluid normally in the range of 100-200 mL (about a ½ to 1 cup) in 24 hours. The amount will vary.<br /><br />
                                                        You can empty the bulb several times a day, but always follow the directions from your doctor or home health nurse.<br /><br />
                                                        After opening the bulb, drain the fluid into a plastic container (e.g. a pitcher). Then, compress the bulb and close the drain to create a suction. The drain tubing is often held in place with a suture to prevent the drain itself from slipping and sliding in and out of the drain site. As the wound heals the drainage decreases.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower text-left">Hemovac Drain&nbsp;</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Hemovac_Drain.png" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p>The Hemovac is a larger closed drain system.<br /><br />
                                                        The drain uses vacuum to pull drainage out from the wound bed and into a circular shaped collection device. It normally collects up to 500 mL ( 2 cups) in 24 hours.<br /><br />
                                                        You empty a Hemovac each day or more often, based on doctor or home health nurse directions.<br /><br />
                                                        After opening the drain spout, drain the fluid into a plastic measuring cup.<br /><br />
                                                        Then, push down on the accordion like device and close the spout while holding the device down. This creates the vacuum to pull fluids out of the wound bed. The drain is usually held in place with a stitch in the skin. As the wound heals the drainage decreases.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Common Conditions That Require a Drain</h2>
                                                <p>Drains are needed in cases of deep wounds and wounds expected to have large amounts of drainage. Patients who are sent home with a surgical drain usually do well and notice a decrease in drainage in a short time.</p>
                                                <p>When a person goes back to their doctor, the drain is usually pulled further out of the wound or removed.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Partial or Total Mastectomy:</p>
                                                <p>This is the removal of a part of a breast or the whole breast, including all of the tissue and nearby lymph nodes. A drain is needed because a large amount of tissue is removed and lymph fluid can build up.</p>
                                                <p>The most common types of drains used are the Jackson Pratt or Hemovac. For a smaller amount of drainage, the surgeon will insert a Penrose drain.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Large Abdominal Wounds:</p>
                                                <p>A large wound in a person’s belly or abdomen will be the result of major surgery or after trauma from an automobile accident or knife or gunshot wound.</p>
                                                <p>Surgery often involves extensive tissue removal and/or the surgeon has to handle or move the tissue around. Thus, a large amount of fluid may buildup and drains for several days. The surgeon will usually use a Jackson Pratt or hemovac system.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Bile Drainage:</p>
                                                <p>Surgery for removal of the gallbladder sometimes requires placement of a small drainage tube that helps to keep the bile duct open for proper healing.</p>
                                                <p>Excess bile will drain until the person’s small intestine gets used to the extra bile. A bile drain will usually be connected to a Jackson Pratt drainage device.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Total Knee or Hip replacements:</p>
                                                <p>Replacement of a major jointoccurs in an area where there is limited blood flow to the bone. Thus, the body is less able to absorb the drainage from the joint and fight off infection.</p>
                                                <p>The surgeon places a Hemovac drain to remove any drainage and prevent infection. Once the drainage has stopped the drain is removed.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Wound Drainage</h2>
                                                <p>When you manage a wound drainage system in the home you need to know the amount of drainage to expect and what it should look like. You also need to know signs of problems.</p>
                                                <p>This will then allow you to know if the wound is healing and to recognize early when problems are developing.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Normal Drainage</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>The doctor will tell you the color and amount of drainage to expect.</li>
                                                    <li>Normally, early drainage is light red in color and it changes to become more clear and watery.</li>
                                                    <li>The amount of drainage should decrease each day.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Infection</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>If the person has an infection, the drainage coming from the collection container, and/or from around the insertion site can be yellow, green or brown, cloudy, and often foul smelling. It will increase greatly in amount.</li>
                                                    <li>There will be increased redness, swelling, or tenderness at the site where the drainage tube exits the skin.</li>
                                                    <li>The person may have a fever, with their oral temperature 100.5° Fahrenheit (38° Centigrade) or higher, or according to doctor’s guidelines.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Blockage</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Suspect blockage if there is a sudden drop in drainage in the collection device. This may be due to debris or blood clots blocking the holes in the tubing under the skin. Sutures holding the tube may be in too tight. <strong><em>Report this to the doctor as soon as possible.</em></strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Bleeding</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>If there is <strong><em>new</em></strong>, bright red drainage. <strong><em>Report this to the doctor as soon as possible. If drainage suddenly occurs in large amounts (more than 50 mL or 3.5 Tablespoons) go to the nearest emergency room.</em></strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer greenBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDrainageWhatYouNeedToKnow;
