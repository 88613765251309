import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonStitchesWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonStitches">Care of Stitches (Sutures)</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What Does a Wound Look Like?</h2>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_04.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Stitches or staples normally cause some redness, mild irritation and itching where the suture enters the skin</li>
                                            <li>There is normally mild swelling along an incision or wound closure for 1 to 2 days.</li>
                                            <li>Normally the wound edges should be clean and closed together. Some drainage from a wound is expected the first few days after surgery. This drainage is initially pale red and watery, then it becomes clear or clear yellow with or without some shades of pink</li>
                                            <li>Within a few days the wound begins to close and some light scabbing can be seen along the edges</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder greenBorder">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Signs of a Wound Infection:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Redness around the incision that goes beyond the edges of the wound</li>
                                                <li>Yellow, green, or reddish brown drainage that looks like puss</li>
                                                <li>A sweet, heavy or thick odor</li>
                                                <li>Increased tenderness (After surgery, a wound is always most tender the first 2 to 3 days, but this should begin to decrease)</li>
                                                <li>Increased swelling along the sutures and skin edges after 2 to 3 days</li>
                                                <li>The edges of a wound separate and open up</li>
                                                <li>Fever - body temperature 100.5 degrees Fahrenheit or greater (oral thermometer)</li>
                                                <li>A very firm tender area of the skin</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who is More at Risk for Poor Wound Healing?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Persons who do not eat a proper diet</li>
                                            <li>Persons with poor circulation to the skin, like someone with diabetes</li>
                                            <li>Older adults</li>
                                            <li>The very obese</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonStitchesWhatYouNeedToKnow;