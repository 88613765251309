import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonAfterSurgeryIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonAfterSurgery">Care After Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonAfterSurgery/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonAfterSurgery/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10189.jpg" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Whether a person has had minor or major surgery, there will be limits placed on activities when he or she returns home. When you provide support to someone in the home, your assistance will help that person recover with greater ease. Most people find it less stressful and more comfortable to be in their own home.</p>
                                        <p>The surgeon will order their care so as to promote healing and make it easier for them to return to normal activities. This plan of care will allow a person to be in their home, but still have help with certain activities.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">The person may need help with:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Wound care</li>
                                            <li>Pain Control</li>
                                            <li>Diet</li>
                                            <li>Taking prescribed medicines (such as antibiotics)</li>
                                            <li>Safe exercise</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">The surgeon will also order activities to prevent problems from occurring, such as:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Limiting activities that place strain on the surgical wound</li>
                                            <li>Providing a list of signs and symptoms that you should report</li>
                                            <li>Taking the person’s temperature regularly to watch for fever</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight middleGreenBackground">
                                            <h2 className="subheadUpperAndLower mb-0">Other Helpful CaringBoost Lessons</h2>
                                        </div>
                                        <div className="withBorder borderAccentMiddleGreen">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p>Many of these lessons offer you the information you need to care for a person after surgery.</p>
                                                    <p className="bold">For example, see our lessons on:</p>
                                                    <p><a href="/LessonInfection" target="_blank">Infection Control</a> <i class="fa-solid fa-chevron-right"></i></p>
                                                    <p><a href="/LessonFeedingAPerson" target="_blank">Feeding a Person</a> <i class="fa-solid fa-chevron-right"></i></p>
                                                    <p><a href="/LessonGivingMedicines" target="_blank">Giving Medicines Safely</a> <i class="fa-solid fa-chevron-right"></i></p>
                                                    <p><a href="/LessonMoving" target="_blank">Moving Safely in the Home</a> <i class="fa-solid fa-chevron-right"></i></p>
                                                    <p><a href="/LessonPain" target="_blank">Recognizing and Relieving Pain</a> <i class="fa-solid fa-chevron-right"></i></p>
                                                    <p>These lessons will review key points for helping you to provide care for a person after surgery.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Whether a person has had minor or major surgery, there will be limits placed on activities when he or she returns home.</p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                        Video: Care After Surgery
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="hhAPGnx5T0Y" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer greenBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonAfterSurgeryIntroduction;