import {postRequest} from "../api";
import { QR_CODE_URL_REQUEST_ERROR } from "./types";

export const requestQrCodeLink = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await postRequest(`/user/generateqrcodelink`, "user", data);


        cb(res.data);
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                dispatch({
                    type: QR_CODE_URL_REQUEST_ERROR,
                    payload: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                dispatch({
                    type: QR_CODE_URL_REQUEST_ERROR,
                    payload: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: QR_CODE_URL_REQUEST_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: QR_CODE_URL_REQUEST_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: QR_CODE_URL_REQUEST_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};
