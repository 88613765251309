import React from "react";
import YouTube from "react-youtube";

function L31ComponentCode() {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }
    return (

        <div className="lego l031 legoMargin2">
            <div className="videoContainer">
                <div className="embedContainer originalPadding">
                    <YouTube videoId="umWIvIe9144" opts={opts}/>
                </div>

                <h3 className="videoTitle w-100 text-center text-dark">
                    Watch:{" "}
                    <span className="videoTitleTreatment">
                                                  How can Living Well Center help you?
                                                </span>
                </h3>
            </div>
        </div>

    )
};


export default L31ComponentCode;