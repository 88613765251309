import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonBloodPressureWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonBloodPressure">Taking a Blood Pressure</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonBloodPressure/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonBloodPressure/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonBloodPressure/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonBloodPressure/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Preparing the Person Before Measuring Blood Pressure</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You want to be accurate when taking a blood pressure measurement. Accurate results are useful to the doctor when he or she looks at your care diary. The results may help in making decisions about the person’s BP medicines.</p>
                                        <p className="bold">Before you measure a blood pressure, have the person relax and be comfortable:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Have the person go to the bathroom 5 to 10 minutes before the reading.</li>
                                            <li>Do not let the person exercise, smoke, or drink any caffeine or alcohol within 30 minutes of measurement. Each of these factors raises the blood pressure.</li>
                                            <li>Have the person relax for 5 minutes before measurement.</li>
                                            <li>Do not allow the person to talk for the 3 to 5 minutes before the measurement or during the measurement of blood pressure.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How to Monitor Blood Pressure</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You want to be accurate when taking a blood pressure measurement. Accurate results are useful to the doctor when he or she looks at your care diary. The results may help in making decisions about the person’s BP medicines.</p>
                                        <p><span className="bold">Follow these tips from the American Heart Association</span> (AHA, 2012):</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Do not apply a blood pressure cuff to an arm if:</li>
                                            <ul className="bulletsCustom bulletsSafeCareIndent">
                                                <li>The person has an intravenous catheter</li>
                                                <li>The person has a Hemodialysis shunt</li>
                                                <li>The person has had breast surgery or a stroke on that side</li>
                                                <li>There is injury, swelling, or disease of the arm</li>
                                                <li>The arm has a cast or large bandage</li>
                                            </ul>
                                            <li>Make sure the cuff fits: Measure around the person’s upper arm and choose a monitor that comes with the correct cuff size.</li>
                                            <li>Be still - have the person relax to get an accurate reading.</li>
                                            <li>Have the person place their feet flat on the floor. Do not let the person cross their legs; this causes the blood pressure to rise.</li>
                                            <li>Support the person’s arm on a flat surface (such as a table) with the upper arm at heart level.</li>
                                            <li>Make sure the middle of the cuff is placed directly over the brachial artery, which is found on the inside of the elbow, just below the bend of the arm. With your index and middle finger, gently apply pressure until you feel the pulse. It will feel like tapping against your fingers. Do not use your thumb because you may feel your own pulse instead. Check your monitor’s instructions for a picture or have your doctor or nurse show you how.</li>
                                            <li>Each time you measure the blood pressure, if you think you have a high or low reading, wait 15 minutes and repeat a reading. Record all the results.</li>
                                            <li>It is important to take the readings at the same time each day, such as morning and evening, or as your doctor recommends.</li>
                                            <li>If you are taking a blood pressure for the first time, check the blood pressure in both arms. If one arm is reading higher than the other, then the higher arm is the one to use for future measurements. If the difference in arm pressures is greater than 20 mmHg, alert the home health nurse, or the person’s doctor.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Unexpected Blood Pressure Results</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When you begin to monitor blood pressure at home, be sure that you have talked to the doctor and know when to call about unexpected results or how to manage them at home.</p>
                                        <p className="bold">Blood pressure reading is above <i className="fa fa-arrow-up redColor"></i> the acceptable range</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Make sure the cuff fits correctly.</li>
                                            <li>Is the person having any symptoms of high blood pressure?</li>
                                            <ul className="bulletsCustom bulletsSafeCareIndent">
                                                <li>Headache</li>
                                                <li>Flushed face</li>
                                                <li>Nosebleed</li>
                                                <li>Sleepiness in older persons</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold italic">If any of these signs or symptoms are present, contact the home health nurse, doctor, or emergency services promptly.</p>
                                        <p className="boldc">Blood pressure reading is below <i className="fa fa-arrow-down redColor"></i> the acceptable range</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Repeat measurement in the other arm and compare</li>
                                            <li>Make sure the cuff fits correctly</li>
                                            <li>Is the person having any symptoms of low blood pressure?</li>
                                            <ul className="bulletsCustom bulletsSafeCareIndent">
                                                <li>Dizzy or fainting</li>
                                                <li>Fatigue</li>
                                                <li>Confused</li>
                                                <li>Skin is pale, bluish, or cool</li>
                                            </ul>
                                            <li>Place person flat if they can tolerate position</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold italic">If any of these signs or symptoms are present, contact the home health nurse, doctor, or emergency services promptly.</p>
                                        <p className="boldc">Blood pressure reading could not be obtained <i className="fa fa-question-circle redColor"></i> was difficult to hear</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Check all equipment for proper connections and function</li>
                                            <li>Cuff is probably too loose, too big, or stethoscope may be in wrong place.</li>
                                            <li>Try measuring in the opposite arm</li>
                                            <li>At times, the ear tips of the stethoscope are not properly placed in the ear. If this happens, you may hear little-to-no sounds coming from the stethoscope. When the ear tips of the stethoscope are in your ears, they should point away from you, or point forward. If the fit isn’t comfortable, or you cannot hear, readjust the headset/eartips for a better fit.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Older Adults</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Older adults may not have as much muscle on their arm. In addition, their skin is more fragile and their blood pressure changes more easily.</p>
                                        <p>For older adults consider:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Careful choice of cuff to fit their arm</li>
                                            <li>Check their skin frequently and alternate arms for frequent blood pressure checks</li>
                                            <li>Do not measure blood pressure shortly after a meal because it may be lower just from eating</li>
                                            <li>Measure blood pressure after the person has rested for five minutes</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Eat Healthy</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The doctor will often have a person with high blood pressure follow a special diet. One diet that many with high blood pressure follow is called the DASH diet.</p>
                                        <p>DASH stands for Dietary Approaches to Stop Hypertension. The DASH diet is an eating plan which is low in fat, but rich in low-fat dairy foods, fruits and vegetables. The diet is also low in salt or sodium, usually with a limit of 2 to 2.4 grams of sodium a day.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="safetytips">SAFETY TIPS</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Have the person’s doctor check the blood pressure device for accuracy</li>
                                                <li>Recheck the device every year (some medical equipment companies will do this for you)</li>
                                                <li>Store the device in a cool place with the tubing free and not twisted</li>
                                                <li>Check the tubing once a month for any leaks and cracks</li>
                                                <li>Clean the ear pieces of the stethoscope with alcohol after every use to prevent dirt/wax build up that will make it difficult to hear the heartbeat</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="showMeHow">SHOW ME HOW</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Before you measure a blood pressure, make sure the person is relaxed and comfortable.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight flex-column">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Learn the techniques of infection control.</p>
                                            </div>
                                        </div>
                                        <div className="w-100 videoFlexContainer">
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="57759OhrOBI" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Measuring a Blood Pressure (Manually) 6:03
                                                </p>
                                            </div>
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="3Ui0PmPJq5U" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Measuring a Blood Pressure (Automatic) 2:43
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="" target="_blank">Open File
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar text-center">Measuring with an aneroid device</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Aneroid blood pressure cuff and gauge</li>
                                                    <li>Stethoscope (may be built into cuff)</li>
                                                    <li>Alcohol pad</li>
                                                    <li>Blood Pressure Tracker or Diary</li>
                                                    <li>Pen</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar text-center">Measuring with an electronic device</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Electronic blood pressure device with cuff and digital gauge</li>
                                                    <li>Blood Pressure Tracker (go to <a href="http://www.heart.org" target="_blank">www.heart.org</a>)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonBloodPressureWhatToDoAndSafety;