import React from "react";

function L82ComponentCode() {

    return (

        <div className="lego l082">
            <div className="contentLeftSide">
                <div className="lego l00paragraph legoMargin3">
                    <p>It may take 2-4 weeks for this procedure to provide you with
                        pain
                        relief. The nerve will regenerate, but this typically takes
                        at least
                        6 months.</p>
                </div>
            </div>
            <div className="contentRightSide">
                <div className="lego l00paragraph legoMargin3">
                    <p>The goals of the (RFA) are to:</p>
                </div>
                <div className="lego l053 legoMargin3">
                    <ul className="w-100">
                        <li className="w-100">Stop or reduce pain.</li>
                        <li className="w-100">Improve function.</li>
                        <li className="w-100">Reduce the number of pain medications
                            taken.
                        </li>
                        <li className="w-100">Avoid or delay surgery.</li>
                    </ul>
                </div>
            </div>
        </div>

    )
};


export default L82ComponentCode;