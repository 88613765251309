import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTL1_1 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Welcome to Your Journey: Welcome to the Journey'}, () => {
            console.log('Welcome to Your Journey: Welcome to the Journey');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entJourneyOrangeColor entJourneyOrangeBorderColor">Welcome
                                                to Your Journey</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="_syHcBLHtxA" opts={opts} onPlay={playVideo} />
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Welcome to the Journey</span></h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Barnes-Jewish Hospital and Washington University Physicians are your
                                                partners in your journey as you plan for and recover from your total
                                                laryngectomy.</p>
                                            <p>This operation will lead to major changes in your life. With this guide,
                                                and many other sources of valuable information and services you can take
                                                advantage of, we will start to prepare you for your new life following
                                                head and neck cancer treatment.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ENT_TG_SP_icons_1.1.svg" className="img-responsive maxWidth300 ml-auto mr-auto icon-1-1" style={{marginBottom: "26px"}}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Exploring expert guidance, key information and advice from patients and
                                                families who have faced similar challenges will help on your road to
                                                recovery.</p>
                                            <p className="boldLeadIn italic">We are here for you at every step.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entJourneyOrangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100 entJourneyOrangeBorderColor" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL1_1;
