import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll4_7 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center">FAQ</h1>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">
                                        General Questions
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">Who should I call for more information, discussions or getting more details on my diagnosis?</p>
                                    <p>If your question involves a life-threatening emergency, dial 911 immediately. You can contact your physician’s office later after the emergency has been addressed.</p>
                                    <p>For all other calls, it is best to start by calling your <a href="/siteman-ent-all-9-1">physician’s office</a> first. They will be able to address your needs or help get you in contact with someone who can.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">Who will be treating me during my course of care?</p>
                                    <p>Your treatment is a team effort led by your physician. He or she will be joined by a team of professionals who combine to give you expert care 24/7. You will have a core team which you will interact with daily. They will collaborate with other <a href="https://siteman.wustl.edu/treatment/cancer-types/head-and-neck/specialist-team/" target="_blank">specialist teams</a> as needed.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">Where can I get additional quality information on my diagnosis? </p>
                                    <p>We recommend visiting <a href="https://headandneck.org/" target="_blank">headandneck.org</a>, the Head and Neck Cancer Alliance, an excellent place for patients and caregivers to learn about the diagnosis and treatment of head and neck cancers. Their site has detailed information about surgical management, chemotherapy and research activities.</p>
                                    <p>Another great source for information is <a href="https://thancguide.org/articles/the-journey/" target="_blank">thancguide.org/articles/the-journey</a>.</p>
                                    <p>The <a href="https://www.cancer.org/" target="_blank">American Cancer Society</a> and the <a href="https://www.cancer.gov/" target="_blank">National Cancer Institute</a> also provide excellent detailed information.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">
                                        Helping Prepare For Surgery
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">What help is available for my family/caregivers?</p>
                                    <p>Siteman Cancer Center has thought about the needs of each step of the experience for you and your family/caregivers. We offer many forms of support and guidance to help you prepare for your surgery, your hospital stay and your recovery afterwards: logistics assistance, guest services, planning help, useful training, nutritional and exercise help, comfort, education, financial guides, community support groups, patient advocacy and many, many more.</p>
                                    <p>A good first step is to check out the information contained in this guide in the <Link to="/cluster/53c38500-cd6b-11ec-bd0a-7dcb213558e3">Support & Resources</Link> section which explains many of the options you have. Or, discuss your needs with your doctor’s office who can refer you to which specific services they feel could best help you.</p>
                                    <p>If your needs involve logistics or guest service questions, you may want to first contact the Concierge Service at Barnes-Jewish Hospital at <a href="tel:3143625194">314-362-5194</a>.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">
                                        Day Of Your Surgery
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">Who can come? What can they do? </p>
                                    <p>Visit our <a href="https://www.bjc.org/coronavirus/News" target="_blank">Coronavirus Information</a> page for the current information and instructions.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">How long will I be in the hospital?<br />
                                    What help is there for my family’s/caregivers’ needs during that time?</p>
                                    <p>Talk with your surgeon’s office prior to your day of surgery to determine the estimated length of your hospital stay (which will vary based on the specifics of your individual procedures). Your actual length of stay will also vary based upon how rapidly you reach certain milestones during your in-patient recovery time, allowing for you to be safely discharged.</p>
                                    <p>Speak with your care team and visit our <a href="https://www.barnesjewish.org/Patients-Visitors/Visiting-Hours" target="_blank">Visiting Information</a> page for the latest information on visitor policies during your stay.</p>
                                    <p>You and your family should also take advantage of the many services and information offered by the <em><strong><a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Barnes Concierge Service</a></strong></em> (reached at <a href="tel:3143625194">314-362-5194</a>). These include:</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Nearby hotel reservations at a discounted rate</li>
                                            <li>Listing of local restaurants</li>
                                            <li>St. Louis entertainment</li>
                                            <li>Books/Newspapers/Magazines</li>
                                            <li>Daily laptop rental</li>
                                            <li>Selection of DVD movies to check out</li>
                                            <li>Assistance with campus directions</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Visit the <Link to="/cluster/53c38500-cd6b-11ec-bd0a-7dcb213558e3">Support & Resources</Link> section of this guide for additional information. </p>
                                    <p>Visit the <Link to="/cluster/64233310-e76e-11ec-abd3-e3d6405138cb">Hospital Info</Link> section of this guide for information on contacts, maps, lodging and other logistic topics.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">What kind of support is available to me and my family?</p>
                                    <p>There are a variety of professionals who are focused on helping you and your family have an excellent experience and outcomes.</p>
                                </div>
                            </div>

                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="row">
                                        <ul className="bulletsCustom">
                                            <li>During your recovery a Nurse Manager and/or Assistant Manager will visit daily to ensure your care is excellent.</li>
                                            <li>Our dedicated Concierge Services are available to all patients and families to help arrange for their needs.</li>
                                            <li>The Office of Patient and Family Affairs is available to address any issues and help connect you to resources and assistance throughout our network.</li>
                                            <li>Social Workers and Case Managers are available upon request; they are a valuable partner in helping you plan for your recovery, your and your family’s well-being, additional treatment resources, connecting to community support and providing guidance step-by-step throughout your experience.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">When will I be discharged? </p>
                                    <p>Your discharge is determined based on your achieving certain milestones in your recovery. Generally you will be discharged when you are not needing IV medication or fluids, your surgical wounds are healing well and any medical issues have been resolved.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">
                                        Post-Stay
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">What help is available for me and my family? </p>
                                    <p>Siteman Cancer Center has thought about the needs of each step of the experience for you and your family. We offer many forms of support and guidance to help you throughout your recovery and getting back to normal.</p>
                                    <p>A good first step is to check out the <Link to="/cluster/53c38500-cd6b-11ec-bd0a-7dcb213558e3">Support and Resources</Link> section of this guide which explains many of the options you have. Or, discuss your needs with your doctor’s office who can refer you to which specific services and/or resources they feel could best fill your needs.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">What can my family do to help?</p>
                                    <p>Be sure to review this online guide, which is made to help them as well on this journey. There is much useful information throughout this guide that families can use to help. In general, some of the best ways family can help include:</p>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                {/*<li>Be sure to review this online guide, which is made to help them as well on this journey. There is much useful information throughout this guide that families can use to help. In general, some of the best ways family can help include:</li>*/}
                                                <li>Knowing their role in your post-procedure care and safety, and helping set up your home to support your success.</li>
                                                <li>Helping you with errands, groceries, cooking, cleaning. During your recovery, you’ll have some specific physical limitations to protect your surgery area; help with some of the chores that require heavy lifting is important.</li>
                                                <li>Moving around the house, taking walks, and generally getting back to normal is key to your fast recovery. Having family and friends join you in this is a great way to aid your recovery.</li>
                                                <li>Family and friends can also help by making themselves informed of the disease and recovery specifics, understanding complications and warning signs and what to do if they appear. Reviewing the care plan with you, including follow-up visits and key contacts, help keep things on-track.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">How often do I see my surgeon? </p>
                                    <p>Your first follow-up visit will usually occur 1 to 2 weeks after you leave the hospital. You may have additional visits after that to monitor your healing progress based on your particular surgery. After you have healed from surgery and finished radiation therapy (if needed in your particular case), you will see the surgeon about 2-3 months later. At that point your surgeon will tell you how often you will be following up for <Link to="/siteman-ent-all-4-2-9">surveillance</Link> visits.</p>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">Is there outside help available?</p>
                                    <p>You are truly not alone. You are surrounded by people and organizations dedicated to your recovery and continued health.</p>
                                    <p>We at your doctor’s office very much want to be kept in the loop on your progress and any emerging needs that you or your family may have. There is a world of available support you and your family should be familiar with. Check out the <Link to="/cluster/9f167490-cd66-11ec-bd0a-7dcb213558e3" >Care at Home</Link> and <Link to="/cluster/53c38500-cd6b-11ec-bd0a-7dcb213558e3">Support and Resources</Link> sections of this guide, as well as the Siteman Cancer Center <a href="https://siteman.wustl.edu/" target="_blank">website</a>. Don’t hesitate to contact your <Link to="/siteman-ent-all-9-1#otolaryngologist">doctor’s office</Link> to discuss forms of support that best fit your needs.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll4_7;
