import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonInhalersWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInhalers">Inhalers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonInhalers/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonInhalers/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonInhalers/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonInhalers/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower"><span className="floatLeft clearLeft">Use a Peak Flow Meter</span></h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <img className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/INH_3.png" alt="" />
                                        <p className="floatNone clearNone col-6">Often a person’s doctor will have him or her use a peak flow meter to decide how well their asthma is under control. A peak flow meter measures how fast air comes out of the lungs when a person breathes out forcefully. This measure is called “peak expiratory flow” or PEF. It is important for a person to know what their PEF is when they are able to breathe relatively normally.</p>
                                        <p className="floatNone clearNone col-6">A person’s PEF might drop hours or even days before asthma problems occur. Regular use of a peak flow meter helps a person recognize early changes that might be signs of worsening asthma. When problems arise and the PEF changes, the person can adjust their medicine (under doctor directions) or take other steps before their symptoms get worse. The National Heart, Lung, and Blood Institute (NHLBI) recommends measuring the PEF during the following times:</p>
                                        <p>The doctor or home health nurse will explain how to know if a person’s asthma is controlled.</p>
                                        <p>Usually they use a system of asthma zones: Green, Yellow and Red.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px">
                                            <h3 className="subheadUpperAndLowerColorBarIcon greenZoneColorBar">Green Zone</h3>
                                        </div>
                                        <p className="floatNone clearNone col-6">The asthma is well controlled. The PEF is 80 to 100 percent of the person’s personal best. Example – if a person’s personal best is 420, the Green Zone is 336 to 420.</p>
                                        <hr className="w-100" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px">
                                            <h3 className="subheadUpperAndLowerColorBarIcon yellowZoneColorBar">Yellow Zone</h3>
                                        </div>
                                        <p className="floatNone clearNone col-6">Asthma is flaring up or poorly controlled. PEF is 50 to 80 percent of a person’s personal best. Example – if a person’s personal best is 420, the Yellow Zone is 210 to 336.</p>
                                        <hr className="w-100" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px">
                                            <h3 className="subheadUpperAndLowerColorBarIcon redZoneColorBar">Red Zone</h3>
                                        </div>
                                        <p className="floatNone clearNone col-6">The asthma is well controlled. Asthma is severe. Need emergency care. The PEF is less than 50 percent of a person’s personal best. Example – if a person’s personal best is 420, the Red Zone is less than 210.</p>
                                        <hr className="w-100" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Help the person keep a daily record of their PEF measurements and report them when they visit their doctor.</p>
                                        <p>If a person is in a <span className="bold">Yellow Zone</span> – he or she needs to adjust medicines according to doctor’s instructions.</p>
                                        <p>If the person is in a <span className="bold">Red Zone – call their doctor immediately</span>.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <img className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/INH_4.png" alt="" />
                                        <p className="captionItalic floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px">Spacer attached to an inhaler</p>
                                        <p className="floatNone clearNone col-6 boldLeadIn">When to Use a Spacer</p>
                                        <p className="floatNone clearNone col-6">A person with poor hand-breath coordination benefits from using a spacer.</p>
                                        <p className="floatNone clearNone col-6">A spacer traps medicine released from an MDI and suspends it in the spacer until the person breathes in. A regular MDI fits easily into the end of a spacer.</p>
                                        <p className="floatNone clearNone col-6">When breathing with a spacer, less medicine deposits on the back of the person’s throat and more reaches the lung’s airways.</p>
                                        <p className="floatNone clearNone col-6">Some spacers have a one-way valve that activates when a person breathes, which removes the need for good hand-breath coordination.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeColorBar">Counting Doses</h3>
                                        <p>A person who uses an inhaler needs to know how to count doses if their device does not have an automatic counter. Failure to do so might result in the person using an empty inhaler when they are having a sudden breathing problem.</p>
                                        <p className="boldLeadIn">To track doses:</p>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom orangeBullets checkBulletsSafeCare">
                                                <li>Have the person take their peak expiratory flow diary with them for each doctor visit.</li>
                                                <li>Let the doctor know if the person has difficulty using the inhaler correctly. Ask the doctor if a different form of the medicine can be given instead.</li>
                                                <li>Let the doctor know if you have noticed any side effects from the medicines the person is inhaling.</li>
                                                <li>Keep notes in your care diary about how the person breathes before and after inhaling a medicine. Show your notes to the doctor.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Troubleshooting</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/INH_5.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>As a caregiver, your job is to be sure the person is taking an inhaled medicine as prescribed. Sometimes a person will either take too much or too little medicine.</p>
                                        <p>Here are common problems you should watch for:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li><span className="bold">Incorrect activation:</span> This occurs when a person presses an MDI canister before taking a breath. Taking a breath and pushing down on the canister should be done at the same time so that the drug carries down to the lungs with the breath.</li>
                                            <li><span className="bold">Forgetting to shake the inhaler:</span> The drug is in a suspension, and therefore particles may settle. If the inhaler is not shaken, it will not deliver the correct dose of the drug.</li>
                                            <li><span className="bold">Not waiting long enough between puffs:</span> The whole process for using an inhaler must be repeated to take a second puff, otherwise an incorrect dose may be delivered, or the drug may not travel deep into the lungs. Usually a person must wait 30 to 60 seconds between doses of the same medicine. When taking two different medicines, take one medicine and then wait 2 to 5 minutes before inhaling the second medicine.</li>
                                            <li><span className="bold">Failure to clean an MDI valve:</span> Particles of medicine may jam up the valve in the canister mouthpiece unless it is cleaned regularly. This causes failure to get 200 puffs from one inhaler.</li>
                                            <li><span className="bold">Failure to observe whether the inhaler is actually releasing a spray:</span> If it is not, this should be checked with the pharmacist.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How to Act in the Event of an Asthma Attack</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Usually a person with asthma will have early warning signs of an asthma attack. Increased wheezing, coughing and tightness of the chest for example, alerts a person to the need to take a dose of their quick-relief medicine by inhaler.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i> However, symptoms can worsen. All of the following symptoms are indications that the person needs to seek emergency care as soon as possible:</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Wheezing while breathing both in and out</li>
                                            <li>Constant coughing</li>
                                            <li>Difficulty breathing</li>
                                            <li>Breathing very fast</li>
                                            <li>Chest Retractions (skin over chest pulls in as the person breathes)</li>
                                            <li>Shortness of breath</li>
                                            <li>Difficulty talking in complete sentences</li>
                                            <li>Becoming pale</li>
                                            <li>Becoming anxious</li>
                                            <li>Blue lips or fingernails called cyanosis</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Be sure the person always keeps track of doses remaining in the inhaler. Have a prescription refilled several days before the last dose.</li>
                                            <li>When flying, a person can take their inhaler with them on Va plane. It is best to carry the inhaler in the prescription box.</li>
                                            <li>If the person is traveling, find out how he or she can get further supplies of their medicines wherever they go, in case the medicines are damaged or baggage goes missing.</li>
                                            <li>The person must never let someone else use their spacer or inhaler. Sharing a device could spread infection.</li>
                                            <li>Always have the person keep their inhalers somewhere where he or she can quickly get it when they need to.</li>
                                            <li>Keep inhalers out of the reach of children.</li>
                                            <li>Store medicine and equipment in a cool, dry area at room temperature.</li>
                                            <li>Always check the expiration dates on the inhalers before use, and do not use one after it has expired.</li>
                                            <li><span className="bold">Seek emergency care for a person when their Peak Expiratory Flow is in the red zone or they show signs of sudden, serious breathing problems.</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        <hr className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Learn how to use nebulizers safely and correctly.</p>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100">
                                        <div className="videoFlexContainer">
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="-2XSFbb_6I4" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Using a Metered Dose Inhaler 07:36
                                                </p>
                                            </div>
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="Pmb_CYoe1y4" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Using a Dry Powdered Inhaler 03:31
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100">
                                        <div className="videoFlexContainer">
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="-2XSFbb_6I4" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Using a Peak Flow Meter 02:24
                                                </p>
                                            </div>
                                            <div className="imgCol noPaddingLeft">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="col-10  col-12 imgCol noPaddingLeft">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Troubleshooting</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/INH_6.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Inhaler device with medicine canister</li>
                                            <li>Spacer device (optional)</li>
                                            <li>Cup of warm water</li>
                                            <li>Facial tissue</li>
                                            <li>Dish soap</li>
                                            <li>Peak Flow Meter</li>
                                            <li>Download and Print Your Daily Care Diary</li>
                                            <li>Peak Expiratory Flow Diary</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInhalersWhatToDoAndSafety;