import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'

const ContentPancreatic13 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Your Care Schedule</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">"DAILY ROUNDS"</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6-730-dailyround.svg" alt=""/>
                                                            <p>6-7:30am</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">MORNING
                                                            </p>
                                                            <p className="mobileMarginBottom30">With Morning Rounds, all of your team (except the attending surgeon) will visit you sometime typically between 6 am and 7:30am.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/attending-physician-dailyround.svg" alt=""/>
                                                            <p>Variable</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">ATTENDING SURGEON
                                                            </p>
                                                            <p className="mobileMarginBottom30">The attending surgeon makes rounds at varying times throughout the day, as they have to schedule their rounds before and after surgeries and clinics.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/3-7-dailyround.svg" alt=""/>
                                                            <p>3-7pm</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">AFTERNOON
                                                            </p>
                                                            <p className="mobileMarginBottom30">The team revisits you during Afternoon Rounds which occur anytime from 3pm to 7pm.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PA-dailyround.svg" alt=""/>
                                                            <p>Constant</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">24/7 CARE
                                                            </p>
                                                            <p className="mobileMarginBottom30">Note that a member of the team is available at all times for questions or concerns not addressed on rounds and for immediate, urgent matters.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">CARE OF DRAINS, TUBES AND INCISIONS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Nasogastric Tube (NGT)</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>This will be taped to your nose; the tape will be changed each day. Notify your nurse if the tape becomes wet. Notify your nurse if the tube is pressing on the inside of your nose.</li>
                                                <li>The tube can be safety-pinned to your gown to help ensure it does not accidentally get pulled out.</li>
                                                <li>Your nurse will assess your nose each shift to ensure no skin breakdown.</li>
                                                <li>Your nurse will measure output at least every 8 hours by emptying the canister on the wall.</li>
                                                <li>This may also be connected to a bag to transition before pulling it out.</li>
                                                <li>It is common for your throat to be sore while the tube is in place.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-3 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nose.jpg" className="img-responsive w-100" />
                                                </div>
                                                <div className="rightCol col-3 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nose2.jpg" className="img-responsive w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Jackson-Pratt (JP/ Blake drain)</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Make sure the drain is always securely attached and is not pulling at your skin. A safety pin will be placed on the bulb tag and then pinned to the inside of your gown.</li>
                                                <li>Your nurse will empty and measure output from drain at least every 8 hours.</li>
                                                <li>Your nurse may take some fluid from the drain to send to the lab. We can measure the level of amylase or bile in order to see if there is a leak.</li>
                                                <li>The dressing around the drain will be changed each day or more often if necessary. Notify your nurse if the dressing becomes wet.</li>
                                                <li>The insertion site will be cleansed each day by your nurse with mild soap and water.</li>
                                                <li>Don’t try to lift anything greater than 10 pounds while the drain is in place.</li>
                                                <li>You may shower while the drain is in place. Remove dressing around the drain before your shower and apply a clean and dry dressing after. Do not apply any creams or lotions around drain insertion site. Just pat dry with a towel after showering.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-3 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/oparation1.jpg" className="img-responsive w-100" />
                                                </div>
                                                <div className="rightCol col-3 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/oparation2.jpg" className="img-responsive w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Foley (Bladder) Catheter</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Make sure to keep the bag off the floor and hanging below your waist at all times.</li>
                                                <li>You can get up and walk around with a Foley in place.</li>
                                                <li>It is okay for you to shower with the Foley in place.</li>
                                                <li>The insertion area will be cleansed each day.</li>
                                                <li>Your nurse will empty the bag and record the output at least every 8 hours.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Central Venous/ Arterial Lines</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>The dressing should remain occlusive (completely covered) and dry at all times. Notify your nurse if the dressing becomes wet.</li>
                                                <li>Cover your dressing before showering or bathing so it does not get wet.</li>
                                                <li>Your nurse will change the dressing at least every 7 days.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Incision</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You may have one or a combination of these four different types of incisions:</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/body_mens.jpg" className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Your outer skin may be closed with staples or skin glue.</li>
                                                <li>The dressing will be removed on Postoperative Day 2. It will be left open to air so long as it is not oozing or draining.</li>
                                                <li>Every other staple may be removed before you are discharged.</li>
                                                <li>You can shower with your incision open.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic13;
