import React from "react";

function L83EComponentCode() {

    return (

        <div className="lego l083 legoMargin3">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_meds.svg"/>
                <img className="overlay dontOverlay"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/dontOverlay.svg"/>
            </div>
            <div className="contentContainer">
                {/* L14 Paragraph */}
                <div className="lego l00paragraph legoMargin3">
                    <p>Certain medications* like antiplatelet and anticoagulants <span
                        className="bold">must be stopped
                                                prior</span> to receiving a spinal injection. <span
                        className="bold italic">Please consult with the
                                                prescribing provider</span> for directions on stopping prior to your
                        injection.
                        In the event your healthcare provider doesn't want medications
                        stopped,
                        contact our office immediately. </p>

                    <p><span className="note">*Ibuprofen, Meloxicam, Diclofenac, etc., Plavix (Clopidogrel), Pradaxa
                                                (Dabigatran), Xarelto (Rivaroxaban), Acenocoumarol, Aggregnox,
                                                Persantine (Dipyridamole), Arixtra (Fondaparinux), Brilinta
                                                (Ticagrelor), Effient (Prasugrel), Fragmin (Dalteparin), Heparin,
                                                Lovenox (Enoxaparin), Pletal (Cilostazol), Savaysa (Edoxaban).</span>
                    </p>
                </div>
                {/* End L14 Paragraph */}

                {/* L53 Bullet List */}
                <div className="lego l053">
                    <ul className="w-100">
                        <li className="w-100"><span className="boldItalic">Do NOT stop any other medication</span> (blood
                            pressure meds, diabetes meds, etc.)
                        </li>
                        <li className="w-100"><span className="boldItalic">Alert our office if you have any history of
                                                        allergies to local anesthetics</span> (such as Novocain or
                            lidocaine).
                        </li>
                        <li className="w-100">If you have an <span className="boldItalic">allergy to X-ray contrast dye</span>,
                            please notify your healthcare provider prior to the procedure.
                        </li>
                    </ul>
                </div>
                {/* End L53 Bullet List */}

            </div>
        </div>

    )
};


export default L83EComponentCode;