import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin6_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorOrange pritikinBorderOrange">Frequently
                                                Asked Questions</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <Accordion className="pritikinFAQ" flush alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Do I qualify?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Yes. If you received this Getting Started Guide, you have a
                                                            qualifying cardiac diagnosis and referral from your
                                                            physician for the Pritikin Intensive Cardiac Rehab (ICR)
                                                            program.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>What is Pritikin Intensive Cardiac Rehab
                                                        (ICR)?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Pritikin ICR is a supervised exercise and lifestyle education
                                                            program that will help you recover from your heart event and
                                                            teach you how to improve your long-term health. It is a
                                                            comprehensive program where you, and your family or support
                                                            person, will work hand-in-hand with a multi-disciplinary
                                                            team of cardiac rehab experts who will create a customized
                                                            program specific to your goals and current health needs.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>How long does the program last? How long does a
                                                        cardiac rehab visit take?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Pritikin ICR is a supervised exercise and lifestyle education
                                                            program that will help you recover from your heart event and
                                                            teach you how to improve your long-term health. It is a
                                                            comprehensive program where you, and your family or support
                                                            person, will work hand-in-hand with a multi-disciplinary
                                                            team of cardiac rehab experts who will create a customized
                                                            program specific to your goals and current health needs.</p>
                                                        <p>Each visit lasts approximately two (2) hours, which includes
                                                            both exercise and education.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>What kind of preparations do I need to do before
                                                        beginning?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>In advance of your first session, you and your cardiac rehab
                                                            team will determine your insurance coverage for the program
                                                            and what costs (if any) are your responsibility.</p>
                                                        <p>Physically, you don’t have to do any special preparation
                                                            before your first session. Your cardiac rehab exercise
                                                            program will be customized to your current physical
                                                            abilities, including any limitations you may have.</p>
                                                        <p>You may also wish to begin considering what your recovery
                                                            goals are and what you would like to achieve and look
                                                            forward to as you feel stronger and healthier. The future is
                                                            bright for a healthier you!</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>What should I expect the first day of
                                                        rehab?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>The primary focus of your first visit will be gathering and
                                                            sharing information. Your cardiac rehab team will meet with
                                                            you to understand how you are doing, what you have been
                                                            through, what your health and life goals are, both while
                                                            you’re participating in the program and long-term.</p>
                                                        <p>Your cardiac team will provide you with an overview of the
                                                            program to make sure you and your family understand the
                                                            process, what to expect, and answer any questions you may
                                                            have. The team will also assess your physical health to best
                                                            customize your individualized exercise and education
                                                            plan.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Is there equipment I need to
                                                        obtain/purchase?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>You don’t need to purchase any equipment. All you need is
                                                            comfortable clothes and well-fitting shoes appropriate for
                                                            exercising.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header>Where does my cardiac rehab take
                                                        place?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Cardiac rehab takes place at a hospital out-patient facility
                                                            or physician’s office that is equipped with exercise and
                                                            monitoring equipment, an education space and emergency
                                                            medical equipment.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header>Who will I work with at my care
                                                        facility?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>You will work with a multi-disciplinary team specially
                                                            trained in cardiac rehab. Teams usually consist of a
                                                            combination of registered nurses, dietitians, exercise
                                                            physiologists, social workers, and respiratory therapists –
                                                            all working under the guidance of a medical director.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header>Do I have to go to every
                                                        session?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Consistent attendance in your cardiac rehab program is very
                                                            important for your optimal recovery and to assure the best
                                                            long-term outcomes.</p>
                                                        <p>Each session you attend leads to tangible progress. Your
                                                            physician has prescribed cardiac rehab because it has a
                                                            proven, positive effect that will speed your recovery and
                                                            decrease your likelihood of setbacks, including further
                                                            cardiac issues and being readmitted to the hospital.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="9">
                                                    <Accordion.Header>Are there ways to make up missed
                                                        sessions?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>If you must miss a visit due to health or scheduling issues,
                                                            your cardiac team will schedule your make-up visit for
                                                            another day that works for you.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="10">
                                                    <Accordion.Header>How difficult is the rehab? What if I have
                                                        physical challenges?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>The goal of cardiac rehab is to help you safely recover from
                                                            your cardiac event by gradually progressing from your unique
                                                            beginning point. This means that any physical limitations
                                                            and medical requirements you face will be considered from
                                                            the very start.</p>
                                                        <p>Your team will be able to customize an exercise plan
                                                            specifically for you. The equipment used during cardiac
                                                            rehab sessions are designed to accommodate a wide range of
                                                            physical abilities. Your heart and your cardiovascular
                                                            system can be safely strengthened, regardless of any
                                                            physical limitations.</p>
                                                        <p>A member of the cardiac rehab team will closely monitor your
                                                            heart, how your body responds to exercise, and how you are
                                                            feeling throughout the exercise session.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="11">
                                                    <Accordion.Header>Can my family participate in some ways?
                                                        Is my family welcome at the facility during my
                                                        rehab?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>In general, a spouse, loved one, or other caregiver involved
                                                            in your recovery is encouraged to participate in your
                                                            education sessions whenever possible. The goal is to help
                                                            your family be fully engaged and knowledgeable about your
                                                            cardiac rehab program, including understanding the key
                                                            components and recongnizing how they can help support
                                                            you.</p>
                                                        <p>Speak with your cardiac rehab team on their specific policies
                                                            regarding family members being allowed nearby during
                                                            exercise sessions. Those rules will vary by-location and may
                                                            at times be affected by factors like changing Covid-19
                                                            restrictions.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="12">
                                                    <Accordion.Header>How much time should I
                                                        schedule?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>A good rule of thumb is to plan for a two-hour window, two or
                                                            three days a week, over the course of approximately three
                                                            months. Talk with your cardiac rehab team on the specific
                                                            times they offer sessions to find the time(s) that best fit
                                                            your weekly schedule.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="13">
                                                    <Accordion.Header>Does insurance cover this cardiac
                                                        rehab?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>This answer will vary based on your insurance policy and its
                                                            coverage plan. You will work with the cardiac rehab team in
                                                            advance of your first visit to understand what is covered by
                                                            insurance and what costs (if any) will be your
                                                            responsibility.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="14">
                                                    <Accordion.Header>Why is this cardiac rehab — Pritikin ICR — best
                                                        for me?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Pritikin ICR is a comprehensive lifestyle education program
                                                            that goes beyond exercise alone, giving you ways to create
                                                            positive outcomes for your future. In addition to your
                                                            individual exercise plan, you will also receive practical,
                                                            real-world education on nutrition and how to prepare healthy
                                                            meals. You’ll learn how your mind-set can help your
                                                            recovery, including setting goals, overcoming challenges,
                                                            and planning how to best achieve long-term success on the
                                                            things that matter most to you.</p>
                                                        <p>Consistently attending your cardiac rehab sessions also
                                                            improves your recovery in other ways. You can think of your
                                                            cardiac rehab team as the eyes and ears of your physician:
                                                            they will be able to see and track your progress and health
                                                            day to day, and they will keep your physician in the loop on
                                                            how you are doing. This helps your doctor make sure your
                                                            care plan is working and helps them adjust it whenever
                                                            needed to best help your recovery.</p>
                                                        <p>The members of your cardiac rehab team work as your health
                                                            advocates; they are constantly focused on how to help you
                                                            achieve the health and life goals you have set and find
                                                            answers to any questions you may have along your
                                                            journey.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="15">
                                                    <Accordion.Header>How personalized is the program to
                                                        me?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Your exercise plan will be personalized to your physical
                                                            abilities and goals. The education will cover core concepts
                                                            important for cardiac patients, as well as other topics that
                                                            may be of relevance to you based on your goals, diagnosis,
                                                            or specific challenges. You will also have the opportunity
                                                            to meet one-on-one with the registered dietitian and other
                                                            staff members to further tailor your program to your unique
                                                            lifestyle.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="16">
                                                    <Accordion.Header>Will I participate in cardiac rehab alone, or in a
                                                        group?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p>Cardiac rehab occurs in a group setting, for both exercise
                                                            and education sessions. Your group will be made up of other
                                                            individuals who have also had a recent heart event. In
                                                            addition, you may have the opportunity to participate in
                                                            one-on-one nutrition and/or health coaching sessions.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin6_0;