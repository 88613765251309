import React from 'react';
import Container from 'react-bootstrap/Container';
import YouTube from 'react-youtube';
import '@fortawesome/fontawesome-svg-core/styles.css'

const SitemanPancreaticRamps1_1 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-lg-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Understanding Your RAMPS
                                                Procedure</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="headline">UNDERSTANDING THE RAMPS PROCEDURE</h2>
                                            <p>This video explains the RAMPS procedure, which is performed to remove
                                                cancer from the tail of the pancreas.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Video: <em>Understanding the RAMPS Procedure</em>
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="esl-aMkr-eM" opts={opts}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <img className="floatLeft wrapImage w-100 clearLeft"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Procedure_caption1.jpg"
                                                 alt="Diagram of Upper Abdomen"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p>The organs of your upper abdomen work as a system.</p>
                                            <p>The esophagus <strong>(1)</strong> carries food from your mouth into the
                                                stomach <strong>(2)</strong>, which leads to the
                                                intestine <strong>(3)</strong>. The liver <strong>(4)</strong> produces
                                                bile that is stored in the gallbladder <strong>(5)</strong>. When you
                                                eat a meal, the bile travels down the bile duct <strong>(6)</strong>,
                                                which runs behind the head of the pancreas <strong>(7)</strong> and
                                                joins with the pancreatic duct and drains into the intestine.</p>
                                            <p>The pancreas is shown in yellow and sits behind the stomach. It has two
                                                functions. First, it has cells that secrete hormones like insulin that
                                                regulate your blood sugar. Second, it has other cells that make enzymes
                                                that get secreted into the pancreatic duct and mix with the bile to help
                                                you digest food, in particular fatty foods.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            {/*<h2 className="headline">Details of the Whipple Procedure.</h2>*/}
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Procedure_RAMPS.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p>When a tumor is in the body or tail of the pancreas (black circle), a
                                                Left-Sided or Distal Pancreatectomy is performed. The pancreas is
                                                divided in a similar fashion over the portal
                                                vein <strong>(A)</strong> and the body and
                                                tail of the pancreas are removed, along with the surrounding lymph nodes
                                                (green line).</p>
                                            <p>Many of these lymph nodes live along the splenic artery and
                                                vein <strong>(B)</strong> and
                                                the tumor often involves these structures. Since the
                                                spleen <strong>(C)</strong> derives
                                                its blood supply from these vessels and because the spleen is often
                                                involved with cancer, it is generally removed as part of this
                                                procedure.</p>
                                            <p>Since this is an extensive procedure that involves the surrounding soft
                                                tissue and lymph nodes, so as to ensure the entire tumor is removed, we
                                                call this type of Distal Pancreatectomy/ Splenectomy
                                                a <strong>R</strong>adical <strong>A</strong>ntegrade
                                                <strong>M</strong>odular <strong>P</strong>ancreatico-<strong>S</strong>plenectomy,
                                                or RAMPS procedure.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">WHAT CAN I EAT AFTER HAVING THE SURGERY?</h2>
                                            <p>There is no restriction of your diet after the operation and your
                                                recovery process is completed. In six months time, most patients find
                                                they can eat normally. However, immediately post-surgery you may find
                                                you can only tolerate small, frequent meals. This is common.</p>
                                            <p>Some patients may not tolerate very sweet foods or high-fat foods and may
                                                need to avoid these. <strong>Staying hydrated is critical. Drink plenty
                                                    of water when you get home.</strong> Make a habit of drinking plenty
                                                of water in the weeks after your surgery. Be sure to always avoid
                                                alcohol for 3 months after your surgery or until your surgeon says
                                                differently.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">WHAT ARE THE COMPLICATIONS THAT ARE LIKELY TO
                                                HAPPEN IMMEDIATELY AFTER SURGERY?</h2>
                                            <p>In the hands of surgeons who are experienced with this surgical operation
                                                such as ours the complication rate is usually very low. The problems and
                                                complications that may be seen after this operation include:</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Pancreatic Fistula:</p>
                                            <p>After the tumor is removed from the pancreas, the cut end of the pancreas
                                                is sutured back into the intestine so that pancreatic juices can go back
                                                into the intestine. The pancreas is a very soft organ and in some
                                                patients this suture line may not heal very well. If this happens then
                                                patients develop leakage of pancreatic juice. Usually the surgeon leaves
                                                behind a drainage catheter in the abdomen during the surgery. Any
                                                leakage of pancreatic juice after the surgery is usually removed from
                                                the body by this drainage catheter. In almost all patients who develop
                                                leakage of pancreatic juice after the surgery, the leakage heals on its
                                                own.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Gastroparesis (paralysis of the stomach):</p>
                                            <p>For the first five to six days after the surgery, you will be provided
                                                with intravenous fluids until your bowel function returns. After your
                                                bowel function returns, your surgeon will begin you on a diet of clear
                                                liquids and your diet will progress to a regular diet as you tolerate
                                                it.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">WHAT ARE THE LONG-TERM COMPLICATIONS OF THE
                                                RAMPS OPERATION?</h2>
                                            <p>Some of the long-term consequences of the Whipple operation include the
                                                following:</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Mal-absorption:</p>
                                            <p>The pancreas produces enzymes required for digestion of food. In some
                                                patients, removal of part of the pancreas during the RAMPS operation can
                                                lead to a diminished production of these enzymes. Patients complain of
                                                bulky diarrhea type of stool that is very oily. Long-term treatment with
                                                oral pancreatic enzyme supplementation usually provides relief from this
                                                problem.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Alteration in Diet:</p>
                                            <p>After the RAMPS operation, we generally recommend that patients ingest
                                                smaller meals and snack between meals to allow better absorption of the
                                                food and to minimize symptoms of feeling of feeling bloated or getting
                                                too full.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Loss of Weight:
                                            </p>
                                            <p>It is common for patients to lose up to 10% of their body weight compared
                                                to their weight prior to their illness. The weight loss usually
                                                stabilizes very rapidly and most patients, after a small amount of
                                                initial weight loss, are able to maintain their weight and do well.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Diabetes:
                                            </p>
                                            <p>When pancreatic tissue is removed, the body releases less insulin and the
                                                risk of developing diabetes is present. Our experience has been that
                                                patients who are diabetic at the time of surgery or who have an abnormal
                                                blood sugar level that is controlled on a diet prior to surgery have a
                                                high chance for the severity of the diabetes becoming worse after the
                                                surgery. On the other hand, patients who have completely normal blood
                                                sugar prior to surgery with no history of diabetes and do not have
                                                chronic pancreatitis have a low probability of developing diabetes after
                                                the RAMPS operation.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticRamps1_1;
