import React from "react";
import Tile from '../../components/tile';
import Cluster from '../../components/cluster';
import {ONE, THREE, TWELVE, TWO} from "../tile/constants.js";
import {
    EIGHT, EIGHTEEN,
    ELEVEN,
    FIFTEEN,
    FIVE,
    FOUR,
    FOURTEEN,
    NINE,
    SEVEN, SEVENTEEN,
    SIX, SIXTEEN,
    TEN,
    THIRTEEN
} from "../cluster/constants.js";

function ClusterDemo() {
    console.log('we made it this far - 1');
    return (
        <div className="App">
            {
                [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((cluster) => {
                    let outerContainerCss = [];
                    outerContainerCss.push('hidden-sm');
                    outerContainerCss.push('hidden-xs');
                    outerContainerCss.push('container');
                    console.log('Here is the cluster value');

                    switch (cluster) {
                        case ONE: return (
                            <React.Fragment>
                                <h1>Cluster 1</h1>
                                <Cluster
                                    clusterType={ONE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 5', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 6', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 7', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 8', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 9', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case TWO: return (
                            <React.Fragment>
                                <h1>Cluster 2</h1>
                                <Cluster
                                    clusterType={TWO}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case THREE: return (
                            <React.Fragment>
                                <h1>Cluster 3</h1>
                                <Cluster
                                    clusterType={THREE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 3,
                                            cols: 1,
                                            tileType: ONE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 3,
                                            cols: 1,
                                            tileType: ONE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 3,
                                            cols: 1,
                                            tileType: ONE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case FOUR: return (
                            <React.Fragment>
                                <h1>Cluster 4</h1>
                                <Cluster
                                    clusterType={FOUR}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 3,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 3,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case FIVE: return (
                            <React.Fragment>
                                <h1>Cluster 5</h1>
                                <Cluster
                                    clusterType={FIVE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case SIX: return (
                            <React.Fragment>
                                <h1>Cluster 6</h1>
                                <Cluster
                                    clusterType={SIX}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case SEVEN: return (
                            <React.Fragment>
                                <h1>Cluster 7</h1>
                                <Cluster
                                    clusterType={SEVEN}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case EIGHT: return (
                            <React.Fragment>
                                <h1>Cluster 8</h1>
                                <Cluster
                                    clusterType={EIGHT}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case NINE: return (
                            <React.Fragment>
                                <h1>Cluster 9</h1>
                                <Cluster
                                    clusterType={NINE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case TEN: return (
                            <React.Fragment>
                                <h1>Cluster 10</h1>
                                <Cluster
                                    clusterType={TEN}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            removePadding: true
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            removePadding: true
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case ELEVEN: return (
                            <React.Fragment>
                                <h1>Cluster 11</h1>
                                <Cluster
                                    clusterType={ONE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 5', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 6', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 7', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case TWELVE: return (
                            <React.Fragment>
                                <h1>Cluster 12</h1>
                                <Cluster
                                    clusterType={TWELVE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 3,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 5', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 6', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 7', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case THIRTEEN: return (
                            <React.Fragment>
                                <h1>Cluster 13</h1>
                                <Cluster
                                    clusterType={THIRTEEN}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            removePadding: true
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            removePadding: true
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            removeOuterContainer: false
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case FOURTEEN: return (
                            <React.Fragment>
                                <h1>Cluster 14</h1>
                                <Cluster
                                    clusterType={ONE}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 2,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case FIFTEEN: return (
                            <React.Fragment>
                                <h1>Cluster 15</h1>
                                <Cluster
                                    clusterType={FIFTEEN}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 2,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case SIXTEEN: return (
                            <React.Fragment>
                                <h1>Cluster 16</h1>
                                <Cluster
                                    clusterType={SIXTEEN}
                                    useLeftRight={true}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            useRight: true,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            useLeft: true,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case SEVENTEEN: return (
                            <React.Fragment>
                                <h1>Cluster 17</h1>
                                <Cluster
                                    clusterType={SEVENTEEN}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 2,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 5', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )

                        case EIGHTEEN: return (
                            <React.Fragment>
                                <h1>Cluster 18</h1>
                                <Cluster
                                    clusterType={EIGHTEEN}
                                    useLeftRight={true}
                                    tileAttributes={[
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 1', numberOfItems: 5},
                                            rows: 2,
                                            cols: 2,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            useRight: true,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 2', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            useLeft: true,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain1'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 3', numberOfItems: 5},
                                            rows: 1,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        },
                                        {
                                            attributes: {lineOneText: 'One Line of Text', lineTwoText: 'Next Line 4', numberOfItems: 5},
                                            rows: 2,
                                            cols: 1,
                                            tileType: THREE,
                                            outerContainerCss: outerContainerCss,
                                            chain: 'chain2'
                                        }
                                    ]}
                                />
                            </React.Fragment>
                        )
                    }

                })

                /*
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23, 24].map((tileType) => {
                    return (
                        <div>
                            <h1 style={{textAlign: 'center', color: '#ffffff'}}>{'T_0' + (tileType <= 9 ? '0' + tileType : tileType)}</h1>
                            {[1,2,3].map((col) => {
                                    return ([1,2,3].map((row) => {
                                        let outerContainerCss = [];
                                        outerContainerCss.push('hidden-sm');
                                        outerContainerCss.push('hidden-xs');
                                        outerContainerCss.push('container');

                                        return (
                                            <div style={{paddingBottom: 30}}>
                                                <h1 style={{textAlign: 'center'}}>{'T_0' + (tileType <= 9 ? '0' + tileType : tileType) + '_' + col + 'x' + row}</h1>
                                                <Tile
                                                    attributes={{lineOneText: 'One Line of Text', lineTwoText: 'Next Line', numberOfItems: 5}}
                                                    rows={row}
                                                    cols={col}
                                                    tileType={tileType}
                                                    outerContainerCss={outerContainerCss}
                                                />
                                            </div>
                                        )
                                }))
                            })}
                        </div>
                    )
                })
                 */
            }
            <header className="App-header">
                <p>
                    Edit <code>src/App.js</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
        </div>
    );
}

export default ClusterDemo;
