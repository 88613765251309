import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog.js";

const SitemanENTTL2_3 = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Your Cancer Removal: Total Laryngectomy Procedure'}, () => {
            console.log('Your Cancer Removal: Total Laryngectomy Procedure');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">What is a total laryngectomy?</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="">
                                            <h3 className="blueColor videoTitle w-100 text-center">Total Laryngectomy Procedure</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="INPzdqgiLU8" opts={opts} onPlay={playVideo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A total laryngectomy is the removal of your larynx (voice box). Your larynx is located at the upper end of your trachea (windpipe).</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer topContainer4images">
                                                <div className="leftCol paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Before_Laryngectomy_266x300.jpg" className="img-responsive laryngectomyImages" />
                                                    <div className="imgCaption text-center" style={{ marginBottom: 30 }}>
                                                        <strong><em>Before laryngectomy</em></strong></div>
                                                </div>
                                                <div className="rightCol paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/After_Laryngectomy_266x300.jpg" className="img-responsive laryngectomyImages" />
                                                    <div className="imgCaption text-center" style={{ marginBottom: 30 }}>
                                                        <strong><em>After laryngectomy</em></strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>After surgery, you will have an opening in your neck called a stoma. This is the upper end of your windpipe (trachea) which is now a permanent opening in the neck (stoma).</p>
                                            <p>You will now breathe ONLY through your stoma, not through your nose and mouth.</p>
                                            <p>This is a new way of breathing sometimes referred as “neck breathing.”</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">With this new way of breathing, you can now expect:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                <li>Windpipe might become dry since you are not breathing through your nose.</li>
                                                <li>Reduced smell because you will no longer be able to inhale odors through your nose.</li>
                                                <li>Reduced taste because this is affected by your sense of smell.</li>
                                                <li>You will not be able to blow air through your nose.</li>
                                                <li>Because your voice box has been removed, you will learn a new way to communicate. For
                                                    more information go to <a href="/siteman-ent-tl-4-2-4">Communicating</a>.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader text-dark text-center floatLeft w-100">The Day of Surgery</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground text-center">Check-in</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>Check in at the surgery waiting area on the main floor of Barnes-Jewish Hospital south campus. If you need directions, visit the information desk located on the main level.</li>
                                                    <li>After you check-in, you will go through hospital registration. Upon completion, your care team member will take you to the pre-surgery holding area.</li>
                                                    <li>The surgical receptionist will give your family member or friend a beeper to allow us to update them about your surgery.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground text-center">Before going into surgery</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>We will review your medications and health history with you.</li>
                                                    <li>We will start an IV in your arm. If you were instructed by your surgeon to keep one of your
                                                        arms free of IVs, please remind the nurse.</li>
                                                    <li>Your anesthesiologist will talk about the most appropriate anesthetic for you and the plan to best manage your pain after surgery.</li>
                                                    <li>Your surgeon will meet with you to review the surgical plan and mark where the surgery will occur.</li>
                                                    <li>You will meet with the nursing staff who will be caring for you in the operating room.</li>
                                                    <li>Before going to the operating room, you will be given medication for comfort.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground text-center">During your surgery</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>Throughout your surgery your surgical team will monitor your breathing, heart rate and blood pressure.</li>
                                                    <li>A catheter, used to drain urine from your bladder, may be placed during your surgery.</li>
                                                    <li>Updates about your progress will be given to the person who holds the beeper in the surgical waiting room.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground text-center">Right after surgery</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>Usually, you will wake up in the recovery room when your surgery is complete.</li>
                                                    <li>Specially-trained nurses and an anesthesiologist will monitor you. They will keep you safe
                                                        and comfortable while you wake up from anesthesia.</li>
                                                    <li>Your care team of specialists will be with you during your recovery. After surgery, patients will be transferred to an observation unit or intensive care unit after being monitored in the post-op area.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader text-center text-dark w-100">Communicating after a laryngectomy</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Find more details on communicating after your surgery <a href="/siteman-ent-tl-4-2-4">here</a>.</p>
                                            <p>Because your voice box has been removed, you will communicate in new ways in the hospital after your surgery. The nurses will provide paper and pen to write on during your hospitalization. There are some other communication options which you can bring to the hospital if you wish.</p>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">These include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                <li>Pencil and paper</li>
                                                <li>Reusable writing tablet (e.g. a dry erase board or a Boogie® board; some patients purchase one
                                                    and bring it with them to the hospital.)</li>
                                                <li>Picture boards</li>
                                                <li>Programs for smartphones (e.g. Text to Speech)</li>
                                                <li>Gestures</li>
                                                <li>Later: electrolarynx or speaking prosthesis. Under the guidance of your SLP, you may learn communication with an electrolarynx, esophageal speech, or tracheoesophageal speech.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder entDiagnosisSurgeryRedBorderColor">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn text-center">How long will it take to regain speech?</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>There is no definite answer; every person is different and the answer depends on how you heal.</p>
                                                <p>If no unexpected complications and no prior radiation, most patients can begin using an electrolarynx while in the hospital. </p>
                                                <p className="mb-0">You will see a Speech Pathologist before your surgery, while you are in the hospital, and after hospital discharge. They will teach and support you along the way.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Examples of speech options after total laryngectomy:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li><a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/TEP+voice.m4a">Tracheoesophageal speech</a></li>
                                                <li><a target="_blank" href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Electrolarynx+voice.m4a">Electrolarynx speech</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL2_3;
