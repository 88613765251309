import {ORBIT_CLUSTER} from "./types.js";

export const setOrbitCluster = (value) => ({
    type: ORBIT_CLUSTER,
    payload: {
        tileAttributes: value.tileAttributes,
        clusterType: value.clusterType,
        useFlexContainer: value.useFlexContainer,
        clusterCssArray: value.clusterCssArray
    }
})
