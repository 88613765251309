import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fab, fas, far);
const LessonInfectionWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInfection">Infection Control</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Need to Know</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10303.jpg" className="img-responsive maxWidth300 floatNone" />
                                        <p className="captionItalic text-center w-100">When caring for a person, always look for possible signs of infection.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">A Local Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A local infection is one limited to a specific body site, and stays there. An example is an infection of a surgical wound, an abscessed tooth, or a boil.</p>
                                        <p className="boldLeadIn">The signs of a local infection include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The person will complain of pain at the site</li>
                                            <li>When you lightly press your hand or fingertips over the area, it will be tender to the person</li>
                                            <li>The site will appear reddened and possibly swollen</li>
                                            <li>The site will feel warm to the touch</li>
                                            <li>An open wound or cut might weep yellowish or greenish drainage</li>
                                            <li>The person might have a fever - 100.5 degrees Fahrenheit (oral) or higher, or a rectal or ear temperature above 101.5 degrees or higher</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Wound Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>First look at the person’s wound.</li>
                                            <ul className="bulletsCustom chevronRightBulletsSafeCare">
                                                <li>Has the appearance changed since you last looked at it?</li>
                                                <li>Is the skin around the wound edges reddened or swollen?</li>
                                                <li>Is there any yellowish or greenish drainage from the wound?</li>
                                                <li>Does the person tell you there is discomfort when you remove the dressing?</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p><strong><em>Answer ‘YES’ to any of these questions</em></strong> and the person could be <strong><em>developing an infection</em></strong>.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Urine Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Whenever you are caring for someone who is ill, make it a habit to look at their urine after they go to the bathroom. Ask the person to not flush the toilet before you get a chance to look.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>If a person has a urine infection the urine will be cloudy, dark yellow in color, and foul smelling.</li>
                                            <li>The person may have pain and burning when passing urine.</li>
                                            <li>Older adults who develop a urine infection will often <span className="bold italic">become confused or more sleepy</span> before any other signs appear.</li>
                                            <li>An elevated temperature may or may not be present.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10302.jpg" className="img-responsive maxWidth300 floatNone" />
                                        <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Cold, Sinus Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Systemic infections (like the flu) are very serious. The infection has spread to affect the entire body. The body’s defenses go into action, which may cause some or all of the following:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Look at the mucus. Normally it is clear, white or light yellow.</li>
                                            <li>Yellowish or greenish mucus is a sign of infection.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">A Systemic Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Systemic infections (like the flu) are very serious. The infection has spread to affect the entire body. The body’s defenses go into action, which may cause some or all of the following:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Fever</li>
                                            <li>An uneasy feeling of weakness</li>
                                            <li>Fatigue</li>
                                            <li>Enlarged and tender lymph nodes (may be in the neck, groin, under arm pits)</li>
                                            <li>Loss of appetite</li>
                                            <li>Nausea and vomiting</li>
                                            <li>Muscle aches</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you suspect a systemic infection, take the person’s temperature.The normal range for body temperature is 96.8<sup>º</sup> to 100.4<sup>º</sup> Fahrenheit, or 36<sup>º</sup> to 38<sup>º</sup> Centigrade.</p>
                                        <p>A single temperature reading does not indicate a fever. Several temperature readings at different times of the day tell you if the person has a fever. Generally, a fever is above 100.4 degrees Fahrenheit (oral) (38<sup>°</sup> Centigrade), or a rectal or ear temperature above 101.4 degrees Fahrenheit (38.5<sup>°</sup> Centigrade).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim" ><i className="fa fa-exclamation-circle hightLight redColor"></i>Most doctors will not treat an adult’s fever until <span className="bold italic">it is over 102.2 degrees Fahrenheit</span> (39<sup>°</sup> Centigrade). However, call the doctor for any <span className="bold italic">fever that lasts longer than 48 to 72 hours</span>, especially in older adults.</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow centered"><i className="fa fa-exclamation-circle"></i>Sometimes an Infection is Not Obvious</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>People who have diseases of their immune system (e.g. cancer, lupus, or rheumatoid arthritis) or who are taking drugs that suppress the immune system, often do not have classic signs of infection. The same is true with older adults.</p>
                                        <p>These people will often not have signs of redness and swelling with a local infection. Often they have no fever. Older adults may only have a mild temperature increase or no increase at all. Infection may be shown by <span className="bold italic">changes in behavior</span> such as:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Fatigue</li>
                                            <li>Loss of appetite</li>
                                            <li>Increased weakness</li>
                                            <li>Difficulty walking or completing daily activities</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer blueBackground">
                                    <div className="w-100">
                                        <p>
                                            <a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP
                                                <i className="fa fa-chevron-up">&nbsp;</i>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInfectionWhatYouNeedToKnow;