import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonPainWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Pain: Show Me How'}, () => {
            console.log('Pain: Show Me How');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center width400">
                                                        <p><Nav.Link className="" href="/LessonPain">Recognizing and Relieving Pain</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What To Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonPain/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonPain/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonPain/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonPain/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/PN_5.1.png" className="img-responsive floatNone maxWidth300" />
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <p className="mediumBlueColor">The biggest challenge you will face is knowing when a person has pain and then deciding what to do. Your ability to help the person you care for depends on recognizing pain.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Common Signs of Pain</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Pain usually affects how a person speaks, looks, moves, and acts around others. When you see the signs of pain, ask the person if he or she is in pain.</p>
                                                <p className="boldLeadIn">Signs of pain include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Moaning, crying, gasping, or grunting</li>
                                                    <li>Shallow breathing or short breaths</li>
                                                    <li>Grimacing, clenching teeth, biting lips, tightly closing or widely opening the eyes and mouth</li>
                                                    <li>Not wanting to talk, a reduced attention span, not wanting to be around others, loss of appetite</li>
                                                    <li>Trouble sleeping (either difficulty falling asleep or waking up early)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Signs of Pain in Persons Who do Not Speak</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Persons with dementia, severe stroke, mental retardation, or psychosis are often not able to report their pain. Being able to recognize pain means you must know the person well. Changes in behavior and body movements are the signs of pain, including:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Grimacing, clenching teeth, biting lips, tightly closing or widely opening the eyes and mouth</li>
                                                    <li>Shallow breathing or short breaths</li>
                                                    <li>Restless movement, muscle tension, increased hand and finger movements, pacing, holding or rubbing a body part, or being unwilling to move</li>
                                                    <li>Trouble sleeping</li>
                                                    <li>Aggressive behavior</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/PN_5.2.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Learn as Much as You Can</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If a person develops pain, you want to learn as much as you can so that you can inform their doctor. This is very important with acute or chronic pain that suddenly changes in character. Many people, especially those with a heart problem may not say they have pain, but will use words like “pressure”.</p>
                                                <p className="boldLeadIn">When a person is in pain, ask them these questions:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>On a scale of 0 to 10 with 0 being no pain and 10 being the worst pain you ever had, tell me what your pain feels like now.</li>
                                                    <li>It helps to know a person’s personal pain scale. Ask them, “What is the lowest level of pain you have had? What is the highest level of pain you have had?” Knowing this will help you decide how well their pain medicine is working and whether or not you need to call their doctor.</li>
                                                    <li>Ask the person, “Show me where your pain is.” (Have the person point to the area.) Then clarify, for example, “Do you mean your stomach or your chest? Your arm or wrist?”</li>
                                                    <li>Ask the person how long he or she has felt the pain. When did it start?</li>
                                                    <li>Ask the person to describe the pain. Do not describe it for them. “Tell me what the pain feels like. Is it dull, sharp or achy?”</li>
                                                    <li>Ask the person to describe anything that makes the pain worse, such as lying down, sitting, or chewing.</li>
                                                    <li>Ask the person if there is anything that lessens the pain, such as lying down, taking an analgesic, or eating food.</li>
                                                    <li>Watch how the person acts or behaves (for example, bends over, holds a body part, bends the knees up towards the chest while lying down).</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Keep a record in your care diary and write down all of the information so that you can report it to the person’s doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How to Act</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The nature of a person’s pain will determine what you should do. Always remember that if you have any doubt at all about a person’s pain and the person’s condition, call their doctor or emergency medical services (EMS).</p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead16px"><i className="fa fa-exclamation-circle coralColorMargin"></i>&nbsp;If a person develops sudden, acute pain, is in distress, and has not had the same type of pain in the past, call 911.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Do not transport the person in your car.</p>
                                                <p>If the pain is serious, as in the case of a heart attack, the person will be treated in the ambulance. You cannot give treatment in your car and the person might become worse as you drive to the hospital. People don’t always feel the need to call 911. Listen to your instincts. You could risk their life by not calling emergency services.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">If a person has acute pain</p>
                                                <p>If a person has acute pain or the same as pain in the past (e.g. back pain, pain from a muscle sprain or tear, pain from surgery), first see if it is time to take any pain medicine ordered by their doctor. If so, give the medicine. Provide a quiet environment. Then, keep an eye on the person after giving the medicine, and if there is no relief within 30 minutes call their doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">The doctor will order the specific times when it is safe to give pain medicine.</p>
                                                <p>Pain medicine taken regularly around-the-clock (ATC) is best because it keeps medicine at the same level in the body at all times. Sometimes pain is not constant and does not occur regularly. In this case, the doctor may order medicine “as needed” or PRN.</p>
                                                <p>For example, the doctor may order the pain medicine to be given “every 4 hours as needed for pain”. You should not give the medicine any sooner than every 4 hours. But, you may not need to give it every 4 hours, depending on the pain level of the person you are caring for.</p>
                                                <p><span className="bold italic">Remember to give pain medicine to the person you care for at the right time.</span></p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/PN_5.3.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Adjusting the Environment</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Whether a person has acute or chronic pain, there are things you can do in addition to giving them their pain medicine that will help in lessening their pain.</p>
                                                <p className="boldLeadIn">These include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Give the pain medicine at the right time so the pain can be controlled.</li>
                                                    <li>Ask the person what you can do to help him or her relax.</li>
                                                    <li>Help the person position in bed, making him or her as comfortable as possible.</li>
                                                    <li>Darken the bedroom and remove any noises.</li>
                                                    <li>Remove or loosen any tight-fitting clothing.</li>
                                                    <li>If the person likes music, turn on a song the person finds soothing or relaxing. Have the person tell you how loud to set the sound.</li>
                                                    <li>Sometimes distraction takes a person’s mind off the pain. See if they want to talk, read a story from the newspaper or magazine, watch television or meditate.</li>
                                                    <li>Offer to just sit with them; perhaps join in prayer.</li>
                                                    <li>Help coach the person through relaxation exercises.</li>
                                                    <li>When a person has chronic pain, they usually have found activities that lessen their pain. Assist them with these activities (i.e. soaking in a warm bath, massage to the area, etc.)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li><span className="bold">Give medicine at the right time and the right amount to the right person. Write this down in your care diary.</span></li>
                                                    <li>Always give a pain medicine <span className="bold italic">at the time ordered by the doctor</span>. In the case of a PRN medicine (as needed), give medicine within the time window ordered. For example, a doctor may order the following: Give Tylenol with codeine, 1 tablet, every 4 to 6 hours for pain. A person might have taken the last tablet at 3 pm, so that means the next tablet can be taken as soon as 7pm or late as 9 pm. However, if a person is having pain, do not wait those extra two hours between 7 and 9. Give the tablet sooner than later. Also, if a person is not having pain, you do not have to give the medicine until he or she needs it.</li>
                                                    <li>Call the person’s doctor if you do not think that the pain medicine is working.</li>
                                                    <li><span className="bold">Severe acute pain can mean a medical emergency. If the pain is new or different and the person is in distress; call emergency medical services – 911.</span></li>
                                                    <li>Have a person regularly use assist devices (as ordered by their doctor), such as a cane or walker.</li>
                                                    <li>If a person has more than one doctor prescribing medicine for the same type of painful condition, this can be dangerous. Always have the person’s primary medical doctor know what pain medicines the person is taking.</li>
                                                    <li>Be aware that older adults are usually prescribed a lower dose of medicine because they are more sensitive to it. If the person you are caring for becomes confused or begins acting differently, call their doctor.</li>
                                                    <li>Always keep the contact information for your doctor and emergency services close by.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Recognizing and Relieving Pain
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="O7zdilH68wg" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Pillows</li>
                                                    <li>Pain medicine as ordered by doctor with glass of water</li>
                                                    <li>Music player (optional)</li>
                                                    <li>Reading materials (optional)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonPainWhatToDoAndSafety;
