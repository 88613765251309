import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonDrainageWhatToTellYourDoctor = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDrainage">Care of Drainage Bags</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell the Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li><strong>Call the doctor</strong> if the person you provide care for develops a fever (100.5° Fahrenheit or 38.0° Centigrade or higher using oral thermometer), or according to doctor’s guidelines.</li>
                                                    <li><strong><em>Call the doctor when these changes occur:</em></strong></li>
                                                    <ul className="bulletsCustom chevronRightBulletsSafeCare greenBullets">
                                                        <li>Change in drainage: either a sudden decrease or increase in drainage or drainage stops suddenly</li>
                                                        <li>Bright red drainage</li>
                                                        <li>Increasing redness, swelling, or tenderness at the drain insertion site or the nearby wound</li>
                                                        <li>Presence of a foul odor at the drain insertion site or nearby wound</li>
                                                        <li>Drainage from the drain insertion site or the wound</li>
                                                        <li>The person’s wound appears open or bigger</li>
                                                        <li>The drain falls out</li>
                                                        <li>The drain tubing is leaking</li>
                                                        <li>The vacuum fails to work, in applicable devices. (An air leak can cause vacuum failure)</li>
                                                    </ul>
                                                    <li>If you are unsure of changes in the wound or the drainage, call the doctor or home health nurse. <strong><em>Do not wait until their next scheduled appointment.</em></strong> Your doctor may prescribe an antibioticor he or she may want to see you in the office. If you see early signs of an infection or poor wound healing <strong><em>waiting until your scheduled appointment may make the situation worse.</em></strong> Early intervention can reduce the effect of any complications.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100 text-center">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer greenBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDrainageWhatToTellYourDoctor;
