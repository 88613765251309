import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonMouthCareWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonMouthCare">Mouth Care</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonMouthCare/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonMouthCare/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonMouthCare/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonMouthCare/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Daily Mouth Care</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/MC_daily_care_linear.jpg" className="img-responsive floatNone" />
                                        <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Brush teeth twice a day with an ADA-accepted fluoride toothpaste and a soft-bristled brush.</li>
                                            <li>To Brush the teeth correctly:</li>
                                            <ul className="bulletsCustom checkBulletsSafeCare bulletsSafeCareIndent">
                                                <li>Hold the bristles at a 45 degree angle to the gum line. Bristles should touch both the tooth surface and the gum line</li>
                                                <li>Brush the outer surfaces of teeth by moving the bristles in a back and forth rolling motion from the gum to the tip of each tooth. (See diagram A)</li>
                                                <li>Use the same motion to brush the inner teeth surfaces. Tilt the brush vertically at a 45 degree angle behind the front teeth.</li>
                                                <li>Also brush gently across the teeth in a back and forth motion. (See diagram B)</li>
                                                <li>Clean the chewing surfaces of the teeth by holding the top of the bristles parallel with the teeth and brushing gently back and forth. (See diagram C)</li>
                                                <li>Brush the tongue to remove germs</li>
                                            </ul>
                                            <li>Replace a toothbrush:</li>
                                            <ul className="bulletsCustom checkBulletsSafeCare bulletsSafeCareIndent">
                                                <li>Every three or four months... or</li>
                                                <li>If the bristles of the toothbrush become worn or frayed.</li>
                                            </ul>
                                            <li>Clean between the teeth daily with floss or an interdental cleaner.</li>
                                            <li>Use a fluoride mouth rinse and fluoride toothpaste to provide extra protection against tooth decay over that provided by fluoride toothpaste alone.</li>
                                            <li>Have the person you care for visit a dentist regularly (most recommend every 6 months)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Check the Mouth</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When you brush a person’s teeth, be sure to check the inside of the person’s mouth daily. Know what the person’s mouth looks like. A check of the mouth can lead to the early identification of oral cancer.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/MC_choking.png" className="img-responsive floatNone maxWidth300" />
                                        <p className="captionItalic text-center w-100">View of open mouth sowing tongue, teeth and soft palate</p>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Signs and Symptoms of Oral Cancer:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Before and after mouth care, ask the person to open their mouth and stick out their tongue. Look into the mouth, using a flashlight. If you see crusts on the tongue or lining of the mouth and debris around the teeth, give mouth care more often.</li>
                                                    <li>A sore of the mouth that bleeds easily or does not heal</li>
                                                    <li>A red or white patch anywhere in the mouth</li>
                                                    <li>A lump, thickening, rough spot, crust or small eroded area</li>
                                                    <li>Pain, tenderness, or numbness anywhere in the mouth or on the lips</li>
                                                    <li>Difficulty chewing, swallowing, speaking or moving the jaw or tongue</li>
                                                    <li>A change in the way the teeth fit together</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If the Person has Crusts or Reddened Gums</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Brush teeth twice a day with an ADA-accepted fluoride toothpaste and a soft-bristled brush.</li>
                                            <li>To brush the teeth correctly:</li>
                                            <ul className="bulletsCustom bulletsSafeCareIndent">
                                                <li>Before and after mouth care, ask the person to open their mouth and stick out their tongue. Look into the mouth, using a flashlight. If you see crusts on the tongue or lining of the mouth and debris around the teeth, give mouth care more often.</li>
                                                <li>If the person’s gums or lining of the mouth are reddened, tender and bleed easily, give mouth care more often and think about a visit to the dentist, if the signs persist. When a person takes medicines that thin the blood (such as Coumadin or Heparin), the person’s gums may bleed more easily.</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If the Person has a Dry Mouth</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Offer water to sip regularly</li>
                                            <li>Try over-the-counter saliva substitutes</li>
                                            <li>Urge the person to breathe through their nose, not their mouth</li>
                                            <li>Add moisture to the air at night with a room humidifier</li>
                                            <li>Sugar-free candy or gum stimulates flow of saliva</li>
                                            <li>Artificial saliva and oral rinses (available in drug stores) can be soothing</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If the Person has Stomatitis
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Tell them to avoid drinking alcohol</li>
                                            <li>Do not use commercial mouthwash</li>
                                            <li>Have the person stop smoking</li>
                                            <li>Brush the teeth frequently throughout the day, using a soft bristled toothbrush</li>
                                            <li>Talk to the person’s doctor about using any mouth rinses and prescribing a mild pain medicine or a local anesthetic rinse</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If a Person is on Chemotherapy</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Cancer patients who receive chemotherapy often have dry mouths and swelling and tenderness of the gums. Use a toothbrush with soft bristles due to increased tenderness and chance of bleeding.</p>
                                        <p className="boldLeadIn">To offer relief:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Rinse the mouth every three hours during the day</li>
                                            <li>Rinse with a mixture of:</li>
                                            <ul className="bulletsCustom bulletsSafeCareIndent">
                                                <li>1 cup warm (not hot) water</li>
                                                <li>¼ teaspoon baking soda</li>
                                                <li>1/8 teaspoon salt</li>
                                            </ul>
                                            <li>Take sips and swish around in the mouth</li>
                                            <li>Keep the lips moist with a lip balm like ChapStick<sup>&reg;</sup></li>
                                            <li>Suck ice chips, other frozen liquids, or solutions recommended by their doctor</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If a Person Can’t Handle a Toothbrush</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a person loses the ability to hold a toothbrush easily:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Provide a brush with an enlarged handle</li>
                                            <li>Electric toothbrushes have larger handles</li>
                                            <li>Another option is to take a soft rubber ball, make a hole in the center, and push the toothbrush handle through the ball. The ball is easy to grasp and allows the person to brush their teeth on their own.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/MC_toothbrush_emlarged_handle.png" className="img-responsive floatNone maxWidth300" />
                                        <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If a Person Begins to Choke</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Certain people have a greater risk for choking because they cannot swallow normally. Water and toothpaste build up in the mouth quickly.</p>
                                        <p className="boldLeadIn">Persons most at risk for choking include those with:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Stroke</li>
                                            <li>Cerebral palsy</li>
                                            <li>Guillain-Barre’ syndrome</li>
                                            <li>Multiple sclerosis (MS)</li>
                                            <li>Amyotrophic Lateral Sclerosis (Lou Gehrig’s disease)</li>
                                            <li>Loss of sensation from diabetes</li>
                                            <li>Parkinson’s disease</li>
                                            <li>Myasthenia gravis</li>
                                            <li>Muscular dystrophy</li>
                                            <li>Obstructions in the throat (such as head and neck cancer, esophageal webs)</li>
                                            <li>Vagotomy</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Signs of Difficulty Swallowing</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Persons who have difficulty swallowing are at risk for choking. Watch the person you care for eat a meal.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Does their voice change?</li>
                                            <li>Do they only eat part of each meal?</li>
                                            <li>Do they drool liquids and chewed food or cough during or after a swallow?</li>
                                            <li>Do they pocket food and saliva in the side of mouth?</li>
                                            <li>Do they take a long time to eat?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What to Do if Choking Occurs</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>If the person begins to choke while you give mouth care, stop brushing or rinsing</li>
                                            <li>Have the person spit out secretions and sit up straight. If the person is lying down, have the person stay on their side with their face turned down toward the mattress.</li>
                                            <li>If you have a gravy baster, use it to remove excess saliva or toothpaste. Squeeze the bulb, insert the tip of the baster into the back of the mouth, release the bulb to remove secretions, then remove the baster from the mouth. <span class="bold">Do Not Squeeze the bulb when secretions are in the baster</span>.</li>
                                            <li><span class="bold">If choking continues, and the person cannot talk or has trouble breathing call 911</span></li>
                                            <li>While waiting for emergency services, perform the <span class="bold">Heimlich Maneuver</span></li>
                                            <li>See our lesson on <a href="/LessonFeedingAPerson" target="_blank">Feeding a Person</a> for steps to follow in performing a Heimlich Maneuver.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Ask the person to whom you are giving mouth care not to bite down on your fingers</li>
                                            <li>If a person has trouble swallowing and seems to hold excess saliva in his or her mouth, use caution. This person is at risk for aspiration (choking on saliva). You will take steps to prevent choking in the way you have the person positioned and holding their mouth during brushing. (<a href="#showMeHow">See Show Me How</a>.)</li>
                                            <li>Wear a pair of clean, disposable gloves to lessen the chance of germs in the person’s spit getting on your hands</li>
                                            <li>Never share a toothbrush with a family member; there is a risk you could get an infection</li>
                                            <li>Never drink directly from a bottle of mouthwash. Germs will be left behind in the bottle.</li>
                                            <li>Talk to the person’s doctor about using fluoride. A fluoride mouth rinse is not recommended for children age six or younger because they may swallow the rinse.</li>
                                            <li>Always brush the teeth gently to prevent bleeding of the gums.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Learn how to provide good mouth care.</p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="z03U-jWz8DY" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Basic Mouth Care</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/MC_equipment_bedridden.png" className="img-responsive floatNone maxWidth300" />
                                        <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Soft bristled toothbrush. An even rounded brush with soft multi-tufted nylon bristles is best.</li>
                                            <li>Fluoride toothpaste</li>
                                            <li>Essential oil-containing mouthwash</li>
                                            <li>A glass of water and straw</li>
                                            <li>A small face towel</li>
                                            <li>Paper towels</li>
                                            <li>A bathroom sink or if in bed, use a shallow bowl or small plastic emesis (eM- ah- sis) basin (you can buy at a medical supply store)</li>
                                            <li>Disposable gloves</li>
                                            <li>A gravy baster (if the person has trouble spitting or swallowing)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Brushing Teeth of Bedridden Person</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Soft bristled toothbrush. An even rounded brush with soft multi-tufted nylon bristles is best. If the person does not respond well and you have trouble reaching the teeth, use mouth swabs you can purchase at the medical supply store.</li>
                                            <li>Fluoride toothpaste</li>
                                            <li>Essential oil-containing mouthwash</li>
                                            <li>Two glasses of water and a straw</li>
                                            <li>A small face towel</li>
                                            <li>Paper towels</li>
                                            <li>A bathroom sink or if in bed, use a shallow bowl or small plastic emesis (eM- ah- sis) basin (you can buy at a medical supply store)</li>
                                            <li>Disposable gloves (you can buy at a medical supply store)</li>
                                            <li>A gravy baster (if the person has trouble spitting or swallowing)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonMouthCareWhatToDoAndSafety;