import React from "react";

function L3AComponentCode({title}) {
    title = 'We are committed to the highest quality of care and patient safety';

    return (

        <div className="lego l003">
            <h2 className="subHeadCaptalizedItalic">{title}</h2>
        </div>

    )
};


export default L3AComponentCode;