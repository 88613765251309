import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTGSP2_6 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideoImportanceOfAskingQuestions = async (e) => {
        const pal = await videoAccessLog({videoName: 'Discharge: Importance of Asking Questions'}, () => {
            console.log('Discharge: Importance of Asking Questions');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Discharge</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Planning your discharge</h3>
                                            <p>Our goal is to get you back to your normal life safely. As your recovery
                                                progresses, your entire care team will be together to discuss when it is
                                                safe for you to leave the hospital. Most patients leave the hospital
                                                around 5 to 10 days after surgery. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Together the team will discuss:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                <li>Your medical concerns</li>
                                                <li>Your support resources</li>
                                                <li>Your needs going home</li>
                                                <li>Where you will be discharged to</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn mb-4">It is vital that your caregiver comes to the hospital
                                                while you are here. He or she should learn how to care for your surgical
                                                wounds, assist with nutrition, and identify wound complications.</p>
                                        </div>
                                    </div>


                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Pre-Discharge Education Class</p>
                                            <p>Before you are discharged, you and your caregiver will be expected to attend an education class which will be taught by one of our nurses. In this class we will go over all of your specific surgical wounds and needs, and also answer any questions you may have. Ask your nurse for more information on the times for this education class.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Day of Discharge</h3>
                                            <p>You will be discharged around noon. </p>
                                            <p>Your nurse will bring your discharge information from your doctor. Your
                                                nurse will review all instructions with you. If you do not understand
                                                any discharge information, please ask as many questions as you like so
                                                you are confident about what to do when you get home.</p>
                                            <p>Your nurse will bring you detailed discharge instructions. If you have a
                                                tracheostomy or feeding tube, you will receive detailed instructions on
                                                this as well. </p>
                                            <p>If being discharged to home, you can expect a home health nurse to come
                                                to your house within the first two days after discharge. The nurse will
                                                typically make home visits a couple times a week for no more than a
                                                couple hours each visit.</p>
                                            <p>Supplies will be delivered to your home and/or your hospital room. Your
                                                nurse will also send you home with enough supplies to get you through
                                                the first couple of days. These supplies are sometimes delivered to your
                                                hospital room prior to your discharge. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer noBtmMargin">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground">You
                                                will need someone with you at home 24/7 when you leave the
                                                hospital.</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon flexContainer noWrap"><i
                                                className="fa fa-exclamation-circle fa-2x hightLight redColor iconTextVerticalAlign"></i>&nbsp;&nbsp;<span
                                                >If you are having surgery on the inside of your mouth or throat, you may need to go home with a tracheostomy. If this is the case, you will need to have someone at home with you 24/7 until cleared by your surgeon (usually at your first follow-up appointment).</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon flexContainer noWrap"><i
                                                className="fa fa-check-circle fa-2x hightLight redColor iconTextVerticalAlign"></i>&nbsp;&nbsp;<span>Please have a caregiver selected before your scheduled surgery. They will need to accompany you to the hospital and be present for discharge education.</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom colorAccentRedBullets">
                                                    <li>Most patients will be able to be discharged to their home.</li>
                                                    <li>If you are not strong enough to function at home, or unable to
                                                        care for your new needs (tracheostomy, tube feeds), arrangements may be
                                                        made for you to go to an extended care facility for further
                                                        recuperation.
                                                    </li>
                                                    <li>If you have any questions about discharge instructions or wound
                                                        care after leaving the hospital, please contact the ENT Clinic
                                                        Number at 314.362.7509 during
                                                        working hours (8 am - 5 pm, M-F).
                                                    </li>
                                                    <li>After hours or on weekends, call 314.362.5000 and ask for the
                                                        “ENT resident on call.”
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="V50Lt2yetOk" opts={opts} onPlay={playVideoImportanceOfAskingQuestions}/>
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Importance of Asking
                                                    Questions</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>We will work with you to determine your care needs at home for a safe
                                                recovery and your options for continuing therapy after you leave.</p>
                                            <p>If you are going home, you will be transported in a wheelchair to the
                                                patient pick-up at the Barnes-Jewish Hospital south campus main
                                                entrance. Someone can meet you there to pick you up. </p>
                                            <p>If you are going to a rehabilitation facility, your social worker or case manager will
                                                arrange transportation for you.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center floatLeft">Follow-Up</h3>
                                            <p>You will return to your surgeon’s clinic 1 to 2 weeks after your
                                                discharge date. Your wounds will be checked and stitches/staples/drains
                                                may be removed at this visit.</p>
                                            <p>If you have had surgery on your mouth or throat, you may be scheduled for
                                                a swallow study and to see a speech language pathologist to see if you
                                                are ready to start eating by mouth again. You may need additional
                                                swallow therapy and healing time before starting to eat again.</p>
                                            <p>You may be referred to see other cancer specialists if needed at this
                                                time.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon"><a href="/siteman-ent-all-4-7"><i
                                                className="fa fa-file-o fa-2x hightLight redColor"></i>&nbsp;&nbsp;<span>Frequently Asked Questions At Discharge</span>&nbsp;
                                                <i className="fa fa-chevron-right"></i></a></h2>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor"
                                                  href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP2_6;
