import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonMovingIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving: Hear the Expert'}, () => {
            console.log('Moving: Hear the Expert');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonMoving">Moving Safely in the Home</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation ">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonMoving/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonMoving/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>All of us benefit from exercise and being able to move about to stay healthy. Sometimes you will care for someone who has trouble moving about on their own. This person is at risk for falling if not given the right type of help.</p>
                                                <p>Being able to walk through one’s home and moving from a bed to a chair or wheelchair without injury, makes a person feel safe. There are many things that can make moving about in the home hard to do:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Pain</li>
                                                    <li>Poor balance</li>
                                                    <li>Weakness</li>
                                                    <li>Loss of movement or strength in a leg</li>
                                                    <li>Surgery of the hip or legs</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you care for an older adult or a person who is seriously ill, you need to know how to reduce their risk of injury. Also, you need to know how to protect yourself from injury when you help someone else. It is important to learn the proper ways to assist them and make their home a safe place to live.</p>
                                            <p>This lesson will show you how to assist a person in walking and transferring safely from a bed to a chair.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="qSjq4WCh2yE" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonMovingIntroduction;
