import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll4_2_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Handling Side Effects</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center floatNone">What are the Side Effects of Treatment?</h2>
                                            <p>Treatment for head and neck cancer may cause a number of side effects during and after treatment. Some of them can last for weeks, months, or even years into your survivorship. </p>
                                            <p>You, and family members helping you, should learn about these possible side effects and how to best manage them. As always, if you ever have side effects or symptoms that are difficult to manage on your own, please talk with your care team about interventions that may help. </p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder lightBlueColorBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2>What to Watch For:</h2>
                                                <p><strong><em>Note:</em></strong> You should always report any side effects to your doctor or nurse, and discuss how to deal with them.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom lightBlueColorBorder">
                                                    <li>Surgery for head and neck cancers often changes your ability to chew, swallow, and/or talk. You may look different after surgery, and your face and neck may be swollen. The swelling usually goes away within a few weeks. However, if lymph nodes are removed, the flow of lymph in the area where they were removed may be slower and lymph could collect in the tissues, causing additional swelling; this swelling may last for a long time.</li>
                                                    <li>After surgery in the neck, parts of the neck and throat may feel numb because nerves have been cut. If lymph nodes in the neck were removed, your shoulder and neck may become weak and stiff. </li>
                                                    <li>After receiving radiation to the head and neck you may experience redness, irritation, and sores in your mouth; a dry mouth or thickened saliva; difficulty in swallowing; changes in taste; or nausea. </li>
                                                    <li>During radiation treatment you may experience a loss of taste, which may decrease your appetite and affect nutrition.</li>
                                                    <li>You may experience earaches caused by the hardening of ear wax. </li>
                                                    <li>You may also notice some swelling or drooping of the skin under your chin and changes in the texture of your skin.</li>
                                                    <li>Your jaw may feel stiff, and you may not be able to open your mouth as wide as before treatment.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><i className="fa fa-check blueColor"></i>&nbsp;<a target="_blank" href="https://www.cancer.gov/about-cancer/treatment/side-effects">Learn more about managing some of the possible side effects caused by head and neck cancer treatment here ></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop blueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll4_2_2;
