import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonAfterSurgeryWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonAfterSurgery">Care After Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell the Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Be sure the person’s primary doctor has been notified of the surgery and any medicines ordered by the surgeon.</li>
                                            <li>The first time the person goes back to see the surgeon or doctor, take your care notes with you.</li>
                                            <p className="bold italic">Let the doctor know:</p>
                                            <ul className="bulletsCustom greenBullets chevronRightBulletsSafeCareIndent">
                                                <li>How well the person is eating and sleeping.</li>
                                                <li>The first time the person goes back to see the surgeon or doctor, take your care notes with you.</li>
                                                <li>How well the person is following their exercise plan and any restrictions to their activity. Is the person exercising as often as ordered? Is the person having pain from the exercises?</li>
                                                <li>What you have observed with looking at the wound or changing the dressing.</li>
                                                <li>The amount of pain medicine the person is taking, how often he or she is taking pain medicine, and if he or she is asking for pain medicine frequently before the time it is due.</li>
                                                <li>If the person does not begin to have regular bowel movements or if the person has changes in the amount of urine he or she is passing.</li>
                                            </ul>
                                            <li>A number of signs can indicate problems after surgery. These guidelines are not meant to take the place of the person’s discussion with their own surgeon.</li>
                                            <ul className="bulletsCustom greenBullets chevronRightBulletsSafeCareIndent">
                                                <li>The person still has pain (unrelieved) an hour after taking their pain medicine (medicine may not be strong enough).</li>
                                                <li>The person becomes very sleepy or groggy within 30 minutes after taking a pain medicine. The medicine may be too strong.</li>
                                                <li>The person has continuous nausea or vomiting.</li>
                                                <li>There is redness, swelling, bleeding or pus like drainage coming from the person’s surgical wound. See our lesson on <a href="/LessonInfection" target="_blank">Infection Control</a>.</li>
                                                <li>The wound opens up, stitches pull out, edges of skin separate.</li>
                                                <li>The person has chills or a fever (100.5 degrees Fahrenheit [oral], or 101.5 degrees Fahrenheit [rectal or ear] or higher).</li>
                                                <li>Person notices tingling, numbness, swelling, or bluish fingers or toes (signs of reducedcirculation).</li>
                                                <li>Persistent cough or shortness of breath.</li>
                                                <li>Person is unable to eat or drink liquids.</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonAfterSurgeryWhatToTellYourDoctor;