import {postRequest} from "../../src/api/index.js";
import {FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_ASSIGN_USER, CLEAR_ERROR} from "../../src/actions/types.js";

export const forgotPassword = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await postRequest(`/user/forgotpassword`, "user", data);

        if (res.status === 200) {
            cb({
                payload: {status: 'success'}
            });
        } else if (res.data.status === 'otp_expiration') {
            cb({
                errorMessage: 'Email OTP Expired. Please check your email again for the new one time password.'
            });
        } else {
            if (res.status !== 200) {
                cb({
                    errorMessage: res.data.errors.map((error) => error + '\n\n').toString()
                });
            } else {
                cb({
                    errorMessage: 'You entered an invalid OTP.  Please try again.'
                });
            }
        }
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                cb({
                    errorMessage: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                cb({
                    errorMessage: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            cb({
                errorMessage: err.message
            });
        } else if (err) {
            cb({
                errorMessage: err.message
            });
        } else {
            // Some other errors
            cb({
                errorMessage: "Something went wrong."
            });
        }
    }
};
