import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL88Component({ code, language }) {
    code = `{/* L88 List with Background Color */}
                                    <div className="lego l088 legoMargin5">
                                        <div className="contentContainer">
                                            {/* L53 Bullet List */}
                                            <div className="lego l053 smallFont">
                                                <ul className="w-100">
                                                    <li className="w-100">You do NOT need to stop any other medication
                                                        (blood pressure meds, diabetes meds, etc.).
                                                    </li>
                                                    <li className="w-100">Alert our office if you have any history of
                                                        allergies to local anesthetics (such as Novocain or lidocaine).
                                                    </li>
                                                    <li className="w-100">If you have an allergy to X-ray contrast dye,
                                                        please notify your healthcare provider prior to the procedure.
                                                    </li>
                                                    <li className="w-100">OK to eat and drink prior to the procedure.
                                                    </li>
                                                </ul>
                                            </div>
                                            {/* End L53 Bullet List */}
                                        </div>
                                    </div>
                                    {/* End L88 List with Background Color */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL88Component;