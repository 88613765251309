import React from "react";

function L66ComponentCode() {

    return (

        <div className="lego l066">
            <hr className="horizonalRule"/>
        </div>

    )
};


export default L66ComponentCode;