import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL87Component({ code, language }) {
    code = `{/* L87 Table 5 GrayScale */}
                                    <div className="lego l087 legoMargin5">
                                        <h4 className="legoMargin2">Dianogstic Injection</h4>
                                        <table className="table table-bordered legoTableStyle2">
                                            <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>NOT Improved</th>
                                                <th>Improved a Little</th>
                                                <th>Improved a Little More</th>
                                                <th>Improved a LOT</th>
                                                <th>NO PAIN</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Immediately</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            <tr>
                                                <td>2 hours AFTER</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            <tr>
                                                <td>4 hours AFTER</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            <tr>
                                                <td>6 hours AFTER</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            <tr>
                                                <td>12 hours AFTER</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            <tr>
                                                <td>24 hours AFTER</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            <tr>
                                                <td>36 hours AFTER</td>
                                                <td>0%</td>
                                                <td>25%</td>
                                                <td>50%</td>
                                                <td>75%</td>
                                                <td>100%</td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>

                                    {/* End L87 Table 5 GrayScale */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL87Component;