import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonOxygenFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center width400">
                                                <p>
                                                    <Nav.Link className="" href="/LessonOxygen">Giving Oxygen</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonOxygen/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonOxygen/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How do I know if there is enough oxygen left in a system?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Talk with the home oxygen provider (HOP) about the timetable for your system. The amount of time will vary by the size of a cylinder or type of device. For example, a large tank when full, will provide enough oxygen for over 100 hours. A smaller tank will provide oxygen for a maximum of about 20 hours. To avoid running out of oxygen, reorder your new supply when the register reads ¼ full. A person’s ordered flow rate will affect how long an oxygen system lasts.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Can I ever give a person too much oxygen?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Yes, in fact with some conditions, giving more oxygen will remove the stimulus for a person to breathe. Always give oxygen at the flow rate prescribed by the person’s doctor. Do not adjust the flow rate on your own.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Can the person I care for travel with oxygen?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The ability to travel depends on the person’s general health and tolerance to exercise and stress. Traveling with oxygen takes planning. Talk with your doctor and HOP.</p>
                                        <p>Sometimes there is a need to adjust the amount of oxygen the person receives, especially if traveling to different altitudes. Consider how long the person will be gone, the method of travel (car, plane or bus), and how long it will take to get there. If the person plans to fly, you must have a statement from the doctor saying the person needs oxygen to fly. Learn about the airlines guidelines for traveling with oxygen.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What should I do if the person becomes hypoxic?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you think the person you are caring for has signs of hypoxia, notify the person’s doctor immediately. The doctor may adjust the amount of oxygen the person is receiving.</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon subhead26px"><i className="fa-solid fa-circle-exclamation"></i>If you find the person on oxygen is unresponsive, very confused, and showing respiratory distress (breathing very rapidly and with much difficulty) call 911.</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 twoSideBars">
                                <div className="w-100">
                                    <div className="w-100">
                                        <div className="w-100">
                                            <div className="sidebarNoFill sidebarNoFillborderColor withSideIcon marginRight w-100">
                                                <img className="img-responsive maxWidth75" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo.png" />
                                                <p className="resourcePartner"><strong>National Lung Health Education Program (NLHEP)</strong></p>
                                                <p>The National Lung Health Education Program (NLHEP), was initiated in 1996 as a new healthcare initiative designed to increase awareness of Chronic Obstructive Lung Disease (COLD) among the public.</p>
                                                <p className="resourceLink">visit: <a href="http://www.nlhep.org/" target="_blank">www.nlhep.org</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <div className="w-100">
                                            <div className="sidebarNoFill sidebarNoFillborderColor withSideIcon marginRight w-100">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logoats.jpg" />
                                                <p className="resourcePartner"><strong>American Thoracic Society</strong></p>
                                                <p>The society’s members include more than 15,000 physicians, research scientists, nurses and other allied healthcare professionals. The mission is to improve health worldwide by advancing research, clinical care and public health.</p>
                                                <p className="resourceLink">visit: <a href="http://www.thoracic.org/" target="_blank">www.thoracic.org</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonOxygenFAQResources;