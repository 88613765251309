import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll8_1 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Chemotherapy</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="w-100">Siteman Cancer Center has multidisciplinary teams with subspecialties in all types of cancer and areas of treatment. Medical oncologists treat patients using chemotherapy approaches, but work as a team with other types of practitioners, such as radiation and surgical oncologists, and other specialists and support services, to ensure that you are treated first as a patient and appropriately for your specific cancer type and subtype, even down to the genetic level. </p>
                                            <p className="w-100" style={{marginBottom: 40}}>The depth and breadth of the expertise at Siteman gives you treatment options not available in other hospitals in the region.</p>
                                            <h2 className="subheadUpperandLower centered w-100">What is Chemotherapy</h2>
                                            <p className="w-100">Chemotherapy is a type of medical treatment for cancer that uses drugs to destroy or slow the growth of cancer cells. While the term chemotherapy is associated in most people’s minds with intravenous or systemic cancer treatment (chemo), it refers to any drugs that are given to fight cancer, including biologics/immune therapy, targeted therapy and medications that are given by different routes: orally, topically in the form of creams and ointments, regionally (confined to the area of the tumor) or systemically via an intramuscular injection or intravenously through a vein. </p>
                                            <p className="w-100">Chemotherapy’s effect can be diffuse, targeted to a specific receptor on a cancer cell, or act as a vaccine.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn w-100">Depending on the type and extent of the cancer, chemotherapy can:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                                <li>Be curative, killing all the cancer cells.</li>
                                                <li>Control your cancer, almost like a chronic disease.</li>
                                                <li>Ease cancer symptoms by shrinking tumors causing pain or distress.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>It can be given alone, several different types together, or in conjunction with surgery or radiation. </p>
                                            <p>Chemotherapy can be given before cancer surgery (neoadjuvant therapy) to shrink a tumor and make it easier to remove. Some combinations of chemotherapy and radiation (chemoradiation) can enhance the treatment effects of both.</p>
                                            <p>Because cancer cells grow and divide quickly, they can be targeted with drugs. Other cells in the body that grow quickly, such as cells in the mouth and intestine, can also be affected causing side effects. Some of these effects resolve after therapy is over. </p>
                                            <p>Your oncologist’s office will have information on the expected side effects of specific chemotherapy drugs.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="sidbarFill sidebarFillBackgroundColor entAdditionalTherapiesSecondaryGreenBackgroundColor">
                                                <h2 className="subheadUpperAndLower withBodyCopy">Clinical Trials</h2>
                                                <p>Some promising medical therapies: chemotherapy, immunotherapy and biologic therapies are available only in clinical trials. Siteman Cancer Center has the largest number of clinical trials in the region, which means you have access to therapies not otherwise available.</p>
                                                <p className="mb-0">For more information, visit <a href="https://siteman.wustl.edu/treatment/clinical-trials/" target="_blank">Clinical Trials ></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll8_1;
