import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL12BComponent({ code, language }) {
    code = `{/* L12B? Content 1 side 70% width */}
                  <div className="lego l012B p-0">
                    <div className="contentLeftSide">
                      {/* L11 Image */}
                      <div
                        className="lego l011 legoMargin3"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <img
                          className="imageW100 noBorderRadius maxWidth300"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/9162560e08f725f61b013117564ddfe3.jpeg"
                        />
                      </div>
                      {/* End L11 Image */}
                    </div>
                    <div className="contentRightSide">
                      <div className="legoMargin5">
                        <p>
                          “At the{" "}
                          <span className="boldText">
                            Washington University Living Well Center
                          </span>{" "}
                          we're changing the way healthcare is delivered. With a
                          multidisciplinary team of experts, we will provide you
                          with comprehensive care and treat the root cause of
                          your problem — not just the symptoms — with the
                          principles of lifestyle medicine. 
                        </p>
                        <p>
                          We offer you your ideal range of coordinated services
                          to treat the whole you, including nutritional
                          counseling, physical therapy, behavioral health
                          counseling, and more. 
                        </p>
                        <p>
                          And, we will make sure those services are available in
                          ways that can fit your health and wellness needs, your
                          lifestyle, your schedule and, in cases where insurance
                          may not cover all services, your budget.”
                          <br />
                        </p>
                        <p>
                          <span className="boldSignature">
                            {" "}
                            Dr. Devyani Hunt
                          </span>
                          <br />
                          <span className="boldSignatureTitle">
                            {" "}
                            Medical Director, Living Well Center
                          </span>
                          {" "}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* End L12B? Content 1 side 70% width */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL12BComponent;