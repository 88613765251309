import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin_CHI_7_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderDarkBlue">Your
                                                Cardiac Rehab Team</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Team_Illu_Prit_horz.jpg"
                                                className="img-responsive centerImage w-100 desktopImage"
                                                alt="Team Heart Image" style={{maxWidth: '900px',}}/>
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Team_Illu_Prit_mobile.jpg"
                                                className="img-responsive centerImage w-100 mobileImage"
                                                alt="Team Heart Image"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="w-100 text-center">Your Cardiac Rehabilitation Team is
                                                comprised of:</p>
                                            <div className="twoColumnBulletList subheadAndBullets">
                                                <div className="columnOneBullets">
                                                    <ul className="bulletsCustom">
                                                        <li>Registered Nurses specially trained in Cardiopulmonary
                                                            Care
                                                        </li>
                                                        <li>Registered Respiratory Therapists
                                                        </li>
                                                        <li>Exercise physiologists</li>
                                                        <li>Registered Pharmacists</li>
                                                    </ul>
                                                </div>
                                                <div className="columnTwoBullets">
                                                    <ul className="bulletsCustom">
                                                        <li>Registered Dietitians</li>
                                                        <li>Occupational Therapists</li>
                                                        <li>Physical Therapist</li>
                                                        <li>Physicians</li>
                                                        <li>And most importantly, <span
                                                            className="italic"><strong>YOU!</strong></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100 topZero"/>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <div className="teamBiosList">*/}
                                    {/*            <div className="leftCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="rightCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="teamBiosList">*/}
                                    {/*            <div className="leftCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="rightCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="teamBiosList">*/}
                                    {/*            <div className="leftCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="rightCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="teamBiosList">*/}
                                    {/*            <div className="leftCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="rightCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="teamBiosList">*/}
                                    {/*            <div className="leftCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="rightCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="teamBiosList">*/}
                                    {/*            <div className="leftCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="rightCol">*/}
                                    {/*                <div className="profileImage">*/}
                                    {/*                    &nbsp;*/}
                                    {/*                </div>*/}
                                    {/*                <div className="nameContainer">*/}
                                    {/*                    <p className="firstName"> First Name</p>*/}
                                    {/*                    <p className="lastName">Last Name, ABBR</p>*/}
                                    {/*                    <p className="title">Title</p>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <hr className="w-100 topZero"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="headlineButtonAndCopy">
                                                <h3 className="subheadUpperandLower w-100 text-center smallerSize">CARDIOLOGY
                                                    TEAM</h3>
                                                <p className="w-100 d-flex justify-content-center"><a
                                                    className="bulletLink"
                                                    href="https://www.chihealth.com/nebraska-heart/medical-team/cardiologists.html"
                                                    target="_blank">Cardiology Team Bios&nbsp; <i
                                                    className="fa fa-angle-right"></i></a></p>
                                                <p className="w-100">Nebraska Heart provides a full-range of cardiology
                                                    services to support the health care needs of patients throughout the
                                                    region. Specialist at Health Institute focus on: non-invasive
                                                    testing in- cluding nuclear and echo as well as invasive cardiac
                                                    catheterization and transesophageal echo- cardiography;
                                                    interventional cardiology to include angioplasty, cardiac stenting,
                                                    PFO Closure and peripheral vascular treatment.</p>
                                                <p>Electrophysiologists afford patients an array of diagnostics and
                                                    treatment such as “mapping” of the heart’s electrical system,
                                                    pulmonary ablation, pacemaker and ICD implantation and are one of
                                                    the country’s leading implanter of biventricular ICDs for heart
                                                    failure patients.</p>
                                                <p className="w-100">To learn more about this team, check out the bio
                                                    link above.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="headlineButtonAndCopy">
                                                <h3 className="subheadUpperandLower w-100 text-center smallerSize">SURGERY
                                                    TEAM</h3>
                                                <p className="w-100 d-flex justify-content-center"><a
                                                    className="bulletLink"
                                                    href="https://www.chihealth.com/nebraska-heart/medical-team/surgeons.html"
                                                    target="_blank">Surgery Team Bios &nbsp;<i
                                                    className="fa fa-angle-right"></i></a></p>
                                                <p className="w-100">Surgeons with Nebraska Heart provide traditional
                                                    open-heart as well as minimal invasive sur- gery for coronary artery
                                                    disease, valvular and peripheral vascular disease and arrhythmias of
                                                    the heart. The Heart Institute surgery team continues to be on the
                                                    leading edge of new treatment including open-heart surgery with
                                                    regional anesthesia, off-pump surgery, minimal invasive valve
                                                    replacement and a leader in the treatment of abdominal aortic
                                                    aneurysms.</p>
                                                <p className="w-100">To learn more about this team, check
                                                    out the bio link above.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="headlineButtonAndCopy">
                                                <h3 className="subheadUpperandLower w-100 text-center smallerSize">PIONEER
                                                    HEART INSTITUTE CARDIOLOGISTS</h3>
                                                <p className="w-100 d-flex justify-content-center"><a
                                                    className="bulletLink"
                                                    href="https://pioneerheart.com/cardiologists/" target="_blank">Cardiologists
                                                    Bios&nbsp; <i className="fa fa-angle-right"></i></a></p>
                                                <p className="w-100">To learn more about this team, check
                                                    out the bio link above.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin_CHI_7_2;