import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGivingMedicinesFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGauzeDressing">Changing a Gauze Dressing</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGivingMedicines/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGivingMedicines/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is the best way for me to avoid making a mistake</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Follow the 5 rights. Always be sure to give the <span className="bold italic">right medicine</span> to the <span className="bold italic">right person</span> at the <span className="bold italic">right time</span>, in the <span className="bold italic">right amount</span>, and by the right route. Stay focused. Never hurry or become distracted when giving medicines. Take your time to be accurate.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">My mom has a hard time swallowing her pills.</h2>
                                                <h2 className="subheadUpperandLower"> Can I crush them and put them in her food?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Check with your pharmacist to be sure it is ok to crush a pill or tablet. Some medicines should never be crushed. Certain capsules should not be opened and mixed with food because the action of the drug would be altered. <span className="bold">Do NOT</span> crush a medicine and then mix it up with a person’s meal. Give medicines separately in a small amount of food, like applesauce, to be sure a person takes all of the medicine</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What if the person I care for decides not to take their medicine?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Try to find out the reason they refuse, they may not feel well; they may believe the medicine is causing unpleasant side effects. Never force a person to take a medicine. If the person continues to refuse a medicine for more than one dose, call the doctor.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-6 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill sidebarNoFillborderColor  marginRight">
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/USP_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">United States Pharmacopoeia (USP)</h2>
                                                        <p>An official public standards–setting authority for all prescription and over– the–counter medicines and other health care products manufactured or sold in the United States. USP sets widely recognized standards for food ingredients and dietary supplements. It also sets standards for the quality, purity, strength, and consistency of these products.</p>
                                                        <p>visit: <a href="https://www.usp.org" target="_blank">www.usp.org</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill sidebarNoFillborderColor  marginRight">
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ISMP_header.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Institute for Safe Medication Practices (ISMP)</h2>
                                                        <p>The society’s members include more than 15,000 physicians, research scientists, nurses and other allied healthcare professionals. The mission is to improve health worldwide by advancing research, clinical care and public health.</p>
                                                        <p>visit: <a href="https://www.ismp.org" target="_blank">www.ismp.org</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-6 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill sidebarNoFillborderColor marginRight">
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Safe_Medication_logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Safe Medication</h2>
                                                        <p>Web site of the American society of Health-system Pharmacists. This is a resource for drug and drug administration.</p>
                                                        <p>visit: <a href="https://www.safemedication.com" target="_blank">www.safemedication.com</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGivingMedicinesFAQResources;
