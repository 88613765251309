import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll8_3 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Physical
                                        Therapy</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="text-center floatNone bottomSpacer font22px">Why Physical
                                        Therapy?</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="imageLeftCopyRight w-100">
                                        <div className="imgCol">
                                            <img className="floatLeft wrapImage clearLeft" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/101071.jpg" alt="" />
                                        </div>
                                        <div className="typeCol">
                                            <p className="floatNone clearNone noPaddingLeft">Physical
                                                therapy does not directly treat cancer. Instead, it provides relief from
                                                treatment complications and helps you recover afterward.
                                            </p>
                                            <p className="floatNone clearNone noPaddingLeft">Physical
                                                therapy can help you during all stages of cancer, to meet your
                                                responsibilities and also do the things you enjoy — working, traveling,
                                                playing sports and participating in many other activities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                        <h3 className="subheadUpperAndLowerColorBar text-center entAdditionalTherapiesSecondaryGreenBackgroundColor ">When is
                                            Physical Therapy Right for You?</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                        <p>Physical therapy can be provided throughout cancer treatment and
                                            beyond,
                                            whenever you and your doctor think it will help.</p>
                                        <p><strong>During active treatment:</strong><br /> You will learn about
                                            tools
                                            to help you manage daily life and achieve the highest function
                                            possible
                                            while you receive treatment for cancer. Physical therapists help you
                                            remain independent, take care of your family, return to work and
                                            resume
                                            your passions.</p>
                                        <p><strong>Survivorship:</strong><br /> You
                                            will
                                            receive help to regain previous levels of function and achieve
                                            particular goals, whether it is resuming running or biking or
                                            returning
                                            to a physically demanding job. Your therapy team will create a
                                            tailored
                                            exercise plan that you can follow at home.</p>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll8_3;