export const validateUserName = (userName) => {
    if (!userName)
        return 'Username is a required field.';

    if (!(userName.length >= 6 && userName.length <= 255))
        return 'Please make sure your {User Name} is within the character limits listed above.';

    return '';
}

export const validateFirstName = (firstName) => {
    if (!firstName)
        return 'First name is a required field.';

    if (!(firstName.length >= 1 && firstName.length <= 50))
        return 'Please make sure your {First Name} is within the character limits listed above.';

    if (!(/^[a-z\d\-_\s]+$/i.test(firstName)))
        return 'An incorrect character was entered into this field, allowable characters.  [a-z, A-Z, 0-9, space character and underscore are allowed]';

    return '';
}

export const validateLastName = (lastName) => {
    if (!lastName)
        return 'First name is a required field.';

    if (!(lastName.length >= 1 && lastName.length <= 50))
        return 'Please make sure your {Last Name} is within the character limits listed above.';

    if (!(/^[a-z\d\-_\s]+$/i.test(lastName)))
        return 'An incorrect character was entered into this field, allowable characters.  [a-z, A-Z, 0-9, space character and underscore are allowed]';

    return '';
}

export const validateEmailAddress = (emailAddress) => {
    const mailFormat =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/; /* /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;*/
    if (!emailAddress)
        return 'Email address is a required field.';

    if (!emailAddress.match(mailFormat))
        return 'Please make sure your {Email Address} matches the format listed above.';

    return '';
}

export const validatePhoneNumber = (phoneNumber) => {
    const phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    //if (!phoneNumber)
     //   return 'Phone number is a required field.';

    if (phoneNumber && phoneNumber.length > 0 && phoneNumber !== '(   )    -    ' && !phoneNumber.match(phoneNo))
        return 'Please make sure your {Phone Number} is in a valid phone number format.';

    return '';
}

export const validatePassword = (password, confirmPassword) => {
    if (!password)
        return 'Password is a required field.';

    if (!(password.length >= 6 && password.length <= 255))
        return 'Please make sure your {Password} matches the format listed above.';

    if (password !== confirmPassword && confirmPassword.length > 0)
        return 'The password and confirm password fields are not equal.'

    return '';
}

export const validateConfirmPassword = (password, confirmPassword) => {
    if (!confirmPassword)
        return 'Confirm Password is a required field.';

    if (!(confirmPassword.length >= 6 && confirmPassword.length <= 255))
        return 'Please make sure your {Confirm Password} matches the format listed above.';

    if (password !== confirmPassword && confirmPassword.length > 0)
        return 'The password and confirm password fields are not equal.'

    return '';
}
