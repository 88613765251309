import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll8_6 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Dental
                                                Care</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps text-center floatNone">RADIATION AND ORAL CARE</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Radiation therapy will have temporary and permanent side effects, some of
                                                which may be delayed.</p>
                                            <p>Side effects may include increased tooth decay, xerostomia (dry mouth),
                                                damage to the jaw bones that cause bone death (osteoradionecrosis),
                                                muscle spasms/contraction of mouth muscles (trismus), taste changes, and
                                                mouth sores/ulcers (mucositis).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Before the start of radiation treatment:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                                    <li>It is important to see your dentist before you begin radiation
                                                        therapy for a thorough oral examination and to establish a
                                                        baseline dental evaluation.
                                                    </li>
                                                    <li>It is recommended that your teeth are cleaned and all teeth with
                                                        sharp spots or concerning issues receive immediate attention.
                                                    </li>
                                                    <li>All teeth with infection, a questionable prognosis, or
                                                        significant periodontal disease within the area of highest
                                                        radiation dose should be extracted from the mouth <strong><em>at
                                                            least 14 days prior</em></strong> to starting radiation
                                                        therapy.
                                                    </li>
                                                    <li>Radiation specific oral care should be reviewed. It is
                                                        recommended that fluoride trays be fabricated at this time.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight entAdditionalTherapiesGreenBackgroundColor entAdditionalTherapiesSecondaryGreenBackgroundColor">
                                                <h2 className="subheadUpperAndLower mb-0">Positioning Devices</h2>
                                            </div>
                                            <div className="withBorder borderAccentLightGreen">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <p>Our expert team may also create <strong><em>custom
                                                            positioning devices for use in radiation
                                                            therapies</em></strong>. Positioning devices are necessary
                                                            to hold your mouth, teeth and surrounding tissues in the
                                                            best position while receiving radiation treatment. Our
                                                            custom positioning devices reduce exposure to nearby healthy
                                                            tissue by pushing parts of your mouth, such as your tongue,
                                                            away from the radiation beam.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">After completion of radiation treatment:</p>
                                            <p className="boldLeadIn italic">It is important to see your dentist for
                                                examination at least 3 times a year and to have your teeth
                                                professionally cleaned every 6 months.</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                                    <li>Radiation specific oral care should be continued including the
                                                        use of additional fluoride/fluoride trays.
                                                    </li>
                                                    <li>Mouthwashes that contain alcohol or phenol should be avoided as
                                                        they may cause irritation.
                                                    </li>
                                                    <li>Poor fitting appliances such as dentures or partials should be
                                                        adjusted to avoid complications with healing.
                                                    </li>
                                                    <li>Routine dental work such as root canal therapy, filling
                                                        cavities, dental cleanings, crowns, dental prosthetics, x-rays
                                                        and local anesthesia <strong><em>can be performed once you have
                                                            healed from radiation therapy.</em></strong></li>
                                                    <li>Surgical procedures such as dental implants, gum surgery, or
                                                        removal of teeth <strong><em>need to be evaluated and approved
                                                            by your radiation oncologist and/or surgeon in advance</em></strong>.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps text-center w-100" >CHEMOTHERAPY AND ORAL CARE</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Chemotherapy treatment may negatively impact the tissues of your oral
                                                cavity. </p>
                                            <p>While undergoing chemotherapy treatment, healing will be slower and the
                                                risk of infection will be higher than normal and as a result you may
                                                have mouth problems including soreness, ulcers, infection and change of
                                                taste.</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                                    <li> It is important to see your
                                                        dentist <strong><em>before</em></strong> you begin treatment for
                                                        a thorough oral examination and to establish a baseline dental
                                                        evaluation.
                                                    </li>
                                                    <li>It is recommended that you have a dental cleaning and have
                                                        treatment completed on all teeth with sharp spots or concerning
                                                        pathology.
                                                    </li>
                                                    <li>All teeth that can pose trauma or are sources of active
                                                        infection will need to be removed to avoid complications.
                                                    </li>
                                                    <li>It is also recommended that fixed orthodontic brackets and oral
                                                        appliances such as dentures or partial dentures are removed from
                                                        the mouth while undergoing treatment.
                                                    </li>
                                                    <li>Oral hygiene should be continued throughout chemotherapy
                                                        treatment with an extra soft/supersoft toothbrush or oral
                                                        sponge.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entYellowColorBarBackground text-black"><i
                                                className="fa fa-exclamation-circle"></i> <span>Timing of dental care is very crucial during chemotherapy so make sure to confirm with your oncologist prior to having any dental work completed.</span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps text-center w-100">PROSTHODONTISTS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Some patients with head and neck cancer sometimes require expertise in
                                                maxillofacial prosthodontics and anaplastology (customizing prosthetics,
                                                including facial prosthetics). Siteman offers the region’s highest
                                                quality care to preserve your ability to eat, speak or swallow,
                                                including any maxillofacial prosthodontic rehabilitation or other
                                                services you need.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entAdditionalTherapiesSecondaryGreenBackgroundColor">Prosthodontist</h3>
                                            <p>A <strong><i>prosthodontist</i></strong> is a dentist with additional
                                                training in caring for people who are missing teeth and other mouth
                                                structures after an illness, such as cancer, or severe (traumatic)
                                                injury. Prosthodontists make custom devices (prosthetics) that restore a
                                                more normal appearance. The devices also make it possible to chew, speak
                                                and swallow more naturally.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entAdditionalTherapiesSecondaryGreenBackgroundColor">Maxillofacial
                                                Prosthodontist</h3>
                                            <p>A <strong><i>maxillofacial prosthodontist</i></strong> has specific,
                                                advanced training to treat conditions affecting the head, neck and face.
                                                The maxillofacial prosthodontist uses skill and science to create
                                                prosthetics that improve your function and appearance.</p>
                                            <p>Find complete information on maxillofacial expertise and other details <a
                                                target="_blank"
                                                href="https://www.barnesjewish.org/Medical-Services/Ear-Nose-Throat-ENT/Maxillofacial-Prosthodontics"><u>here</u>
                                                <i className="fas fa-chevron-right"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll8_6;
