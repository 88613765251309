import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonSubcutaneousInjectionsWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonSubcutaneousInjections">Subcutaneous Injections</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower text-center">Injection Pens</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <img className="floatLeft wrapImage col-6 marginLeft20px clearLeft" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/shutterstock_168772943.png" alt="" />
                                                <p className="floatNone clearNone col-6 noPaddingLeft">Certain medicines come prepared in injection pens. The injection pen makes giving injections much easier. The companies that make each pen offer directions on their use.</p>
                                                <p className="floatNone clearNone col-6 noPaddingLeft">You can also find injection aides such as magnifiers that help a person see the numbers on the side of the syringe.</p>
                                                <p className="floatNone clearNone col-6 noPaddingLeft">These are available in medical equipment stores and some drug stores.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Making an Injection Less Painful</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Because subcutaneous tissue is sensitive to pain, it is normal for an injection to cause mild discomfort. However, the needles used for a subcutaneous injection are very small and thus pain is minimal.</p>
                                                <p>A medicine will absorb and have its best effects when you give a subcutaneous injection correctly.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_3.png" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <h2 className="subheadUpperandLower floatLeft clearLeft w-100 text-left">Follow these tips to lessen injection pain</h2>
                                                    <ul className="bulletsCustom orangeBullets">
                                                        <li>Give the medicine at room temperature. (You can keep Insulin at room temperature for one month. However, always store unopened insulin in a refrigerator. Even if you keep insulin refrigerated after opening it is still only good for one month).</li>
                                                        <li>Wait until the alcohol dries on the skin before inserting the needle</li>
                                                        <li>Have the person relax their muscles as much as possible during an injection</li>
                                                        <li>Insert the needle quickly</li>
                                                        <li>Do not change the direction of the needle as it goes in or comes out</li>
                                                        <li>Do not reuse a disposable needle</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonSubcutaneousInjectionsWhatYouNeedToKnow;
