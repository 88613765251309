import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll9_5 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center">Finding
                                        Activities Nearby</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                        <h2 className="subheadUpperAndLower conciergeHeader">Concierge Services Can Help!</h2>
                                    </div>
                                    <div className="withBorder borderAccentBlue entBaseBlueBorderColor">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <p>While visiting patients at Barnes-Jewish Hospital, concierge representatives are available to help assist you with information, services and special requests, including: </p>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom">
                                                                <li className="conciergeList">Maps and Directions</li>
                                                                <li className="conciergeList">Restaurant and delivery options</li>
                                                                <li className="conciergeList">Hotels</li>
                                                                <li className="conciergeList">Local attractions</li>
                                                                <li className="conciergeList">Parks</li>
                                                                <li className="conciergeList">Shopping</li>
                                                                <li className="conciergeList">Pharmacies nearby</li>
                                                                <li className="conciergeList">Transportation services</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Concierge assistance is offered at no charge. Services provided by outside vendors may include a fee. Concierge representatives are located at the information desks on the hospital’s South Campus and in the Center for Advanced Medicine.</p>
                                                <p className="conciergeLink text-left"><span><a href="tel:3143625196">314-362-5196</a></span>
                                                    <a href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a>
                                                </p>
                                                <p><span className="bold">More information on </span><a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Hospitality Services</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Grand_Basin.jpg" className="img-responsive w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">THE NEIGHBORHOOD</h2>
                                    <p>The neighborhood surrounding the medical campus is called the <strong>Central
                                            West End</strong>.</p>
                                    <p>Visit <a href="https://www.thecwe.org/" target="_blank">thecwe.org</a> or <a href="https://www.marylandplaza.com/" target="_blank">marylandplaza.com</a> for details on options throughout
                                        the neighborhood which include a big variety of restaurants, shopping,
                                        theaters and much more. </p>
                                    <p>The campus is also beside Forest Park, one of the finest urban parks in the country, filled with things to do for individuals and families, many of which are free. These include free admissions to the world-class St. Louis Zoo, as well as art and history museums and a science center/planetarium perfect for kids and adults. The park also offers recreational activities like miles of jogging/biking/nature paths and trails, paddleboats, a racquet sports center, a boathouse restaurant, an outdoor theater, multiple golf courses and a full calendar of special events.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/STL_attractions_logos.jpg" className="img-responsive w-100" />
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 vertMiddle">
                                    <div className="col-2 col-3 imgCol noPaddingLeft">
                                        <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/FP_Map_Detail.png" alt="" />
                                    </div>
                                    <div className="col-10 col-9 typeCol noPaddingLeft">
                                        <p>Find out more about Forest Park <a href="https://www.forestparkforever.org/" target="_blank">here</a>.
                                        </p>
                                        <p className="mobileMarginBottom30"><a href="https://forestparkmap.org/" target="_blank">forestparkmap.org</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/CWE_street.jpeg" className="img-responsive w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">AREA RESTAURANTS</h2>
                                    <p>There are over 100 restaurants of all varieties in the neighborhood, many within walking distance. These range from quick, inexpensive lunch options to more formal dining.</p>
                                    <p>An Applebee’s is directly across the street from the Center for Advanced
                                        Medicine and a Panera Bread caf&eacute; is across Forest Park Parkway.
                                        Many other selections are a short walk north on Euclid Avenue. The
                                        Information/Concierge Desks have brochures on this area.</p>
                                    <p>For authentic Italian cuisine, patients and visitors may be interested in
                                        driving to <a href="https://thehillstlouis.com/" target="_blank">The
                                            Hill</a>, a local neighborhood known for its nationally-acclaimed
                                        restaurants, specialty groceries and bakeries. The Hill neighborhood is
                                        about 1.5 miles south of the hospital, off of Kingshighway Boulevard.
                                    </p>
                                    <p>Other dining options can be found at <a href="https://www.marylandplaza.com/" target="_blank">marylandplaza.com</a>.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">ADDITIONAL ACTIVITIES</h2>
                                    <p>Visit <a href="https://explorestlouis.com/" target="_blank">explorestlouis.com</a> for
                                        more options throughout the St. Louis area.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP </a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll9_5;
