import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL96Component({ code, language }) {
    code = `{/* L96 Content Block with Bullets and No Background Color */}
                                     <div className="lego l096 legoMargin3">
                                            <div className="contentContainer">
                                                {/* L53 Bullet List */}
                                                <div className="lego l053 customBulletList">
                                                <ul className="w-100">
                                                    <li className="w-100">
                                                    <p className="withBorderBottom">
                                                        Do not use the system while driving, operating machinery, or
                                                        during any activity in which sudden muscle tension may put you
                                                        at risk of injury
                                                    </p>
                                                    </li>
                                                </ul>
                                                </div>
                                                {/* End L53 Bullet List */}
                                                {/* L53 Bullet List */}
                                                <div className="lego l053 customBulletList">
                                                <ul className="w-100">
                                                    <li className="w-100">
                                                    <p className="withBorderBottom">
                                                        Do not use the system while driving, operating machinery, or
                                                        during any activity in which sudden muscle tension may put you
                                                        at risk of injury
                                                    </p>
                                                    </li>
                                                </ul>
                                                </div>
                                                {/* End L53 Bullet List */}

                                                {/* L53 Bullet List */}
                                                <div className="lego l053 customBulletList">
                                                <ul className="w-100">
                                                    <li className="w-100">
                                                    <p className="withBorderBottom">
                                                        Do not use the system while driving, operating machinery, or
                                                        during any activity in which sudden muscle tension may put you
                                                        at risk of injury
                                                    </p>
                                                    </li>
                                                </ul>
                                                </div>
                                                {/* End L53 Bullet List */}

                                                {/* L53 Bullet List */}
                                                <div className="lego l053 customBulletList">
                                                <ul className="w-100">
                                                    <li className="w-100">
                                                    <p className="withBorderBottom">
                                                        Do not use the system while driving, operating machinery, or
                                                        during any activity in which sudden muscle tension may put you
                                                        at risk of injury
                                                    </p>
                                                    </li>
                                                </ul>
                                                </div>
                                                {/* End L53 Bullet List */}
                                            </div>
                                            </div>
                                    {/* End L96 Content Block with Bullets and No Background Color */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL96Component;