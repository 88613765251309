import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll1_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center orangeColor orangeColorBorder">Progress & Key Milestones</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You can take steps right away to guide you to be your healthiest for your new start. Your success begins with creating a true understanding of your path through this journey – the nature of your procedure, how to help ensure the best outcomes, and what your recovery will entail. Everyone involved – you, your family, your care team and other resources available to you – canall come together to help you through this journey.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ENT_1.3_Milestones.svg" className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100 orangeColorBorder" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll1_3;
