import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter4_3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2ede3f035121ab57bb47435f693cdcf6.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FBAF3F",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Involving Loved Ones</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      The Living Well Center greatly values the impact a loved
                      one can have on your continued success throughout your
                      journey. If you can, involve them from the start, both
                      with your planning and in getting to know your Living Well
                      Center team as your plan progresses (some activities are
                      even open for their attendance; just ask your care team
                      which programs can also include family).
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      A loved one who understands your goals, your S.M.A.R.T.
                      plan, and your challenges can be a huge benefit in both
                      good and challenging times.  In so many ways, you and your
                      loved ones are the most important members of your team.  
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14B Paragraph With Bold Italic Spans */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      <span className="bold italic">Work with them to: </span>{" "}
                    </p>
                  </div>
                  {/* End L14B Paragraph With Bold Italic Spans */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin2 withBorderBottom">
                    <div
                      className="iconContainer"
                      style={{ paddingBottom: "0px", paddingTop: "10px" }}
                    >
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/message.svg"
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Help you communicate more openly and clearly with your
                          care team
                        </span>
                        , sharing any concerns and making sure all topics are
                        covered to your satisfaction.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin2 withBorderBottom">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/questionMark.svg"
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        <span className="bold"> Ask questions – </span>
                        don’t be afraid to say you don’t understand something or
                        need more information to be comfortable.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin2 withBorderBottom">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/rightArrow.svg"
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Follow your S.M.A.R.T. action plan
                        </span>
                        , giving you the consistency that is key to better
                        outcomes.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin2 withBorderBottom">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/calender.svg"
                        style={{ maxWidth: "28px" }}
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Help you attend all scheduled appointments,{" "}
                        </span>
                         including help with any logistics required.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin2 withBorderBottom">
                    <div
                      className="iconContainer"
                      style={{ paddingTop: "10px" }}
                    >
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/tickMark.svg"
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Keep accurate, consistent records 
                        </span>
                        to make sure you and your care team know all the
                        relevant information to make informed decisions about
                        your health.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin2 withBorderBottom">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/statistics.svg"
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Monitor your progress and health status,{" "}
                        </span>
                         and pay attention to how your progress is keeping up
                        with your goals.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  {/* L89A Content  with Icon and BottomBorder */}
                  <div className="lego l089A legoMargin5 withBorderBottom">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/WalkingEmoji.svg"
                        style={{ maxHeight: "34px" }}
                      />
                    </div>

                    <div className="contentContainer">
                      <p>
                        And, of course, 
                        <span className="bold">
                          being there to support you throughout your journey
                        </span>
                         in the ways that will make a difference.
                      </p>
                    </div>
                  </div>
                  {/* End L89A Content  with Icon and BottomBorder */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter4_3;
