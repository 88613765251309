import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonAfterSurgeryWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonAfterSurgery">Care After Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonAfterSurgery/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonAfterSurgery/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonAfterSurgery/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonAfterSurgery/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are many ways that you can help a person after their surgery. Always be a good observer and provide support for any orders the surgeon has prescribed. Keep notes in your care diary to describe how the person progresses.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Look at the Wound and Dressing</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A surgeon closes a wound with stitches, staples or sometimes with glue. In many cases a wound will not be covered with a dressing. It may only be covered with a band-aid.</p>
                                        <p>Look at the wound when the person first comes home, then at least once a day or whenever the person tells you their wound bothers them.</p>
                                        <p>If the wound is covered with a dressing (such as gauze or a clear transparent dressing), the surgeon may or may not have you change it. If you do change it, then look at the wound and dressing during each change. See our lesson on Changing a Gauze Dressing.</p>
                                        <p>If a dressing is covering a wound, it is important for it to remain clean, dry and intact. Do not let the dressing get wet. Talk to the person’s doctor or home health nurse on ways to keep a dressing dry.</p>
                                        <p className="boldLeadIn">Tips on What to look for:</p>
                                        <p>
                                            The surgeon will tell you what to expect and give you a list of the type of problems to report. <span className="bold italic">Follow these directions exactly.</span> Here are some tips on what to look for:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Does the wound look different since you last looked at it? Has the size changed or does it look deeper?</li>
                                            <li>Are the wound edges closed together?</li>
                                            <li>Are the wound edges reddened or swollen? (Some pink to light redness and swelling along the wound is normal.)</li>
                                            <li>Is there any drainage? If so what is the color; bloody, clear, yellowish, or greenish? If a dressing is in place and there is drainage, you will notice a change in the color of the gauze.</li>
                                            <li>Notice the size of the area discolored on the gauze, this gives you an idea of the amount of drainage. Also – when you change a gauze dressing, count the number of gauzes that contain drainage.</li>
                                            <li>Does the person tell you there is pain or discomfort around the incision?</li>
                                            <li>If the wound has stitches or staples – are they all in place?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className=" text-center subheadUpperAndLowerColorBarIcon centerBar greenColorBar w-100"><i className="fa fa-exclamation-circle"></i>
                                            When you think there is a problem with a person’s wound – call their surgeon immediately.
                                        </h3>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_03.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p><span className="fontWeight700">First 24 hours</span></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom greenBullets">
                                                            <li>Edges of the skin are closed, either by staples, stitches or glue.</li>
                                                            <li>Any drainage is clear or light pink</li>
                                                            <li>Skin along edges of the wound will be reddened, swollen, and be warm or throbbing from normal inflammation.</li>
                                                            <li>Skin is tender to touch.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_04.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p><span className="fontWeight700">Next 2 to 3 days</span></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom greenBullets">
                                                            <li>Edges of the skin are closed, either by staples, stitches or glue.</li>
                                                            <li>Drainage becomes more clear.</li>
                                                            <li>Inflammation with redness, swelling, warmth and throbbing still present.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_05.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p><span className="fontWeight700">Next 3 to 24 days</span></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom greenBullets">
                                                            <li>Drainage stops</li>
                                                            <li>Wound fills, closing any space between skin edges.</li>
                                                            <li>Size of the wound begins to shrink.</li>
                                                            <li>Redness and swelling of skin disappear.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p><i className="fa fa-exclamation-circle middleGreenColor"></i> At times, when a wound heals, the person may feel the need to scratch the area, as it may itch. Discourage this activity as this can cause skin abrasions and infection. Also be sure that the tape holding the dressing is not causing a skin reaction.</p>
                                        <p><i className="fa fa-exclamation-circle middleGreenColor"></i> When you change a dressing, look at the skin. When the person is sensitive to tape, there will be redness, and sometimes even blisters. If this is noticed, <span className="bold">call the doctor or the person’s Home Health nurse.</span></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Wound Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If an infection forms in a wound, look for these signs:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>The person complains of pain at the site.</li>
                                            <li>When you lightly press your hand or fingertips over the skin next to a wound, it will be ender to the person.</li>
                                            <li>The skin around the wound will be more reddened and swollen than normal.</li>
                                            <li>The skin around the wound will feel warm to the touch.</li>
                                            <li>The wound will have yellowish or greenish drainage.</li>
                                            <li>The person might have a fever (100.5 degrees Fahrenheit [oral] or greater, or 101.5 degrees [rectal or ear temperature] or greater).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Bleeding</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Ask the doctor or the nurse at the hospital how much bleeding should occur after the surgery and when to call and report bleeding. Call the doctor immediately if unexpected bleeding develops.</p>
                                        <p><i className="fa fa-edit middleGreenColor"></i> When you change a dressing, look at the skin. When the person is sensitive to tape, there will be redness, and sometimes even blisters. If this is noticed, <span className="bold">call the doctor or the person’s Home Health nurse.</span></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Care of Drains and Drainage Tubes</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Sometimes a person will come home with a drain or drainage tube placed inside the wound. The purpose is to help drain fluid that builds up inside the wound. A wound always heals from the inside out. Removal of any extra fluid helps a wound heal more quickly.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Drains are usually removed at a follow up doctor’s visit or by a home health nurse.</li>
                                            <li>Always know whether the drainage tube is supposed to be flat, or compressed and know the steps of what to do, and how to care for these external drainage tubes. See our Lesson on <a href="/LessonDrainage" target="_blank">Care of Drains and Drainage Bags</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Activity</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person’s activity may or may not be limited after surgery. The surgeon will provide specific guidelines for the person to follow.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Most people receive guidelines for a progressive return to full exercise. This means the person may start out walking a certain distance, and then increase the time walking or distance to walk each day, until he or she returns to normal. Remind the person to get up to walk and help them keep track of their progress in your care diary.</li>
                                            <li>Always urge a person to stay as active as possible, within their limits. This means, letting them dress and feed themselves, do their own shower or bath, within limits set by the doctor.</li>
                                            <li>Lifting objects is often limited. Lifting can place strain on stitches. The surgeon will tell the person the amount of weight restricted. For example, a person who has had bladder surgery may not be able to lift anything over 10 to 15 pounds for several weeks. If the person you care for has lift restrictions think about what they do each day to decide what is safe for them to do.</li>
                                            <li>Do they carry laundry down stairs? If so, how much does that weigh?</li>
                                            <li>Do they normally pick up a pet or an infant or baby? What do they weigh?</li>
                                            <li>Do they normally pick up large objects when they do grocery shopping?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Activity Restrictions</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Sometimes a person will have specific activity restrictions, depending on the type of surgery. Here are a few examples:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li><span className="bold">Walking up and Down Stairs</span><br />
                                                There are many cases where a patient is not allowed to walk up or down stairs for several weeks. The muscle movement and effort to go up or down stairs is often limited when surgery involves the legs.</li>
                                            <li><span className="bold">>Driving a Car</span><br />
                                                Driving is possible when it is safe for a person to sit up in a car, bend their knees, apply pressure to brakes, and have enough muscle control to properly operate the brakes and accelerator. Driving restrictions may be in place for several weeks until it is fully safe for a person to drive.</li>
                                            <li><span className="bold">Hip Precautions</span><br />
                                                After a total hip replacement, a person must avoid excessive flexion, adduction and internal rotation of the hip. This means he or she should not sit straight up in bed or bring the leg with the new hip up toward their chest.<br /><br />Place a pillow between their legs to maintain their proper position and to keep their legs apart. Do not let the person rotate their leg with the new hip inward. These precautions prevent dislocationof the new hip.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp;Do Not Place Stress on a Wound</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Any physical stress, especially on a surgical wound, can cause the edges of the skin to separate. This is very important during the first 24 to 72 hours after surgery. Protect a person’s wound by following these tips:</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_06.png" className="img-responsive maxWidth400" />
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Have the person place a pillow over their wound, and hold it firmly with their hand over the wound to splint it when coughing. If the person cannot splint a wound themselves, you can position a pillow or take your hands and place them flat over the wound, and apply light pressure to splint as the person coughs.</li>
                                            <li>Vomiting places stress on a wound. Have the person take any prescribed anti-emetic medicines.</li>
                                            <li>Movement places stress on a wound. Have the person move slowly and not try to exercise past the level ordered by their doctor. After surgery a person may not be able to climb stairs or drive a car for several weeks.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Pain Relief</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person who has had surgery must know the type of pain to expect after surgery. The surgeon or nurse will explain the type of pain and how long it normally lasts.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Be sure the person takes any prescribed pain medicine. This medicine will not cause the person to become addicted or dependent on the medicine as long as it is taken correctly.</li>
                                            <li>If you are helping to give a person their pain medicine, do not give extra doses.</li>
                                            <li>Do not wait longer than needed to give a dose. At first the person will have better pain control if the medicine is given regularly or an hour or so before daily activities need to be done.</li>
                                            <li>Call the doctor if the person continues to have severe pain, even after medicine has been taken.</li>
                                            <li>The person should not drink alcohol or drive when taking an opioid (narcotic) pain medicine.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_07.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder greenBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Tips for Pain Relief</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>When surgery involves the arm or leg, keep it raised up on pillows. This helps relieve the swelling that causes pain.</li>
                                                    <li>If the person has an incision in their chest or abdomen (belly), have them hold a pillow firmly over the area when they cough, deep breathe or move about in bed. Splinting with a pillow supports the incision and prevents pulling and pain as the person coughs, breathes or turns.</li>
                                                    <li>The doctor may order the use of warm or cold compresses over an incision. The proper use of heat or cold can promote healing.</li>
                                                    <li>Help the person change positions in bed frequently. This takes the pressure off the incision, promotes circulation and normal breathing.</li>
                                                    <li>Relaxation exercises along with use of pain medicine can help a person to relax more fully and gain greater pain relief.</li>
                                                    <li>Always be sure the person gets plenty of rest after surgery. Rest is needed to help the body repair itself.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Circulation</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person’s circulation slows during surgery and afterwards as well, if the person is not active right after surgery. When the circulation slows and if the person has any problems with blood clotting, there is the risk of a person forming a deep vein thrombosis or DVT.</p>
                                        <p>A DVT is a blood clot that forms on the inner wall of a vein, most commonly a deep vein in the leg. The risk of a DVT is that it can break off, enter the circulation, and then be carried up to the lung, with the potential of causing death. This risk is common in persons who have had orthopedic or vascular surgery.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>A person will often be told to wear a pair of elastic stockings after surgery. The stockings keep pressure on the outside veins of the leg to prevent pooling of blood. Instead of just staying in the veins, the blood moves up and into the veins back to the heart, so clots are less likely to form.</li>
                                            <li>Ask the doctor or the nurse at the hospital when the person should wear the stockings and when they can be left off. See our lesson on Applying Elastic Stockings for proper fitting and applicatio ip.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Each person will have orders for the type of diet and the amount of fluids to take after surgery. This will depend on their type of surgery, and whether they feel queasy from anesthesia when they go home. For example, if a person has surgery on the digestive tract, return to a normal diet will take a few days. A person who has an arthroscopicprocedure of the knee will be able to eat a normal diet the next day.</p>
                                        <p>The surgeon may order a progressive diet. This means the person may start with full liquids and soft food, and then gradually add solid foods.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">As a caregiver, you want to observe:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>How the person tolerates the diet: how much is eaten?</li>
                                            <li>How many glasses of liquids does he or she drink?</li>
                                            <li>Does the person get full easily or become sick to their stomach?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">A return to a normal diet promotes:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Wound healing</li>
                                            <li>Regular pattern of passing stool and urine</li>
                                            <li>Energy to do normal activities</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Provide meals when a person is rested and pain is mild. If the person feels queasy or sick to their stomach, delay the meal or offer a small snack, such as a piece of toast, or liquid broth. Always offer 8 to 10, eight ounce glasses of fluid each day (unless the person has limits).</p>
                                        <p>For more information, see our lesson on <a href="/LessonFeedingAPerson" target="_blank">Feeding a Person</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Do not give more pain medicine than is ordered for the patient. Call the doctor if the pain medicine is not working.</li>
                                            <li>Wash your hands thoroughly before and after you change a dressing.</li>
                                            <li>When you change a dressing and a drain is present, be very careful. Do not pull the drain out and do not try to push the drain back in if it does fall out. If the drain falls out, place a clean gauze over the wound and call the doctor for further instruction.</li>
                                            <li>When a person is oozing bright red blood from a surgical wound, call the surgeon.</li>
                                            <li>Be sure the person takes all of the medicines he or she is prescribed after surgery.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 video">
                                    <div className="w-100">
                                        <h3 className="blueColor videoTitle width100 text-center">
                                            Video: Care After Surgery
                                        </h3>
                                        <div className="floatLeft width100 captionBelow">
                                            <div className="embedContainer">
                                                <YouTube videoId="hhAPGnx5T0Y" opts={opts} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="equipment">EQUIPMENT</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Pair of disposable gloves (if wound has drainage)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonAfterSurgeryWhatToDoAndSafety;