import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTAll4_1_3 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Drains: Tracheostomy'}, () => {
            console.log('Drains: Tracheostomy');
        });
        await dispatch(pal);
    }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Caring
                                        for your Drain</h1>
                                </div>
                            </div>

                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="ziaHMkGOW3E" opts={opts} onPlay={playVideo} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Caring for your JP Drain</span></h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader text-center w-100">
                                        Caring for Your JP Drain:
                                    </h3>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">1.</h2>
                                        <p className="w-100 mb-0">Wash hands with soap and water.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">2.</h2>
                                        <p className="w-100 mb-0">Loosen the tape and gently remove the old dressing.<br />Throw the old dressing into a plastic bag.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">3.</h2>
                                        <p className="w-100 mb-0">Wash hands again with soap and water. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">4.</h2>
                                        <p className="w-100 mb-0">Use soap and water or saline solution to clean your JP site. Dip a
                                            cotton swab or gauze pad in the solution and gently clean your
                                            skin.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">5.</h2>
                                        <p className="w-100 mb-0">Pat the area dry.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">6.</h2>
                                        <p className="w-100 mb-0">Apply a 4”x4” or drain sponge to the base of the tube and tape in place making sure the tube is not bent or kinked. Change the dressing once a day. You may change it more often if it becomes very soiled or wet.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">7.</h2>
                                        <p className="w-100">Make sure the drain does not hang loosely or it may fall out or hurt your skin.</p>
                                        <p className="w-100">1. Place a safety pin on the bulb tag and then pin it to the inside of your clothes near the drain site, or...</p>
                                        <p className="w-100 mb-0">2. ...place a strip of tape over the drain tubing to hold the tube next to the skin.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Drain-with-tape_312px.jpg" className="img-responsive floatNone maxWidth400 centerImage" alt="" />
                                    <p className="captionItalic text-center w-100"><strong>Tape in place.</strong></p>
                                </div>
                            </div>

                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>

                            <div className="col-12 video">
                                <div className="w-100 text-center">
                                    <h3 className="sectionHeader text-center w-100">
                                        How to Empty the Bulb:
                                    </h3>
                                </div>
                                <div className="w-100 text-center">
                                    <h2 className="subheadUpperandLower text-center w-100 bottomSpacer">Empty the bulb every 8-12 hours unless
                                        the bulb fills up more often.</h2>
                                    <p style={{float: "none"}}><strong><a target="_blank"
                                                  href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pdf/Jackson_Pratt_chart.pdf">Please click here to journal your drain cleaning history</a></strong></p>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">1.</h2>
                                        <p className="w-100 mb-0">Wash hands with soap and water. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">2.</h2>
                                        <p className="w-100 mb-0">Unplug the drain plug and squeeze fluid out into the
                                            measuring container.<br/>Do not touch the drain plug with your hands or let it
                                            touch the measure container. This will help prevent infection.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">3.</h2>
                                        <p className="w-100 mb-0">Clean the plug with an alcohol swab or cotton ball dipped in
                                            alcohol.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">4.</h2>
                                        <p className="w-100 mb-0">Squeeze the bulb until both sides touch and replace the
                                            plug.<br /> The bulb will stay flat until it starts to fill with
                                            fluid again.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/1c9d446dafdd23d9270b9a72046c1fee.jpg" className="img-responsive floatNone maxWidth400 centerImage" alt="" />
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">5.</h2>
                                        <p className="w-100 mb-0">Look at the drainage for:<br/> 1.&nbsp;
                                            <em>Amount</em> &nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;
                                            <em>Odor</em> &nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;
                                            <em>Color</em>&nbsp;&nbsp;&nbsp;&nbsp; 4.&nbsp;<em>Thickness
                                                of fluid.</em>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">6.</h2>
                                        <p className="w-100 mb-0">Measure and write down the amount of fluid you drain out.
                                            <br />
                                            Empty the drainage into the toilet and flush. <br />
                                            For each drain you have, be sure to note the following:<br /><br />
                                        <em>Date</em><br />
                                        <em>Time</em><br />
                                        <em>Amount</em><br />
                                        <em>Any Problems (redness, swelling, drain issues, etc.).</em></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100 text-center">
                                        <h2 className="subheadUpperandLower noBtmMargin boldNumber">7.</h2>
                                        <p className="w-100 mb-0">Rinse out the measure container. Wash hands with soap and water.<br />
                                            Repeat the above steps for each drain.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAndCopyWithIcon">
                                        <i className="fa fa-exclamation-circle hightLight entDiagnosisSurgeryRedColor"></i>
                                        What should I do if the tube becomes clogged?
                                    </h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p><em>Use the following steps to clear your JP Drain:</em></p>
                                    <p><strong>1.</strong> Hold the tubing between your thumb and forefinger at
                                        the place closest to your skin. This hand will prevent the tube from
                                        being pulled out of the skin.</p>
                                    <p><strong>2.</strong> Use your other thumb and forefinger to slide the clog
                                        down toward the bulb.</p>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="typesFeedingTubes" className="subheadUpperAndLowerColorBarIcon centered">When to call your doctor:</h3>
                                </div>
                            </div>

                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                        <li>Redness, swelling, warmth, pain or pus in your wound or tube site.
                                        </li>
                                        <li>Foul, smelly, cloudy, or tan drainage in your drain or coming out
                                            around the drain entrance at the skin.
                                        </li>
                                        <li>Chills or fever.</li>
                                        <li>Sudden changes in the amount of drainage, either an increase or
                                            decrease.
                                        </li>
                                        <li>The JP Drain stitches come loose or break off</li>
                                        <li>A change in the position of the JP tube or if your JP Drain comes
                                            out.
                                        </li>
                                        <li>If you see holes or cracks in the JP tubing or bulb or if the drain
                                            is leaking.
                                        </li>
                                        <li>If you have questions about your JP Drain care.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll4_1_3;
