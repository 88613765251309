import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";
//const navigate = useNavigate();
const ContentPancreatic8 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Having a Successful Hospital Stay'}, () => {
            console.log('Having a Successful Hospital Stay');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Having a Successful
                                                Hospital Stay</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                HAVING A SUCCESSFUL HOSPITAL STAY
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="1aaqNiErCHE" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">HOW LONG WILL YOU BE IN THE HOSPITAL?</h2>
                                            <p>The time you stay will be based on the progress you’re able to make each
                                                day which is, in part, affected by how well you follow your daily
                                                guidance and activities designed to help you heal and return to a
                                                healthier condition.</p>
                                            <p>Your length of hospital stay will vary by what type of procedure you had
                                                as well.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/date.jpg"
                                                 className="img-responsive w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">PROGRESS DAY-BY-DAY</h2>
                                            <p>In general, you’ll see and feel progress each day after your surgery as
                                                you start to heal and your body responds to the treatments and
                                                activities designed to help you recover.</p>
                                            <p>Here is a typical day-by-day progress chart.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">Postoperative Day (POD 1)
                                                Through Discharge</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/chatt.jpg"
                                                 className="img-responsive w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/chatt2.jpg"
                                                 className="img-responsive w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                {/*<li><a href="#">Click to view entire chart</a></li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower subheadAndCopyWithIcon"><i
                                                className="hightLight redColor fa fa-exclamation-circle" aria-hidden="true">&nbsp;</i>&nbsp;FOR PEOPLE VISITING YOU IN YOUR
                                                ROOM</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Do not allow people who are sick to visit you in the hospital.</li>
                                                <li>Ask that all visitors practice good hand hygiene when visiting you
                                                    in the hospital (washing/cleaning their hands before coming into the
                                                    room and when leaving). Hand sanitizer is available at stations
                                                    throughout the floor.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/venti.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic8;
