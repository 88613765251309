import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css';


const ContentPage = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto pageNotCompletedFlexContainer">
                            <div className="contentPageContainer">
                                <h1 className="headlineContent">Content Page</h1>
                                <p>Page created based on your content on this topic (or will be created from vetted health reference materials if you do not have existing content available on this topic). </p>
                                <img src="https://demo.careorbit.health/careorbit-demo/app/clientorbits/HERS-Demo/images/content.svg" alt="page icon" />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default ContentPage;
