import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_1 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 legoMargin5 greenColorAndBorder">
                    <h1 className="tileTitle">How Injections Can Help</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      Your care team has a wide range of potential treatments
                      that can directly address issues you may have. These
                      procedures may work in unison over time.
                    </p>
                    <p>
                      Each patient is different, so your care team will
                      customize your plan to reflect the right procedure(s)
                      based on the results you’re seeking. This plan could
                      evolve over time based on the results you are
                      experiencing.
                    </p>
                    <p>
                      It’s not uncommon for patients to be a bit confused as to
                      which injections they’re receiving. Injections are
                      commonly known procedures, but the extent of what is
                      actually available is less well known. Don’t be afraid to
                      ask questions to make sure you understand exactly what
                      procedure(s) your plan calls for, and what you can expect
                      from it.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      <span className="boldItalic">
                        The range of procedures includes, but is not limited to,
                        the following:
                      </span>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">
                      Epidural and Joint/Bursa Steroid Injections
                    </p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of nerve pain
                      </span>
                    </p>
                    <p>
                      This injection takes place in the epidural space, directly
                      over the compressed nerve root in the spine or neck, or
                      directly into a joint. These types of injections are
                      typically used in the treatment of back, neck or limb pain
                      or inflammation from nerve damage in the spine. They can
                      be used to diagnose the source of back, leg, neck, or arm
                      pain (“diagnostic”), or as a treatment to relieve pain
                      (“therapeutic”).
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">Facet Block Injection</p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of severe arthritis
                      </span>
                    </p>
                    <p>
                      Facet joints can be found at each segment of the spine.
                      They help provide stability and your range of motion.
                      These joints can become painful over time from arthritis
                      in the spine, back injuries or other back conditions. A
                      facet block injection is traditionally used to assist your
                      physician in determining if facet treatment is necessary,
                      as well as to offer continued pain reduction for you via a
                      steroid element.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">Medial Branch Block</p>
                    <p>
                      <span className="italic">
                        Used primarily for: Diagnosis of facet-joint blockage
                      </span>
                    </p>
                    <p>
                      The medial branch block goes hand-in-hand with the facet
                      block joints mentioned above. The blockage is treated by
                      injecting an anesthetic near the small medial nerves
                      connected to a specific facet joint. There are usually
                      multiple injections that occur in a single procedure.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">Genicular Nerve Block</p>
                    <p>
                      <span className="italic">
                        Used primarily for: Diagnosis of source of pain
                      </span>
                    </p>
                    <p>
                      This injection is not a treatment. It is used to find the
                      source of your pain. It can tell your provider if a
                      certain nerve is involved in the pain you are feeling. The
                      local anesthetic (numbing medicine) used in the injection
                      may give you pain relief right away. The pain relief can
                      last several hours, but it is only short-term. Depending
                      on the results of this injection, your provider may
                      suggest another type of procedure or treatment.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">SI Joint Injection</p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of lower back pain
                      </span>
                    </p>
                    <p>
                      A sacroiliac, or “SI,” joint injection can work to
                      counteract sacroiliac joint dysfunction. There are two of
                      these joints in your body, and they’re located near the
                      spine on either side, connecting the sacrum (between the
                      hip bones). Inflammation of these joints causes pain that
                      can be remedied by an SI joint injection.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">Viscosupplementationn</p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of joint pain
                      </span>
                    </p>
                    <p>
                      Viscosupplementation is a procedure that involves
                      injecting hyaluronic acid, a thick gel-like fluid, into a
                      joint. It is commonly used to treat joint pain, especially
                      in osteoarthritis, by providing cushioning and
                      lubrication.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034">
                    <p className="boldLeadInNewUI">Intracept Procedure</p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of lower back pain
                      </span>
                    </p>
                    <p>
                      The Intracept procedure is a treatment for chronic lower
                      back pain that involves ablating a small nerve that
                      provides sensation to the vertebral body.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">ADDITIONAL PROCEDURES</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">
                      Radiofrequency Ablation (RFA)
                    </p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of nerve pain
                      </span>
                    </p>
                    <p>
                      Radiofrequency ablation, also called radiofrequency
                      neurotomy, uses radio waves to create a current that heats
                      a small area of nerve tissue. The heat destroys that area
                      of the nerve, stopping it from sending pain signals to
                      your brain. RFA can provide lasting relief for people with
                      chronic pain especially in lower back, neck and arthritic
                      joints.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">
                      Platelet-Rich Plasma (PRP)
                    </p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of conditions involving
                        tendons, ligaments, and joints
                      </span>
                    </p>
                    <p>
                      The Platelet-Rich Plasma (PRP) treatment is a step-by-step
                      therapeutic process where your own blood is drawn, your
                      platelets are concentrated, and then injected into the
                      injured area. Platelets contain growth factors and other
                      proteins that stimulate tissue repair and reduce
                      inflammation, which promotes the healing of many
                      musculoskeletal injuries and conditions.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">
                      Peripheral Nerve Stimulation (PNS)
                    </p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of nerve pain
                      </span>
                    </p>
                    <p>
                      Peripheral Nerve Stimulation stimulates targeted
                      peripheral nerve fibers for up to 60 days, modulating
                      central plasticity to enable significant and sustained
                      pain relief. The procedure is minimally invasive; there
                      will be no permanent implant.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  {/* l34 Bold Lead In Description and Body Copy */}
                  <div className="lego l034 legoMargin3">
                    <p className="boldLeadInNewUI">
                      Ultrasound-Guided Injections
                    </p>
                    <p>
                      <span className="italic">
                        Used primarily for: Treatment of pain and inflammation
                        in joints and soft tissues
                      </span>
                    </p>
                    <p>
                      An Ultrasound Guided Injection is a procedure in which an
                      ultrasound is used to guide a needle for accuracy.
                      Commonly injected medications include cortisone (steroid),
                      local anesthetic, hyaluronic acid, and platelet-rich
                      plasma.
                    </p>
                  </div>
                  {/* End l34 Bold Lead In Description and Body Copy */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_1;
