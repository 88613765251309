import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll4_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanAdviceFromPatientsOnRecoveryBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Advice From
                                                                Patients <br/>on Recovery ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-2-2">
                                        <div
                                            className="tile Height1RowTall width2Column t_009 sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Handling Side Effects ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile Height1RowTall width1Column t_009 sitemanDarkerBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Healing ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile Height1RowTall width1Column t_009 sitemanLightestBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Communicating ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanNutritionBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Nutrition ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-2-7">
                                        <div
                                            className="tile Height1RowTall width1Column t_009 sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Emotional Health ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-2-7">
                                        <div
                                            className="tile t_003 Height1RowTall width1Column sitemanDarkerBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0054_care-circle-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">How Families Can Help ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile Height1RowTall width2Column t_009 sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Surveillance ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll4_2;