import React from "react";

function L83JComponentCode() {

    return (

        <div className="lego l083 legoMargin2">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_exer.svg"/>
                <img className="overlay dontOverlay"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/dontOverlay.svg"/>
            </div>
            <div className="contentContainer">
                <p>Stay out of a pool, bath or hot tub for two days after the
                    procedure.<br/>
                    <span className="boldItalic">Showers are OK.</span></p>
            </div>
        </div>

    )
};


export default L83JComponentCode;