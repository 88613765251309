import {postRequest} from "../../src/api/index.js";
import {FORGOT_PASSWORD_ERROR, RESET_PASSWORD_ASSIGN_OTP} from "../../src/actions/types.js";

export const resetPassword = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await postRequest(`/user/resetpassword`, "user", data);

        if (res.data.status === 'success') {
            cb({
                payload: {status: res.data.status}
            });
        } else if (res.data.status === 'otp_expiration') {
            cb({
                errorMessage: 'Email OTP Expired. Please check your email again for the new one time password.'
            });
        } else {
            cb({
                errorMessage: 'You entered an invalid OTP.  Please try again.'
            });
        }
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                cb({
                    errorMessage: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                cb({
                    errorMessage: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            cb({
                errorMessage: err.request
            });
        } else if (err) {
            cb({
                errorMessage: err.message
            });
        } else {
            // Some other errors
            cb({
                errorMessage: "Something went wrong."
            });
        }
    }
};
