import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter4_5 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/d8b64dea1873bc958754bc6f1882a278.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FBAF3F",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Helpful Tools</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L14 Paragraph */}

                  <div className="lego l00paragraph2 legoMargin3">
                    <p>
                      Adjusting to a healthier diet can be challenging at first,
                      which is why the WashU Living Well Center® team has put
                      together a comprehensive list of resources you can use in
                      your daily life to help you successfully achieve your
                      nutrition goals. Please note that these are recommended
                      resources for healthy living, but cannot be fully
                      evaluated or endorsed for accuracy, and do not constitute
                      medical advice. 
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps ">
                      SMARTPHONE NUTRITION APPS
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14A Paragraph With Bold Spans */}
                  <div className="lego l00paragraph2">
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://getbitesnap.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Bitesnap
                      </a>
                      <br />
                      (Available on App Store and Google Play Store, free): This
                      simple app allows users to keep a photo diary of the meals
                      they consume throughout the day and visualize the amount
                      of food they are consuming. The app also includes a
                      calorie-counting feature. 
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://nutritionfacts.org/daily-dozen/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Dr. Greger’s Daily Dozen
                      </a>
                      <br />A free, checklist-style app that inspires you to
                      include the healthiest foods into your daily meals.
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://www.happycow.net/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Happy Cow
                      </a>
                      <span className="boldLeadInNewUI underline"></span> <br />
                      The go-to app for finding plant-based food near you! This
                      app filters by “Vegan,” “Vegetarian,” and “Vegan Options,”
                      to help you find restaurants, cafes, and grocery stores in
                      180+ countries. Simply search by dropping a pin on the map
                      or typing in the area you’re visiting—Favorite lists and
                      Travel lists are a great feature for future use. Users can
                      leave reviews with pictures on each business’s page,
                      making it easy to find what you’re looking for.
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://www.myfitnesspal.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        MyFitnessPal:
                      </a>
                      <span className="boldLeadInNewUI underline"></span> <br />
                      A free calorie-counting app that tracks macronutrients,
                      vitamins, fiber, and other minerals. Users may also log
                      their water consumption and exercise with this app. Users
                      can sync data from MyFitnessPal with the FitBit, Garmin,
                      and Apple Health apps, and more!
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://www.mynetdiary.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        MyNetDiary:
                      </a>
                      <span className="boldLeadInNewUI underline"></span> <br />
                      A free weight and food tracking app which provides daily
                      and weekly analyses of users’ diets, macronutrients, and
                      micronutrients. A barcode scanner and nutrition tips are
                      provided. A public discussion/support forum is also
                      available for users.
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://www.noom.com/?route=_direct&utm_content=453318178305&utm_term=kwd-299095591416%7Cnoom"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Noom:
                      </a>
                      <br />A free Psychology-based lifestyle change app for
                      long-term weight loss. This app is subscription-based and
                      creates a customized plan that includes mini-lessons in
                      psychology and behavior change. Food tracking follows a
                      green/yellow/red system, and allows for dietary
                      preferences. Includes a coach-moderated peer support
                      group. Free one-week trial available.
                    </p>
                    <p>
                      <a
                        className="blackLinkfont22"
                        href="https://yuka.io/en/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Yuka app:
                      </a>
                      <br />
                      Bring this free app to the grocery store to evaluate food
                      quality before you check-out! Features a barcode scanner
                      that provides you with an immediate grade: from excellent
                      to bad. This may be particularly helpful for processed
                      foods. Remember: the healthiest foods don’t have barcodes!
                    </p>
                  </div>

                  {/* End L14A Paragraph With Bold Spans */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps ">
                      PLANT-BASED MEAL PLANNER APPS
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14A Paragraph With Bold Spans */}
                  <div className="lego l00paragraph2">
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://www.pcrm.org/news/news-releases/21-day-vegan-kickstart-program-launches-new-website-and-phone-app"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        21-Day Vegan Kickstart:
                      </a>
                      <span className="boldLeadInNewUI underline"></span> <br />
                      A free app designed to provide a 3-week plant-based meal
                      plan. Plans are designed for nutritional completeness and
                      are paired with recipe libraries and nutritional guidance.
                    </p>

                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://forksmealplanner.forksoverknives.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Forks Meal Planner:
                      </a>
                      <span className="boldLeadInNewUI underline"></span> <br />
                      Accessible via free online trial or through the paid
                      mobile app. Provides plant-based, oil-free recipes for
                      breakfast, lunch, dinner, and a snack. Weekly meal plans
                      include a grocery list, meal prep, and members-only
                      community.
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://www.lighter.world/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Lighter:
                      </a>
                      <br />
                      Provides meal plans customizable by personal health goals,
                      appetite, preparation time, and cooking skill. Meals are
                      paired with a grocery list. Free trial available.
                    </p>
                    <p>
                      <a
                        className="blackLinkfont22"
                        href="https://meals.richroll.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        The Plantpower Meal Planner:
                      </a>
                      <br />
                      Personalized meal planner based on goals, kitchen skills,
                      time to cook, and other preferences. Also includes support
                      from food coaches and cooking technique videos.
                    </p>
                  </div>

                  {/* End L14A Paragraph With Bold Spans */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>

                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps ">MINDFUL EATING</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14A Paragraph With Bold Spans */}
                  <div className="lego l00paragraph2">
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://get.gg/docs/CravingsDiary.pdf"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Cravings Diary:
                      </a>
                      <br />
                      This template provides users with the opportunity to pause
                      when experiencing a craving, and to further query the
                      nature of the craving and their experience of it. The
                      questions provided on the document are applicable to
                      cravings both related and unrelated to food.
                    </p>
                    <p className="legoMargin3">
                      <a
                        className="blackLinkfont22"
                        href="https://goeatrightnow.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Eat Right Now Smartphone App
                      </a>
                      <br />
                      (Available on App Store and Google Play Store): This
                      mobile app provides users with 4 free modules that are
                      tailored to their goals around food. The app prompts users
                      at intervals to assess their own emotional and physical
                      state and in so doing, promotes the development of
                      mindfulness. A monthly subscription is required after
                      completion of the first 4 modules ($24.99/month).
                    </p>
                  </div>

                  {/* End L14A Paragraph With Bold Spans */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter4_5;
