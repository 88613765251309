import {UPDATE_PROFILE_URL, USER_PROFILE, USER_PROFILE_ERROR} from "../actions/types";

const INITIAL_STATE = {
    userFirstName: '',
    userLastName: '',
    userEmailAddress: '',
    userPhoneNumber: '',
    errorMessage: '',
    userProfileImage: ''
};

export const userProfileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_PROFILE:
            return {
                ...state,
                userFirstName: action.payload?.firstName,
                userLastName: action.payload?.lastName,
                userEmailAddress: action.payload?.emailAddress,
                userPhoneNumber: action.payload?.phoneNumber
            };

        case UPDATE_PROFILE_URL:
            return {
                ...state,
                userProfileImage: action.payload
            }

        case USER_PROFILE_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
