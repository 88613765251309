import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
const LessonInfectionWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };
    return (
        <div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInfection">Infection Control</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInfection/WhatToDoAndSafety#introduction#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInfection/WhatToDoAndSafety#introduction#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInfection/WhatToDoAndSafety#introduction#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInfection/WhatToDoAndSafety#introduction#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Hand Washing</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Hand washing is the most important step for preventing infection. When caring for someone, always wash or disinfect your hands:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Before eating and preparing food</li>
                                            <li>Before you begin caring for the person</li>
                                            <li>Between any changes of gloves</li>
                                            <li>After you touch any body fluids like saliva, urine, blood, or drainage from a wound</li>
                                            <li>After you finish caring for the person</li>
                                            <li>After playing with pets and small children</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/IC_thermometer.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">For a Fever</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If the person you care for has a fever, take these steps to help control the fever. Controlling a fever will make the person more comfortable.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Encourage rest and limit physical activity.</li>
                                            <li>Remove or reduce the amount of clothing on the person’s body. Do not let the person shiver. (Shivering increases body heat). Remove extra blankets or bed covers, have the person wear light-weight and dry bed clothes.</li>
                                            <li>Keep the temperature of the rooms in the home above 70º degrees so the person is comfortable and not shivering.</li>
                                            <li>Offer the person eight to ten, 8-ounce glasses of fluids each day, unless the person has heart or kidney disease. The body needs more fluids as it tries to cool down.</li>
                                            <ul class="bulletsCustom checkBulletsSafeCare">
                                                <li>Offer water, fruit juice, or broth.</li>
                                                <li>Check with the doctor if you are unsure about how much a person can drink.</li>
                                                <li>Avoid caffeine drinks such as tea, regular soda, and coffee.</li>
                                            </ul>
                                            <li>A person with a fever often has a dry mouth. Offer water or ice chips frequently. Let the person rinse their mouth out often.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Hand Washing</h2>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Basic Wound Care Tips:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>First, always follow the instructions from your doctor on wound care.</li>
                                                <li>Wash your hands thoroughly for at least 15 seconds before caring for a wound.</li>
                                                <li>Put on a pair of disposable gloves. Remove the old dressing. Look at the dressing for drainage and note the amount, color, and odor. Write down what you see in your care diary.</li>
                                                <li>Remove your gloves and re-wash your hands. Apply a new pair of gloves.</li>
                                                <li>Cleanse the skin around the wound with mild soap and water or cleanser as ordered by the doctor. Use a clean wash cloth with each cleansing.</li>
                                                <li>Cleanse the wound, wipe in the direction from the wound out toward the surrounding skin.</li>
                                                <li>Use a new clean section of the cloth each time you wipe. This prevents you from wiping over dirty skin and then re-touching the wound itself.</li>
                                                <li>Use a separate dry cloth to gently dry the skin, wiping in the same direction you used to clean the wound.</li>
                                                <li>If the doctor has given instructions to apply any solution or ointment to a wound, apply it gently, avoid rubbing. Use a sterile gauze pad to apply solution or ointment. Take special care not to touch the side of the gauze that will cover the wound.</li>
                                                <li>If you see black threads or metal closures in an incision, these are sutures. Never try to remove a suture. These are to be removed in the doctor’s office. See our lesson on <a href="#" target="_blank">Care of Stitches (Sutures)</a>.</li>
                                                <li>Cover the wound with a new clean dressing, just like the one removed. Tape it securely so it completely covers the wound. Do not tape entirely around an arm or leg as it will cut off circulation.</li>
                                                <li>When finished, dispose of soiled supplies and gloves in a plastic trash or grocery bag and wash your hands.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/IC_Man_drinking_Water.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">For a Urine Infection</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Have the person drink about eight to ten 8-ounce glasses of liquids, if able.</li>
                                            <li>Have the person clean carefully around their private parts after passing urine. A woman should always clean from front to back, moving the wash cloth from the perineum (front) towards the rectum (back), using a clean part of the wash cloth with each swipe.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/IC_Chain_of_Infection.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom">
                                            <li>Food safety – Be sure the foods you keep in your refrigerator are fresh and not spoiled. Always check expiration dates on milk products and prepared foods. Eating spoiled foods can cause food poisoning, a serious infection.</li>
                                            <li>Know how to safely dispose of used needles and syringes. Each city or community has laws for disposal of the containers you use. <a href="LessonDisposal/introduction" target="_blank">See our lesson on Medical Waste</a>.</li>
                                            <li>When in doubt, wash your hands. You can never be too careful about washing your hands before and after you care for someone.</li>
                                            <li>If you think a person might have an infection, take their temperature. <a href="#" target="_blank">See our lesson on Taking a Temperature</a>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight flex-column">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Learn the techniques of infection control.</p>
                                    </div>
                                </div>
                                <div className="w-100 videoFlexContainer">
                                    <div className="imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <YouTube videoId="57759OhrOBI" opts={opts} />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 text-center">Infection Control
                                        </p>
                                    </div>
                                    <div className="imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <YouTube videoId="3Ui0PmPJq5U" opts={opts} />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 text-center">Hand Washing
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File
                                                <i className="fa fa-chevron-right">&nbsp;</i>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Basic Infection Control</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom checkBulletsSafeCare">
                                            <li>Regular hand soap or antibacterial soap</li>
                                            <li>Bleached based cleaners for counter tops and household objects</li>
                                            <li>Disposable gloves</li>
                                            <li>Plastic trash or grocery bags</li>
                                            <li>Chlorine bleach for laundering</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Hand Washing</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom checkBulletsSafeCare">
                                            <li>Paper towels or clean dish towel</li>
                                            <li>Antibacterial soap (in a pump)</li>
                                            <li>Gel sanitizer</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    {/*//
                                    <hr className="w-100" />*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
    )
};
export default LessonInfectionWhatToDoAndSafety;