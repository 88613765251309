export const validateFirstName = (firstName) => {
    if (!firstName || !(firstName.length >= 1 && firstName.length <= 50))
        return 'First name field should be between 1 and 50 characters long.';

    if (!(/^[a-z\d\-_\s]+$/i.test(firstName)))
        return 'An incorrect character was entered into this field, allowable characters.  [a-z, A-Z, 0-9, space character and underscore are allowed]';

    return '';
}

export const validateLastName = (lastName) => {
    if (!lastName || !(lastName.length >= 1 && lastName.length <= 50))
        return 'Last name field should be between 1 and 50 characters long.';

    if (!(/^[a-z\d\-_\s]+$/i.test(lastName)))
        return 'An incorrect character was entered into this field, allowable characters.  [a-z, A-Z, 0-9, space character and underscore are allowed]';

    return '';
}

export const validateEmailAddress = (emailAddress) => {
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailAddress || !(emailAddress.length <= 255))
        return 'Email address field should be between 1 and 255 characters long.';

    if (!emailAddress.match(mailFormat))
        return 'You must enter a valid email address.';

    return '';
}

export const validateOrganization = (organization) => {
    if (!organization || organization === "0" || !(organization.length >= 1 && organization.length <= 50))
        return 'Please select an organization from the provided list.';

    return '';
}

export const validateOrganizationDepartment = (organizationDepartment) => {
    if (!organizationDepartment || organizationDepartment === "0" || !(organizationDepartment.length >= 1 && organizationDepartment.length <= 50))
        return 'Please select an organization department from the provided list.';

    return '';
}

export const validateRole = (role) => {
    if (!role || role === "0" || !(role.length >= 1 && role.length <= 50))
        return 'Please select a role from the provided list.';

    return '';
}
