import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { HashLink as Link } from "react-router-hash-link";
import LessonInhalersWhatToDoAndSafety from "./what-to-do-and-safety";
const LessonInhalersFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInhalers">Inhalers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInhalers/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInhalers/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What should I do if the person I care for has trouble activating or pushing on an MDI canister?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The person’s hands might be too weak to push down on the canister. If the person has trouble breathing in while activating the canister, a spacer device can be helpful.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What should we do if the person I care for has a bad taste in their mouth after taking a medicine?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Have the person rinse their mouth out with a glass of warm water. It is common for inhaled medicines to leave an unpleasant taste in the mouth.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How can I help prevent an asthma attack?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There is no one way to prevent all asthma attacks. The best way for a person to control their asthma is to take all prescribed medicines regularly and on time. Also, the person should avoid being around any allergen triggers (such as smoke or pets).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What is a Peak Flow Meter used for?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A peak flow meter is a portable, handheld device for measuring how fast air moves out of a person’s lungs. Measuring the peak flow is often recommended by the doctor for managing the person’s asthma symptoms and preventing an asthma attack.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo-mayoclinic.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">>Mayo Clinic</h2>
                                                <p>PThe website is owned by the Mayo Foundation for Medical Education and Research. The Mayo Clinic’s mission is to empower people to manage their health. Mayo Clinic health professionals provide valuable information for using inhalers and a spacer.</p>
                                                <p className="resourceLink">visit: <a href="http://www.mayoclinic.com/health-information" target="_blank">www.mayoclinic.com/health-information</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth300" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/aafa_logo.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">Asthma and Allergy Foundation of America</h2>
                                                <p>AAFA is the only asthma and allergy patient organization that offers educational programs and tools for patients, caregivers, and health professionals. The website has information on use of inhalers.</p>
                                                <p className="resourceLink">visit: <a href="http://aafa.org/" target="_blank">aafa.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/asthma.ca_logo.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">Asthma Society of Canada</h2>
                                                <p>Society promotes self-management, prevention, research and health care for persons with asthma.</p>
                                                <p className="resourceLink">visit: <a href="http://asthma.ca/adults" target="_blank">http://asthma.ca/adults</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth300" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">The Ohio State University Medical Center Patient Education Materials</h2>
                                                <p>The Ohio State University Medical Center is one of the largest and most diverse academic medical centers in the country. The website has information on peak flow meters and inhalers.</p>
                                                <p className="resourceLink">visit: <a href="http://medicalcenter.osu.edu/patientcare/patient_education" target="_blank">http://medicalcenter.osu.edu/patientcare/patient_education</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth300" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Safe_Medication_logo.jpg" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">Safe Medication</h2>
                                                <p>American Society of Health-system Pharmacists. This is a resource for drug and drug administration.</p>
                                                <p className="resourceLink">visit: <a href="http://www.safemedication.com/" target="_blank">www.safemedication.com</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <h2 class="subheadUpperAndLower withBodyCopy">Inhaler websites</h2>
                                                <p>All inhalers will have a website on the label with information and directions for use.</p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInhalersFAQResources;