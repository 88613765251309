import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTGSP1_5 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideoOurJourney = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Our Journey'}, () => {
            console.log('Advice from Patients & Families: Our Journey');
        });
        await dispatch(pal);
    }

    const playVideoTakingAction = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Taking Action'}, () => {
            console.log('Advice from Patients & Families: Taking Action');
        });
        await dispatch(pal);
    }

    const playVideoOvercomingFears = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Overcoming Fears'}, () => {
            console.log('Advice from Patients & Families: Overcoming Fears');
        });
        await dispatch(pal);
    }

    const playVideoFeeding = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Feeding'}, () => {
            console.log('Advice from Patients & Families: Feeding');
        });
        await dispatch(pal);
    }

    const playVideoNutrition = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Nutrition'}, () => {
            console.log('Advice from Patients & Families: Nutrition');
        });
        await dispatch(pal);
    }

    const playVideoCommunicating = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Communicating'}, () => {
            console.log('Advice from Patients & Families: Communicating');
        });
        await dispatch(pal);
    }

    const playVideoWorkingWithTherapists = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Working with Therapists'}, () => {
            console.log('Advice from Patients & Families: Working with Therapists');
        });
        await dispatch(pal);
    }

    const playVideoCaregivers = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Caring for the Caregivers'}, () => {
            console.log('Advice from Patients & Families: Caring for the Caregivers');
        });
        await dispatch(pal);
    }

    const playVideoSurvivorGroups = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: Survivor Groups'}, () => {
            console.log('Advice from Patients & Families: Survivor Groups');
        });
        await dispatch(pal);
    }

    const playVideoThePowerOfSupport = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice from Patients & Families: The Power of Support'}, () => {
            console.log('Advice from Patients & Families: The Power of Support');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entJourneyOrangeColor entJourneyOrangeBorderColor">Advice
                                                from Patients & Families</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <h3 className="sectionHeader w-100 text-center">You are not alone.</h3>
                                                <p>In this region, and around the world, there have been many patients
                                                    and families who have found themselves on journeys similar to the
                                                    one you face. As people who have experienced surgery, chemotherapy,
                                                    radiation, and recovery, they can be incredible sources of relevant
                                                    advice, useful everyday guidance and compassionate
                                                    understanding. </p>
                                                <p>In fact, past patients and family members are actually part of the
                                                    team that is there for you throughout your journey here. Be sure to
                                                    speak with your care team to know how you can best reach out to
                                                    others who may have shared some parts of your experience.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 videoPage">
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="hPLZeU27pdU" opts={opts} onPlay={playVideoOurJourney}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Our Journey</span>
                                            </h3>

                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4Oh6LlmVBDA" opts={opts} onPlay={playVideoTakingAction}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Taking Action</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="yNRfLR6o4sQ" opts={opts} onPlay={playVideoOvercomingFears}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Overcoming Fears</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="WhCgjmMFPFo" opts={opts} onPlay={playVideoFeeding}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Feeding</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="rWoDuNFFYtY" opts={opts} onPlay={playVideoNutrition}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Nutrition</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="Ob1-mNcoOMM" opts={opts} onPlay={playVideoCommunicating}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Communicating</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="PVEM_dAd1hQ" opts={opts} onPlay={playVideoWorkingWithTherapists}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Working With Therapists</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4t08QXZpQmg" opts={opts} onPlay={playVideoCaregivers}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Caring for the Caregiver</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="I8WFvUGJq_A" opts={opts} onPlay={playVideoSurvivorGroups}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">Survivor Groups</span>
                                            </h3>
                                        </div>
                                        <div className="video">
                                            <div className="w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="8VZutRnnMLk" opts={opts} onPlay={playVideoThePowerOfSupport}/>
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                className="videoTitleTreatment">The Power of Support</span>
                                            </h3>
                                        </div>
                                    </div>
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle width100">*/}
                                    {/*            What I would tell a newly-diagnosed person.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*<div className="embedContainer pb-0">*!/*/}
                                    {/*                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive width100"/>*/}
                                    {/*            /!*</div>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle width100">*/}
                                    {/*            What helped us the most.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*<div className="embedContainer pb-0">*!/*/}
                                    {/*                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                     className="img-responsive width100"/>*/}
                                    {/*            /!*</div>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle width100">*/}
                                    {/*            Our challenges and how we dealt with them.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*<div className="embedContainer pb-0">*!/*/}
                                    {/*                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                     className="img-responsive width100"/>*/}
                                    {/*            /!*</div>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle width100">*/}
                                    {/*            Managing expectations.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*<div className="embedContainer pb-0">*!/*/}
                                    {/*                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                     className="img-responsive width100"/>*/}
                                    {/*            /!*</div>*!/*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entJourneyOrangeColor" href="#">BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP1_5;
