import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonDressingFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDressing">Dressing A Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDressing/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDressing/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">My father has really bad arthritis in his right shoulder and can barely move it sometimes. Which arm do I dress first when putting on a shirt?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Always dress the weak or affected arm or leg first. In your father’s case, dress his right arm first. Then dress the stronger arm or leg. When removing clothing, reverse your steps. Take clothing off the strong side first.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">My mother has dementia and she gets agitated when I try to dress her. What do I do?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Be sure you are not rushing her to dress. Also, try to follow the same routine each day so she does not think dressing is some new activity. Give her short and simple instructions as you help her dress. Respect her sense of modesty. If a task is non-essential but causing agitation, let it go and try again later when she is in a different mood.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I help the person I care for choose clothes that are easy to put on?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Thick, rough, or stretchy fabrics make it harder to don or remove clothing. Lightweight, smooth fabrics are much easier to slip on.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AlzheimersAssoc.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Alzheimer’s Association</h2>
                                                        <p>The Alzheimer’s Association of the United States is the leading voluntary organization focusing on Alzheimer’s care, support and research. The website offers tips on dressing.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.alz.org" target="_blank">www.alz.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/alzheimers-logo-mobile.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Alzheimer’s Society</h2> <p>Alzheimer’s Society is a membership organization which works to improve the quality of life of people affected by dementia in England, Wales and Northern Ireland. The Society has very useful tips for dressing a person with dementia.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.alzheimers.org.uk" target="_blank">alzheimers.org.uk</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/cdc_logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Home medical equipment companies</h2>
                                                        <p>These companies have a variety of adaptive devices. Use the recommendations of an occupational therapist.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.cdc.gov" target="_blank">www.cdc.gov</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDressingFAQResources;
