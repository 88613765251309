import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic24 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Managing
                                                Pain</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">MEDICATIONS AT HOME</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>In general, by the time you return home you will have resumed all of the
                                                medications you took at home before your operation.</p>
                                            <p>You will also be given a prescription for pain medicine. A list of
                                                medicines and dosing instructions will be given to you before
                                                discharge.</p>
                                            <p><strong>You should see your primary physician within 2 to 4 weeks of
                                                surgery for a general checkup and a review of your medications.</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">MANAGING PAIN</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>There will be some pain as you complete your recovery.</p>
                                            <p>In most cases, patients are sent home with a prescription for pain
                                                medication (typically Norco, Percocet or Oxycodone). It is important
                                                that you follow the dosing instructions properly and do NOT take more
                                                than the prescription suggests.</p>
                                            <p>After about one to two weeks, you should be able to transition to less
                                                strong pain meds such as Tylenol, Aleve or Advil.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i> A
                                                sudden increase of abdominal pain, or the appearance of a new abdominal
                                                pain is cause to contact your surgeon promptly.
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority middleGreenBackground w-100">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower text-black">Pain
                                                            medications:</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100 thickWhiteBorder"/>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom whiteBullets">
                                                                <li><strong>Hydrocodone:</strong><br/>An opioid narcotic
                                                                    that is combined with Tylenol (Vicodin, Norco) that
                                                                    can be given every 4 to 6 hours as needed.
                                                                </li>
                                                                <li><strong>Oxycodone:</strong><br/>An opioid narcotic
                                                                    that is either given alone or with Tylenol
                                                                    (Percocet).
                                                                </li>
                                                                <li><strong>NSAIDS (Non-Steroidal Anti Inflammatory
                                                                    Drugs):</strong><br/>Includes ibuprofen, naproxen.
                                                                    These are helpful to reduce narcotic use, thus
                                                                    reducing narcotic side effects such as constipation.
                                                                    Risks include kidney injury, bleeding.
                                                                </li>
                                                                <li><strong>Tylenol:</strong><br/>Similar to NSAIDS,
                                                                    Tylenol can reduce the use of narcotics and thus
                                                                    reduce their side effects. Risks include liver
                                                                    injury.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower text-black">For all
                                                            over-the-counter medications, be sure to adhere to the
                                                            dosing recommendations and amounts so as to not take too
                                                            much.</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic24;
