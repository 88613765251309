import React from 'react';
import WindowIcon from '@mui/icons-material/Window';

export const SelectList = ({
                               list,
                               setSelectedValue,
                               heading,
                               labels: {text, value}
                           }) => {

    return (
        <div className="selectContainer">
            <h1 className="text-center title" style={{marginTop: '36px',marginBottom:'56px'}}>{heading}</h1>
            <ul>
                {list.map((item, i) => <li className="hvr-grow"  key={'listItem' + i}><a key={'anchor' + i} onClick={(e) => {
                    e.preventDefault();
                    setSelectedValue(item[value]);
                }}><p >{item[text]}</p></a></li>)}
            </ul>
        </div>
    )
}
