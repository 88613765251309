export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;
export const ELEVEN = 11;
export const TWELVE = 12;
export const THIRTEEN = 13;
export const FOURTEEN = 14;
export const FIFTEEN = 15;
export const SIXTEEN = 16;
export const SEVENTEEN = 17;
export const EIGHTEEN = 18;
