import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonAspirationWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Aspiration: Show Me How'}, () => {
            console.log('Aspiration: Show Me How');    
        });    
        await dispatch(pal);
    }


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonAspiration">Aspiration
                                                            Precaution</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonAspiration/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonAspiration/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonAspiration/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonAspiration/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Talk with the Doctor</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If you believe the person you care for has dysphagia, talk to their
                                                    doctor. When the person has dysphagia, the doctor will refer the
                                                    person to a dietitian or a speech pathologist. A dietitian is
                                                    trained to identify people at risk for swallowing problems. Once the
                                                    problem is identified, the dietitian will be able to pick the best
                                                    type of diet with the proper food and liquid thickness. A speech
                                                    pathologist will evaluate swallowing abilities and aspiration risk.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Special Diets</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>There are many kinds of dysphagia diets, <strong><em>but do not
                                                    choose one on your own.</em></strong> Let a dietitian be your guide.
                                                    The dietitian will decide how to change food thickness and liquid
                                                    consistency so that a diet best matches a person’s limitations.
                                                </p>
                                                <p>Some people do best with semisolid foods that are easy to chew.
                                                    Others may need liquid or pureed (pudding like) foods, especially if
                                                    they have had mouth surgery or have mouth conditions associated with
                                                    side effects from cancer treatment. Often you will have to thicken
                                                    liquids with commercial thickeners to decrease the time it takes to
                                                    move liquid to the back of the throat for swallowing.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Talk with the Doctor</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>There are many kinds of dysphagia diets, but do not choose one on
                                                    your
                                                    own. Let a dietitian be your guide. The dietitian will decide how to
                                                    change food thickness and liquid consistency so that a diet best
                                                    matches
                                                    a person’s limitations.
                                                </p>
                                                <p>Some people do best with semisolid foods that are easy to chew.
                                                    Others
                                                    may need liquid or pureed (pudding like) foods, especially if they
                                                    have
                                                    had mouth surgery or have mouth conditions associated with side
                                                    effects
                                                    from cancer treatment. Often you will have to thicken liquids with
                                                    commercial thickeners to decrease the time it takes to move liquid
                                                    to
                                                    the back of the throat for swallowing.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Allergic Reaction</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon coralColorBar">Eating
                                                    Tips</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">When a person has difficulty swallowing, have
                                                    him or her follow these tips during a meal:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare redBullets">
                                                    <li>Give the person plenty of time to eat</li>
                                                    <li>Have the person brush their teeth and rinse the mouth thoroughly
                                                        before eating.
                                                    </li>
                                                    <li>If the person wears dentures, be sure they are thoroughly
                                                        cleaned, rinsed and placed properly in the mouth.
                                                    </li>
                                                    <li>Have the person eat small pieces of food (1/2 teaspoon). Break
                                                        apart or cut up any foods that are hard to chew.
                                                    </li>
                                                    <li>Alternating a small bite of food, and a sip of liquid may help
                                                        clear food from the mouth.Kitchen Tablespoon or teaspoon
                                                    </li>
                                                    <li>Liquids should be sipped, not gulped. Do Not use straws with
                                                        persons who have dsyphagia. A straw puts too much liquid in the
                                                        back of the throat too fast.
                                                    </li>
                                                    <li>Sucking on lemon ice or sipping lemon water will increase the
                                                        flow of saliva.
                                                    </li>
                                                    <li>Do not drink from a can.</li>
                                                    <li>Do not encourage the person to talk while eating. It is better
                                                        for you to watch and observe and avoid talking
                                                    </li>
                                                    <li>Have the person eat sitting upright, using the chin tuck
                                                        position. Raising the chin increases the risk of aspiration.
                                                    </li>
                                                    <li>Have the person sit upright after each meal (at least 30
                                                        minutes) to prevent foods or liquids from coming up into the
                                                        esophagus.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If a Person Chokes While
                                                    Eating</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If a person begins to choke while eating be sure that he or she sits
                                                    up straight. If a person can speak, the choking is not life
                                                    threatening. Have him or her spit out or remove any food or liquids
                                                    in their mouth and wait till the choking stops.
                                                </p>
                                                <p>Do not force a person to eat or drink while choking. In some cases
                                                    you might care for a person who uses oral suction routinely. Oral
                                                    suction is a good way to remove secretions.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon goldColorBar">
                                                    &nbsp;If
                                                    the person choking cannot speak, cough, or breathe, call 911
                                                    immediately.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Heimlich Maneuver</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>While waiting for emergency personnel, stay calm and perform
                                                    the <strong><em>Heimlich
                                                        Maneuver.</em></strong> See our lesson on <Nav.Link className=""
                                                                                                            href="/LessonAspiration">Feeding
                                                        a Person.</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Stand or kneel behind the person. Wrap your arms around his or
                                                        her
                                                        waist. If the person is standing, place one of your legs between
                                                        his
                                                        or her legs so you can support the person if he or she faints.
                                                    </li>
                                                    <li>Make a fist with one hand. Place the thumb side of your fist
                                                        against
                                                        the person’s belly, just above the belly button but well below
                                                        the
                                                        breastbone.
                                                    </li>
                                                    <li>Grasp your fist with the other hand. Give a quick upward thrust
                                                        up
                                                        into the belly. This may cause the food to pop out. You may need
                                                        to
                                                        use more force for a large person and less for a child or small
                                                        adult.
                                                    </li>
                                                    <li>Repeat thrusts until the object pops out or the person faints.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If you cannot reach around the
                                                    person, call
                                                    911 and do the following:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AP_heimlich.png"
                                                     className="img-responsive floatNone maxWidth300"/>
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Ease the person to the floor and place them on their back.</li>
                                                    <li>Facing the person, kneel astride the victim’s hips.</li>
                                                    <li>With one of your hands on top of the other, place the heel of
                                                        your
                                                        bottom hand on the upper abdomen below the rib cage and above
                                                        the belly
                                                        button.
                                                    </li>
                                                    <li>Use your body weight to press into the victim’s upper abdomen
                                                        with a
                                                        quick upward thrust.
                                                    </li>
                                                    <li>Repeat until object is expelled.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If the person stops breathing and loses their heartbeat (pulse), CPR
                                                    may be
                                                    necessary.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead16px"><i
                                                    className="fa fa-exclamation-circle coralColorMargin"></i>&nbsp;Do
                                                    not do
                                                    CPR on someone if you have not been trained.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i
                                                        className="fa fa-chevron-up"></i></a></p>
                                                <hr className="w-100"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AP_safety.png"
                                                     className="img-responsive floatNone maxWidth300"/>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>During a meal, check to see if the person pockets or collects
                                                        food in
                                                        the side or back of the mouth. Some people with nerve changes
                                                        cannot
                                                        feel food remaining in the mouth. Pocketed food can be
                                                        aspirated.
                                                    </li>
                                                    <li>Never rush a person to eat. Be sure to take time in what you do.
                                                        If the
                                                        person tries to rush, he or she will try to eat faster than they
                                                        can
                                                        swallow or take too large a bite of food.
                                                    </li>
                                                    <li>Know how to perform the Heimlich maneuver if a person begins to
                                                        choke on
                                                        food.
                                                    </li>
                                                    <li>Swallowing pills can often be difficult for the person with
                                                        dysphagia.
                                                        Many pills can be crushed and mixed with applesauce or pudding
                                                        to make
                                                        swallowing easier. However, always check with the pharmacist
                                                        before
                                                        crushing medicines. Some medicines, such as time released,
                                                        enteric
                                                        coated, capsules and sublingual medicines cannot be crushed
                                                        because this
                                                        affects the way the drugs work in the body.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i
                                                        className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle w-100 text-center">Video: Learn steps to
                                                protect
                                                a person from aspiration when eating or drinking liquids.</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="XHWFT4WQJ6U" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i
                                                className="fa fa-chevron-right"></i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AP_equipment.png"
                                                     className="img-responsive floatNone maxWidth300"/>
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Chair or Bed placed in 90 degree upright position</li>
                                                    <li>Thickener agent (rice, cereal, yogurt, gelatin, commercial
                                                        thickener)
                                                    </li>
                                                    <li>Mouth care supplies, including toothpaste, brush and
                                                        antimicrobial
                                                        mouthwash
                                                    </li>
                                                    <li>Assist devices as needed (cup with two handles, large handled
                                                        fork)
                                                    </li>
                                                    <li>Small flashlight (option)</li>
                                                    <li>Tongue blade (option)</li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i
                                                        className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonAspirationWhatToDoAndSafety;
