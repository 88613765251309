import React from "react";

function L14AComponentCode() {

    return (

        <div className="lego l00paragraph">
            <p>You can take steps <span className="bold">right now to guide you to be</span> your healthiest for
                your
                new start. Your success begins with creating a true understanding of
                your path through this journey – the nature of your procedure, how
                to
                help ensure the best outcomes, and what your recovery will entail.
                <span className="bold">Everyone involved – you, your family, your care team and other
                resources
                available to you</span> – can all come together to help you through this
                journey.
            </p>
        </div>

    )
}

export default L14AComponentCode;