import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin2_4 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorLightRed pritikinBorderLightRed">Healthy
                                                Mind-Set</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="italic bold">The way you think, act, and feel has an impact on
                                                your heart and overall health. </p>
                                            <p>During cardiac rehab, you will have the opportunity to increase your
                                                awareness of how you handle stress, and you will learn effective coping
                                                techniques.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 images2AcrossAndText paddingOnSides stackedCircleImagesControlImageSize">
                                        <div className="w-100">
                                            <div className="imageContainer noWrap topContainer4images flexCenter">
                                                <div className="leftCol  col-4 ">
                                                    <img
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/outlook_icon.png"
                                                        className="img-responsive maxHeight200 floatRight"/>
                                                </div>
                                                <div className="rightCol col-8">
                                                    <div className="imgCaption">
                                                        <p className="italic">You’ll learn techniques to help change the
                                                            way you view stressful events and incorporate relaxation
                                                            methods to calm your mind and body.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 images2AcrossAndText paddingOnSides stackedCircleImagesControlImageSize">
                                        <div className="w-100">
                                            <div className="imageContainer noWrap topContainer4images flexCenter">
                                                <div className="leftCol  col-4 ">
                                                    <img
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/rethinking_icon.png"
                                                        className="img-responsive maxHeight200 floatRight"/>
                                                </div>
                                                <div className="rightCol col-8">
                                                    <div className="imgCaption">
                                                        <p className="italic">You will learn how to be patient with
                                                            yourself as you form new healthy habits. Sometimes it can
                                                            take weeks, or even months, to change your behaviors and the
                                                            way you think.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 images2AcrossAndText paddingOnSides stackedCircleImagesControlImageSize">
                                        <div className="w-100">
                                            <div className="imageContainer noWrap topContainer4images flexCenter">
                                                <div className="leftCol  col-4">
                                                    <img
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/support_icon.png"
                                                        className="img-responsive maxHeight200 floatRight"/>
                                                </div>
                                                <div className="rightCol col-8">
                                                    <div className="imgCaption">
                                                        <p className="italic">Your cardiac rehab team will help you tap
                                                            into the strength of your great support system, helping you
                                                            enjoy spending time with people in your life who care about
                                                            you, and who allow you to freely express your emotions.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Ultimately, through our Healthy Mind-Set Workshops, you will discover
                                                simple and actionable techniques you can incorporate into your daily
                                                life as you continue the recovery process.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin2_4;