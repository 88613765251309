import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll7_5 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center cyanColor cyanBorderColor">Emotional
                                                Support</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader text-center w-100 floatNone">Siteman Psychology Services</h3>
                                        </div>


                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The Siteman Cancer Center provides state-of-the-art medical care. And
                                                    because we believe in treating the whole patient, we also provide
                                                    counseling support to help patients and families through the cancer
                                                    journey at Siteman Psychology Service.</p>
                                                <p>For complete information on all services offered, <a
                                                    href="https://siteman.wustl.edu/treatment/siteman-psychology-service/" target="_blank">visit
                                                    our full webpage&nbsp;<i className="fa fa-chevron-right"></i></a>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder lightBlueColorBorder">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h2>Our psychologists:</h2>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom checkboxBullets lightBlueColorBorder">
                                                        <li>Provide education, counseling and referrals to specific
                                                            resources.
                                                        </li>
                                                        <li>Teach coping skills, help with problem solving and provide
                                                            an outlet for talking about difficulties.
                                                        </li>
                                                        <li>Offer counseling services to address grief or concerns for
                                                            couples or families.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>To fully meet patient and family needs, we collaborate with
                                                    specialists in case management, financial counseling, genetic
                                                    counseling and spiritual care. We also work with the <a href="https://siteman.wustl.edu/treatment/siteman-psychology-service/" target="_blank>">Oncologic Psychiatry Service</a>, which provides treatment for Siteman patients who need psychiatric care.</p>
                                                <p>Counseling services are also available on a limited basis at our
                                                    satellite facilities in west and south St. Louis County and in St.
                                                    Charles County.</p>
                                                <p>Cancer patients and their family members can schedule an appointment
                                                    to talk about their situation and discuss which services might suit
                                                    their needs best. One appointment may be all that is needed to
                                                    receive the necessary support.</p>
                                                <p>For more information or to schedule an appointment, call <a href="tel:3147475587">314-747-5587</a>&nbsp;
                                                     or visit the Barnard Health and Cancer Information Center on
                                                    Siteman’s main campus in the Central West End area of St. Louis.</p>
                                                <p><span className="bold">For more information on Emotional Health at home after your procedure, visit our <a href="/siteman-ent-all-4-2-7">Emotional Health page</a>.</span></p>
                                                <p><span className="bold italic">Click on the links below for more information about emotional concerns associated with cancer treatment.</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p><a target="_blank" href="https://siteman.wustl.edu/glossary/cdr0000476551/">Anxiety
                                                Disorder <i className="fa fa-chevron-right"></i></a></p>
                                            <p><a target="_blank" href="https://siteman.wustl.edu/ncipdq/depression-pdq-patient-version/">Depression <i
                                                className="fa fa-chevron-right"></i></a></p>
                                            <p><a target="_blank" href="https://siteman.wustl.edu/ncipdq/adjustment-to-cancer-anxiety-and-distress-pdq-patient-version/">Normal
                                                Adjustment and the Adjustment Disorders <i
                                                    className="fa fa-chevron-right"></i></a></p>
                                            <p><a target="_blank" href="https://siteman.wustl.edu/ncipdq/cancer-related-post-traumatic-stress-pdq-patient-version/">Post-traumatic
                                                Stress Disorder <i className="fa fa-chevron-right"></i></a></p>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop lightBlueColor" href="#">BACK TO
                                                    TOP <i className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll7_5;
