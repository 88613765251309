import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './profile.module.scss';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import InputMask from 'react-input-mask';
import FileUpload from "../../fileUpload/index.js";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TopNav from "../../routing/topNav/index.js";
import {Avatar} from "@material-ui/core";
import {InputWithErrorMessage} from "../../../components/forms/inputWithErrorMessage.js";
import {
    validateConfirmPassword,
    validateConfirmPasswordProfile,
    validateEmailAddress,
    validateFirstName,
    validateLastName,
    validatePassword,
    validatePasswordProfile,
    validatePhoneNumber
} from "./validation.js";
import {register} from "../../../actions/register.js";
import {orbitClusterTileList} from "../../../actions/orbitClusterTileList.js";
import {profile} from "../../../actions/profile.js";
import {userProfile} from "../../../actions/userProfile.js";
import {profileImageUpload} from "../../../actions/profileImageUpload.js";
import {loginUser} from "../../../actions/login.js";
import {profileImageRemove} from "../../../actions/profileImageRemove.js";
import {REGISTER_ERROR, UPDATE_PROFILE_URL} from "../../../actions/types.js";
import {validateUserName} from "../register/validation.js";
import { ToastContainer, toast } from 'react-toastify';

const Profile = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isDirty, setIsDirty] = useState(false);
    let {
        errorMessage,
        userFirstName,
        userLastName,
        userEmailAddress,
        userPhoneNumber
    } = useSelector(state => state.userProfile);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
        }

    }, [errorMessage])

    useEffect(async () => {
        const ca = await userProfile({}, (data) => {
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmailAddress(data.emailAddress);
            setPhoneNumber(data.phoneNumber);
            setImageUrl(data.imageUrl);

            if (data && data.firstName && data.lastName && data.emailAddress) {
                setIsDirty(true);
            }
        });
        await dispatch(ca);

        //get default orbit for logged in user
    }, []);

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    const removeImage = async (e) => {
        e.preventDefault();
        const deleteImage = await profileImageRemove({}, () => {
            setUserMessage('Profile image removed successfully.');
            setImageUrl('');
        })
        await dispatch(deleteImage);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (!await validateAllFields())
            return;

        if (selectedImage && selectedImage?.name) {
            const profileUpload = await profileImageUpload(selectedImage, async (fileName) => {
                const userProfile = await profile({
                    firstName,
                    lastName,
                    emailAddress,
                    phoneNumber,
                    password,
                    confirmPassword,
                    imageUrl: fileName
                }, async (data) => {
                    await dispatch({
                        type: UPDATE_PROFILE_URL,
                        payload: data.imageUrl
                    })
                    navigate('/');
                })

                await dispatch(userProfile);
            })
            await dispatch(profileUpload);
        } else {
            const userProfile = await profile({
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                password,
                confirmPassword
            }, () => {
                navigate('/');
            })
            await dispatch(userProfile);
        }
    }

    const changeFirstName = (e) => setFirstName(e.target.value);
    const changeLastName = (e) => setLastName(e.target.value);
    const changeEmailAddress = (e) => setEmailAddress(e.target.value);
    const changePhoneNumber = (e) => setPhoneNumber(e.target.value);
    const changePassword = (e) => setPassword(e.target.value);
    const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);

    const validateFirstNameField = async (e) => {
        await setIsDirty(true);
        await setFirstNameError(validateFirstName(firstName));
    }

    const validateLastNameField = async (e) => {
        await setIsDirty(true);
        await setLastNameError(validateLastName(lastName));
    }

    const validateEmailAddressField = async (e) => {
        await setIsDirty(true);
        await setEmailAddressError(validateEmailAddress(emailAddress));
    }

    const validatePhoneNumberField = async (e) => {
        await setIsDirty(true);
        await setPhoneNumberError(validatePhoneNumber(phoneNumber));
    }

    const validatePasswordField = async (e) => {
        await setIsDirty(true);
        setPasswordError(validatePasswordProfile(confirmPassword));
    }

    const validateConfirmPasswordField = async (e) => {
        setConfirmPasswordError(validateConfirmPasswordProfile(password, confirmPassword));
    }

    const validateAllFields = async () => {
        await setIsDirty(true);
        let invalidPassword = '';
        let invalidConfirmPassword = '';

        const invalidFirstName = validateFirstName(firstName);
        const invalidLastName = validateLastName(lastName);
        const invalidEmailAddress = validateEmailAddress(emailAddress);
        const invalidPhoneNumber = validatePhoneNumber(phoneNumber);

        if (password.length > 0) {
            invalidPassword = validatePassword(password, confirmPassword);
            invalidConfirmPassword = validatePassword(password, confirmPassword);
        }

        setFirstNameError(invalidFirstName);
        setLastNameError(invalidLastName);
        setEmailAddressError(invalidEmailAddress);
        setPhoneNumberError(invalidPhoneNumber);
        setPasswordError(invalidPassword);
        setConfirmPasswordError(invalidConfirmPassword);

        if (invalidFirstName.length > 0 ||
            invalidLastName.length > 0 ||
            invalidEmailAddress.length > 0 ||
            invalidPhoneNumber.length > 0 ||
            invalidPassword.length > 0 ||
            invalidConfirmPassword.length > 0)
            return false;
        else
            return true;
    }

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <ToastContainer />
                <Container className="registration">
                    <div className="pageContainer">
                        <h1 className="text-center title headlineText">Profile Information</h1>
                        <Box className="material-box" component="form" Validate autoComplete="off"
                             onSubmit={submitForm}>
                            <div className="card-body">
                                <div className="formFlexContainer">
                                    <div className="formInput profileTopSection">
                                        <div className="profileImageContainer">

                                            <Avatar
                                                alt={selectedImage?.name}
                                                src={imageUrl}
                                                sx={{width: 96, height: 96}}
                                                className="avatarImage"
                                                name="previewImage"
                                                id="previewImage"
                                            />
                                        </div>
                                        <span className="buttonContainerProfile">
                                            <input
                                                accept="image/*"
                                                type="file"
                                                id="select-image"
                                                style={{display: 'none'}}
                                                onChange={e => setSelectedImage(e.target.files[0])}
                                                name="select-image"
                                            />
                                            <label className="buttonContainerUpload" htmlFor="select-image">
                                                <Button variant="text" color="primary"
                                                        component="span">
                                                  Upload image
                                                </Button>
                                            </label>
                                            <Button variant="text" color="primary"
                                                    onClick={removeImage} className="deleteProfile"
                                                    component="span">
                                              Delete image
                                            </Button>
                                        </span>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={firstNameError}>
                                            <TextField className="w-100" name="firstName" id="firstName"
                                                       label="First Name" variant="outlined" value={firstName ?? ''}
                                                       onChange={changeFirstName} onBlur={validateFirstNameField}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={lastNameError}>
                                            <TextField className="w-100" name="lastName" id="lastName" label="Last Name"
                                                       variant="outlined" value={lastName ?? ''}
                                                       onChange={changeLastName} onBlur={validateLastNameField}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={emailAddressError}>
                                            <TextField className="w-100" name="email" id="email" type="email"
                                                       label="Email" variant="outlined" value={emailAddress ?? ''}
                                                       onChange={changeEmailAddress}
                                                       onBlur={validateEmailAddressField}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={phoneNumberError}>
                                            <TextField className="w-100" name="phone" id="phone" type="phone"
                                                       label="Phone" variant="outlined" value={phoneNumber ?? ''}
                                                       onChange={changePhoneNumber} onBlur={validatePhoneNumberField}>
                                                <InputMask mask="(0)999 999 999" maskChar=" "/>
                                            </TextField>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={passwordError}>
                                            <TextField type="password" className="w-100" name="password" id="password"
                                                       label="Password"
                                                       variant="outlined" onChange={changePassword}
                                                       autoComplete="new-password" onBlur={validatePasswordField}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={confirmPasswordError}>
                                            <TextField type="password" className="w-100" name="confirmPassword"
                                                       id="confirmPassword" label="Re-Enter Password"
                                                       variant="outlined" onChange={changeConfirmPassword}
                                                       onBlur={validateConfirmPasswordField}/>
                                            <span style={{color: "#00ff00"}}>{userMessage}</span>
                                        </InputWithErrorMessage>
                                    </div>

                                    <div className="buttonContainer loginButtonContainer">
                                        <Button className="loginUIComp center" variant="contained" color="primary"
                                                type="submit">
                                            Update Profile
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Profile;
