import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTL7_1 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'The Power of Help: The Power of Support'}, () => {
            console.log('The Power of Help: The Power of Support');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center cyanColor cyanBorderColor">The
                                                Power of Help</h1>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Adjusting to
                                                    life after a laryngectomy can be difficult, and anxiety and
                                                    depression are very common. </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Your healthcare team, friends, and family can help provide a strong
                                                    support network that can make a real difference.</p>
                                                <p>Know that you are <span className="bold">never alone</span> in your
                                                    healing process.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>In addition to your healthcare team, there are local support groups and
                                                online resources available which can be of great help on your journey to
                                                your new normal.</p>
                                            <p>A useful goal, starting the moment you first learn of your diagnosis and
                                                treatment plan, is to make sure you and any close friend or family
                                                member who will be helping you have a good understanding of all the many
                                                forms of help that are available at each step of your journey. </p>
                                            <p>Too often, families don’t take full advantage of the network of resources
                                                that are always there.</p>
                                            <p>At every step of your experience, knowledge is power and comfort.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="8VZutRnnMLk" opts={opts} onPlay={playVideo} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">The Power of Support</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <hr className="w-100" style={{marginTop: "0"}}/>
                                    </div>
                                    <div className="col-12">
                                        <p>Be sure to review all of the various types of help covered in this guide
                                            including:</p>
                                        <p><a href="/siteman-ent-tl-7-2">Advice from patients and families <i
                                            className="fa fa-chevron-right"></i></a></p>
                                        <p><a href="/siteman-ent-all-7-3">In-system services <i
                                            className="fa fa-chevron-right"></i></a></p>
                                        <p><a href="/siteman-ent-tl-7-4">Communities & Other Help <i
                                            className="fa fa-chevron-right"></i></a></p>
                                        <p><a href="/siteman-ent-all-7-5">Emotional support resources <i
                                            className="fa fa-chevron-right"></i></a></p>
                                        {/*<p><a href="/siteman-ent-all-7-6">Vendors <i*/}
                                        {/*    className="fa fa-chevron-right"></i></a></p>*/}
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL7_1;
