import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LessonNebulizersIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonNebulizers">Nebulizers</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonNebulizers/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonNebulizers/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Using a Nebulizer</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img className="floatLeft wrapImage col-6 clearLeft marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NEB_1.png" alt="" />
                                            <p className="floatNone clearNone col-6 ">A nebulizer is a small machine that delivers medicine in a mist to a person’s breathing passages. Breathing in medicines in moist air to the lungs improves the ability to breathe and cough up any thick secretions.</p>
                                            <p className="floatNone clearNone col-6 ">Nebulizers are commonly used for persons with asthma, emphysema, bronchitis, and cystic fibrosis. Medicines given through a nebulizer include bronchodilators (which widen airway passages), steroids(which reduce inflammation and narrowing of airways),and mucolytics (which thin mucus making secretions easier to cough up).</p>
                                            <p className="floatNone clearNone col-6 ">Nebulizers can also deliver humidified oxygen (oxygen mixed with a water mist). Oxygen alone is very dry. However, when given through a nebulizer, the oxygen and water create a mist, which is less irritating to mucus membranes so secretions remain wet and loose. This is a common treatment for people with emphysema and who have tracheostomies.</p>
                                            <p>A nebulizer is easy to use, but it takes about 10 minutes to deliver a medicine. The nebulizer device is powered by a compressed air machine and plugs into an electrical outlet. Portable nebulizers, powered by batteries or automobile cigarette lighters, are available for individuals requiring treatments away from home. A nebulizer weighs under 10 pounds and it is easy to move from place to place.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A nebulizer is easy to use, but it takes about 10 minutes to deliver a medicine.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle w-100 text-center">Video: Nebulizers</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="-hMZDePH1n8" opts={opts}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer orangeBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonNebulizersIntroduction;
