import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL11AComponent({ code, language }) {
    code = `{/* L11B Image Max Width 300px */}
                                    <div className="lego l011 legoMargin5 d-flex justify-content-center">
                                        <img className="imageW100 maxWidth300" alt=""
                                             src="https://s3-alpha-sig.figma.com/img/d99d/2ae0/389f9d194c0fe0b172c13d06fe029a81?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LhgvbPozqyWU9cpkXLnnkS6D5ApbHcRbeY2obbSHenO~FdEeTl1wY07Yz733irqEfkJqsKiOJ4qX-LKaGXXthIg6BNtxYA4ZnsX9mYYwGFKXxNNDrfI5uL3vBn3JAaBdisa5bpTcCKYlIgQ2GxMz4V4gaXIcvRLLnDhdvXLzJx1zYaqX7gHviUC1cI4T06Sm0pY~dUUe7qXd9Pcnpen4RPZLmaNES0OWgMRVKYhAj2GK5SqIHV3LO-hhEFyLJMUERkEG6yb6ycVti2b8FSWeOFeojU0CcJgG83AxeaJBpJWL36fomsntpLLkbVlLe7jTur2Dn84yX8cdin4XHZPgrw__"/>
                                    </div>
                                    {/* End L11B Image Max Width 300px */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL11AComponent;