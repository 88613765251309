import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport1_1 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                                <section>
                                    {/* L1 Tile Title Header */}
                                    <div className="lego l001 legoMargin5 blueColorAndBorder">
                                        <h1 className="tileTitle">Welcome!</h1>
                                        <hr className="w-100 l001Rule"/>
                                    </div>
                                    {/* End Tile Title Header L1 */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">Our Mission and Vision</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph">
                                        <p>Our vision is to redefine excellence in the field of Orthopaedic Surgery and
                                            Rehabilitation.
                                        </p>
                                        <p>Our mission is to have a national and international impact on orthopedics
                                            through excellence in clinical care, education, research and leadership.
                                        </p>
                                        <p>We will accomplish this goal by providing evidence-based and cost-effective
                                            musculoskeletal care with access for all. We will increasingly become the
                                            authoritative resource locally, regionally, and nationally for the most
                                            straightforward to the most complex orthopaedic problems by supporting all
                                            faculty and removing access barriers for patients. We will seek to train
                                            future leaders, providing outstanding musculoskeletal education in an
                                            inclusive and equitable environment. The Department will support and
                                            resource basic, translational, and clinical research to transform the future
                                            of orthopaedics through discovery and innovation.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L3 Subhead Upper and Lower */}
                                    <div className="lego l003 legoMargin3">
                                        <h2 className="subHeadUpperAndLowerCase">We are committed to the highest quality
                                            of care and patient safety.</h2>
                                    </div>
                                    {/* End L3 Subhead Upper and Lower */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin2">
                                        <p>We're proud to rank #1 in St. Louis and Missouri for adult orthopedic care.
                                            We're also #13 in the nation for pediatric orthopedic care. Our <a
                                                className="consistentLinkColor"
                                                href="https://www.ortho.wustl.edu/content/Patient-Care/8181/Services/Hip-Knee/Adult-Reconstruction-and-Hip-Preservation-Overview/What-is-a-Total-Joint-Replacement-Program.aspx"
                                                rel="noreferrer noopener" target="_blank"> total
                                                joint
                                                replacement program</a> at Barnes-Jewish Hospital and Barnes-Jewish West
                                            County
                                            Hospital has earned the Joint Commission’s Gold Seal of Approval for quality
                                            and safety of joint replacement procedures, and we are consistently
                                            recognized for our commitment to trusted, excellent care for all patients.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L11A Image Max Width 400px */}
                                    <div className="lego l011">
                                        <img alt="" className="imageW100 maxWidth400"
                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7e1df8674b2769484bd6e9d04771bb23.png"/>
                                    </div>
                                    {/* End L11A Image Max Width 400px */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L3 Subhead Upper and Lower */}
                                    <div className="lego l003 legoMargin3">
                                        <h2 className="subHeadUpperAndLowerCase">We are committed to the highest quality
                                            of care and patient safety.</h2>
                                    </div>
                                    {/* End L3 Subhead Upper and Lower */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin5">
                                        <p>As the #3 NIH-funded orthopedic research program in the United States, our
                                            orthopedic providers and research faculty are turning their scientific
                                            discoveries into future orthopedic treatments. Areas of research include
                                            cartilage biology and joint disease, tendon injury and repair, and bone
                                            injury and repair. We're also looking at how stem cells promote
                                            musculoskeletal tissue regeneration of bone, cartilage, and
                                            tendons/ligaments.
                                        </p>

                                        <p>An <a className="consistentLinkColor"
                                                 href="https://www.ortho.wustl.edu/content/Research/7034/Achieving-Excellence---Research-at-Washington-University-Orthopedics/Orthopedic-Clinical-Research-Center.aspx"
                                                 rel="noreferrer noopener" target="_blank">Orthopedic Clinical Research
                                            Center (OCRC)</a> was developed to support clinical research in areas such
                                            as rotator cuff disease, anterior cruciate ligament injury, upper extremity
                                            nerve injury, and the non-operative management of hip impingement. The OCRC
                                            is currently working on over 400 active clinical research studies, with two
                                            of those being large scale, multi-center studies.
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP </a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default InjectionSupport1_1;