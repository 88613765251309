import React from 'react';
import Container from 'react-bootstrap/Container';
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";

const LessonFallPreventionFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFallPrevention">Fall Prevention</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonFallPrevention/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonFallPrevention/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If a person has fallen once, is there a good chance they will fall again?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Yes, a person who has a history of falling is at risk to fall again.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If a person falls, what is the first thing I should do?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Do not try to move the person. Have them lie still until you learn if they are hurt.</p>
                                                <p><span classs="bold italic">Is the person alert and able to talk to you?<br />
                                            Do they have pain they have not had before?<br />
                                            Are they unable to move the arm, leg or neck? Is there any bleeding?</span></p>
                                                <p>See this lesson’s <Nav.Link className="" href="/LessonDisposal/WhatToDoAndSafety">What to Do section</Nav.Link> for actions to take in case of a fall injury. Also, see our lesson on <Nav.Link className="" href="/LessonMoving">Moving Safely in the Home</Nav.Link> for tips on how to help a person fall more safely and how to help get the person up after a fall.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/cdc_logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Center for Disease Control and Prevention (CDC)</h2>
                                                        <p>The CDC has a National Center for Injury Prevention providing a full set of resources of how to prevent falls.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.cdc.gov" target="_blank">www.cdc.gov</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NOF_logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">National Osteoporosis Foundation (NOF)</h2>
                                                        <p>Started in 1984, the NOF is the nation’s leading voluntary health organization solely dedicated to osteoporosis and bone health.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nof.org/" target="_blank">www.nof.org/</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NAHB_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">National Association of Home Builders</h2>
                                                        <p>Has information regarding Certified Aging-In-Place Specialists (CAPS) who design and adapt homes so that a person can continue living in a familiar environment throughout their maturing years.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nahb.org/" target="_blank">www.nahb.org/</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NSC_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">National Safety Council</h2>
                                                        <p>The Council is a non-for-profit charitable organization that has the mission to educate people on ways to prevent accidental injury and death. The website contains numerous resources on falls and fall prevention tips.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nsc.org" target="_blank">www.nsc.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonFallPreventionFAQResources;
