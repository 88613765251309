import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonDisposalIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Introduction to Disposing Medical Waste'}, () => {
            console.log('Introduction to Disposing Medical Waste');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDisposal">Disposal
                                                            of Medical Waste</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation ">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION 
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/medical_weste_container_1.png"
                                        className="img-responsive maxWidth300 m-auto mb-2"/>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is Medical Waste?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Medical waste is any object or piece of equipment that carries
                                                    infectious material, such as blood, urine, stool, drainage from a
                                                    wound, and vomited food or liquid. Examples of medical waste
                                                    include:
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Soiled bandages</li>
                                                    <li>Soiled bed linens or clothing</li>
                                                    <li>Lancets used for finger sticks</li>
                                                    <li>Blood and urine test strips</li>
                                                    <li>Drainage tubing from wound drains</li>
                                                    <li>Intravenous tubing</li>
                                                    <li>Used disposable razors</li>
                                                    <li>Dirty gloves</li>
                                                    <li>Dirty tissue from nasal discharge</li>
                                                    <li>Bed pans and urinals</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>This lesson will teach you the health risks from contact with medical
                                                    waste and how to properly throw away items you use when caring for
                                                    someone in the home.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="">
                                            <p>When you care for someone in the home, there is a good chance that you
                                                will come in contact with medical waste.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="">
                                            <h3 className="blueColor videoTitle w-100 text-center">Video: Introduction
                                                to Disposing Medical Waste</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="JjSfIWxPf7c" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File
                                                    <i className="fa fa-chevron-right">&nbsp;</i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                           
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDisposalIntroduction;