import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AUTH_ERROR, UPDATE_BODY_CSS} from "../../actions/types";

const FavIconLoader = (props) => {
    const {orgImageUrl, favIconAttributes, orbitCssClass, bodyCss} = useSelector(state => state.logo);
    const dispatch = useDispatch();

    useEffect(async () => {
        //let link = document.querySelector("link[rel~='icon']");
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        if (link && orgImageUrl)
            link.href = orgImageUrl;

        let cssClassProps = document.querySelector("body");
        let linkOneHundredTwentyEight = document.querySelector("link[rel~='icon'][sizes='128x128']");
        let linkOneHundredNinetyTwo = document.querySelector("link[rel~='icon'][sizes='192x192']");
        let linkAppleTouchIconSeventyTwo = document.querySelector("link[rel~='apple-touch-icon'][sizes='72x72']");
        let linkAppleTouchIconOneHundredFourteen = document.querySelector("link[rel~='apple-touch-icon'][sizes='114x114']");
        let linkAppleTouchIconOneHundredFortyFour = document.querySelector("link[rel~='apple-touch-icon'][sizes='144x144']");
        let linkAppleTouchIconOneHundredNinetyTwo = document.querySelector("link[rel~='apple-touch-icon'][sizes='192x192']");

        if (cssClassProps && orbitCssClass && orbitCssClass.length > 0)
            cssClassProps.className = 'newOrbitStructure contentPage safecare ' + orbitCssClass.join(' ');

        if (linkOneHundredNinetyTwo && favIconAttributes && favIconAttributes?.oneHundredNinetyTwo)
            linkOneHundredNinetyTwo.href = favIconAttributes?.oneHundredNinetyTwo;

        if (linkOneHundredTwentyEight && favIconAttributes && favIconAttributes?.oneHundredTwentyEight)
            linkOneHundredTwentyEight.href = favIconAttributes?.oneHundredTwentyEight;

        if (linkAppleTouchIconSeventyTwo && favIconAttributes && favIconAttributes?.appleTouchIconSeventyTwo)
            linkAppleTouchIconSeventyTwo.href = favIconAttributes?.appleTouchIconSeventyTwo;

        if (linkAppleTouchIconOneHundredFourteen && favIconAttributes && favIconAttributes?.appleTouchIconOneHundredFourteen)
            linkAppleTouchIconOneHundredFourteen.href = favIconAttributes?.appleTouchIconOneHundredFourteen;

        if (linkAppleTouchIconOneHundredFortyFour && favIconAttributes && favIconAttributes?.appleTouchIconOneHundredFortyFour)
            linkAppleTouchIconOneHundredFortyFour.href = favIconAttributes?.appleTouchIconOneHundredFortyFour;

        if (linkAppleTouchIconOneHundredNinetyTwo && favIconAttributes && favIconAttributes?.appleTouchIconOneHundredNinetyTwo)
            linkAppleTouchIconOneHundredNinetyTwo.href = favIconAttributes?.appleTouchIconOneHundredNinetyTwo;
    }, []);

    return (
        props.children
    );
}

export default FavIconLoader;
