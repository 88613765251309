import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_6 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                                <section>
                                    {/* L1 Tile Title Header */}
                                    <div className="lego l001 greenColorAndBorder legoMargin5">
                                        <h1 className="tileTitle">Other Treatments</h1>
                                        <hr className="w-100 l001Rule"/>
                                    </div>
                                    {/* End Tile Title Header L1 */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin5">
                                        <h2 className="subHeadAllCaps">PERIPHERIAL NERVE STIMULATION (PNS)</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L3 Subhead Upper and Lower */}
                                    <div className="lego l003 legoMargin3">
                                        <h2 className="subHeadUpperAndLowerCase">About the Procedure.</h2>
                                    </div>
                                    {/* End L3 Subhead Upper and Lower */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin5">
                                        <p>The Sprint Peripherial Nerve Stimulator is uniquely designed to selectively
                                            and robustly stimulate targeted peripheral nerve fibers for up to 60 days,
                                            modulating central plasticity to enable significant and sustained pain
                                            relief. The procedure is minimally invasive and there will be no permanent
                                            implant. </p>
                                        <p><span className="bold italic">You’ll find more details on this supplemental guide viewable here.</span>
                                        </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L53 Bullet List */}
                                    <div className="lego l053 smallFont legoMargin5">
                                        <ul className="w-100">
                                            <li className="w-100">Let your doctor or nurse know if you are on any blood
                                                thinners such as Aggrenox, Coumadin, Effient, Lovenox, Pletal, Pradaxa,
                                                Ticlid or Xarelto.
                                            </li>
                                            <li className="w-100">It is okay to eat/drink/take your medications as
                                                usual.
                                            </li>
                                            <li className="w-100">Bring someone with you to drive you home.
                                            </li>
                                            <li className="w-100">An x-ray machine may be used during the procedure. Let
                                                your doctor or nurse know if there is any chance you may be pregnant.
                                            </li>
                                            <li className="w-100">A SPRINT rep will meet with you and go over PNS system
                                                and questions
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End L53 Bullet List */}

                                    {/* L3 Subhead Upper and Lower */}
                                    <div className="lego l003 legoMargin3">
                                        <h2 className="subHeadUpperAndLowerCase">What should I expect during my
                                            procedure?</h2>
                                    </div>
                                    {/* End L3 Subhead Upper and Lower */}

                                    {/* L53 Bullet List */}
                                    <div className="lego l053 smallFont legoMargin5">
                                        <ul className="w-100">
                                            <li className="w-100">Your procedure will be done on an x-ray table while
                                                you are awake.
                                            </li>
                                            <li className="w-100">A technician will be taking x-rays or working an
                                                ultrasound machine.
                                            </li>
                                            <li className="w-100">A nurse will monitor your blood pressure, breathing
                                                and heart rate.
                                            </li>
                                            <li className="w-100">You will have an IV and receive antibiotics.</li>
                                            <li className="w-100">Your doctor will use a local anesthetic to numb the
                                                area.
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End L53 Bullet List */}

                                    {/* L3 Subhead Upper and Lower */}
                                    <div className="lego l003 legoMargin3">
                                        <h2 className="subHeadUpperAndLowerCase">After the procedure.</h2>
                                    </div>
                                    {/* End L3 Subhead Upper and Lower */}

                                    {/* L53 Bullet List */}
                                    <div className="lego l053 smallFont">
                                        <ul className="w-100">
                                            <li className="w-100">A nurse will monitor you for a brief time.</li>
                                            <li className="w-100">You may feel sore at the injection site.</li>
                                            <li className="w-100">You may feel light headed or dizzy. These symptoms
                                                usually wear off in 6-8 hours and are almost always gone by the next
                                                morning.
                                            </li>
                                            <li className="w-100">A representative from SPRINT will join you in the
                                                recovery area and go through instructions on the device with you.
                                            </li>
                                            <li className="w-100">You will make an appointment to return in 2 weeks to
                                                follow up with the doctor and SPRINT rep.
                                            </li>
                                            <li className="w-100">You will make an appointment to return in 60 days to
                                                follow up with the doctor and SPRINT rep for implant removal.
                                            </li>
                                            <li className="w-100">A SPRINT rep will call you after your procedure to
                                                follow-up.
                                            </li>
                                        </ul>
                                    </div>
                                    {/* End L53 Bullet List */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L89 Content Block with Title and Background Color */}
                                    <div className="lego l089 legoMargin3">
                                        <div className="contentContainer">
                                            <h4 className="legoMargin4">USING YOUR SPRINT PNS SYSTEM</h4>
                                            <p className="withBorderBottom">Do not use the system while driving,
                                                operating machinery, or during any activity in which sudden muscle
                                                tension may put you at risk of injury</p>
                                            <p className="withBorderBottom">Never soak the exit site in water. It is
                                                okay to shower. Always remove the Pulse Generator (stimulator) and
                                                Mounting Pad from your body and keep the Waterproof Bandage over your
                                                exit site when showering.</p>
                                            <p className="withBorderBottom">Bathing and Swimming should be avoided
                                                during the treatment.</p>
                                            <p className="withBorderBottom">A MicroLead (thin, thread-like wire) exits
                                                your skin under the Waterproof Bandage. Do not pull on it.</p>
                                            <p className="withBorderBottom">Change the Waterproof Bandage & Cradle when
                                                they become soiled, damp, or no longer adhere well to the skin (about
                                                every 4-5 days). Take care when removing these to prevent pulling the
                                                MicroLead out.</p>
                                            <p className="withBorderBottom">Limit strenuous physical activity & motion
                                                (such as twisting, bending, climbing, lifting) near the implant for at
                                                least one week. Avoid excessive stress (tugging, pressure, heat) that
                                                may damage the stimulation system.</p>
                                            <p className="withBorderBottom">You may resume exercise and/or physical
                                                therapy when directed by your doctor.</p>
                                            <p className="withBorderBottom">Watch for signs and/or symptoms of
                                                infection.</p>
                                            <p className="withBorderBottom">Check temperature if having fever or chills.
                                                Notify Physiatry for temperature over 100.4o or greater.</p>
                                            <p className="withBorderBottom">Observe dressing daily for red ness,
                                                swelling, drainage, or foul odor around or on dressing.</p>
                                        </div>

                                    </div>
                                    {/* End L89 Content Block with Title and Background Color */}

                                    {/* L90 Color Top Bar with special icon list */}
                                    <div className="lego l090 legoMargin3">
                                        <div className="headerColorBar">
                                            <h3>COMPLICATIONS:</h3>
                                        </div>
                                        <div className="contentContainer">
                                            <ul>
                                                <li>The most common risks <span className="bold italic">are skin irritation and redness</span> that
                                                    usually
                                                    go away within a few days without the need for medical care.
                                                </li>
                                                <li><span className="bold italic">Infection</span> (may be indicated by
                                                    a fever of 100.4o or greater, heat,
                                                    redness, or drainage at the injection site)
                                                </li>
                                                <li>Review the <span className="bold italic">Warnings, Precautions & Risks section</span> in
                                                    the <span className="bold italic">Patient
                                                    Instructions for Use</span> for potential hazard that may cause
                                                    injury,
                                                    discomfort, or damage to your system. Examples include MRI &
                                                    diathermy.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* End L90 Color Top Bar with special icon list */}

                                    {/* L91 Bold Lead In with List Small font */}
                                    <div className="lego l091">
                                        {/* L10A Bold Lead In */}
                                        <div className="lego l00paragraph">
                                            <p className="boldLeadInNewUI">Using the System at Home
                                            </p>
                                        </div>
                                        {/* End L10A Bold Lead In */}

                                        {/* L53 Bullet List */}
                                        <div className="lego l053 legoMargin3 smallFont">
                                            <ul className="w-100">
                                                <li className="w-100">Make sure that the Pulse Generator is snapped onto
                                                    a
                                                    Mounting Pad. The entire surface of the Mounting Pad must be in
                                                    contact
                                                    with your skin. Check that all cables are firmly connected.
                                                </li>
                                                <li className="w-100">Turn stimulation ON for the following amount each
                                                    day
                                                    check one: (A) 6-12 hours (B) 24 hours
                                                </li>
                                                <li className="w-100">If stimulation feels uncomfortable or painful,
                                                    decrease the intensity to a level that is comfortable.
                                                </li>
                                                <li className="w-100">Replace & recharge the Pulse Generator battery
                                                    when
                                                    the battery icon on your remote shows 1 bar.
                                                </li>
                                                <li className="w-100">Always have one battery charging in the Recharging
                                                    Base so that you never miss your daily therapy.
                                                </li>
                                            </ul>
                                        </div>
                                        {/* End L53 Bullet List */}
                                    </div>
                                    {/* End L91 Bold Lead In with List Small font */}

                                    {/* L91 Bold Lead In with List Small font */}
                                    <div className="lego l091">
                                        {/* L10A Bold Lead In */}
                                        <div className="lego l00paragraph">
                                            <p className="boldLeadInNewUI">Cleaning and Care
                                            </p>
                                        </div>
                                        {/* End L10A Bold Lead In */}

                                        {/* L53 Bullet List */}
                                        <div className="lego l053 legoMargin3 smallFont">
                                            <ul className="w-100">
                                                <li className="w-100">A MicroLead (thin, thread-like wire) exits your
                                                    skin
                                                    under the Waterproof Bandage. Do NOT pull on it.
                                                </li>
                                                <li className="w-100">Change the Waterproof Bandage & Cradle when they
                                                    become soiled, damp, or no longer adhere well to the skin (about
                                                    every
                                                    4-5 days). Take care when removing these to prevent pulling the
                                                    MicroLead out.
                                                </li>
                                                <li className="w-100">Clean skin under the bandage with mild soap &
                                                    water OR
                                                    wipe skin with an alcohol pad. Allow skin to dry.
                                                </li>
                                                <li className="w-100">Review the Changing the Bandage and Cradle section
                                                    of
                                                    the QUICK START GUIDE for detailed instructions.
                                                </li>
                                                <li className="w-100">Check the skin under the bandage for redness,
                                                    swelling, drainage, or bleeding during bandage changes.
                                                </li>
                                                <li className="w-100">If any of these signs are present, or if your
                                                    MicroLead(s) pull out, notify your doctor and an SPR Therapeutics
                                                    representative.
                                                </li>
                                            </ul>
                                        </div>
                                        {/* End L53 Bullet List */}
                                    </div>
                                    {/* End L91 Bold Lead In with List Small font */}

                                    {/* L91 Bold Lead In with List Small font */}
                                    <div className="lego l091">
                                        {/* L10A Bold Lead In */}
                                        <div className="lego l00paragraph">
                                            <p className="boldLeadInNewUI">Troubleshooting
                                            </p>
                                            <p>Review the QUICK START GUIDE and the Appendix B: Troubleshooting section
                                                in
                                                the Patient Instructions for Use for assistance with your device.</p>
                                        </div>
                                        {/* End L10A Bold Lead In */}

                                        {/* L53 Bullet List */}
                                        <div className="lego l053 legoMargin3 smallFont">
                                            <ul className="w-100">
                                                <li className="w-100">Additional instructional videos and patient
                                                    resources
                                                    are available at the SPR Therapeutics website:
                                                    https://www.sprtherapeutics.com/quickstart
                                                </li>
                                                <li className="w-100">f you are running low on any supplies, or if you
                                                    need
                                                    help with other questions, problems, or errors on your remote,
                                                    please
                                                    call a SPR Therapeutics representative.
                                                </li>
                                            </ul>
                                        </div>
                                        {/* End L53 Bullet List */}
                                    </div>
                                    {/* End L91 Bold Lead In with List Small font */}

                                    {/* L92 Bold Lead In Title, Name, Job Title, phone number and URL */}
                                    <div className="lego l092">
                                        {/* L10A Bold Lead In */}
                                        <div className="lego l00paragraph">
                                            <p className="boldLeadInNewUI">SPR Therapeutics Representative
                                            </p>
                                        </div>
                                        {/* End L10A Bold Lead In */}

                                        {/* L14 Paragraph */}
                                        <div className="lego l00paragraph">
                                            <p><span className="bold italic">Kimberlee Griffey</span><br/>
                                                Regional Sales Manager<br/>
                                                <a href="tel:636-667-7493">636-667-7493</a><br/>
                                                <a href="https://www.SprintPNS.com">www.SprintPNS.com</a>
                                            </p>
                                        </div>
                                        {/* End L14 Paragraph */}
                                    </div>
                                    {/* L92 Bold Lead In Title, Name, Job Title, phone number and URL */}


                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* End L53 Bullet List */}

                                    {/* L84 Block */}
                                    <div className="lego l084 legoMargin5">
                                        <h3 className="contentTitle">Billing for Injection Procedures</h3>
                                        <p>Our Washington University orthopedic specialists treat patients at
                                            BJC
                                            HealthCare facilities, which means you may receive two separate
                                            bills. One
                                            bill is for the physician and the other is for the facility
                                            charges. </p>

                                        {/* L82? Content 2 sides 50% width */}
                                        <div className="lego l082 p-0">
                                            <div className="contentLeftSide">
                                                {/* L11 Image */}
                                                <div className="lego l011 legoMargin3">
                                                    <img className="imageW100 noBorderRadius"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"/>
                                                </div>
                                                {/* End L11 Image */}

                                            </div>
                                            <div className="contentRightSide">
                                                <div className="lego l00paragraph legoMargin1">
                                                    <p>If you have questions regarding a price estimation for
                                                        the
                                                        services or a recently received bill, please
                                                        contact:</p>
                                                    <p>BJC HealthCare Price Estimation:<br/>
                                                        <span
                                                            className="bold">314-747-8845 or toll free 844-747-8845</span>
                                                    </p>
                                                    <p>Washington University Patient Services:<br/>
                                                        <span
                                                            className="bold">314-273-0500 or toll free 800-862-9980</span>
                                                    </p>
                                                    <p>BJC HealthCare Patient Billing Services:<br/>
                                                        <span
                                                            className="bold">314-362-8400 or toll free 855-362-8400</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End L82? Content 2 sides 50% width */}
                                    </div>
                                    {/* End L84 Block */}

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP </a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default InjectionSupport2_6;