import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {useEffectForVideoAccess, videoAccessLog} from "../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin1_1 = () => {

    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Pritikin Welcome'}, () => {
            console.log('Pritikin Welcome');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderdDarkBlue">Welcome
                                                to Your Healthier Future!</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="">
                                            <h3 className="blueColor videoTitle w-100 text-center">Welcome to Cardiac
                                                Rehab</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="3ajrQNlozW0" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Pritikin Intensive Cardiac Rehab (ICR) is a comprehensive lifestyle
                                                education program which will help you recover from your recent heart
                                                event or procedure. This powerful program can help you <span
                                                    className="bold italic">reduce your risk of having another heart event and improve your quality of life</span>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Participating in cardiac rehab is essential for your optimal recovery and
                                                continued long-term health. And your dedicated team of cardiac rehab
                                                professionals will be supporting you every step of the way.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin1_1;