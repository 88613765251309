import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanPancreaticAll2_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-1">
                                        <div
                                            className="tile Height1RowTall t_025 t_025_3x1 width3Column lightBlueBackground">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="col-4 imgColumn gettingReadyInAdvanceBackgroundImg">
                                                    </div>
                                                    <div className="col-8 typeColorColumn lightBlueBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center whiteColor sitemanT_25FontSize">Getting Ready in Advance</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-2">
                                        <div
                                            className="tile Height1RowTall t_025 t_025_3x1 width3Column paleBlueBackground">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="col-4 imgColumn yourSurgeryBackgroundImg">
                                                    </div>
                                                    <div className="col-8 typeColorColumn paleBlueBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center whiteColor sitemanT_25FontSize">Your
                                                                    Surgery</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <Nav.Link href="/Pancreatic_2-3">
                                        <div
                                            className="tile Height1RowTall t_025 t_025_3x1 width3Column lightBlueBackground">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="col-4 imgColumn yourStayAtTheHosptialBackgroundImg">
                                                    </div>
                                                    <div className="col-8 typeColorColumn lightBlueBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center whiteColor sitemanT_25FontSize">Your
                                                                    Stay at the Hospital</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll2_0;