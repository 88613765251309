import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './oneTimePassword.module.scss';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Card from 'react-bootstrap/Card';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
import {oneTimePassword} from "../../../actions/oneTimePassword.js";
import {FORGOT_PASSWORD_ERROR} from "../../../actions/types.js";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {Navbar, Nav, Dropdown} from "react-bootstrap";



const OneTimePassword = () => {
  const [passwordCode, setPasswordCode] = useState("");
  const [userName, setUserName] = useState('');
  const {errorMessage, userName: uName} = useSelector(state => state.forgotPassword);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeOTP = (e) => setPasswordCode(e.target.value);
  const back = async (e) => {
    await dispatch({
      type: FORGOT_PASSWORD_ERROR,
      payload: ''
    });
    navigate('/forgotpassword')
  }

  const submitForm = async (e) => {
    e.preventDefault();
    const otp = await oneTimePassword({userName, passwordCode}, () => {
      navigate('/resetpassword');
    })
    await dispatch(otp);
  }

    useEffect(() => {
      if (uName)
        setUserName(uName);
    }, [])

  return (
      <div className="w-100 gradient">
        <div className="w-100">
          <Container>
            <div className="pageContainer">
              <h1 className="text-center title">One Time Password</h1>
              <Box className="material-box" component="form" Validate onSubmit={submitForm}>
                <div className="card-body">
                  <div className="screenIconsContainer">
                    {/*<Avatar>*/}
                    {/*  <VpnKeyIcon className="icon" />*/}
                    {/*</Avatar>*/}
                  </div>
                  <div className="formFlexContainer">
                    <div className="formInput">
                      <TextField className="w-100" id="passwordCode" name="passwordCode" label="Enter OTP received via email" variant="outlined" onChange={changeOTP} required />
                      <span className="validationError">{errorMessage}</span>
                    </div>
                  </div>
                  <div className="buttonContainer sideBySideButtons">
                    {/*<Button variant="contained" color="primary" className="btn btn-primary" onClick={back}>*/}
                    {/*  <i className="fa fa-angle-left" aria-hidden="true"></i>&nbsp; Back*/}
                    {/*</Button>*/}
                    <Button variant="contained" type="submit" style={{marginLeft:'38%' ,backgroundColor:'#2d70b7', color:'#fff' , marginTop: '84px'}} disabled={errorMessage}>
                      Send OTP
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </Container>
        </div>
        <div className="gradientBottom">
        </div>
      </div>
    );
}

OneTimePassword.propTypes = {};

OneTimePassword.defaultProps = {};

export default OneTimePassword;
