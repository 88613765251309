import React from "react";

function L83AComponentCode() {

    return (

        <div className="lego l083 legoMargin3">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/car-exclamation.svg"/>
                <img className="overlay dontOverlay"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/dontOverlay-rectangle.svg"/>

            </div>
            <div className="contentContainer">
                <p><span
                    className="boldItalic">Driving:</span> For your safety,<span
                    className="boldItalic">we strongly recommend having someone drive you</span>
                    The anesthetic may cause temporary numbness.</p>
            </div>
        </div>

    )
};


export default L83AComponentCode;