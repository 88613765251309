import React from "react";
import { CodeBlock } from "react-code-block";
import { useCopyToClipboard } from "react-use";
import "@fortawesome/fontawesome-svg-core/styles.css";
import YouTube from "react-youtube";

function LegoL88AComponent({ code, language }) {
  code = `{/* L88B Bold rectangle Footer Box */}
                  <div className="col-12 m-auto">
                    <div className="lego l088B legoMargin5">
                      <div className="contentContainer">
                        <p>
                          <span className="highlightedText">
                            Results often include:
                          </span>
                          <br />
                          less pain
                          <br />
                          improved strength and stamina <br />
                          weight loss,
                          <br />
                          controlled diabetes and/or lower blood pressure. 
                        </p>
                        {/* <p style={{ fontWeight: 400 }}>90-120 days. </p>*/}
                      </div>
                    </div>
                  </div>
                  {/* Ends L88B Bold rectangle Footer Box  */}`;
  language = "jsx";
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyCode = () => {
    // Logic to copy `code`
    copyToClipboard(code);
  };

  return (
    <CodeBlock code={code} language={language}>
      <div className="relative">
        <CodeBlock.Code className="bg-black">
          <div className="table-row">
            <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
            <CodeBlock.LineContent className="table-cell">
              <CodeBlock.Token />
            </CodeBlock.LineContent>
          </div>
        </CodeBlock.Code>
        <div className="buttonContainer">
          <button className="btn btn-primary ml-auto" onClick={copyCode}>
            <i className="fa-solid fa-copy"></i>{" "}
            {state.value ? "Copied!" : "Copy code"}
          </button>
        </div>
      </div>
    </CodeBlock>
  );
}

export default LegoL88AComponent;
