import React from "react";

function L89ComponentCode() {

    return (

        <div className="lego l089 legoMargin3">
            <div className="contentContainer">
                <h4 className="legoMargin4">USING YOUR SPRINT PNS SYSTEM</h4>
                <p className="withBorderBottom">Do not use the system while driving,
                    operating machinery, or during any activity in which sudden muscle
                    tension may put you at risk of injury</p>
                <p className="withBorderBottom">Never soak the exit site in water. It is
                    okay to shower. Always remove the Pulse Generator (stimulator) and
                    Mounting Pad from your body and keep the Waterproof Bandage over your
                    exit site when showering.</p>
                <p className="withBorderBottom">Bathing and Swimming should be avoided
                    during the treatment.</p>
                <p className="withBorderBottom">A MicroLead (thin, thread-like wire) exits
                    your skin under the Waterproof Bandage. Do not pull on it.</p>
                <p className="withBorderBottom">Change the Waterproof Bandage & Cradle when
                    they become soiled, damp, or no longer adhere well to the skin (about
                    every 4-5 days). Take care when removing these to prevent pulling the
                    MicroLead out.</p>
                <p className="withBorderBottom">Limit strenuous physical activity & motion
                    (such as twisting, bending, climbing, lifting) near the implant for at
                    least one week. Avoid excessive stress (tugging, pressure, heat) that
                    may damage the stimulation system.</p>
                <p className="withBorderBottom">You may resume exercise and/or physical
                    therapy when directed by your doctor.</p>
                <p className="withBorderBottom">Watch for signs and/or symptoms of
                    infection.</p>
                <p className="withBorderBottom">Check temperature if having fever or chills.
                    Notify Physiatry for temperature over 100.4o or greater.</p>
                <p className="withBorderBottom">Observe dressing daily for red ness,
                    swelling, drainage, or foul odor around or on dressing.</p>
            </div>

        </div>

    )
};


export default L89ComponentCode;