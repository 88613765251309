import React from "react";

function L83LComponentCode() {

    return (

        <div className="lego l083 legoMargin2">
            <div className="iconContainer bg-white">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/warning.svg"/>
                <img className="overlay dontOverlay"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/dontOverlay-rectangle.svg"/>
            </div>
            <div className="contentContainer">
                <p><span className="boldItalic">DO NOT drive, drink alcohol, use machinery, or sign legal documents for 24 hours</span>after receiving sedation.</p>
            </div>
        </div>

    )
};


export default L83LComponentCode;