import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";
const Pritikin2_3 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorLightRed pritikinBorderLightRed">Heart-Healthy
                                        Nutrition</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>As part of your in-depth Nutrition Workshops taught by registered
                                        dietitians, you will learn about the Pritikin Eating Plan. </p>
                                    <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                        <h3 className="subheadUpperAndLowerColorBarIcon pritikinBackgroundRedOrange">
                                            <i class="fas fa-child"></i>The Pritikin Eating Plan is not a diet. It
                                            is a lifestyle.</h3>
                                    </div>
                                    <p><span className="bold">The Pritikin Eating Plan</span> <span className="italic">is a realistic, sustainable approach to heart-healthy eating, tailored to meet your needs...</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PRIT_2.3_infographics.png" className="img-responsive centerImage w-100
                                 maxWidth450" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Through Nutrition Workshops, you will also learn how to read nutrition
                                        labels when grocery shopping, choose meals in line with your health
                                        goals when dining out, and manage your weight successfully.</p>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <div className="w-80">
                                    <div className="sidbarFill withBodyCopy noTopRadius sidebarFillBackgroundColor noMinHeight pritikinBackgroundPeach colorBackgroundNoRadius btmMargin30">
                                        <h2 className="subheadUpperAndLower"><span className="blackText">Stock Your Pritikin Pantry</span>
                                        </h2>
                                        <p className="blackText">During the Pritikin program, you will learn
                                            about effective shopping and meal planning strategies, beginning
                                            with how to stock your pantry with the best combination of healthy
                                            foods and ingredients.</p>
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/tray-collage.jpg" className="img-responsive centerImage w-100 maxWidth400" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">Cooking School Workshops</h2>
                                    <p>Your journey to recovery is taken even further through weekly Cooking
                                        School Workshops. Taught by a team of culinary experts, these live
                                        cooking demos will show you, plus your significant other or support
                                        person, just how easy it is to prepare Pritikin-style, heart-healthy
                                        meals that are both tasty and affordable. </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/401016.jpg" className="img-responsive centerImage w-100
                                 maxWidth400" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="italic bold mb-0">Topics include:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom checkBulletsSafeCare pritikinGoldBullets tighSpacingList noBtmMargin bulletsSafeCareNoIndent">
                                        <li>Fast and Healthy Breakfasts</li>
                                        <li>One-Pot Wonders</li>
                                        <li>Fast Evening Meals</li>
                                        <li>Easy Entertaining</li>
                                        <li>Efficiency Cooking – Meals in a Snap</li>
                                        <li>Adding Flavor – Sodium-Free</li>
                                        <li>Powerhouse Plant-Based Proteins</li>
                                        <li>Satisfying Salads and Dressings</li>
                                        <li>Simple Sides and Sauces</li>
                                        <li>Savory Soups</li>
                                        <li>Comforting Weekend Breakfasts</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="italic">...and more!</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default Pritikin2_3;