import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonEnemaFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center width400">
                                                        <p><Nav.Link className="" href="/LessonEnema">Giving an Enema</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonEnema/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonEnema/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is a hemorrhoid and can I still give the enema?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A hemorrhoid is a swollen vein in the lower rectum and anus. Some are inside (internal) the rectum and some appear outside (external) the anus, which you can see. If a hemorrhoid is large enough to extend from the inside out, it can be painful and it will likely bleed when a person has a bowel movement. A hemorrhoid on the outside usually does not bleed and is not painful. You can give an enema when hemorrhoids are present but be very gentle as you insert the tip.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Can I become ill from giving a person an enema?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The contents of a bowel movement contain bacteria. You could develop an infectious disease, such as hepatitis if you do not follow precautions. <span className="bold italic">Always wash your hands thoroughly before and after giving an enema.</span> Use the clean disposable gloves we recommend. Gloves can be purchased at drug stores.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/American-College-of-surgeons-logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The American College of Surgeons “Patients as Partners in Surgical Care” site</h2>
                                                        <p>The site contains educational information about different types of surgery and surgical care. The College provides the most current information about surgical procedures, diseases, tests, medications and pain management from government and professional sources to support you in participating in a person’s surgical care.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.facs.org/education/patient-education" target="_blank"> www.facs.org/education/patient-education/</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">MedLine Plus</h2>
                                                        <p>This resource will direct you to information to help answer health questions. MedlinePlus brings together authoritative information from the National Library of Medicine, the National Institutes of Health (NIH), and other government agencies and health-related organizations. There is a section on Bisacodyl Fleet enemas.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nlm.nih.gov/medlineplus/druginfo/meds/a601027.html" target="_blank">www.nlm.nih.gov/medlineplus/druginfo/meds/a601027.html</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonEnemaFAQResources;
