import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll3_6 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entPreparingSurgeryGreenColor entPreparingSurgeryGreenBorderColor">Checklists</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entPreparingSurgeryGreenBackgroundColor text-center">1-2
                                        Weeks Before Surgery</h3>
                                    <p><span className="numberCircleMint">1</span><strong>Do you think you may have
                                            an infection?</strong><br />
                                        Call your surgeon’s office if you feel sick or you think you may have an
                                        infection of any kind, such as a respiratory, bladder, skin or tooth
                                        infection.</p>
                                    <p><span className="numberCircleMint">2</span> <strong>Do you have any open
                                            wounds?</strong><br />Check your skin for open sores, wounds and rashes.
                                        If you have any questions call your surgeon’s office.</p>
                                    <p><span className="numberCircleMint">3</span> <strong>Finish your <a href="#setUpHome">“Set Up Your Home”</a> checklist ></strong></p>
                                    <p><span className="numberCircleMint">4</span> <strong>Visit with the speech
                                            language pathologist (SLP).</strong><br /> The speech language
                                        pathologists are an important part of your cancer team. Meeting with a
                                        speech language pathologist is important to help learn how to take care
                                        of your larygectomy stoma. </p>
                                    <p>They are located in the Center for Advanced Medicine, 11th floor, in the
                                        Otolaryngology-Head and Neck Surgery offices. Your surgeon will set up
                                        an appointment with them for you.</p>
                                    <p><strong><em>What to expect during the appointment with the speech
                                                language pathologist:</em></strong></p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li>The opportunity to ask questions about how things will change
                                                after your laryngectomy
                                            </li>
                                            <li>Discussions about ways to rehabilitate your voice after surgery
                                                <ul className="bulletsCustom chevronRight colorAccentBlueGreenBullets">
                                                    <li>Electrolarynx</li>
                                                    <li>Tracheo-esophageal puncture
                                                        (TEP)
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Instructions for caring for your laryngectomy stoma</li>
                                            <li>Discussion of different kinds of accessories for your stoma
                                                <ul className="bulletsCustom chevronRight colorAccentBlueGreenBullets">
                                                    <li>Laryngectomy tube</li>
                                                    <li>Humidification devices</li>
                                                    <li>Stomal covers (for showering,
                                                        going outside
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>Discussion about swallowing postoperatively</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill marginRight noMinHeight entPreparingSurgeryGreenBackgroundColor entPreparingSurgeryGreenBorderColor">
                                                <h2 className="subheadUpperAndLower withBodyCopy entPreparingSurgeryGreenBackgroundColor text-white">
                                                    <i className="fa-solid fa-circle-exclamation">&nbsp;</i>&nbsp; Special
                                                    Alert:
                                                </h2>
                                                <p className="text-white">It may be time for you to stop taking
                                                    certain medications
                                                    before surgery. Review your CPAP medication instructions
                                                    from your preoperative anesthesia visit. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entPreparingSurgeryGreenBackgroundColor text-center">2-3
                                        Days Before Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li><strong>Please follow any special instructions you received from
                                                    your care team or during your preoperative anesthesia
                                                    visit.</strong></li>
                                            <li><strong>Perform your special chlorhexidine bath each day for
                                                    the 3 days prior to surgery as instructed during your
                                                    preoperative anesthesia visit.</strong></li>
                                            <li><strong>If you did not already, please complete the “set up your
                                                    home” checklist.</strong><br /> For a successful recovery, your
                                                home will need to be in order and ready for you to return.
                                            </li>
                                            <li><strong>Pack your bags.</strong><br /> Complete the <a href="#whatToBring">What To Bring </a> checklist >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entPreparingSurgeryGreenBackgroundColor text-center">Night
                                        Before Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li><strong>Please follow any special instructions you received from
                                                    your care team or during your preoperative anesthesia
                                                    visit.</strong></li>
                                            <li><strong>Perform your special chlorhexidine bath each day for the 3 days
                                                    prior to surgery as instructed during your preoperative
                                                    anesthesia visit.</strong>
                                            </li>
                                            <li><strong>If you did not already, please complete the “set up your
                                                    home” checklist.</strong><br /> For a successful recovery, your
                                                home will need to be in order and ready for you to return.
                                            </li>
                                            <li><strong>Pack your bags.</strong><br /> Complete the <a href="#whatToBring">What To Bring </a> checklist >
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entPreparingSurgeryGreenBackgroundColor text-center">Day
                                        of Surgery</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Doing this checklist to the best of your ability is part of your
                                        contribution to ensuring a safe and successful surgery. </p>
                                    <p><strong>Before you arrive:</strong></p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li>Do not use any nail polish, lotions, deodorant, makeup, perfume
                                                or body sprays on the day of surgery. Come “natural.”
                                            </li>
                                            <li>Take medications that you were instructed to take by the CPAP
                                                clinic on the morning of your surgery, with a sip of water.
                                            </li>
                                            <li>Follow any special instructions given to you for the morning of
                                                surgery. For example, your surgeon might request that you not
                                                have blood drawn or an IV placed in a specific arm in the
                                                morning of your operation.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entPreparingSurgeryGreenBackgroundColor text-center">Day
                                        of Surgery and Hospital Stay</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Your day will begin with check-in and hospital registration at the main
                                        floor of the Barnes-Jewish Hospital south campus. <a href="siteman_ent_tl-2-5-new.js">See complete details here ></a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 id="setUpHome" className="headline text-center w-100">Home
                                        Checklists</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">“Set Up Your Home” Checklist.</p>
                                    <p>You will need time to recuperate after surgery. You may have some
                                        limitations on your physical activities. This checklist will help you
                                        prepare your home so recovery is easier. </p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li>Make sure that you have arranged to stay with a caregiver all of
                                                the time for the first few days after you arrive home.
                                            </li>
                                            <li>For your safety, put your favorite things in places you can
                                                easily reach.
                                            </li>
                                            <li>Delay mail or have someone take care of your mail.</li>
                                            <li>Have someone take care of your children and pets.</li>
                                            <li>Secure someone to do your yard work.</li>
                                            <li>Do laundry.</li>
                                            <li>Clean your home.</li>
                                            <li>Make sure all stairs at your home have a sturdy railing.</li>
                                            <li>Help plan to be independent after your surgery by making sure
                                                you have the necessary items to help you while home. Get more <a target="_blank" href="">information
                                                    on equipment here ></a>.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p id="whatToBring" className="boldLeadIn">“What to Bring to the Hospital”
                                        Checklist. </p>
                                    <p>You will need time to recuperate after surgery. You may have some
                                        limitations on your physical activities. This checklist will help you
                                        prepare your home so recovery is easier.</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li>An attitude of success - you will soon be on a journey to
                                                recovery
                                            </li>
                                            <li>Phone numbers of anyone you may want to talk with during your
                                                stay at Barnes-Jewish Hospital
                                            </li>
                                            <li>Your completed health history</li>
                                            <li>Your medication record</li>
                                            <li>Your insurance card</li>
                                            <li>A photo ID, such as a driver’s license</li>
                                            <li>Your prescription card</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">Please Leave Following Items at Home</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                            <li>Jewelry</li>
                                            <li>Valuables such as credit cards, checks and large amounts of
                                                money
                                            </li>
                                            <li>Medications - your care team will give you all necessary
                                                medications while you are at Barnes-Jewish Hospital
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entPreparingSurgeryGreenColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll3_6;