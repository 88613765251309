import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic23 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Warning Signs</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon w-100 centered">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                IF YOU EXPERIENCE ANY OF THE FOLLOWING,
                                                CALL YOUR SURGEON PROMPTLY:
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom chevronRightBulletsSafeCare redBullets">
                                                <li>Spreading redness or warmth</li>
                                                <li>Increased pain/ pain medications are not working</li>
                                                <li>Increased drainage amount or color change</li>
                                                <li>Fever greater than 101 degrees or higher, chills</li>
                                                <li>Persistent nausea/ vomiting</li>
                                                <li>Constipated for 3 days</li>
                                                <li>Not passing gas</li>
                                                <li>Diarrhea for 3 days</li>
                                                <li>Not staying hydrated</li>
                                                <li>Eyes appear yellow, clay-colored stools, dark urine, and itching Bloody stools</li>
                                                <li>Greasy, oily stools that float in the toilet</li>
                                                <li>Lose more than 10% of body weight from the time of discharge Drainage from your incision or drain becomes thick, cloudy, or bright red blood</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100 middleGreenBorderColor" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100 centered">Contact Information:</h2><strong><em>
                                        </em></strong>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you experience any of these complications, <strong>contact your surgeon’s office</strong> during weekday hours (M-F 8a-4p). If after hours or on weekends, call <span className="tel-style"><a href="tel:3143621242">314-362-1242</a></span> </p>
                                            <p>Dr. William Hawkins: <span className="tel-style"><a href="tel:3143627046">314-362-7046</a></span></p>
                                            <p>Dr. Ryan Fields: <span className="tel-style"><a href="tel:3142861694">314-286-1694</a></span></p>
                                            <p>Dr. Chet Hammill: <span className="tel-style"><a href="tel:3142731809">314-273-1809</a></span></p>
                                            <p>Dr. Dominic Sanford: <span className="tel-style"><a href="tel:3142732720">314-273-2720</a></span></p>
                                            <p>Dr. Natasha Leigh: <span className="tel-style"><a href="tel:3143627147">314-362-7147</a></span></p>
                                            <p>Dr. William Chapman: <span className="tel-style"><a href="tel:3143622538">314-362-2538</a></span></p>
                                            <p>Dr. Maria Majella Doyle: <span className="tel-style"><a href="tel:3143622880">314-362-2880</a></span></p>
                                            <p>Dr. Adeel Khan: <span className="tel-style"><a href="tel:3143622820">314-362-2820</a></span></p>
                                        </div>
                                    </div>
                                            <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic23;
