import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonFootCareWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonFootCare">Foot Care</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i className="fa fa-exclamation-circle">&nbsp;</i>Call the doctor if you notice any of the following foot problems:
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>new areas of redness</li>
                                            <li>areas of darkening on the skin</li>
                                            <li>swelling</li>
                                            <li>cuts</li>
                                            <li>scratches</li>
                                            <li>blisters</li>
                                            <li>soft areas of the skin and over the heels</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>If you care for a person with diabetes mellitus, a history of a foot ulcer, loss of feeling in the feet, bony deformities of the feet, or thick or ingrown toenails, always have their doctor look at their feet during each visit.</li>
                                            <li>Have the person take off their shoes and socks at <span class="bold italic">every</span> doctor’s visit.</li>
                                            <li>Have a podiatrist or physician test a diabetic person’s feet at least once a year for loss of feeling.</li>
                                            <li>Tell the doctor if you have noticed the person walking differently or complaining of pain, numbness or tingling of the feet.</li>
                                            <li>Tell the doctor if the person regularly sees a podiatrist for foot care. If you need the name of a podiatrist for the person you care for, your doctor can refer you to someone that is in your area.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonFootCareWhatToTellYourDoctor;