import React from "react";

function L92ComponentCode() {

    return (

        <div className="lego l092">
            {/* L10A Bold Lead In */}
            <div className="lego l00paragraph">
                <p className="boldLeadInNewUI">SPR Therapeutics Representative
                </p>
            </div>
            {/* End L10A Bold Lead In */}

            {/* L14 Paragraph */}
            <div className="lego l00paragraph">
                <p><span className="bold italic">Kimberlee Griffey</span><br/>
                    Regional Sales Manager<br/>
                    <a href="tel:636-667-7493">636-667-7493</a><br/>
                    <a className="consistentLinkColor" href="https://www.SprintPNS.com" rel="noreferrer noopener" target="_blank">www.SprintPNS.com</a>
                </p>
            </div>
            {/* End L14 Paragraph */}
        </div>

    )
};


export default L92ComponentCode;