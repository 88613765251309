import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin7_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderDarkBlue">Our
                                                Cardiac Rehab Team</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/UnityPoint-Health-CR-Team_edited.jpg"
                                                className="img-responsive centerImage w-100"
                                                style={{maxWidth: '600px',}}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Dan Saskowski</span>, <span className="italic">Cardiac Rehab Manager (not in photo)</span>
                                            </p>
                                            <p><span className="bold">Alyssa Metallo</span>, <span className="italic">Exercise Specialist</span>
                                            </p>
                                            <p><span className="bold">Merritt Adams</span>, <span className="italic">Exercise Specialist</span>
                                            </p>
                                            <p><span className="bold">Lance Sperry</span>, <span className="italic">Exercise Specialist</span>
                                            </p>
                                            <p><span className="bold">Alyssa Tompkins</span>, <span className="italic">Exercise Specialist</span>
                                            </p>
                                            <p><span className="bold">Stephanie Wainwright</span>, <span
                                                className="italic">Exercise Specialist (not in photo)</span></p>
                                            <p><span className="bold">Karen Bailey</span>, <span className="italic">Registered Nurse</span>
                                            </p>
                                            <p><span className="bold">Cathy Meyer</span>, <span className="italic">Registered Nurse</span>
                                            </p>
                                            <p><span className="bold">Kim Nelson,</span>, <span className="italic">Registered Nurse (not in photo)</span>
                                            </p>
                                            <p><span className="bold">Val Thompson</span>, <span className="italic">Registered Nurse (not in photo)</span>
                                            </p>
                                            <p><span className="bold">Lisa Zobeck</span>, <span className="italic">Registered Nurse (not in photo)</span>
                                            </p>
                                            <p><span className="bold">Sheila Hendricks</span>, <span className="italic">Department Secretary</span>
                                            </p>
                                            <p><span className="bold">Tanya Mattingly</span>, <span className="italic">Department Secretary</span>
                                            </p>
                                            <p><span className="bold">Linda Harris-Teach</span>, <span
                                                className="italic">Registered Dietitian</span></p>
                                            <p><span className="bold">Teresa Pangan</span>, <span className="italic">Registered Dietitian (not in photo)</span>
                                            </p>
                                            <p><span className="bold">Kaleigh Estolano</span>, <span className="italic">Registered Respiratory Therapist/ Certified Tobacco Treatment Specialist</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin7_3;