import React from "react";

function L83ComponentCode() {

    return (

        <div className="lego l083 legoMargin2">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"/>
            </div>
            <div className="contentContainer">
                <p><span className="bold">Please arrive 30 minutes prior to your injection time for registration.</span>
                    &nbsp;If you arrive more than 15 minutes past your scheduled injection
                    time, your procedure may need to be rescheduled.</p>
            </div>
        </div>

    )
};


export default L83ComponentCode;