import React from 'react';
import Container from 'react-bootstrap/Container';
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";

const LessonMovingWhatToTellYourDoctor = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonMoving">Moving Safely in the Home</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If you know the person you care for has a risk of falling, take the first step in prevention by going to see their doctor. Take a list of all their medicines (prescription and over-the-counter) with you, or bring the medicines with you. Also, if the person has fallen, write down the details for the doctor.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Describe the Fall</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Describe when and where the person fell.</li>
                                                    <li>What led to the fall? A slippery floor, a pet running in front of them, failure to see a step? Did the person become dizzy?</li>
                                                    <li>Describe any injuries (such as a bruise or soreness).</li>
                                                    <li>Describe any times when the person almost fell but was able to hold onto something and avoid falling.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">The Person’s Health</p>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom">
                                                        <li>Tell the doctor if the person has trouble seeing or hearing.</li>
                                                        <li>Tell the doctor if the person has trouble walking or loses their balance. </li>
                                                        <li>Tell the doctor if the person has joint pain, numbness in the feet or legs. </li>
                                                        <li>Tell the doctor if the person has trouble remembering where they are or what they are doing.</li>
                                                        <li>Tell the doctor if the person has complained of dizziness or lightheadedness.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10186.jpg" className="img-responsive maxWidth300" />
                                        </div>
                                        <div className="col-12 backToTop footer blueBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonMovingWhatToTellYourDoctor;
