import React from "react";

function L88ComponentCode() {

    return (

        <div className="lego l088 legoMargin5">
            <div className="contentContainer">
                {/* L53 Bullet List */}
                <div className="lego l053 smallFont">
                    <ul className="w-100">
                        <li className="w-100">You do NOT need to stop any other medication
                            (blood pressure meds, diabetes meds, etc.).
                        </li>
                        <li className="w-100">Alert our office if you have any history of
                            allergies to local anesthetics (such as Novocain or lidocaine).
                        </li>
                        <li className="w-100">If you have an allergy to X-ray contrast dye,
                            please notify your healthcare provider prior to the procedure.
                        </li>
                        <li className="w-100">OK to eat and drink prior to the procedure.
                        </li>
                    </ul>
                </div>
                {/* End L53 Bullet List */}
            </div>
        </div>

    )
};


export default L88ComponentCode;