import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTTL2_1 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Your
                                        Anatomy</h1>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="sectionHeader w-100 text-center">A Closer Look at Your Neck and Throat.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 imageLeftCopyRight">
                                    <div className="imgCol">
                                        <img className="floatLeft wrapImage col-6 clearLeft" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Larynx_normal.jpg" alt="" />
                                    </div>
                                    <div className="typeCol">
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Larynx <span>(LAIR-inks): your voice box</span>
                                        </p>
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Trachea <span>(TRAY-kee-ah): your "windpipe" or airway</span>
                                        </p>
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Esophagus <span>(ee-SOF-ah-gus): your "food pipe"</span>
                                        </p>
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Pharynx <span>(FAIR-inks): the inside of your throat</span>
                                        </p>
                                        <p className="floatNone clearNone col-6 noPaddingLeft">The larynx sits
                                            on
                                            top of the trachea and connects the trachea to the pharynx and the
                                            pharynx to the esophagus. </p>
                                        <p className="boldLeadIn noBtmMargin">The larynx plays an important part in:</p>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom chevronRight colorAccentRedBullets">
                                                    <li>Breathing</li>
                                                    <li>Talking</li>
                                                    <li>Swallowing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="col-12">
                                    <p>People often have breathing trouble, hoarseness or voice trouble and/or
                                        swallowing trouble when the larynx doesn’t work as it should.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 imageLeftCopyRight">
                                    <div className="imgCol">
                                        <img className="floatLeft wrapImage col-6 clearLeft" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Larynx_breathing.jpg" alt="" />
                                    </div>
                                    <div className="typeCol">
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Breathing</p>
                                        <p className="floatNone clearNone col-6 noPaddingLeft">When you breathe,
                                            air is drawn in through the nose and mouth, passes the vocal cords
                                            and enters the trachea and lungs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 imageLeftCopyRight">
                                    <div className="imgCol">
                                        <img className="floatLeft wrapImage col-6 clearLeft" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Larynx_taling.jpg" alt="" />
                                    </div>
                                    <div className="typeCol">
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Talking</p>
                                        <p className="floatNone clearNone col-6 noPaddingLeft">When you talk,
                                            air comes up from the lungs and vibrates the vocal cords, creating
                                            voice.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 imageLeftCopyRight">
                                    <div className="imgCol">
                                        <img className="floatLeft wrapImage col-6 clearLeft" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Larynx_swallowing.jpg" alt="" />
                                    </div>
                                    <div className="typeCol">
                                        <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Swallowing</p>
                                        <p className="floatNone clearNone col-6 noPaddingLeft">When you swallow,
                                            the vocal cords close, allowing the food pipe to open so food and
                                            liquid can go to the stomach and not into the windpipe.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100" />
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="sectionHeader text-center w-100">Total Laryngectomy</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder entDiagnosisSurgeryRedBorderColor">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn text-center marginBottom12">During a total laryngectomy, the larynx is removed and two separate pipes are created for swallowing and breathing/talking.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentRedBullets entDiagnosisSurgeryRedColor ">
                                            <li>The mouth is connected to the esophagus (food pipe), forming one
                                                pipe for swallowing.
                                            </li>
                                            <li>The trachea (wind pipe) is redirected to create a permanent hole
                                                on the surface of the neck, forming one pipe for breathing. This
                                                hole is called a tracheostoma (TRAY-kee-oh STOW-ma) or “stoma”
                                                for short.
                                            </li>
                                            <li>The nose and the mouth do not connect to the lungs after this
                                                surgery.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                            TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTL2_1;