import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic21 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Involving Your Family and Friends</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-2 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/involving_family_image.jpg" alt=""/>
                                            </div>
                                            <div className="col-10 typeCol noPaddingLeft">
                                                <p className="mobileMarginBottom30">Involving your family and close friends in understanding your needs and your plan for recovery can help improve your outcome, avoid problems and give you a support system that will help you get and stay on track in your progress.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Family can help in many ways with your keys to success:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Understanding your discharge instructions, including your medications and options for care available to you</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Keeping all contact information handy and reaching out whenever needed</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Knowing and adhering to your follow-up schedule</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Knowing your support resources and how to reach out to them</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Knowing any warnings signs to look out for and letting us know promptly if any arise</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Understanding the kinds of possible complications and inconveniences you’ll face and how to best act to help lower their severity. Some common issues like constipation, pain and nausea can be helped greatly by knowing what to do as soon as they arise and how to help avoid symptoms in the first place.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Proper care of your drains and incision</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Planning (and stocking up for) proper nutrition at home</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Being active in ways that speed your recovery</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Following any additional instructions relating to the use of medications, medical devices, diabetes treatments, needed therapies</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Like your procedure and recovery efforts at the hospital, getting the best results is a team effort. And you and your family are never alone. Reach out to the various experts and resources that are available to you. Addressing small problems as they arise can help avoid large problems that could lead to your re-admission or delay your recovery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Some practical family preparations that often help include:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ol className="">
                                                <li>Entering key contact information for your various care team professionals in their phones, as well as writing it down and displaying it in a place everyone can find it.</li>
                                                <li>Reviewing the various topics throughout this reference tool, including training on areas they can help you at home.</li>
                                                <li>Making sure they all know the warning signs to look for and promptly contact your surgeon for follow-up. Addressing these warning signs quickly can help avoid a small problem becoming a larger one.</li>
                                                <li>Knowing how to help you alleviate common issues that can arise during your recovery like nausea, constipation and managing any pain.</li>
                                                <li>Stocking your pantry at home with the proper foods, and keeping snacks and supplements handy.</li>
                                                <li>Finding ways to get involved in your daily walking and other physical activities to offer encouragement and support.</li>
                                                <li>Making sure your prescriptions are properly filled and your regimen followed.</li>
                                                <li>Knowing what support organizations and groups offer useful information, services and assistance and knowing how to best reach out to them.</li>
                                                <li>Developing a way to easily keep family and close friends informed of your progress and making it a habit that everyone can follow along with.</li>
                                                <li>Check in with you periodically. Your recovery can be mentally and physically taxing and some days will be better than others. Having someone there when you need an extra hand or encouragement can make a real difference.</li>
                                            </ol>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic21;
