import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from 'react-router-hash-link';

const ContentPancreatic28 = () => {


    return (<div>
        <div className="w-100">
            <Container>
                <div className="w-100">
                    <div className="col-10 m-auto">
                        <div className="whiteBackground floatLeft clearLeft">
                            <section>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h1 className="tileTitleHeader centeredPageHeader">Checklists</h1>
                                    </div>
                                </div>
                                <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer boxes">
                                            <div className="typeContainer text-center">
                                                <p><Link to="/Pancreatic_9#general">General Questions</Link></p>
                                            </div>
                                            <div className="typeContainer text-center">
                                                <p><Link to="/Pancreatic_9#helpUs">Help Us Prepare For
                                                    Surgery</Link></p>
                                            </div>
                                            <div className="typeContainer text-center">
                                                <p><Link to="/Pancreatic_9#dayOf">Day Of Your Surgery
                                                    Questions</Link></p>
                                            </div>
                                            <div className="typeContainer text-center">
                                                <p><Link to="/Pancreatic_9#postStay">Post-Stay Questions</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 coloredContentBox">
                                    <div className="w-100">
                                        <div
                                            className="roundedColorBackgroundBoxPriority grayBackground text-center">
                                            <div className="col-12 imgColLeftContentColRight">
                                                <div className="w-100">
                                                    <div className="col-md-2 col-xs-3 imgCol noPaddingLeft">
                                                        <img className="img-responsive"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/0_cluster_small.png"
                                                             alt=""/>
                                                        <p className="captionItalic">Your Orbit</p>
                                                    </div>
                                                    <div className="col-md-10 col-xs-9 typeCol noPaddingLeft">
                                                        <p>The reference tool you are using is called your “orbit.”
                                                        </p>
                                                        <p>Within this orbit is a very useful collection of clear,
                                                            relevant information and other help specifically
                                                            selected to help you get the best outcomes before,
                                                            during and after your procedure taking full advantage of
                                                            the support that is available to you right now.</p>
                                                        <p>Explore the various sections of your orbit whenever you
                                                            have the chance to get a more complete understanding of
                                                            all the things that could matter most to you.</p>
                                                        <p className="mobileMarginBottom30">For help navigating
                                                            within your orbit, go <a href="#">here.</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 id="general" className="headline w-100">General Questions</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">

                                                        <Accordion.Header>Who should I call for more
                                                            information, discussions or getting more details on my
                                                            diagnosis?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>If your question involves a life-threatening
                                                                emergency,
                                                                dial 911 immediately and have your physician’s office
                                                                contacted later after the emergency is being
                                                                addressed.</em>
                                                            </p>
                                                            <p><em>For all other calls, it is best to start by calling
                                                                your
                                                                physician’s office first. They will be able to address
                                                                your
                                                                needs or help get you in contact with someone who
                                                                can.</em>
                                                            </p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>Who will be treating me
                                                            during my course of
                                                            care?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Your treatment is a team effort led by your
                                                                physician. He
                                                                or
                                                                she will be joined by a team of professionals who
                                                                combine to
                                                                give you expert care 24/7. You will have a core team you
                                                                interact with daily who will collaborate with other
                                                                specialist
                                                                teams as needed.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>Where can I get more quality
                                                            information on my
                                                            diagnosis?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Your Siteman doctor’s office has both printed and
                                                                downloadable
                                                                materials available.</em></p>
                                                            <p><em>The American Cancer Society (at cancer.org) and the
                                                                National
                                                                Cancer Institute (at <a href="https://www.cancer.gov/"
                                                                                        target="_blank">cancer.gov)</a> also
                                                                provide
                                                                excellent detailed information.</em></p>

                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item></Accordion>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="w-100">
                                        <hr/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 id="helpUs" className="headline w-100">Help Us Prepare For Surgery</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <Accordion defaultActiveKey="3">
                                            <Accordion.Item eventKey="3">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>What help is available for my
                                                            family?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Siteman Cancer Center has thought about the needs of
                                                                each
                                                                step of
                                                                the experience for you and your family. We offer many
                                                                forms
                                                                of
                                                                support and guidance to help you prepare for your
                                                                surgery,
                                                                your
                                                                hospital stay and your recovery afterwards: logistics
                                                                assistance,
                                                                guest services, planning help, useful training,
                                                                nutritional
                                                                and
                                                                exercise help, comfort, education, financial guides,
                                                                community
                                                                support groups, patient advocacy and many, many
                                                                more.</em>
                                                            </p>
                                                            <p><em>A good first step is to check out the “Get Support”
                                                                tile
                                                                within
                                                                this orbit which explains many of the options you have.
                                                                Or,
                                                                discuss
                                                                your needs with your doctor’s office who can refer you
                                                                to
                                                                which
                                                                specific services they feel could best help you. If your
                                                                needs
                                                                involve logistics or guest service questions, you may
                                                                want
                                                                to first
                                                                contact the Concierge Service at Barnes-Jewish Hospital
                                                                at
                                                                314-362-5194.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="w-100">
                                        <hr/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 id="dayOf" className="headline w-100">Day Of Your Surgery</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <Accordion defaultActiveKey="4">
                                            <Accordion.Item eventKey="4">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>Who can come? What can they
                                                            do?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>You may bring family members and close friends.
                                                                Visitors
                                                                to your
                                                                room after surgery are limited to 1 to 2 visitors at a
                                                                time,
                                                                so keep
                                                                numbers reasonable.</em></p>
                                                            <p><em>Family and friends will experience hours of waiting
                                                                during your
                                                                procedure and initial recovery that day. It’s a good
                                                                idea to
                                                                bring
                                                                books and/or computers. Wifi and vending is available in
                                                                the
                                                                main
                                                                waiting room where they’ll be spending their time during
                                                                your
                                                                surgery (which can last 4 to 8 hours). Check out the map
                                                                of
                                                                your
                                                                “Day Of Surgery” for locations of key areas and
                                                                services.</em></p>
                                                            <p><em>Your family will be given a pager to get updates from
                                                                the
                                                                operating room. Once your procedure has ended, your
                                                                family
                                                                will be
                                                                told what recovery room you can visited at.</em></p>
                                                            <p><em>We ask that you please designate one family member to
                                                                be
                                                                the
                                                                Designated Contact who will receive updates from our
                                                                team.
                                                                Your
                                                                Designated Contact should then update everyone in your
                                                                group.</em>
                                                            </p>
                                                            <p><em>Visitors should wear comfortable clothes and, if
                                                                needed,
                                                                consider
                                                                wearing their glasses instead of contacts. Bring things
                                                                that
                                                                will
                                                                help you pass the time Ideally, leave any non-required
                                                                valuables at
                                                                home; you’ll be in public areas during your waiting
                                                                time.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="5">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>How long will I be in the
                                                            hospital? What help
                                                            is
                                                            there for my family’s needs during that
                                                            time?</Accordion.Header>
                                                        <Accordion.Body>

                                                            <p><em>After your procedure, you will likely be in the
                                                                hospital
                                                                approximately 7 days.</em></p>
                                                            <p><em>During that time, you’ll be staying in a room on the
                                                                16th
                                                                floor
                                                                of Barnes-Jewish Hospital South. Visiting hours are 11
                                                                a.m.
                                                                to 9
                                                                p.m. These can change based on the nursing unit’s
                                                                discretion. You
                                                                can have a maximum of four visitors at a time in a
                                                                private
                                                                room, two
                                                                visitors in a semi-private room. The floor has a family
                                                                waiting room
                                                                area to stay at while others are visiting the room. One
                                                                person can
                                                                stay overnight in a private patient’s room. No overnight
                                                                guests are
                                                                allowed in semi-private rooms.</em></p>
                                                            <p><em>You should take advantage of the many services and
                                                                information
                                                                offered by the Barnes Concierge Service (reached at
                                                                314-362-5194).
                                                                These include:</em></p>
                                                            <p className="boldLeadIn">Nearby hotel reservations at a
                                                                discounted
                                                                rate</p>
                                                            <div className="col-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom">
                                                                        <li><em>Listing of local restaurants</em></li>
                                                                        <li><em>St. Louis entertainment</em></li>
                                                                        <li><em>Books/Newspapers/Magazines</em></li>
                                                                        <li><em>Daily laptop rental</em></li>
                                                                        <li><em>Selection of DVD movies to check
                                                                            out</em>
                                                                        </li>
                                                                        <li><em>Assistance with campus directions</em>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="6">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>What kind of support is
                                                            available to me and
                                                            my
                                                            family</Accordion.Header>
                                                        <Accordion.Body>

                                                            <p><em>What kind of support is available to me and my
                                                                family?</em></p>
                                                            <div className="col-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom">
                                                                        <li><em>During your recover, a Nurse Manager
                                                                            and/or
                                                                            Assistant Manager will visit daily to ensure
                                                                            your care
                                                                            is excellent.</em></li>
                                                                        <li><em>Our dedicated Concierge Services are
                                                                            available to
                                                                            all patients and families to help arrange
                                                                            for
                                                                            their
                                                                            needs.</em></li>
                                                                        <li><em>The Office of Patient and Family Affairs
                                                                            is
                                                                            available to address any issues and help
                                                                            connect
                                                                            you to
                                                                            resources and assistance throughout our
                                                                            network.</em>
                                                                        </li>
                                                                        <li><em>Social Workers and Case Managers are
                                                                            available upon
                                                                            request; they are a valuable partner in help
                                                                            you
                                                                            plan
                                                                            for your recovery, your and your family’s
                                                                            well-being,
                                                                            additional treatment resources, connecting
                                                                            to
                                                                            community
                                                                            support and provide guidance step-by-step
                                                                            throughout
                                                                            your experience</em></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="7">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>When will I be
                                                            discharged?</Accordion.Header>
                                                        <Accordion.Body>

                                                            <p><em>Your discharge is determined based on your achieving
                                                                certain
                                                                milestones in your recovery. Following your therapy,
                                                                nutrition and
                                                                overall care instructions well can help you reach these
                                                                milestones
                                                                faster. Generally when you are tolerating an oral diet,
                                                                off
                                                                IV
                                                                medications, and any medical issues have been resolved
                                                                you
                                                                can be
                                                                discharged.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 id="postStay" className="headline w-100">Post-Stay</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <Accordion defaultActiveKey="8">
                                            <Accordion.Item eventKey="8">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>What help is available for me
                                                            and my family?
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Siteman Cancer Center has thought about the needs of
                                                                each
                                                                step of
                                                                the experience for you and your family. We offer many
                                                                forms
                                                                of
                                                                support and guidance to help you throughout your
                                                                recovery
                                                                and
                                                                getting back to normal.</em></p>
                                                            <p><em>This includes planning help, useful therapy training,
                                                                nutritional
                                                                and exercise help, comfort, education, financial guides,
                                                                community
                                                                support groups, patient advocacy and much more.</em></p>
                                                            <p><em>A good first step is to check out the “Get Support”
                                                                tile
                                                                within
                                                                this orbit which explains many of the options you have.
                                                                Or,
                                                                discuss
                                                                your needs with your doctor’s office who can refer you
                                                                to
                                                                which
                                                                specific services and/or resources they feel could best
                                                                fill
                                                                your
                                                                needs</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="9">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">

                                                        <Accordion.Header>What can my family do to
                                                            help?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Be sure to review the “Success Going Home” content
                                                                within
                                                                this
                                                                orbit. There is much useful information shared there
                                                                that
                                                                you can
                                                                act on easily. In general, some of the best ways family
                                                                can
                                                                help
                                                                include:</em></p>
                                                            <p><em>Helping you with errands, groceries, cooking,
                                                                cleaning.
                                                                During
                                                                your recovery, you’ll have some specific physical
                                                                limitations to
                                                                protect your surgery area; help with some of the chores
                                                                that
                                                                require
                                                                heavy lifting is important.</em></p>
                                                            <p><em>But, other than those few specifics that keep your
                                                                surgery site
                                                                safe from too much strain, moving around the house,
                                                                talking
                                                                walks
                                                                and generally getting back to normal is key to your fast
                                                                recovery.
                                                                Having family and friends join you in this is a great
                                                                way to
                                                                aid
                                                                your recovery.</em></p>
                                                            <p><em>Family and friends can also help by making themselves
                                                                informed of
                                                                the disease and recovery specifics, understanding
                                                                complications and
                                                                warning signs and what to do if they appear. Reviewing
                                                                the
                                                                care plan
                                                                with you, including follow-up visits and key contacts,
                                                                help
                                                                keep
                                                                things on-track. Family members are given an orbit of
                                                                their
                                                                own to
                                                                help them be able to get up to speed more easily and
                                                                completely.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="10">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>What can I
                                                            expect?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Very useful, easy-to-follow details of all the topics
                                                                below are
                                                                available in the “Success Going Home” section of this
                                                                orbit
                                                                reference tool. Review that content, which has been
                                                                designed
                                                                to make
                                                                it easy to get the key information that will help you
                                                                most
                                                                in your
                                                                recovery.</em></p>
                                                            <p className="boldLeadIn">Food and drink</p>
                                                            <p><em>During your recovery, your appetite and ability to
                                                                tolerate food
                                                                will be different than normal. Generally you’ll find you
                                                                get
                                                                best
                                                                results with small, frequent meals. It is a trial and
                                                                error
                                                                approach
                                                                in some ways though. You’ll need to pay close attention
                                                                to
                                                                how
                                                                certain foods make you feel.</em></p>
                                                            <p className="boldLeadIn">Being active</p>
                                                            <p><em>The main restrictions you’ll experience have to do
                                                                with
                                                                heavy
                                                                (more than 10 lbs.) lifting, pushing or pulling. You’ll
                                                                find
                                                                that
                                                                walking is your best medicine, keeping your active and
                                                                speeding your
                                                                recovery, and help your digestive system get back to
                                                                normal.</em>
                                                            </p>
                                                            <p className="boldLeadIn">Travel</p>
                                                            <p><em>Generally, it’s okay to fly approximately 4 weeks
                                                                after
                                                                your
                                                                surgery. For car drives over an hour, be sure to stop
                                                                and
                                                                walk
                                                                around a bit.</em></p>
                                                            <p><em>Every patient is slightly different in their recovery
                                                                though;
                                                                check with your doctor about other travel restrictions
                                                                he or
                                                                she may
                                                                suggest.</em></p>
                                                            <p className="boldLeadIn">Returning to work</p>
                                                            <p><em>In general, patients can return to work approximately
                                                                6
                                                                weeks
                                                                after their surgery. But, this is just a general rule.
                                                                It
                                                                varies
                                                                widely by-individual. Sometimes it can happen earlier;
                                                                sometimes
                                                                later based on what kind of activities your job
                                                                requires.</em></p>
                                                            <p><em>You will be tired more than normal, so the need to
                                                                reduce
                                                                your
                                                                hours upon return is possible. Manual labor jobs would
                                                                come
                                                                with
                                                                some activity restrictions.</em></p>
                                                            <p><em>Check with your doctor to develop your ideal plan to
                                                                get
                                                                back to
                                                                work as soon as you are ready.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="11">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>How often do I then see
                                                            you?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>Follow-up visits usually have 2 to 4 weeks
                                                                post-operation. Then,
                                                                approximately 1 to 3 months after that. And again 6
                                                                months
                                                                later.</em></p>

                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="12">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>What will be my biggest
                                                            challenges?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>A successful recovery requires you to be
                                                                knowledgeable
                                                                and
                                                                actively planning for your ideal diet. Making sure you
                                                                eat
                                                                properly,
                                                                and often enough, requires focus (and a properly-stocked
                                                                pantry).</em></p>
                                                            <p><em>You need to be active throughout your recovery,
                                                                despite
                                                                whatever
                                                                pain you may still have. Making sure you’re continuously
                                                                taking
                                                                walks, using your Incentive Spirometer, etc. also
                                                                requires
                                                                focus and
                                                                effort (on your part and that of your loved ones).</em>
                                                            </p>
                                                            <p><em>The other challenge patients report is simply the
                                                                emotional toll
                                                                that comes with not feeling as in control as you do
                                                                normally, or the
                                                                effect of waiting for pathology reports, or the
                                                                beginning of
                                                                any
                                                                further treatment. Your emotional health is important to
                                                                us
                                                                just as
                                                                much as your physical recovery. Please do not hesitate
                                                                to
                                                                reach out
                                                                to your doctor’s office to determine what kinds of the
                                                                available
                                                                help may be useful for you and your family.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="13">
                                                <div className="col-12 questionAndAnswer">
                                                    <div className="w-100">


                                                        <Accordion.Header>Is there outside help
                                                            available?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p><em>You are truly not alone. You are surrounding by
                                                                people
                                                                and
                                                                organizations dedicated to your recovery and continued
                                                                health.</em>
                                                            </p>
                                                            <p><em>We at your doctor’s office very much want to be kept
                                                                in
                                                                the loop
                                                                on your progress and any emerging needs you or your
                                                                family
                                                                may have.
                                                                There is a world of available support you and your
                                                                family
                                                                should be
                                                                familiar with. Check out the “Get Support” section of
                                                                this
                                                                orbit to
                                                                start, as well as the Siteman Cancer Center website.
                                                                Don’t
                                                                hesitate
                                                                to contact you doctor’s office to discuss forms of
                                                                support
                                                                that best
                                                                fit your needs.</em></p>
                                                        </Accordion.Body>


                                                    </div>
                                                </div>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop careOrbitBlueColor"
                                              onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                            BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </div>)
};

export default ContentPancreatic28;
