import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll4_6 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Home Medications</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you take any daily medications at home, it is important to be aware of any changes that may have been made during your hospital stay. Any changes will be discussed before and during your hospital stay, and will also be printed on your discharge instructions.</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder lightBlueColorBorder">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom lightBlueColorBorder">
                                                    <li>For patients on blood thinners that were held before surgery, it is important to clarify with your surgeon when you may resume your blood thinners.</li>
                                                    <li>For patients with diabetes, you may have been switched to an insulin regimen while you were in the hospital. It is important to clarify any changes to your daily home regimen that may have been made.</li>
                                                    <li>For patients who require tube feeding after discharge, it is important to ensure that your home medications can be administered through your feeding tube.</li>
                                                    <li>If you have any other questions about your medications, please reach out to your doctor’s office.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop blueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll4_6;
