import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin7_1 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderDarkBlue">ABOUT
                                                US</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/heart-header.jpg"
                                                 className="img-responsive centerImage w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>The nationally recognized cardiac rehabilitation (rehab) at UnityPoint
                                                Health – Trinity can change your life. This 12-week, medically
                                                supervised program helps you adopt new habits that support your heart
                                                health.</p>
                                            <p>People completing our program typically gain strength, lose weight and
                                                lower their blood pressure and cholesterol levels. Many people no longer
                                                need medications to control blood pressure or other conditions. And they
                                                are less likely to need a future heart procedure or emergency heart
                                                care.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Cardiac Rehab at Trinity: Why Choose
                                                Us?</h2>
                                            <p>Doctors, nurses and rehab specialists work together to coordinate your
                                                care so that you can focus on healing. We teach you about the rehab
                                                process while you are still in the hospital. During rehab, nurses,
                                                exercise specialists and a dietitian work together, so you get the most
                                                out of each session</p>
                                            <p className="bold italic marginBtm12">Highlights of our program
                                                include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom pritikinBullets">
                                                <li>National recognition: Trinity is the first Pritikin
                                                    Certified<sup>&trade;</sup> Intensive Cardiac Rehabilitation (ICR)
                                                    program in the Quad Cities. People completing our ICR program are
                                                    less likely to need daily heart medications or additional
                                                    procedures.
                                                </li>
                                                <li>Safety: It’s natural to be anxious about becoming physically active
                                                    when you live with severe heart disease. Cardiac rehab is a
                                                    medically supervised program. That means nurses and exercise
                                                    specialists pay careful attention to your body’s response to cardiac
                                                    rehab exercise. If any concerns arise, Trinity’s experienced heart
                                                    team is on standby
                                                </li>
                                                <li>Personalized rehab: We take time to get to know you, your overall
                                                    health history and the activities you enjoy. For people who feel
                                                    unsteady on their feet, we offer seated exercises. If you enjoy
                                                    activities like gardening that use your arms, your rehab program can
                                                    focus on upper body strength.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidebarNoFill sidebarNoFillBorderColor pritikinBorderSkyBlue">
                                                <h2 className="subheadUpperandLower withBodyCopy pritikinColorSkyBlue">More
                                                    About Trinity.</h2>
                                                <p>UnityPoint Health – Trinity is a regional integrated health care
                                                    delivery system including four full-service hospitals in Rock Island
                                                    and Moline, Illinois and Bettendorf and Muscatine, Iowa with a total
                                                    of 555 licensed inpatient beds. UnityPoint Health – Trinity
                                                    hospitxals, clinics, home health care agency, mental health center
                                                    and health sciences college employ more than 4,000 employees.
                                                    Together, we are working toward physician-driven, patient-centered
                                                    care to achieve our vision of delivering the best outcome for every
                                                    patient, every time.</p>
                                                <p>We do our best to make a difference through compassionate care,
                                                    exceptional physicians and staff and comprehensive community benefit
                                                    programs. We are dedicated to our mission of improving the health
                                                    and well-being of the people and communities we serve by providing
                                                    the highest quality health care possible. We are committed to health
                                                    promotion and disease prevention programs that keep people out of
                                                    the hospital. Our approach includes collaboration with physicians,
                                                    behavioral health providers and nursing facilities for innovative
                                                    care models to improve the overall quality, efficiency and
                                                    effectiveness of the care we provide our community.</p>
                                                <p>UnityPoint Health – Trinity is a senior affiliate of UnityPoint
                                                    Health, the state’s first and largest integrated health system that
                                                    serves the health care needs of one-in-three Iowans. Our leadership
                                                    in quality and service excellence has helped earn UnityPoint Health
                                                    – Trinity top industry awards for patient safety, excellent outcomes
                                                    and cost-control.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin7_1;