import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const Lessons = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div
                                        className="col-12 backgroundImageWithColorBar withNavigation heightAutoOverride wow fadeInDown animated fadeInDownAnim">
                                        <div className="contentCenter">
                                            <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                <div className="centeringContainer">
                                                    <div className="text-center h3Container">
                                                        <h3 className="whiteColor text-center">Learning Gallery</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <p>Easy instructional videos and notes to help you understand, plan and work
                                            toward better results for you and your family.</p>
                                        <h2 className="subheadAllCaps">Family and friends:</h2>
                                        <p>Please take some time to familiarize yourself with key topics that you may be
                                            involved with during the patient’s hospital stay and when returning
                                            home.</p>
                                    </div>
                                    <div className="col-12">
                                        <h2 className="subheadAllCaps">These videos will help your outcome, before and
                                            after your procedure and at home.</h2>
                                        <p>Please be sure you (and any family and friends) review the following lessons
                                            videos:</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 d-flex flex-row justify-content-between">
                                            <div className="col-5">
                                                <h2 className="subheadAllCaps">Before Your Procedure
                                                    and Hospital Stay:</h2>
                                                <p><Nav.Link href="/Pancreatic_Video-WHIPPLE">WHIPPLE
                                                    Procedure</Nav.Link></p>
                                                <p><Nav.Link href="/Pancreatic_Video-RAMPS">RAMPS Procedure</Nav.Link>
                                                </p>
                                                <p><Nav.Link href="/Pancreatic_Video-Getting-Ready-For-Surgery">Getting
                                                    Ready For Surgery</Nav.Link></p>
                                                <p><Nav.Link href="/Pancreatic_Video-Having-A-Successful-Hospital-Stay">Having
                                                    a Successful Hospital Stay</Nav.Link></p>
                                                <p><Nav.Link href="/Pancreatic_Video-Your-Therapy">Your
                                                    Therapy</Nav.Link></p>
                                            </div>
                                            <div className="col-5">
                                                <h2 className="subheadAllCaps">During Your Hospital Stay
                                                    (and reviewing when you get home)</h2>
                                                <p><Nav.Link href="/Pancreatic_Video-Care-After-Surgery">Care After
                                                    Surgery</Nav.Link></p>
                                                <p><Nav.Link href="/Pancreatic_Video-Care-Of-Your-Drain">Care Of Your
                                                    Drains</Nav.Link></p>
                                                <p><Nav.Link href="/Pancreatic_Video-Going-Home-What-To-Expect">Going
                                                    Home – What To Expect</Nav.Link></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 backgroundImageWithColorBar withNavigation heightAutoOverride wow fadeInDown animated fadeInDownAnim">
                                        <div className="contentCenter">
                                            <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                <div className="centeringContainer">
                                                    <div className="text-center h3Container">
                                                        <h3 className="whiteColor text-center">Lessons</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                        <div className="w-100">
                                            <div className="col-10 m-auto">
                                                <div className="lesssonContainer">
                                                    <div className="imgContainer">
                                                        <Nav.Link href="/LessonDisposal">
                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10266_small.jpg"
                                                                className="img-responsive "/>
                                                        </Nav.Link>
                                                    </div>
                                                    <div className="contentContainer allImages">
                                                        <div className="lessonsTitleContainer noBorder">
                                                            <h1><Nav.Link href="/LessonDisposal">Disposal of Medical
                                                                Waste</Nav.Link></h1>
                                                        </div>
                                                        <div className="descriptionContainer">
                                                            <p>Medical waste is a biohazard and dangerous to people,
                                                                pets and the environment. Learn proper handling and
                                                                disposal methods.</p>
                                                        </div>
                                                        <div className="buttonContainer">
                                                            <div className="insideContainer">
                                                                <Nav.Link href="/LessonDisposal"
                                                                          className="btn btn-primary t">
                                                                    <p>View Lesson&nbsp;</p><i
                                                                    className="fa fa-chevron-right"></i>
                                                                </Nav.Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="lesssonContainer">
                                                    <div className="imgContainer">
                                                        <Nav.Link href="/LessonFallPrevention"><img
                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10207_small.jpg"
                                                            className="img-responsive"/></Nav.Link>
                                                    </div>
                                                    <div className="contentContainer allImages">
                                                        <div className="lessonsTitleContainer noBorder">
                                                            <h1><Nav.Link href="/LessonFallPrevention">Fall
                                                                Prevention</Nav.Link></h1>
                                                        </div>
                                                        <div className="descriptionContainer">
                                                            <p>Falls are the most common cause of injury at home,
                                                                regardless of age or medical condition. Learn how to
                                                                protect loved ones from preventable injury.</p>
                                                        </div>
                                                        <div className="buttonContainer">
                                                            <div className="insideContainer">
                                                                <Nav.Link href="/LessonFallPrevention"
                                                                          className="btn btn-primary">
                                                                    <p>View Lesson&nbsp;</p><i
                                                                    className="fa fa-chevron-right"></i>
                                                                </Nav.Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="imgContainer">
                                                            <Nav.Link href="/LessonMoving"><img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10259_small.jpg"
                                                                className="img-responsive"/></Nav.Link>
                                                        </div>
                                                        <div className="contentContainer allImages">
                                                            <div className="lessonsTitleContainer noBorder">
                                                                <h1><Nav.Link href="/LessonMoving">Moving Safely in the
                                                                    Home</Nav.Link></h1>
                                                            </div>
                                                            <div className="descriptionContainer">
                                                                <p>If you care for someone who is limited in the way
                                                                    they walk and move, this care lesson shows you how
                                                                    to help a person walk and to move from a bed to a
                                                                    chair safely. You will also learn the risks for
                                                                    falling and what to do if a person falls in the
                                                                    home.</p>
                                                            </div>
                                                            <div className="buttonContainer">
                                                                <div className="insideContainer">
                                                                    <Nav.Link href="/LessonMoving"
                                                                              className="btn btn-primary">
                                                                        <p>View Lesson&nbsp;</p><i
                                                                        className="fa fa-chevron-right"></i>
                                                                    </Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*<div*/}
                                            {/*    className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">*/}
                                            {/*    <div className="w-100">*/}
                                            {/*        <div className="col-10 m-auto">*/}
                                            {/*            <div className="lesssonContainer">*/}
                                            {/*                <div className="imgContainer">*/}
                                            {/*                    <Nav.Link href="/LessonDressing"><img*/}
                                            {/*                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10269_small.jpg"*/}
                                            {/*                        className="img-responsive"/></Nav.Link>*/}
                                            {/*                </div>*/}
                                            {/*                <div className="contentContainer allImages">*/}
                                            {/*                    <div className="lessonsTitleContainer noBorder">*/}
                                            {/*                        <h1><Nav.Link href="/LessonDressing">Dressing a*/}
                                            {/*                            Person</Nav.Link></h1>*/}
                                            {/*                    </div>*/}
                                            {/*                    <div className="descriptionContainer">*/}
                                            {/*                        <p>Illness can make dressing difficult. Learn how to*/}
                                            {/*                            help a person, limited in the use of their limbs*/}
                                            {/*                            or with memory problems, getting dressed.</p>*/}
                                            {/*                    </div>*/}
                                            {/*                    <div className="buttonContainer">*/}
                                            {/*                        <div className="insideContainer">*/}
                                            {/*                            <Nav.Link href="/LessonDressing"*/}
                                            {/*                                      className="btn btn-primary">*/}
                                            {/*                                <p>View Lesson&nbsp;</p><i*/}
                                            {/*                                className="fa fa-chevron-right"></i>*/}
                                            {/*                            </Nav.Link>*/}
                                            {/*                        </div>*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div
                                                className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                                <div className="w-100">
                                                    <div className="col-10 m-auto">
                                                        <div className="lesssonContainer">
                                                            <div className="imgContainer">
                                                                <Nav.Link href="/LessonPain"><img
                                                                    src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10267_small.jpg"
                                                                    className="img-responsive"/></Nav.Link>
                                                            </div>
                                                            <div className="contentContainer allImages">
                                                                <div className="lessonsTitleContainer noBorder">
                                                                    <h1><Nav.Link href="/LessonPain">Recognizing &
                                                                        Relieving Pain</Nav.Link></h1>
                                                                </div>
                                                                <div className="descriptionContainer">
                                                                    <p>Learn how to observe and care for a person who is
                                                                        in pain and how to provide pain treatment and
                                                                        comfort measures safely.</p>
                                                                </div>
                                                                <div className="buttonContainer">
                                                                    <div className="insideContainer">
                                                                        <Nav.Link href="/LessonPain"
                                                                                  className="btn btn-primary">
                                                                            <p>View Lesson&nbsp;</p><i
                                                                            className="fa fa-chevron-right"></i>
                                                                        </Nav.Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="lesssonContainer">
                                                            <div className="imgContainer">
                                                                <Nav.Link href="/LessonGlucose"><img
                                                                    src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10264_small.jpg"
                                                                    className="img-responsive"/></Nav.Link>
                                                            </div>
                                                            <div className="contentContainer allImages">
                                                                <div className="lessonsTitleContainer noBorder">
                                                                    <h1><Nav.Link href="/LessonGlucose">Measuring Blood
                                                                        Glucose</Nav.Link></h1>
                                                                </div>
                                                                <div className="descriptionContainer">
                                                                    <p>Measuring blood glucose is a simple blood test
                                                                        that tells how
                                                                        well a person manages their diabetes. This
                                                                        lesson explains
                                                                        normal blood glucose, the problems of
                                                                        hypoglycemia and
                                                                        hyperglycemia, and how to select a glucose
                                                                        meter. You will also
                                                                        learn how to measure a blood glucose correctly,
                                                                        what to do if a
                                                                        glucose reading is abnormal, and safety tips on
                                                                        preventing
                                                                        infection.</p>
                                                                </div>
                                                                <div className="buttonContainer">
                                                                    <div className="insideContainer">
                                                                        <Nav.Link href="/LessonGlucose"
                                                                                  className="btn btn-primary">
                                                                            <p>View Lesson&nbsp;</p><i
                                                                            className="fa fa-chevron-right"></i>
                                                                        </Nav.Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="imgContainer">
                                                            <Nav.Link href="/LessonDrainage"><img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/20002_small.jpg"
                                                                className="img-responsive"/></Nav.Link>
                                                        </div>
                                                        <div className="contentContainer allImages">
                                                            <div className="lessonsTitleContainer noBorder">
                                                                <h1><Nav.Link href="/LessonDrainage">Care of
                                                                    Drainage Bags</Nav.Link></h1>
                                                            </div>
                                                            <div className="descriptionContainer">
                                                                <p>This lesson describes common types of wound drains
                                                                    and the systems used to collect drainage. You will
                                                                    learn about the purpose of the devices, how to empty
                                                                    and manage a device correctly, the ways to reduce
                                                                    spread of infection and how to properly dispose of
                                                                    wound drainage.</p>
                                                            </div>
                                                            <div className="buttonContainer">
                                                                <div className="insideContainer">
                                                                    <Nav.Link href="/LessonDrainage"
                                                                              className="btn btn-primary">
                                                                        <p>View Lesson&nbsp;</p><i
                                                                        className="fa fa-chevron-right">&nbsp;</i>
                                                                    </Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="imgContainer">
                                                            <Nav.Link href="/LessonGauzeDressing"><img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10180_small.jpg"
                                                                className="img-responsive"/></Nav.Link>
                                                        </div>
                                                        <div className="contentContainer allImages">
                                                            <div className="lessonsTitleContainer noBorder">
                                                                <h1><Nav.Link href="/LessonGauzeDressing">Changing A
                                                                    Gauze Dressing</Nav.Link></h1>
                                                            </div>
                                                            <div className="descriptionContainer">
                                                                <p>When a person's wound is covered with a gauze
                                                                    dressing, you need to know what a normal healing
                                                                    wound looks like, the signs of infection, and the
                                                                    risks for infection. This lesson explains how to
                                                                    look at a wound to know if it is healing, how to
                                                                    cleanse a wound and how to apply a new clean gauze
                                                                    dressing.</p>
                                                            </div>
                                                            <div className="buttonContainer">
                                                                <div className="insideContainer">
                                                                    <Nav.Link href="/LessonGauzeDressing"
                                                                              className="btn btn-primary">
                                                                        <p>View Lesson&nbsp;</p><i
                                                                        className="fa fa-chevron-right">&nbsp;</i>
                                                                    </Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="imgContainer">
                                                            <Nav.Link href="/LessonGivingMedicines"><img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10265_small.jpg"
                                                                className="img-responsive"/></Nav.Link>
                                                        </div>
                                                        <div className="contentContainer allImages">
                                                            <div className="lessonsTitleContainer noBorder">
                                                                <h1><Nav.Link href="/LessonGivingMedicines">Giving
                                                                    Medicines
                                                                    Safely</Nav.Link></h1>
                                                            </div>
                                                            <div className="descriptionContainer">
                                                                <p>When you care for someone in the home, medicine
                                                                    safety is
                                                                    one of your most important jobs. This care lesson
                                                                    teaches you how to prepare and give medicines safely
                                                                    and
                                                                    what to do when a person has trouble taking
                                                                    medicines.
                                                                    The lesson offers information about side effects and
                                                                    allergies from medicines.</p>
                                                            </div>
                                                            <div className="buttonContainer">
                                                                <div className="insideContainer">
                                                                    <Nav.Link href="/LessonGivingMedicines"
                                                                              className="btn btn-primary">
                                                                        <p>View Lesson&nbsp;</p><i
                                                                        className="fa fa-chevron-right"></i>
                                                                    </Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div*/}
                                        {/*    className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">*/}
                                        {/*    <div className="w-100">*/}
                                        {/*        <div className="col-10 m-auto">*/}
                                        {/*            <div className="lesssonContainer">*/}
                                        {/*                <div className="imgContainer">*/}
                                        {/*                    <Nav.Link href="/LessonNebulizers"><img*/}
                                        {/*                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10279_small.jpg"*/}
                                        {/*                        className="img-responsive"/></Nav.Link>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="contentContainer allImages">*/}
                                        {/*                    <div className="lessonsTitleContainer noBorder">*/}
                                        {/*                        <h1><Nav.Link*/}
                                        {/*                            href="/LessonNebulizers">Nebulizers</Nav.Link>*/}
                                        {/*                        </h1>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="descriptionContainer">*/}
                                        {/*                        <p>People with chronic lung problems often use*/}
                                        {/*                            nebulizers to deliver medicine in the form of a mist*/}
                                        {/*                            into their breathing passages. The medicines widen*/}
                                        {/*                            airway passages, reduce inflammation, and help make*/}
                                        {/*                            it easier to cough up mucous. This lesson explains*/}
                                        {/*                            the reasons for using a nebulizer, the effects of*/}
                                        {/*                            the medicines, and how to correctly use and clean a*/}
                                        {/*                            nebulizer.</p>*/}
                                        {/*                    </div>*/}
                                        {/*                    <div className="buttonContainer">*/}
                                        {/*                        <div className="insideContainer">*/}
                                        {/*                            <Nav.Link href="/LessonNebulizers"*/}
                                        {/*                                      className="btn btn-primary">*/}
                                        {/*                                <p>View Lesson&nbsp;</p><i*/}
                                        {/*                                className="fa fa-chevron-right"></i>*/}
                                        {/*                            </Nav.Link>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="imgContainer">
                                                            <Nav.Link href="/LessonSubcutaneousInjections"><img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10284_small.jpg"
                                                                className="img-responsive"/></Nav.Link>
                                                        </div>
                                                        <div className="contentContainer allImages">
                                                            <div className="lessonsTitleContainer noBorder">
                                                                <h1><Nav.Link href="/LessonSubcutaneousInjections">Subcutaneous
                                                                    Injections</Nav.Link></h1>
                                                            </div>
                                                            <div className="descriptionContainer">
                                                                <p>If you care for a person who takes medicines by
                                                                    injection (a shot), you need to learn how to give an
                                                                    injection correctly and how to avoid injury to the
                                                                    person you care for. This care lesson teaches you
                                                                    how to use a syringe and an injection pen, how to
                                                                    give an injection, and what to look for after giving
                                                                    a medicine.</p>
                                                            </div>
                                                            <div className="buttonContainer">
                                                                <div className="insideContainer">
                                                                    <Nav.Link href="/LessonSubcutaneousInjections"
                                                                              className="btn btn-primary">
                                                                        <p>View Lesson&nbsp;</p><i
                                                                        className="fa fa-chevron-right"></i>
                                                                    </Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-12 safecareLessons wow fadeInRight animated fadeInRightAnim">
                                            <div className="w-100">
                                                <div className="col-10 m-auto">
                                                    <div className="lesssonContainer">
                                                        <div className="imgContainer">
                                                            <Nav.Link href="/LessonAspiration"><img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10273_small.jpg"
                                                                className="img-responsive"/></Nav.Link>
                                                        </div>
                                                        <div className="contentContainer allImages">
                                                            <div className="lessonsTitleContainer noBorder">
                                                                <h1><Nav.Link href="/LessonAspiration">Aspiration
                                                                    Precautions</Nav.Link></h1>
                                                            </div>
                                                            <div className="descriptionContainer">
                                                                <p>Aspiration is the accidental entrance of food or
                                                                    saliva into a person’s windpipe, a problem to look
                                                                    for in persons who have trouble swallowing. This
                                                                    care lesson explains the signs of difficulty
                                                                    swallowing, safe eating tips, and shows you
                                                                    step-by-step how to perform the Heimlich Maneuver
                                                                    when a person chokes on their food.</p>
                                                            </div>
                                                            <div className="buttonContainer">
                                                                <div className="insideContainer">
                                                                    <Nav.Link href="/LessonAspiration"
                                                                              className="btn btn-primary">
                                                                        <p>View Lesson&nbsp;</p><i
                                                                        className="fa fa-chevron-right"></i>
                                                                    </Nav.Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                {/*    <div className="lesssonContainer">*/}
                                                {/*        <div className="imgContainer">*/}
                                                {/*            <Nav.Link href="/LessonFeedingAPerson"><img*/}
                                                {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10274_small.jpg"*/}
                                                {/*                className="img-responsive"/></Nav.Link>*/}
                                                {/*        </div>*/}
                                                {/*        <div className="contentContainer allImages">*/}
                                                {/*            <div className="lessonsTitleContainer noBorder">*/}
                                                {/*                <h1><Nav.Link href="/LessonFeedingAPerson">Feeding A*/}
                                                {/*                    Person</Nav.Link></h1>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="descriptionContainer">*/}
                                                {/*                <p>When a person needs help with eating, you want to*/}
                                                {/*                    protect their safety and independence. This care*/}
                                                {/*                    lesson teaches you about the types of persons who*/}
                                                {/*                    need help with eating and how to feed a person*/}
                                                {/*                    safely. The lesson also explains steps to prevent a*/}
                                                {/*                    person from choking on their food and what to do*/}
                                                {/*                    when choking occurs.</p>*/}
                                                {/*            </div>*/}
                                                {/*            <div className="buttonContainer">*/}
                                                {/*                <div className="insideContainer">*/}
                                                {/*                    <Nav.Link href="/LessonFeedingAPerson"*/}
                                                {/*                              className="btn btn-primary">*/}
                                                {/*                        <p>View Lesson&nbsp;</p><i*/}
                                                {/*                        className="fa fa-chevron-right"></i>*/}
                                                {/*                    </Nav.Link>*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                    BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default Lessons;
