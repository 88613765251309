import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTNotCompleted = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto pageNotCompletedFlexContainer">
                            <h2 className="text-center text-dark">Page In Progress</h2>
                            <i className="fas fa-cog fa-spin fa-6x text-center width100 text-dark"></i>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTNotCompleted;
