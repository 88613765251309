import React from "react";

function L89AComponentCode() {
  return (
    <div>
      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin2 withBorderBottom">
        <div
          className="iconContainer"
          style={{ paddingBottom: "0px", paddingTop: "10px" }}
        >
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/message.svg"
          />
        </div>

        <div className="contentContainer">
          <p>
            <span className="bold">
              Help you communicate more openly and clearly with your care team
            </span>
            , sharing any concerns and making sure all topics are covered to
            your satisfaction.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}

      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin2 withBorderBottom">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/questionMark.svg"
          />
        </div>

        <div className="contentContainer">
          <p>
            <span className="bold"> Ask questions –</span>
            don’t be afraid to say you don’t understand something or need more
            information to be comfortable.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}

      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin2 withBorderBottom">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/rightArrow.svg"
          />
        </div>

        <div className="contentContainer">
          <p>
            <span className="bold">Follow your S.M.A.R.T. action plan</span>
            , giving you the consistency that is key to better outcomes.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}

      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin2 withBorderBottom">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/calender.svg"
            style={{ maxWidth: "28px" }}
          />
        </div>

        <div className="contentContainer">
          <p>
            <span className="bold">
              Help you attend all scheduled appointments,{" "}
            </span>
             including help with any logistics required.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}

      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin2 withBorderBottom">
        <div className="iconContainer" style={{ paddingTop: "10px" }}>
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/tickMark.svg"
          />
        </div>

        <div className="contentContainer">
          <p>
            <span className="bold">Keep accurate, consistent records </span>
            to make sure you and your care team know all the relevant
            information to make informed decisions about your health.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}

      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin2 withBorderBottom">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/statistics.svg"
          />
        </div>

        <div className="contentContainer">
          <p>
            <span className="bold">
              Monitor your progress and health status,{" "}
            </span>
             and pay attention to how your progress is keeping up with your
            goals.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}

      {/* L89A Content  with Icon and BottomBorder */}
      <div className="lego l089A legoMargin5 withBorderBottom">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/WalkingEmoji.svg"
            style={{ maxHeight: "34px" }}
          />
        </div>

        <div className="contentContainer">
          <p>
            And, of course, 
            <span className="bold">
              being there to support you throughout your journey
            </span>
             in the ways that will make a difference.
          </p>
        </div>
      </div>
      {/* End L89A Content  with Icon and BottomBorder */}
    </div>
  );
}

export default L89AComponentCode;
