import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonDrainageWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Drainage: Show Me How'}, () => {
            console.log('Drainage: Show Me How');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDrainage">Care of Drainage Bags</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDrainage/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDrainage/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDrainage/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDrainage/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Look at the Wound and Drain
                                                    Site</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When you manage a wound drainage system you need to learn to look at
                                                    the surgical wound and the drain closely. It is important for you to
                                                    recognize a wound infection or other complications early.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className=" text-center subheadUpperAndLowerColorBarIcon centerBar greenColorBar">
                                                    Wound
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Call the doctor and seek immediate help when:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>There is drainage or a foul odor at the wound</li>
                                                    <li>Stitches are missing or falling out</li>
                                                    <li>The drain has accidentally been pulled on and is now partially
                                                        or completely out. <strong><em>(If partially out, NEVER TRY TO
                                                            REINSERT)</em></strong></li>
                                                    <li>The wound edges are separating</li>
                                                    <li>There is increased redness, swelling, or tenderness in the skin
                                                        next to the stitches or incision
                                                    </li>
                                                    <li>The person has a fever, with their oral temperature 100.5°
                                                        Fahrenheit (38.0° Centigrade) or higher
                                                    </li>
                                                    <li>Wound bandages are soaked with blood</li>
                                                    <li>The wound vacuum does not work</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className=" text-center subheadUpperAndLowerColorBarIcon greenColorBar autoWidth centerBar">
                                                    Drain Site
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadAndCopyWithIcon exclaim">
                                                    <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                    Call the doctor and seek immediate help when:
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>The skin at the drain site is excessively red, swollen, tender
                                                        or has drainage from the skin around the tube site
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Managing the Drainage Device</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Follow the doctor’s directions for the frequency to empty a
                                                        drainage device and for changing any wound dressing. See our
                                                        lesson on Changing a Gauze Dressing.
                                                    </li>
                                                    <li>Keep the drainage device connected to the person’s clothing with
                                                        a safety pin or clip so that the drain does not pull out.
                                                        (Always remind the person that the device is clipped to his or
                                                        her clothing to avoid accidental dislodgement).
                                                    </li>
                                                    <li>Wash your hands for 15 seconds before and after you provide care
                                                        for a drain. See our lesson on Infection Control.
                                                    </li>
                                                    <li>Apply a pair of clean gloves before touching the drainage
                                                        device. This protects you from transferring any germs to
                                                        yourself or others.
                                                    </li>
                                                    <li>If there is a small dressing around the site where the drain
                                                        enters the skin, remove it carefully so you do not pull the
                                                        drain out.
                                                    </li>
                                                    <li>Make sure the drain tubing is not kinked and drainage flows
                                                        freely. Check for leaks in the system.
                                                    </li>
                                                    <li>If you notice holes or cracks in the tubing, or in the bulb or
                                                        collection container, call your home health nurse or doctor’s
                                                        office immediately.
                                                    </li>
                                                    <li>Always keep the drainage container lower than the wound. This
                                                        prevents drainage from flowing back and bathing the wound with
                                                        bacteria from the collection container.
                                                    </li>
                                                    <li>Touch only the drainage system, if you need to provide other
                                                        care remove gloves and wash your hands.
                                                    </li>
                                                    <li>Always wipe off the spout of the drainage device with an alcohol
                                                        swab just before you close it.
                                                    </li>
                                                    <li>Never allow the drainage tubing to be pulled on, as this can
                                                        loosen the sutures and cause the tube to come out.
                                                    </li>
                                                    <li>The doctor may have you measure the amount of drainage each time
                                                        you empty the drain. Keep a record of the amount of drainage in
                                                        your care diary.
                                                    </li>
                                                    <li>Empty the drain into a measuring cup or pitcher, and then empty
                                                        the contents into the toilet. Mark the outside of the measuring
                                                        cup “For Wound Care Only” so that you and anyone else in the
                                                        home does not use the cup for other purposes.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Disposal of Medical Waste</h2>
                                                <p>Wound drainage is a type of medical waste. Correct disposal of
                                                    medical wastes protects the family, community, and the safety of
                                                    sanitation workers.</p>
                                                <p>See our lesson on <a href="safecare_1-1-SubCluster.html"
                                                                        target="_blank">Disposal of Medical Waste</a>.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Throw away soiled or bloody gauze dressings and gloves into a
                                                        plastic or leak proof bag. If the dressings are highly soiled or
                                                        bloody then place this first bag into a second bag, called
                                                        “double bagging”. This bag can then be thrown into your regular
                                                        trash.
                                                    </li>
                                                    <li>Be sure to use a tightly lidded trash container so that animals
                                                        are not attracted to the bloody or soiled items. Some
                                                        communities request that you notify your trash hauling company
                                                        and just let them know that there is medical waste at your
                                                        residence.
                                                    </li>
                                                    <li>Dispose of bile or wound drainage into the toilet and flush
                                                        twice. Avoid splashing.
                                                    </li>
                                                    <li>Up to date information regarding medical waste disposal is
                                                        located on the Environmental Protection Agency website found in
                                                        the Resources section of this lesson.
                                                    </li>
                                                    <li>Never place a medical waste container in a recycling bin.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Wash your hands thoroughly for 15 seconds before you handle a
                                                        drainage device.
                                                    </li>
                                                    <li>Do not touch the tip or plug of the drainage device to the
                                                        measuring cup or anything else. Touching the plug could transfer
                                                        infection.
                                                    </li>
                                                    <li>Always keep the drainage spout of the drainage device closed
                                                        when you are not emptying it. If left open, there will not be
                                                        enough vacuum to promote drainage. Also, germs can enter the
                                                        open spout.
                                                    </li>
                                                    <li>Be sure to routinely look at the drain insertion site and the
                                                        drainage for signs of infection.
                                                    </li>
                                                    <li>Dispose of drainage in the toilet. Wear a pair of
                                                        glasses if there is a risk of splashing.
                                                    </li>
                                                    <li>If drainage splashes and lands on your skin wash that
                                                        area thoroughly with soap and water. If drainage
                                                        splashes into the eyes, flush eye with large amounts of
                                                        cool water for 15 minutes.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">

                                        <div className="col-12 video">
                                            <div className="w-100">
                                                <h3 className="blueColor videoTitle width100">
                                                    Video: Managing a Wound Drainage System.
                                                </h3>
                                                <div className="floatLeft width100 captionBelow">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="TeW943PgINM" opts={opts} onPlay={playVideo}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="" target="_blank">Open File<i
                                                    className="fa fa-chevron-right"></i></a></p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="navTopicButtonPageHref">
                                                    <a id="equipment">EQUIPMENT</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100 text-center">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/DB_equipment.png"
                                                         className="img-responsive  maxWidth300 floatNone"/>
                                                    <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom greenBullets">
                                                        <li>Measuring container</li>
                                                        <li>Clean disposable gloves</li>
                                                        <li>Gauze dressings</li>
                                                        <li>Tape</li>
                                                        <li>Alcohol swabs</li>
                                                        <li>Plastic trash bags</li>
                                                        <li>Waterproof pad</li>
                                                        <li>Care diary</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer greenBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                    className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default LessonDrainageWhatToDoAndSafety;
