import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL86Component({ code, language }) {
    code = `{/* L86 Table New Lego */}
                                    <div className="lego l086">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <td><span className="bold">Aspirin 81 mg</span></td>
                                                <td><span className="bold">5 days</span><br/>
                                                    (Stop for Cervical Injections ONLY)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Aspirin 325 mg</span></td>
                                                <td><span className="bold">5 days</span><br/>
                                                    Acetaminophen/Tylenol may be used for pain relief instead if
                                                    no
                                                    liver problems
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Coumadin</span> (Warfarin)</td>
                                                <td><span className="bold">5 days</span><br/>INR levels must be ordered and drawn within 24
                                                    hour of
                                                    procedure. This test will be ordered for guidance of your
                                                    orthopedic
                                                    condition only. Follow up with your managing provider for
                                                    guidance
                                                    regarding management of warfarin dosing.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Eliquis</span> (Apixaban)</td>
                                                <td><span className="bold">3 days</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">NSAIDs</span> non-steroid anti-inflammatory drugs such as: Motrin,
                                                    Aleve,
                                                    Ibuprofen, Meloxicam, Diclofenac, Ketorolac, etc.
                                                </td>
                                                <td><span className="bold">5 days</span><br/>
                                                    Acetaminophen/Tylenol may be used for pain relief instead if
                                                    no
                                                    liver problems
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Plavix</span> (Clopidogrel)</td>
                                                <td><span className="bold">7 days</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Pradaxa</span> (Dabigatran)</td>
                                                <td><span className="bold">4 days</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Persantine</span> (Dipyridamole)</td>
                                                <td><span className="bold">24 hours</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Arixtra</span> (Fondaparinux)</td>
                                                <td><span className="bold">TBD</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Brilinta</span> (Ticagrelor) )</td>
                                                <td><span className="bold">7 days</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Effient</span> (Prasugrel)</td>
                                                <td><span className="bold">10 days</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Pletal</span> (Cilostazol))</td>
                                                <td><span className="bold">48 hours</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Xarelto</span> (Rivaroxaban)</td>
                                                <td><span className="bold">3 days</span></td>
                                            </tr>
                                            <tr>
                                                <td><span className="bold">Savaysa</span> (Edoxaban)</td>
                                                <td><span className="bold">72 hours</span></td>
                                            </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                    {/* End L86 Table New Lego */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL86Component;