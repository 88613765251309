import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import Accordion from "react-bootstrap/Accordion";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog.js";
const SitemanENTTGSP4_1_5 = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Your New Normal after a Tracheostomy: Free Flaps Reconstruction'}, () => {
            console.log('Your New Normal after a Tracheostomy: Free Flaps Reconstruction');
        });
        await dispatch(pal);
    }

return (
    <div>
        <div className="w-100">
            <Container>
                <div className="w-100">
                    <div className="col-10 m-auto">
                        <div className="whiteBackground floatLeft clearLeft">
                            <section>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h1 className="tileTitleHeader centeredPageHeader text-center">Your New
                                            Normal After Head & Neck Surgery</h1>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Major surgery of the head and neck can be a life-changing event.
                                            Depending on what type of surgery you had, you may note changes in how
                                            you eat, speak, and/or how you look. While the goal is to get you back
                                            as close to how you were before surgery, there will be changes and you
                                            will have a “new normal.”</p>
                                        <p className="bold italic">Below are some of the changes that may occur:</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Appearance:</p>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom marginBottom12">
                                                    <li>Depending on what type of surgery you had, you will likely
                                                        notice some changes in your appearance. In general, most
                                                        patients appear swollen after surgery. Over time this
                                                        swelling will decrease. The amount that the swelling
                                                        decreases and how long it takes for the swelling to decrease
                                                        is dependent on the type of surgery you had. If you will be
                                                        having radiation therapy, it may prolong the swelling. Most
                                                        people continue to see improvements over the first year
                                                        after surgery. Sometimes <a href="/siteman-ent-all-8-8">lymphedema
                                                            therapy</a> may help decrease swelling.
                                                    </li>
                                                    <li>Over time the scars from your incisions will often appear
                                                        less obvious. Scars will continue to change and decrease for
                                                        many months.
                                                    </li>
                                                    <li>Many people will have numbness of their neck, face, and/or
                                                        scalp following surgery. In many cases the feeling in your
                                                        skin will return over time, but sometimes numbness in some
                                                        areas may continue. If any motor nerves (<em>nerves that
                                                            control muscles</em>) were removed or injured as part of
                                                        your surgery, you may notice weakness in your face,
                                                        shoulder, or mouth depending on the type of surgery you had.
                                                        You can talk to your surgeon if you have any questions.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Speaking:</p>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom marginBottom12">
                                                    <li>If you had surgery for a cancer in your mouth or throat, you
                                                        will likely notice changes in how you speak. Your speech may
                                                        not be as clear as you are used to, especially in the first
                                                        couple of weeks after surgery. With time, the swelling in
                                                        your mouth/throat will decrease and your speech will
                                                        improve. We have a team of speech language pathologist
                                                        (“SLPs”) who are experts in treating patients with head and
                                                        neck cancer. If necessary, they can help you <a
                                                            href="/siteman-ent-tgsp-4-2-4">improve your
                                                            speech</a> as well.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Eating/Drinking:</p>
                                        <p>If you had surgery for cancer in your mouth or throat, you will likely
                                            need to <a href="/siteman-ent-all-4-2-5">modify how you eat and
                                                drink</a>, at least for the initial period after surgery. Many
                                            patients will eventually return to their normal diet. Other patients may
                                            have to modify the consistency of the food and drink they consume. Some
                                            patients will require a feeding tube for a prolonged period after
                                            surgery. Visit <a href="/siteman-ent-all-4-1-2">HERE</a> to learn more
                                            about feeding tubes.</p>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <hr className="w-100"/>
                                        <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i
                                            className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center"
                                                style={{borderBottom: "0px", marginBottom: "0px"}}>This section only
                                                applies if you will have a tracheostomy</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header style={{marginLeft: "20px"}}>Click Here &nbsp;
                                                        <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="subheadAndBulletsWithFloat">
                                                            <div className="col-12">
                                                                <div className="w-100">
                                                                    <p>Many patients who have a tracheostomy will
                                                                        have it removed before they leave the
                                                                        hospital. For patients who have their
                                                                        tracheostomy removed, usually the small hole
                                                                        will close up in a matter of days
                                                                        (<em>see</em> <a
                                                                            href="/siteman-ent-tgsp-4-1-1">Tracheostomy
                                                                            Care</a>).</p>
                                                                    <p>Other patients will require a tracheostomy
                                                                        when they go home, possibly for the
                                                                        long-term.</p>
                                                                    <p>Many people return to a normal lifestyle
                                                                        after having a tracheostomy. You should be
                                                                        able to continue with most of your normal
                                                                        activities and hobbies. Before beginning any
                                                                        activities, check with your doctor or nurse.
                                                                        Not everyone will be able to do the same
                                                                        activities when they go home, based on the
                                                                        type of surgery they had.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 video">
                                                            <div className="">
                                                                <h3 className="blueColor videoTitle w-100 text-center">Tracheostomy
                                                                    Procedure</h3>
                                                                <div className="floatLeft w-100 captionBelow">
                                                                    <div className="embedContainer originalPadding">
                                                                        <YouTube videoId="RBH_T7v_GKE" opts={opts}
                                                                                 onPlay={playVideo}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div
                                                                className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                                                <h3 className="subheadUpperAndLowerColorBarIcon colorBlack entNavBaseYellowBackground">
                                                                    <i className="fa fa-exclamation-circle"></i>&nbsp; Stop
                                                                    any activity immediately if you feel short of
                                                                    breath, faint or become dizzy
                                                                </h3>
                                                                <p>Rest until the symptoms go away. If they do not
                                                                    go away after suctioning and/or 20 minutes, call
                                                                    your doctor.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower centered">It is
                                                                    important to pace your activities throughout the
                                                                    day.<br/>Do not try to do too many things at
                                                                    once.</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Walking:</p>
                                                                <p>This is one of the best forms of exercise because
                                                                    it increases circulation throughout your body
                                                                    and to your heart. It is important to increase
                                                                    your activity gradually. Walk at your own pace.
                                                                    Stop and rest if you get tired.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Outdoor Activities:</p>
                                                                <p>During your first follow up appointment, ask the
                                                                    doctor what other activities you will be able to
                                                                    begin again. <strong><em>The activities that
                                                                        could harm you are water related. Boating,
                                                                        skiing, fishing from a boat, and swimming
                                                                        should not be done.</em></strong> If you go
                                                                    underwater you will have no way to hold your
                                                                    breath. Water could freely enter your lungs and
                                                                    you could drown.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Rest:</p>
                                                                <p>You will need a balance of rest and exercise for
                                                                    your recovery. Plan to rest between activities.
                                                                    Take short naps as needed. Rest also includes
                                                                    sitting quietly for 20-30 minutes. After meals
                                                                    rest for 30 minutes before exercising.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Lifting:</p>
                                                                <p>You should not put too much strain on your
                                                                    incision while it is healing. Avoid lifting,
                                                                    pushing, or pulling anything heavier than 10
                                                                    pounds for 3 weeks after surgery. This includes
                                                                    carrying children and groceries, mowing the
                                                                    grass, vacuuming and moving furniture. Be sure
                                                                    to not block your trach when bending.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Driving:</p>
                                                                <p>You can ride as a passenger in a car at any time.
                                                                    Be sure to bring your suction machine with you.
                                                                    Do not drive until your doctor allows you to do
                                                                    so. Do not drive a car or operate machinery if
                                                                    taking prescription pain medication.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Visitors:</p>
                                                                <p>Limit your visitors for the first couple of
                                                                    weeks. If you get tired, excuse yourself and lie
                                                                    down. Ask your visitors to understand that you
                                                                    are still getting better.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Work:</p>
                                                                <p>Check with your doctor before you return to
                                                                    work.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">Sexuality:</p>
                                                                <p>Many people living with a tracheostomy have
                                                                    concerns about engaging in sexual activity. Some
                                                                    practical considerations can make the experience
                                                                    comfortable for both you and your partner:</p>
                                                                <div className="col-12 subheadAndBullets">
                                                                    <div className="w-100">
                                                                        <ul className="bulletsCustom">
                                                                            <li>Remove mucus with your suction
                                                                                device first.
                                                                            </li>
                                                                            <li>Wearing a scarf or tee shirt around
                                                                                your trach will decrease your
                                                                                partner’s feeling and hearing air
                                                                                flowing through your trach.
                                                                            </li>
                                                                            <li>Keep the area around your trach
                                                                                clean to minimize any odor.
                                                                                Aftershave or perfume can help as
                                                                                well.
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 backToTop">
                                                            <div className="w-100">
                                                                <p><a className="specialUseBackToTop" href="#">BACK
                                                                    TO TOP <i className="fa fa-chevron-up"></i></a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </div>

)
};
export default SitemanENTTGSP4_1_5;
