import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {useEffectForVideoAccess, videoAccessLog} from "../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin2_5 = () => {

    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Our Cardiac Rehab Stories'}, () => {
            console.log('Our Cardiac Rehab Stories');
        });
        await dispatch(pal);
    }

    const playVideo2 = async (e) => {
        const pal = await videoAccessLog({videoName: 'My Story: Jane F.'}, () => {
            console.log('My Story: Jane F.');
        });
        await dispatch(pal);
    }

    const playVideo3 = async (e) => {
        const pal = await videoAccessLog({videoName: 'My Story: Charles T.'}, () => {
            console.log('My Story: Charles T.');
        });
        await dispatch(pal);
    }

    const playVideo4 = async (e) => {
        const pal = await videoAccessLog({videoName: 'My Story: Pam G.'}, () => {
            console.log('My Story: Pam G.');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorLightRed pritikinBorderLightRed">Hear
                                                from Past Patients</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            {/*<h3 className="videoTitle w-100 text-center">*/}
                                            {/*    The difference Pritikin ICR has made to me.*/}
                                            {/*</h3>*/}
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="0epCoWN6pcE" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                            {/*<p className="caption w-100 text-center">The difference Pritikin ICR has made to me.</p>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            {/*<h3 className="videoTitle w-100 text-center">*/}
                                            {/*    How I got started.*/}
                                            {/*</h3>*/}
                                            {/*Start This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="K1K4GOwmSnY" opts={opts} onPlay={playVideo2}/>
                                                </div>
                                            </div>
                                            {/*End This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                            {/*<p className="caption w-100 text-center">The difference Pritikin ICR has made to me.</p>*/}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“I came in <strong>defeated</strong>, you know? I’ve been trying for months to try to fix myself or help myself get better. I was just spinning my wheels and when you come in here, there’s someone who is glad to see you, and has time for you, and appreciates you and your questions. And then they show you exercises to help you get better, and things that work for you and your condition, as opposed to all the things I was trying and that weren’t working.”</p>
                                            <p className="name">— Ernest Z, Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“I feel very grateful that we have this program here because it truly has changed my life and I feel like I can do anything now.”</p>
                                            <p className="name">— Jane F., Cardiac Rehab Patient</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            {/*<h3 className="videoTitle w-100 text-center">*/}
                                            {/*    The difference Pritikin ICR has made to me.*/}
                                            {/*</h3>*/}
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="vrISqbukUeo" opts={opts} onPlay={playVideo3}/>
                                                </div>
                                            </div>
                                            {/*<p className="caption w-100 text-center">The difference Pritikin ICR has made to me.</p>*/}
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            {/*<h3 className="videoTitle w-100 text-center">*/}
                                            {/*    How I got started.*/}
                                            {/*</h3>*/}
                                            {/*Start This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="n6y_1CBhYtM" opts={opts} onPlay={playVideo4}/>
                                                </div>
                                            </div>
                                            {/*End This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                            {/*<p className="caption w-100 text-center">The difference Pritikin ICR has made to me.</p>*/}
                                        </div>
                                    </div>

                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100 text-center">*/}
                                    {/*            How the session worked for us.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="caption w-100 text-center">John and Jill S.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100 text-center">*/}
                                    {/*            How exercise made a difference.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="caption w-100 text-center">John and Jill S.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100 text-center">*/}
                                    {/*            How the cooking classes help us enjoy great, healthy foods*/}
                                    {/*            – at home and when eating out.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="caption w-100 text-center">John and Jill S.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100 text-center">*/}
                                    {/*            How I learned to handle stress and communicate in the healthiest ways.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="caption w-100 text-center">John and Jill S.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100 text-center">*/}
                                    {/*            How I worked with my care team.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="caption w-100 text-center">John and Jill S.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle w-100 text-center">*/}
                                    {/*            How families got involved.*/}
                                    {/*        </h3>*/}
                                    {/*        <div className="floatLeft w-100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive w-100"/>*/}
                                    {/*        </div>*/}
                                    {/*        <p className="caption w-100 text-center">John and Jill S.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin2_5;