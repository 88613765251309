import React from "react";

function L86ComponentCode() {

    return (

    <div className="lego l086">
        <table className="table">
            <tbody>
            <tr>
                <td><span className="bold">Aspirin 81 mg</span></td>
                <td><span className="bold">5 days</span><br/>
                    (Stop for Cervical Injections ONLY)
                </td>
            </tr>
            <tr>
                <td><span className="bold">Aspirin 325 mg</span></td>
                <td><span className="bold">5 days</span><br/>
                    Acetaminophen/Tylenol may be used for pain relief instead if
                    no
                    liver problems
                </td>
            </tr>
            <tr>
                <td><span className="bold">Coumadin</span> (Warfarin)</td>
                <td><span className="bold">5 days</span><br/>INR levels must be ordered and drawn within 24
                    hour of
                    procedure. This test will be ordered for guidance of your
                    orthopedic
                    condition only. Follow up with your managing provider for
                    guidance
                    regarding management of warfarin dosing.
                </td>
            </tr>
            <tr>
                <td><span className="bold">Eliquis</span> (Apixaban)</td>
                <td><span className="bold">3 days</span></td>
            </tr>
            <tr>
                <td><span className="bold">NSAIDs</span> non-steroid anti-inflammatory drugs such as: Motrin,
                    Aleve,
                    Ibuprofen, Meloxicam, Diclofenac, Ketorolac, etc.
                </td>
                <td><span className="bold">5 days</span><br/>
                    Acetaminophen/Tylenol may be used for pain relief instead if
                    no
                    liver problems
                </td>
            </tr>
            <tr>
                <td><span className="bold">Plavix</span> (Clopidogrel)</td>
                <td><span className="bold">7 days</span></td>
            </tr>
            <tr>
                <td><span className="bold">Pradaxa</span> (Dabigatran)</td>
                <td><span className="bold">4 days</span></td>
            </tr>
            <tr>
                <td><span className="bold">Persantine</span> (Dipyridamole)</td>
                <td><span className="bold">24 hours</span></td>
            </tr>
            <tr>
                <td><span className="bold">Arixtra</span> (Fondaparinux)</td>
                <td><span className="bold">TBD</span></td>
            </tr>
            <tr>
                <td><span className="bold">Brilinta</span> (Ticagrelor) )</td>
                <td><span className="bold">7 days</span></td>
            </tr>
            <tr>
                <td><span className="bold">Effient</span> (Prasugrel)</td>
                <td><span className="bold">10 days</span></td>
            </tr>
            <tr>
                <td><span className="bold">Pletal</span> (Cilostazol))</td>
                <td><span className="bold">48 hours</span></td>
            </tr>
            <tr>
                <td><span className="bold">Xarelto</span> (Rivaroxaban)</td>
                <td><span className="bold">3 days</span></td>
            </tr>
            <tr>
                <td><span className="bold">Savaysa</span> (Edoxaban)</td>
                <td><span className="bold">72 hours</span></td>
            </tr>
            </tbody>

        </table>
    </div>

    )
};


export default L86ComponentCode;