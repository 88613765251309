import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonGlucoseIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };
    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Glucose: Hear The Expert'}, () => {
            console.log('Glucose: Hear The Expert');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGlucose">Measuring Blood Glucose</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGlucose/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGlucose/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_intro.png" className="img-responsive maxWidth300 floatNone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When you provide care for a person who has Diabetes mellitus, you need to know how to measure their blood sugar (called blood glucose). The person’s blood glucose is a measure of their health.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Glucose is a simple sugar found in many foods. When it enters the bloodstream it provides energy for the body.</li>
                                                    <li>The cells of our body use glucose for normal metabolism.</li>
                                                    <li>Normally, glucose enters cells with the help of insulin.</li>
                                                    <li>Persons with diabetes either do not make enough insulin or are “insulin resistant” meaning they make insulin, but it does not work properly.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Diabetes</p>
                                                <p>Measuring blood glucose helps a person with diabetes manage their disease. The level of a person’s blood glucose determines:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>The diabetes medicines they should take.</li>
                                                    <li>The type and amount of food they can eat.</li>
                                                    <li>The level of exercise they can take part in.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_drop_strip.png" className="img-responsive maxWidth300 floatNone" />
                                                <p className="captionItalic text-center w-100">Drop of blood applied to test strip</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Measuring blood glucose requires a very simple blood test. You place a test strip into a small glucose meter. Then you prick the person’s finger with a sterile lancet and squeeze a small drop of blood onto the test strip. In a few seconds the meter displays the amount of glucose in the blood. The person’s doctor will tell you how often the person should check their blood glucose.</p>
                                                <p>Learn how to measure a person’s blood glucose in case he or she becomes ill or has trouble handling the test meter or lancet.</p>
                                                <p className="boldLeadIn">Safe blood glucose monitoring requires you to:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Know the normal range for a blood glucose level.</li>
                                                    <li>Know what to do when a person’s blood glucose is too low or too high.</li>
                                                    <li>Obtain a blood drop without causing the person to get an infection.</li>
                                                    <li>Protect yourself from a lancet stick.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>By tracking blood glucose levels each day, a person will have a record showing how well their diabetes is under control.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Video: Measuring Blood Glucose
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="YYKUCGfhsWc" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGlucoseIntroduction;
