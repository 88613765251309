import {currentApi} from "../../src/api/index.js";
import {REGISTER, REGISTER_ERROR} from "../../src/actions/types.js";
import Evaporate from 'evaporate';
import { v4 as uuidv4 } from 'uuid';
import MD5 from 'js-md5';
import { sha256 as SHA256 } from 'js-sha256';

export const profileImageUpload = async (data, cb) => async (
    dispatch
) => {
    try {
        let fileName = uuidv4();

        if (data && data?.name && data?.name?.length > 0) {
            const extensionSplit = data?.name.split('.');
            const extension = extensionSplit[1];
            fileName = fileName + '.' + extension;

            Evaporate.create({
                signerUrl: `${currentApi('user')}${'/user/auth/'}`,
                aws_key: 'AKIA23BXD5MZWIHI5A43',
                bucket: 'orbit-image-video-bucket',
                cloudfront: true,
                computeContentMd5: true,
                awsRegion: 'us-east-2',
                cryptoMd5Method: (_) => md5(_),
                cryptoHexEncodedHash256: (_) => sha256(_)
            })
            .then(evaporate =>
                evaporate.add({
                    name: fileName,
                    file: data,
                    progress: (progress) => {
                        console.log(progress);
                    },
                }))
            .then(s3Key => {
                cb(fileName);
            }, err => {
                console.log('error', err)
            });
        } else {
            cb(fileName);
        }

        return fileName;
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                dispatch({
                    type: REGISTER_ERROR,
                    payload: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                dispatch({
                    type: REGISTER_ERROR,
                    payload: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: REGISTER_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: REGISTER_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: REGISTER_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};

const md5 = (x) => { const o = MD5.create(); o.update(x); return o.base64(); };
const sha256 = (x) => { const o = SHA256.create(); o.update(x); return o.hex(); };
