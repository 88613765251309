import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic20 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Being Active</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-md-2 col-xs-3 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/active-image.jpg" alt=""/>
                                            </div>
                                            <div className="col-md-10 col-xs-9 typeCol noPaddingLeft">
                                                <p>It is normal to feel easily fatigued after your operation. Even simple tasks may, at first, be tiring.
                                                </p>
                                                <p>But <strong>activity after your surgery is one of the biggest keys to a quicker, more effective healing process.</strong> It helps normalize the GI tract after your surgery. It also is the best way to minimize complications like Deep Vein Thrombosis (blood clots) and loss of muscle.</p>
                                                <p className="mobileMarginBottom30">Being active helps improve your sleep, mood and physical and mental well-being. And eating properly can help increase your energy, helping you stay active throughout the day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Are there limitations and guidelines?</h2>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>For the first month home, it’s advisable to have someone assist you with errands like grocery shopping. Don’t try to lift anything heavier than a gallon of milk (anything over 10 lbs.) for the first six weeks.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>For the first 6 weeks after surgery, walking is the best activity. You should try to walk 6 to 8 times per day. You can do this outside or, if weather does not permit, around a shopping mall or similar environment.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>You should resume moderate exercise in a common sense manner, generally 6 weeks after your operation, as long as you avoid lifting, pushing or pulling anything heavier than 10 lbs. for 6 weeks.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>You should not drive until you have completely stopped taking pain medication and are able to respond to emergency situations appropriately.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>You can resume sexual activity in 4 weeks, unless instructed otherwise by your physician.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Other basic activities of daily living should be resumed as soon as possible:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You may need to take a nap during the day and this is perfectly fine. But avoid staying in bed all day.</p>
                                            <p>You can shower every day. You should get dressed each day and walk frequently to help build up your strength.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">A KEY ACTIVITY: CONTINUING USE OF YOUR INCENTIVE SPIROMETER</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-2 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/jog.jpg" alt=""/>
                                            </div>
                                            <div className="col-10 typeCol noPaddingLeft">
                                                <p className="boldLeadIn">What is an incentive spirometer?</p>
                                                <p>An incentive spirometer, or IS, is a device that measures how deep your breathing is, showing how well your lungs inflate (expand) which affects how much air can go into your lungs. Use of an IS helps prevent lung problems by exercising the muscles used for breathing.
                                                </p>
                                                <p className="mobileMarginBottom30">An IS is made up a breathing tube, an air chamber and an indicator. The breathing tube is connected to the air chamber and has a mouthpiece at the end. The indicator is found inside the device. For spirometry, you will need to breathe slowly and deeply. Breathing deeply helps open your airway and fill your lungs with air.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Who may need to use an incentive spirometer?</p>
                                            <p>An incentive spirometer is commonly used in those who are at risk of having airway or breathing problems. Using an incentive spirometer may help people who smoke, patients with lung diseases or who are inactive or cannot move well. IS may also be used before and after having head, neck, chest or abdominal (stomach) surgery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">How do I use an incentive spirometer?</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn"><em>Getting Ready.</em></p>
                                            <p>Sit up straight and as comfortably as possible. Keep your head and neck centered and do not bend forward or backward. Hold the incentive spirometer in an upright position. Place the target pointer to the level that you need to reach or your caregiver has suggested.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">To Begin:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Exhale <em>(breathe out)</em> normally.</li>
                                                <li>Then, place the mouthpiece in your mouth with your lips sealed around it. Your tongue should not block the mouthpiece.</li>
                                                <li>Inhale (breathe in) slowly and deeply through the mouthpiece to raise the indicator. Try to make the indicator rise up to the level of the target pointer. This is the goal that you need to reach.</li>
                                                <li>Look for and make note of the highest level that the indicator can reach.
                                                </li>
                                                <li>When you cannot inhale any longer, remove the mouthpiece and hold your breath for at least three seconds.</li>
                                                <li>Then, exhale normally.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Exhale <em>(breathe out)</em> normally.</li>
                                                <li>Then, place the mouthpiece in your mouth with your lips sealed around it. Your tongue should not block the mouthpiece.</li>
                                                <li>Inhale (breathe in) slowly and deeply through the mouthpiece to raise the indicator. Try to make the indicator rise up to the level of the target pointer. This is the goal that you need to reach.</li>
                                                <li>Look for and make note of the highest level that the indicator can reach.
                                                </li>
                                                <li>When you cannot inhale any longer, remove the mouthpiece and hold your breath for at least three seconds.</li>
                                                <li>Then, exhale normally.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ol className="">
                                                <li>Repeat these steps 5 to 10 times every hour when you are awake, or as often as caregivers tell you to.</li>
                                                <li>After each session, try to cough out the sputum (mucus) from your lungs. This is done by inhaling deeply and pushing the air out of your lungs with a deep, strong cough.</li>
                                            </ol>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic20;
