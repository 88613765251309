import React from "react";

function L83IComponentCode() {

    return (

        <div className="lego l083 legoMargin2">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_glass_water.svg"/>
            </div>
            <div className="contentContainer">
                <p>You may&nbsp;<span className="bold italic">resume all medications the day after the procedure</span>,
                    &nbsp;including blood thinners/anti-coagulants.</p>
            </div>
        </div>

    )
};


export default L83IComponentCode;