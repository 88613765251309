import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Box from '@mui/material/Box';
import { HashLink as Link } from 'react-router-hash-link';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
library.add(fab, fas, far);
const LessonDisposalWhatToTellYourDoctor = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDisposal">Disposal of Medical Waste</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder text-center w-100">
                                                <i className="fa fa-exclamation-circle fa-4x text-center"></i>
                                                <h2 className="blackColor">Report to the doctor if you get an accidental needle or sharps stick.</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                             {/*<hr className="w-100" />*/}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDisposalWhatToTellYourDoctor;
