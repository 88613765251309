import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonObservingWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonObserving">Observing General Health</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonObserving/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonObserving/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonObserving/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonObserving/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Recognize Change</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Your ability to observe the person you care for and to recognize obvious, as well as not so obvious, changes is important. When you see a change that is sudden (e.g. symptoms of a stroke), unexpected (e.g. sudden mood swing) or a change that happens over several days or even weeks (e.g. the drainage from a cut that becomes thick with an odor or a change in the color or shape of a mole) – call the person’s doctor.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Listen to the person you care for</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If he or she is reliable, listen when they tell you something is wrong. Take time and have them explain in more detail what is wrong.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp; Here are some conditions that require you to call for medical help immediately.</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow text-center"><i className="fa fa-ambulance">&nbsp;</i>Heart attack — a medical emergency</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Blood flow to a part of the heart has stopped. Some heart attacks are sudden and intense and there is no doubt what is happening, however most heart attacks start slowly, with mild pain or discomfort.</p>
                                        <p>Symptoms of a Heart Attack (American Heart Association, 2016) are:</p>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li><span className="bold">Chest discomfort</span> – Usually in the center of the chest, lasting more than a few minutes or that goes away and comes back. It can feel like pressure, squeezing, fullness or pain</li>
                                                <li><span className="bold">Discomfort in other areas of the upper body. </span>Symptoms can include pain or discomfort in one or both arms, the back, neck, jaw or stomach.</li>
                                                <li><span className="bold">Shortness of breath</span> with or without chest discomfort</li>
                                                <li>Other signs: <span className="bold">cold sweat, nausea or lightheadedness</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The most common symptom for both men and women is chest pain or discomfort. However, women are more likely to have some of the other common symptoms, particularly shortness of breath, nausea/vomiting, and back or jaw pain.</p>
                                        <p>Don’t wait more than five minutes to call 911 or your emergency response number. Emergency medical services (EMS) can start treatment faster than if you try to drive someone to a hospital. Also, the person could pass out or their heart could stop before you get to the hospital.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow text-center"><i className="fa fa-ambulance">&nbsp;</i>Stroke — a medical emergency</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Blood flow to a part of the person’s brain has been blocked or stopped, either from a clot or from bleeding. Symptoms of a stroke (National Institute of Neurological Disorders and Stroke, 2017) are:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li><span className="bold">Sudden numbness or weakness</span> of the face, arm or leg (most often on one side of the body)</li>
                                            <li><span className="bold">Sudden confusion, trouble speaking</span> or understanding someone else’s speech</li>
                                            <li><span className="bold">Sudden trouble seeing</span> in one or both eyes</li>
                                            <li><span className="bold">Sudden trouble walking, dizziness, loss of balance or coordination</span></li>
                                            <li><span className="bold">Sudden severe headache</span> with no known cause</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">Call 911 – tell the person on the phone you think your family or friend is having a stroke..</p>
                                        <p>Tell the EMS responders you want to go to the local hospital that has a stroke center (if available) with 24-hour emergency stroke care. Do not let the person you care for talk you out of calling. Time is critical. If given within 3 hours of the start of symptoms, clot busting drugs given by a doctor can reduce the injury from a stroke.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Other Conditions</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Dementia</p>
                                        <p>If you care for an older adult, always watch for the warning signs of dementia, a progressive decline in a person’s ability to think. Alzheimer’s disease is one type of dementia. Warning signs of Alzheimer’s disease (Alzheimer’s Association, 2014) include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Memory loss that disrupts daily life, such as forgetting important dates, asking for the same information over and over, or relying on memory aides (e.g. reminder notes).</li>
                                            <li>Challenges with problem solving. Persons will have trouble developing and following a plan or working with numbers. Trouble in keeping track of monthly bills is common. It might take them much longer to do a routine task than before.</li>
                                            <li>Difficulty completing familiar tasks at home, work or leisure. The tasks a person usually performs each day become difficult, such as washing clothes, driving to the local store, managing a budget at work, remembering the rules of a favorite card game.</li>
                                            <li>Confusion with time or place. The person will lose track of dates, seasons and the passage of time. Sometimes they forget where they are and how they got there.</li>
                                            <li>Trouble understanding visual images and objects in space. It is common for persons with dementia to have difficulty reading, judging distances, and seeing different color contrasts. They might not recognize their own reflection in a mirror.</li>
                                            <li>New problems with words in speaking or writing. The person you care for might have trouble following or joining a conversation. It is common for a person to have trouble finding the right word or calling things by the wrong name. The person might stop in the middle of talking with you and have no idea how to go on.</li>
                                            <li>Misplacing things and losing the ability to retrace steps. Watch closely, the person you care for mightput things in unusual places such as, a carton of milk in the garage or a toothbrush in the kitchen drawer. A person may lose things and be unable to go back over their steps to find them.</li>
                                            <li>Decreased or poor judgment. A person with Alzheimer’s will change the way they make decisions, such as in the way they deal with money. Telemarketers might easily get them to donate or buy an item sight unseen. A person may no longer pay attention to grooming or keeping clean.</li>
                                            <li>Withdraws from work or social events. A person begins to avoid hobbies, social events, work projects or sports. It is common, for example to cancel regular social events (e.g. bridge game, night at movies) or to have trouble completing a hobby project.</li>
                                            <li>Changes in mood and personality. A person can become confused, suspicious, depressed, fearful or anxious often without warning. It is common for the person to get upset easily at work or with friends.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Disease Changes</p>
                                        <p>If the person you care for has a known disease, such as diabetes, arthritis or glaucoma, know the changes that tell you the disease is worsening or progressing. This means you need to know what are the common symptoms.</p>
                                        <p>For example, if your family member or friend has diabetes, know the behavior changes that occur with low blood sugar. If a person has glaucoma, know the signs of vision problems.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">New Medicines</p>
                                        <p>When a person starts new medicines, watch for changes. Changes may be shown in appearance, behavior, mental status and weight.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="safetytips">SAFETY TIPS</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>If the person you care for shows signs of a heart attack or stroke, do not delay. <span className="bold">Do not let them talk you out of calling 911</span> for emergency medical services. Quick action saves lives.</li>
                                                <li>If a person develops an unexplained pain that worsens quickly over time, call 911 or take the person to an emergency room. Do not try to treat it.</li>
                                                <li>Do not try to drive someone to the emergency room, if the person you care for shows signs of a heart attack or stroke.</li>
                                                <li>If a person becomes angry or threatens violence, stay an adequate distance away. Maintain an open exit so you do not feel trapped. Do not block an exit so the person feels trapped. Use slow gestures, speak softly when talking and avoid making an abrupt or sudden movement. Arguing or trying to reason can make the person angry or agitated.</li>
                                                <li>Maintain a safe environment if the person you care for has problems with walking. Assistive devices may be necessary, such as a walker or cane.</li>
                                                <li>See our lesson on <a href="/LessonWalkers" target="_blank">Using a Walker.</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="showMeHow">SHOW ME HOW</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>This lesson will prepare you to better care for a person in the home by learning how and what to observe.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Video: Observing General Health
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="eNTC20Q6SnI" opts={opts} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step Instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/GH_Diary.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Alcohol wipe</li>
                                                    <li>Cotton ball or small gauze pad</li>
                                                    <li>A sterile lancet to stick the finger</li>
                                                    <li>Blood glucose meter</li>
                                                    <li>Blood glucose test strips</li>
                                                    <li>Pair of clean disposable gloves</li>
                                                    <li>Plastic grocery bag</li>
                                                    <li>Sharps container</li>
                                                    <li>Blood glucose log</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p><a href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/pdf/YourDailyCareDiary.pdf"><i className="fa fa-file-o"></i>&nbsp; Download</a> and Print Your Daily Care Diary</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonObservingWhatToDoAndSafety;