import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTGSP7_4 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Communities and Other Help</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">You are never alone in your journey.</p>
                                            <p>Over the years, patients and families who have had similar diagnoses and treatments as the ones you may face, as well as healthcare professionals trying to do all they possible can to help, have founded many different communities specifically to help people on their journeys. </p>
                                            <p>They can be valuable allies providing help, guidance and other support in many ways. Consider what the groups listed here may be able to do with and for you.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Cancer_Support_Community_logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Cancer Support Community of Greater St. Louis</h2>
                                                        <p>Their mission is ensure that all people impacted by cancer are empowered by knowledge, strengthened by action, and sustained by community to enhance their overall well-being. They provide a comprehensive professional program of social and emotional support and education designed to enhance the mind, body, and spirit of people whose lives have been affected by cancer. All of their programs and services are provided at no charge to participants (known as members) so there’s never a financial barrier for anyone who needs cancer support.</p>
                                                        <p className="resourceLink">visit: <a href="http://www.cancersupportstl.org/" target="_blank">cancersupportstl.org</a></p>
                                                        <hr className="width100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight">
                                                        <img className="img-responsive" alt="Cancer Survivor Logo" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cancer_survivor_network.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Cancer Survivors Network</h2>
                                                        <p>Cancer Survivors Network is a vibrant community established by people whose lives have been touched by cancer, providing strength and inspiration from their personal stories, discussions and expressions of caring.</p>
                                                        <p className="resourceLink">visit: <a href="https://csn.cancer.org" target="_blank">csn.cancer.org</a></p>
                                                        <hr className="width100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cancer_survivor_network_small.png" alt="" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American Cancer Society Patient Programs and Services</h2>
                                                        <p>The American Cancer Society offers programs and services to help patients during and after cancer treatment with resources including a 24/7 Cancer Hotline, education, transportation and lodging help and recommendations on useful products. They can also help you locate other free or low-cost resources that may be available.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.cancer.org/treatment/support-programs-and-services" target="_blank">www.cancer.org/treatment/support-programs-and-services</a></p>
                                                        <hr className="width100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP7_4;
