import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonSubcutaneousInjectionsWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Subcutaneous Injections: Part of a Syringe and Vial'}, () => {
            console.log('Subcutaneous Injections: Part of a Syringe and Vial');    
        });    
        await dispatch(pal);
    }

    const playVideo2 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Subcutaneous Injections: Where to Give an Injection'}, () => {
            console.log('Subcutaneous Injections: Where to Give an Injection');    
        });    
        await dispatch(pal);
    }

    const playVideo3 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Subcutaneous Injections: Preparing to give a dose in a Syringe'}, () => {
            console.log('Subcutaneous Injections: Preparing to give a dose in a Syringe');    
        });    
        await dispatch(pal);
    }

    const playVideo4 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Subcutaneous Injections: Mixing two Meds in a Syringe'}, () => {
            console.log('Subcutaneous Injections: Mixing two Meds in a Syringe');    
        });    
        await dispatch(pal);
    }

    const playVideo5 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Subcutaneous Injections: Giving an Injection'}, () => {
            console.log('Subcutaneous Injections: Giving an Injection');    
        });    
        await dispatch(pal);
    }

    const playVideo6 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Subcutaneous Injections: Injection Pen'}, () => {
            console.log('Subcutaneous Injections: Injection Pen');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonSubcutaneousInjections">Subcutaneous
                                                            Injections</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonSubcutaneousInjections/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonSubcutaneousInjections/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonSubcutaneousInjections/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonSubcutaneousInjections/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What to look for after an
                                                    Injection</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>After giving an injection there are signs to look for:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Normally the skin at the injection site becomes slightly
                                                        reddened from the needle insertion.
                                                    </li>
                                                    <li>Allow 15 to 20 minutes after you give an injection, then ask the
                                                        person how he or she feels. This allows you to be on the alert
                                                        for side effects.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Side Effects</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Every medicine can cause side effects. A side effect is a secondary
                                                    effect of a medicine, different from the reason you give the
                                                    medicine. Know what the side effects are for the medicine you give
                                                    so that you can take the right action.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Insulin</p>
                                                <p>When taking Insulin or other injectable medicines that affect the
                                                    blood sugar levels such as Exenatide (Byetta&trade;), Pramlintide
                                                    (Symlin&trade;), there is a risk for low blood sugar (hypoglycemia).
                                                    This is less likely to occur if you give the correct amount of
                                                    medicine and if the person eats normal meals.</p>
                                                <p>Signs of <span className="bold">Hypoglycemia</span> include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Hunger</li>
                                                    <li>Nervousness and shakiness</li>
                                                    <li>Perspiration</li>
                                                    <li>Dizziness or light-headedness</li>
                                                    <li>Sleepiness</li>
                                                    <li>Confusion</li>
                                                    <li>Difficulty speaking</li>
                                                    <li>Feeling anxious or weak</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Byetta&trade;</p>
                                                <p>A person who takes Byetta can also <span className="bold">develop low blood sugar (hypoglycemia) if it is used with other diabetes medicines</span>.
                                                </p>
                                                <p>Other side effects include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Nausea</li>
                                                    <li>Vomiting</li>
                                                    <li>Diarrhea</li>
                                                    <li>Headache</li>
                                                    <li>Feeling jittery</li>
                                                    <li>Acid stomach</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Symlin&trade;</p>
                                                <p>A person who takes Symlin can also <span className="bold">develop low blood sugar (hypoglycemia) if it is used with other diabetes medicines</span>.
                                                </p>
                                                <p>Other side effects include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Nausea (sick to stomach)</li>
                                                    <li>Decreased appetite</li>
                                                    <li>Vomiting</li>
                                                    <li>Stomach pain</li>
                                                    <li>Tiredness</li>
                                                    <li>Dizziness</li>
                                                    <li>Indigestion</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Enoxaparin</p>
                                                <p>A person who takes heparin or low molecular weight heparin is at risk
                                                    for bleeding problems.</p>
                                                <p>These might include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>New or unusual bruising from the gums, cuts or wounds</li>
                                                    <li>Excess nose bleed</li>
                                                    <li>Black or bloody stools</li>
                                                    <li>Blood in the urine</li>
                                                    <li>Swollen ankle or feet</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Forteo&trade;</p>
                                                <p>A person who takes Forteo for osteoporosis can have these side
                                                    effects:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Dizziness</li>
                                                    <li>Leg cramps</li>
                                                    <li>Fast heartbeat</li>
                                                    <li>Nausea (sick to stomach)</li>
                                                    <li>Vomiting</li>
                                                    <li>Constipation</li>
                                                    <li>Low energy</li>
                                                    <li>Muscle weakness</li>
                                                    <li>Indigestion</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn"><i
                                                    className="fa fa-play redColor"></i>&nbsp;What to do if Side Effects
                                                    Occur</p>
                                                <p>If a person develops side effects for the first time, call their
                                                    doctor. In most cases the person will continue the same medicine, if
                                                    side effects are mild. Mild side effects will usually go away as the
                                                    body becomes use to the medicine.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder orangeBorder position-relative">
                                        <div className="col-12 goldColorBoxHeader">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower whiteColor">What to do for a low
                                                    blood sugar</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center marginTop40">
                                            <div className="w-100">
                                                <p className="text-center w-100">Give the person 10 to 15 grams of
                                                    carbohydrate, which equals one of the following:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>3-4 Glucose tablets or glucose gel (read package label for
                                                        amount)
                                                    </li>
                                                    <li>1/2 Cup of fruit juice</li>
                                                    <li>1/2 cup of regular soft drink (non-diet)</li>
                                                    <li>8 ounces of skim milk</li>
                                                    <li>6 to 10 hard candies</li>
                                                    <li>4 teaspoons or 4 cubes of sugar</li>
                                                    <li>6 saltine crackers</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If a diabetic shows signs of low blood sugar have the person sit down
                                                    so as not to fall down. If the person is fully awake and alert, have
                                                    him or her check their blood sugar.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead26px"><i
                                                    className="fa fa-exclamation-circle coralColorMargin"></i>&nbsp;If
                                                    you find a person with diabetes unconscious or not responding to
                                                    you, check their blood sugar and call 911.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Sometimes the doctor will recommend you learn how to give a glucagon
                                                    injection. There are Glucagon ER kits available that require mixing
                                                    of the powdered medicine with a solution. Your home health nurse
                                                    will teach you when and how to use the Glucagon if it is appropriate
                                                    for the person you care for.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive w-100"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_8.png" alt=""/>
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p className="captionBold">Avoid spreading germs when you give an
                                                        injection.</p>
                                                    <ul className="bulletsCustom orangeBullets">
                                                        <li>Always wash your hands using soap and water or a hand
                                                            disinfectant. Wash your hands together in lather for 15
                                                            seconds before rinsing. Do this to remove any germs from
                                                            your skin.
                                                        </li>
                                                        <li>Before you give an injection, cleanse the injection site
                                                            well. If the skin is dirty, wash it off with soap and water.
                                                            Then use an alcohol swab to clean the site just before you
                                                            give the injection. Place the swab at the center of the site
                                                            and use a firm circular motion, swabbing from the center
                                                            out. Be sure to let the alcohol dry completely before
                                                            injecting.
                                                        </li>
                                                        <li>Never touch the needle of the syringe against any surface.
                                                            Keep the needle capped until you are ready to give the
                                                            injection. If there is any question that the needle was
                                                            touched, throw it and the syringe away and start over with a
                                                            new syringe and needle.
                                                        </li>
                                                        <li>Use a hard plastic or metal, leak-proof container with a
                                                            tight fitting lid to throw away syringes and needles. Do NOT
                                                            put this container in the recycling bin. Most communities
                                                            have specific disposal laws. There are also syringe buy back
                                                            programs at some pharmacies and hospitals. Check with your
                                                            local health department for specific disposal instructions
                                                            in your community.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive w-100"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_9.png" alt=""/>
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p className="captionBold">Give the right amount of medicine</p>
                                                    <ul className="bulletsCustom orangeBullets">
                                                        <li>Be sure to give the correct amount of medicine, especially
                                                            if you have to mix medicines. Double check the amount before
                                                            giving the injection.
                                                        </li>
                                                        <li>Always buy the same type of syringe. Syringes hold different
                                                            amounts, have different needle sizes, and are marked in
                                                            different measures. There are specific syringes that are
                                                            only used for insulin. An insulin syringe is marked in units
                                                            instead of milliliters.
                                                        </li>
                                                        <li>Feel comfortable using the same syringe.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="videoFlexContainer">
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="43hH1z36UN0" opts={opts} onPlay={playVideo}/>
                                                    </div>
                                                </div>
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="dehLerH5x5Q" opts={opts} onPlay={playVideo2}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="videoFlexContainer">
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="KshjdjRVVr0" opts={opts} onPlay={playVideo3}/>
                                                    </div>
                                                </div>
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="wqq8iOzTo3c" opts={opts} onPlay={playVideo4}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="videoFlexContainer">
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="ty6H5JgPKng" opts={opts} onPlay={playVideo5}/>
                                                    </div>
                                                </div>
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="Of4E6jjw39M" opts={opts} onPlay={playVideo6}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-10  col-12 imgCol noPaddingLeft">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="" target="_blank">Open File<i
                                                    className="fa fa-chevron-right">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Giving a Subcutaneous
                                                    Injection:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Syringe with pre-attached needle (Use the needle size and
                                                        length prescribed by the doctor)
                                                    </li>
                                                    <li>Alcohol swabs</li>
                                                    <li>Vial(s) of medicine</li>
                                                    <li>Plastic disposal container or sharps box which you can buy
                                                        at a local drug store or use a sealed coffee can or
                                                        leak-proof, plastic detergent bottle. Check the trash
                                                        services where you live for proper disposal of used syringes
                                                        and needles.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Using an Injection Pen:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_10.png"
                                                     className="img-responsive floatNone"/>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Injection pen with new needle</li>
                                                    <li>Alcohol swab</li>
                                                    <li>Plastic disposal container or sharps box to dispose of
                                                        needle
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonSubcutaneousInjectionsWhatToDoAndSafety;
