import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonPulseFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonPulse">Taking a Pulse</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonPulse/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonPulse/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What is a normal resting heart or pulse rate?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Normally the heart beats at a rate of about 70 to 80 beats per minute at rest. However, the heart rate can range from 60 to 100 beats a minute.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">When is the best time to measure a pulse?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The best time is in the morning after the person has just awakened from sleep. Take a resting pulse or heart rate after a person has been at rest for at least 15 minutes.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Is my pulse rate the same as my heart rate?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The pulse rate is usually the same as the heart rate. There are conditions that may cause the pulse rate to be different, such as an ineffective pumping of the heart that does not allow the arteries to expand enough for you to feel anything. These conditions are watched carefully and you would not be able to take a pulse rate like you have learned in this lesson.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What if I cannot feel a pulse?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>This is not uncommon. You might not be able to locate the artery or you might be pressing too hard over the artery. Too much pressure closes off the artery. Lighten your pressure and try again. Sometimes, the strength of the pulse is weak and cannot be felt very easily. If you are unsure and continue to have trouble finding the pulse, call the doctor.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">When should I check a pulse using the carotid artery?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Most people check a carotid artery pulse when they exercise because the artery is easy to locate. <span className="bold">Never massage or check both sides of the neck at the same time</span> when trying to check a carotid pulse. This can cause an abnormal slowing of the heart rate.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="navTopicButtonPageHref">
                                    <a id="resources">RESOURCES</a>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AHA_Full.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American Heart Association</h2>
                                                <p>The American Heart Association is a national voluntary health agency whose mission is: “Building healthier lives, free of cardiovascular diseases and stroke.” The agency provides credible heart disease and stroke information for effective prevention and treatment.</p>
                                                <p className="resourceLink">visit: <a href="https://www.heart.org/" target="_blank">www.heart.org</a>
                                                </p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">Medline Plus</h2>
                                                <p>This resource will direct you to information to help answer health questions. MedlinePlus brings together authoritative information from the National Library of Medicine, the National Institutes of Health (NIH), and other government agencies and health-related organizations.</p>
                                                <p className="resourceLink">visit: <a href="https://medlineplus.gov/" target="_blank">www.nlm.nih.gov/medlineplus</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonPulseFAQResources;