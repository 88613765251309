import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter6_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/0d445c7f791eb836ce7a12e72e3003b1.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FA4E36",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Weight Loss & Nutrition
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L12A Head, Lead In Photo Larger with body copy */}
                  <div className="lego l012A">
                    <div className="contentContainer">
                      <div
                        className="imgContainer"
                        style={{ minWidth: "500px" }}
                      >
                        <img
                          className=""
                          alt="Adults of Mulitple Ethnicities doing Tai Chi"
                          src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/09f122dce6580fd5676488e38a3cc11b.jpeg"
                        />
                      </div>
                      <div className="copyContainer">
                        {/* L14 Paragraph */}
                        <div className="lego l00paragraph legoMargin3">
                          <p className="legoMargin2">
                            <span className="bold italic">
                              If you need to lose weight in order to improve
                              your health, the Washington University Living Well
                              Center® can help. 
                            </span>
                          </p>
                          <p className="legoMargin2">
                            We offer dietitian-led, weight loss shared medical appointments that are expertly
                            designed to help you drop excess weight using the
                            latest evidence-based approach that focuses on how
                            to expand your healthy food intake, and provide
                            education as to what foods to limit and why. The
                            program includes the support and resources you need
                            to meet your goal.
                          </p>
                          <p className="legoMargin2">
                            {" "}
                            By using a whole-food, plant-predominant lifestyle, you
                            will reduce your weight and the risk of chronic
                            diseases (such as diabetes and hypertension)
                            associated with excess weight and obesity.{" "}
                          </p>
                          <p className="legoMargin2">
                            Participants receive guidance from a dietitian who
                            specializes in plant-based diet and healthy
                            lifestyles, as well as social support from peers in
                            a group setting.
                          </p>{" "}
                          <p className="legoMargin2">
                            The education you receive will go beyond just food
                            and will include comprehensive lifestyle medicine –
                            a recipe for long-term success. We give you tools to
                            meet your weight loss goals (our methods are proven
                            to result in greater weight loss) and maintain your
                            success long-term. Four biweekly group sessions are
                            delivered virtually and in-person, increasing safe
                            access to patients everywhere.
                          </p>
                          <p>
                            Click to{" "}
                            <a className="blueLinkfont18"
                              href="https://www.ortho.wustl.edu/content/Patient-Care/8357/Services/Living-Well-Center/Living-Well-Center-Weight-Loss-Program.aspx"
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              Learn More
                            </a>
                          </p>
                        </div>
                        {/* End L14 Paragraph */}
                      </div>
                    </div>
                  </div>
                  {/* End L12A Head, Lead In Photo Larger with body copy */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter6_2;
