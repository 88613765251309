import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { useNavigate } from 'react-router-dom';

const BooneHealthLandingPage = () => {
	const encString = "f25c719ad8685c7fb9393e5c6a8c5b14:daa6842f64c327ab5cc1c88f960a52e16a6c8162f700fe6638f4178b498b02b31588d4473363b8ca1eef2fbba2226e9418e55005dbec4dda23969b7f5da1dd4326347fa1fc9e6c180c50544c794dec2981c39cc56de23a8af68e7db94a2731680a6943e0272feafdeb25883219098f2ed1311b9ee3bc8d7e97f1c12b2550c76eb0645345d92ca164063790b48c2578b6";

	const opts = {
		height: '390',
		width: '640',
		playerVars: {
			autoplay: 0
		}
	};
	let navigate = useNavigate();
	const routeChange = () => {
		let path = `/register/qr/${encString}`;
		navigate(path);
	};

	if (window?.location.pathname === '/boone-health') {
		import('./boone-health.scss');
		require('./boone-health.scss');
	}
	return (
		<div>
			<div className="w-100">
				<Container className="landingPage">
					<div className="w-100">
						<div className="col-12 m-auto">
							<div className="whiteBackground floatLeft clearLeft">
								<section>
									<div className="landingPage">
										<div className="landingHero">
											<div className="col-12 landingHero unityPointLandingBackgroundImg">
												<div className="w-100 content-center text-center landingHeader">
													<div className="col-12">
														<div className="w-100 d-flex content-center">
															<img
																src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BooneHealth_rev.png"
																className="img-responsive centerImage w-100 maxWidth400"/>
														</div>
													</div>
													<h1 className="">Receive your <span className="italic">Getting Started Guide</span> today!
													</h1>
													<h4 className="">This easy-to-use guide walks you through the entire
														cardiac rehab experience</h4>
													<h4><span className="italic">— including what to expect, how to plan for rehab, and how to get the most benefit from what the Pritikin program offers!</span>
													</h4>
													<Nav.Link>
														<button onClick={routeChange} className="guideButton">Activate
															My
															Free Guide <i className="fa-solid fa-chevron-right"></i>
														</button>
													</Nav.Link>
												</div>
											</div>
										</div>
										<div className="twoColorbar">
											<div className="yellowBar">
												&nbsp;
											</div>
											<div className="greenBar">
												&nbsp;
											</div>
										</div>
										<div className="col-12 subheadAndBullets bulletsCenterContainer">
											<div className="w-100 bulletsCenterSection">
												<ul className="bulletsCustom checkBulletsSafeCare">
													<li>Learn from cardiac rehab experts and past patients</li>
													<li>Get easy-to-follow answers to your most common questions.</li>
													<li>Receive expert advice on all you need to plan for success.</li>
												</ul>
											</div>
										</div>
										<div className="grayBG">
											<div className="col-12 graySection">
												<div className="w-100 grayContent text-center alignRight">
													<p><i className="fa-regular fa-clock fa-icon "></i> <br/>The <span
														className="italic">Getting Started Guide</span> is your <br/>always-there
														source of information.</p>
												</div>
												<div className="w-100 grayContent text-center alignLeft">
													<p><i className="fa-solid fa-laptop fa-icon"></i> <i
														className="fa-solid fa-tablet-screen-button fa-icon"></i> <i
														className="fa-solid fa-mobile-screen fa-icon"></i> <br/>Available
														on
														your desktop, <br/>tablet and smartphone.</p>
												</div>
											</div>
										</div>
										<div className="col-12 logoRowContainer">
											<div className="w-100">
												<div className="col-12">
													<div className="w-100 d-flex content-center">
														<img
															src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/pritikin_blue.jpg"
															className="img-responsive centerImage w-100"/>
													</div>
													<div className="w-100 d-flex content-center logoRowContent">
														<p className="italic text-center"><span
															className="bold">Remember:</span> <br/> Actively
															participating in your cardiac rehab program may be just as
															essential to your long-term recovery as your medical
															procedure or medications.</p>
													</div>
												</div>
											</div>
										</div>
										<div className="imgColorBar">
											<div className="imgBar">
												<img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Welcome_RGB.jpg"
													 className="img-responsive w-100"/>
											</div>
											<div className="textBar">
												<div className="col-12 subheadAndBullets bulletsGreenContainer">
													<h3 className="colorContainerHeader">CARDIAC REHABILITATION IS
														FOCUSED ON HELPING YOU:</h3>
													<div className="w-100">
														<ul className="bulletsCustom checkBulletsSafeCare">
															<li>Live longer and reduce your chances of another heart
																event
															</li>
															<li>Increase your strength and endurance so you can get
																back to your favorite activities, like hobbies,
																sports, and sharing time with loved ones
															</li>
															<li>Lessen the physical and emotional effects of heart
																disease
															</li>
															<li>Improve your confidence and well-being</li>
															<li>Receive the support and guidance you need to commit
																to a new, healthier lifestyle
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12 subheadAndBullets greenSection">
											<div className="w-100 greenContent">
												<p>Studies have shown that participation in a cardiac rehab program can
													reduce the risk of death -- from any cause and from cardiac causes.
													Studies have also shown that cardiac rehab decreases hospital
													readmission rates.</p>
											</div>
										</div>
										<div className="col-12 subheadAndBullets footerSection">
											<div className="w-100">
												&nbsp;
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
export default BooneHealthLandingPage;
