import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {useEffectForVideoAccess, videoAccessLog} from "../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin2_1 = () => {

    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'How Cardiac Rehab Can Help'}, () => {
            console.log('How Cardiac Rehab Can Help');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorLightRed pritikinBorderLightRed">How
                                                Cardiac Rehab Helps You</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Having a serious heart event can be both emotionally and physically
                                                challenging, but committing
                                                to Pritikin's comprehensive cardiac rehab program is an important first
                                                step towards your
                                                recovery. In fact, actively participating in your cardiac rehab program
                                                may be just as essential to
                                                your long-term recovery as your medical procedure or medications.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100">The Three Pillars of
                                                the Pritikin Program</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Pritikin's comprehensive cardiac rehab program is based on three key
                                                pillars, which are simple
                                                yet powerful:</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="contentBlockHorizontal3Elements">
                                                <div className="contentBlock">
                                                    <div className="headerImageAndText pritikinBackgroundRedMedium">
                                                        <img
                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Pritikin_Icon_Exercise.svg"
                                                            className="" alt="Exercise Icon"/>
                                                        <p>Regular Exercise</p>
                                                    </div>
                                                    <div className="contentCopy">
                                                        <p>Improved cardiovascular endurance</p>
                                                        <p>Increased strength</p>
                                                        <p>Improved flexibility</p>
                                                        <p className="mb-0">Enhanced overall health</p>
                                                    </div>
                                                </div>
                                                <div className="contentBlock">
                                                    <div className="headerImageAndText pritikinBackgroundRedMedium">
                                                        <img
                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Pritikin_Icon_Nutrition.svg"
                                                            className="" alt="Nutrition Icon"/>
                                                        <p>Heart-Healthy Eating Plan</p>
                                                    </div>
                                                    <div className="contentCopy">
                                                        <p>Affordable and satisfying foods</p>
                                                        <p>Successful weight management</p>
                                                        <p>Label reading guidelines</p>
                                                        <p className="mb-0">Simplified cooking and dining out</p>
                                                    </div>
                                                </div>
                                                <div className="contentBlock">
                                                    <div className="headerImageAndText pritikinBackgroundRedMedium">
                                                        <img
                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Pritikin_Icon_Mindset.svg"
                                                            className="" alt="Mindset Icon"/>
                                                        <p>Healthy Mind-Set</p>
                                                    </div>
                                                    <div className="contentCopy">
                                                        <p>Stress and anxiety management</p>
                                                        <p>Communication skills improvement</p>
                                                        <p>Effective goal-setting</p>
                                                        <p className="mb-0">Smoking cessation</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>These simple yet powerful concepts come together to form the foundation
                                                of a healthy lifestyle
                                                because the foods you eat, the physical activity you do, and the healthy
                                                mind-set you
                                                possess are all intricately connected.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100">
                                        <div className="w-100 d-flex flex-row justify-content-center">
                                            <h3 className="subheadUpperAndLowerColorBar pritikinBackgroundRedOrange centered w-auto ml-auto mr-auto">And loved ones are encouraged to attend each ICR session for additional patient motivation and support.</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100">Discover how others
                                                have benefitted from Pritikin ICR</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="videoTitle width100 text-center text-dark">
                                               {/* Video Caption*/}
                                            </h3>
                                            {/*Start This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="qTZIWiNSdJ4" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                            {/*End This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“This has been life changing. It’s just opened my eyes to more possibilities. I feel better health-wise. This works. This class works and I’ve been
                                                telling people about it ever since I took the cardiac class that you need
                                                to do this!”</p>
                                            <p className="name">— Gail T., Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“I feel excited. I feel stronger. I feel like getting up every morning and going!”</p>
                                            <p className="name">— Randy N., Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“I’m thinking about the future. I’m not thinking about the past anymore. I know that I’m going to be healthier. I am healthier.”</p>
                                            <p className="name">— John S., Cardiac Rehab Patient</p>
                                        </div>
                                    </div>
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img*/}
                                    {/*                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                className="img-responsive width100"/>*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin2_1;