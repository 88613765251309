import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTTGSP6_0 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entLearningGalleryPurpleColor entLearningGalleryPurpleBorderColor">Learning
                                        Gallery</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>For your convenience, this page contains all the videos found throughout
                                        this guide that help patients and their family caregivers better
                                        understand, plan and work toward better outcomes.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLowerColorBar entLearningGalleryPurpleBackgroundColor w-100 ">Recently
                                        Watched
                                        Videos</h3>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight entLearningGalleryVideoRowBackgroundColor">
                                <div className="w-100">
                                    <div className="col-4 imgCol ">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol ">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol ">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLowerColorBar entLearningGalleryPurpleBackgroundColor">All
                                        Videos Found
                                        in This Guide</h3>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100">
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100">
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100">
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100">
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                    <div className="col-4 imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg" className="img-responsive w-100" />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 entLearningGalleryGrayBackgroundColor bold">Video
                                            Title
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entLearningGalleryPurpleColor" href="#">BACK TO TOP</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP6_0;