import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll4_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-1B">
                                        <div
                                            className="tile Height1RowTall t_025 t_025_3x1 width3Column sitemanDarkerBlueBackground">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div
                                                        className="col-4 imgColumn sitemanWoundCareTubesDrainsBackgroundImg">
                                                    </div>
                                                    <div className="col-8 typeColorColumn sitemanDarkerBlueBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center whiteColor">Wound Care,
                                                                    Tubes &amp; Drains</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-2">
                                        <div
                                            className="tile Height1RowTall width1Column t_009 sitemanLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Successful Recovery
                                                            ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-4-3">
                                        <div
                                            className="tile t_003 Height1RowTall width1Column sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0030_exclamation-mark-thin-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Signs to Watch For ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-4">
                                        <div
                                            className="tile t_003 Height1RowTall width1Column sitemanLightestBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0011_pain-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Managing Pain ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-4-5">
                                        <div
                                            className="tile Height1RowTall width1Column blueBackground t_009 sitemanDarkerBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderBox">
                                                        <h3 className="text-center whiteColor">Swallowing and Aspiration
                                                            ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile t_003 Height1RowTall width1Column sitemanLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0071_treatments-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Home Medications ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile t_003 Height1RowTall width1Column sitemanDarkerBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0031_FAQs-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">FAQs ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll4_0;