import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
const LessonInfectionFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInfection">Infection Control</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInfection/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInfection/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who is most likely to get an infection?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are several types of people who are more likely to get infections:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Infants</li>
                                            <li>Adults over the age of 65</li>
                                            <li>Persons with poor nutrition</li>
                                            <li>Persons who have chronic disease (e.g. diabetes, cancer, asthma, chronic heart or lung disease)</li>
                                            <li>Persons with open, healing wounds (e.g. a person recovering from surgery)</li>
                                            <li>Persons who have had a traumatic injury</li>
                                            <li>Persons who have been long-term smokers</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How long does a person need to wash their hands?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Take at least 15 seconds to soap and lather your hands (long enough to sing the happy birthday song twice). Be sure you clean all surfaces of your hands and fingers. Then rinse well. If using a hand sanitizer, cleanse all surfaces of the hands thoroughly and allow the sanitizer to dry completely.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">When caring for someone, when is the best time to wash the hands?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Before eating and preparing food</li>
                                            <li>Before you give care to a person</li>
                                            <li>Between any changes of gloves</li>
                                            <li>After you touch any body fluids like saliva, urine, or drainage from a wound</li>
                                            <li>After you finish caring for the person</li>
                                            <li>After playing with pets and small children</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">When I care for a family member, how do I know if something has germs on it?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Everything has germs on it.</li>
                                            <li>Anything that becomes soiled from body secretions or discharges, such as clothes or linens contains germs.</li>
                                            <li>Place soiled Kleenex and wound dressings in a plastic trash or grocery bag.</li>
                                            <li>Wash soiled sheets, towels, and wash clothes separately from your clothing; use hot water and chlorine bleach if possible.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What is a normal temperature?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The normal range of a person’s body temperature is 96.8º to 100.4º Fahrenheit, or 36º to 38º Centigrade. A single temperature reading does not indicate a fever. Repeated readings above 100.4 Fahrenheit (38° Centigrade) with an oral thermometer, or 101.4 degrees Fahrenheit (38.5° Centigrade) with a rectal or ear thermometer should be reported to the doctor.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>Most doctors will not treat an adult’s fever until <span className="bold italic">it is over 102.2 degrees Fahrenheit</span> (39° Centigrade). However, call the doctor for any <span className="bold italic">fever that lasts longer than 48 to 72 hours</span>, especially in older adults.</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/cdc_logo.jpg" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">Center for Disease Control and Prevention (CDC)</h2>
                                                <p>The CDC is a component of the U.S. government’s Department of Health and Human Services. It provides credible scientific information on a broad range of disease prevention and health promotion topics. Health and safety topics include diseases and conditions, healthy living, traveler’s health, disaster preparedness, and environmental health.</p>
                                                <p className="resourceLink">visit: <a href="https://www.cdc.gov" target="_blank">www.cdc.gov</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/APIC-Logo.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">Association for Professionals in Infection Control (APIC)</h2>
                                                <p>The professional organization for infection preventionists on a mission to improve health and patient safety by reducing risks of infection. The association’s 11,000 members include nurses, epidemiologists, doctors, microbiologists, clinical pathologists, laboratory technologists and public health practitioners.</p>
                                                <p className="resourceLink">visit: <a href="https://apic.org/" target="_blank">www.apic.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <h2 className="subheadUpperAndLower withBodyCopy">Local Medical Equipment Stores</h2>
                                                <p>Will have gloves and masks available for purchase.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInfectionFAQResources;