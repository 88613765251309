import React, {useEffect, useState, Fragment} from 'react';
import DOMPurify from 'dompurify';
import Container from 'react-bootstrap/Container';
import {tilePage as tilePageRetrieve} from '../../actions/tilePage.js';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {TILE_PAGE} from "../../actions/types.js";

const TilePage = (props) => {
    const {errorMessage, fileName} = useSelector(state => state.tilePage);
    const [contentArea, setContentArea] = useState('');
    const dispatch = useDispatch();
    const { id } = useParams();

    const scriptLoaded = () => {
        setContentArea(displayContent());
    }

    useEffect(async () => {
        const ca = await tilePageRetrieve({id}, TILE_PAGE, (data) => {
            const script = document.createElement("script");
            script.src = data.fileName;
            script.async = true;
            script.onload = () => scriptLoaded();

            document.body.appendChild(script);
        });
        await dispatch(ca);
        //get default orbit for logged in user
    }, []);

    return (
        <div id="contentArea" dangerouslySetInnerHTML={{__html: contentArea}}>
        </div>
    )
};

export default TilePage;
