import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTTL4_2_4 = () => {
    const opts = {
            height: '390',
            width: '640',
            playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Communicating: Speech and Swallow Recovery After a Laryngectomy'}, () => {
            console.log('Communicating: Speech and Swallow Recovery After a Laryngectomy');
        });
        await dispatch(pal);
    }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center sitemanENTLightBlueColor sitemanENTLightBlueBorder">Communicating</h1>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Speech and Swallow Recovery
                                        After a Laryngectomy
                                    </h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="D77iIGYdX9w" opts={opts} onPlay={playVideo}  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Following a total laryngectomy, you will not be able to produce voice. That’s because your larynx (voice box) is removed during surgery. However, there are several options for communication after laryngectomy.</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100">
                                    <p className="bold italic">The four most common methods:</p>
                                </div>
                            </div>
                            <div className="col-md-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li><span className="bold">Nonverbal communication</span></li>
                                        <li><span className="bold">Esophageal <span className="italic normalTextWeight">(EE-sof-ah-JEE-ahl)</span> voice</span></li>
                                        <li><span className="bold">Electrolarynx or artificial larynx</span></li>
                                        <li><span className="bold">Tracheoesophageal <span className="italic normalTextWeight">(TRAY-kee-oh-EE-sof-ah-JEE-ahI)</span> puncture <span className="italic normalTextWeight">(TEP)</span> voice</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBarIcon sitemanENTLightBlueBackground centered">Nonverbal communication</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>The first form of communication you’ll be using after your surgery</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100">
                                    <p className="bold italic">This includes:</p>
                                </div>
                            </div>
                            <div className="col-md-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom checkBulletsSafeCare">
                                        <li>Pencil and paper</li>
                                        <li>Dry erase boards (“boogie boards”)</li>
                                        <li>Picture boards (that allow you to quickly point at key objects, words or letters)</li>
                                        <li>Gestures</li>
                                        <li>Mouthing words</li>
                                        <li>Programs for smart phones and computers that speak what you type</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBarIcon sitemanENTLightBlueBackground centered">Esophageal voice</h3>
                                </div>
                            </div>

                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 responsiveFlexWrap">
                                    <div className="imgCol noPaddingLeft">
                                        <img className="maxWidth300 img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Esophageal+Speech.jpg" alt="" />
                                    </div>
                                    <div className="typeCol noPaddingLeft">
                                        <p className="boldLeadIn">Communicating with esophageal speech</p>
                                        <p>In esophageal speech, a small amount of air is swallowed and trapped in the throat. This vibrates the throat creating sound. This sound, like your voice before surgery, can be shaped in the mouth to create speech. Esophageal speech can be difficult to learn but, if mastered, is a hands-free, no-cost method of speaking with no required maintenance.</p>
                                        <p className="mobileMarginBottom30">You should talk to your healthcare team to see if this is a good option for you.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBarIcon sitemanENTLightBlueBackground centered">Electrolarynx (or artificial larynx)</h3>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 responsiveFlexWrap">
                                    <div className="imgCol noPaddingLeft">
                                        <img className="maxWidth300 img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Electrolarynx_speech.jpg" alt="" />
                                    </div>
                                    <div className="typeCol noPaddingLeft">
                                        <p className="boldLeadIn">Communicating with an electrolarynx</p>
                                        <p>An electrolarynx (EL) is a small, battery-operated device that creates sound. It is placed flat against the neck or cheek. A small straw adapter can also be used inside the mouth if skin on your neck or cheek is too sensitive or does not allow sound to pass. When the EL is placed in the best spot for you, sound is carried into the mouth to be shaped into speech.</p>
                                        <p className="mobileMarginBottom30">Electrolarynx speech may seem mechanical and takes some practice, but it is reliable and low-maintenance. Your care team will help you with how to best use an electrolarynx. The electrolarynx is typically covered by insurance.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBarIcon sitemanENTLightBlueBackground centered">Tracheoesophageal puncture (TEP) Speech</h3>
                                </div>
                            </div>
                            <div className="col-12 images2AcrossAndText paddingOnSides">
                                <div className="w-100">
                                    <div className="imageContainer">
                                        <div className="">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Post-laryngectomy.jpg" className="img-responsive maxWidth500" />
                                            <div className="imgCaption">
                                                <p className="captionItalic text-center w-100" style={{marginBottom: 26}}>Post-laryngectomy</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 images2AcrossAndText paddingOnSides">
                                <div className="w-100">
                                    <div className="imageContainer">
                                        <div className="">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Tracheoesophagea.jpg" className="img-responsive maxWidth500"  />
                                            <div className="imgCaption">
                                                <p className="captionItalic text-center w-100" style={{marginBottom: 36}}>Tracheoesophageal voice prosthesis</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="text-left floatNone bold">Tracheoesophageal (TE) voice is esophageal voice made easier by using lung air.</p>
                                    <p>A small hole or puncture is made in the common wall between the trachea and the esophagus. This puncture is visible inside the stoma and becomes a path to allow airflow into the esophagus. A small silicone one-way valve is placed in the puncture to keep it open. It also keeps food and liquid from flowing from the esophagus into the trachea. This one-way valve is called a tracheoesophageal prosthesis, or TEP.</p>
                                    <p className="italic">This can be done at the time of the laryngectomy or, in some cases, later on during recovery. Sometimes it is better to wait, especially in cases where previous radiation was given.</p>
                                    <p>To talk with TE voice, breathe in and gently cover the stoma, or press gently on the HME, which covers the stoma. This directs air through the TEP and into the esophagus, vibrating the tissue. This vibration is shaped by your mouth into speech.</p>
                                    <p><span className="italic bold">The TEP needs regular maintenance</span>, as the valve will begin to leak food and liquid from the esophagus into the trachea. To prevent leaking, regular cleaning with a “brush and flush” technique is necessary. The TEP will also need to be replaced. This is usually done in an outpatient clinic and does not require general anesthesia.</p>
                                    <p>Although a TEP is a great option for many people, it is not for everyone. Your healthcare provider will determine if a TEP is the best option for you.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTL4_2_4;
