import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
const LessonWanderingWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWandering">Wandering</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWandering/WhatToDoAndSafety#introduction#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWandering/WhatToDoAndSafety#introduction#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWandering/WhatToDoAndSafety#introduction#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWandering/WhatToDoAndSafety#introduction#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering6.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Observe their Activity</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Write down what the person was doing or what was happening just before their wandering activity started, increased, or decreased.</li>
                                            <li>Write down what you tried doing to stop the activity and what worked; what did not work?</li>
                                            <li>Note closely when the person has had a change in their life, such as a hospital stay or moving to a new home, as this often causes wandering.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>For those people who are confused and unable to tell you when they are feeling sick, changes in their wandering pattern may be the first sign that something is wrong.</p>
                                        <p>Keep a list of all of the person’s medicines. Write down the date when new medicines are started and any change in behavior that happens in the days and first weeks after that.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Try to Remove the Cause</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Because wandering can be caused or triggered by many things, there are a variety of ways you can try to reduce it:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Offer drink and food.</li>
                                            <li>Ask if the person feels too warm or cold. Adjust the room temperature or change their clothing as needed.</li>
                                            <li>Ask the person to help you with a simple task, such as:</li>
                                            <ul class="bulletsCustom chevronRightBulletsSafeCareIndent">
                                                <li>Placing napkins on the table</li>
                                                <li>Folding laundry</li>
                                                <li>Helping to open the mail</li>
                                            </ul>
                                            <li>Get the person involved in something they like or did in the past, such as reading the sports results or playing familiar music.</li>
                                            <li>Stop extra noise in the house or take the person to an area in the house that is less noisy.</li>
                                            <li>Keep things in the home that make it look familiar, like a favorite chair, pictures of loved ones, and pets.</li>
                                            <li>Put the person’s name on their bedroom door.</li>
                                            <li>Stick to the same routine with meal times, bathing, taking a walk, or bedtime.</li>
                                            <li>Have clocks and calendars out in the house. Slip cues about the time when you are talking together, such as: “The mail will be here soon. It’s almost 3 o’clock in the afternoon already.” “I am expecting Sue to be here in another hour, around 2 o’clock.”</li>
                                            <li>Reassure the person that someone else is taking care of their work, such as: “Your boss is not expecting you today.” “Jane knows where you are. Someone else is taking care of her today.” You will likely have to repeat explanations.</li>
                                            <li>Take the person for a drive or walk in the house, yard, neighborhood or shopping center. Do not leave them in a car alone. If he or she begins to wander away from you, walk a little way along with them and then gently guide them back in the right direction. Never force the person.</li>
                                            <li>Make sure your car keys are not available for the person to pick up. He or she could leave at night when no one would be watching.</li>
                                            <li>See more <a href="#safetyTips" target="_blank">safety tips</a> included later in this lesson.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Take Care of Physical Health Needs</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Comfort</p>
                                        <p>Ask if the person is having pain and look for any signs that he may be in pain. See our Lesson on <a href="/LessonPain" target="_blank">Recognizing Pain</a>.</p>
                                        <p>For example, if he or she lets you, check their mouth for sores and look at the throat for any redness. Does he or she stop chewing or grimace when eating? Are any of the joints swollen or does the skin have any sores or bruising? Is the person rubbing his head or any other body parts? Is he having any trouble urinating or having a bowel movement?</p>
                                        <p className="boldLeadIn">Time for Rest</p>
                                        <p>Make sure there are rest periods but not long naps during the day. Sleeping too much during the day can make day/night confusion and night wandering worse. Being overtired, on the other hand, can agitate a person and increase their restlessness.</p>
                                        <p className="boldLeadIn">Diet</p>
                                        <p>Weigh the person weekly, and write this down in your care diary. Give the person foods higher in calories and protein if they’re losing weight. Reading food labels and talking with your doctor or a dietician can help you make good food choices. If the person wanders away from meals, try finger foods that he or she can eat while walking.</p>
                                        <p className="boldLeadIn">Medicines</p>
                                        <p>Assist your loved one with their medicines to be sure he or she is taking them correctly and at the right time. Keep medicines out of reach and out of sight to stop them from taking more medicine than is prescribed. Monitor their medicine planner closely.</p>
                                        <p>See our Lesson on <a href="/LessonGivingMedicines" target="_blank">Giving Medicines Safely</a>.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering9.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Communicate with Others</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>It is important to let people know that the person you care for wanders. This means more people will be able to look out for the person and know that he or she might need help finding their home.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom">
                                            <li>Tell neighbors and your local police that a person with wandering behavior is living there.</li>
                                            <li>Put reflectors on their outdoor clothing. When buying new clothes, buy athletic shoes with reflectors and brightly colored outerwear.</li>
                                            <li>Sew in labels or use a permanent laundry marker to write in clothes the person’s name, phone number, and the words “memory impaired.”</li>
                                            <li>Place cards with important information about your loved one in their pockets, purses, and/or wallets.</li>
                                            <li>A variety of identification bracelets are available. Most pharmacies have Medic Alert ® bracelets. The National Alzheimer’s Association offers a “Safe Return” program with ID bracelets and live, 24-hour response to an 800 number on the bracelet. Make sure the bracelet fits well and cannot be removed.</li>
                                            <li>Wear a bracelet that identifies you as a caregiver for someone with memory problems. Matching bracelets may help the person with dementia feel better about wearing his.</li>
                                            <li>Have emergency phone numbers handy (e.g. doctor, poison control central, neighbors and relatives).</li>
                                            <li>If the person you care for has to be hospitalized, let the staff know about the pattern of wandering.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 images3AcrossAndText">
                                    <div className="w-100">
                                        <div className="imageContainer d-flex">
                                            <div className="leftCol col-4 paddingLeft7 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_safety1.png" className="img-responsive" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">&nbsp;&nbsp;
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="centerCol col-4 paddingLeft7 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_safety2.png" className="img-responsive" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                            <div className="rightCol col-4 paddingLeft7 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering10.png" className="img-responsive" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">&nbsp;&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Safety Proof the Home</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul class="bulletsCustom">
                                            <li>Disguise doors to the outside in some way, such as:</li>
                                            <ul class="bulletsCustom bulletsSafeCareIndent">
                                                <li>Paint them the same color as the walls.</li>
                                                <li>Hang a picture or full-length mirror on them. (Note, if a person does not recognize his or her reflection, they may become frightened and the mirror should be removed.)</li>
                                                <li>Cover them with curtains or movable screens.</li>
                                                <li>Place a stop sign on the door.</li>
                                            </ul>
                                            <li>Put medicines, poisons, matches, guns, and sharp objects in locked cabinets.</li>
                                            <li>Never lock the person in the home alone. Make sure smoke alarms are working and there is a fire extinguisher.</li>
                                            <li>Consider having a security alarm placed on doors so you know when the doors have been opened. A less expensive option is to hang bells on the door.</li>
                                            <li>Place safety gates across stairwells and in doorways that lead to unsafe areas.</li>
                                            <li>Add deadbolts or slide latches out of sight or reach. Door knob covers may make turning the knobs harder.</li>
                                            <li>Use a toddler monitor to help you hear activity in other rooms.</li>
                                            <li>A fenced-in backyard with locks on outside gates allows for safer roaming. Be aware of any poisonous plants and trip or water hazards. The person who wanders still must be watched.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight flex-column">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Learn suggestions for reducing wandering and for keeping the person you care for safe.</p>
                                    </div>
                                </div>
                                <div className="w-100 videoFlexContainer">
                                    <div className="imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <YouTube videoId="8Ccyt6eOi54" opts={opts} />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 text-center">Person Who Wanders
                                        </p>
                                    </div>
                                    <div className="imgCol noPaddingLeft">
                                        <div className="embedContainer">
                                            <YouTube videoId="WFA9-po8MYM" opts={opts} />
                                        </div>
                                        <p className="captionRegular videoCaptionBox w-100 text-center">Keeping a Person Who Wanders Safe
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File
                                                <i className="fa fa-chevron-right">&nbsp;</i>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 images2AcrossAndText paddingOnSides">
                                <div className="w-100">
                                    <div className="imageContainer">
                                        <div className="leftCol col-5 paddingRight7">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_equip1.png" className="img-responsive w-100" />
                                            <div className="imgCaption">
                                                <p className="captionBold">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="rightCol col-5 paddingLeft7">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_equip2.png" className="img-responsive maxWidth300" />
                                            <div className="imgCaption">
                                                <p className="captionBold">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul class="bulletsCustom">
                                        <li>Identification bracelet</li>
                                        <li>Care diary to take notes and keep important phone numbers at hand</li>
                                        <li>Photograph or video of the person who wanders</li>
                                        <li>Sewing labels or permanent laundry marker</li>
                                        <li>Safety gates</li>
                                        <li>Deadbolts or slide latches</li>
                                        <li>Security alarm (see photo) or bells that can be hung on exterior doors</li>
                                        <li>Cabinet locks</li>
                                        <li>Toddler monitor (see photo)</li>
                                        <li>Fire extinguisher</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    {/*//
                                    <hr className="w-100" />*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWanderingWhatToDoAndSafety;