import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGlucoseWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGlucose">Measuring Blood Glucose</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatYouNeedToKnow#bloodGlucose">
                                                    <p className="whiteColor">Blood Glucose <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatYouNeedToKnow#hypoglycemia">
                                                    <p className="whiteColor">Hypoglycemia <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatYouNeedToKnow#hypoglycemia2">
                                                    <p className="whiteColor">Hyperglycemia <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="bloodGlucose">Blood Glucose</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Who Should Measure Blood Sugar (Blood Glucose)?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The American Diabetes Association (ADA) suggests that anyone with diabetes benefits from checking their blood glucose regularly.</p>
                                                <p>Glucose testing should be done if a person is:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Taking insulin or diabetes pills</li>
                                                    <li>Pregnant, especially those at risk for gestational diabetes</li>
                                                    <li>In poor control of their blood glucose levels</li>
                                                    <li>Having low blood glucose levels</li>
                                                    <li>Having low blood glucose levels without the usual warning signs</li>
                                                    <li>Have ketones from high blood glucose levels</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Regular blood glucose checks allow people with diabetes to better manage their diet, exercise, and medicines, such as insulin and oral pills.</p>
                                                <p>The person’s doctor will recommend how often to check blood glucose levels.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is a Normal Blood Glucose?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A person’s blood glucose level varies during the day. A number of factors can raise or lower the blood glucose:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 twoSideBars">
                                            <div className="w-100">
                                                <div className="col-6 col-12">
                                                    <div className="w-100">
                                                        <div className="sidbarFill sidebarFillBackgroundColor marginRight">
                                                            <h2 className="subheadUpperAndLower withBodyCopy"><i className="fa fa-arrow-up"></i>&nbsp;
                                                                Raises blood glucose:</h2>
                                                            <ul className="bulletsCustom whiteBullets">
                                                                <li>The amount and type of foods a person eats increases glucose. Certain foods high in carbohydrates such as white bread, cereals, pastas, candy and most desserts raise blood glucose without offering many nutrients.</li>
                                                                <li>Stress, including illness and infection cause a person’s blood glucose to rise.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-12">
                                                    <div className="w-100">
                                                        <div className="sidbarFill sidebarFillBackgroundColor marginLeft">
                                                            <h2 className="subheadUpperAndLower withBodyCopy"><i className="fa fa-arrow-down"></i>&nbsp;
                                                                Lowers blood glucose:</h2>
                                                            <ul className="bulletsCustom whiteBullets">
                                                                <li>Exercise requires energy, so the blood glucose will lower depending on how much a person exercises.</li>
                                                                <li>The medicines for diabetes, including insulin and oral medicines lower the blood glucose.</li>
                                                                <li>In rare cases, stress can cause a person’s glucose level to fall.</li>
                                                                <li>Having low blood glucose levels</li>
                                                                <li>Having low blood glucose levels without the usual warning signs</li>
                                                                <li>Have ketones from high blood glucose levels</li>
                                                                <p>A person with diabetes needs to ask their doctor about what their blood glucose level should be. It will vary for each person. For a non-pregnant adult with diabetes, these are the desirable blood glucose ranges (American Diabetes Association, 2013):</p>
                                                                <li>Before a meal: 70 – 130 mg/dl (5.0–7.2 mmol/l)</li>
                                                                <li>1-2 hours after beginning a meal: less than 180 mg/dl (<i className="fa fa-chevron-right">&nbsp;</i>10.0 mmol/l)</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">The A1C Test</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The blood test A1C measures a person’s average blood glucose control for the past 2 to 3 months. It tells if their diabetes is in control and if their treatment plan is working. The American Diabetes Association (ADA) recommends an A1C test at least every 6 months, or more often if needed. It does not replace daily self-testing of blood glucose.</p>
                                                <p>According to the ADA (2013), the normal A1C level is below 7% (also reported as eAG:154 mg/dl).</p>
                                                <p>Talk with the person’s doctor about the target level that is right for him or her.</p>
                                                <p className="boldLeadIn">How does the A1C test help with diabetes control?</p>
                                                <p>The ADA (2013) reports that the A1C test helps manage diabetes by:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Confirming self-testing results or blood tests by the doctor.</li>
                                                    <li>Judging if a treatment plan (such as diet and amount of insulin) is working.</li>
                                                    <li>Showing you how healthy choices can help in diabetes control.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Estimated Average Glucose (eAG)</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>There is a new way to understand how well a person’s diabetes is in control. The estimated average glucose (eAG) uses the same units (mg/dl) as a blood glucose meter.</p>
                                                <p>The eAG is showing up on blood test reports, giving health care providers an easier way to talk with patients about their blood glucose control. The ADA offers an eAG Tool Kit to help patients better understand how their A1C relates to their daily diabetes management.</p>
                                                <p>See our <a href="safecare_9_Additional_Resources.html" target="_blank">Resources</a> section.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="hypoglycemia">HYPOGLYCEMIA</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When taking insulin or other diabetes medicines, there is a risk for a low blood glucose (hypoglycemia). Hypoglycemia can also occur if a person does not eat enough or if they have exercised. The level of exercise does not have to be at a high level. Sometimes simple housework or gardening can lead to hypoglycemia. It is less likely to occur if the person takes the correct amount of medicine and eats balanced meals, keeping in mind that snacks may be needed with exercise.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon">Signs of Hypoglycemia include:</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Low blood glucose level</li>
                                                    <li>Hunger</li>
                                                    <li>Nervousness and shakiness</li>
                                                    <li>Sweating</li>
                                                    <li>Clumsiness</li>
                                                    <li>Pale face</li>
                                                    <li>Dizziness or light-headedness</li>
                                                    <li>Sleepiness</li>
                                                    <li>Confusion</li>
                                                    <li>Difficulty speaking or slurred speech</li>
                                                    <li>Feeling anxious or weak</li>
                                                    <li>Heart palpitations</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What to Do for Hypoglycemia</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Most persons with diabetes know what to do if their blood sugar levels fall below or rise above their target. Each person has a blood glucose target set by their doctor.</p>
                                                <p>For <span className="bold">mild hypoglycemia</span> (the person may feel low, have no symptoms, and the blood glucose is around 60- 80 mg/dl) follow the rule of 15:</p>
                                                <p>Have the person eat or drink 15 grams of carbohydrate, which equals one of the following:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>3-4 Glucose tablets or glucose gel (read package label for amount)</li>
                                                    <li>½ Cup of fruit juice</li>
                                                    <li>½ cup of regular soft drink (non diet)</li>
                                                    <li>8 ounces of skim milk</li>
                                                    <li>6 to 10 hard candies</li>
                                                    <li>4 teaspoons or 4 cubes of sugar</li>
                                                    <li>6 saltine crackers</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Recheck the blood glucose in 15 minutes to make sure it is 70 mg/dl or above. If the blood glucose is still too low, have the person eat or drink another 15 grams of carbohydrate, and once again check the blood glucose after 15 minutes. Once the blood glucose returns to target level, the person should feel better.</p>
                                                <p>If a diabetic shows signs of low blood sugar, have him or her sit down so that they do not fall down. If the person is fully awake and alert, have them check their blood sugar, or check the blood sugar for them.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If you find a person with diabetes unconscious or not responding:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead16px"><i className="fa fa-exclamation-circle coralColorMargin" ></i>&nbsp;Call 911 or a local emergency department.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li><span className="bold">Do not</span> give the person liquids or food.</li>
                                                    <li>Roll or place the person on their side in case they begin to vomit. You do not want the vomit to enter their lungs when they breathe.</li>
                                                    <li>Stay by the person’s side until emergency medical responders arrive.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="hypoglycemia2">HYPERGLYCEMIA</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Hyperglycemia is a high blood sugar level. It develops when the body has too little insulin or is not able to use the insulin it has. A number of other factors cause hyperglycemia:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Not taking enough insulin or diabetes medicine</li>
                                                    <li>Eating more than planned</li>
                                                    <li>Exercising less than planned</li>
                                                    <li>Stress from an illness or daily demands</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon">Signs of Hyperglycemia include:</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>High blood glucose level</li>
                                                    <li>Frequent passing of urine</li>
                                                    <li>Increased thirst</li>
                                                    <li>Difficulty concentrating</li>
                                                    <li>Blurred vision</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What to Do for Hyperglycemia</h2>
                                                <p>When a person’s blood sugar is over 200 mg/dl, call their doctor or home health nurse. Also, be sure the person is taking the prescribed amount of insulin or other diabetes medicines.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGlucoseWhatYouNeedToKnow;
