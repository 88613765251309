import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonOxygenWhatYouNeedToKnow = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center width400">
                                                <p>
                                                    <Nav.Link className="" href="/LessonOxygen">Giving Oxygen</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What To Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatYouNeedToKnow#whatToKnow">
                                        <p className="whiteColor">What to Know <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatYouNeedToKnow#oxygenTherapy">
                                        <p className="whiteColor">Oxygen Therapy <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatYouNeedToKnow#dangers">
                                        <p className="whiteColor">Dangers <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonOxygen/WhatYouNeedToKnow#delivery">
                                        <p className="whiteColor">Delivery Systems <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToKnow">WHAT TO KNOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Human Respiratory System</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/respiratory_system_illustration.jpg" className="img-responsive  floatNone" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How We Breathe</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Normally when we inhale or breathe in, air moves in and out of our <strong>lungs</strong> freely and easily. As we breathe in, the oxygen in the air travels through very tiny airways in the lungs to small air sacs called <strong>alveoli</strong>. There, oxygen travels across the membranes of the alveoli into the pulmonary artery where oxygen attaches to red blood cells. The cells circulate to deliver oxygen to all tissues of the body.</p>
                                        <p>After the cells of the body use oxygen, carbon dioxide forms. This gas then travels through the circulation to the alveoli of the lungs. The carbon dioxide moves by way of the pulmonary veins across the membranes of the alveoli into the small airways of the lungs, where the gas exits the body as we exhale or breathe out.</p>
                                        <p>For each of us to live, we must breathe in oxygen and breathe out carbon dioxide. The body regulates this very nicely, keeping normal levels of oxygen and carbon dioxide in the blood. Normally, we breathe without thinking.</p>
                                        <p className="bold"><i className="fa-solid fa-droplet redColor"></i>&nbsp;&nbsp;The rate at which we breathe depends primarily on the amount of carbon dioxide circulating in our blood.</p>
                                        <p>When carbon dioxide levels rise, this causes us to breathe faster. When we breathe faster, more oxygen enters our lungs and we exhale or breathe out to remove the extra carbon dioxide.</p>
                                        <p>The body protects itself if oxygen levels become too low. Low oxygen will cause a person to breathe faster and to inhale more oxygen through the lungs.</p>
                                        <p>Breathing is automatic, but when we exercise hard, catch a bad cold, or simply want to take a nice deep breath, we will consciously breathe more often or more deeply to get more oxygen to our cells.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="oxygenTherapy">OXYGEN THERAPY</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Who Needs Oxygen Therapy?</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A person receives oxygen to prevent or treat hypoxemia (low blood oxygen). With hypoxemia, there is not enough oxygen to meet the needs of the body’s tissues and cells.</p>
                                            <p>In order to function properly, your body needs a constant level of oxygen circulating in the blood to cells and tissues. When this level of oxygen falls below a certain amount, hypoxemia occurs.</p>
                                            <p>Various diseases cause hypoxemia, requiring the temporary or long-term use of oxygen.</p>
                                            <p className="boldLeadIn">Acute Hypoxemia (from short-term oxygen use)</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Pneumonia – inflammation of the lungs from infection</li>
                                                <li>Bronchiolitis - viral infection of lower airways, common in infants</li>
                                                <li>Short-term worsening of chronic obstructive lung disease</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Chronic Hypoxemia (from long-term oxygen use)</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Chronic obstructive lung disease (bronchitis, emphysema, asthma)</li>
                                                <li>Cystic fibrosis</li>
                                                <li>Bronchiectasis (born with condition) or Acquired Bronchietasis (Adult age)</li>
                                                <li>Widespread lung tumor</li>
                                                <li>Pulmonary hypertension</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A doctor decides if a person has hypoxemia by testing a blood sample taken from an artery. Another way is to use a pulse oximeter – a small device that clips on a finger. A pulse oximeter measures the oxygen saturation of your blood, which estimates blood oxygen levels.</p>
                                            <p>Normal pulse oximeter readings range from 95 to 100 % in persons who do not have lung disease. Values under 90 % are considered low. A person who requires oxygen will learn what their normal oxygen saturation is. Oxygen therapy will be ordered to keep the person’s oxygen saturation at a healthy level.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="dangers">DANGERS OF OXYGEN THERAPY</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Oxygen Toxicity</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Oxygen is a medicine that can cause serious effects if not given correctly. For some people, there is a delicate balance in how much oxygen they can receive.</p>
                                        <p>Patients with <strong>Chronic Obstructive Lung Disease (COLD)</strong> have limited airflow. This means that air gets trapped in the small alveoli (air sacs) of the lung as a result of mucous buildup or narrowing of the small airways.</p>
                                        <p>When a person with COLD breathes in, the airways pull open to allow gas to flow past any obstruction. But, when the person exhales, the airways collapse and the air (including carbon dioxide) cannot get out.</p>
                                        <p className="bold"><i className="fa-solid fa-circle-exclamation redColor"></i>&nbsp;&nbsp;The rate at which we breathe depends primarily on the amount of carbon dioxide circulating in our blood.</p>
                                        <p>When these diseases progress, a person holds more carbon dioxide in the blood than normal. Thus, the body is no longer able to sense small changes in carbon dioxide levels. A high level of carbon dioxide is no longer the trigger for breathing. <strong>In people with Chronic Obstructive Pulmonary Disease (COPD), small changes in oxygen become the trigger for breathing.</strong> So, if a person is given too high levels of oxygen, the stimulus to breathe disappears. In these individuals, giving too much oxygen can be deadly as a result of oxygen toxicity.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Oxygen Fire</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold"><i className="fa-solid fa-fire-flame-curved redColor"></i>&nbsp;&nbsp;Oxygen is very combustible!</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Store and deliver oxygen in a well-ventilated area.</li>
                                            <li>Keep all oxygen equipment a minimum of 8 feet from any open flames.</li>
                                            <li>Do not use any electrical appliances that may cause sparks.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="delivery">OXYGEN DELIVERY AND SYSTEMS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person receives oxygen either by continous-flow or pulse-dose.</p>
                                        <p>Traditional continuous-flow oxygen works the way the name suggests. It is delivered through a device as the patient breathes in and breathes out. It runs continuously.</p>
                                        <p>In pulse-dosed flow, oxygen is delivered in a “dose” only as the patient breathes in. Pulse-dose technology allows oxygen delivery systems to save oxygen. As a result, a person breathes in less total oxygen, without being at risk for hypoxia. This allows relatively small portable oxygen systems to be more efficient and last longer.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Oxygen Delivery Systems</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Long term oxygen therapy is delivered by one or a combination of three delivery systems:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ol>
                                            <li>Oxygen Concentrator</li>
                                            <li>Compressed Gas Cylinder</li>
                                            <li>Liquid Oxygen System</li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Each of the systems has pros and cons for operation, function, and cost. A person’s choice depends on how much and when oxygen is needed and the level of daily activity.</p>
                                        <p>Cost or insurance restrictions may also affect choice. The person’s doctor, home health nurse, and home care provider of oxygen will select the system that best suits the person’s needs.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Oxygen Concentrators</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Concentrators are the most common delivery system used and most economical.</li>
                                            <li>The system needs electricity and may increase a person’s home electric bill.</li>
                                            <li>An electrical generator or a backup compressed oxygen cylinder is needed in case of a power failure.</li>
                                            <li>Tell the electric company if the person for whom you provide care has an oxygen concentrator.</li>
                                            <li>Portable concentrators are available, each weighing about 10 pounds.</li>
                                            <li>This system is cost-effective for people requiring low-flow continuous oxygen and those with limited mobility.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Compressed Oxygen</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Tanks can be large (stationary), medium (portable), or small (ambulatory).</li>
                                            <li>The cylinders are fitted with a flow meter and a regulator and are made of steel or aluminum.</li>
                                            <li>The aluminum cylinders are easier to manage because they weigh less than the steel tanks.</li>
                                            <li>Once a cylinder is empty, the home oxygen provider must refill the cylinder.</li>
                                            <li>Stationary tanks are quite large and only moved by the provider during delivery.</li>
                                            <li>Although portable tanks are easier to move, each weighs more than 10 pounds and is not designed to be carried. A portable tank usually lasts for about 5 hours.</li>
                                            <li>Ambulatory cylinders weigh less than 10 pounds and can be carried easily. An ambulatory tank delivers oxygen for at least 4 hours. People typically use this system for intermittent therapy, such as for exercise or during travel.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                    <div className="w-100">
                                        <div className="imageContainer">
                                            <div className="leftCol col-5 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/oxygen_concentrator.png" className="img-responsive w-100" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">Oxygen Concentrator</p>
                                                </div>
                                            </div>
                                            <div className="rightCol col-5 paddingLeft7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/compressed_oxygen_tank.png" className="img-responsive maxWidth300" />
                                                <div className="imgCaption">
                                                    <p className="captionItalic">Compressed Gas Cylinder</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Oxygen Delivery Devices</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Home oxygen therapy is usually given through a nasal cannula or a face mask. The person's doctor will choose the best method to suit the person's needs.</p>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight ">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Nasal_Cannula_image.png" alt="" />
                                            <div className="imgCaption">
                                                <p className="captionItalic">Nasal Cannula</p>
                                            </div>
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p className="caption mobileMarginBottom30"><strong>Nasal Cannula</strong><br /><br />
                                                The easiest, least expensive, most common, and most comfortable device for receiving oxygen.<br /><br />
                                                A continuous-flow cannula is a plastic tubing that has two prongs or tips, each about ½ inch long that fit into each nostril of the nose. Place the prongs in the person’s nostrils and then slip the cannula around the person’s ears to make a lanyard under the person’s chin. The lanyard adjusts to a snug fit, keeping the prongs or tips in place. Oxygen flows through the cannula at a low-flow rate with or without humidity.<br /><br />
                                                A reservoir cannula works by storing oxygen in a small chamber as a person breathes out. When the person is ready to inhale, they get the stored oxygen. A person will be able to use a lower oxygen flow setting (compared with a continuous-flow) while still receiving the same amount of oxygen.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Oxygen_face_mask.png" alt="" />
                                            <div className="imgCaption">
                                                <p className="captionItalic">Oxygen Face Mask</p>
                                            </div>
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p className="caption mobileMarginBottom30"><strong>Oxygen Masks</strong><br /><br />
                                                There are several types of masks; each delivers a different concentration (percentage) of oxygen with or without humidity.<br /><br />
                                                <strong>Simple face mask</strong> – delivers oxygen for short-term therapy. It fits loosely around the face and delivers oxygen usually in a higher concentration than a nasal cannula.<br /><br />
                                                <strong>Face mask with reservoir (rebreather or non-rebreather)</strong> – delivers higher concentrations of oxygen. The bag must be inflated to deliver the higher oxygen percentage. (A non-rebreather looks similar to a rebreather mask, but it has a special valve which prevents air from going back into the bag during inhaling or exhaling.)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p><strong>Venturi mask</strong>– Delivers a more accurate concentration of oxygen than a re-breathing mask. The venturi mask is cone shaped and has special ports at the base of the mask to adjust higher concentrations of oxygen.</p>
                                        <p><strong>Oxygen Through Tracheostomy</strong> – A person who has an artificial opening in their neck into the trachea (tracheostomy) receives oxygen through a tracheostomy collar or a T-Tube.</p>
                                        <p><strong>T-Tube</strong> – A T-shaped device that connects an oxygen tubing to the tracheostomy. It is used more commonly if a person is on a home ventilator.</p>
                                        <p><strong>Tracheostomy collar</strong> – A curved device with an adjustable strap that fits around a person’s neck and over the tracheostomy opening. The collar has two ports, an exhalation port that must remain open at all times and the port that connects to the oxygen container.</p>
                                        <p><strong>Transtracheal oxygen</strong> – Oxygen flows through a thin tube inserted through the neck into the trachea (windpipe). A person is able to talk with the tube in place.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonOxygenWhatYouNeedToKnow;