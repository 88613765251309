import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog.js";
const SitemanENTTGSP2_3_2 = () => {

const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};

const dispatch = useDispatch();
const playVideo = async (e) => {
    const pal = await videoAccessLog({videoName: 'Skin Cancer Removal: Scalp Cancer Surgery and Reconstruction'}, () => {
        console.log('Skin Cancer Removal: Scalp Cancer Surgery and Reconstruction');
    });
    await dispatch(pal);
}

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entBaseRedColor">Your
                                        Cancer Removal/Day of Surgery</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBarIcon darkOrangeBackground centered">SKIN CANCER REMOVAL</h3>
                                </div>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer boxes">
                                        <div className="typeContainer text-center entNavBaseYellowBackground">
                                            <a href="#cancerRemoval">
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft smaller">ABOUT
                                                        YOUR</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft">CANCER
                                                        REMOVAL</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft clickHere">continue
                                                        scrolling</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="typeContainer text-center entNavBaseBlueBackground">
                                            <a href="#dayOfSurgery">
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft smaller">GO
                                                        TO</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft">DAY OF
                                                        SURGERY</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft clickHere">click
                                                        here ></p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Scalp Cancer Surgery and Reconstruction</h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="l2uXBBJEQoY" opts={opts} onPlay={playVideo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>The recommended treatment for most advanced skin cancers – including the scalp and face – is surgery. This requires removing the cancer with a cuff of normal tissue (or margin) around the cancer. When skin cancers get large, they may invade into deeper structures like nerves, blood vessels or bone. If this occurs, these tissues may need to be removed as well during your surgery.</p>
                                    <p>Removal of some large skin cancers or those that invade into surrounding tissues will leave a large wound that needs to be repaired. A free flap is often needed for reconstruction (<em><strong>see</strong></em> <a href="/siteman-ent-all-2-4">Your Reconstruction</a>). This reconstruction is usually performed at the same time as the cancer removal. Your surgeon will discuss your particular surgery with you in more detail.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 flexContainer flexRow spaceEvenlyWithPadding">
                                    <div className="imageContainer  flexContainer justifyCenter">
                                        <img className="maxWidth450" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/scalp_photo_2.png" alt="Healed scalp reconstruction" />
                                    </div>
                                    <div className="imageContainer flexContainer justifyCenter">
                                        <img className="maxWidth450" alt="Healed scalp reconstruction" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/scalp_photo_1.png"  />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="imgCaption">
                                        <p className="captionItalic centered "><strong>Examples of healed scalp reconstruction with free flaps</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                           <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">Removal of lymph nodes (neck dissection)</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Lymph nodes (sometimes referred to as glands) are small bean-shaped structures that are part of your immune system. Lymph nodes filter your blood and help you fight off infections. You have many lymph nodes in your head and neck. Sometimes cancers can spread to lymph nodes.</p>
                                    <p> Based on your type of cancer, your surgeon may recommend removal of certain lymph nodes in your neck, which is called a <strong>neck dissection</strong>. This would be performed at the same time as your cancer removal surgery.
                                    </p>
                                    <p>During a neck dissection, great care is taken to safely remove the necessary lymph nodes from around important nerves and blood vessels. Even though these lymph nodes are removed, your immune system will still work well after surgery.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer  flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/73-neck-lymph_nodes.jpg" alt="" />
                                    </div>
                                    <div className="imgCaption">
                                        <p className="captionItalic centered "><strong>Lymph nodes that are removed during neck dissection</strong>
                                        </p>
                                    </div>
                                    <div className="imageContainer flexContainer justifyCenter">
                                        <img className="maxWidth500" alt="Neck Healed Incision" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Neck_healed+incision.jpg"  />
                                    </div>
                                    <div className="imgCaption">
                                       <p className="captionItalic centered "><strong>Example of a healed neck dissection incision after removal of lymph nodes</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 id="dayOfSurgery" className="sectionHeader w-100 text-center">The Day of Surgery</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Check-in</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-md-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>Check in at the surgery waiting area on the main floor of Barnes-Jewish Hospital south campus. If you need directions, visit the information desk located on the main level.</li>
                                            <li>After you check-in, you will go through hospital registration. Upon completion, your care team member will take you to the pre-surgery holding area.</li>
                                            <li>The surgical receptionist will give your family member or caregiver a beeper to allow us to update them about your surgery.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Before
                                        Going into Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-md-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>We will review your medications and health history with you.</li>
                                            <li>We will start an IV in your arm. <strong>If you were instructed by your surgeon to keep one of your arms free of IVs, please remind the nurse.</strong></li>
                                            <li>Your anesthesiologist will talk about the most appropriate anesthetic for you and the plan to best manage your pain after surgery.</li>
                                            <li>Your surgeon will meet with you to review the surgical plan and mark where the surgery will occur.</li>
                                            <li>You will meet with the nursing staff who will be caring for you in the operating room. </li>
                                            <li>Before going to the operating room, you will be given medication for comfort. </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">During
                                        Your Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-md-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>Throughout your surgery your surgical team will monitor your breathing, heart rate and blood pressure.</li>
                                            <li>A catheter, used to drain urine from your bladder, may be placed during your surgery.</li>
                                            <li>Updates about your progress will be given to the person who holds the beeper in the surgical waiting room.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Right
                                        After Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-md-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>Usually, you will wake up in the recovery room when your surgery is complete.</li>
                                            <li>Specially trained nurses and an anesthesiologist will monitor you. They will keep you safe and comfortable while you heal.</li>
                                            <li>Your care team of specialists will be with you during your recovery.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP2_3_2;
