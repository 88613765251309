import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonFeedingAPersonFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFeedingAPerson">Feeding A Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonFeedingAPerson/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonFeedingAPerson/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is most important to know when feeding a person?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>You need to know the steps to take to prevent choking. This is very important in a person who has trouble swallowing.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I tell if a person has trouble swallowing?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The person might be able to tell you. If you think the person is having trouble, tell their doctor. You may need a referral to a dietitian. Also, observe for these signs of dysphagia:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>A wet sounding voice</li>
                                                    <li>Change in voice, such as hoarseness after swallowing</li>
                                                    <li>A weak uncontrolled cough</li>
                                                    <li>Gagging, coughing or choking on food</li>
                                                    <li>Taking a long time to swallow</li>
                                                    <li>Pain when swallowing</li>
                                                    <li>Having food or liquids come back up though the throat, mouth, or nose after swallowing</li>
                                                    <li>Pain or pressure in the chest or heartburn with eating</li>
                                                    <li>Pocketing of food in the mouth (Food remains in mouth after person has finished eating)</li>
                                                    <li>Slow, weak or uncoordinated speech.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What do I do if a person starts to choke on their food?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Stay calm, stop feeding the person and be sure he or she is sitting upright.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Is the Heimlich Maneuver the same for everyone?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>No, only use the maneuver on conscious adults or children over age 1.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/USDA_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The United States Department of Agriculture (USDA)</h2>
                                                        <p>Offers sample weekly meal planning services for a range of budgets on their website. Also offers information on food safety and Choose MyPlate.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.usda.gov" target="_blank">www.usda.gov</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CDC_logo.jpeg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Centers for Disease Control and Prevention Food safety office</h2>
                                                        <p>Provides guidelines for proper way to handle and store food.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.cdc.gov/foodsafety/" target="_blank">www.cdc.gov/foodsafety</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AlzheimersAssoc.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Alzheimer’s Association</h2>
                                                        <p>Provides information and help for caregivers of persons with Alzheimer’s Disease; 24/7 helpline (800) 272-3900</p>
                                                        <p className="resourceLink">visit: <a href="https://www.alz.org" target="_blank">www.alz.org</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonFeedingAPersonFAQResources;
