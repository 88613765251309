import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from 'react-router-hash-link';

const SitemanPancreaticAll7_3 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-lg-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Help Getting Around</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-12 logosAndType3Across internalNavigation bordersOff">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_7-3#concierge">CONCIERGE</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_7-3#parking">PARKING</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_7-3#other">OTHER SERVICES</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <p>We provide a variety of services and information to help you navigate and
                                                take advantage of our facility’s amenities.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight">
                                                <h2 id="concierge" className="subheadUpperAndLower mb-0">Concierge
                                                    Services</h2>
                                            </div>
                                            <div className="withBorder blueBorder">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>While visiting patients at Barnes-Jewish Hospital, concierge
                                                            representatives are available to help assist you with
                                                            information, services and special requests, including:</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                                    <div className="w-100">
                                                        <div className="imageContainer">
                                                            <div
                                                                className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                                <p><strong>Bank locations</strong></p>
                                                                <p><strong>Cafeterias and restaurants</strong></p>
                                                                <p><strong>Campus maps and directions</strong></p>
                                                                <p><strong>Hotels and long-term accommodations</strong>
                                                                </p>
                                                                <p><strong>Local attractions</strong></p>
                                                                <p><strong>Laundry facilities</strong></p>
                                                            </div>
                                                            <div className="rightCol col-md-5 paddingLeft7">
                                                                <p><strong>Parks</strong></p>
                                                                <p><strong>Pharmacies nearby</strong></p>
                                                                <p><strong>Postal services</strong></p>
                                                                <p><strong>Shopping</strong></p>
                                                                <p><strong>Shower facilities</strong></p>
                                                                <p><strong>Transportation services</strong></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>Concierge assistance is offered at no charge. Services
                                                            provided by outside vendors may include a fee. Concierge
                                                            representatives are located at the information desks on the
                                                            hospital’s South Campus and in the Center for Advanced
                                                            Medicine.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperAndLower"><em><a
                                                            href="tel:3143625194">314-362-5194</a> <a
                                                            href="conciergeservices@bjc.org"
                                                            target="_blank">conciergeservices@bjc.org</a></em></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">Main Hospital: <span
                                                className="tel-style"><a href="tel:3147473000">314-747-3000</a></span>
                                            </h2>
                                            <h2 className="headline">MEET AND GREET ESCORTS</h2>
                                            <p><em><a href="tel:3143625194">314-362-5194</a></em> or <em>toll-free <a
                                                href="tel:8004514892">800-451-4892</a></em></p>
                                            <p>We can help make your visit more pleasant and convenient by having
                                                someone meet you at the door, escort you to your appointment and help
                                                you with any other special guest services. This free service is
                                                available to anyone.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="parking" className="headline">PARKING</h2>
                                            <p>Parking Garages: Barnes-Jewish Hospital offers three parking garages for
                                                patients and visitors. All of our garages are connected to the hospital
                                                by covered, overhead walkways. When confirming your appointment, please
                                                be
                                                sure to find out which garage is closest to the area you are visiting or
                                                receiving treatment.</p>
                                            <p><strong>Valet Parking:</strong> Pull up to the Center for Advanced
                                                Medicine or the main hospital entrance closest to Kingshighway on
                                                Barnes-Jewish
                                                Hospital Plaza and we'll valet park your vehicle. Valet parking is
                                                available in two locations on campus. The cost for valet service is $2
                                                plus garage
                                                fees. It is not offered on weekends or holidays. If you have a disabled
                                                hang tag or license plate, valet parking is free.</p>
                                            <p><strong>Barnes-Jewish South Valet:</strong><br/>
                                                <em>Monday through Friday, 6 a.m.-5:30 p.m.
                                                    Located at the lobby entrance on the street level of Queeny Tower on
                                                    Barnes-Jewish Hospital Plaza
                                                    (closest to Kingshighway)</em></p>
                                            <p><strong>Barnes-Jewish Hospital North and the Center for Advanced Medicine
                                                Valet:</strong><br/>
                                                <em>Monday through Friday, 6 a.m.-7 p.m.</em><br/>
                                                Located at the main entrance of the Center for Advanced Medicine on
                                                Euclid.</p>

                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h2 className="headline">EXTENDED STAY PARKING FOR INPATIENTS</h2>
                                                    <p><em><a href="tel:3143620750">314-362-0750</a></em></p>
                                                    <p>Inpatients who drive their vehicle to the hospital, and will have
                                                        an extended stay, may call 314-362-0750 and a member of public
                                                        safety will bring them
                                                        a parking voucher, which will waive all fees if the vehicle is
                                                        not moved during the inpatient’s stay. Anyone needing assistance
                                                        may also contact the hospital’s department of
                                                        public safety at <a href="tel:3143620750">314-362-0750</a>. For
                                                        emergencies, call <a href="tel:3143620911">314-362-0911</a>.</p>
                                                    <p>RV parking is available on the medical near the Taylor Avenue
                                                        Building, 600 S. Taylor Avenue. These are electric-only hook
                                                        ups, no water. Please call
                                                        Public Safety at <a href="tel:3142807953">314-280-7953</a> to
                                                        make arrangements. Concierge Services has a list
                                                        of other RV parking options. Please call <a
                                                            href="tel:3143625194">314-362-5194</a> to reach Concierge
                                                        Services.</p>
                                                </div>

                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-2 imgCol noPaddingLeft">
                                                            <img className="img-responsive w-100"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/in-route_icon.svg"
                                                                 alt=""/>
                                                        </div>
                                                        <div className="col-md-10 typeCol noPaddingLeft">
                                                            <h1><span
                                                                className="redColor">IN-ROUTE APPOINTMENT HOTLINE</span>
                                                                <br/>
                                                                866-51-HWY40 (<a href="tel:8665149940">866-514-9940</a>)
                                                            </h1>
                                                            <p>If you are unexpectedly delayed due to traffic, don’t
                                                                worry.</p>
                                                            <p className="mobileMarginBottom30">Call us and we will
                                                                notify your physician that you may be late. Hotline
                                                                operators are available Monday
                                                                through Friday during normal business hours to notify
                                                                your physician and provide alternate directions.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="w-100">
                                                        <hr/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="headline">SHUTTLE SERVICE</h2>
                                                        <p><em><a href="tel:3143620750">314-362-0750</a></em></p>
                                                        <p>The hospital offers a free, wheelchair-accessible shuttle to
                                                            patients and visitors from 7 a.m. – 11 p.m. Monday through
                                                            Friday. The route includes Barnes-Jewish Plaza Tower, St.
                                                            Louis Children’s Hospital, Barnes-Jewish Parkview Tower,
                                                            Center for Advanced Medicine, Barnes Lodge, Hope Lodge and
                                                            the Ronald McDonald House. The shuttle picks up every 20
                                                            minutes.</p>
                                                        <p><a href="">Download Weekday Shuttle Schedule <i
                                                            className="fa fa-chevron-right"></i></a></p>
                                                        <p>On weekends and holidays, transportation is available via a
                                                            hospital security vehicle every two even hours from 8 a.m. –
                                                            8 p.m. Please call the shuttle number 314.362.0750 to
                                                            confirm a pick-up at a certain time. Weekend and holiday
                                                            hours do not include Hope Lodge or the Ronald McDonald
                                                            House.</p>
                                                        <p className="bold">Shuttle busses stop in the following order on the Washington University Medical Center campus:</p>
                                                        <p><em>Barnes-Jewish Plaza Tower (entrance located off Barnes-Jewish Hospital Plaza Drive)</em></p>
                                                        <p><em>St. Louis Children’s Hospital (entrance located off Children’s Place)</em></p>
                                                        <p><em>Barnes-Jewish Parkview Tower</em></p>
                                                        <p><em>Center for Advanced Medicine (entrance located off Parkview Place)</em></p>
                                                    </div>
                                                </div>
                                                <div className="col-12 backToTop">
                                                    <div className="w-100">
                                                        <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                              onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                            TOP <i className="fa fa-chevron-up"></i></a></p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 id="other" className="headline">OTHER SERVICES</h2>
                                                        <p><strong>Pharmacy</strong><br/>
                                                            <em><a href="tel:3143620750">314-362-0750</a></em></p>
                                                        <h2 className="subheadUpperandLower">Hours</h2>
                                                        <p><em>Mon - Fri 6:30 am to 5:30 pm, Sat 7 am to 3 pm, Closed
                                                            Sundays and most major holidays.</em></p>
                                                        <p>Barnes-Jewish Hospital's outpatient pharmacy provides
                                                            services for outpatients from Barnes-Jewish Hospital's south
                                                            and north campuses, for
                                                            outpatients from the Center for Advanced Medicine physician
                                                            offices, and for BJC HealthCare employees and their
                                                            dependents. <br/><a
                                                                href="https://www.barnesjewish.org/patients-visitors/pharmacy">Barnesjewish.org/patients-visitors/pharmacy</a>
                                                        </p>
                                                        <h2 className="subheadUpperandLower">Sending Flowers or Gifts to
                                                            Patients</h2>
                                                        <p><em><a href="tel:3143624300">314-362-4300</a> or <a
                                                            href="tel:8004094485">800-409-4485</a></em></p>
                                                        <p>To send a gift to a patient during this hospital stay,
                                                            contact our gift shop at the numbers listed, or go online to
                                                            order. <br/><a
                                                                href="https://shop.barnesjewish.org/">shop.barnesjewish.org</a>
                                                        </p>
                                                        <h2 className="subheadUpperandLower">Internet Access</h2>
                                                        <p>Patients, family members and guests can enjoy the Internet
                                                            from most
                                                            patient rooms at Barnes-Jewish Hospital. Service is free and
                                                            can be accessed
                                                            with most properly configured wireless devices. Patients and
                                                            visitors without
                                                            access to a wireless device can take advantage of free
                                                            Internet access in our Concierge Services offices.</p>
                                                        <h2 className="subheadUpperandLower">Computers and Office
                                                            Services</h2>
                                                        <p>Computers are available for use in the offices on the South
                                                            campus and in
                                                            the Center for Advanced Medicine. Both offer, at no charge,
                                                            Internet service, printing,
                                                            email and other office services.</p>
                                                        <h2 className="subheadUpperandLower">Laptop and DVD Loaner
                                                            Program</h2>
                                                        <p><em><a href="tel:3143625196">314-362-5196</a></em></p>
                                                        <p>Laptop computers, ipads and a selection of DVD shows are
                                                            available for loan with a current ID. Please see Concierge
                                                            Services or call (314)
                                                            362-5196 for availability Monday through Friday.</p>
                                                        <h2 className="subheadUpperandLower">Bank and ATMs</h2>
                                                        <p>A full-service U.S. Bank branch is located on the first floor
                                                            of Barnes-Jewish Hospital North adjacent to the Center for
                                                            Advanced Medicine.</p>
                                                        <p>The bank is open 9 am to 5 pm Mon —Thurs, Fri 7 am to 5
                                                            pm.</p>
                                                        <p>An automatic teller machine (ATM) is located near the
                                                            entrance to the
                                                            bank. In addition, ATMs can be found in the lobby of the
                                                            Center for Advanced
                                                            Medicine and on the street level of Barnes-Jewish Hospital
                                                            South near the lobby.</p>
                                                        <h2 className="subheadUpperandLower">Lost & Found</h2>
                                                        <p><em>314-747-LOST (<a
                                                            href="tel:3147475678">314-747-5678</a>)<br/>
                                                            Or, Security at <a
                                                                href="tel:3143620750">314-362-0750</a></em></p>
                                                        <p>To report a lost or found item, call <a
                                                            href="tel:3147475678">314-747-LOST (5678)</a> or
                                                            security at
                                                            <a href="tel:3143620750">314-362-0750</a>. We will make
                                                            every effort to locate a patient’s lost property;
                                                            however we are not responsible for lost or stolen property.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll7_3;
