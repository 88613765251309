import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonFallPreventionWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Fall Prevention: Show Me How'}, () => {
            console.log('Fall Prevention: Show Me How');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFallPrevention">Fall
                                                            Prevention</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFallPrevention/WhatToDoAndSafety#reduceRisk">
                                                    <p className="whiteColor">Reduce Risk <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFallPrevention/WhatToDoAndSafety#aFallHappens">
                                                    <p className="whiteColor">A Fall Happens <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFallPrevention/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Nav Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFallPrevention/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFallPrevention/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="reduceRisk">REDUCE FALL RISKS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Reduce Fall Risks</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p><span className="bold">If you know a person is at risk for a fall, take these steps to lessen their risk:</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Review Medicines</p>
                                                <p>When the person you care for is on more than 6 medicines, including
                                                    over-the-counter medicines, review the list of medicines with their
                                                    doctor or pharmacist. It is best to have one doctor in charge of the
                                                    person’s medical condition and one pharmacy to fill prescriptions.
                                                    Be sure the doctor also knows about any medicines ordered by a
                                                    specialist. Be alert if you care for an older adult who gets a
                                                    prescription changed.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Regular Exercise</p>
                                                <p>Encourage the person you care for to exercise regularly, even if it
                                                    is simply walking several times a day. Decreased activity can make a
                                                    person more weak and cause him to lose muscle mass. Strength
                                                    training using low impact weights is also very useful. Regular
                                                    exercise improves balance and coordination. Older adults benefit
                                                    from supervised exercise classes. Tai Chi is one exercise that helps
                                                    improve balance in older adults. Tai Chi is one form of martial art.
                                                    It is a mind-body practice sometimes called “moving meditation”—
                                                    persons move their bodies slowly, gently, and with awareness, while
                                                    breathing deeply. During Tai Chi exercise the body is in constant
                                                    motion, and good posture is very important.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Maintain Clear Vision</p>
                                                <p>Any person who is at risk for falling and who wears glasses or
                                                    contacts should take steps to be sure they can see clearly when
                                                    walking and moving about.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Clean the person’s glasses routinely before he or she gets up to
                                                        walk.
                                                    </li>
                                                    <li>Remove any glare in the home, by using sheer curtains or shades
                                                        across windows. Nongloss or satin finishes on walls and
                                                        countertops also reduce glare.
                                                    </li>
                                                    <li>Have the person wear sunglasses when walking outside.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn"> Regular Toileting</p><br/><br/>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>If a person goes to the bathroom often, especially at night,
                                                        there is an increased risk for falling.
                                                    </li>
                                                    <li>If a person needs help to get to the bathroom, ask regularly
                                                        (every hour or two) during the day if they need to go.
                                                    </li>
                                                    <li>If the person takes a diuretic medicine (“water pill”), give it
                                                        in the morning, not at night.
                                                    </li>
                                                    <li>Be sure the person has a night light or can easily reach a light
                                                        switch from the bed. Place a night light in the bathroom, too.
                                                        Turning on a bright light after coming from a dark room can be
                                                        blinding.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Safe use of Canes, Crutches and Walkers</p>
                                                <p>Unless persons use their cane, set of crutches, or walker correctly,
                                                    they can trip, tip over, and lose balance and fall. These assist
                                                    devices should always be kept in working order. A person should only
                                                    use the type of device prescribed by their doctor. Do not let them
                                                    use someone else’s device.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Store medicines in a clean, dry place, away from humidity or
                                                        moisture. The kitchen is best
                                                    </li>
                                                    <li>Keep syringes, dressing supplies, blood or urine test strips in
                                                        an airtight container (e.g. plastic storage bin). Store in a
                                                        cool place like a bedroom closet
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Standing and Sitting Up</p>
                                                <p>If a person feels dizzy when sitting or standing up, they are at risk
                                                    for falling if they are not careful to first let the dizziness pass.
                                                    Always ask a person if he or she feels dizzy when you first help
                                                    them stand or sit up after lying down. Have the person sit on the
                                                    side of the bed and dangle their feet before standing.</p>
                                                <p>Usually the dizziness will go away and it will be safe to move about.
                                                    However, if the dizziness lasts, do not let the person get up. Help
                                                    him or her return to bed or sit in a chair. For more tips see our
                                                    lesson on <Nav.Link className="" href="/LessonMoving">Moving Safely
                                                        in the Home</Nav.Link>.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="aFallHappens">WHEN A PERSON FALLS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Most falls do not involve injuries or the injuries are minor and do
                                                    not require a trip to the emergency room. Yet proper care is
                                                    needed.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>If a person falls, do not try to move him or her. Wait and check
                                                        to see if the person is hurt. Ask the person to tell you if he
                                                        or she feels hurt and, if so, where. Have the person lie still
                                                        for a few minutes before trying to move him or her.
                                                    </li>
                                                    <li>Then help the person to stand <Nav.Link className=""
                                                                                                href="/LessonMoving">(See
                                                        our lesson on Moving Safely in in the Home)</Nav.Link>.
                                                    </li>
                                                    <li>If a person falls and can’t remember falling, he or she likely
                                                        fainted. Fainting can be due to many different problems. Report
                                                        this to the person’s doctor immediately.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i
                                                    className="fa fa-exclamation-circle"></i>Call 911 if the person:
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Hit their head and is unconscious or difficult to awaken</li>
                                                    <li>Has a hard time breathing</li>
                                                    <li>Is bleeding from the head or face</li>
                                                    <li>Has a severe headache</li>
                                                    <li>Has slurred speech</li>
                                                    <li>Becomes confused about where he or she is</li>
                                                    <li>Has repeated vomiting</li>
                                                    <li>Has a seizure (sudden uncontrollable muscle jerking)</li>
                                                    <li>Is unable to move or stand without having severe pain in their
                                                        legs or lower body
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon subhead16px"><i
                                                    className="fa fa-exclamation-circle coralColorMargin"></i>&nbsp;If
                                                    there is any bleeding from a scrape or gash:</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Take a clean cloth or gauze bandage and apply gentle pressure for 20
                                                    minutes. Do not release the pressure. <span className="bold italic">If the blood spurts or flows after holding pressure, Call 911</span>.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Fall prevention is all about safety. Here is a summary of key points
                                                    to remember:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Have the person’s doctor, nurse, or pharmacist review their
                                                        medicines to reduce any drug side effects and interactions
                                                    </li>
                                                    <li>Have their eyes checked by an eye doctor each year</li>
                                                    <li>Improve the lighting in the home</li>
                                                    <li>Keep any assist device, such as a cane, crutches, or walker in
                                                        good repair and be sure the person uses the device correctly
                                                    </li>
                                                    <li>Have the person get up slowly from a lying or a sitting position
                                                        before trying to walk
                                                    </li>
                                                    <li>Have the person wear shoes inside and outside the house</li>
                                                    <li>Do not let the person wear socks, smooth bottom slippers or go
                                                        barefoot
                                                    </li>
                                                    <li>Have the person exercise regularly. Tai Chi is a good exercise
                                                        for increasing strength and improving balance.
                                                    </li>
                                                    <li>Follow steps in the Show Me How section for making rooms in the
                                                        home safe
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="99v9z0mf0Eg" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="#" target="_blank">Open File<i
                                                className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">Equipment</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Safety Tips</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p><i className="fa fa-file-text mediumGrayColor"></i>&nbsp;<i
                                                    className="fa fa-arrow-down mediumGrayColor"></i>&nbsp;<a
                                                    href="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/pdf/HomeSafetyChecklist.pdf"
                                                    target="_blank">Download</a> and Print the Home Safety Checklist</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonFallPreventionWhatToDoAndSafety;
