import React from "react";

function L83CComponentCode() {

    return (

        <div className="lego l083">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"/>
            </div>
            <div className="contentContainer">
                <p>You will be given written discharge instructions after your procedure
                    along with a pain diary to track your response to the injection.</p>
            </div>
        </div>

    )
};


export default L83CComponentCode;