import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonGettingReadyWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonGettingReady">Getting Ready For Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Questions for the First Visit with the Surgeon</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you care for a person who is having surgery, write these questions down along with any others you might think about. Discuss them together, before you visit the surgeon for the first time.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What does surgery involve?</p>
                                        <p>Ask the surgeon to describe the operation. If possible, ask him or her to draw a picture or give you any brochures or booklets that include drawings, CDs or videos.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What type of anesthesia will the person have?</p>
                                        <p>The type of surgery determines the best type of anesthesia.There are three types of anesthesia: local, regional (such as an epidural), and general. Each involves different risks and degrees of sedation (e.g. sleepiness to being unconscious). The type of anesthesia used will determine when the person must stop eating or drinking before surgery and what medicines the person should take the day of surgery.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What are the other options?</p>
                                        <p>Ask if there are options other than surgery. For example, when a person injures a back or hip, would exercise and rehabilitation be just as effective? Are there surgical procedures (such as endoscopy or microsurgery) that are less invasive (involve a smaller incision and shorter surgery time)?</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">How will surgery help?</p>
                                        <p>Every person wants to know what will be the benefits from surgery and how long they will last. If a person is getting a knee replacement, for example:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Will they be able to walk again without pain? How soon?</li>
                                            <li>Will the person need rehabilitation after surgery? For how long?</li>
                                            <li>Does rehabilitation take place after surgery in the home or at an outside facility? In another example, if a person is having surgery to improve swallowing:</li>
                                            <li>Will they be able to eat a normal diet again?</li>
                                            <li>How long will any improvements last?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What are a person’s risks?</p>
                                        <p>Every surgery has risks, but some are greater than others. Find out what the risks are for the surgery the person you care for will be having.</p>
                                        <p>Common risks include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Infection</li>
                                            <li>Bleeding</li>
                                            <li>Blood clots</li>
                                            <li>Loss of function of an arm or leg</li>
                                            <li>Death</li>
                                            <li>Paralysis</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What is the surgeon’s experience?</p>
                                        <p>All surgeons keep records of their surgeries. If you are not happy with the surgeon’s experience, you might want a second opinion.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>How many times has the surgeon performed a procedure?</li>
                                            <li>What is the surgeon’s success rate?</li>
                                            <li>How often do his or her patients have complications?</li>
                                            <li>Have there been any deaths?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">How long will surgery last?</p>
                                        <p>This is a hard question for surgeons to answer, because it differs for each person’s situation. Many surgeries take less time because of newer technologies. Some are performed the same way as in the past because it is still the standard way to perform the surgery. Surgeons or the surgery staff will do the best they can in estimating how long the surgery will take.</p>
                                        <p>Remember, it takes time to prepare a patient before he or she enters the operating room and there is also time after surgery during which the patient is watched carefully in the recovery room. This adds to the total amount of time that the person is away for surgery.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What about Pain control?</p>
                                        <p>Pain control after surgery is very important in helping a person recover. Ask about the type of pain control the person will receive after surgery. There are many options. The surgeon may order pain medicine to be given through a small tube inserted into a vein (IV), by a patient controlled analgesia (PCA) device, or by pain medicine placed through a tube into the spinal canal ( spinal analgesia). Once a patient is able to drink liquids and no longer feels sick to their stomach, pain medicines will be ordered to be taken by mouth.</p>
                                        <p>A person will be less anxious about surgery if he or she knows they will get pain relief.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">How long will recovery take?</p>
                                        <p>This will vary on the type of surgery, but patients usually return home in a few days.</p>
                                        <p>Ask these questions:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>After surgery, will the person be going home or will he or she stay in the hospital?</li>
                                            <li>If the person stays in the hospital, how long is the expected stay? Sometimes a person will need extra recovery time in a nursing or rehabilitation facility.</li>
                                            <li>If the person lives alone and goes home the same day, how many days will they need someone to stay with them?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Know a Person’s Medical History</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A surgeon needs to know their patient’s medical history, so he or she can decide what the risks are when they have surgery. You can help to identify those risks. Discuss the following issues with the person you care for so that you can fully inform the surgeon on your first visit.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Discuss the person’s experience with past surgeries</p>
                                        <p>Did the person have any problems such as trouble breathing, infection, inability to eat, or severe pain? This information allows a surgeon to clarify your concerns and to plan ways to avoid problems for the upcoming surgery</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Did the person have motion sickness or nausea and vomiting during past surgery?</p>
                                        <p>If so, the surgeon will be able to provide the right combination of anesthesia to avoid this complication.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Review the person’s allergies</p>
                                        <p>Before surgery, it is important for the nurses and doctors to know if the person has had an allergy or reaction to any medicines, latex (rubber), tape, ointments or solutions (like iodine). It is a nurse and surgeon’s job to avoid exposing persons to anything to which they are allergic during surgery. Food allergy information is important also, because occasionally, medicines have a food component, which can cause an allergic reaction.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">What medicines is the person currently taking?</p>
                                        <p>Have a current list of all medicines (prescribed and over-the-counter) including any herbs or supplements, for the surgeon to review. Some herbal medicines prolong the effects of anesthesia and increase the risk of bleeding. The surgeon may need to temporarily stop a medicine before surgery or adjust a dose.</p>
                                        <p>For example, medicines that cause bleeding during surgery are often stopped a week or so before surgery. These medicines include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Aspirin containing products</li>
                                            <li>NSAIDS (non-steroidal anti-inflammatory medicines [e.g. Advil, Motrin, Celebrex, Naprosyn,])</li>
                                            <li>Extra Vitamin E</li>
                                            <li>Garlic</li>
                                            <li>Ginseng</li>
                                            <li>Ginkgo</li>
                                            <li>Plavix or Coumadin. Ask the person’s primary doctor about the risk of not taking medicine that has a blood thinning effect.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Since it is usually one of your other health care providers who prescribe daily medicines, your surgeon needs to be aware of the risk of not taking medicine that has a blood thinning effect. It is important that a person’s primary doctor be aware of an upcoming surgery. Communication with all of your health care providers is important in obtaining a complete medical history. Always ask your surgeon to talk with your primary doctor about an upcoming surgery.</p>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonGettingReadyWhatYouNeedToKnow;