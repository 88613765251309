import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll8_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-1">
                                        <div
                                            className="tile Height1RowTall width1Column t_007 sitemanLimeLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="h3Container">
                                                        <h3 className="text-center whiteColor">Chemotherapy ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-page-not-completed">
                                        <div
                                            className="tile Height1RowTall width1Column t_007 sitemanLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="h3Container">
                                                        <h3 className="text-center whiteColor">Radiation Therapy ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-3">
                                        <div
                                            className="tile Height1RowTall width1Column t_007 sitemanLimeLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="h3Container">
                                                        <h3 className="text-center whiteColor">Physical Therapy</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-5">
                                        <div
                                            className="tile Height1RowTall width2Column t_007 t_007_2x1 sitemanLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="h3Container">
                                                        <h3 className="text-center whiteColor">Speech Therapy ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-6">
                                        <div
                                            className="tile Height1RowTall width1Column t_007 sitemanLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="h3Container">
                                                        <h3 className="text-center whiteColor">Dental Care ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-7">
                                        <div
                                            className="tile Height1RowTall width2Column t_009 sitemanLimeLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderBox">
                                                        <h3 className="text-center whiteColor">Clinical Research News
                                                            ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-8">
                                        <div
                                            className="tile Height1RowTall width2Column t_009 sitemanLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Lymphedema ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-9">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanSurvivorshipBackgroundImg">
                                            <div className="contentCenter">
                                                <div
                                                    className="centerTextTileBanner lightGreenBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Survivorship ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll8_0;