import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin1_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderdDarkBlue">What
                                                to Expect</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100">
                                        <div className="w-100 d-flex flex-row justify-content-center">
                                            <h3 className="subheadUpperAndLowerColorBar pritikinBackgroundDarkBlue centered w-auto ml-auto mr-auto">Your
                                                cardiac rehab team will be here for you, every step of the way. </h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/101071.jpg" className="img-responsive centerImage w-100
                                 maxWidth400"/>
                                            <p className="captionItalic text-center w-100">Having a heart event can be a
                                                challenging and emotional experience. But you don’t have to go through
                                                this alone.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>As you begin your recovery, your care team of cardiac rehab professionals
                                                will provide the support and education you need to commit to a new,
                                                healthier lifestyle. And, they will work with you to monitor your
                                                progress and help you achieve your specific health and wellness
                                                goals.</p>
                                            <p>It may seem overwhelming at times, but we assure you that developing
                                                healthy lifestyle habits is even easier than you might think!</p>
                                        </div>
                                    </div>
                                    <div
                                        className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder marginBottom40">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Your program will be tailored just
                                                    for you.</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Your cardiac rehab plan will be individualized specifically for you
                                                    based on your health, your needs and your own personal goals.
                                                    Through the course of the program, you will:</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Strengthen your body and your heart</li>
                                                    <li>Discover delicious, wholesome foods and easy-to-prepare
                                                        recipes
                                                    </li>
                                                    <li>Foster a healthy mind-set along your journey</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="mb-0">You’ll move forward at a pace that is comfortable to
                                                    you. As you attend more sessions, you’ll start to see results and
                                                    feel better and better!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100">Sessions are designed
                                                to be interesting, effective and relevant to your life.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Cardiac rehab consists of a series of sessions run by an expert team that
                                                delivers supervised exercise training, education and skills development
                                                for heart-healthy living, and counseling on stress and other
                                                psychosocial factors.</p>
                                            <p className="bold italic">Each day you attend, you will be participating in
                                                both an exercise session and a lifestyle education session, which are
                                                anything but boring!</p>
                                            <p>You'll attend professional Cooking School demos and discover how to add
                                                heart-healthy flavor to your favorite meals, participate in workshops on
                                                Nutrition and Healthy Mind-Set, and receive one-on-one consultations
                                                with a registered dietitian and/or health coach.</p>
                                            <p>You'll be given a variety of useful take-home materials including a
                                                complete <span className="italic">Patient Guidebook</span> that walks
                                                you through every part of your journey, step-by-step!</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">"You just really crave feeling normal again because you
                                                have been through so much. You want to be normal and I’m moving in that
                                                direction. It changed my spirits, and how I feel about my body. I cannot
                                                praise the program enough. It does so much for you.”</p>
                                            <p className="name">— Paula P., Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule"/>
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“After the heart event I was very emotional. And you
                                                kind of feel like, ‘am I going to live a normal life?’ Then you come in
                                                here and start learning the Pritikin program, and you know you can live
                                                a normal life.”</p>
                                            <p className="name">— Theresa M., Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule"/>
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“It felt good coming here to cardiac rehab and seeing
                                                the friendly nurses and therapists. If I had any questions, they were
                                                right there for me.”</p>
                                            <p className="name">— Pam G., Cardiac Rehab Patient</p>
                                        </div>
                                    </div>
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h3 className="videoTitle width100 text-center text-dark">*/}
                                    {/*            My Cardiac Rehab Story*/}
                                    {/*        </h3>*/}
                                    {/*        <p className="videoTitle width100 text-center text-dark captionItalic">*/}
                                    {/*            (stories from patients who have completed their sessions)*/}
                                    {/*        </p>*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive width100" />*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 video">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <div className="floatLeft width100 captionBelow">*/}
                                    {/*            /!*     <div className="embedContainer">*!/*/}

                                    {/*            /!*</div>*!/*/}
                                    {/*            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_couple.jpg"*/}
                                    {/*                 className="img-responsive width100" />*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin1_3;