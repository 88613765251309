import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTGSP7_2 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideoOvercomingFears = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice From Patients on Recovery: Overcoming Fears'}, () => {
            console.log('Advice From Patients on Recovery: Overcoming Fears');
        });
        await dispatch(pal);
    }

    const playVideoWorkingWithTherapist = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice From Patients on Recovery: Working With Therapists'}, () => {
            console.log('Advice From Patients on Recovery: Working With Therapists');
        });
        await dispatch(pal);
    }

    const playVideoFeeding = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice From Patients on Recovery: Feeding'}, () => {
            console.log('Advice From Patients on Recovery: Feeding');
        });
        await dispatch(pal);
    }

    const playVideoNutrition = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice From Patients on Recovery: Nutrition'}, () => {
            console.log('Advice From Patients on Recovery: Nutrition');
        });
        await dispatch(pal);
    }

    const playVideoCaregivers = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice From Patients on Recovery: Caring for the Caregivers'}, () => {
            console.log('Advice From Patients on Recovery: Caring for the Caregivers');
        });
        await dispatch(pal);
    }

    const playVideoSurvivorGroups = async (e) => {
        const pal = await videoAccessLog({videoName: 'Advice From Patients on Recovery: Survivor Groups'}, () => {
            console.log('Advice From Patients on Recovery: Survivor Groups');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Advice
                                                from Patients &amp; Families</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Often, the most valuable advice comes from those who have been there before. The stories and suggestions from past patients and family members can be so valuable as you navigate all the steps of your journey. At Siteman Cancer Center, we are so grateful that many of those who have faced similar challenges, and learned from them, are eager to help those who are just beginning their own journey.</p>
                                            <p>Below are some of those stories and thoughts on ways to help ensure your outcomes are as successful as possible.</p>
                                            <p>For more stories, and to connect with those who have shared similar experiences, check out the <a href="siteman-ent-tgsp-7-4">many communities</a> – local and national – who are there for you.</p>
                                        </div>
                                    </div>

                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="yNRfLR6o4sQ" opts={opts} onPlay={playVideoOvercomingFears} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Overcoming Fears</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="PVEM_dAd1hQ" opts={opts} onPlay={playVideoWorkingWithTherapist} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Working With Therapists</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="WhCgjmMFPFo" opts={opts} onPlay={playVideoFeeding} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Feeding</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="rWoDuNFFYtY" opts={opts} onPlay={playVideoNutrition} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Nutrition</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="4t08QXZpQmg" opts={opts} onPlay={playVideoCaregivers} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Caring for the Caregivers</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="I8WFvUGJq_A" opts={opts} onPlay={playVideoSurvivorGroups} />
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Survivor Groups</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP7_2;
