import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";
const LessonWalkersWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWalkers">Moving Safely in the Home</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Types of Walkers</h2>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WLK_walker_types.jpg" className="img-responsive" />
                                    <p className="captionItalic text-center w-100">There are two types of walkers, a pickup walker and a roller walker.</p>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The <span class="bold">pickup walker</span> has four solid legs, offers the most stability, and is the best choice when the person cannot place full weight on a leg. Some pickup walkers fold for easy storage. When using a folding walker, make sure the legs click into place before use.</li>
                                            <li><span class="bold">Roller walkers</span> have wheels on the front two legs (most common) or all four legs. Wheeled walkers work best for persons who need assistance but have trouble picking up the walker and moving it. You can place tennis balls on the back legs of the walker with two wheels so that movement of the walker is smooth and easy. Some wheeled walkers can fold for easy storage. When using a folding walker, make sure the legs click into place before use.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Proper Fitting of a Walker</h2>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WLK_proper_fit.jpg" className="img-responsive" />
                                    <p className="captionItalic text-center w-100">When you care for someone who uses a walker, know what is the correct way to use it to move about.</p>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The person’s elbow should bend comfortably, about 15 to 30 degrees, while holding on to the grips.</li>
                                            <li>When the person relaxes the arms at the side of their body, the top of the walker should line up with the crease on the inside of the wrist.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Knowing how to use a walker correctly will help you to watch the person you care for and help prevent injury or falls.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 backToTop footer blueBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWalkersWhatYouNeedToKnow;