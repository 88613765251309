import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import {HashLink as Link} from 'react-router-hash-link';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic5 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Help In Planning</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Making all the necessary arrangements, providing needed information and
                                                notifications, even simply preparing logistics for where to go, what to
                                                bring, what to organize for the patient and family can get confusing and
                                                stressful. We’ve compiled some key guidance and information to help you
                                                plan for success.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-3#preoperativeTestsDiscussion">PREOPERATIVE
                                                        TESTS DISCUSSION</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-3#dayBeforeSurgeryInstructions">DAY
                                                        BEFORE SURGERY INSTRUCTIONS</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-3#lodgingOptions">LODGING
                                                        OPTIONS</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">KEY AREAS OF ADVANCE PLANNING:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder text-center">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom checkBullets">
                                                            <li className="text-start">Preoperative Tests</li>
                                                            <li className="text-start">Having key patient information
                                                                ready to provide when
                                                                asked
                                                            </li>
                                                            <li className="text-start">Making sure to follow pre-surgery
                                                                requirements (e.g.
                                                                fitness, incentive spirometer use)
                                                            </li>
                                                            <li className="text-start">Following the "day before
                                                                surgery" guidelines provided;
                                                                having checklists ready to make sure you don’t forget
                                                                anything
                                                            </li>
                                                            <li className="text-start">Arranging for any needed family
                                                                lodging (and knowing
                                                                what services we offer that can assist you in lodging,
                                                                transportation and other services)
                                                            </li>
                                                            <li className="text-start">Knowing when and where to go,
                                                                park, stay during and
                                                                after the procedure.
                                                            </li>
                                                            <li className="text-start">Getting all needed information
                                                                you’ll be using after the
                                                                surgery and once you return home (contact information,
                                                                instructions, education, plans)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="preoperativeTestsDiscussion">YOUR TEAM
                                                VISIT WITH PREOPERATIVE TESTS</h2>
                                            <p>You will visit the anesthesiology team before surgery for tests that will
                                                likely include blood work, EKG, chest X-ray and answering questions
                                                about your medical and surgery history. You will also be asked to bring
                                                with you a list of key information listed below. This visit may be the
                                                same day as your clinic visit or you may come back on a different day.
                                                This visit will not require you to be without food or drink.</p>
                                            <p className="bold">Location is the Center for Preoperative Assessment and
                                                Planning (CPAP) on the 1st floor of the Center for Advanced
                                                Medicine.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Make sure to let the anesthesiologists know about any allergies or
                                                    adverse reactions to any medications in the past as well as any
                                                    difficulties with anesthesia in the past.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You may be asked to have additional testing such as stress test,
                                                    echocardiogram, ultrasound, pulmonary function testing, or
                                                    catheterization.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>This is a good time to discuss postoperative pain including
                                                    epidurals, narcotic medications, adjunctive therapies, etc.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>If you have a cardiologist or pulmonologist, it is helpful to see
                                                    him/her prior and let them know you are being scheduled for surgery
                                                    and require clearance.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You may be asked to stop, change, or start certain medications before
                                                surgery. It is important that you share these recommendations with your
                                                surgeon and your primary care physician so they know about these changes
                                                as well.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Please bring the following to your
                                                visit:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom checkBullets">
                                                <li>It is important that you bring your medications or a Home Medication
                                                    Record (with dose and how often you take them). Include all over the
                                                    counter items – vitamins, herbs, aspirin, inhalers, etc.
                                                </li>
                                                <li>Any advanced testing (e.g. stress test or echocardiogram) results or
                                                    the testing site’s phone numbers.
                                                </li>
                                                <li>Your implant/information card for devices such as a pacemaker,
                                                    defibrillator, or blood vessel stent.
                                                </li>
                                                <li>If you have recently seen your cardiologist, ask him/her for a
                                                    letter regarding your heart history, any implanted heart devices,
                                                    and your most recent test results.
                                                </li>
                                                <li>Your Primary Care Physician's office phone &amp; fax numbers.</li>
                                                <li>The name and/or phone number of your laboratory, MD office or
                                                    hospital where any recent blood testing, EKG or chest x-rays have
                                                    been done. If you have a copy of your own, please bring.
                                                </li>
                                                <li>Your Advanced Directive or Living Will.</li>
                                                <li>If applicable, the paperwork showing Power of Attorney, guardianship
                                                    or adoption for the patient. This is necessary for us to render
                                                    care.
                                                </li>
                                                <li>All current insurance cards.</li>
                                                <li>A completed Health Questionnaire that you have done in advance. You
                                                    can print a copy from this link: <a
                                                        href="http://anest.wustl.edu/pdf/about/CPAPHealthQuestionnaire.pdf"
                                                        target="_blank">http://anest.wustl.edu/pdf/about/CPAPHealthQuestionnaire.pdf</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="dayBeforeSurgeryInstructions">ON THE DAY
                                                BEFORE SURGERY</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will be given an instruction sheet regarding what medications to
                                                    take, what to eat, and what activities to avoid before surgery.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You may be asked to do a ‘bowel preparation’ which includes taking a
                                                    bottle of Magnesium Citrate (found over the counter) in order to
                                                    induce a bowel movement. If asked, this should be done about 4 or 5
                                                    pm.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>In most cases you can eat a normal breakfast, a “light” lunch and
                                                    “light” dinner such as a sandwich and soup (nothing greasy, oily or
                                                    fried).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Nothing to eat or drink after midnight. You may take a sip of water
                                                    with the medications allowed by your surgeon (see the instruction
                                                    sheet).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Instructions for where to go, when and what to expect on the day of
                                                    surgery can be found in the “Your Surgery” section of this reference
                                                    tool.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="lodgingOptions">ARRANGING FOR
                                                LODGING</h2>
                                            <p>You have many options when locating affordable, convenient lodging.
                                                Barnes Jewish offers help with questions regarding lodging options by
                                                calling our concierge services at <a
                                                    href="tel:3149668412">314-966-8412</a> or emailing <a
                                                    href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a>.
                                                Your physician's office can also provide printouts with more details as
                                                needed.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">BARNES LODGE (Located on
                                                    the Washington University Medical Center campus)</h2>
                                            </div>
                                            <div className="withBorder borderAccentBlue">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100 addressInfoContent">
                                                        <div className="imgCol">
                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lodging_images_0003_Barnes_Lodge.jpg"
                                                                alt=""/>
                                                        </div>
                                                        <div className="addressCol">
                                                            <p>4520 Clayton Ave.</p>
                                                            <p>St Louis, MO 63110</p>
                                                            <p>314-652-4319</p>
                                                            <p><a
                                                                href="https://www.barnesjewish.org/patients-visitors/lodging/barnes-lodge"
                                                                target="_blank">website</a></p>
                                                        </div>
                                                    </div>
                                                    <p>Barnes Lodge is a 20-room guesthouse for outpatients and
                                                        families of patients receiving medical services at
                                                        Barnes-Jewish Hospital. In addition to sleeping rooms,
                                                        Barnes Lodge offers a dining room, a kitchen, television
                                                        rooms, laundry facilities and quiet rooms. To be eligible to
                                                        stay at Barnes Lodge, you must live at least 50 miles from
                                                        St. Louis. Guests are served on a first-come, first-served
                                                        basis – reservations are not accepted.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">HOPE LODGE CENTER</h2>
                                            </div>
                                            <div className="withBorder borderAccentBlue">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100 addressInfoContent">
                                                        <div className="imgCol">
                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lodging_images_0001_Hope_Lodge.jpg"
                                                                alt=""/>
                                                        </div>
                                                        <div className="addressCol">
                                                            <p>4207 Lindell Blvd.</p>
                                                            <p>St. Louis, MO 63108</p>
                                                            <p>314-286-8150</p>
                                                            <p><a
                                                                href="https://www.cancer.org/treatment/support-programs-and-services/patient-lodging/hope-lodge/st-louis/about-our-facility.html"
                                                                target="_blank">website</a></p>
                                                        </div>
                                                    </div>
                                                    <p>The Hope Lodge Center, located about seven blocks from the
                                                        hospital, serves cancer patients who live at least 50 miles
                                                        from St. Louis. There is no charge for lodging. Referrals
                                                        must be made by a physician, social worker or nurse care
                                                        manager. Patients must complete a referral form. This is a
                                                        smoke-free facility. Please call for eligibility
                                                        requirements. Shuttle service to the hospital is
                                                        provided.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">THE PARKWAY HOTEL</h2>
                                            </div>
                                            <div className="withBorder borderAccentBlue">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100 addressInfoContent">
                                                        <div className="imgCol">
                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lodging_images_0002_Parkway_Hotel.jpg"
                                                                alt=""/>
                                                        </div>
                                                        <div className="addressCol">
                                                            <p>4550 Forest Park Avenue</p>
                                                            <p>St. Louis, MO 63108</p>
                                                            <p>314-256-7777 (toll free) 866-314-7700</p>
                                                            <p><a href="https://www.theparkwayhotel.com"
                                                                  target="_blank">website</a></p>
                                                        </div>
                                                    </div>
                                                    <p>The Parkway Hotel is connected to the Center for Advanced
                                                        Medicine by the skywalk in the hospital’s north garage. The
                                                        hotel is two blocks from the Central West End Metro Link
                                                        station. When making a reservation, ask for the Barnes-Jewih
                                                        Hospital patient/family rate.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">R.V. PARKING</h2>
                                            </div>
                                            <div className="withBorder borderAccentBlue">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100 addressInfoContent">
                                                        <div className="imgCol">
                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lodging_images_0000_RV_Parking.jpg"
                                                                alt=""/>
                                                        </div>
                                                        <div className="addressCol">
                                                            <p>600 S. Taylor Ave.</p>
                                                            <p>St. Louis, MO 63110</p>
                                                            <p>314-362-0750</p>
                                                            <p><a href="https://siteman.wustl.edu"
                                                                  target="_blank">website</a></p>
                                                        </div>
                                                    </div>
                                                    <p>Four parking spaces for recreational vehicles are available
                                                        in the parking lot of the Taylor Avenue Building on our main
                                                        campus. The parking spaces are equipped with water and
                                                        electrical hookups. A weekly sewer pick-up service is
                                                        available every Saturday. Persons interested in renting a
                                                        space should contact the Security Department at the number
                                                        above. Sewer removal service must be arranged and paid for
                                                        separately through the Social Work department.</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>More options can be found at here.</p>
                                            <p><a href="http://www.barnesjewish.org/patients-visitors/lodging-directory"
                                                  target="_blank">(http://www.barnesjewish.org/patients-visitors/lodging-directory)</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic5;
