import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTL4_1_1 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Laryngectomy Stoma: Caring for your Laryngectomy Stoma'}, () => {
            console.log('Laryngectomy Stoma: Caring for your Laryngectomy Stoma');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Laryngectomy Stoma</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader text-center w-100">Care of your Laryngectomy Stoma</h3>
                                            <p>At home, you need to care for your laryngectomy stoma to keep it clean
                                                and healthy. As part of that, you may wear a laryngectomy tube to keep
                                                your stoma open. You will have to learn how to remove the tube and clean
                                                it to prevent it from clogging with mucus.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100">When
                                                you are first going home or to a facility:</h2>
                                            <p>Your nurse will bring your discharge instructions from your doctor. Your
                                                nurse will review all instructions with you. If you do not understand
                                                any discharge information, please ask as many questions as you like so
                                                you are confident about what to do when you get home.</p>
                                            <p>Your nurse will bring you detailed instructions about caring for your
                                                laryngectomy stoma.</p>
                                            <p><em><strong>Your laryngectomy caregiver should come to the hospital while you
                                                are here.</strong> He or she should learn how to clean and care for your
                                                stoma, clean any laryngectomy tubes, assist with nutrition, and identify
                                                wound complications.</em></p>
                                        </div>
                                    </div>
                                    <div className="col-12 video floatLeft clearLeft">
                                        <div className="w-100">
                                            <h3 className="videoTitle w-100 text-center text-dark">Caring for your Laryngectomy Stoma</h3>
                                            <div className="floatLeft clearLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="P1bBtCzc-3w" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox legoContructNew">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder">
                                                <p className="floatNone text-center legoHeader">Caring for your
                                                    laryngectomy stoma and laryngectomy tube at home:</p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ol className="BlueNumbers">
                                                            <li>
                                                                <strong>
                                                                    <em>Clean your laryngectomy stoma at least twice a day as instructed by your surgical team.</em>
                                                                </strong><br/>
                                                                Always wash your hands before and after caring for your laryngectomy stoma. Good stoma care, with hand washing, is key to staying healthy and preventing wound infections and wound breakdown. The skin around your stoma needs to be kept clean and dry.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    <em>You should also clean your laryngectomy tube at least twice daily.</em>
                                                                </strong><br/>
                                                                To clean, first wash and dry your hands. Then gently scrub the laryngectomy tube with a soft brush or pipe cleaner, mild soap, and water. Rinse well under running water and pat dry. Apply lubrication to the outside of the laryngectomy tube and insert the laryngectomy tube back into your stoma.
                                                            </li>
                                                            <li>
                                                                <strong>
                                                                    <em>You must keep your laryngectomy tube free of secretion and mucus.</em>
                                                                </strong><br/>
                                                                You should suction your laryngectomy tube at least twice daily (or more if you have a lot of mucus). You will need your suction machine, connection tubing, and a new suction catheter each time. <em>Remember to wash and dry your hands first.</em>

                                                                <ul className="bulletsCustom">
                                                                    <li>Use suction to remove secretions that you cannot cough out. Suctioning will keep your airway clean and make breathing easier.</li>
                                                                    <li>Use humidified oxygen or an HME (heat moisture exchanger) to thin your secretions and moisten the air to allow you to breathe more comfortably.</li>
                                                                </ul>
                                                            </li>
                                                            <li><strong><em>Keep a close watch on the skin around your
                                                                stoma.</em></strong><br/> Watch for redness, heat, odor,
                                                                increased drainage, opening of the incision or drainage
                                                                from the incision when you eat or take your tube feeds.
                                                            </li>
                                                            <li><strong><em>Protect your stoma from water.</em></strong><br/> Follow
                                                                your surgeon’s instructions on caring for your stoma.
                                                                Cover your stoma before you take a shower. You cannot
                                                                submerge your stoma (in a tub, hot tub, pool, lake,
                                                                river, etc.). Water will enter your lungs and you could
                                                                drown.
                                                            </li>
                                                            <li><strong><em>Take action if the air is dusty or
                                                                smoky.</em></strong><br/> Cover your stoma with a scarf,
                                                                tie, specially made cover, or an HME.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100 text-center">Shortness of breath and Mucus Plugs</h2>
                                            <p>If you become short of breath, remove your laryngectomy tube if you have
                                                one.</p>
                                            <p>Suction your laryngectomy stoma to make sure there is no mucus plugging
                                                it and making breathing difficult. If there is no improvement after
                                                suctioning, try using saline bullets and repeat suctioning. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/saline+bullets.jpg" alt="saline bullets image" className="img-responsive maxWidth400 centerImage"/>
                                            <p className="captionItalic text-center">
                                                <strong>NOTE:</strong> Thumb is <strong>NOT</strong> covering the
                                                suction control port during insertion of the catheter. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                <em>If you are still short of breath, call 911 and proceed to the
                                                    nearest emergency room.</em>
                                            </h2>
                                            <p>Make sure the paramedics and emergency medicine team are aware of your
                                                total laryngectomy status and that you can ONLY BREATHE THROUGH YOUR NECK STOMA.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder w-100">
                                                <p className="w-100 text-center boldLeadIn"><strong>Call your Surgeon
                                                    immediately if:</strong></p>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom mb-0">
                                                            <li>You have chills or a temperature above 101&deg; F or 38.5&deg;
                                                                C
                                                            </li>
                                                            <li>Your incision becomes red or warm</li>
                                                            <li>There is an opening in your incision</li>
                                                            <li>There is new or increased drainage from the incision
                                                            </li>
                                                            <li>The drainage from the incision has an odor</li>
                                                            <li>Either calf becomes painful, swollen or tender</li>
                                                            <li>Increasing, persistent swelling of legs or feet</li>
                                                            <li>Increasing pain that is not well-controlled by current
                                                                pain medication
                                                            </li>
                                                            <li>If you remove your laryngectomy tube and you can’t get
                                                                it back in
                                                            </li>
                                                            <li>If a feeding tube in your neck, stomach or nose falls
                                                                out
                                                            </li>
                                                            <li>Your JP drain stitches come loose or break off</li>
                                                            <li>A change in position of the JP tube or your JP drain
                                                                comes out
                                                            </li>
                                                            <li>If you see holes or cracks in the JP tubing or bulb or
                                                                the drain is leaking
                                                            </li>
                                                            <li>If you have questions about your JP drain care</li>
                                                            <li>Poor appetite, not drinking, or generally not feeling
                                                                well
                                                            </li>
                                                            <li className="mb-0">If you have other concerns</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL4_1_1;
