import React from "react";

function L2ComponentCode({title}) {
    title = 'L2 Headline';

    return (

        <div className="lego l002">
            <h2 className="l2Headline">{title}</h2>
        </div>

    )
};


export default L2ComponentCode;