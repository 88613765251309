import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonDisposalWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Medical Waste: Show Me How'}, () => {
            console.log('Medical Waste: Show Me How');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDisposal">Disposal
                                                            of Medical Waste</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/WhatToDoAndSafety#introduction#whatToDo">
                                                    <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/WhatToDoAndSafety#introduction#showMeHow">
                                                    <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/WhatToDoAndSafety#introduction#safetytips">
                                                    <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Get Vaccinated for Hepatitis</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>If the person you care for has Hepatitis B – get vaccinated. The vaccine is a series of three shots given over a period of 6 months.</li>
                                                    <li>If the person you care for has Hepatitis A – get vaccinated. The vaccine is a single shot. A second booster is given 6 to 12 months later to ensure long-term protection.</li>
                                                    <li>There is a combined Hepatitis A and B vaccine, called Twinrix®. It includes 3 doses given initially and then at 3 and 6 months.</li>
                                                    <li>There is no vaccine for Hepatitis C.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn"> Accidental Needle Stick</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>If you accidentally stick yourself with a dirty needle or lancet, stop what you are doing and wash the injured area with soap and water. Rinse thoroughly.</li>
                                                    <li><span className="bold italic">Call your doctor</span>. The doctor may decide to test you to see if you became infected. <span className="bold italic">The first test will test negative</span>, this is the baseline test. You will <span className="bold italic">need to have tests 6-12 months later to determine if you are infected</span>. It takes several months for the infection to be identified.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Storage of Medical Equipment</p>
                                                <p>Any equipment you use to care for someone should be stored correctly.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Store medicines in a clean, dry place, away from humidity or moisture. The kitchen is best.</li>
                                                    <li>Keep syringes, dressing supplies, blood or urine test strips in an airtight container (e.g. plastic storage bin). Store in a cool place like a bedroom closet.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Disposal of Waste</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Know the laws in your community that regulate disposal of waste. You will dispose of dirty needles, syringes, lancets and other sharps in special puncture-proof, plastic sharps containers.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Since the containers are not recyclable, you may be able to dispose of them in the trash. Check with the local trash company to know how to dispose of a container.</li>
                                                    <li>Some hospitals and pharmacies have sharps disposal programs.</li>
                                                    <li>Many waste management companies allow you to mail the containers for disposal.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Learn the health risks from contacting medical waste and how to properly throw away items you use when caring for someone in the home.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="LJQDqHgyCPA" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-10 col-12 imgCol noPaddingLeft">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">

                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="navTopicButtonPageHref">
                                                    <a id="safetytips">SAFETY TIPS</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Clean disposable gloves</li>
                                                    <li>Sharps Container or a large-mouthed plastic bottle (laundry detergent bottle)</li>
                                                    <li>Heavy-duty tape like duct or adhesive tape</li>
                                                    <li>Plastic garbage bags</li>
                                                    <li>Labels</li>
                                                    <li>Detergent</li>
                                                    <li>Chlorine bleach</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            {/*// <hr className="w-100" />*/}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDisposalWhatToDoAndSafety;
