import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL11AComponent({ code, language }) {
    code = `{/* L11A Image Max Width 400px */}
                                    <div className="lego l011 legoMargin3">
                                        <img alt="" className="imageW100 maxWidth400"
                                             src="https://s3-alpha-sig.figma.com/img/107b/de6e/5ae0e11e0de9ce2d1600b0a84fc34adc?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=bBX~AsM0AS1hgyQrgpkvi4tJAvzn6NluI0p6dDp~Uf9OGZfySVv3L6TW2~w2FM6SOUfBIB3LkRMggT7uA2depYszTjXoefOEcpf2Lxmn0Mele~ZXEIOpd9RwGDJMVSrssnOk4fekLy2cjVypEDoBjJVBbDQl4CTKzogjcKQdtZfwaFREOkZ-kXzMU9ZirjECgQFQ1z7VhXEV4-lZg52ke9g2fUYeorLVptlZ7m5Vv2hyF2nscW~OubfeT7HF6lQBkBwAG96dC55rmMmyZafuvJ0Cv~TAxq5sjhYDhprmZ6giXbGRm7QGYzGOXg3u0MmEMvS6nz97CK1T0H9qBc7V3A__"/>
                                    </div>
                                    {/* End L11A Image Max Width 400px */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL11AComponent;