import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll7_6 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center cyanColor cyanBorderColor">Vendors</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong><em>The right supplies, and making sure they are on-hand when needed</em></strong>, are an important part of a safe, successful recovery at home, as well as helping you reach your best outcomes as your journey continues. </p>
                                            <p>Some of the important vendors who serve our patients and families, covering a wide range of treatments, include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Inhealth-tech-logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">InHealth Technologies</h2>
                                                        <p>A manufacturer and distributor of Blom-Singer® voice restoration and ENT products worldwide with 40 years of designing products dedicated to patient health, safety and comfort. </p>
                                                        <p>Toll-Free: <em><a href="tel:8004775969">800.477.5969</a></em><br/>
                                                            Mon-Thu 6:30 - 4pm PST, Fri 6:30-2pm PST</p>
                                                        <p className="resourceLink"><a href="https://inhealth.com/" target="_blank">Inhealth.com</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Atos_Logo_.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">ATOS Medical</h2>
                                                            <p>A worldwide leader in neck stoma and laryngectomy care with a range of high-quality Provox voice and pulmonary rehabilitation devices, allowing for a personalized solution for each user.</p>
                                                            <p>Toll-Free: <em><a href="tel:8002170025">800.217.0025</a></em><br/>
                                                                email: <a href="mailto:info.us@atosmedical.com">info.us@atosmedical.com</a></p>
                                                            <p className="resourceLink"><a href="https://www.atosmedical.com/" target="_blank">atosmedical.com</a></p>
                                                            <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/lumlogo_lw.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Luminaud Inc.</h2>
                                                            <p>A manufacturer and supplier of a wide range of high quality electronic speech equipment and tracheostoma products. </p>
                                                            <p>Toll-Free: <em><a href="tel:800.255.3408">800.255.3408</a></em></p>
                                                            <p className="resourceLink"><a href="https://luminaud.com/" target="_blank">luminaud.com</a></p>
                                                            <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Bruce-Medical-logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Bruce Medical</h2>
                                                            <p>One of America’s most respected medical supply catalog companies with a 100% satisfaction guarantee.</p>
                                                            <p>Toll-Free: <em><a href="tel:8002258446">800.225.8446</a></em></p>
                                                            <p className="resourceLink"><a href="https://www.brucemedical.com/" target="_blank">brucemedical.com</a></p>
                                                            <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Lauder-logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Lauder Enterprises</h2>
                                                            <p>Makers of a range of electrolarnyx and stoma care products as well as accessories and parts. Lauder Enterprises will also service and repair all viable electrolarynx brands and models, including older discontinued models.</p>
                                                            <p>Toll-Free: <em><a href="tel:8003888642">800.388.8642</a></em><br/>
                                                                email: <a href="mailto:info@electrolarynx.com">info@electrolarynx.com</a></p>
                                                            <p className="resourceLink"><a href="https://www.electrolarynx.com/#/" target="_blank">electrolarynx.com</a></p>
                                                            <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Griffin_Lab_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Griffin Laboratories</h2>
                                                            <p>A trusted manufacturer of speech aides and accessories, including the TruTone EMOTE® electrolarynx. Griffin Laboratories also services and repairs electrolarynx and amplifier products. </p>
                                                            <p>Toll-Free: <em><a href="tel:800.330.5969">800.330.5969</a></em></p>
                                                            <p className="resourceLink"><a href="http://www.griffinlab.com/catalog/" target="_blank">griffinlab.com</a></p>
                                                            <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight noMinHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/MedicalWest-logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Medical West Healthcare Center</h2>
                                                            <p>A Missouri-based provider of quality, affordable home healthcare supplies and equipment with a professional staff who will help you find the right solution for meeting your needs and easing your transition home. </p>
                                                            <p><em><a href="tel:3149938100">314-993-8100</a></em></p>
                                                            <p className="resourceLink"><a href="https://www.medicalwest.com/" target="_blank">Medicalwest.com</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"  href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll7_6;
