import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {useNavigate} from 'react-router-dom';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

const LwcInjectionSupportLandingPage = () => {
    const encString = "d519f1f719e136c91c8b9b0e5aaf2cba:a37f987d724728a0069a6ede6271f948aa8d565a578c04bf574eb7fad70be441b586b65879c252f92529f73d07fed5408813cac057c87df28c27e884af540f2fb400570a073317a1e601b45ca566bc00cfdc5882583e4b867949d1d5c6181afbcf170f679d8e6ea391897abe0dfab3a7adc7e10cde004ae2607216622d3cfcc4d4ee85b0290957bf175e678b836cccfa";

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0
        }
    };
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/register/qr/${encString}`;
        navigate(path);
    };

    /*
    if (window?.location.pathname === '/lwcperformance') {
        import('./lwcperformance.scss');
        require('./lwcperformance.scss');
    }
     */
    return (
        <div>
            <div className="w-100">
                <Container className="landingPage">
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="landingPage">
                                        <div className="landingLogo w-100 bg-white">
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/WashU+Ortho.png"
                                                className="img-responsive centerImage w-100 maxWidth300"
                                            />
                                        </div>
                                        <div className="landingHero">
                                            <div className="col-12 landingHero lwcGeneralLandingBackgroundImg">
                                                <div className="w-100 content-center text-center landingHeader">
                                                    <div className="col-12">
                                                        <div className="w-100 d-flex content-center">

                                                        </div>
                                                    </div>
                                                    <h1 className="">
                                                        Receive your{" "}
                                                        <span className="italic">
                                                            Injection Procedure Support Guide
                                                        </span>{" "}
                                                        today!
                                                    </h1>
                                                    <h4 className="">
                                                        This easy-to-use guide walks you through Injection Procedures
                                                        offered by Washington University Orthopedics — including what to
                                                        expect and key information that will help you on your journey
                                                        toward a successful procedure and better health outcomes.
                                                    </h4>
                                                    <Nav.Link>
                                                        <button
                                                            onClick={routeChange}
                                                            className="guideButton"
                                                        >
                                                            Activate My Guide{" "}
                                                            <i className="fa-solid fa-chevron-right"></i>
                                                        </button>
                                                    </Nav.Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="grayBG">
                                            <div className="col-12 graySection">
                                                <div className="w-100 grayContent text-center alignRight">
                                                    <p>
                                                        <AccessTimeIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <br/>
                                                        The{" "}
                                                        <span className="italic">
                                                            Guide
                                                        </span>{" "}
                                                        is your always-there source of information.
                                                    </p>
                                                </div>
                                                <div className="w-100 grayContent text-center alignLeft">
                                                    <p>
                                                        <LaptopMacIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <TabletMacIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <SmartphoneIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <br/>
                                                        Available on your desktop, <br/>
                                                        tablet and smartphone.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 subheadAndBullets footerSection">
                                            <div className="w-100">&nbsp;</div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};
export default LwcInjectionSupportLandingPage;
