import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic15 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Warning Signs</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">IF YOU EXPERIENCE ANY OF THE FOLLOWING,
                                                CALL YOUR SURGEON PROMPTLY:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Chills or fevers of 101° F (38.3°C) or higher</li>
                                                <li>Swelling, warmth, or increased redness around your incision</li>
                                                <li>Drainage of fluid from your incision, especially if it is cloudy, thick or foul smelling</li>
                                                <li>Any sudden increase in abdominal pain or new abdominal pain</li>
                                                <li>Persistent diarrhea, nausea, vomiting or inability to eat and drink or inability to pass gas for longer than 3 days</li>
                                                <li>Your skin or eyes turn yellow, or your urine becomes dark</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Contact Information:</p>
                                            <p>Dr. Fields' office: <strong><em><a href="tel:3142861694">314-286-1694</a></em></strong></p>
                                            <p>Dr. Hawkins' office: <strong><em><a href="tel:3143627046">314-362-7046</a></em></strong></p>
                                            <p>Dr. Strasberg’s office: <strong><em><a href="tel:3143627147">314-362-7147</a></em></strong></p>
                                            <p>Dr. Doyle’s office: <strong><a href="tel:3143622880">314-362-2880</a></strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic15;
