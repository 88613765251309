import { AUTH_ROLE } from "../actions/types";

const INITIAL_STATE = {
  role: "",
};

export const roleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_ROLE:
      return {
        ...state,
        role: action.payload,
      };

    default:
      return state;
  }
};
