import { PAGE_ACCESS, PAGE_ACCESS_ERROR } from "../actions/types";

const INITIAL_STATE = {
    errorMessage: ""
};

export const pageAccessReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PAGE_ACCESS:
            return {
                ...state,
                errorMessage: ''
            };

        case PAGE_ACCESS_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
