import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Alert from '@mui/material/Alert';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    validateOrganization
} from "./validation.js";
import Select from "@material-ui/core/Select";
import {InputWithErrorMessage} from "../forms/inputWithErrorMessage.js";
import styles from "./generateqrcodeurl.module.scss";
import {getOrbitList, getUser} from "../../helpers";
import {orgList} from "../../actions/orgList.js";
import {DEPARTMENT_ADMIN, ORBIT_ADMIN, ORGANIZATION_ADMIN, REGULAR_USER, SYS_ADMIN} from "../../constants/roles.js";
import {orgDepList} from "../../actions/orgDepList.js";
import { requestQrCodeLink } from '../../actions/requestQrCodeLink';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {requestQrCodeLinkForTempUser} from "../../actions/generateQrCodeLinkForTempUser.js";

const GenerateQrCodeUrl = () => {

    const user = JSON.parse(getUser());
    const [organizationId, setOrganizationId] = useState("");
    const [organizationList, setOrganizationList] = useState([]);
    const [orbitIdList, setOrbitIdList] = useState([user.defaultOrbit]);
    const [organizationIdError, setOrganizationIdError] = useState("");
	const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [isDirty, setIsDirty] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const {errorMessage} = useSelector(state => state.register);
    const [urlCreationType, setUrlCreationType] = useState("NonTempBased");
    const {defaultOrbit} = useSelector(state => {
        return state.home
    });

    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {
        setIsActive(current => !current);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitForm = async (e) => {
        let request = null;
        e.preventDefault();

        if (!await validateAllFields())
            return;

        switch (urlCreationType) {
            case "NonTempBased": request = await requestQrCodeLink({
                    organizationId,
                    orbitId: defaultOrbit
                    },
                    (data) => {
                        if(data.error) return;
                        setQrCodeUrl(data.url);
                    }
                )
                await dispatch(request);
                break;
            case "TempBased": request = await requestQrCodeLinkForTempUser({
                        organizationId,
                        orbitId: defaultOrbit
                    },
                    (data) => {
                        if(data.error) return;
                        setQrCodeUrl(data.url);
                    }
                )
                await dispatch(request);
                break;
        }
    }

    useEffect(async () => {
        if (!orbitIdList || orbitIdList.length === 0 || (orbitIdList.length > 0 && (orbitIdList[0]?.length === 0 || orbitIdList[0] === null))) {
            setOrbitIdList([defaultOrbit && defaultOrbit.length > 0 ? defaultOrbit : user?.defaultOrbit]);
        }

        if (user.roles.indexOf(SYS_ADMIN) !== -1) {
            const ol = await orgList({}, (data) => {
                setOrganizationList(data);
            });
            await dispatch(ol);
        }
    }, []);

    const changeOrganization = (e) => {
        setOrganizationId(e.target.value);
    }

    const validateOrganizationField = async (e) => {
        if (user.roles && user.roles.indexOf(SYS_ADMIN) !== -1) {
            await setIsDirty(true);
            await setOrganizationIdError(validateOrganization(organizationId));
        }
    }

    const validateAllFields = async () => {
        await setIsDirty(true);
        let invalidateOrganization = '';

        if (user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1)) {
            invalidateOrganization = validateOrganization(organizationId);
            setOrganizationIdError(invalidateOrganization);
        }

        if (
            invalidateOrganization.length > 0
		) {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <Container className="registration">
                    <div className="pageContainer">
                    <h1 className="text-center title headlineText">Generate QR Code URL</h1>

                    <Box className="material-box" component="form" onSubmit={submitForm} autoComplete="off">
                        <div className="card-body">
                            <div className="formFlexContainer">
                                {
                                    user.roles && user.roles.indexOf(SYS_ADMIN) !== -1 && Array.isArray(organizationList) && organizationList.length > 0 &&
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={organizationIdError}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="NonTempBased"
                                            value={urlCreationType}
                                            onChange={(e) => setUrlCreationType(e.target.value)}
                                            id="urlCreationType"
                                            name="radio-buttons-group">
                                            <FormControlLabel value="NonTempBased" className="text-dark" control={<Radio />} label="Non Temp-based Url" />
                                            <FormControlLabel value="TempBased" className="text-dark" control={<Radio />} label="Temp-Based Url" />
                                        </RadioGroup>

                                        <Select multiple={false} native={true} autoWidth={true} className="w-100"
                                                name="organization" id="organization" key="organization"
                                                label="Organization" value={organizationId} variant="standard"
                                                onChange={changeOrganization} onBlur={validateOrganizationField} style={{paddingTop: "20px"}}>
                                            <option value={0} key="organization0">Please select an organization</option>
                                            {
                                                Array.isArray(organizationList) && organizationList?.length > 0 ? organizationList?.map((item, i) =>
                                                    <option value={item.organizationId} key={"organization" + (i + 1)}>{item.orgName}</option>
                                                ) : ''
                                            }
                                        </Select>
                                    </InputWithErrorMessage>
                                    </div>
                                }

                                {
                                    user.roles && user.roles.indexOf(SYS_ADMIN) !== -1 && Array.isArray(organizationList) && organizationList.length > 0 &&
                                    <div className="buttonContainer loginButtonContainer">
                                        <span style={{color: "#ff0000"}}>{errorMessage}</span>
                                        <Button className="loginUIComp center" type="submit" variant="contained" color="primary" onClick={handleClick}
                                                disabled={errorMessage?.length > 0}>
                                            Generate URL
                                        </Button>
                                    </div>
                                }

								{ qrCodeUrl.length > 0 ?
                                    <div className="card qrCard">
                                        <div className="card-title">
                                            <h2>Copy the entire URL below and paste into your QR Code generator:</h2>
                                        </div>
                                        <div className="card-body">
										    <span className="text-dark w-100">{qrCodeUrl}</span>
                                        </div>
									</div>
									:
									''
								}
                            </div>
                        </div>
                    </Box>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default GenerateQrCodeUrl;
