import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";
const LessonWanderingWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWandering">Wandering</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering2.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Signs of Wandering</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Be alert to signs that a person may begin to wander or become lost.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">For example, a person:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Takes longer than usual to return from a routine walk or drive</li>
                                            <li>Tries to go to a job they no longer have</li>
                                            <li>Talks about wanting to go home when they are at home</li>
                                            <li>Has trouble finding familiar places, like their bathroom or bedroom</li>
                                            <li>Follows their loved one around or always needs to know where they are</li>
                                            <li>Acts as if they are working on something, but nothing gets done</li>
                                            <li>Appears lost in a new or changed environment</li>
                                            <li>Becomes easily upset when in a new or changed environment</li>
                                            <li>Expresses a feeling of uselessness, so they just want to “get away”</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering3.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Causes for Wandering
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Causes for Wandering</p>
                                        <p>Wandering may be a symptom of a disease causing moderate dementia, a progressive change in the ability of the brain to think and reason. If someone shows signs of wandering, they must be evaluated by their doctor. This is very important if they have not already been diagnosed with a disease that causes dementia (e.g. Alzheimer’s Disease, multi-infarct or vascular dementia, and Pick’s Disease).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering4.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Watch for changes in their behavior. If wandering begins, increases, or decreases it may be due to a number of causes that need to be checked and possibly treated.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">These include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Illness or infection</li>
                                            <li>A side effect from a medicine</li>
                                            <li>Hunger or thirst</li>
                                            <li>Needing to use the bathroom</li>
                                            <li>Pain or constipation</li>
                                            <li>Being too warm or cold</li>
                                            <li>Boredom, feeling useless</li>
                                            <li>Too much going on around them</li>
                                            <li>A new home or different daily routine (e.g. starting adult day care)</li>
                                            <li>A change in their regular routine that causes further confusion, such as a hospital stay</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering5.png" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">Brain disease causes other problems that may cause people to wander:
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Confusion related to time or trouble telling night from day</li>
                                            <li>Inability to recognize familiar people, places, and things</li>
                                            <li>Forgetting they no longer have the same responsibilities (e.g. going to work, taking care of a child)</li>
                                            <li>Fear or anxiety when they do not understand a situation or they are in an unfamiliar place</li>
                                            <li>Disorientation to where they are, making them feel lost</li>
                                            <li>Inability to tell the difference between dreams or television and reality</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder w-100">
                                        <h2 className="subheadUpperAndLower withBodyCopy"><i class="fa-solid fa-caret-right"></i>&nbsp;If wandering is simply a part of a person’s new “normal” behavior, it may actually help him or her to relieve stress.</h2>
                                        <p>In this case, allow the person to wander, with supervision in a place you have made safe for them. Otherwise, a different and more troubling behavior might begin if you do not let the person wander. This is just another reason why it is important to talk with their doctor about wandering.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 backToTop footer blueBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWanderingWhatYouNeedToKnow;