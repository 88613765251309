import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll1_5 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center orangeColor orangeColorBorder">Key
                                                Questions</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 d-flex">
                                            <div className="col-6">
                                                <p className="boldLeadIn">You are not alone.</p>
                                                <p>Today, there are hundreds of thousands of people around the world who
                                                    have undergone their total laryngectomy.</p>
                                                <p>In this region, there have been many patients and families who have
                                                    found themselves on journeys similar to the one you face. As people
                                                    who have experienced surgery, chemotherapy,radiation, and recovery,
                                                    they can be incredible sources of relevant advice, useful everyday
                                                    guidance and compassionate understanding.</p>
                                                <p>In fact, past patients and family members are actually part of the
                                                    team that is there for you throughout your journey here. Be sure to
                                                    speak with your care team to know how you can best reach out to
                                                    others who may have shared some parts of your experience.</p>
                                            </div>
                                            <div className="col-6">
                                                <div className="w-100">
                                                    <div className="sidbarFill entBrightOrangeBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Nu Voice Club of Greater St. Louis</h2>
                                                        <p>The Nu Voice Club of Greater St. Louis hosts social and education
                                                            events throughout the year (and even help in the early days
                                                            with new patients to give an insider’s perspective on what
                                                            you may face, and how they have succeeded).</p>
                                                        <p>You can find out more at <a href="https://nuvoicestl.com"
                                                                                       target="_blank"> nuvoicestl.com</a> and
                                                            be sure to get on their mailing list for upcoming events by
                                                            emailing them at <a
                                                                href="mailto:nuvoicestl@gmail.com">nuvoicestl@gmail.com</a>
                                                        </p>
                                                        <img className="img-responsive"
                                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nu_voice_club_stl.jpg" alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 video">
                                        <div className="w-100">
                                            <h3 className="videoTitle w-100">
                                                What I would tell a newly-diagnosed person.
                                            </h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg"
                                                         className="img-responsive w-100"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 video">
                                        <div className="w-100">
                                            <h3 className="videoTitle w-100">
                                                What helped us the most.
                                            </h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg"
                                                         className="img-responsive w-100"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 video">
                                        <div className="w-100">
                                            <h3 className="videoTitle w-100">
                                                Our challenges and how we dealt with them.
                                            </h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg"
                                                         className="img-responsive w-100"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 video">
                                        <div className="w-100">
                                            <h3 className="videoTitle w-100">
                                                Managing expectations.
                                            </h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Faux_video_nurse_1.jpg"
                                                         className="img-responsive w-100"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100 orangeColorBorder"/>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll1_5;
