import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonTemperatureFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonTemperature">Taking a Temperature</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonTemperature/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonTemperature/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What is the best route for taking a temperature?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The answer to this question depends on the age of the person you care for and whether they have any other problems. Generally speaking, taking a temperature by mouth is the easiest and most common route for adults. A rectal temperature is the most accurate for infants and young children.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">I still have a mercury thermometer at home, should I use it?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>No. Mercury is a hazardous material that can be toxic when exposed to the skin. Glass thermometers break easily releasing mercury. Contact your local health department to determine how to dispose of a mercury thermometer. Call poison control immediately if you break a glass thermometer.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">When a person has a fever, sometimes they are also sick to their stomach.<br />Can I still give them liquids?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Yes, but give them clear liquids like:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>water</li>
                                            <li>clear juices (apple and cranberry)</li>
                                            <li>decaffeinated tea or coffee</li>
                                            <li>Popsicles and ice chips work well to hydrate a person who is nauseated. Caffeine drinks sometimes add to nausea and will increase the loss of fluids from the body.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="navTopicButtonPageHref">
                                    <a id="resources">RESOURCES</a>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/cdc_logo.jpg" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">Centers for Disease Control and Prevention (CDC)</h2>
                                                <p>CDC is one component of the Department of Health and Human Services within the US government. Website contains a wide range of health topics.</p>
                                                <p className="resourceLink">visit: <a href="https://www.cdc.gov/" target="_blank">www.cdc.gov</a>
                                                </p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">Medline Plus</h2>
                                                <p>This resource will direct you to information to help answer health questions about body temperature.</p>
                                                <p className="resourceLink">visit: <a href="https://medlineplus.gov/" target="_blank">www.medlineplus.gov</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonTemperatureFAQResources;