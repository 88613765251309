import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
const LessonWanderingIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWandering">Wandering</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation ">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWandering/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWandering/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WN_wandering1.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Wandering refers to moving about without having a definite place to go or a purpose. Over 60% of people with diseases that cause dementia, or memory and thinking problems (like Alzheimer’s Disease), will wander at some point.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">A person who wanders may:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Be restless</li>
                                            <li>Pace back and forth</li>
                                            <li>Show repetitive movement</li>
                                            <li>Walk around their house, yard or neighborhood</li>
                                            <li>Be aimless or busy, but not productive</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Even people with only mild dementia can have this problem and it’s sometimes harder to deal with because they can still do many things normally.</p>
                                        <p>A person with dementia may have a reason to wander that is not obvious to you, the observer. For example, a person will become agitated because they cannot remember what they want to do, so they walk around hoping that will help them remember.</p>
                                        <p>Wandering may develop in response to a physical illness or emotional problem. Wandering may also just be a symptom of the person’s dementia. If you care for a person who wanders, this lesson will help you sort out these issues.</p>
                                        <p>Left unrestricted and unsupervised, wandering can be dangerous. It is important to know the warning signs that tell you a person may begin to wander. This lesson will also give you suggestions for reducing wandering and for keeping the person you care for safe.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Over 60% of people with diseases that cause dementia, or memory and thinking problems like Alzheimer's, will wander.</p>
                                    </div>
                                </div>
                                <div className="col-12 video">
                                    <div className="w-100">
                                        <h3 className="blueColor videoTitle width100">Video: Wandering</h3>
                                        <div className="floatLeft width100 captionBelow">
                                            <div className="embedContainer originalPadding">
                                                <YouTube videoId="vMFW437W4OY" opts={opts} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWanderingIntroduction;