import React from "react";

function L53BComponentCode() {

    return (

        <div className="lego l053 customBulletList">
            <ul className="w-100">
                <li className="w-100">Stop or reduce pain.</li>
                <li className="w-100">Improve function.</li>
                <li className="w-100">Reduce the number of pain medications taken.
                </li>
                <li className="w-100">Avoid or delay surgery.</li>
            </ul>
        </div>

    )
};


export default L53BComponentCode;