import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll4_2_7 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Emotional
                                        Health</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className=" w-100">
                                    <p className="floatNone clearNone">The Siteman
                                        Cancer Center provides state-of-the-art medical care.
                                    </p>
                                    <p className="floatNone clearNone">And because
                                        we believe in treating the whole patient, we also provide
                                        counseling support to help patients and families through the
                                        cancer journey at <a href="https://siteman.wustl.edu/treatment/siteman-psychology-service/" target="_blank">Siteman Psychology Service ></a>.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className=" w-100">
                                    <img className="img-responsive maxWidth400 centerImage" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/102007.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p><span className="bold italic">Our psychologists:</span></p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Provide education, counseling and referrals to specific resources.
                                        </li>
                                        <li>Teach coping skills, help with problem solving and provide an outlet
                                            for talking about difficulties.
                                        </li>
                                        <li>Offer counseling services to address grief or concerns for couples
                                            or families.
                                        </li>
                                        <li>Help connect patients with support groups, peer support and
                                            community resources.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>To fully meet patient and family needs, we collaborate with specialists
                                        in case management, financial counseling, genetic counseling and
                                        spiritual care. We also work with the Oncologic Psychiatry Service,
                                        which provides treatment for Siteman patients who need psychiatric
                                        care.</p>
                                    <p>Counseling services are also available on a limited basis at our
                                        satellite facilities in <a href="https://siteman.wustl.edu/visiting/west-county/" target="_blank">West St. Louis County</a> and <a href="https://siteman.wustl.edu/visiting/south/" target="_blank">South
                                            St. Louis County</a> and in <a href="https://siteman.wustl.edu/visiting/st-charles-county/" target="_blank">St. Charles County.</a></p>
                                    <p>Cancer patients and their family members can schedule an appointment to
                                        talk about their situation and discuss which services might suit their
                                        needs best. One appointment may be all that is needed to receive the
                                        necessary support.</p>
                                    <p>For more information or to schedule an appointment, call <a href="tel:3147475587">314-747-5587</a> or
                                        visit the <a href="https://siteman.wustl.edu/visiting/barnard-health-cancer-information-center" target="_blank">Barnard Health and Cancer Information Center</a> on Siteman’s main
                                        campus in the Central West End area of St. Louis.</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Support Programs</h2>
                                    <p>The Siteman Cancer Center offers a variety of support programs for
                                        patients and families at all of our locations. These programs range from
                                        support groups and exercise programs to educational sessions and pet
                                        therapy. Due to Covid-19 safety restrictions, many of these services
                                        have not been able to take place in-person. To continue supporting our
                                        patients on their journey, we have reshaped many of our services into a
                                        virtual format.</p>
                                    <p><a href="https://siteman.wustl.edu/visiting/patient-and-family-services/virtual-support-services/" target="_blank">Virtual Support Services ></a></p>
                                    <p>We also partner with local organizations to offer cancer support groups
                                        for patients and families. Learn more about those groups by clicking
                                        below:</p>
                                    <p><a href="https://www.cancer.org/treatment/support-programs-and-services.html" target="_blank">American Cancer Society Support Programs and Services
                                            ></a></p>
                                    <p><a href="http://www.cancersupportstl.org/get-support/our-program" target="_blank">Cancer Support Community Support Programs and Services
                                            ></a></p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll4_2_7;