import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'

const SitemanPancreaticAll2_3_3 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Criteria for
                                                discharge</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="w-100">Your discharge from the hospital is based on achieving
                                                this progress
                                                during your stay:</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between w-100 col-12">
                                        <div className="col-5 coloredContentBox minHeight240">
                                            <div className="w-100">
                                                <div
                                                    className="roundedColorBackgroundBoxNoBackgroundPriority w-100 blueBorder paleBlueBackground text-center minHeight240">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadAndCopyWithIcon text-black">
                                                                <i className="fa fa-square-check hightLight text-white"></i>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperAndLower text-white">Any acute medical
                                                                issues are
                                                                resolved or stable</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 coloredContentBox minHeight240">
                                            <div className="w-100">
                                                <div
                                                    className="roundedColorBackgroundBoxNoBackgroundPriority w-100 blueBorder paleBlueBackground text-center minHeight240">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadAndCopyWithIcon text-black">
                                                                <i className="fa fa-square-check hightLight text-white"></i>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperAndLower text-white">Passing gas, tolerating
                                                                food</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between w-100 col-12">
                                        <div className="col-5 coloredContentBox minHeight240">
                                            <div className="w-100">
                                                <div
                                                    className="roundedColorBackgroundBoxNoBackgroundPriority w-100 blueBorder paleBlueBackground text-center minHeight240">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadAndCopyWithIcon text-black">
                                                                <i className="fa fa-square-check hightLight text-white"></i>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperAndLower text-white">Urinating on your
                                                                own</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5 coloredContentBox minHeight240">
                                            <div className="w-100">
                                                <div
                                                    className="roundedColorBackgroundBoxNoBackgroundPriority w-100 blueBorder paleBlueBackground text-center minHeight240">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadAndCopyWithIcon text-black">
                                                                <i className="fa fa-square-check hightLight text-white"></i>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <h2 className="subheadUpperAndLower text-white">Able to complete
                                                                activities of daily living with minimal assistance
                                                                (walking to the bathroom, dressing self, feeding
                                                                self)</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your discharge from the hospital is based on achieving this progress
                                                during your stay:</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Options at discharge:
                                                Home health, Skilled Nursing Facilities (SNF), Rehab</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your ability to perform those listed criteria helps determine what
                                                services you may need when you are discharged.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Needed services can range from going home with home health nurses
                                                visiting you periodically to needing to enter a Skilled Nursing Facility
                                                (SNF) or a period of time until you’ve achieved more progress.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your case manager and/or social worker will work with you to determine
                                                what is the most appropriate choice for you.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">When discharged, you will
                                                receive:</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">A PACKET OF KEY INFORMATION</p>
                                            <p>On when and how to contact your physician, necessary wound and/or drain
                                                care and discharge medications.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">YOUR DISCHARGE PRESCRIPTIONS</p>
                                            <p>The Outpatient Pharmacy at Barnes Jewish Hospital will deliver
                                                medications to your room before you go home. This also gives you a good
                                                opportunity to get medication questions answered by a pharmacist,
                                                resolve any insurance issues, and discover any potential interactions
                                                before leaving the hospital. The program is available from 9am to 9pm
                                                seven days per week. Typical medications may include pain relievers,
                                                stool softeners and stomach acid blockers. In most cases all of your
                                                home medications will be resumed.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox minHeight240">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 blueBorder paleBlueBackground text-center">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperAndLower text-white">The Outpatient Pharmacy
                                                            program is available from 9am to 9pm seven days per
                                                            week.</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">GENERAL SCHEDULE FOR FOLLOW UP</p>
                                            <p>Keep follow up information and relevant phone numbers handy; it is
                                                helpful to post on your refrigerator. You should expect a return
                                                appointment with your surgeon 2-4 weeks after your surgery. This
                                                appointment will be made with you at the time of discharge.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox minHeight240">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 blueBorder paleBlueBackground text-center">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperAndLower text-white">Expect a return appointment 2-4 weeks after your surgery</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll2_3_3;
