import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Box from '@mui/material/Box';
import { HashLink as Link } from 'react-router-hash-link';
//import {useNavigate, useParams} from "react-router-dom";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
library.add(fab, fas, far);
const LessonInfectionWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInfection">Infection Control</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>If the person you care for has signs of an infection, <strong><em>do not wait for your next visit to the Doctor’s office</em></strong>. Call and report what you have observed.</li>
                                            <li>If you are caring for a person with a wound, keep a record of what the wound looks like. Tell the doctor if there is any redness, swelling, tenderness or drainage. Try to estimate how much drainage by the number of times you changed a dressing. Describe the color, amount, and odor of the drainage.</li>
                                            <li>If a person has a serious infection, there is usually a change in their behavior. Tell the doctor about any change in the person’s behavior:</li>
                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent">
                                                <li>Change in appetite</li>
                                                <li>Feels sleepy or tired all of the time</li>
                                                <li>Has trouble completing routine tasks at home</li>
                                            </ul>
                                            <li>If you have been taking the person’s temperature – keep a record and report your results to the doctor.</li>
                                            <li>Tell the doctor about anything you have done to help control a person’s fever. Report the amount and type of fluids you have given the person.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    {/*
                                    <hr className="w-100" />*/}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInfectionWhatToTellYourDoctor;