export const validateUserName = (userName) => {
    if (!userName || !(userName.length >= 4 && userName.length <= 255))
        return 'UserName field should be between 4 and 255 characters long.';

    return '';
}

export const validateFirstName = (firstName) => {
    if (!firstName || !(firstName.length >= 1 && firstName.length <= 50))
        return 'First name field should be between 1 and 50 characters long.';

    if (!(/^[a-z\d\-_\s]+$/i.test(firstName)))
        return 'An incorrect character was entered into this field, allowable characters.  [a-z, A-Z, 0-9, space character and underscore are allowed]';

    return '';
}

export const validateLastName = (lastName) => {
    if (!lastName || !(lastName.length >= 1 && lastName.length <= 50))
        return 'Last name field should be between 1 and 50 characters long.';

    if (!(/^[a-z\d\-_\s]+$/i.test(lastName)))
        return 'An incorrect character was entered into this field, allowable characters.  [a-z, A-Z, 0-9, space character and underscore are allowed]';

    return '';
}

export const validateEmailAddress = (emailAddress) => {
    const mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailAddress || !(emailAddress.length <= 255))
        return 'Email address field should be between 1 and 255 characters long.';

    if (!emailAddress.match(mailFormat))
        return 'You must enter a valid email address.';

    return '';
}

export const validatePhoneNumber = (phoneNumber) => {
    const phoneNo = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phoneNumber || !phoneNumber.match(phoneNo))
        return 'You must enter a valid phone number.';

    return '';
}

export const validatePassword = (password) => {
    if (!password || !(password.length >= 6 && password.length <= 255))
        return 'Password field should be between 6 and 255 characters long.';

    return '';
}

export const validateConfirmPassword = (password, confirmPassword) => {
    if (!confirmPassword || !(confirmPassword.length >= 6 && confirmPassword.length <= 255))
        return 'The confirm password field should be between 6 and 255 characters long.';

    if (password !== confirmPassword && confirmPassword.length > 0)
        return 'The password and confirmPassword fields are not equal.'

    return '';
}

export const validatePasswordProfile = (password) => {
    if (password && (password.length < 6 || password.length > 255))
        return 'Password field should be between 6 and 255 characters long.';

    return '';
}

export const validateConfirmPasswordProfile = (password, confirmPassword) => {
    if (confirmPassword && (confirmPassword.length < 6 || confirmPassword.length > 255))
        return 'The confirm password field should be between 6 and 255 characters long.';

    if (password !== confirmPassword && confirmPassword.length > 0)
        return 'The password and confirmPassword fields are not equal.'

    return '';
}
