import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {useEffectForVideoAccess, videoAccessLog} from "../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin4_3 = () => {

    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Involving Families'}, () => {
            console.log('Involving Families');
        });
        await dispatch(pal);
    }

    // const playVideo2 = async (e) => {
    //     const pal = await videoAccessLog({videoName: 'Pritikin video 4-3 2nd video'}, () => {
    //         console.log('Pritikin video 4-3 2nd video');
    //     });
    //     await dispatch(pal);
    // }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorBrightGreen pritikinBorderBrightGreen">Involving Your Family</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="imageLeftCopyRight contentCentered">
                                    <div className="imgCol">
                                        <img className="floatLeft wrapImage"
                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/happy-young-man-talking-with-middle-aged-father-at-home-2HBD2RY.jpg"
                                             alt=""/>
                                    </div>
                                    <div className="typeCol">
                                        <p className="floatNone clearNone boldLeadIn">To help you and your family get the absolute most from your rehab, a loved one or support person is encouraged to attend each ICR session to learn how they can be involved to motivate and support you on your journey.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>They are invited to get the full advantage from Pritikin’s comprehensive learning model consisting of immersive workshops, cooking classes, and engaging videos.</p>
                                    <p>And, since your cardiac rehab plan is tailored to your specific needs, everything shared with you can be used to help you directly on your road to lifelong health!</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps w-100 text-center pritikinColorBrightGreen">COMMITTING TO A NEW LIFESTYLE IS MUCH EASIER WHEN YOU INVOLVE OTHERS.</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>As you move further away from your cardiac event, it’s important to regularly remind yourself of the <span className="bold">reasons you want to improve your health</span> – and make sure you have all the support you need to succeed!</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 d-flex justify-content-center">
                                    <h2 className="subheadUpperAndLower w-100 pritikinColorBrightGreen w-auto spacer36px"><i className="fa fa-chevron-right pritikinColorBrightGreen "></i>&nbsp;Enlisting the support of your spouse, partner, family, and friends is a natural way to receive the encouragement you need to stay fully engaged and committed to your health.</h2>
                                    <br/><br/>
                                </div>
                            </div>
                            {/*<div className="col-12">*/}
                            {/*    <div className="w-100">*/}
                            {/*        <h2 className="subheadUpperAndLower text-center w-100">What Families Think About Pritikin ICR</h2>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="videoTitle w-100 text-center spacer36px text-dark">
                                        What Families Think About Pritikin ICR
                                    </h3>
                                    {/*Start This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="5oQiDJ8tOOc" opts={opts} onPlay={playVideo}/>
                                        </div>
                                    </div>
                                    {/*End This is a duplicate video from page 1-1. Needs replaced Add for testing reporting*/}
                                    {/*<p className="caption w-100 text-center">The difference Pritikin ICR has made to me.</p>*/}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 quotesNewLego">
                                <div className="w-100">
                                    <p className="quote">“I am in a much better place today because of the program than I was before. It’s made a positive impact on my life, on my family’s life. It’s just a shame I had to have a heart attack to get there.”</p>
                                    <p className="name">— Don B, Cardiac Rehab Patient</p>
                                </div>
                                <div className="w-100 ruleWidth66">
                                    <hr className="quoteRule" />
                                </div>
                            </div>
                            <div className="col-12 quotesNewLego">
                                <div className="w-100">
                                    <p className="quote">“This has not only been a life change, but it has been hope for me and also my family. That’s powerful.”</p>
                                    <p className="name">— Ron W., Cardiac Rehab Patient</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default Pritikin4_3;