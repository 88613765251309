import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll9_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Logistics and Concierge</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>We provide a variety of services and information to help you navigate and take advantage of our facility’s amenities.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">Concierge Services</h2>
                                            </div>
                                            <div className="withBorder entBaseBlueBorderColor">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <p>While visiting patients at Barnes-Jewish Hospital, concierge representatives are available to help assist you with information, services and special requests, including: </p>
                                                        <div className="col-12 d-flex">
                                                        <div className="col-6">
                                                            <p className="bold">Bank locations
                                                                <br/>Cafeterias and restaurants
                                                                <br/>Campus maps and directions
                                                                <br/>Hotels and long-term
                                                                accommodations
                                                                <br/>Local attractions
                                                                <br/>Laundry facilities</p>
                                                        </div>
                                                        <div className="col-6">
                                                            <p className="bold">Parks
                                                                <br/>Pharmacies nearby
                                                                <br/>Postal services
                                                                <br/>Shopping
                                                                <br/>Shower facilities
                                                                <br/>Transportation services</p>
                                                        </div>
                                                        </div>
                                                        <p>Concierge assistance is offered at no charge. Services provided by outside vendors may include a fee. Concierge representatives are located at the information desks on the hospital’s South Campus and in the Center for Advanced Medicine.</p>
                                                        <p className="text-left w-100"><span><a href="tel:3143625196">314-362-5196</a></span>&nbsp;&nbsp;&nbsp; <a href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a></p>
                                                        <p><span className="bold">More information on </span><a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Hospitality Services</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">MEET AND GREET ESCORTS</h2>
                                            <p><em><a href="tel:3143625194">314-362-5194</a></em> or <em>toll-free <a href="tel:8004514892">800-451-4892</a></em><br/> We can help make your visit more pleasant and convenient by having someone meet you at the door, escort you to your appointment and help you with any other special guest services. This free service is available to anyone.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">OTHER SERVICES</h2>
                                            <p className="boldLeadIn">Pharmacy</p>
                                            <p><em><a href="tel:3146579006">314-657-9006</a></em><br/>
                                                <strong>Hours</strong><br/>
                                                Mon - Fri 8:00 am to 5:30 pm, Closed Saturdays, Sundays and most major holidays </p>
                                            <p>Barnes-Jewish Hospital’s outpatient pharmacy provides services for outpatients from Barnes-Jewish Hospital’s south and north campuses, for outpatients from the Center for Advanced Medicine physician offices, and for BJC HealthCare employees and their dependents. <a target="_blank" href="https://www.barnesjewish.org/patients-visitors/pharmacy">Barnesjewish.org/patients-visitors/pharmacy</a></p>
                                            <p className="boldLeadIn">Sending Flowers or Gifts to Patients</p>
                                            <p><em><a href="tel:3143624300">314-362-4300</a> or <a href="tel:8004094485">800-409-4485</a></em></p>
                                            <p>To send a gift to a patient during this hospital stay, contact our gift shop at the numbers listed, or go online to order. <a target="_blank" href="https://www.barnesjewish.org/Patients-Visitors/Gifts-and-Flowers">shop.barnesjewish.org</a></p>
                                            <p className="boldLeadIn">Internet Access</p>
                                            <p>Patients, family members and guests can enjoy the Internet from most patient rooms at Barnes-Jewish Hospital. Service is free and can be accessed with most properly configured wireless devices. Patients and visitors without access to a wireless device can take advantage of free Internet access in our <a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Concierge Services</a> offices.</p>
                                            <p className="boldLeadIn">Computers and Office Services</p>
                                            <p>Computers are available for use in the <a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Concierge Services</a> offices on the South campus and in the Center for Advanced Medicine. Both offer, at no charge, Internet service, printing, email and other office services.</p>
                                            <p className="boldLeadIn">Laptop and DVD Loaner Program</p>
                                            <p><em><a href="tel:3143625196">314-362-5196</a></em><br/>
                                                Laptop computers, ipads and a selection of DVD shows are available for loan with a current ID. Please see Concierge Services or call for availability Monday through Friday.</p>
                                            <p className="boldLeadIn">Bank and ATMs</p>
                                            <p>A full-service U.S. Bank branch is located on the first floor of Barnes-Jewish Hospital North adjacent to the Center for Advanced Medicine</p>
                                            <p>The bank is open 8 am to 4 pm Mon — Fri.</p>
                                            <p>An automatic teller machine (ATM) is located near the entrance to the bank. In addition, ATMs can be found in the lobby of the Center for Advanced Medicine and on the street level of Barnes-Jewish Hospital South near the lobby.</p>
                                            <p className="boldLeadIn">Lost & Found</p>

                                            <p>To report a lost or found item, call <a href="tel:3147475678">314-747-LOST (5678)</a> or security at <a href="tel:3143620750">314-362-0750</a>. We will make every effort to locate a patient’s lost property; however we are not responsible for lost or stolen property.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"  href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>


    )
};

export default SitemanENTAll9_3;
