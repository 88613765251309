import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic12 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Nutrition During Your Stay</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">NUTRITION IN THE HOSPITAL</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nutrition_stay_image.jpg" alt=""/>
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="mobileMarginBottom30">After your surgery, you will be seen by a registered dietitian who is familiar with your needs. The following is a review of key facts and understandings that will help improve your progress both during your stay and at home.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>Proper nutrition and staying hydrated is very vital to healing after any surgery.</strong> Pancreas surgery is no different. Weight loss after surgery is common with patients typically losing 5 to 10% of their body weight.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">GENERAL GUIDELINES</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>When you start passing gas, this is a sign that the GI tract is awake and you are ready to start consuming food!</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Your appetite will initially decrease after surgery. You will not necessarily feel like eating, so you will probably have to make yourself eat.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will feel full quickly with very small amounts of food. It helps to eat 6 small meals throughout the day rather than the standard 3 large meals per day.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Each day, drink at least four to five 8 oz. glasses of non-caffeinated, non-carbonated beverages.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Fill up on foods rich in protein first, then add in fruits, vegetables, and carbohydrates.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Limit consumption of raw fruits and vegetables initially. Gradually increase as tolerated.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Avoid greasy and fried foods.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Altered taste is a very common complaint after pancreas surgery. Things you enjoyed eating before surgery may no longer appeal to you. This can last weeks to months.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">HOW YOUR DIET PROGRESSES AS YOU HEAL</h2>
                                            <p>While in the hospital, your diet will change as you reach certain milestones. During your healing, your GI tract won’t initially be able to function as normal. The diet progression is designed to best ease you to a healthy outcome throughout the healing process from Day 1.</p>
                                            <p>Starting the day after your surgery, your diet should follow this sequence:</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar">“Post-Whipple 1” Diet:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>This is the first diet you will be on. It is essentially <strong>a clear liquid diet in smaller portions.</strong> You will be able to have broth, clear juices, water, ice chips, Jell-O and popsicles on this diet. Remember to go slow on this part. You will probably have been without food in your stomach for 2 or 3 days so at this point, it will not take a lot to make you feel full.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar">“Post-Whipple 2” Diet:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Once you have had the Post Whipple 1 diet successfully, meaning you did not vomit or are not bloated and belching. The Post Whipple 2 diet <strong>introduces you to soft foods</strong> such as scrambled eggs, chicken noodle soup, pudding, mashed potatoes, tuna and chicken salads etc.</p>
                                            <p><strong>It is very important you eat the items high in protein first.</strong> Protein is what helps you to heal after surgery. Since you get full faster now, if you fill up on the carbs in mashed potatoes, first you’ll get minimal protein and you lose the healing power that you could have had if you had eaten the tuna salad first!</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar">“Post-Whipple 3” Diet:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Congratulations, you have graduated to the diet you will be on when you go home! This diet is <strong>smaller portions of a regular heart healthy diet</strong>. That means 6 to 8 low-fat, high protein snacks during the day.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">USING SNACKS AND SUPPLEMENTS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Remember, once you’ve reached your post-Whipple diet, you are now supposed to be eating 6 small meals per day.</p>
                                            <p>Breakfast, lunch and dinner will be delivered to your room by our catering associates. The other 3 meals will be offered by your nurses in the form of high protein snacks and supplements. <strong>Make sure you ask for at least 3 snacks or supplements daily</strong> to reach your goal of six small meals. Snacks and supplements are available to you at all times.</p>
                                            <p><strong>Available Snacks:</strong> Graham crackers with peanut butter, cottage cheese, yogurt, pudding, Jell-O, juice, milk, cereal, popsicles, ice cream, cheese slices, and soup.</p>
                                            <p><strong>Supplements:</strong> Supplements are a fantastic source of vitamins and protein. We carry Glucerna, Boost, Ensure, Carnation, and Impact in various flavors.</p>
                                            <p>Aim to drink 2 of these per day.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">TAKING ENZYMES (CREON, ZENPEP, VIOKACE, PANCREALIPASE)</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A healthy pancreas secretes a variety of enzymes that aid in the digestion of your food. When your pancreas is not functioning correctly you may need to supplement your digestion with enzyme pills to improve digestion and absorption of food and thus improving healing.</p>
                                            <p><strong>Signs you need enzymes:</strong> You are having greasy, oily stools that float in the toilet rather than sink, accompanied by weight loss because you are not absorbing your nutrients properly.</p>
                                            <p><strong>How and when to take:</strong> Take enzymes in the middle of every meal or snack (about 5 minutes after you’ve begun eating). Swallow tablets and capsules intact. The tablets should not be crushed or chewed unless you are directed to do that by a member of your medical team. Enzymes should not be taken at the same time as calcium- or magnesium-containing antacids such as Maalox, Tums, Mylanta or Rolaids.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/simple.jpg" className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic12;
