import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonAspirationWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonAspiration">Aspiration Precaution</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Persons with Difficulty Swallowing (Dysphagia)</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Some of the most common causes of dysphagia are:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Stroke or brain injury</li>
                                                    <li>Serious dementia (such as advanced Alzheimer's disease)</li>
                                                    <li>Cerebral palsy</li>
                                                    <li>Multiple sclerosis</li>
                                                    <li>Parkinson’s disease</li>
                                                    <li>ALS (Lou Gehrig’s disease)</li>
                                                    <li>Muscular dystrophy</li>
                                                    <li>Diabetic neuropathy</li>
                                                    <li>Facial and tongue weakness</li>
                                                    <li>Poor head control</li>
                                                    <li>Head and neck cancer</li>
                                                    <li>Injury to the throat and jaw</li>
                                                    <li>Gastroesophageal Reflux Disease (GRD)</li>
                                                    <li>Tumors, inflammation or spasm of the esophagus</li>
                                                    <li>Scleroderma</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Signs of Dysphagia</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Any of these signs can mean a person has dysphagia:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>A wet sounding voice</li>
                                                    <li>Change in voice</li>
                                                    <li>A weak uncontrolled cough</li>
                                                    <li>Gagging, coughing or choking on food</li>
                                                    <li>Drooling</li>
                                                    <li>Taking a long time to swallow a single mouthful of food</li>
                                                    <li>Pain when swallowing</li>
                                                    <li>Food or liquids come back up though the throat, mouth, or nose after swallowing</li>
                                                    <li>Pain or pressure in the chest, called heartburn</li>
                                                    <li>Pocketing of food in the mouth</li>
                                                    <li>Slow, weak or uncoordinated speech</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonAspirationWhatYouNeedToKnow;
