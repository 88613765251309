import React from "react";

function L83KComponentCode() {

    return (

        <div className="lego l083 legoMargin2">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/food-drink.svg"/>
                <img className="overlay dontOverlay"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/dontOverlay.svg"/>
            </div>
            <div className="contentContainer">
                <p>Please&nbsp;<span className="boldItalic">DO NOT eat or drink anything for 6 hours prior</span>&nbsp;to your procedure. You may have small sips of clear liquid up to 2 hours prior to the procedure.</p>
            </div>
        </div>

    )
};


export default L83KComponentCode;