import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonDrainageFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDrainage">Care of Drainage Bags</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDrainage/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDrainage/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">I notice that there is some redness at the drain insertion site and it has not changed since the drain was placed is that ok?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Some redness is to be expected when the skin is injured. However redness along with one or more of the following, fever, swelling or tenderness near the wound or insertion site, should be reported to the doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">After 2 weeks the drain fell out.<br />
                                                    My doctor told me to observe the site for swelling, but he thought all the drainage from the wound was finished. Should I worry?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Observing the site for swelling is a good way to determine if there is more drainage. If during the two week period the drainage gradually subsided, that is an indicator that the wound was healing. Because of the wound healing process, your doctor is probably comfortable not reinserting the drain.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Can I transfer an infection to the person I’m carrying for or myself?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Yes you can, if you touch around the wound or the drainage and then touch another part of the body or touch yourself. If you touch the drainage port to the measuring cup or contaminated dressings, you increase the risk for infection spreading into the wound and drain container. You reduce the risk for infection with good hand washing before and after care and after touching dirty dressings.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AARP_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The American Association for Retired People</h2>
                                                        <p>Includes information on a variety of health topics.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.aarp.org" target="_blank">www.aarp.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Medline Plus</h2>
                                                        <p>Medline Plus brings together authoritative information from NLM, the National Institutes of Health (NIH), and other government agencies and health-related organizations.</p>
                                                        <p className="resourceLink">visit: <a href="https://medlineplus.gov" target="_blank">www.nlm.nih.gov/medlineplus</a></p>
                                                        <p className="resourceLink">The site has information about the Hemovac drainage system.<br /> <a href="https://medlineplus.gov/ency/patientinstructions/000038.htm" target="_blank">http://www.nlm.nih.gov/medlineplus/ency/patientinstructions/000038.htm</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        {/*<img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/drugs.com_logo.png" />*/}
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Drug Information Online</h2>
                                                        <p>Drug information online contains pertinent information related to the Jackson Pratt drainage device. This site includes a Spanish version.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.drugs.com/cg/jackson-pratt-drain-care.html" target="_blank">www.drugs.com/cg/jackson-pratt-drain-care.html</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/EPA_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Environmental Protection Agency</h2>
                                                        <p>Provides information on hazardous and non-hazardous waste.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.epa.gov/environmental-topics/land-waste-and-cleanup-topics" target="_blank">www.epa.gov/osw</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer greenBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDrainageFAQResources;
