import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";
const LessonWalkersIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWalkers">Correct Use of Walkers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation ">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWalkers/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonWalkers/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WLK_person_walker1.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Sometimes a person has difficulty walking because of:</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Poor balance</li>
                                            <li>Weakness in the legs</li>
                                            <li>A healing bone fracture</li>
                                            <li>An unsteady gait</li>
                                            <li>Surgery on the hip or legs</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When this happens, the proper use of an assistive device, such as a walker can help a person walk more safely. A walker provides stability and security in walking.</p>
                                        <p><span class="bold">A doctor or physical therapist should recommend a walker for the person you care for.</span> The doctor will order a visit to a rehabilitation facility or ask that a physical therapist come to your home to evaluate the type of walker that will work best.</p>
                                        <p>The doctor will decide how much weight the person can place on their affected leg. This is called <span class="bold italic">weight bearing status</span>. The type of assistive device used, such as a walker or cane, will be decided by how much weight the person can safely put on their weaker leg. Selection of the right assistive device also depends on a person’s age, strength, coordination, overall health, and ease of being able to move about.</p>
                                        <p>Once you know the type of walker a person needs, the physical therapist or the staff of a medical supply store can help fit the walker correctly. When you care for a person who uses a walker, watch how the person stands and advances the walker. Compare what you observe with what this lesson teaches you about the proper way to use the walker. Then you can offer assistance as needed.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When you care for a person who uses a walker, watch how the person stands and advances the walker.</p>
                                    </div>
                                </div>
                                <div className="col-12 video">
                                    <div className="w-100">
                                        <h3 className="blueColor videoTitle width100">
                                        </h3>
                                        <div className="floatLeft width100 captionBelow">
                                            <div className="embedContainer originalPadding">
                                                <YouTube videoId="c_nnupjuflA" opts={opts} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWalkersIntroduction;