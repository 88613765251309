import {REGISTER_ERROR, REGISTER, REGISTER_BOTTOM_FORM_ERROR} from "../actions/types";

const INITIAL_STATE = {
    errorMessage: '',
    bottomFormErrorMessage: ''
};

export const registerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER:
            return {
                ...state,
                errorMessage: '',
                bottomFormErrorMessage: ''
            };
        case REGISTER_ERROR:
            return {
                ...state,
                errorMessage: action?.payload
            };

        case REGISTER_BOTTOM_FORM_ERROR:
            return {
                ...state,
                bottomFormErrorMessage: action.payload
            };

        default:
            return state;
    }
};
