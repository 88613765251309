import React from 'react';
import Container from 'react-bootstrap/Container';
import {Dropdown, Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanPancreaticAll9_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100">
                                <div className="w-100 col-12 hospitalInfoClusterFlexContainer pancreatic">
                                    <div className="col-4 hospitalInfoFlexLeft wow fadeInLeft animated">
                                        <div className="col-12 paddingLR5 hvr-grow">
                                            <Nav.Link href="/Pancreatic_7-1">
                                                <div
                                                    className="tile t_003 Height1RowTall width1Column sitemanDarkerBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="centerCircle">
                                                            <img alt="" className="img-responsive img-circle"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0041_key-contacts-icon.svg"/>
                                                        </div>
                                                        <div className="textBelowIcon">
                                                            <h3 className="text-center whiteColor">Contact Info ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-12 paddingLR5 hvr-grow">
                                            <Nav.Link href="/Pancreatic_7-3">
                                                <div
                                                    className="tile t_003 Height1RowTall width1Column middleBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="centerCircle">
                                                            <img alt="" className="img-responsive img-circle"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/icon-help-hov.svg"/>
                                                        </div>
                                                        <div className="textBelowIcon">
                                                            <h3 className="text-center whiteColor">Help ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-12 paddingLR5 hvr-grow">
                                            <Nav.Link href="/Pancreatic_7-5">
                                                <div
                                                    className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemaNearbyBackgroundImg pancreatic">
                                                    <div className="contentCenter">
                                                        <div
                                                            className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                            <div className="centeringContainer">
                                                                <div className="text-center">
                                                                    <h3 className="whiteColor text-center">Nearby ></h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                    </div>
                                    <div className="col-8 hospitalInfoFlexRight">
                                        <div className="w-100 wow fadeInRight animated">
                                            <div className="col-6 paddingLR5 hvr-grow">
                                                <Nav.Link target="_blank" href="https://covid19.bjc.org/Portals/0/PDF%20Documents/General%20Updates/530986_C19_ICC_VisitorPolicy.pdf?ver=IyE4mCg3kpElARP9dbDATw%3d%3d">
                                                    <div
                                                        className="tile Height1RowTall width2Column t_009 middleBlueBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center whiteColor">COVID-19<br/>
                                                                    Requirements ></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </div>
                                            <div className="col-6 paddingLR5 hvr-grow">
                                                <Nav.Link target="_blank" href="https://www.google.com/maps/place/Siteman+Cancer+Center/@38.6384816,-90.2656108,17z/data=!4m5!3m4!1s0x87d8b52094fa988d:0xfcfea69622af0ea3!8m2!3d38.6384816!4d-90.2634221">
                                                    <div
                                                        className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemaGoogleMapBackgroundImg pancreatic"
                                                        sitemaGoogleMapBackgroundImg>
                                                        <div className="contentCenter">
                                                            <div
                                                                className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                                <div className="centeringContainer">
                                                                    <div className="text-center">
                                                                        <h3 className="whiteColor text-center">Live
                                                                            Google
                                                                            Map ></h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </div>
                                            <div className="col-12 paddingLR5 hvr-grow">
                                                <Nav.Link href="/Pancreatic_7-4">
                                                    <div
                                                        className="tile t_013 t_013_2x2 tileWithCenterBanner Height2RowTall width2Column sitemanDirectionsMapBackgroundImg">
                                                        <div className="contentCenter">
                                                            <div
                                                                className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                                <div className="centeringContainer">
                                                                    <div className="text-center">
                                                                        <h3 className="whiteColor text-center">Directions & Maps</h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Nav.Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll9_0;