import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonNebulizersWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonNebulizers">Nebulizers</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Need to Know</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonNebulizers/WhatYouNeedToKnow#uses">
                                                    <p className="whiteColor">Uses of Nebulizer <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonNebulizers/WhatYouNeedToKnow#sideEffects">
                                                    <p className="whiteColor">Side Effects <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="uses">USES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Watch for Expected Effects</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>People take medicines to create an expected response. A bronchodilator enlarges a person’s breathing passages allowing the person to breathe with greater ease. A mucolytic thins mucus in the lungs making the mucus watery and easier to cough up.</p>
                                                <p><span className="bold">When you know the expected effects of a medicine you can judge how a person will respond to it. This is important.</span> For example, if a medicine is for a person’s breathing, you will watch the person take the medicine and later ask if he or she feels like it is easier to breathe.</p>
                                                <p>You might also notice if the person breathes more slowly and that he or she uses fewer muscles to breathe. Knowing what to expect makes you a better judge of whether a medicine is effective.</p>
                                                <p>When you give medicines to another person, know the expected effects. When the person does not respond as expected, report this to the doctor so that the treatment may be adjusted as needed.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Reasons for Using a Nebulizer</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Persons with a variety of lung problems will often take medicines through a nebulizer. An understanding of how a person’s disease affects breathing will help you understand how nebulized medicines work.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeBackgroundColor">Asthma</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Asthma is a chronic (long-term) inflammation of the breathing passages (airways) in the lungs. It is common in children and adults. Asthma is caused when a person breathes in substances they are allergic to (such as pollens, dust, and animal dander). This causes their airways to become hyper-reactive or very sensitive.</p>
                                                <p>A person can have an asthma reaction triggered by polluted air, smoke, cold air, exercise or emotional stress. The disease can be controlled. However, when an asthma attack occurs, there will be constriction or a narrowing of the small airways in the lungs. This causes inflammation of the airways and mucus buildup.</p>
                                                <p className="boldLeadIn">Signs of an asthma attack include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>Wheezing</li>
                                                    <li>Difficulty breathing</li>
                                                    <li>Coughing</li>
                                                    <li>Pain, tightness or pressure in the chest</li>
                                                    <li>Feeling anxious</li>
                                                    <li>Thick mucus secretions from the lungs</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeBackgroundColor">Emphysema</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <img className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10303.jpg" alt="" />
                                                <p className="floatNone clearNone col-6"><span className="bold">Emphysema</span> is an abnormal permanent enlargement of air spaces within the lungs. The air spaces are where a person normally breathes in oxygenand breathes out carbon dioxide. This disease results in the lung being less elastic and less able to exchange oxygen.</p>
                                                <p className="floatNone clearNone col-6">Air gets trapped and the person uses more energy to breathe. The most common cause of emphysema is cigarette smoking, although exposure to toxic fumes, pollution, and second hand smoke can also put a person at risk. Persons with emphysema may also have asthma. Often a person with emphysema is described as having chronic obstructive lung disease.</p>
                                                <p className="boldLeadIn">Signs of emphysema include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>Difficulty breathing during exercise or at rest</li>
                                                    <li>Coughing (usually a dry cough)</li>
                                                    <li>Difficulty breathing while lying flat</li>
                                                    <li>Use of muscles in the shoulders and upper chest to breathe in and out</li>
                                                    <li>An enlarged or barrel chest</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeBackgroundColor">Chronic Bronchitis</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When a person has a chronic productive cough (coughs up mucus) for three months of the year for two years in a row, he or she has bronchitis. The membranes that line the lung’s airways become inflamed and scarred. It is most often associated with smoking.</p>
                                                <p>Over time a person develops difficulty breathing and will have frequent respiratory infections, like pneumonia.</p>
                                                <p className="boldLeadIn">Signs of bronchitis include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>A productive cough (coughs up mucus). Often, you can hear the mucus “rattling” in the lungs</li>
                                                    <li>Wheezing</li>
                                                    <li>Difficulty breathing on exertion</li>
                                                    <li>Heart failure is a common complication</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeBackgroundColor">Cystic Fibrosis</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Cystic fibrosis (CF) is a genetic disease that alters the function of glands in the lungs, pancreas, and sweat glands. A large amount of abnormally thick secretions from the mucus glands result in chronic obstructive lung disease. Although the person with CF has multiple health problems, it affects the lungs by causing a build-up of very thick, sticky mucus. The mucus blocks the person’s airways as well as the glands that secrete the mucus. As a result, the person has repeated lung infections.</p>
                                                <p>The disease progresses from the small to larger airways of the lungs, resulting in destruction of lung tissue. Cystic Fibrosis also affects digestion. The thick, sticky, mucus blocks the digestive enzymes which are needed to help break down the food the person eats. A person will have problems with their nutrition, growth, and development.</p>
                                                <p>There is no cure for cystic fibrosis and the disease generally gets worse over time.</p>
                                                <p className="boldLeadIn">Signs of Cystic Fibrosis affecting the lungs include:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redOrangeBullets">
                                                    <li>Frequent cough</li>
                                                    <li>Cough becomes persistent and produces very thick, greenish colored sputum</li>
                                                    <li>Recurring lung infections</li>
                                                    <li>The disease also has symptoms affecting food digestion such as: Foul-smelling, greasy stools; Poor weight gain and growth; and a distended abdomen from constipation.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="w-100" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="sideEffects">SIDE EFFECTS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Know the Side Effects of Medicines</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A side effect is a predictable, often unwanted effect of a medicine. A side effect is either harmless or it can cause injury. Know the possible side effects of the nebulized medicines taken by the person you care for.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i className="fa fa-exclamation-circle"></i>When side effects develop, call the person’s doctor.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Examples of side effects of commonly prescribed nebulized medicines:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Bronchodilators (Beta Agonists such as Proventil®, Albuterol® Airet®, or Atroventl®):</p>
                                                <p>cause dizziness, headache, nausea, feeling jittery or restless, rapid heart rate, palpitations (or the feeling that the heart is moving or jumping in the chest), chest pain and tremors of the hands.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Steroids (such as Flonase®, Pulmicort®):</p>
                                                <p>side effects are rare. However, a person may develop a yeast infection of the mouth and have difficulty speaking. A yeast infection appears as creamy white, slightly raised lesions in the mouth—usually on the tongue or inner cheeks. It can also appear on the roof of the mouth, gums, tonsils, or back of the throat. It looks a bit like cottage cheese.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Mucolytics (such as Mucomyst®):</p>
                                                <p>can trigger bronchospasm, the sudden narrowing of the larger airways in the lungs resulting in mild to severe difficulty breathing.</p>
                                                <p>A doctor will adjust the dosage or prescribe a new medicine when side effects are severe. Lifestyle or dietary changes may help to lessen side effects. Never let the person stop taking a medicine because of side effects without calling the doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer orangeBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonNebulizersWhatYouNeedToKnow;
