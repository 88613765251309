import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll2_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-2-1">
                                        <div
                                            className="tile Height1RowTall t_025 t_025_2x1 width2Column sitemanENTRedBackground">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="col-4 imgColumn yourAnatomyBackgroundImg">
                                                    </div>
                                                    <div className="col-8 typeColorColumn sitemanENTRedBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center whiteColor sitemanT_25FontSize">Your
                                                                    Anatomy</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-2-2">
                                        <div
                                            className="tile Height1RowTall width1Column t_001 sitemanENTDarkRedBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Your Diagnosis ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-2-3">
                                        <div
                                            className="tile Height1RowTall width2Column t_001 t_001_2x1 sitemanENTDarkRedBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Your Cancer Removal
                                                            ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-2-4">
                                        <div className="tile Height1RowTall width1Column t_001 sitemanENTRedBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Your Reconstruction ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-2-5">
                                        <div
                                            className="tile Height1RowTall t_025 t_025_2x1 width2Column sitemanENTRedBackground">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="col-md-4 imgColumn yourHospitalStayBackgroundImg">
                                                    </div>
                                                    <div className="col-md-8 typeColorColumn sitemanENTRedBackground">
                                                        <div className="contentCenter">
                                                            <div className="tileJustText">
                                                                <h3 className="text-center text-white sitemanT_25FontSize">Your
                                                                    Hospital
                                                                    Stay</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-2-6">
                                        <div
                                            className="tile t_003 Height1RowTall width1Column sitemanENTDarkRedBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K1003_tile-menu-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Discharge ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll2_0;