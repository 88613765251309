import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGettingReadyFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGettingReady">Getting Ready For Surgery</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGettingReady/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonGettingReady/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What can I do to be sure my family member is prepared for surgery?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Help him or her prepare questions to ask their surgeon. Be a teacher and coach to help them learn postoperative exercises.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">When my dad practices deep breathing he always coughs, is that a problem?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Sometimes deep breathing stimulates a cough, especially after surgery. This is normal, but it might be painful to the incision. Splinting the incision by placing the hands or a small pillow or towel firmly over the area (abdomen or chest) while deep breathing, turning and coughing reduces the pain and protects the incision.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I help my family member be comfortable during postoperative exercises?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>person is in the correct position for the exercises and splints the incision during deep breathing, coughing and turning.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">My family member is a smoker. Does that mean surgery will be harder for her?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A person who smokes is at greater risk for breathing problems after surgery than a non-smoker. Smoking increases the risk of pneumonia or a collapsed lung. It also slows wound healing.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">My mother is having back surgery. Will she be able to turn?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Each surgeon has a different approach for patients who have had back surgery. <span className="bold">It also depends on the extent of surgery</span>. Most people with spinal surgery rely on nurses to turn them after surgery because their body needs to be in a straight line.</p>
                                                <p>This method of turning the person is called “logrolling”, which helps prevent the person from pulling or using their arms while turning. Exercise after surgery is still very important; however, it may need to done a little differently. The surgeon will be able to tell you what your mother can and cannot do before surgery so that you will know what to expect.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/American-College-of-surgeons-logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American College of Surgeons (ACS)</h2>
                                                        <p>The “Persons as Partners in Surgical Care” site contains educational information to help you and your family become informed about your operation and surgical care..</p>
                                                        <p>The site provides current information about surgical procedures, diseases, tests, medicines and pain management from government and professional sources to support people in becoming participants in their surgical care.</p>
                                                        <p className="resourceLink">visit: <a href="http://www.facs.org/" target="_blank">www.facs.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1 w-100" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/nih_usnlm_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Ohio State University Medical Center Patient Education Materials</h2>
                                                        <p>The National Library of Medicine (NLM), on the campus of the National Institutes of Health in Bethesda, Maryland, is the world’s largest medical library. The Library collects materials and provides information and research services in all areas of biomedicine and health care.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nlm.nih.gov/about/index.html" target="_blank">www.nlm.nih.gov/about/index.htmly</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AAOS.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American Academy of Orthopaedic Surgeons</h2>
                                                        <p>Provides an Education Library on topics about orthopedics - parts of the body, and diseases and conditions.</p>
                                                        <p className="resourceLink">visit: <a href="https://orthoinfo.aaos.org/" target="_blank">orthoinfo.aaos.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer greenBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGettingReadyFAQResources;
