import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonSubcutaneousInjectionsFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonSubcutaneousInjections">Subcutaneous Injections</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonSubcutaneousInjections/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonSubcutaneousInjections/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What do I do if I get air in the syringe?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>While preparing the dose, hold the vial and syringe with the needle pointing up. If you see air bubbles, tap the barrel of the syringe with your fingers to make the bubbles rise to the top. Then push the air bubbles back into the vial, be sure the needle tip is below fluid level and redraw again more slowly until you get the proper dose with no air bubbles.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Where is the best place to give a subcutaneous injection?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_4.png" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p className="captionBold">Insulin</p>
                                                    <p>Sites to give insulin include the outer surface of the upper arms, the abdomen (except within 2 inches of navel), and the top and lateral (side) surfaces of the thighs.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_5.png" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p className="captionBold">Low molecular weight heparin</p>
                                                    <p>Sites to give insulin include the outer surface of the upper arms, the abdomen (except within 2 inches of navel), and the top and lateral (side) surfaces of the thighs.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100">
                                                <div className="col-4 imgCol noPaddingLeft">
                                                    <img className="img-responsive w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SUB_6.png" alt="" />
                                                </div>
                                                <div className="col-8 typeCol subheadAndBullets rightCol">
                                                    <p className="captionBold">Forteo</p>
                                                    <p>Sites to give Forteo are the abdomen and the thighs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If I find a body site I am comfortable using for injections, can I use it all the time?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Do not use the same injection site each time. Rotate the injection site in a <span className="bold">regular pattern</span>. For example, divide the abdomen into four areas with the navel in the center. You can rotate within the same body part or rotate from one area to the next. Each injection should be at least 1 inch away from the last site used.</p>
                                                <p><span className="bold">TIP:</span> It will help to keep a record of where you give each injection</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">When should you give an injection?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Check with the doctor. The schedule for giving an injection will depend on the medicine being given.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What if the medicine in a vial does not look right?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Throw the vial away.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">I have noticed my family member’s skin sometimes seems spongy and swollen, why is this happening?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Sometimes after giving injections in the same site the skin becomes spongy, swollen, and less sensitive. Rotating the injection sites in different body areas can help to prevent this.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/American_Diabetes_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American Diabetes Association</h2>
                                                        <p>An association that provides objective and credible information about current therapies in diabetes and recommendations for a normal lifestyle.</p>
                                                        <p>visit: <a href="http://www.diabetes.org" target="_blank">www.diabetes.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">MedLine Plus</h2>
                                                        <p>Service of the United States National Library of Medicine and the National Institutes of Health.</p>
                                                        <p>visit: <a href="http://www.nlm.nih.gov/medlineplus/diabetes.html" target="_blank">www.nlm.nih.gov/medlineplus/diabetes.html</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/niddk_logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">National Diabetes Information Clearing House</h2>
                                                        <p>Provides educational materials to increase the knowledge and understanding about diabetes among patients, family caregivers and health professionals.</p>
                                                        <p>visit: <a href="http://www.diabetes.niddk.nih.gov" target="_blank">www.diabetes.niddk.nih.gov</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/HealthPartners.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">National Osteoporosis Foundation</h2>
                                                        <p>Foundation has goal to help improve the lives of those affected by osteoporosis and to find a cure through programs of awareness, advocacy, public and health professional education and research.</p>
                                                        <p>visit: <a href="http://www.nof.org" target="_blank">www.nof.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight w-100 minHeight1" >
                                                        {/*// <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo-mayoclinic.png"  />*/}
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Injection pens</h2>
                                                        <p>For instructions on how to use injection pens, go to the website of the manufacturers such as Eli Lilly.</p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonSubcutaneousInjectionsFAQResources;
