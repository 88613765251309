 import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
 import {useDispatch} from "react-redux";
 import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTL2_6 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideoImportanceOfAskingQuestions = async (e) => {
        const pal = await videoAccessLog({videoName: 'Discharge: Importance of Asking Questions'}, () => {
            console.log('Discharge: Importance of Asking Questions');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Discharge</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Planning your discharge</h3>
                                            <p>Our goal is to get you back to your normal life safely. As your recovery
                                                progresses, your entire care team will be together to discuss when it is
                                                safe for you to leave the hospital. Most patients leave the hospital
                                                around 7 to 10 days after surgery. </p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Together the team will discuss:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                <li>Your progress learning to take care of your laryngectomy stoma
                                                </li>
                                                <li>Your medical concerns</li>
                                                <li>Your support resources</li>
                                                <li>Your needs</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn mb-4">It is vital that your laryngectomy coach
                                                comes to the hospital while you are here. He or she should learn how to
                                                clean and care for your stoma, clean the lary tube, assist with
                                                nutrition, and identify wound complications.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Pre-Discharge Education Class</p>
                                            <p>Before you are discharged, you and your caregiver will be expected to
                                                attend an education class which will be taught by one of our nurses. In
                                                this class we will go over all of your specific surgical wounds and
                                                needs, and also answer any questions you may have. Ask your nurse for
                                                more information on the times for this education class.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Day of Discharge</h3>
                                            <p>You will be discharged around noon.</p>
                                            <p>Your nurse will bring your discharge information from your doctor. Your
                                                nurse will review all instructions with you. If you do not understand
                                                any discharge information, please ask as many questions as you like so
                                                you are confident about what to do when you get home.</p>
                                            <p>Your nurse will bring you detailed instructions about caring for your
                                                laryngectomy stoma. If you have a feeding tube, you will receive
                                                detailed instructions on this as well.</p>
                                            <p>If being discharged to home, you can expect a home health nurse to come
                                                to your house the day of discharge. The nurse will typically make home
                                                visits a couple times a week for no more than a couple hours each
                                                visit.</p>
                                            <p>Supplies will be delivered to your house. Your nurse will also send you
                                                home with enough supplies to get you through a couple of days.</p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon text-center entDiagnosisSurgeryRedBackground">You
                                                will need someone with you at home 24/7 when you leave the
                                                hospital!</h3>
                                        </div>
                                    </div>

                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                <li>This is not permanent – but should continue at least until your
                                                    follow-up appointment and doctor approval.
                                                </li>
                                                <li>If this is not possible, arrangements can be made for you to go to
                                                    an extended care facility.
                                                </li>
                                                <li>If you have any questions about your discharge, contact your <a
                                                    href="/siteman-ent-all-9-1">physician’s office</a>.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Follow-Up</h3>
                                            <p>You will return to your surgeon’s clinic 1 to 2 weeks after your
                                                discharge date. Your wounds will be checked and stitches/staples/drains
                                                may be removed at this visit.</p>
                                            <p>A swallow study may also take place to see if you are ready to start
                                                eating by mouth again.</p>
                                            <p>You will also see a Speech Language Pathologist at your follow up visit,
                                                who will provide you with additional education and support regarding
                                                eating, stoma care, and communication.</p>
                                            <p>You may be referred to see other cancer specialists if needed at this
                                                time.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon" style={{marginBottom: "26px"}}><a href="/siteman-ent-all-4-7"><i
                                                className="fa fa-file-o fa-2x hightLight redColor"></i>&nbsp;&nbsp;<span>Frequently Asked Questions At Discharge</span>&nbsp;
                                                <i className="fa fa-chevron-right"></i></a></h2>
                                        </div>
                                    </div>

                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="V50Lt2yetOk" opts={opts} onPlay={playVideoImportanceOfAskingQuestions}/>
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Importance of Asking
                                                    Questions</span></h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Leaving the Hospital</h3>
                                            <p>We will work with you to determine your care needs at home for a safe
                                                recovery and your options for continuing therapy after you leave.</p>
                                            <p className="boldLeadIn italic">Your options include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                <li>Going home with home-based nursing visits</li>
                                                <li>A skilled nursing facility or an inpatient rehabilitation facility
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you are going home, you will be transported in a wheelchair to the
                                                patient pick-up at the Barnes-Jewish Hospital south campus main
                                                entrance. Someone can meet you there to pick you up.</p>
                                            <p>If you are going to a rehabilitation facility, your social worker or case
                                                coordinator will arrange transportation for you.</p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/IMG_2946_copy.png"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default SitemanENTTL2_6;
