import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";

const LessonMovingWhatYouNeedToKnow = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonMoving">Moving Safely in the Home</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Need to Know</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Risks of Falling</p>
                                                <p>A fall can lead to serious injury. Know the reasons why the person you care for is at risk for falling. A person might have more than one risk. By knowing a person’s risks, you can take steps to help them move about safely and make their home safe.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i className="fa fa-exclamation-circle"></i>A fall can lead to serious injury.</h3>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h2 className="subheadUpperandLower">Factors that Increase the Risk of Falling:</h2>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom">
                                                        <li> A history of falling in the past</li>
                                                        <li>An unusual gait (e.g. limping, dragging foot, uneven stride)</li>
                                                        <li>Confusion - the person does not know what day or time it is, or who he or she is</li>
                                                        <li>Poor judgment</li>
                                                        <li>Not able to see clearly</li>
                                                        <li>Needs help to walk or move about</li>
                                                        <li>Incorrect use of a walker, cane, or crutches</li>
                                                        <li>Loss of sensation in the feet</li>
                                                        <li>Takes medicines that lower blood pressure or increase the need to go to the bathroom</li>
                                                        <li>Becomes dizzy when standing</li>
                                                        <li>Heart or breathing problems</li>
                                                        <li>Urgency – sudden feeling of having to pass urine</li>
                                                        <li>Incontinence – the loss of the ability to hold urine or a bowel movement</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">Ways to Help When You Know the Risks</p>
                                                    <p>If the person you care for is at risk for falling, take action. If the person likes to help himself or herself, then he or she may be less likely to ask for your help. So, think ahead.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom checkBulletsSafeCare">
                                                        <li>Ask him or her to tell you when they first begin to feel the need to go to the bathroom.</li>
                                                        <li>If the person gets dizzy when standing, ask how he or she feels when they are first standing up. Be at the person’s side as he or she stands. Walk by their side as they first move about in a room or to the bathroom.</li>
                                                        <li>For a person who is incontinent – buy a portable commode and make it easy to reach when he or she feels the urge to go.</li>
                                                        <li>Before the person stands from a chair to walk around, be sure he or she has on their glasses. Check the glasses and be sure they are clean.</li>
                                                        <li>Keep their cane or walker close by when they are sitting down.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/F003_ntk_c.jpg" className="img-responsive" />
                                                <p className="captionItalic text-center w-100"><strong>Portable Bedside Commode</strong></p>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer blueBackground">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonMovingWhatYouNeedToKnow;
