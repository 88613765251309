import axios from "axios";
import { config } from "../config.js";

export const currentApi = (type) => {
  switch (type) {
    case "orbit":
      return config.url.ORBIT;

    case "organization":
      return config.url.ORGANIZATION;

    case "user":
      return config.url.USER;

      case "diagnosis":
          return config.url.USER;
  }
};

export const request = (path, type, data, method, validateStatus) => {
 let token = localStorage.getItem("token");
 let headers = {
    "Content-Type": "application/json"
 }

 if (token &&  token.length > 0) {
     headers = {...headers, Authorization: `Bearer ${token}`}
 }

  return axios({
    method,
    validateStatus,
    url: `${currentApi(type)}${path}`,
    headers,
    data,
  });
};

export const getRequest = (path, type, data, validateStatus) =>
  request(path, type, data, "GET", validateStatus);
export const postRequest = (path, type, data, validateStatus) =>
  request(path, type, data, "POST", validateStatus);
export const patchRequest = (path, type, data, validateStatus) =>
  request(path, type, data, "PATCH", validateStatus);
export const deleteRequest = (path, type, data, validateStatus) =>
  request(path, type, data, "DELETE", validateStatus);
export const putRequest = (path, type, data, validateStatus) =>
  request(path, type, data, "PUT", validateStatus);
