import React from "react";
import Container from "react-bootstrap/Container";
import {Nav} from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {useNavigate} from "react-router-dom";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const LwcGeneralLandingPage = () => {
    const encString =
        "64ee5947bbe9d612fba0d04e08161f90:7092a0768ae4af14b93683b3c0d7673ffd68d342cb3971636128f3a2958497fa4f61f3651d2a36c497fbbc84116e8cca60b004b60fade523f5bbb666c77ab0ee06a9343affe096184d14b6e64a50f48e0a41c083a64102a6b1294fd3fb44d8e0670ede0d203472c79b34eb587eaa5f6e77a2d401b74befcd4e69a93dcc2ca654b3a6650de65d3bd507a4beaceefa9ee6";

    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            autoplay: 0,
        },
    };
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/register/qr/${encString}`;
        navigate(path);
    };

    /*
      if (window?.location.pathname === '/lwcgeneral') {
          import('./lwcgeneral.scss');
          require('./lwcgeneral.scss');
      }
       */
    return (
        <div>
            <div className="w-100">
                <Container className="landingPage">
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="landingPage">
                                        <div className="landingLogo w-100 bg-white">
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/WashU+Ortho.png"
                                                className="img-responsive centerImage w-100 maxWidth300"
                                            />
                                        </div>
                                        <div className="landingHero">
                                            <div className="col-12 landingHero lwcGeneralLandingBackgroundImg">
                                                <div className="w-100 content-center text-center landingHeader">
                                                    <div className="col-12">
                                                        <div className="w-100 d-flex content-center">

                                                        </div>
                                                    </div>
                                                    <h1 className="">
                                                        Receive your{" "}
                                                        <span className="italic">
                                                            your Living Well Center Guide
                                                        </span>{" "}
                                                        today!
                                                    </h1>
                                                    <h4 className="">
                                                        This easy-to-use guide walks you through the Living
                                                        Well Center experience
                                                    </h4>
                                                    <h4>
                                                        <span className="italic">
                                                          — including what to expect, how we help, videos
                                                          from our team, and key information that will help
                                                          you get the most benefit from what we offer!
                                                        </span>
                                                    </h4>
                                                    <Nav.Link>
                                                        <button
                                                            onClick={routeChange}
                                                            className="guideButton"
                                                        >
                                                            Activate My Living Well Center Guide{" "}
                                                            <i className="fa-solid fa-chevron-right"></i>
                                                        </button>
                                                    </Nav.Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 subheadAndBullets bulletsCenterContainer">
                                            <div className="w-100 bulletsCenterSection">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>
                                                        Learn from our experts and the stories from past
                                                        patients
                                                    </li>
                                                    <li>
                                                        Get easy-to-follow answers to your most common
                                                        questions.
                                                    </li>
                                                    <li>
                                                        Receive expert advice on all you need to plan for
                                                        success.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="grayBG">
                                            <div className="col-12 graySection">
                                                <div className="w-100 grayContent text-center alignRight">
                                                    <p>
                                                        <AccessTimeIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <br/>
                                                        The{" "}
                                                        <span className="italic">
                                                            The Living Well Center Guide
                                                        </span>{" "}
                                                        is your always-there source of information.
                                                    </p>
                                                </div>
                                                <div className="w-100 grayContent text-center alignLeft">
                                                    <p>
                                                        <LaptopMacIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <TabletMacIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <SmartphoneIcon
                                                            style={{fontSize: 40}}
                                                            className="fa-icon"
                                                        />
                                                        <br/>
                                                        Available on your desktop, <br/>
                                                        tablet and smartphone.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="w-100">
                                            <div className="col-12 m-auto">
                                                <div
                                                    className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                                                    <section className="mb-0">
                                                        {/* L12B? Content 1 side 70% width */}
                                                        <div className="lego l012B p-0 mb-0">
                                                            <div className="contentLeftSide">
                                                                {/* L11 Image */}
                                                                <div className="lego l011 landingBackgroundLWC">
                                                                    <img className="imageW100 noBorderRadius maxWidth500 mb-0"
                                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/9162560e08f725f61b013117564ddfe3.jpeg"
                                                                    />
                                                                </div>
                                                                {/* End L11 Image */}
                                                            </div>
                                                            <div className="contentRightSide">
                                                                <div className="">
                                                                    <p>
                                                                        “At the{" "}
                                                                        <span className="boldText">
                                                                            Washington University Living Well Center
                                                                        </span>{" "}
                                                                        we're changing the way healthcare is
                                                                        delivered. With a multidisciplinary team of
                                                                        experts, we will provide you with
                                                                        comprehensive care and treat the root cause
                                                                        of your problem — not just the symptoms —
                                                                        with the principles of lifestyle medicine.
                                                                    </p>
                                                                    <p>
                                                                        We offer you your ideal range of coordinated
                                                                        services to treat the whole you, including
                                                                        nutritional counseling, physical therapy,
                                                                        behavioral health counseling, and more.
                                                                    </p>
                                                                    <p>
                                                                        And, we will make sure those services are
                                                                        available in ways that can fit your health
                                                                        and wellness needs, your lifestyle, your
                                                                        schedule and, in cases where insurance may
                                                                        not cover all services, your budget.”
                                                                        <br/>
                                                                    </p>
                                                                    <p className="mb-0">
                                                                        <span className="boldSignature">
                                                                            {" "}
                                                                            Dr. Devyani Hunt
                                                                        </span>
                                                                        <br/>
                                                                        <span className="boldSignatureTitle">
                                                                            {" "}
                                                                            Medical Director, Living Well Center
                                                                        </span>{" "}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* End L12B? Content 1 side 70% width */}

                                                        <div className="lego l088B">
                                                            <div className="contentContainer">
                                                                <p>
                                                                    <span className="highlightedText">Results often include:</span>
                                                                    <br />
                                                                    less pain
                                                                    <br />
                                                                    improved strength and stamina <br />
                                                                    weight loss
                                                                    <br />
                                                                    controlled diabetes and/or lower blood pressure.
                                                                </p>
                                                                {/* <p style={{ fontWeight: 400 }}>90-120 days. </p>*/}
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 subheadAndBullets footerSection">
                                            <div className="w-100">&nbsp;</div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};
export default LwcGeneralLandingPage;
