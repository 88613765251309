import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonEnemaWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center width400">
                                                <p>
                                                    <Nav.Link className="" href="/LessonEnema">Giving an Enema</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What To Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonEnema/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonEnema/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonEnema/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonEnema/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Promote Normal Bowel Movements</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>A person should take time to have a bowel movement. Begin by having a regular habit of when to go to the bathroom. For example, 30 minutes after a meal is a good time.</li>
                                            <li>For older adults or persons who have trouble bending the knees to sit on a toilet, install an elevated toilet seat and arms that attach to the toilet. This makes it easier for a person to sit on or stand up from the toilet.</li>
                                            <li>Have the person drink at least 8-10 eight-ounce glasses of liquid a day, unless the doctor orders them to drink less. Placing the total recommended amount of fluid for the day in a pitcher in the refrigerator may make it easier to keep track of the total amount of fluid to drink.</li>
                                            <li>Have the person increase physical activity when they are able, such as walking more often or a bit farther, or riding a stationary bike.</li>
                                            <li>Have the person increase the amount of fiber in their diet, such as wheat bran, fresh fruits and vegetables. Dietary fiber supplements, such as Metamucil and Citracel, can also help.</li>
                                            <li>If constipation continues, the person’s doctor may order a stool softener medicine for a short period of time.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Cleaning the Bowel for Tests and Surgery</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If a person is having surgery on the bowel, expect him or her to also have to take medicines (e.g. GoLytely<sup>&reg;</sup>, Colyte<sup>&reg;</sup>, Nulytely<sup>&reg;</sup>) to clean the entire bowel. These medicines are safe. In this case, an enema prevents “accidents” (leaking of stool) during surgery and constipation after surgery.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Be sure the person takes all of the medicines ordered and at the correct time, usually in the afternoon or evening before the test or procedure.</li>
                                            <li>Some of the medicines given to cleanse the bowel require drinking a large amount of water or Gatorade over a short period of time. This is difficult if the person has trouble swallowing or becomes full easily. Tell the doctor about these problems so he can order the best medicine for the person.</li>
                                            <li>Be sure the person drinks all of the liquids. If the person has indigestion, cramps, nausea, or vomiting, have them lie down and rest. Continue to have them drink fluids, but not coffee or other caffeine drinks.</li>
                                            <li>After drinking all of the liquids, the person will develop diarrhea. The stool will become almost clear once the bowel is cleaned out. This can happen quickly, or it could take several hours. It is best for you to stay close by if the person needs your help to get to the bathroom. If it is difficult for the person to get to the bathroom, keep a bedside commode close by.</li>
                                            <li>After cleaning the bowel, it is usually ok to drink clear liquids, because the person should stay hydrated. Most doctors will give you a list of fluids that are ok to drink until a test is completed.</li>
                                            <li>It is usually ok to drink water, clear broths (chicken, beef, or vegetable), apple juice, popsicles without fruit, lemonade, Kool Aid, tea, or coffee. However, most doctors do not want a person to drink soda, red-colored jello or popsicles, or cherry, cranberry, strawberry or red grape juice before a test or procedure.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Enema_illustration.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The Food and Drug Administration (FDA) has a black box (safety alert) warning for any over-the-counter oral sodium phosphate solution (such as Fleet’s Phospha Soda, Visicol and OsmoPrep). The solution, often given by mouth to clean the entire colon prior to a colonoscopy, has the risk of causing acute kidney injury in certain persons. Thus, sodium phosphate solution can only be given with a doctor’s prescription.</li>
                                            <li>The FDA warning does not apply to over-the-counter laxative products such as, Golytely<sup>&reg;</sup>, Colyte<sup>&reg;</sup>, Nulytely<sup>&reg;</sup>, Trilyte, and Half-lytely.</li>
                                            <li>Always wear gloves when giving an enema to prevent transfer of germs.</li>
                                            <li>Never force the tip of an enema into a person’s rectum.</li>
                                            <li>Some people have trouble holding enema fluid because of a weakness in the rectum. In this case, do not give the enema with the person sitting on the toilet. There is a risk of injuring the wall of the rectum with the enema tip.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                        Giving an Enema Safely
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="J_WFySZeFPg" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Enema_Equipment.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Disposable Enema (Fleet Enema or antibiotic enema)</li>
                                            <li>Pair of clean disposable gloves (available at drug store)</li>
                                            <li>Disposable pad</li>
                                            <li>Roll of toilet paper</li>
                                            <li>Plastic trash bag</li>
                                            <li>Packet of K-Y<sup>&reg;</sup> Jelly</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonEnemaWhatToDoAndSafety;