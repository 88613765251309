import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import Accordion from "react-bootstrap/Accordion";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog.js";
const SitemanENTAll2_4 = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Your Reconstruction: Free Flaps Reconstruction'}, () => {
            console.log('Your Reconstruction: Free Flaps Reconstruction');
        });
        await dispatch(pal);
    }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Your
                                        Reconstruction</h1>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Free Flaps Reconstruction</h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="p4tbXNUhuww" opts={opts} onPlay={playVideo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Any cancer surgery leaves behind a surgical wound. For small cancers,
                                        simply sewing together the nearby skin may be enough. However, surgery
                                        to remove cancers of the head and neck often leave behind complex
                                        wounds. Often these complex or large wounds are best reconstructed using
                                        a <strong>free flap</strong>.</p>
                                    <p>A free flap is a piece of tissue that is taken from elsewhere in the
                                        body, such as the arm, back, or leg. Free flaps are similar to organ
                                        transplants, except you are both the donor and recipient. A free flap
                                        can be made up of skin, muscle, and/or bone and requires bloodflow. The
                                        blood vessels in the free flap are connected to an artery and vein in
                                        your neck.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower withBodyCopy">Common Free Flap Donor
                                        Sites for Head and Neck Reconstruction</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>There are many types of free flaps that can be used from around the body.
                                        For head and neck reconstruction, surgeons typically use a free flap
                                        that is transferred from your forearm, thigh, lower leg, or
                                        back/shoulder blade (the ‘donor site’). Each donor site has advantages
                                        and disadvantages, and your surgeon will discuss which of these options
                                        makes the most sense for you.</p>
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Common+Free+Flap_new.svg" className="img-responsive" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBar darkOrangeBackground centered">Radial
                                        Forearm Free Flap</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>This radial forearm free flap consists of taking a
                                                            rectangle of skin and underlying soft tissue taken
                                                            from the inside of your forearm, just above your
                                                            wrist. This tissue is thin and pliable, making it
                                                            very useful for reconstructing the lining of the
                                                            mouth or the tongue. It can also be used to repair
                                                            other areas.</p>
                                                        <p>Patients who undergo this type of surgery often
                                                            require a skin graft to cover the tendons in their
                                                            forearm. This skin graft can be taken from the upper
                                                            thigh. The skin graft takes several weeks to fully
                                                            heal and requires daily wound care.</p>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-md-12 subheadAndBullets">
                                                        <div className="row">
                                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                                <li>Most patients will be placed in a splint for
                                                                    the first several days after surgery to
                                                                    ensure that the skin graft “sticks”.
                                                                </li>
                                                                <li>You will have either a wound VAC or a bolster
                                                                    to hold your skin graft in place under the
                                                                    splint.
                                                                </li>
                                                                <li>You will have a surgical drain to ensure
                                                                    fluid does not build up under the skin
                                                                    graft.
                                                                </li>
                                                                <li>After the initial splint is removed, a
                                                                    smaller splint will be placed by one of our
                                                                    occupational therapists. The splint helps to
                                                                    keep pressure on your dressing, and also
                                                                    keeps patients from bending their wrists,
                                                                    which can disrupt the skin graft healing.
                                                                </li>
                                                                <li>The skin graft requires diligent care for
                                                                    the first few weeks after surgery to ensure
                                                                    proper healing (<strong><em>see</em></strong> <a href="/siteman-ent-all-4-1-4">Wound
                                                                        Care</a>).
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/forearm_1.jpg" className="img-responsive centerImage w-100 maxWidth400" />
                                                    </div>
                                                </div>
                                                <div className="col-12 images3AcrossAndText circleImagesControlImageSize">
                                                    <div className="w-100">
                                                        <div className="imageContainer">
                                                            <div className="leftCol col-4 paddingLeft7 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Forearm.jpg" className="img-responsive imageTrio" />
                                                                <div className="imgCaption text-center w-100 p-0">
                                                                    <p className="captionItalic centered"><strong>After the surgery your arm will be in a split similar to this.</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="centerCol col-4 paddingLeft7 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Forearm_fresh+1.jpg" className="img-responsive imageTrio marginRight" />
                                                                <div className="imgCaption text-center w-100 p-0">
                                                                    <p className="captionItalic centered spanTwoImages">
                                                                        <strong>Sometimes the skin graft
                                                                            can break down showing the
                                                                            tendons of your wrist as
                                                                            shown here. This will heal
                                                                            with wound care.</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="rightCol col-4 paddingLeft7 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Forearm+fresh+wound.jpg" className="img-responsive imageTrio " />
                                                                <div className="imgCaption text-center w-100 p-0">
                                                                    {/*<p className="captionItalic centered">*/}
                                                                    {/*    <strong>Sometimes the skin graft*/}
                                                                    {/*        can break down showing the*/}
                                                                    {/*        tendons of your wrist as*/}
                                                                    {/*        shown here. This will heal*/}
                                                                    {/*        with wound care.</strong>*/}
                                                                    {/*</p>*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="twoImagesWithOneCaption w-100">
                                                    <div className="imagesTopContainer w-100 m-auto">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Forearm_healed+3.jpg" className="img-responsive imageTrio marginRight" />
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Forearm+healed+1.jpg" className="img-responsive imageTrio marginRight" />
                                                    </div>
                                                    <div className="imgCaption floatLeft w-100">
                                                        <p className="captionItalic centered">
                                                            <strong>What your wrists will look
                                                                like
                                                                once fully healed.</strong></p>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                                {/*<div className="col-12">*/}
                                                    {/*<div className="w-100">*/}
                                                        {/*
                                                        <hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBar darkOrangeBackground centered">Fibula Free Flap</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>The fibula is a thin bone on the outside part of your lower leg. For patients who need jaw reconstruction, the fibula free flap is a common and reliable choice.</p>
                                                        <p>Your surgeon will make an incision down the side of your leg and remove a segment of bone that he/she will use to reconstruct the cancer surgery defect. Often, skin and soft tissue are taken with the bone for reconstruction.</p>
                                                        <p>If skin is taken, you will most likely need a skin graft to cover the muscles of your calf. This skin graft is usually taken from the upper thigh. After surgery, your skin graft will need time to heal.</p>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-md-12 subheadAndBullets">
                                                        <div className="row">
                                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                                <li>You will have either a bolster or a wound VAC to hold your skin graft in place, as well as a large boot to keep your foot stable.</li>
                                                                <li>You will also have surgical drains from your lower leg to ensure blood and fluid do not collect in the healing wound.</li>
                                                                <li>The skin graft requires diligent care for the first few weeks after surgery to ensure proper healing (<strong><em>see</em></strong> <a href="/siteman-ent-all-4-1-4">Wound
                                                                    Care</a>).</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Fibular-skin-free-flap_350x400.jpg" className="img-responsive centerImage w-100 maxWidth400" />
                                                    </div>
                                                </div>
                                                <div className="col-12 images3AcrossAndText circleImagesControlImageSize">
                                                    <div className="w-100">
                                                        <div className="imageContainer">
                                                            <div className="leftCol col-4 paddingLeft7 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Fibula+Walking+Boot.jpg" className="img-responsive imageTrio" />
                                                                <div className="imgCaption text-center w-100 p-0">
                                                                    <p className="captionItalic centered"><strong>After surgery your leg will be in a walking boot similar to this.</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="centerCol col-4 paddingLeft7 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Fibula_wound+1.jpg" className="img-responsive imageTrio marginRight" />
                                                                <div className="imgCaption text-center w-100 p-0">
                                                                    <p className="captionItalic centered">
                                                                        <strong>What you will see after the walking boot and dressings are removed, and what you will care for at home.</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="rightCol col-4 paddingLeft7 paddingRight7">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Fibula_healed.jpg" className="img-responsive imageTrio " />
                                                                <div className="imgCaption text-center w-100 p-0">
                                                                    <p className="captionItalic centered">
                                                                        <strong>What your leg will look like once it is fully healed.</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                                {/*<div className="col-12">*/}
                                                    {/*<div className="w-100">*/}
                                                        {/*
                                                        <hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBar darkOrangeBackground centered">Anterolateral Thigh (ALT) Free
                                        Flap</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>The anterolateral thigh (ALT) free flap consists of skin and soft tissue from the outside part of your thigh. This flap can be used to reconstruct large tongue defects, the upper esophagus (“food pipe”), the skin of face or neck, or other areas.</p>
                                                        <div className="col-md-12 subheadAndBullets">
                                                            <div className="row" ref={(node)=> {
                                                                if (node) {
                                                                node.style.setProperty("justify-content", "left", "important");
                                                                }
                                                                }}>
                                                                <ul className="bulletsCustom colorAccentRedBullets">
                                                                    <li>It’s important to be aware that this incision usually extends the full length of the thigh.</li>
                                                                    <li>The incision on your thigh will be closed with stitches and/or staples.</li>
                                                                    <li>You will have surgical drains from your thigh to ensure blood and fluid do not collect in the healing wound.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ALT+Free+Flap.jpg" className="img-responsive centerImage" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        {/*<img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ALT_Free_Flap.png" className="img-responsive centerImage maxWidth400" />*/}
                                                    </div>
                                                </div>
                                                <div className="twoImagesWithOneCaption withCaptionsUnderImages w-100">
                                                    <div className="imagesTopContainer w-100 m-auto">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Thigh+fresh.jpg" className="img-responsive imageTrio marginRight" />
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Thigh_post_op.jpg" className="img-responsive imageTrio marginRight" />
                                                    </div>
                                                    <div className="imagesTopContainer w-100 m-auto">
                                                        <p className="captionItalic centered">
                                                            <strong>What you will see after surgery. Your leg incision may be closed with glue, staples, or sutures.</strong>
                                                        </p>
                                                        <p className="captionItalic centered">
                                                            <strong>What your thigh will look like as it heals at home.</strong>
                                                        </p>
                                                    </div>
                                                    {/*<div className="imgCaption floatLeft w-100">*/}
                                                    {/*    <p className="captionItalic centered">*/}
                                                    {/*        <strong>What you will see after surgery. Your leg incision may be closed with glue, staples, or sutures. What your thigh will look like as it heals at home.</strong></p>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                                {/*<div className="col-12">*/}
                                                    {/*<div className="w-100">*/}
                                                        {/*
                                                        <hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBar darkOrangeBackground centered">Free Flaps from the Back & Shoulder
                                        Blade</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>The skin over the upper back, the bone from the scapula (i.e. “shoulder blade”), and the latissimus dorsi (the large fan-shaped muscle of the back, sometimes called the “lat”), can all be used for reconstruction of head and neck surgery wounds. These can be used alone or in combination with one another to potentially reconstruct many types of head and neck cancer defects.</p>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-md-12 subheadAndBullets">
                                                        <div className="row">
                                                            <ul className="bulletsCustom colorAccentRedBullets">
                                                                <li>After surgery patients will have multiple surgical drains in place to ensure that fluid and blood do not accumulate in the surgical site.
                                                                </li>
                                                                <li>Patients will often notice a decrease in the range of motion of their shoulder after undergoing this kind of surgery.</li>
                                                                <li>Participating in early and consistent physical therapy for your shoulder is very important to gain back as much strength and range of mobility as possible.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Shoulder+Back+Free+Flap.jpg" className="img-responsive centerImage maxWidth400" />
                                                    </div>
                                                </div>
                                                <div className="twoImagesWithOneCaption withCaptionsUnderImages w-100">
                                                    <div className="imagesTopContainer w-100 m-auto">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Back_post_op.jpg" className="img-responsive imageTrio marginRight" />
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Back+healed.jpg" className="img-responsive imageTrio marginRight" />
                                                    </div>
                                                    <div className="imagesTopContainer w-100 m-auto">
                                                        <p className="captionItalic centered">
                                                            <strong>What you will see after the procedure.</strong>
                                                        </p>
                                                        <p className="captionItalic centered">
                                                            <strong>What your surgical site will look like once it is fully healed.</strong>
                                                        </p>
                                                    </div>
                                                    {/*<div className="imgCaption floatLeft w-100">*/}
                                                    {/*    <p className="captionItalic centered">*/}
                                                    {/*        <strong>What you will see after the procedure. What your surgical site will look like once it is fully healed.</strong></p>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                                {/*<div className="col-12">*/}
                                                    {/*<div className="w-100">*/}
                                                        {/*
                                                        <hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                    {/*</div>*/}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="sidbarFill withBodyCopy noMarginBottom sidebarFillBackgroundColor noMinHeight entDiagnosisSurgeryRedBackground centered">
                                        <h2 className="subheadUpperAndLower marginBottom0" style={{fontSize: 22}}>What are the risks of free flap surgery?</h2>
                                    </div>
                                    <div className="withBorder entDiagnosisSurgeryRedBorderColor">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom colorAccentRedBullets mb-0" style={{paddingLeft: 36, paddingTop: 20}}>
                                                    <li>Flap failure (tissue does not survive, requiring a need to go back to the operating room)</li>
                                                    <li>Infection</li>
                                                    <li>Blood collection</li>
                                                    <li>Numbness or weakness of the free flap donor site (arm, shoulder or leg)</li>
                                                    <li>Fistula (leakage of spit from the mouth or throat to the neck)*</li>
                                                    <li>Hardware failure such as loosening of screws, breakage or exposure of plate (only if bone is used in the free flap)</li>
                                                    <li>Change in appearance (scarring, swelling, contour changes)</li>

                                                </ul>
                                                <p>*Fistula or leakage of spit from the mouth or throat to the neck (only applies to surgery in the mouth or throat)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll2_4;
