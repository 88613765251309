import React from "react";

function L101ComponentCode() {
  return (
    <div>
      {/* L83D Icon Color Bar New */}
      <div className="lego l101 legoMargin1">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
          />
        </div>
        <div className="contentContainer">
          <p>
            <span className="boldHeadLine">Specific</span>
            <br />
            What exactly do you plan to do?
            <br /> With whom?  Where? What time of day?
            <br />
            How many times per day/week?
          </p>
        </div>
      </div>
      {/* End L83D Icon Color Bar New */}
      {/* L83D Icon Color Bar New */}
      <div className="lego l101 legoMargin1">
        <div className="iconContainer">
          <img
            className="icon"
            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
          />
        </div>
        <div className="contentContainer">
          <p>
            <span className="boldHeadLine">Measurable</span>
            <br />
            How will you measure and track this?
          </p>
        </div>
      </div>
      {/* End L83D Icon Color Bar New */}
    </div>
  );
}

export default L101ComponentCode;
