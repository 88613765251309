import React from "react";

function L83FComponentCode() {

    return (

        <div className="lego l083">
            <div className="iconContainer">
                <img className="icon"
                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_home.svg"/>
            </div>
            <div className="contentContainer">
                <p><span className="bold italic">You will likely have some soreness/discomfort following the procedure.</span><br/>
                    Please ice the area after the procedure and take acetaminophen or
                    non-stero idal anti-inflammatory medications as directed by your
                    physician.</p>
            </div>
        </div>

    )
};


export default L83FComponentCode;