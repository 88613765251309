import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll8_7 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Clinical Research News</h1>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder entAdditionalTherapiesGreenBorderColor">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <p>In addition to clinical excellence, Siteman’s head and neck cancer research program is <strong><i>leading discoveries in the treatment and prevention</i></strong> of head and neck cancer. </p>
                                                <p>This team includes surgeon-scientists, epidemiologists, and cancer biologists working together to find a cure. </p>
                                                <p> Our innovative clinical trials offer hope for patients facing difficult diagnoses, and our translational research program offers the potential to quickly transfer the knowledge gained in the laboratory into life-saving therapies.</p>
                                                <p>Research efforts are always underway to address a wide range of issues and ways to improve care.</p>
                                                <p><strong><i>Be sure to keep up to date on our</i></strong> <a target="_blank" href="https://siteman.wustl.edu/research/research-programs/"><u>latest research efforts</u> <i className="fas fa-chevron-right"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                     <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll8_7;
