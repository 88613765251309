import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin_BOONE_HEALTH_7_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorDarkBlue pritikinBorderDarkBlue">Our
                                                Success Stories</h1>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <img*/}
                                    {/*            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BooneHealthCardiacRehabteam2023.jpg"*/}
                                    {/*            className="img-responsive centerImage w-100"*/}
                                    {/*            alt="Team Image" style={{maxWidth: '900px',}}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <p>With each session, you will work with our multi-disciplinary team specially trained in cardiac rehab. Your team includes:</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <div className="subheadAndBullets">*/}
                                    {/*            <ul className="bulletsCustom">*/}
                                    {/*                <li>Registered Nurses specially trained in Cardiopulmonary Care*/}
                                    {/*                </li>*/}
                                    {/*                <li>Registered Respiratory Therapists*/}
                                    {/*                </li>*/}
                                    {/*                <li>Exercise physiologists</li>*/}
                                    {/*                <li>Registered Pharmacists</li>*/}
                                    {/*                <li>Registered Dietitians</li>*/}
                                    {/*                <li>Occupational Therapists</li>*/}
                                    {/*                <li>Physical Therapist</li>*/}
                                    {/*                <li>Physicians</li>*/}
                                    {/*            </ul>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <hr className="w-100 topZero"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin_BOONE_HEALTH_7_3;