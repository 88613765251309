import {UPDATE_BODY_CSS, UPDATE_LOGO} from "../actions/types";

const INITIAL_STATE = {
  orgImageUrl: "",
  favIconAttributes: {},
  orbitCssClass: [],
  bodyCss: ''
};

export const logoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOGO:
      return {
        ...state,
        orgImageUrl: action.payload.orgImageUrl,
        favIconAttributes: action.payload.favIconAttributes,
        orbitCssClass: action.payload.orbitCssClass
      };

    case UPDATE_BODY_CSS:
      return {
        ...state,
        bodyCss: action.payload.bodyCss
      }

    default:
      return state;
  }
};
