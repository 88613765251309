import { VIDEO_ACCESS, VIDEO_ACCESS_ERROR } from "../actions/types";

const INITIAL_STATE = {
    errorMessage: ""
};

export const videoAccessReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VIDEO_ACCESS:
            return {
                ...state,
                errorMessage: ''
            };

        case VIDEO_ACCESS_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
