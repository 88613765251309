import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'

const ContentPancreatic14 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Possible Complications During Your Stay</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                HIT YOUR CALL BUTTON and notify your NURSE IMMEDIATELY if you experience any of these symptoms:
                                            </h2>
                                        </div>
                                    </div>
                                    //Needs styling for bullet section
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Fever, sweating, shaking chills</li>
                                                <li>Wound draining pus</li>
                                                <li>Redness, swelling at the incision</li>
                                                <li>Blood in the drain<br/>(filling up with bright-red, fresh blood)</li>
                                                <li>Sudden increase in pain</li>
                                                <li>Vomiting</li>
                                                <li>Any tube or drain that has come out</li>
                                                <li>Difficulty breathing</li>
                                                <li>Confusion</li>
                                                <li>Numbness in your arms or legs<br/>
                                                    (especially if you have an epidural)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">DELAYED GASTRIC EMPTYING</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>The new connection between your stomach and small bowel can sometimes be swollen, causing slow passage of food and liquid. This “delayed gastric emptying” occurs about 25% of the time after a Whipple surgery.</li>
                                                <li>Signs that you have this: nausea, vomiting, bloating, belching, not passing gas. In most cases this resolves with resting your bowels and taking a step or two backward on your diet to give the swollen connection time to improve on its own.</li>
                                                <li>Sometimes you will need your nasogastric tube reinserted to decompress your stomach Less often, you will need a period of time on nutrition through the IV (TPN) if you are unable to tolerate food by mouth.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">PANCREATIC LEAK</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>The pancreas makes important enzymes that aid in digestion. This enzyme-rich pancreatic fluid can cause a leak where the pancreas was cut during surgery. This occurs about 25% of the time both after Whipple and distal pancreatectomy surgeries</li>
                                                <li>In most cases, this is managed by leaving the drain in for a longer period of time. Some times, the pancreas leaks and is not captured by the surgical drain. In these cases, a new drain needs to be placed using imaging to guide a new drain into the needed area.</li>
                                                <li>The pancreatic fluid sometimes becomes infected, requiring antibiotics.</li>
                                                <li>Less often, the pancreas is leaking a large amount of fluid and the patient will need bowel rest and nutrition through the IV (TPN).</li>
                                                <li>A patient that has a pancreatic leak may receive an injection of Octreotide into their buttocks. This can slow down a leak.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">BLEEDING/ ANEMIA</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>There are many important blood vessels surrounding the pancreas, bile ducts, and small bowel that can be affected by surgery. Postoperative hemorrhage is rare, but sometimes there can be a minor “oozing” from a vein that will cause blood counts to drift down.</li>
                                                <li>About 15% of patients will require a blood transfusion during or after surgery.</li>
                                                <li>If blood counts are already low due to recent chemotherapy, the chance that one needs a transfusion is increased.</li>
                                                <li>A pancreatic leak increases the chance of bleeding, as the pancreatic fluid can “digest” the walls of the blood vessels.</li>
                                                <li>Smoking, uncontrolled diabetes, and poor nutrition can all increase chances of bleeding.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">INFECTIONS</h2>
                                            <p>Infections are the most common complication after pancreas surgery (about 40% of patients).</p>
                                            <p>Infections include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Urinary tract infections: the chance of UTI increases the longer the Foley catheter is left in or if it is reinserted.</li>
                                                <li>Wound infections: smoking, diabetes, and malnutrition increases the risk of wound infections.</li>
                                                <li>Pneumonia: smoking, not taking deep breaths, and being on the ventilator for a longer period of time increases the risk of pneumonia.</li>
                                                <li>Abdominal abscess (pus pocket in the abdomen): if this is not drained by the existing surgical drain, the patient may require another drain placed by Interventional Radiology.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">ARRHYTHMIAS (ABNORMAL HEART RHYTHMS)</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>There can be fluid and electrolyte changes after surgery that put more stress on the heart. The heart may develop an abnormal rhythm.</li>
                                                <li>This is usually discovered by checking the monitors, as patients don’t typically have symptoms (chest pain or shortness of breath).</li>
                                                <li>Most of the time these rhythms can be corrected with medications.</li>
                                                <li>The cardiology team may be consulted.</li>
                                                <li>If a patient has had an irregular rhythm in the past, they are at increased risk of developing it again after surgery.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">DIABETES</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>The pancreas makes insulin, a very important factor in controlling blood sugar.</li>
                                                <li>All patients have their blood sugar checked 4 to 6 times per day after pancreas surgery.</li>
                                                <li>If a patient was diabetic before, there is a chance that their diabetes will get worse.</li>
                                                <li>Sometimes patients are newly diabetic after pancreas surgery.</li>
                                                <li>If it appears that you will need insulin when you get home, the diabetes service and diabetic nurse educators will be consulted. You will receive extensive teaching to make sure you understand how to manage your diabetes at home.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">READMISSION</h2>
                                            <p>About 20% of patients need to come back to the hospital after discharge.</p>
                                            <p>When you are discharged, please makes sure you and your family have worked to learn and understand the topics, plans and activities that will make a real difference in achieving your best outcome.</p>
                                            <p>This reference tool includes a section on “Success Going Home” that includes information and training on things you should do, avoid and look out for once you arrive home, as well as contact numbers to call if you have any questions.</p>
                                            <p>WE ARE AVAILABLE FOR CALLS 24/7. Please do not hesitate to contact us.</p>
                                            <p>Our goal is for you to have the most positive outcome and progress possible.<br/>
                                                <strong>Small problems, addressed promptly, can help you avoid bigger complications that could lead to your re-admission.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic14;
