import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll8_8 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Treating Lymphedema</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">What is Lymphedema?</h3>
                                            <p className="w-100">The lymphatic system is a complex network of vessels that moves protein-rich fluid called "lymph" from your tissues back into your blood stream.</p>
                                            <p className="w-100">Lymphedema is swelling in your body when the lymph gets trapped in your tissues. Cancer treatments can cause this, leading to swelling in your head, neck, arms or legs. The swollen area may feel heavy, right or painful. </p>
                                            <h3 className="sectionHeader w-100 text-center">Lymphedema Causes</h3>
                                            <p className="w-100">Lymphedema has several possible causes. However, it does not affect everyone equally for reasons that remain unknown.</p>
                                            <p className="w-100 boldLeadIn">Potential causes include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                                <li className="w-100"><strong><em>Surgery:</em></strong> Surgery can cause lymphedema, particularly lymph node removal during cancer treatment. Medical providers do not yet have a clear understanding of who will develop lymphedema and when. It may show up a few months after surgery for some and not until several years later for others (swelling immediately after surgery is normal and not usually lymphedema). While risk generally goes up with the amount of lymph nodes taken out, individual outcomes vary.</li>
                                                <li className="w-100"><strong><em>Radiation therapy:</em></strong> While radiation therapy can treat cancer, it can also damage the lymphatic system. Receiving radiation after lymph node removal puts you at particular risk for lymphedema.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Therapy for Lymphedema</h3>
                                            <p className="w-100">Lymphedema can become chronic, meaning it can flare up throughout your life. We can help you successfully manage it. Lymphedema treatment initially helps relieve swelling and thickened skin and helps you manage the condition.</p>
                                            <p className="w-100">Early recognition and treatment of lymphedema is the most effective strategy. If you notice persistent or extensive swelling in your head and neck a few weeks after your surgery, talk to your surgeon about when you can begin lymphedema therapy.</p>
                                            <p className="w-100 boldLeadIn">Our treatment includes:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                                <li><strong><em>Education:</em></strong> Once your swelling has decreased, you will work with your care team to prepare you to manage it on your own. You will be taught helpful exercises and massages and how to best take care of your skin. People with lymphedema face a higher risk of infection from even minor cuts because of the protein-rich fluid trapped near the skin’s surface.</li>
                                                <li><strong><em>Manual lymphatic drainage (MLD):</em></strong> Another specific type of massage can move the lymph fluid along and decrease swelling. Your therapist will stay near the surface of your skin and use a light touch, rather than going deep into your tissue. You will be taught this technique so you can continue massaging at home once you’ve completed your treatment.</li>
                                                <li><strong><em>Bandages:</em></strong> Treating lymphedema often requires special elastic bandages that help relieve swelling from lymphedema. Some people do not need bandaging, but it plays a key therapy role for many others. Bandaging typically is the first step taken to bring swelling down until other treatments can be added. People who need bandages must initially wear them throughout the day and night, often for several weeks or more.</li>
                                                <li><strong><em>Compression garments:</em></strong> Once swelling goes down and you no longer need bandages, we will fit you for a compression garment for your head and neck. You can choose from many styles and options with guidance from your care team. The garments are similar to compression stockings or sleeves. You must wear your garment daily, including when treatment with us is finished. Some people have such minor swelling that they can skip bandages and go straight to a compression garment. The <a href="https://tactilemedical.com/our-lymphedema-solutions/for-head-and-neck/flexitouch-plus/" target="_blank">Flexitouch compression garments</a> have been shown to be quite effective in treating head and neck lymphedema.</li>
                                                <li><strong><em>Fibrosis massage: </em></strong>Prolonged swelling can cause your skin to thicken. A special type of massage can be used to loosen the thickened tissue, almost like the techniques used to break up scar tissue. This massage is different and needed less frequently than manual lymphatic drainage. While it can feel more intense than lymphatic drainage, fibrosis massage is usually very effective. Your treatment will not go beyond the pressure or discomfort that you can tolerate.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/*<div className="col-12 subheadAndBullets">*/}
                                    {/*    <div className="w-100">*/}

                                    {/*        <div className="floatRight col-12">*/}
                                    {/*            <ul className="bulletsCustom colorAccentLimeGreenBullets">*/}
                                    {/*                <li><strong><em>Compression garments:</em></strong> Once swelling goes down and you no longer need bandages, we will fit you for a compression garment for your head and neck. You can choose from many styles and options with guidance from your care team. The garments are similar to compression stockings or sleeves. You must wear your garment daily, including when treatment with us is finished. Some people have such minor swelling that they can skip bandages and go straight to a compression garment. The <a href="https://tactilemedical.com/our-lymphedema-solutions/for-head-and-neck/flexitouch-plus/" target="_blank">Flexitouch compression garments</a> have been shown to be quite effective in treating head and neck lymphedema.</li>*/}
                                    {/*            </ul>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-12 subheadAndBullets">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <ul className="bulletsCustom colorAccentLimeGreenBullets">*/}
                                    {/*            <li><strong><em>Education:</em></strong> Once your swelling has decreased, you will work with your care team to prepare you to manage it on your own. You will be taught helpful exercises and massages and how to best take care of your skin. People with lymphedema face a higher risk of infection from even minor cuts because of the protein-rich fluid trapped near the skin’s surface.</li>*/}
                                    {/*            <li><strong><em>Fibrosis massage: </em></strong>Prolonged swelling can cause your skin to thicken. A special type of massage can be used to loosen the thickened tissue, almost like the techniques used to break up scar tissue. This massage is different and needed less frequently than manual lymphatic drainage. While it can feel more intense than lymphatic drainage, fibrosis massage is usually very effective. Your treatment will not go beyond the pressure or discomfort that you can tolerate.</li>*/}
                                    {/*            <li><strong><em>Manual lymphatic drainage (MLD):</em></strong> Another specific type of massage can move the lymph fluid along and decrease swelling. Your therapist will stay near the surface of your skin and use a light touch, rather than going deep into your tissue. You will be taught this technique so you can continue massaging at home once you’ve completed your treatment.</li>*/}
                                    {/*        </ul>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Contact Us </p>
                                            <p>Talk with your doctor if you have any of the symptoms of lymphedema to discuss your treatment options. </p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll8_8;
