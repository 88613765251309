import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './resetPassword.module.scss';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import LockResetIcon from '@mui/icons-material/LockReset';
import Card from 'react-bootstrap/Card';
import {useDispatch, useSelector, useStore} from "react-redux";
import {loginUser} from "../../../actions/login.js";
import {useNavigate} from "react-router-dom";
import {forgotPassword} from "../../../actions/forgotPassword.js";
import {resetPassword} from "../../../actions/resetPassword.js";
import {FORGOT_PASSWORD_ERROR} from "../../../actions/types.js";
import {validateUserName} from "../login/validation.js";
import {
  validateEmailAddress,
  validateFirstName,
  validateLastName, validatePassword,
  validatePhoneNumber
} from "../register/validation.js";
import {InputWithErrorMessage} from "../../forms/inputWithErrorMessage";
import {Navbar, Nav, Dropdown} from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';


const ForgotPassword = () => {
  const [userName, setUserName] = useState('');
  const [passwordCode, setPasswordCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const {errorMessage, userName: uName, passwordCode: pCode} = useSelector(state => state.forgotPassword);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePassword = (e) => setPassword(e.target.value);
  const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);
  const back = async (e) => {
    await dispatch({
      type: FORGOT_PASSWORD_ERROR,
      payload: ''
    });
    navigate('/onetimepassword');
  }

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage)
    }

  }, [errorMessage])

  useEffect(() => {
    if (uName)
      setUserName(uName);

    if (pCode)
      setPasswordCode(pCode);
  }, [])

  const submitForm = async (e) => {
    e.preventDefault();

    if (!await validateAllFields())
      return;

    const rp = await resetPassword({userName, passwordCode, password, confirmPassword}, () => {
      navigate('/');
    })
    await dispatch(rp);
  }
  const changeEmailAddress = (e) => setEmailAddress(e.target.value);

  const validateEmailAddressField = async (e) => {
  }

  const validateAllFields = async () => {
    const invalidPassword = validatePassword(password, confirmPassword);
    const invalidConfirmPassword = validatePassword(password, confirmPassword);
    const invalidEmailAdress = validateEmailAddressField()

    setPasswordError(invalidPassword);
    setConfirmPasswordError(invalidConfirmPassword);

    if (invalidPassword.length > 0 ||
        invalidConfirmPassword.length > 0)
      return false;
    else
      return true;
  }

return (
    <div className="w-100 gradient">
      <div className="w-100">
          <ToastContainer />
          <Container>
            <div className="pageContainer">
              <h1 className="text-center title">Reset Your Password</h1>
              <Box className="material-box" component="form" Validate onSubmit={submitForm}>
                <div className="card-body">
                  <div className="screenIconsContainer">
                    {/*<Avatar>*/}
                    {/*  <LockResetIcon className="icon" />*/}
                    {/*</Avatar>*/}
                  </div>
                  <div className="formFlexContainer">
                    <div className="formInput">

                    <TextField className="w-100" name="email" id="email" type="email" label="Email"
                                 variant="outlined" value={emailAddress} onChange={changeEmailAddress}
                                 style={{marginBottom:'30px'}}
                                 error={!!emailAddressError} required/>
                      <InputWithErrorMessage
                          errorMessage={emailAddressError}
                      ></InputWithErrorMessage>
                    </div>
                    <div className="formInput">
                      <TextField type="password" className="w-100" id="password" label="Password" name="password" variant="outlined" style={{marginBottom:'30px'}} error={!!passwordError} onChange={changePassword}/>
                      <InputWithErrorMessage
                          errorMessage={passwordError}
                      ></InputWithErrorMessage>
                    </div>
                    <div className="formInput">
                      <TextField type="password" className="w-100" id="confirmPassword" label="Confirm Password" name="confirmPassword" variant="outlined" style={{marginBottom:'30px'}} error={!!confirmPasswordError} onChange={changeConfirmPassword}/>
                      <InputWithErrorMessage
                          errorMessage={confirmPasswordError}
                      ></InputWithErrorMessage>
                    </div>
                  </div>
                  <div className="">
                    {/*<Button variant="contained" color="primary"  onClick={back}>*/}
                    {/*  &lt;&lt; Back*/}
                    {/*</Button>*/}
                    <Button style={{backgroundColor:'#2d70b7', color:'#fff', marginTop: '84px'}} className="loginUIComp center"  type="submit" variant="contained" disabled={!!userNameError || !!passwordError}>
                     Login
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </Container>
        </div>
      <div className="gradientBottom">
      </div>
    </div>
  )
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
