import React, { createContext, useEffect, useState } from 'react';
import {useDispatch} from "react-redux";
import {Navigate, useNavigate, useLocation} from "react-router-dom";
import jwt_decode from "jwt-decode";
import {getLoginScreenStatus, setLoginScreenStatus} from "../helpers/index.js";
import {Modal} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import TopNav from "../components/routing/topNav/index.js";
import PageTracker from "../components/pageTracker/index.js";
import FavIconLoader from "../components/favIconLoader/index.js";
import {externalPathToFilter} from "./externalPathToFilter.js";
import {externalPathToInclude} from "./externalPathToInclude.js";
import {AUTH_ERROR} from "../actions/types";
import {useInterval} from "react-use";

export const AppContext = React.createContext(null);

const AppContextProvider = (props) => {
	let user = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : null);
	let token = localStorage.getItem('token');
	const [jwt, setJwt] = useState(localStorage.getItem('token'));
	const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : null));
	const [showTempOrbitAccessModal, setShowTempOrbitAccessModal] = useState(false);
	const [hideTemporaryOrbitAccessModal, setHideTemporaryOrbitAccessModal] = useState(false);
	const [timerRef, setTimerRef]  = useState(null);
	const [logoutNotificationConfirmed, setLogoutNotificationConfirmed] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [currentLocation, setCurrentLocation] = useState(location.pathname);

	const logout = (useTimeoutError) => (navigate) => async (dispatch) => {
		await dispatch({
			type: 'RESET'
		});
		localStorage.clear();
		if (useTimeoutError) {
			await dispatch({
				type: AUTH_ERROR,
				payload: 'Your user session has expired.  Pleases login to continue.',
			});
		}
		navigate('/login');
	}

	const handleOpenTemporaryOrbitAccessModal = async () => {
		await setShowTempOrbitAccessModal(true);
		await setHideTemporaryOrbitAccessModal(false);
	}

	const handleCloseTemporaryOrbitAccessModal = async () => {
		await setShowTempOrbitAccessModal(false);

		await setHideTemporaryOrbitAccessModal(true);
		navigate("/register/tempuser/posttrial/" + ("true" + (user?.accessToken ? "/" + user?.accessToken : "") +  "" + (user?.counterToken ? "/" + user?.counterToken : "") + "" + (user?.hideRenew ? "/" + user?.hideRenew : "/false")));
		setJwt('');
		dispatch({
			type: 'RESET'
		});
		localStorage.clear();
	}

	const checkTokenExpired = async () => {
		let tokenExpired = token ? (jwt_decode(token).exp * 1000) < Date.now() : false;
		user = JSON.parse(localStorage.getItem('user') ? localStorage.getItem('user') : null);
		token = localStorage.getItem('token');
		const logoutFunc = await logout(true)(navigate);

		if (!user || !token)
			return;

		if (!externalPathToFilter.includes(location.pathname.replace('/',''))) {
			if ((!token || tokenExpired) && !getLoginScreenStatus()) {
				if (!user?.isTemporaryOrbitAccessUser) {
					await dispatch(logoutFunc);
				} else {
					if (!hideTemporaryOrbitAccessModal) {
						setShowTempOrbitAccessModal(false);
						await handleOpenTemporaryOrbitAccessModal();
					}
				}
			}
		}
	}

	useEffect(async () => {
		const logoutFunc = logout(true)(navigate);

		if (logoutNotificationConfirmed) {
			dispatch(logoutFunc);
		}
	}, [logoutNotificationConfirmed])

	useEffect(async () => {
		let tokenExpiredOuter = token ? (jwt_decode(token).exp * 1000) < Date.now() : true;
		let pathName = location.pathname.replace('/', '');
		setCurrentLocation(location.pathname);

		await checkTokenExpired();

		let pathInExcludeList = (externalPathToFilter.find((path) => {
			if (path.replace('/', '') === pathName)
				return path;
		}) !== null && externalPathToFilter.find((path) => {
			if (path.replace('/', '') === pathName)
				return path;
		}) !== undefined)
		let pathInIncludeList = (externalPathToInclude.find((path) => {
			if (path.replace('/', '') === pathName)
				return path;
		}) !== null && externalPathToInclude.find((path) => {
			if (path.replace('/', '') === pathName)
				return path;
		}) !== undefined)

		if ((!pathInExcludeList || pathInIncludeList) && user && user?.isTemporaryOrbitAccessUser) {
			if (tokenExpiredOuter) {
				if (!hideTemporaryOrbitAccessModal && tokenExpiredOuter)
					await handleOpenTemporaryOrbitAccessModal();
			}
		}
	}, [location]);

	useInterval(async () => {
		setCurrentLocation(location.pathname);
		await checkTokenExpired();
	}, 60000);

	return (
		<AppContext.Provider value={{jwt, setJwt, currentUser, setCurrentUser, logout, setCurrentLocation, currentLocation, setHideTemporaryOrbitAccessModal, hideTemporaryOrbitAccessModal}}>
			<Modal show={showTempOrbitAccessModal} onHide={handleCloseTemporaryOrbitAccessModal} id={1} className="authModal">
				<Modal.Header className="authModalHeader" closeButton>
					&nbsp;
				</Modal.Header>
				<Modal.Body>
					<h4 className="w-100 modal-title text-center">To continue with full access, please
						activate your guide.</h4>
					<p className="w-100 modal-title text-center">You will have the option to activate now or at a later time.</p>
					<div className="buttonContainer">
						<Button className="btn btn-primary" onClick={handleCloseTemporaryOrbitAccessModal}>Continue</Button>
					</div>
				</Modal.Body>
			</Modal>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppContextProvider;
