import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";
const LessonWheelchairsWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonWheelchairs">Using a Wheelchair</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Have a Therapist Fit a Person for a Wheelchair</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a person is fitted with the right wheelchair and learns to use it correctly, he or she will have greater freedom, function, independence and confidence.</p>
                                        <p>Always have a professional physical therapist or occupational therapist select the wheelchair for the person you care for. If you or the person you care for selects a chair on your own, without the help of a therapist, there is a greater risk of problems with fit and function.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">A therapist will help you select the correct wheelchair features:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Proper seating and body support</li>
                                            <li>Proper chair width for person’s weight, height and size</li>
                                            <li>Proper wheelchair mobility with adjustments for the person’s strengths and weaknesses</li>
                                            <li>Proper wheelchair to fit job functions, workplace setting, and daily living activities</li>
                                            <li>Special seat cushions to prevent pressure to the hips and buttocks. Pressure could lead to a pressure ulcer (bed sore).</li>
                                            <li>Modifications such as a head rest, body restraints, removable arm or adjustable leg rests are the most common options, depending on the person’s needs</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Types of Wheelchairs
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Wheelchairs are manual or powered, including scooters. When a person has a condition that requires long-term use of a wheelchair, it is not unusual for the person to have more than one type of chair. For example, a person may use a manual wheelchair to get around within their home but use a powered scooter to move around outside and in their community.</p>
                                        <p>Most persons buy a single chair to begin with, and then purchase others to meet their mobility needs. When selecting a wheelchair, The American Physical Therapy Association suggests that the choice be based on a person having the ability to gain access to their home, to care for themselves and their home, to function at work (job or school) and in the community, and to perform leisure activities.</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">Manual Wheelchairs
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Chairs are designed for two purposes:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>To be pushed by someone</li>
                                            <li>Self-propulsion</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WC_manual.png" alt="" />
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p><span className="fontWeight700">Transport wheelchairs</span><br />
                                            When a person is physically dependent and cannot self-propel a wheelchair, a transport wheelchair will suit your needs. A transport chair folds for easy storage in the back of a car. The chair is a good back up for a person’s primary wheelchair.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Power wheelchairs</p>
                                        <p>These wheelchairs are designed for persons who have good upper body strength so they can propel themselves (see photo). The chair has a large wheel for self-propelling and come in lightweight and standard designs
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">Power Wheelchairs
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Chairs that are motorized and run on a battery that is charged by electricity when not in use; include scooters and powered wheelchairs.</p>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WC_scooter.png" alt="" />
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p><span className="fontWeight700">Mobility Scooters</span><br />
                                                A mobility scooter is a chair mounted on a three or four wheeled cart and powered with an electrical battery. While in an electric scooter, the user pushes a joystick in the direction they want to go, and a silent motor drives the chair. You plug the chair into a wall-mounted charger overnight, and in the morning it is ready to move again. The scooter is useful for a person who has upper body weakness and cannot self-propel a manual wheelchair. A person often chooses a scooter because it does not look like a wheelchair.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                    <div className="w-100">
                                        <div className="col-4 imgCol noPaddingLeft">
                                            <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WC_powered.png" alt="" />
                                        </div>
                                        <div className="col-8 typeCol noPaddingLeft">
                                            <p><span className="fontWeight700">Powered wheelchairs</span><br /> A powered wheelchair is a standard manual wheelchair with a battery-powered unit. It must be chosen carefully to meet the needs of the person who will use it without being overly costly.<br /><br />
                                            People who use powered wheelchairs generally have limited strength in their arms, and thus need to use an external power source for them to get around. Powered wheelchairs use either gel cell or wet cell batteries that must be re-charged on a regular basis.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar">Specialty positioning
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a person cannot self-propel a wheelchair or operate a power wheelchair, a specialty chair offers changes in positioning. A chair equipped with a tilting seat system, reclining backrest or both offer comfort for the person who will sit in a chair the whole day. Also, tilting wheelchairs lessen pressure and rubbing of the skin to prevent pressure ulcers.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Choosing a Wheelchair</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Remember, have a trained physical or occupational therapist help the person you care for choose a wheelchair. The American National Standards Institute (ANSI) recently approved a complete set of standards for wheelchairs. Learn if the manufacturer of the wheelchair you choose follows these standards
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Advantages and Disadvantages of Each Type of Wheelchair</h2>
                                    </div>
                                </div>
                                <div className="col-md-12 threecolumnTable">
                                    <div className="w-100">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th colSpan="3">
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="leftCol">
                                                        <h4>Type</h4>
                                                    </th>
                                                    <th className="centerCol cyanTableBackgroundColor">
                                                        <h4>Advantages</h4>
                                                    </th>
                                                    <th className="rightCol">
                                                        <h4>Disadvantages</h4>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Manual</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Less costly</p>
                                                        <p>Lighter In weight</p>
                                                        <p>Easy to transport in a car/truck</p>
                                                        <p>Easier to overcome access problems in the home</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Self-propelled requiring strong upper body</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Scooters</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Attractive, does not look like a standard wheelchair</p>
                                                        <p>Increases mobility without increasing exertion</p>
                                                        <p>Increases mobility without increasing exertion</p>
                                                        <p>Less expensive than powered wheelchair</p>
                                                        <p>Narrow wheelbase makes them maneuverable</p>
                                                        <p>Easy to steer</p>
                                                        <p>Good for persons with limited arm strength</p>
                                                        <p>Greatest mobility range with least exertion </p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Difficult to transport</p>
                                                        <p>Needs to have battery charged</p>
                                                        <p>Less flexible to modify to changing physical conditions than power chair</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Powered Wheelchairs</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Good for persons with limited arm strength</p>
                                                        <p>Greatest mobility range with least exertion </p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Heavier than manual chair</p>
                                                        <p>More expensive</p>
                                                        <p>Difficult to transport</p>
                                                        <p>Less reliable than manual wheelchair</p>
                                                        <p>Needs to have battery charged</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WC_wheelchair.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Know the Parts of a Wheelchair
                                        </h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Frame:</p>
                                        <p>Most wheelchairs have a cross-brace frame that allows you to fold or collapse the chair for storage and transport. There are also chairs designed to carry a person while they are being taken from one place to another. These types of chairs do not fold or come apart. Newer frames are made of lightweight material e.g. aluminum, chromaloy and tubular steel.</p>
                                        <p className="boldLeadIn">Upholstery:</p>
                                        <p>Upholstery for wheelchairs must withstand daily use in all kinds of weather. Materials come in a variety of options, ranging from cloth to new synthetic fabrics to leathers.</p>
                                        <p className="boldLeadIn">Seating System:</p>
                                        <p>A seating system based on the person’s preferences for comfort and the best style for reducing pressure on the tailbone or buttocks that could eventually lead to a pressure ulcer. There are special cushions that fit most wheel chairs to decrease pressure to the buttocks. For example, there are foam, air, and gel cushions, and a new type of cushion that uses honeycomb plastic cells to form a seat that provides good support and pressure relief.</p>
                                        <p className="boldLeadIn">Brakes:</p>
                                        <p>Manual wheelchairs have wheel locks like a parking brake. Most powered chairs have a braking system in which the motor and brakes work together to slow and stop the chair when the power controller is released.</p>
                                        <p className="boldLeadIn">Wheels/Tires:</p>
                                        <p>Manual chairs have a four-wheel design, with two large wheels at the back and two smaller ones (casters) at the front. The standard tire on the rear wheels is an air-filled tire. There are also solid tires. Power chairs have a four-wheel design. The tires are air filled, semi air-filled and solid.</p>
                                        <p className="boldLeadIn">Footrests:</p>
                                        <p>A variety of footrests are available on all wheelchairs. A footrest may be a rigid single unit, or have folding, flip-up, detachable, adjustable length, or a combination of features.</p>
                                        <p className="boldLeadIn">Armrests:</p>
                                        <p>Armrests come in several styles or with a combination of features. They may be full- or desklength, wraparound, and they may be fixed, removable, pivoting, and/or adjustable height. Removable and pivoting armrests are useful when the person transfers often from the wheelchair to their bed, armchair or bathtub.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 backToTop footer blueBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonWheelchairsWhatYouNeedToKnow;