import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonBloodPressureWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonBloodPressure">Taking a Blood Pressure</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Blood Pressure Readings</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Two numbers are used to describe blood pressure.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The top number is called the systolic pressure and it measures the pressure of the blood when the heart pumps or contracts.</li>
                                            <li>The second number is lower than the systolic pressure and it measures the blood pressure between contractions, when the heart is at rest. It is called a diastolic pressure.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person’s blood pressure is written with the systolic number above or before the diastolic, such as 120/80 mmHg. (The mmHg is millimeters of mercury—the units used to measure blood pressure.) <span className="bold">The normal blood pressure for adults is a systolic blood pressure less than 120 and a diastolic blood pressure less than 80</span> (American Heart Association, 2012).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Hypertension</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Blood pressure is the force of blood against the walls of arteries. Blood pressure rises and falls normally throughout the day. When blood pressure stays high over time, it is called hypertension. Hypertension is dangerous because the condition makes the heart work too hard.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">American Heart Lung and Blood Institute Categories for Blood Pressure in Adults (2012)</h2>
                                    </div>
                                </div>
                                <div className="col-md-12 threecolumnTable">
                                    <div className="w-100">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th className="leftCol">
                                                        <h4>Blood Pressure Category</h4>
                                                    </th>
                                                    <th className="centerCol cyanTableBackgroundColor">
                                                        <h4>Systolic (mm/Hg)s</h4>
                                                    </th>
                                                    <th className="rightCol">
                                                        <h4>Diastolic (mm/Hg)</h4>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Normal</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Less than 120</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>...and Less than 80</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Prehypertension</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>120 – 139</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>...or 80 – 89</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Stage I</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>140 – 159</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>...or 90 – 99</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <h4>Stage II</h4>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>160 or higher</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>...or 100 or Higher9</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A diagnosis of hypertension is never made with just one blood pressure reading. The doctor will follow a person who shows a high blood pressure reading over several weeks or months before a diagnosis is made. Prehypertension is a warning that the person may develop hypertension in the future. When a person has prehypertension, their doctor will likely have him or her visit the office more often for blood pressure checks.</p>
                                        <p>About two-thirds of people over age 65 have high blood pressure. Stress, caffeine, smoking, pain, and other factors can cause blood pressure to rise for a short time. For some people, just the act of having their blood pressure taken in a doctor’s office, clinic, or hospital can cause a rise in blood pressure. At other times, their blood pressure is normal. These people have what is called "white coat hypertension".</p>
                                        <p>Once the doctor decides that the person has hypertension, he or she will treat the hypertension with medicines. The doctor may also recommend not drinking caffeine, eating a low-salt diet and exercising regularly.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar text-center">Types of Blood Pressure Monitors</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The selection of the right blood pressure equipment depends on a person’s arm or leg width, health condition, and ease of being able to hold their arm in the correct position. Ask their doctor for advice in selecting and using a device to monitor blood pressure in the home.</p>
                                        <p className="boldLeadIn">Aneroid Sphygmomanometer</p>
                                        <p>The aneroid sphygmomanometer has a dial gauge that you read by looking at a pointer on the dial. You apply the cuff, inflate it and then listen with the stethoscope for sounds as you release the air in the cuff.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li><span className="bold">Pros:</span> easily portable; the cuff may have a built in stethoscope, less expensive ($20 to $30).</li>
                                            <li><span className="bold">Cons:</span> can be easily damaged and unreliable in measurement, can be difficult to use, and the rubber bulb can be difficult to squeeze. If you have a hearing problem, you may not be able to hear the sounds needed to measure blood pressure.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Electronic Monitor</p>
                                        <p>The electronic monitor is either manual or automatic and the blood pressure reading flashes on a digital display screen. The American Heart Association recommends an automatic, cuff-style, bicep (upper-arm) monitor.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li><span className="bold">Pros:</span> automatic and easy-to-use, easy-to-read, may produce a paper printout for recording, BP gauge and stethoscope are in one unit, has an error indicator, deflation of cuff is automatic, good for hearing impaired.</li>
                                            <li><span className="bold">Cons:</span> body movement or irregular heart rate makes blood pressure reading inaccurate, needs batteries, some models can only be used on the left arm, and some models are expensive (over $100).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Finger/Wrist Monitor</p>
                                        <p>Finger/wrist devices are types of electronic monitors with a cuff that wraps around the wrist or the finger. The American Heart Association does not recommend these monitors because they yield less reliable readings.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Finger/wrist monitors are not very accurate and are very sensitive to movement and body temperature.</li>
                                            <li>Not good to use for people with peripheral vascular disease (poor circulation).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar text-center">Features to Look for in a Monitor</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Properly Fitting Blood Pressure Cuff</p>
                                        <p>Ask the doctor, nurse, pharmacist, or medical equipment technician to assist in choosing the correct blood pressure cuff size for the person you care for.</p>
                                        <p>Proper blood pressure cuff sizes:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Small adult - The circumference of the arm is 8.8 to 10.4 inches (22 to 26 cm).</li>
                                            <li>Adult - The circumference of the arm is 10.8 to 13.6 inches (27 to 34 cm).</li>
                                            <li>Large adult - The circumference of the arm is 14 to 17.6 inches (35 to 44 cm).</li>
                                            <li>Adult thigh - The circumference of the arm is 18 to 20.8 inches (45 to 52 cm).</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a blood pressure cuff fits properly, the blood pressure reading will be most reliable. If the cuff is too large, the blood pressure reading may be too low. If the cuff is too narrow, the reading may be too high.</p>
                                        <p>If you choose an electronic blood pressure monitor, it may not come with interchangeable cuffs. The numbers on the monitor should be easy for you to read.</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BP_where_to_take.png" className="img-responsive maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Know what Blood Pressure Range the Doctor Wants</p>
                                        <p>The doctor will decide what the right blood pressure is for the person you care for. In the case of an average size adult:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li><span className="bold">Normal</span> means a systolic (top) blood pressure of less than 120 and a diastolic (bottom) blood pressure of less than 80.</li>
                                            <li><span className="bold">Prehypertension</span> means a systolic (top) blood pressure of 120 to 139 or a diastolic (bottom) blood pressure of 80-89.</li>
                                            <li><span className="bold">Stage 1 Hypertension</span> means a systolic (top) blood pressure of 140-159 or a diastolic (bottom) blood pressure of 90-99.</li>
                                            <li><span className="bold">Stage 2 Hypertension</span> means a systolic (top) blood pressure of greater than 160 or a diastolic (bottom) blood pressure of greater than 100.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar text-center">Choosing a Stethoscope</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A stethoscope consists of two earpieces connected by a flexible tubing to a diaphragm, which is placed against the skin to hears blood pressure sounds.</p>
                                        <p>If you use an aneroid sphygmomanometer, you may also need a stethoscope unless one is built into the aneroid device.</p>
                                        <p className="bold">When you choose a stethoscope, look for these features:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The earpieces should fit snugly and comfortably.</li>
                                            <li>Be sure the stethoscope’s eartubes are angled and strong enough so the earpieces stay firmly in your ears without causing pain.</li>
                                            <li>The tubing of the stethoscope should be flexible and 12 to 18 inches (30 to 40 cm) long.</li>
                                            <li>Be sure you can easily hear sounds through the stethoscope.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BP_stethescope.png" className="img-responsive maxWidth300" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonBloodPressureWhatYouNeedToKnow;