import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const CookiePolicy = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="w-100">
                                        <h1 className="signUph1 text-center">Cookie Policy</h1>
                                    </div>
                                    <div className="w-100">
                                        <p className="text-dark w-100">This Cookie Policy explains when and why cookies and similar tracking
                                            technologies are used by Total Orbit, Ins. ("CareOrbit," "we" or "us") and
                                            our partners on our websites, applications, advertisements and related
                                            services, including CareOrbit.com and the mobile optimized version of this
                                            site and our Mobile Device Applications or "Apps" (we refer to these sites
                                            and Apps collectively as the "CareOrbit Sites"). We refer to the
                                            CareOrbit Sites, along with the information and services made available to
                                            you through the CareOrbit Sites, as the "Services."</p>
                                        <p className="text-dark w-100">Please refer to the CareOrbit Privacy Policy to learn more about the use of
                                            cookies and other tracking technologies on the CareOrbit Sites and how you
                                            can contact us for more information.</p>
                                        <h2 className="text-dark w-100">Cookies</h2>
                                        <p className="text-dark w-100">"Cookies" are small data files that are stored on the hard drive of the
                                            computer you use to view a website. Every computer that accesses a
                                            CareOrbit Site is assigned a different cookie by CareOrbit. Different
                                            Cookies serve different purposes:</p>
                                        <div className="indent">
                                            <p className="text-dark w-100"><u>Session cookies</u> are specific to a particular visit and carry
                                                information as you view different pages within a CareOrbit Site.
                                                Session cookies expire and delete themselves automatically in a short
                                                period of time, e.g., when you leave the website or when you close your
                                                browser.</p>
                                            <p className="text-dark w-100"><u>Persistent cookies</u> enable us to recognize you when you visit a
                                                CareOrbit Site and remember your preferences. Persistent cookies are
                                                stored on your browser or mobile device until you choose to delete them,
                                                and otherwise typically delete themselves at expiration.</p>
                                            <p className="text-dark w-100"><u>Third-party cookies</u> are placed by someone other than CareOrbit,
                                                and may gather browsing activity across multiple websites and sessions.
                                                They are usually persistent cookies and are stored until you delete them
                                                or they expire based on the time period set in each third-party cookie.
                                            </p>
                                        </div>
                                        <p className="text-dark w-100">"Web Beacons" are graphic image files embedded in a page of a website
                                            typically used to monitor activity on the page and send back to its home
                                            server (which can belong to the host site, a network advertiser or some
                                            other third party) information from your browser, such as the IP address,
                                            the URL of the page on which the Web Beacon is located, the type of browser
                                            that is accessing the site and the ID number of any cookies on your computer
                                            previously placed by that server.</p>
                                        <p className="text-dark w-100">"Application SDKs" are mobile application third-party software development
                                            kits that are embedded in mobile applications. These app SDKs permit the
                                            collection of information about the app itself, activity in the app, and the
                                            device the application is running on.</p>
                                        <h2 className="text-dark w-100">Cookie on the CareOrbit Sites</h2>
                                        <p className="text-dark w-100">The cookies on the CareOrbit Sites are generally used for one of the
                                            following purposes:</p>
                                        <div className="indent">
                                            <p className="text-dark w-100"><u>Essential functionality</u>. These cookies are essential to access
                                                some areas of the CareOrbit Sites. For example, certain cookies enable
                                                us to identify registered members and ensure that they have access to
                                                content that is only available to registered members.</p>
                                            <p className="text-dark w-100"><u>Important functionality</u>. These cookies enable us to remember your
                                                preferences, such as your user name, language or the region you are in,
                                                and provide enhanced, more personalized features. They may also be used
                                                to provide services you have asked for, such as watching a video.</p>
                                            <p className="text-dark w-100"><u>Performance and Analytics</u>. These cookies enable us to analyze how
                                                visitors use the CareOrbit Sites and to monitor website performance.
                                                We use the information collected by these Cookies to improve the
                                                performance of the CareOrbit Sites and our Services.</p>
                                            <p className="text-dark w-100"><u>Advertising</u>. These cookies are used by CareOrbit and our
                                                advertisers to deliver advertisements on the CareOrbit Sites and third
                                                party sites that are more relevant to you and your interests based on
                                                your CareOrbit account information, content browsing activity and in
                                                some cases, other information about you that we or our advertisers may
                                                have obtained from other sources. Cookies may be used to limit the
                                                number of times you see a particular advertisement and to enable
                                                measurement of the effectiveness of the advertising campaign.
                                                Information collected through the use of cookies may be collected
                                                directly by CareOrbit, our partners or third party advertisers and
                                                their ad servers.</p>
                                        </div>
                                        <p className="text-dark w-100">We use cookies and other tracking technologies to recognize individual users
                                            when they access the Services, remember user preferences, keep track of
                                            users' access to and use of the Services, track whether our emails opened
                                            and whether links are clicked, ensure that the Services are functioning
                                            properly, analyze trends and to personalize the Services, including
                                            advertising on the CareOrbit Sites and on third party websites, so that it
                                            is relevant to individual user's interests which may be inferred based on
                                            location, prior activity on the CareOrbit Sites and other information that
                                            CareOrbit or our partners may have about our users. When you use the
                                            Services, we also automatically collect information from your browser or
                                            mobile device such as your IP address or unique device identifier, browser
                                            information (including referring URL), your preferences and settings,
                                            cookies and information about the content you have viewed and actions taken
                                            (e.g., search queries, ad engagement, clicks and the associated dates and
                                            times). CareOrbit may also collect device-specific information when you
                                            install and use an App including your device model, operating system
                                            information, advertising ID (which is a unique, user-resettable
                                            identification number for advertising associated with a mobile device) and
                                            app version and usage information. When enabled by you, we collect precise
                                            location information provided by your mobile device, which you may disable
                                            through the device settings. </p>
                                        <h2 className="text-dark w-100">Third Party Cookies</h2>
                                        <p className="text-dark w-100">Sponsors or advertisers on the CareOrbit Sites may use their own cookies or
                                            other tracking technologies in the banner advertisements and sponsored links
                                            and on the pages ("Brand Pages") on the CareOrbit Sites that consist
                                            solely of advertisements or other content from our advertisers. This
                                            advertiser content may also be served in emails, special promotions or
                                            newsletters we send you. Their advertisements may be displayed on the
                                            CareOrbit Sites or on other sites that you visit after you visit the
                                            CareOrbit Sites. Some advertisers use companies other than CareOrbit to
                                            serve their ads and to monitor users' responses to ads, and these ad servers
                                            may also collect information through the use of cookies on the CareOrbit
                                            Sites.</p>
                                        <p className="text-dark w-100">While we generally permit the use of these third party cookies on the
                                            assumption that they comply with our Advertising Policy and we assist our
                                            advertisers with the placement of cookies on Brand Pages, we do not control
                                            how third parties use cookies or how they manage the information they gather
                                            through the use of these technologies on the CareOrbit Sites and we may be
                                            unable to verify compliance with our Advertising Policy. To see how Google
                                            may use information collected through your use of Google's search services
                                            on the CareOrbit Sites visit <a
                                                href="https://www.google.com/policies/privacy/partners"
                                                target="_blank">https://www.google.com/policies/privacy/partners</a>.
                                            We work with third party ad networks to display advertising on the
                                            CareOrbit Sites and on third party sites. Our ad network vendors use
                                            technologies to collect information about your activities on the CareOrbit
                                            Sites and our flagship CareOrbit App to provide you cookie-based targeted
                                            advertising based upon your browsing activity and your interests. The
                                            specific providers we use are subject to change, however below is a list of
                                            some of our current ad network partners:</p>
                                        <h2 className="text-dark w-100">NONE CURRENTLY</h2>
                                        <p className="text-dark w-100">Because your browser, App, or device communicates with these third parties
                                            respective servers, these companies can collect information, including your
                                            IP address, page header information, browser or device information, just as
                                            if you had requested their website or used their apps directly. These
                                            companies may collect information to deliver you customized ads on both the
                                            CareOrbit Sites and non-CareOrbit websites, applications, and services.
                                            In some situations, CareOrbit may interact with these companies outside of
                                            your browser or device, directly between our web servers. Many of these
                                            third parties offer information about their data collection practices, and
                                            in some cases, an opt-out, on their respective websites. Please review each
                                            company's privacy policy to better understand the controls available to you.
                                            We encourage you to periodically review this Cookie Policy for the latest
                                            information on our ad network partners.</p>
                                        <h2 className="text-dark w-100">Your Choices</h2>
                                        <p className="text-dark w-100">Most browser software can be set to reject cookies. Most browsers offer
                                            instructions on how to reset the browser to reject cookies in the "Help" or
                                            "Settings" or "Preferences" section of your browser's toolbar. If you reject
                                            our cookies, certain of the functions and conveniences of our websites may
                                            not work properly but you do not have to accept our cookies in order to
                                            productively use most features of our websites. It is important to note that
                                            in order to use the opt-out services described below, such as the Network
                                            Advertising Initiative and the TRUSTed Ads&trade; program, you must not
                                            reject all cookies because these opt-out procedures work by placing cookies
                                            on your browser that enable these opt-out procedures to function. The
                                            "opt-out cookies" are placed, for example, in order to help prevent the
                                            placement of Cookies used for the targeting of advertising.</p>
                                        <p className="text-dark w-100">Certain companies that serve ads allow you to prevent them from collecting
                                            data through the use of cookies. In order to do so, you must opt-out of such
                                            data collection with each individual site. You can also opt out of cookies
                                            for several ad servers by visiting the <a
                                                href="http://optout.networkadvertising.org/?c=1#!%2F" target="_blank">Network
                                                Advertising Initiative</a> gateway opt-out site or by clicking here: <a
                                                href="https://www.worldprivacyforum.org/2015/08/consumer-tips-top-ten-opt-outs/"
                                                target="_blank">https://preferences.truste.com/</a>. This website will
                                            also allow you to review the privacy policies of these ad servers. You can
                                            find additional information and resources about how to opt out of
                                            advertising and related cookies by visiting the World Privacy Forum's Site.
                                            If you are located in the European Union (EU), you may also opt-out <a
                                                href="http://www.youronlinechoices.eu" target="_blank">here</a>. </p>
                                        <p className="text-dark w-100">In order to give you more control over your privacy choices CareOrbit has
                                            implemented a DAA (Digital Advertising Alliance) approved opt-out mechanism
                                            for interest-based advertising. At the bottom of every page of the
                                            CareOrbit Sites you will see an AdChoices logo (which looks like this: ).
                                            By clicking on that logo on the CareOrbit Sites, you will be presented
                                            with a window that will identify all of the companies that CareOrbit uses
                                            to direct tailored advertisements to you, both on the CareOrbit Sites and
                                            general CareOrbit App as well as on third-party sites. If you would like
                                            to stop this tailored advertising from CareOrbit, you can manage these
                                            advertisements through the choices in that window. Please note that even if
                                            you opt-out of interest-based advertising, you will continue to receive
                                            generic advertisements through the Services. Please note that your choice to
                                            stop tailored advertising is specific to the browser or mobile device that
                                            you are using so if you make a choice to opt out from one computer or mobile
                                            device and you want your opt-out to apply to a different computer or mobile
                                            device as well please opt out from that computer or mobile device too. By
                                            clicking the + symbol next to the name of a vendor in that window you will
                                            get information about how and where that vendor assists us with the delivery
                                            of advertisements. The CareOrbit Sites do not respond to web browser "do
                                            not track" signals.</p>
                                        <p className="text-dark w-100">You may also control interest-based advertising on your mobile device by
                                            enabling the "Limit Ad Tracking" setting in your iOS device's settings or
                                            "Opt out of Ads Personalization" in your Android device's settings. This
                                            will not prevent you from seeing advertisements, but will limit the use of
                                            device advertising identifiers to personalize ads based on your interests.
                                            If you have questions about how to reject Cookies on your specific device
                                            please check with the device manufacturer.</p>
                                        <h2 className="text-dark w-100">Changes</h2>
                                        <p className="text-dark w-100">CareOrbit reserves the right to change this Cookie Policy at any time and
                                            any changes will be effective upon being posted unless we advise otherwise.
                                            We encourage you to periodically review this Cookie Policy for the latest
                                            information about the use of cookies on the CareOrbit Sites.</p>
                                        <p className="text-dark w-100">Last updated on March 11, 2019</p>
                                        <p className="text-dark w-100">&copy; 2018 CareOrbit, LLC. All rights reserved.</p>
                                    </div>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p className="text-dark w-100"><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default CookiePolicy;