import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll1_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-1-1">
                                        <div className="tile Height1RowTall width2Column t_001 t_001_2x1 sitemanENTYellowBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Your Journey ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-1-2">
                                        <div className="tile Height1RowTall width1Column t_009 sitemanENTLightOrangeBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                    <h3 className="text-center whiteColor">Helping Your Success ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-1-3">
                                        <div className="tile Height1RowTall width2Column t_009 t_009_2x1 sitemanENTLightOrangeBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Progress and Key
                                                            Milestones ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-1-4">
                                        <div className="tile t_003 Height1RowTall width2Column sitemanENTYellowBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0027_questions-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Key Questions ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-1-5">
                                        <div className="tile Height1RowTall width2Column t_001 t_001_2x1 sitemanENTYellowBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Advice from
                                                        Patients &amp; Families ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-1-6">
                                        <div className="tile t_003 Height1RowTall width2Column sitemanENTBrightYellowBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K1003_tile-menu-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Resources ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll1_0;