import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fab, fas, far);

const LessonDisposalWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDisposal">Disposal of Medical Waste</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Need to Know</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Medical waste items can carry infection. You are at risk if you touch an infected item and then touch a cut or open sore on your body, or your mouth. You can get any number of different infections if you do not handle medical waste the right way.</p>
                                                <p>Here are two very serious infections you need to know about.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Hepatitis</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Hepatitis is a disease of the liver. Normally the liver helps to digest food, store energy and remove poisons from your body. When you get Hepatitis, your liver will stop working well. Damage to the liver can lead to scarring and even cancer.</p>
                                                <p>Viruses cause most cases of Hepatitis. Each type of hepatitis gets its name from the virus that causes it; For example, Hepatitis A, Hepatitis B and Hepatitis C.</p>
                                                <p>You can get Hepatitis from eating food or drinking water containing a virus. However, in the case of medical waste, the viruses live in body fluids, such as stool, blood, saliva, vaginal secretions, menstrual fluid, and vomit of persons who are infected.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp;Many people, who get Hepatitis, do not know they have it.</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Many people who get Hepatitis do not know they have it.  You could care for someone who does not know he or she has Hepatitis.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How Does Hepatitis Spread?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Hepatitis A</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Through food or water tainted by stool from a person who has the virus. If a person has hepatitis and does not wash hands after having a bowel movement, the person can transfer the virus when touching food.</li>
                                                    <li>Through linens or clothing soiled by body fluids. As a caregiver you can contract Hepatitis if stool gets on your hands while changing linens.</li>
                                                    <li>Through handling drainage bags that hold body fluids, such as an ostomy bag.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Hepatitis B and C</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>From accidental dirty needle sticks or sharing needles</li>
                                                    <li>From living in the same household with someone who is infected</li>
                                                    <li>Contact with blood from someone who is infected</li>
                                                    <li>Dirty tattoo/body piercing equipment</li>
                                                    <li>Newborns may be infected during birth if the mother has hepatitis B</li>
                                                    <li>Persons with hemophilia may become infected from receiving blood clotting factor medicine</li>
                                                    <li>Through tissue soiled from saliva of a person who is infected</li>
                                                    <li>Through touching a soiled wound dressing of a person infected</li>
                                                    <li>If you are a sexual partner of a person infected</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Symptoms of Hepatitis</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Many persons with Hepatitis have no symptoms</li>
                                                    <li>Flu-like symptoms – fatigue, nausea, loss of appetite</li>
                                                    <li>Pain in the upper right area of the belly (where the liver is located)</li>
                                                    <li>Fever</li>
                                                    <li>Joint aches</li>
                                                    <li>The whites of the eye and the skin may have a yellow tint (jaundice)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">HIV – the Virus that Causes AIDS</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p><span className="bold italic">HIV virus</span> causes the disease AIDs, which attacks the person’s <span className="bold italic">immune system</span>. A person may remain healthy for many years carrying the HIV virus. However, if left untreated, AIDs develops into a series of acute and then chronic <span className="bold italic">infections</span>. The disease can be fatal. Any person who is an IV drug user or has multiple sexual partners is at high risk.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">How Does HIV Spread?</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Through contact with infected body fluids, including blood, semen, vaginal secretions, and breast milk.</li>
                                                    <li>For a family caregiver, the most common way to get HIV is through contact with infected blood, by way of an accidental needle stick.</li>
                                                    <li>If you are a sexual partner of the person you care for, you can get HIV during sexual contact.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">How Does HIV Not Spread?</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>HIV is not spread casually.</li>
                                                    <li>You cannot get the virus from hugging, dry kissing, shaking hands, sharing eating utensils, using toilet seats or working with a person with the infection.</li>
                                                    <li>The HIV virus is not in tears, saliva, urine, sputum, sweat, stool or food that you vomit.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Symptoms of HIV</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>The course of HIV will vary, depending if the infection is treated or not. Then there are a wide variety of treatments.</li>
                                                    <li>Early acute infection is a flu-like syndrome, with fever, sore throat, headache, feeling tired, nausea, muscle and joint pain, and swollen lymph nodes in the neck and under the arms.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p>
                                                <a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP
                                                    <i className="fa fa-chevron-up">&nbsp;</i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDisposalWhatYouNeedToKnow;