import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonGettingReadyIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonGettingReady">Getting Ready For Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonGettingReady/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonGettingReady/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a person you care for faces surgery, you want him or her to be prepared, both physically and mentally. The more a person understands about surgery and how he or she can prepare, the more likely surgery will be a positive experience.</p>
                                        <p>Studies show that being prepared and understanding your surgical procedure can have a positive impact on your recovery after surgery and your overall satisfaction with the procedure.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How Can You Help?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>By being a source of information and a coach. If you have a good understanding of the person’s surgery and what is to be expected, you can help to clarify and explain information and coach the person through a set of recovery exercises. You can also help by just listening. Surgery can be scary at times and sometimes a person just needs you to listen to their fears.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Get Any Questions Answered Before Surgery.</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person wants to feel good about their surgeon and the decision to have surgery. Each doctor plans for surgery differently but there are common activities to expect. If you don’t understand what to expect, these activities can cause confusion and be frightening.</p>
                                        <p>To avoid confusion, prepare a list of questions for the surgeon. The more a person knows, the better able he or she can become a partner in their own care after surgery. This will speed their recovery. Peace of mind is very important, so you may want to get a second opinion from another doctor.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Get Any Questions Answered Before Surgery.</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>In these situations there is little time to prepare for surgery. As a caregiver for someone, you can best help by being present. Help inform the doctors if they need information about the person for whom you provide care. Your presence can help calm the person before surgery begins.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you care for an older adult or a person who is seriously ill, you need to know how to reduce their risk of injury. Also, you need to know how to protect yourself from injury when you help someone else. It is important to learn the proper ways to assist them and make their home a safe place to live.</p>
                                        <p>This lesson will show you how to assist a person in walking and transferring safely from a bed to a chair. Also see our lesson on Fall Prevention.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Studies show that being prepared and understanding your surgical procedure can have a positive impact on your recovery after surgery and your overall satisfaction with the procedure.</p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">
                                        Video: Walking through a home safely.
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="hhAPGnx5T0Y" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer greenBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonGettingReadyIntroduction;