import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import PageTracker from './components/pageTracker';
import "core-js/stable";
import "regenerator-runtime/runtime";

import'./scss/App.scss';
//import'./css/App.css';
import reportWebVitals from './reportWebVitals';
import Provider from "react-redux/es/components/Provider.js";
import {rootReducer} from "./reducers/index.js";
import configureStore from './store.js';
import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter, useRoutes } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { PersistGate } from 'redux-persist/integration/react';
const {store, persistor} = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </BrowserRouter>
        </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
