import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonPainFAQResources = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center width400">
                                                        <p><Nav.Link className="" href="/LessonPain">Recognizing and Relieving Pain</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonPain/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonPain/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How should I throw away prescription drugs?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The Food and Drug Administration has guidelines for the safe disposal of unused, unneeded, or expired prescription medicines.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Follow any specific disposal instructions on the drug label or patient information that comes with the medicine.</li>
                                                    <li>Do not flush prescription drugs down the toilet. Only do this if the label specifically instructs you to do so.</li>
                                                    <li>Use community drug take-back programs that allow the public to bring unused drugs to a central location for proper disposal. Call your city or county government’s household trash and recycling services (see blue pages in phone book) or call 1-888-INFO FDA, or check online at <a href="https://takebackday.dea.gov/" target="_blank">https://takebackday.dea.gov</a></li>
                                                    <li>If your pharmacist says it is safe to throw medicines away in the trash, take them out of their bottles and mix them with coffee grounds or kitty litter. Put the mixture inside a sealable bag, then place it in the trash.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I tell if a person who cannot speak is in pain?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>You can tell by watching their behaviors, expressions and body movements.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Will some pain never go away?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Chronic pain may not go away completely, but it can be managed so that the person you care for can be as active as possible.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I tell if a pain medicine worked or not?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>After a person takes a pain medicine, wait 30 minutes and then ask him or her if the pain has lessened. Have them rate their pain (scale of 0 to 10) and compare with the number just before you gave the medicine. Also, watch to see if any behaviors, expressions or body movements that indicated pain before are gone.</p>
                                                <p>Remember some people do not show their true pain in their behaviors, expressions or body movements. Listen to what the person says about their pain.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Should I wait until our next doctor’s appointment if I think the pain medicine is not working?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>No. Do not wait to call the doctor if you feel that the person you are caring for is having too much pain. Use the pain scales, what the person tells you, and their behaviors and expressions to tell this to the doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/white-house-logo-md-wh.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Office of National Drug Control Policy (ONDCP)</h2>
                                                        <p>The principal purpose of ONDCP is to establish policies, priorities, and objectives for the Nation’s drug control program. Includes drug facts and information about prescription drug abuse prevention.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.whitehouse.gov/ondcp/" target="_blank">www.whitehouse.gov/ondcp/</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ISMP_logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Institute for Safe Medication Practices (ISMP)</h2>
                                                        <p>The nation’s only nonprofit organization devoted entirely to medication error prevention and safe medication use.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.ismp.org" target="_blank">www.ismp.org</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/HealthPartners.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Food and Drug Administration (FDA)</h2>
                                                        <p>The FDA is an agency within the Department of Health and Human Services. The website offers a wide range of information about drug safety and availability.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.fda.gov" target="_blank">www.fda.gov</a></p>
                                                        <hr className="w-100 floatLeft clearLeft"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonPainFAQResources;
