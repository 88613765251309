import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTL2_4 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">The Free Flap Process</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">What is a free flap?</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A free flap is a piece of skin, tissue, and/or bone that will be taken from another part of your body (the flap donor site) and transplanted into the area where the tumor or dead jaw bone was removed. </p>
                                            <p>Blood vessels in the free flap will be connected to blood vessels in your neck. This gives the free flap a blood supply, like a transplant, but from your own body. </p>
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2.4_what_is_freeflap.jpg" className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">Why do I need a free flap?</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Free flaps are needed when you have an area of tumor that needs to be removed with surgery. This will create a large hole that cannot be closed by itself. Rebuilding this area with a free flap will help restore your appearance and help the wound heal.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">Common Free Flaps</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>There are several areas on your body that can be used for free flap surgery. The choice of donor site depends on the nature of your wound and your anatomy. Skin, muscle, and/or bone can be taken from your arm, back, or leg and be used to repair the wound from removing your cancer</p>
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2.4_freeflap_locations.jpg" className="img-responsive" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">Skin Graft</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Skin grafts are thin sections of skin that are laid over wounds and heal like normal skin over time. Some patients will require a skin graft to cover part of their free flap donor site. Your surgeon will discuss whether he/she anticipates whether or not you will need a skin graft.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentYellow">
                                                <h2 className="subheadUpperAndLower">What are the risks of free flap surgery?</h2>
                                            </div>
                                            <div className="withBorder borderAccentYellow">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom colorAccentYellowBullets">
                                                            <li>A history of falling in the past</li>
                                                            <li>Flap failure (may need to go back to the operating room)</li>
                                                            <li>Infection</li>
                                                            <li>Blood collection</li>
                                                            <li>Numbness or weakness of the face, tongue, shoulder, or voice box</li>
                                                            <li>Numbness or weakness of the flap donor site (arm or leg)</li>
                                                            <li>Fistula (leakage of spit from the mouth or throat to the neck)</li>
                                                            <li> Hardware failure (loosening of screws, breakage or exposure of plate)</li>
                                                            <li>Trouble with speaking or swallowing (need for feeding tube)</li>
                                                            <li>Trouble with breathing (need for tracheostomy tube)</li>
                                                            <li>Change in appearance (scarring, swelling, deformity</li>
                                                            <li>Lung puncture</li>
                                                            <li>Stroke, heart attack, death</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100 entDiagnosisSurgeryRedBorderColor" />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL2_4;
