import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTL3_1 = () => {

    const opts = {
        height: '390', width: '640', playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Keys to Success: Taking Action'}, () => {
            console.log('Keys to Success: Taking Action');
        });
        await dispatch(pal);
    }

    return (<div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entPreparingSurgeryGreenColor entPreparingSurgeryGreenBorderColor">Keys
                                                to Success</h1>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>You can take steps right away to feel good about your health and that
                                                    will guide you to be your healthiest self for your new start.</p>
                                                <p>Your success begins with creating a true understanding of your path
                                                    through this journey – the nature of your diagnosis, the treatments
                                                    you will undergo, what your recovery will entail, and how to help
                                                    ensure the best outcomes. </p>
                                                <p>Everyone involved – you, your family, your care team and other
                                                    resources available to you – can all come together with a focus on
                                                    your success. </p>
                                            </div>
                                        </div>
                                        <div className="col-12 video">
                                            <div className="w-100">
                                                <div className="floatLeft w-100">
                                                    <div className="embedContainer originalPadding">
                                                        <YouTube videoId="4Oh6LlmVBDA" opts={opts} onPlay={playVideo}/>
                                                    </div>
                                                </div>
                                                <h3 className="videoTitle w-100 text-center text-dark">Watch: <span
                                                    className="videoTitleTreatment">Taking Action</span></h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="sectionHeader w-100 text-center">GETTING
                                                    HEALTHY FOR SURGERY</h3>
                                                <p>How to start strong on your road to a successful procedure and
                                                    recovery.</p>
                                                <p className="boldLeadIn">1. Check in with your primary care doctor.</p>
                                                <p>It is important to check in with your primary care physician to make
                                                    sure that he/she is updated about your health status.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="col-12">
                                                    <p className="boldLeadIn">2. Identify a caregiver for your
                                                        journey.</p>
                                                    <p>A caregiver can help you stay safe, learn your new wound
                                                        care and be your best during this journey. Select a family
                                                        member or friend who can be by your side through this journey
                                                        and help you toward a successful recovery.</p>
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                                                <li>Your caregiver will help you meet your surgery
                                                                    planning
                                                                    and recovery goals.
                                                                </li>
                                                                <li>Your caregiver will help you become independent
                                                                    faster.
                                                                </li>
                                                                <li>When you first arrive home, your caregiver can stay
                                                                    with
                                                                    you for a few days or visit daily. This home support
                                                                    will help you in your recovery.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn mb-4">3. Know common
                                                    concerns and challenges and how to address or overcome them.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="col-12">
                                                    <p className="boldLeadIn">4. Recovery from your upcoming surgery
                                                        will be a big adjustment.</p>
                                                    <p>The more you know about the nature of your recovery, what to
                                                        expect and what help you have available can make a real
                                                        difference.</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">5. Know your team and how they help you.</p>
                                                <p>Your care team’s goal is to provide world-class health care in a
                                                    compassionate, respectful and responsive way. They are committed to
                                                    help you during this journey. </p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom colorAccentBlueGreenBullets">
                                                    <li><em>Surgeon</em></li>
                                                    <li><em>Surgeon’s Clinical Nurse Assistant</em></li>
                                                    <li><em>Anesthesia Team</em></li>
                                                    <li><em>Registered Nurse (RN)</em></li>
                                                    <li><em>Speech Pathologist</em></li>
                                                    <li><em>Physical Therapist</em></li>
                                                    <li><em>Occupational Therapist</em></li>
                                                    <li><em>Case Manager </em></li>
                                                    <li><em>Radiation Oncology Team</em></li>
                                                    <li><em>Medical Oncology Team </em></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entPreparingSurgeryGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL3_1;
