import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './forgotPassword.module.scss';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Card from 'react-bootstrap/Card';
import {useDispatch, useSelector, useStore} from "react-redux";
import {loginUser} from "../../../actions/login.js";
import Avatar from '@material-ui/core/Avatar';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import { useNavigate } from 'react-router-dom';
import {forgotPassword} from "../../../actions/forgotPassword.js";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import {validatePassword, validateUserName} from "../login/validation.js";
import {InputWithErrorMessage} from "../../forms/inputWithErrorMessage.js";
import {Navbar, Nav, Dropdown} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import {FORGOT_PASSWORD_ERROR} from "../../../actions/types.js";
import {oneTimePassword} from "../../../actions/oneTimePassword.js";
import {resetPassword} from "../../../actions/resetPassword.js";
import 'react-toastify/dist/ReactToastify.min.css';
import {validateConfirmPassword, validatePasswordCode} from "../register/validation.js";

const ForgotPassword = (props) => {
  const [userName, setUserName] = useState('');
  const [passwordCode, setPasswordCode] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userMessage, setUserMessage] = useState('');
  //const {errorMessage, userName: uName} = useSelector(state => state.forgotPassword);
  const [userNameError, setUserNameError] = useState('');
  const [passwordCodeError, setPasswordCodeError] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(true);
  const [showOTP, setShowOTP] = useState(false);
  const [otpButtonState, setOtpButtonState] = useState(1);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

    const backResetPassword = async () => {
        setShowForgotPassword(false);
        setShowResetPassword(true);
        setShowOTP(false);
    }
  const changeUserName = (e) => setUserName(e.target.value);

  const changeOTP = (e) => setPasswordCode(e.target.value);
  const backForgotPassword = async () => {
    setShowForgotPassword(true);
    setShowResetPassword(false);
    setShowOTP(false);
  }

  const changePassword = (e) => setPassword(e.target.value);
  const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);
  const backOTPScreen = async () => {
      setShowForgotPassword(false);
      setShowResetPassword(false);
      setShowOTP(true);
  }

  const regenerateOTP = async () => {
    const fp = await forgotPassword({userName}, (data) => {
      if (data?.payload?.status === 'success') {
        setUserMessage('A new OTP has been sent to your email address.  Please check your email and re-enter the new OTP.');
      } else if (data?.errorMessage) {
        setErrorMessage(data?.errorMessage);
      }
    })
    await dispatch(fp);
  }

  const submitOTPForm = async (e) => {
    e.preventDefault();
    if (otpButtonState === 1) {
      if (!await validateAllPasswordCodeFields())
        return;
      console.log('we are in the otp code');

      console.log('passwordCodeError = ', passwordCodeError);

      console.log('we are in the otp code');
      const otp = await oneTimePassword({userName, passwordCode}, (data) => {
        if (data?.payload?.status === 'success')
          backResetPassword();
        else if (data?.errorMessage)
          setErrorMessage(data?.errorMessage);
      })
      await dispatch(otp);
    }
  }

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage)
      setErrorMessage('');
    }

  }, [errorMessage])

  useEffect(() => {
    if (userMessage) {
      toast.success(userMessage);
      setUserMessage('');
    }

  }, [userMessage])

  const submitForm = async (e) => {
    e.preventDefault();

    if (!await validateAllFields())
      return;

    const fp = await forgotPassword({userName}, (data) => {
      if (data?.payload?.status === 'success')
        backOTPScreen();
      else if (data?.errorMessage)
        setErrorMessage(data?.errorMessage);
    })
    await dispatch(fp);
  }

  const validateUserNameField = async (e) => {
    await setUserNameError(validateUserName(userName));
  }

  const validatePasswordField = async (e) => {
    await setPasswordError(validatePassword(password));
  }

  const validateConfirmField = async (e) => {
    await setConfirmPasswordError(validateConfirmPassword(password, confirmPassword));
  }

  const validatePasswordCodeField = async (e) => {
    await setPasswordCodeError(validatePasswordCode(passwordCode));
  }

  const submitResetPasswordForm = async (e) => {
    e.preventDefault();

    if (!await validateAllResetPasswordFields())
      return;

    const rp = await resetPassword({userName, passwordCode, password, confirmPassword}, (data) => {
      if (data?.payload?.status === 'success')
        navigate('/');
      else if (data?.errorMessage)
        setErrorMessage(data?.errorMessage);
    })
    await dispatch(rp);
  }

  const validateAllFields = async () => {
    const invalidUserName = validateUserName(userName);

    setUserNameError(invalidUserName);

    if (invalidUserName.length > 0)
      return false;
    else
      return true;
  }

  const validateAllPasswordCodeFields = async () => {
    const invalidPasswordCode = validatePasswordCode(passwordCode);

    setPasswordCodeError(invalidPasswordCode);

    if (invalidPasswordCode.length > 0)
      return false;
    else
      return true;
  }

  const validateEmailAddressField = async (e) => await setUserNameError(validateUserName(userName));

  const validateAllResetPasswordFields = async () => {
    const invalidPassword = validatePassword(password);
    const invalidConfirmPassword = validatePassword(confirmPassword);

    setPasswordError(invalidPassword);
    setConfirmPasswordError(invalidConfirmPassword);

    if (password !== confirmPassword) {
      setPasswordError("Password and confirm password fields must be equal.  Please try again.");
      setConfirmPasswordError("Password and confirm password fields must be equal.  Please try again.");
      return false;
    }

    if (invalidPassword.length > 0 ||
        invalidConfirmPassword.length > 0)
      return false;
    else
      return true;
  }

return (
    <div className="w-100 gradient">
      <div className="w-100 ">
          <ToastContainer/>
          <Container className="registration">
            <div className="pageContainer" id="forgotPasswordScreen" style={{display: (showForgotPassword ? 'block' : 'none')}}>
              <h1 className="text-center title" >Forgot Password</h1>
              <Box className="material-box" component="form" Validate onSubmit={submitForm}>
                <div className="card-body">
                  <div className="screenIconsContainer">
                    {/*<Avatar>*/}
                    {/*  <VpnLockIcon className="icon" />*/}
                    {/*</Avatar>*/}
                  </div>
                  <div className="formFlexContainer">
                    <div className="formInput">
                      <InputWithErrorMessage errorMessage={userNameError}>
                        <TextField className="w-100" id="userName" label="Enter Email or User Name" name="userName" variant="outlined" value={userName} onChange={changeUserName} onBlur={validateEmailAddressField} style={{marginBottom:'30px'}} error={!!userNameError} />
                      </InputWithErrorMessage>
                      <span className="validationError">{errorMessage}</span>
                    </div>
                  </div>
                  <div className="buttonContainer loginButtonContainer">
                    <Button className="center loginUIComp"  style={{  backgroundColor: '#2d70b7' , color: '#fff' ,marginTop:'84px'}}  type="submit" variant="contained" disabled={!!userNameError}>
                      SUBMIT USER NAME or EMAIL ADDRESS
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
            <div className="pageContainer" id="otpScreen" style={{display: (showOTP ? 'block' : 'none')}}>
              <h1 className="text-center title">Enter One Time Password</h1>
              <Box className="material-box" component="form" Validate onSubmit={submitOTPForm}>
                <div className="card-body">
                  <div className="screenIconsContainer">
                    {/*<Avatar>*/}
                    {/*  <VpnKeyIcon className="icon" />*/}
                    {/*</Avatar>*/}
                  </div>
                  <div className="formFlexContainer">
                    <div className="formInput">
                      <InputWithErrorMessage errorMessage={passwordCodeError}>
                        <TextField className="w-100" id="passwordCode" name="passwordCode" label="Enter One Time Password received via email" variant="outlined" onBlur={validatePasswordCodeField} onChange={changeOTP} />
                      </InputWithErrorMessage>
                      <span className="validationError">{errorMessage}</span>
                    </div>
                  </div>
                  <div className="buttonContainer loginButtonContainer">
                    {/*<Button variant="contained" color="primary" className="btn btn-primary" onClick={back}>*/}
                    {/*  <i className="fa fa-angle-left" aria-hidden="true"></i>&nbsp; Back*/}
                    {/*</Button>*/}
                    <Button variant="contained" type="submit" className="loginUIComp center mb-4" onClick={() => setOtpButtonState(1)} disabled={!!passwordCodeError}>
                      Submit One Time Password
                    </Button>
                    <Button variant="contained" type="button" className="loginUIComp center" onClick={regenerateOTP} disabled={!!passwordCodeError}>
                      Resend New One Time Password
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
            <div className="pageContainer" id="resetPasswordScreen" style={{display: (showResetPassword ? 'block' : 'none')}}>
              <h1 className="text-center title">Reset Your Password</h1>
              <Box className="material-box" component="form" Validate onSubmit={submitResetPasswordForm}>
                <div className="card-body">
                  <div className="screenIconsContainer">
                    {/*<Avatar>*/}
                    {/*  <LockResetIcon className="icon" />*/}
                    {/*</Avatar>*/}
                  </div>
                  <div className="formFlexContainer">
                    <div className="formInput">
                      <InputWithErrorMessage errorMessage={passwordError}>
                        <TextField type="password" className="w-100" id="password" label="Password" name="password" variant="outlined" style={{marginBottom:'30px'}} onChange={changePassword} onBlur={validatePasswordField}/>
                      </InputWithErrorMessage>
                    </div>
                    <div className="formInput">
                      <InputWithErrorMessage errorMessage={confirmPasswordError}>
                        <TextField type="password" className="w-100" id="confirmPassword" label="Confirm Password" name="confirmPassword" variant="outlined" style={{marginBottom:'30px'}} onChange={changeConfirmPassword} onBlur={validateConfirmField}/>
                      </InputWithErrorMessage>
                    </div>
                  </div>
                  <div className="buttonContainer loginButtonContainer">
                    {/*<Button variant="contained" color="primary"  onClick={back}>*/}
                    {/*  &lt;&lt; Back*/}
                    {/*</Button>*/}
                    <Button className="loginUIComp center" type="submit" variant="contained" disabled={!!passwordError || !!confirmPasswordError}>
                      Reset Password
                    </Button>
                  </div>
                </div>
              </Box>
            </div>
          </Container>
        </div>
        <div className="gradientBottom">
        </div>
    </div>
  )
}

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
