import {
    HOME_DIAGNOSTIC_FILTER_LIST,
    HOME_DIAGNOSTIC_ID,
    HOME_DISPLAY_DIAGNOSTICS,
    HOME_DISPLAY_TILES,
    HOME_ORBIT_LIST,
    SET_DEFAULT_ORBIT,
    HOME_DIAGNOSTIC_LIST,
    HOME_DEFAULT_ORBIT,
    HOME,
    INITIAL_TOP_NAV_DISPLAY,
    NAVIGATE_TO_HOME_PAGE,
    TERMS_CONDITIONS_COMPLETED,
    REFRESH_TILE_ATTRIBUTE_LIST,
    HOME_ORGANIZATION_DEPARTMENT,
    HOME_ORGANIZATION_DEPARTMENT_LIST,
    HOME_ORGANIZATION_DEPARTMENT_ID, HOME_DISPLAY_ORGANIZATION_DEPARTMENT
} from "./types.js";

export const setInitialTopNavDisplay = (value) => ({
    type: INITIAL_TOP_NAV_DISPLAY,
    payload: {
        initialTopNavDisplay: value
    }
})

export const setDefaultOrbit = (value) => ({
    type: HOME_DEFAULT_ORBIT,
    payload: {
        defaultOrbit: value
    }
})

export const setOrganizationDepartmentList = (value) => ({
    type: HOME_ORGANIZATION_DEPARTMENT_LIST,
    payload: {
        organizationDepartmentList: value
    }
})

export const setOrganizationDepartmentId = (value) => ({
    type: HOME_ORGANIZATION_DEPARTMENT_ID,
    payload: {
        organizationDepartmentId: value
    }
})

export const setDisplayTiles = (value) => ({
    type: HOME_DISPLAY_TILES,
    payload: {
        displayTiles: value
    }
})

export const setDisplayOrganizationDepartment = (value) => ({
    type: HOME_DISPLAY_ORGANIZATION_DEPARTMENT,
    payload: {
        displayOrganizationDepartment: value
    }
})

export const setDiagnosticId = (value) => {
    return ({
        type: HOME_DIAGNOSTIC_ID,
        payload: {
            diagnosticId: value
        }
    })
}

export const setDisplayDiagnostics = (value) => ({
    type: HOME_DISPLAY_DIAGNOSTICS,
    payload: {
        displayDiagnostics: value
    }
})

export const setOrbitList = (value) => ({
    type: HOME_ORBIT_LIST,
    payload: {
        orbitList: value
    }
})

export const setDiagnosticFilterList = (value) => ({
    type: HOME_DIAGNOSTIC_FILTER_LIST,
    payload: {
        diagnosticFilterList: value
    }
})

export const setDiagnosticList = (value) => ({
    type: HOME_DIAGNOSTIC_LIST,
    payload: {
        diagnosticList: value
    }
})

export const setNavigateToHomePage = (value) => ({
    type: NAVIGATE_TO_HOME_PAGE,
    payload: {
        navigateToHomePage: value
    }
})

export const setRefreshTileAttributeList = (value) => ({
    type: REFRESH_TILE_ATTRIBUTE_LIST,
    payload: {
        refreshTileAttributeList: value
    }
})

export const setHome = (value) => ({
    type: HOME,
    payload: {
        tileAttributes: value.tileAttributes,
        clusterType: value.clusterType,
        useFlexContainer: value.useFlexContainer,
        clusterCssArray: value.clusterCssArray
    }
})

export const setTermsConditionsCompleted = (value) => ({
    type: TERMS_CONDITIONS_COMPLETED,
    payload: {
        termsConditionsCompleted: value
    }
})
