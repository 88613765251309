import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTGSP2_2 = () => {
    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Understanding
                                                Your Diagnosis</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Most head and neck cancers are treated with surgery. Sometimes additional treatments such
                                                as radiation and/or chemotherapy are needed after surgery. Below is brief description of these
                                                different cancer treatments.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div
                                                        className="sidbarFill entDiagnosisSurgerySecondaryRedBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Surgery:</h2>
                                                        <p>This will involve removal of your cancer, possible removal of the lymph nodes
                                                            in your neck (“neck dissection”), reconstruction (“free flap surgery”), and a
                                                            possible surgically placed breathing tube in your neck (“tracheostomy”).</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div
                                                        className="sidbarFill entDiagnosisSurgerySecondaryRedBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Radiation:</h2>
                                                        <p>A treatment that uses high-energy particles or waves, such as x-rays, gamma
                                                            rays, electron beams or protons to damage or destroy cancer cells.
                                                            This is given after you have healed from surgery.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div
                                                        className="sidbarFill entDiagnosisSurgerySecondaryRedBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Chemotherapy:</h2>
                                                        <p>A type of cancer treatment that uses medications to increase the effects of
                                                            radiation therapy and to limit or stop the spread of cancer cells. This is also
                                                            called “chemo.” This is sometimes used based on the type and extent of your
                                                            cancer. If chemotherapy is needed, it is given at the same time as radiation. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <p className="question" style={{marginTop: 20}}>Overview</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p>More than 45,000 people in the United States are diagnosed every year with a mouth or throat
                                                        cancer.</p>
                                                    <p>The diagnosis of an oral cavity (“mouth”) or pharyngeal (“throat”) cancer means that abnormal
                                                        cells are growing in these areas. Most of the time, these cells are from the lining of these areas
                                                        (also known as the “mucosa”). Often these cancers are related to tobacco use or excess alcohol consumption. Some types of throat cancer are caused by HPV (the human papillomavirus).
                                                        Sometimes cancers can develop without a clear explanation.</p>
                                                    <p>Your cancer stage is based on the extent and type of cancer you have, as well as whether the
                                                        cancer has spread to the lymph nodes in your neck or elsewhere in the body. Your treatment
                                                        team will balance the goals of getting rid of your cancer while preserving your ability to speak
                                                        and swallow. Treatment usually entails surgery. Sometimes radiation therapy
                                                        and/or chemotherapy is given afterwards.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <p className="question">Mouth and Throat Cancers.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <Accordion className="moreLess">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>MORE &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="col-12">
                                                                    <div className="w-100">
                                                                        <p>The risk of developing oral cavity (mouth) or oropharyngeal (throat) cancer is about 1 in 60 for men and 1 in 141 for women. The greatest risk factor for developing oral cavity cancer is tobacco and alcohol use. It is very important for people who have been diagnosed with mouth or
                                                                            throat cancer to quit all tobacco products. The usual treatment for oral cavity cancer is surgery.
                                                                            Radiation and/or chemotherapy may be recommended after surgery.</p>
                                                                        <p>Most throat cancers are now caused by the human papillomavirus (HPV). Surgery, radiation,
                                                                            and/or chemotherapy are very effective in treating HPV-related throat cancers. Throat cancers
                                                                            which are very large or have come back after initial treatment may require an extensive surgery
                                                                            and reconstruction. </p>
                                                                        <p>Treatment of mouth and throat cancers can impact speaking, swallowing and breathing. <strong><em>See</em></strong>&nbsp;
                                                                            <a href="/siteman-ent-tgsp-2-3-3">Your cancer removal ></a></p>
                                                                    </div>
                                                                </div>
                                                                {/*<hr className="width100"/>*/}
                                                                <div className="less">
                                                                    <Accordion.Header>LESS &nbsp; <i
                                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>

                                                </div>
                                            </div>

                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <p className="question">Skin Cancers.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <Accordion className="moreLess">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>MORE &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="col-12">
                                                                    <div className="w-100">
                                                                        <img
                                                                            className="floatLeft wrapImage col-6 clearLeft"
                                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/60_skin-20190410.jpeg"
                                                                            alt=""/>
                                                                        <p className="floatNone clearNone col-6 noPaddingLeft">Skin cancers are very common in the United
                                                                            States. It’s estimated that 1 in 5 Americans
                                                                            will develop a skin cancer by the age of 70.
                                                                            Both melanoma and nonmelanoma skin cancers (e.g., basal cell carcinoma, squamous cell
                                                                            carcinoma) are most commonly caused by
                                                                            exposure to UV radiation from the sun. Some
                                                                            patients who take immunosuppressant medications are at increased risk of developing skin
                                                                            cancers as well.<br/><br/>
                                                                            <strong><em>See</em></strong> <a
                                                                                href="/siteman-ent-tgsp-2-3-2">Your cancer
                                                                                removal ></a><br/></p>

                                                                            <p>Most skin cancers on the head and neck are caught at an early stage and can be treated with
                                                                                small surgical procedures. However, some can grow very large and even invade surrounding
                                                                                tissues, including muscle, nerves, and bone. Your cancer stage is based on the extent and type
                                                                                of cancer you have, as well as whether the cancer has spread to the lymph nodes in your neck
                                                                                or elsewhere in the body. Treatment usually entails surgery and sometimes radiation therapy
                                                                                afterwards.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="less">
                                                                    <Accordion.Header>LESS &nbsp; <i
                                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </div>
                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <p className="question">Parotid Cancers.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 questionAndAnswer">
                                                <div className="w-100">
                                                    <Accordion className="moreLess">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>MORE &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="col-12">
                                                                    <div className="w-100">
                                                                        <img
                                                                            className="floatLeft wrapImage col-6 clearLeft"
                                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/salivary-glands-and-facial-nervev2_3x2.jpg"
                                                                            alt=""/>
                                                                        <p className="floatNone clearNone col-6 noPaddingLeft">Cancers affecting the parotid gland are relatively uncommon and affect less than 1 adult
                                                                            out of 100,000 people in the United States
                                                                            each year. Parotid cancers can arise directly
                                                                            from the parotid gland itself or cancers from
                                                                            the skin can sometimes spread to the lymph
                                                                            nodes within the parotid gland. Aggressive
                                                                            parotid gland cancers can invade the facial
                                                                            nerve and cause facial weakness
                                                                            or paralysis. Sometimes they can invade
                                                                            into the overlying cheek skin and/or spread
                                                                            to the lymph nodes near the jaw in the neck.
                                                                            Treatment usually entails surgery and may also include radiation therapy and/or chemotherapy
                                                                            after surgery.</p>
                                                                    </div>
                                                                </div>
                                                                <div className="less">
                                                                    <Accordion.Header>LESS &nbsp; <i
                                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </div>
                                            <div className="col-12 backToTop">
                                                <div className="w-100">
                                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor"
                                                          href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a>
                                                    </p>
                                                    <hr className="width100 entDiagnosisSurgeryRedBorderColor"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
)
};
export default SitemanENTTGSP2_2;
