import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import {tempLoginUser} from "../../../actions/tempLogin.js";
import {useDispatch, useSelector} from "react-redux";
import {setLoginScreenStatus} from "../../../helpers/index.js";


const TempLogin = (props) => {
    const { accessToken, counterToken } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const {errorMessage} = useSelector(state => state.auth);

    useEffect(async () => {
        const tlu = await tempLoginUser({accessToken, counterToken}, () => {
            setLoginScreenStatus(false);
            navigate('/');
        });
        await dispatch(tlu);
        //get default orbit for logged in user
    }, []);

    useEffect(async () => {
        if (errorMessage && errorMessage.length > 0)
            setDisplayErrorMessage(true);
        else
            setDisplayErrorMessage(false);
    }, [errorMessage])

    return (
        <div className="w-100">
            <div className="w-100">
                <Container className="tempLogin">
                    {!displayErrorMessage &&
                    <div className="pageContainer" style={{margin: "auto", textAlign: "center"}}>
                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/spinner.gif" />
                    </div>
                    }
                    {displayErrorMessage &&
                        <div className="pageContainer">
                            <p style={{fontWeight: "bold", fontSize: 28}}>
                                Authentication Failed: You are not authorized to access this site.<br/>  Please contact your
                                administrator for additional assistance.
                            </p>
                        </div>
                    }
                </Container>
            </div>
        </div>

    )
};

export default TempLogin;

