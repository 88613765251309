import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL6Component({ code, language }) {
    code = `{/* L6 Background image header bar */}
                                    <div className="lego l006 legoMargin3">
                                        <div className="backgroundImage" style={{
                                            backgroundImage: "url(" + "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/93a16df86d1db0ea41026dd5db201ab8.png" + ")"
                                        }}>
                                            <div className="transparentBarTypeContainer" style={{
                                                backgroundColor: "#0181E2" }}>&nbsp;&nbsp;</div>
                                            <h2 className="l6SubheadWithBackground">About Us</h2>
                                        </div>
                                    </div>
                                    {/* End L6 Background image header bar */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL6Component;