import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonStockingsWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonStockings">Applying Elastic Stockings</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStockings/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStockings/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStockings/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStockings/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ES_2.png" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Exercise</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You want the person you provide care for to stay active and exercise as much as possible. This keeps circulation in the legs flowing. If the person has recently been ill or has had surgery, <span className="bold">know the restrictions ordered by his or her doctor.</span></p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Have the person get out of bed and move around as soon as possible after surgery or illness</li>
                                            <li>When sitting in front of a TV or lying in bed, have the person exercise their lower legs every hour</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Here are some simple foot and leg exercises:</p>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ES_3.png" className="img-responsive floatNone maxWidth300" />
                                            <p className="captionItalic text-center w-100">Alternate dorsiflexion and plantar flexion</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Rotate each ankle in a full circle. Imagine drawing circles with the big toe. Perform the exercise slowly in a full circle. Repeat the exercise 5 times with each foot. Repeat every 2 hours while awake</li>
                                            <li>Stretch and contract the calf muscles. Do this by having the person push their feet against your hand, then alternate by stretching their foot back with the toes facing their knee. (see Photo) Stretch the muscles smoothly and slowly. Repeat 5 times with each foot. Repeat every 2 hours while awakeng</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Have the person do the same exercises listed above, during trips on a bus, train, or airplane.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Medicines</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Be sure the person takes their anticoagulants on time</li>
                                            <li>Be sure the person has the regular blood tests (a PT/ INR = Prothrombin time and International Normalized Ratio) or a PTT (Partial Thromboplastin Time) as ordered by their doctor. The tests tell a doctor how well the blood is clotting and the amount of anticoagulant a person should take</li>
                                            <li>Know the side effects of anticoagulants. A side effect is a predictable, often unwanted effect of a medicine. The main side effect of anticoagulants is bleeding. Some bleeding is normal, such as:</li>
                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent greenBullets bulletsSafeCareIndent">
                                                <li>Bleeding of the gums (often seen while brushing teeth)</li>
                                                <li>cut bleeding longer than normal</li>
                                            </ul>
                                            <li>Abnormal bleeding includes:</li>
                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent greenBullets">
                                                <li>Blood in the urine</li>
                                                <li>Coughing up bloody mucus</li>
                                                <li>A cut that will not stop bleeding</li>
                                                <li>Blood in the person’s stool (bowel movement)</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Use and Care of Stockings</h2>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ES_4.png" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Buy the stockings at a reliable medical equipment store or pharmacy where someone can fit the person with the right sized stocking</li>
                                            <li>Put on the stockings each morning when the person awakens from sleep. If you wait, the feet may swell during the day, making it harder to apply the stockings</li>
                                            <li>Be sure the person wears the stockings regularly, as ordered by their doctor</li>
                                            <li>Have the person remove the stockings before going to bed</li>
                                            <li>Have 2 pairs of stockings so that a clean set is available at all times</li>
                                            <li>Have a person wear nonskid shoes when getting up with elastic stockings to prevent slipping and risk for a fall</li>
                                            <li>Wash the stockings every 2 days in warm water and mild detergent. Rinse well to remove soap and residue. Then lay flat on a towel to air dry completely.</li>
                                            <li>Do not use bleach to wash the stockings.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 coloredContentBox">
                                    <div className="w-100">
                                        <div className="roundedColorBackgroundBoxPriority deepBlueBackground">
                                            <h1 className="font500">When applying a stocking:</h1>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom whiteBullets whiteText">
                                                        <li>First place your hand inside the stocking and turn the stocking inside out, while holding onto the heel. Stop at the heel.</li>
                                                        <li>Then apply the stocking over the foot, being sure the heel is in the heel pocket</li>
                                                        <li>Then slowly pull the stocking up over the leg, without any wrinkles. See the video in Show Me How for steps to apply stockings</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Be sure the stocking remains smooth, up over the leg and that wrinkles do not form. Do not let the person roll the stocking down halfway. This will cause a tightening around the leg that can increase chances of a clot forming</li>
                                            <li>If the stockings won’t stay up, they may be too big, or too old. After time, the stockings will no longer be able to support the veins.</li>
                                            <li>If the person has an open wound or skin lesion, the stocking may irritate or worsen the condition. Check with their doctor about how to treat the skin problem</li>
                                            <li>Some people have skin reactions to the stockings. This may be <strong><em>due to an allergy to the elastic</em></strong> in the stocking.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder greenBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Signs of allergy include:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Redness of the skin</li>
                                                    <li>Hive like lesions</li>
                                                    <li>Itching or burning of the skin</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Contact the home health agency or the doctor’s office to report any problems with the skin.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Observe for Signs of A DVT (Deep Vein Thrombosis)</h2>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ES_5.png" className="img-responsive floatNone maxWidth300" />
                                            <p className="captionItalic text-center w-100">Deep Vein Thrombosis of the Thigh</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Here are some ways to help reduce a perWhen you provide care for a person who is at risk for DVT, know what to look for. You want to recognize any signs or symptoms as soon as possible. However, according to the National Heart Lung and Blood Institute, only about half of the people who have DVT have warning signs or symptoms. Always check the person’s legs and skin each time you remove or replace a stocking. Generally speaking, elastic stockings are worn through-out the day and removed at bedtime. This is a good time to do skin checks.</p>
                                            <p>Each time you remove the stocking look at both legs carefully, all around each leg.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Notice the color of the skin, is there any area of redness?</li>
                                                <li>Take the palm of your hand and place it over the skin of each leg. Does the skin feel cool? Warm? Does each leg feel the same?</li>
                                                <li>Notice if one leg is larger than the other, this could mean swelling</li>
                                                <li>Ask the person at this time if they feel any pain in the calves or thighs</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Signs and Symptoms of a DVT</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Swelling of the leg or along a vein in the leg</li>
                                            <li>Pain or tenderness in the leg, which you may feel only when standing or walking</li>
                                            <li>Increased warmth in the area of the leg that is swollen or in pain</li>
                                            <li>Red or discolored skin on the leg</li>
                                            <li>Fever or chills</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className=" text-center subheadUpperAndLowerColorBarIcon centerBar greenColorBar w-100"><i class="fa fa-user-md"></i>
                                            When any of these signs or symptoms develop, call the person’s doctor immediately
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Signs and Symptoms of a DVT</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If a person has a DVT and the clot loosens and travels to the lung, you need to know the <span className="bold">Signs and Symptoms of a Pulmonary Embolus:</span></p>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Unexplained shortness of breath</li>
                                            <li>Problems breathing</li>
                                            <li>Chest pain</li>
                                            <li>Coughing or coughing up blood</li>
                                            <li>An irregular pulse</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className=" text-center subheadUpperAndLowerColorBarIcon centerBar greenColorBar w-100"><i class="fa fa-exclamation-circle"></i>
                                            When any of these signs or symptoms develop, call 911 immediately for medical assistance
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Travel Tips</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Travel increases the risk of developing a DVT. A person is inactive while sitting in a car, train, bus, or airplane. Inactivity slows the blood flow in the legs. The risk while flying is greater if a flight is longer than 4 hours. Flying a high altitude causes dehydration, which makes the blood “thicker” and more likely to form clots. Here are some tips from the National Heart, Lung and Blood Institute:</p>
                                            <p>During a long trip, have the person you provide care for:</p>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Walk up and down the aisles of a bus, train or airplane every 2 hours. If driving a car, stop about every hour and walk around</li>
                                            <li>Move their legs and flex and stretch their feet to improve blood flow in the calves</li>
                                            <li>Wear loose and comfortable clothing. Do not wear anything tight around the legs, such as rolled socks</li>
                                            <li>Drink plenty of fluids (at least eight to ten 8- ounce glasses of liquids a day, unless otherwise ordered by the doctor)</li>
                                            <li>The person’s doctor may recommend wearing compression stockings during a trip</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>If a person has a clot in their leg and it breaks off, traveling to the lungs, this is a pulmonary embolus PE, a life threatening event. If the person has unexplained shortness of breath, pain with deep breathing, or coughs up blood <strong>call emergency medical services, 911, immediately.</strong></li>
                                            <li>A rapid and irregular heart rate can also be a sign of a PE. See our lesson on <a href="/LessonPulse" target="_blank">Taking a Pulse</a>.</li>
                                            <li>A stocking can irritate or worsen a skin lesion on the leg. Check with the doctor about applying a stocking.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 video">
                                    <div className="w-100">
                                        <h3 className="blueColor videoTitle width100 text-center">
                                            Learn the proper way to apply elastic stockings.
                                        </h3>
                                        <div className="floatLeft width100 captionBelow">
                                            <div className="embedContainer">
                                                <YouTube videoId="hhAPGnx5T0Y" opts={opts} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="equipment">EQUIPMENT</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ES_6.png" className="img-responsive floatNone maxWidth300" />
                                            <p className="captionItalic text-center w-100">&nbsp;</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Pair of stockings</li>
                                                <li>Powder or cornstarch is optional if person is not allergic</li>
                                                <li>Option – tape measure</li>
                                                <li>Option – pair of disposable gloves</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonStockingsWhatToDoAndSafety;