import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTL2_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Understanding Your Diagnosis</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>More than 12,000 people are diagnosed in the U.S. with laryngeal cancer (cancer of the voice box) each year. While not every person with laryngeal cancer undergoes a total laryngectomy, in some cases the tumor is so large or the voicebox is not functioning and a total laryngectomy is necessary. There are several hundred thousand individuals who have had a total laryngectomy living around the world right now.</p>
                                            <p>The diagnosis of laryngeal cancer means abnormal cells are growing on the structures of the larynx. Laryngeal cancer affects the ability to speak, swallow and breathe normally.</p>
                                            <p>Your cancer stage is based on the extent of the tumor in the voicebox and in the lymph nodes in your neck. Your cancer stage will determine your recommended treatment.</p>
                                            <p>Treatment for laryngeal cancer includes one or more of the following: surgery, radiation, and/or chemotherapy. Your medical team’s goal is to get rid of your cancer and maintain your ability to breath, swallow and communicate as best as possible.</p>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <p><strong>Treatments for Laryngeal Cancer &mdash;</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidbarFill sitemanENTDarkRedBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Total Laryngectomy:</h2>
                                                        <p>A surgery that completely removes the larynx.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidbarFill sitemanENTDarkRedBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Radiation:</h2>
                                                        <p>A cancer treatment that uses high-energy particles or waves, such as x-rays, gamma rays, electron beams or protons to destroy cancer cells.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="sidbarFill sitemanENTDarkRedBackground">
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Chemotherapy:</h2>
                                                        <p>A cancer treatment that uses medications to destroy cancer cells throughout the body and to increase the effects of radiation therapy. This is also called "chemo."</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL2_2;
