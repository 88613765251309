import React from "react";
import { CodeBlock } from "react-code-block";
import { useCopyToClipboard } from "react-use";
import "@fortawesome/fontawesome-svg-core/styles.css";
import YouTube from "react-youtube";

function LegoL101Component({ code, language }) {
  code = ` {/* L101 Icon Color Bar New */}
                  <div className="lego l101 legoMargin1">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Specific</span>
                        <br />
                        What exactly do you plan to do?
                        <br /> With whom?  Where? What time of day?
                        <br />
                        How many times per day/week?
                      </p>
                    </div>
                  </div>
                  {/* End L101 Icon Color Bar New */}
                  {/* L101 Icon Color Bar New */}
                  <div className="lego l101 legoMargin1">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldHeadLine">Measurable</span>
                        <br />
                        How will you measure and track this?
                      </p>
                    </div>
                  </div>
                  {/* End L101 Icon Color Bar New */}`;
  language = "jsx";
  const [state, copyToClipboard] = useCopyToClipboard();

  const copyCode = () => {
    // Logic to copy `code`
    copyToClipboard(code);
  };

  return (
    <CodeBlock code={code} language={language}>
      <div className="relative">
        <CodeBlock.Code className="bg-black">
          <div className="table-row">
            <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
            <CodeBlock.LineContent className="table-cell">
              <CodeBlock.Token />
            </CodeBlock.LineContent>
          </div>
        </CodeBlock.Code>
        <div className="buttonContainer">
          <button className="btn btn-primary ml-auto" onClick={copyCode}>
            <i className="fa-solid fa-copy"></i>{" "}
            {state.value ? "Copied!" : "Copy code"}
          </button>
        </div>
      </div>
    </CodeBlock>
  );
}

export default LegoL101Component;
