import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin3_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorLimeGreen pritikinBorderLimeGreen">Why
                                                Pritikin</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower">Why Pritikin Intensive Cardiac
                                                Rehab?</h2>
                                            <p>Cardiac rehab is a time-tested, proven, and well-established component of
                                                recovery from a heart event. Yet, traditionally, the focus has been
                                                solely on exercise.</p>
                                            <p>Pritikin Intensive Cardiac Rehab is a comprehensive program which
                                                incorporates not only exercise, but also <span className="bold italic">heart-healthy nutrition and healthy mind-set education</span> aimed
                                                at strengthening every facet of your well-being.</p>
                                            <p>Pritikin Intensive Cardiac Rehab (ICR) is based on the Pritikin Program,
                                                which has been taught to more than 100,000 individuals at the Pritikin
                                                Longevity Center since 1975. More than 100 peer-reviewed, published
                                                studies, performed by UCLA medical researchers, have established the
                                                program’s remarkable results.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides btmMargin30">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-5 colorBox noTopRadius">
                                                    <div
                                                        className="sidbarFill withBodyCopy sidebarFillBackgroundColor   pritikinBackgroundLimeGreen colorBackgroundNoRadius centered noTopRadius">
                                                        <p>Individuals taught at the Pritikin Longevity Center since
                                                            opening in 1975</p>
                                                        <h3>100K</h3>
                                                    </div>
                                                </div>
                                                <div className="rightCol col-5 colorBox">
                                                    <div
                                                        className="sidbarFill withBodyCopy sidebarFillBackgroundColor  pritikinBackgroundLimeGreen colorBackgroundNoRadius centered noTopRadius">
                                                        <p>Peer-reviewed, published studies, performed by UCLA medical
                                                            researchers</p>
                                                        <h3>100+</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Pritikin is the leader in the market: a time-tested, evidence-based,
                                                comprehensive, and sustainable program with a high level of patient
                                                satisfaction. Pritikin ICR provides you with the tools and knowledge you
                                                need to succeed, both during cardiac rehab and long after cardiac rehab
                                                is complete.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn italic">Pritikin’s comprehensive learning model
                                                consists of:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom pritikinLimeGreenBullets tighSpacingList bulletsSafeCareNoIndent">
                                                <li>Safe, supervised exercise</li>
                                                <li> In-depth education through immersive workshops, in-depth videos,
                                                    live cooking demonstrations, and individualized consultations.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 floatRight">
                                        <div className="w-100">
                                            <p className="bold italic centered pritikinColorLimeGreen">The flexible
                                                curriculum will be tailored to fit your needs.</p>
                                            <p className="bold italic centered pritikinColorLimeGreen">Loved ones are
                                                encouraged to attend each session for additional motivation and
                                                support.</p>
                                        </div>
                                    </div>
                                    <div
                                        className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder pritikinBorderLimeGreen">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2>Through Pritikin ICR, you will learn how to:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom pritikinLimeGreenBullets">
                                                    <li>Implement significant lifestyle changes</li>
                                                    <li>Create a personalized Nutrition Action Plan and Exercise Action
                                                        Plan
                                                    </li>
                                                    <li>Make heart-healthy choices when preparing a meal or dining out
                                                    </li>
                                                    <li>Utilize a variety of strategies for tobacco cessation (if
                                                        needed)
                                                    </li>
                                                    <li>Manage stress in your daily life</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Ultimately, through our Healthy Mind-Set Workshops, you will discover
                                                simple and actionable techniques that you can incorporate into your
                                                daily life as you continue the recovery process.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin3_0;