import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonInhalersWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInhalers">Inhalers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Have the person take their peak expiratory flow diary with them for each doctor visit.</li>
                                            <li>Let the doctor know if the person has difficulty using the inhaler correctly. Ask the doctor if a different form of the medicine can be given instead.</li>
                                            <li>Let the doctor know if you have noticed any side effects from the medicines the person is inhaling.</li>
                                            <li>Keep notes in your care diary about how the person breathes before and after inhaling a medicine. Show your notes to the doctor.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInhalersWhatToTellYourDoctor;