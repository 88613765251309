import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTGSP2_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="w-100">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <a href="#" className="tile2-3-1">
                                        <div className="tile Height1RowTall width2Column  t_009 t_009_2x1 sitemanENTDarkRedBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Parotid Cancer Removal ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <a href="#" className="tile2-3-2">
                                        <div className="tile Height1RowTall width1Column t_001 sitemanENTDarkRedBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Skin Cancer Removal ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <a href="#" className="tile2-3-3">
                                        <div className="tile Height1RowTall width3Column t_007 t_007_3x1 sitemanENTRedBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Oral Cavity/Soft Tissue and Tongue ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="col-12 paddingLR5 hvr-grow">
                                    <a href="#" className="tile2-3-4">
                                        <div className="tile Height1RowTall width3Column t_007 t_007_3x1 backgroundAccentRed">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Oral Cavity/Bone Cancer Removal ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                       </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP2_3;
