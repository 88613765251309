import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTAll1_2 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Your Reconstruction: Free Flaps Reconstruction'}, () => {
            console.log('Your Reconstruction: Free Flaps Reconstruction');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center orangeColor orangeColorBorder">Helping
                                                Your Success</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You can take steps right now to guide you to be your healthiest for your
                                                new start. Your success begins with creating a true understanding of
                                                your path through this journey – the nature of your procedure, how to
                                                help ensure the best outcomes, and what your recovery will entail.
                                                Everyone involved – you, your family, your care team and other resources
                                                available to you – can all come together to help you through this
                                                journey.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <div className="floatLeft w-100">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="umWIvIe9144" opts={opts} onPlay={playVideo} />
                                                </div>
                                            </div>
                                            <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">Helping Yourself on Your Journey</span></h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Your Journey</h3>
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ENT_1.2_journey_map.svg" className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop orangeColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanENTAll1_2;
