import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';

function LegoL14Component({ code, language }) {
    code = `{/* L14 Paragraph */}
                                            <div className="lego l00paragraph">
            <p>You can take steps right now to guide you to be your healthiest for
                your
                new start. Your success begins with creating a true understanding of
                your path through this journey – the nature of your procedure, how
                to
                help ensure the best outcomes, and what your recovery will entail.
                Everyone involved – you, your family, your care team and other
                resources
                available to you – can all come together to help you through this
                journey.
            </p>
        </div>
                                            {/* End L14 Paragraph */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL14Component;