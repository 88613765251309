import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic10 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Managing Pain</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">IN GENERAL</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive w-100" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/managing_pain_image.jpg" alt=""/>
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="mobileMarginBottom30">The goal for pain management is to control your pain enough such that you are able to participate in your recovery. You should be able to <strong>remain awake, respond appropriately, perform your deep breathing and controlled coughing, and walk around.</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>There is a fine balance between controlling pain and causing over-sedation.</strong> You will be monitored frequently as pain medications are adjusted to provide optimal control. Preference is given to medications that provide pain relief without causing too much sedation.</p>
                                            <p>A realistic goal is a “3” or “4” on a scale of 1 to 10, with a “10” being the most severe pain you could possibly imagine.</p>
                                            <p>Medications that cause sedation include narcotics (morphine, hyromorphone, oxycodone, fentanyl, hydrocodone) and benzodiazepines (Ativan, Xanax, Valium, Ambien).</p>
                                            <p>Oral pain medications last longer than IV, thus these are started as soon as possible (usually when you are tolerating some liquids by mouth).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">OPTIONS FOR PAIN MANAGEMENT</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You will have a team of multi-disciplined experts to identify how to best relieve your pain on an individual basis using a combination of the following treatment options.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Epidurals</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>These are catheters inserted in the space around your spinal cord through the skin in your back before, during, or after your surgery. They are used to continuously infuse an anesthetic. They are managed by the pain team.</p>
                                            <p>You will be able to walk around as the targeted area of pain relief is the abdomen. Risks will be discussed prior to placement but include low blood pressure, headache, extremity numbness, and inability to urinate when the bladder catheter is removed.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Patient-Controlled Analgesia (PCA)</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Also known as the “pain button,” this is a method where you deliver doses of pain medication (usually morphine or hyromorphone) yourself. There is a “lockout” time (usually 10 to 15 minutes) so that you cannot give yourself too much pain medication. This is started in the postoperative area and you are instructed on its use.</p>
                                            <p><strong><em>NEVER</em></strong> let anyone else press the pain button besides yourself. If someone else pushes the button while you are asleep then it could slow or stop your breathing.</p>
                                            <p>Side effects of the IV narcotics include difficulty breathing, low blood pressure, confusion, sleepiness and constipation.</p>
                                            <p>After the PCA is discontinued you may receive the same medication in an IV as needed every 2 to 4 hours to help bridge the transition to oral pain medications.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Toradol and other NSAIDS</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>NSAIDS <strong><em>(Non-Steroidal Anti Inflammatory Drugs)</em></strong> are used in combination with narcotic medications. Toradol is given as an IV. Oral forms include ibuprofen, naproxen. These are helpful to reduce narcotic use, thus reducing narcotic side effects such as constipation.</p>
                                            <p>They may be given scheduled or as needed.<br/>
                                                <strong><em>Risks include kidney injury, bleeding.</em></strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Tylenol</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>This is given orally either scheduled or as needed. Similar to NSAIDS, Tylenol can reduce the use of narcotics and thus reduce their side effects.<br/>
                                                <strong><em>Risks include liver injury</em></strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Oral narcotics</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong><em>(in order of increasing potency)</em></strong></p>
                                            <p><strong>Tramadol:</strong> a mild opioid that can be given every 4 to 6 hours.</p>
                                            <p><strong>Hydrocodone:</strong> an opioid narcotic that is combined with Tylenol (Vicodin, Norco) that can be given every 4 to 6 hours as needed.</p>
                                            <p><strong>Oxycodone:</strong> an opioid narcotic that is either given alone or combined with Tylenol. Oxycodone alone can be given more frequently (every 2 to 4 hours) as needed. This can be combined with Tylenol separately. Or the combination together (Percocet) can be given every 4 to 6 hours as needed</p>
                                                <p><strong>Others (not commonly used after surgery):</strong> morphine tablets, Fentanyl patch, hyromorphone tablets</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">OTHER MEDICATIONS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Your Existing Home Medications</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Important home medications are started as soon as possible and converted to IV form initially if necessary. Certain medications are held if they have the potential to interact with the medications you are receiving in the hospital.</p>
                                            <p>If there is a medication you are concerned about receiving, let the team know. In most cases all of your normal home medications will be resumed upon discharge.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Pasireotide/ Octreotide</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Pasireotide is a medication that is given to all patients having pancreatic surgery in order to help prevent pancreatic leaks. It is an injection given twice per day under the skin for 7 days. Octreotide is a similar medication that you may receive if there is a significant pancreatic leak. It is a long acting injection given in a large muscle (usually the buttocks or shoulder).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Heparin or Lovenox</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>These are medications given to help prevent blood clots. They are injected under the skin. Shorter-lasting Heparin would be injected three times per day. Longer-lasting Lovenox would be injected once per day.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Acid-Reducing Medications (includes PPI’s and H2 blockers)</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Also known as H2 blockers, these medications reduce acid in the stomach. They are given IV immediately post-operatively, then converted to oral and continued for a month after discharge. Proton Pump Inhibitors (PPI’s) include Nexium and Protonix. H2 blockers include Zantac and Pepcid.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Electrolytes</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your electrolytes are monitored with daily labs and you may be given IV or oral potassium, magnesium, phosphorus, or calcium if your levels are low.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Bowel Regimens</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Constipation is very common after surgery. Anesthesia, entering the abdomen and manipulating the bowels during the operation, and narcotic pain medications all contribute to slow bowel motility.</p>
                                            <p>Stool softeners are started as soon as you are taking sips of liquids. They are continued even if you are passing gas or having bowel movements. They are only stopped if you are having diarrhea.</p>
                                            <p>You may also be started on Miralax, magnesium citrate, or receive a Dulcolax suppository or enema.</p>
                                            <p>Frequent walks and minimizing narcotics reduce constipation and return bowels to normal function more quickly.</p>
                                            <p>You will be given prescriptions for a bowel regimen upon discharge.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Insulin</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>All patients receive a “sliding scale” of insulin after surgery.</p>
                                            <p>Your blood sugar is checked (by a finger stick) every 4 hours until you start drinking liquids, then checks occur before meals and before bedtime.</p>
                                            <p>One to 5 units of Insulin are injected based on your blood sugar.</p>
                                            <p>Often, the fluids and medications you receive as well as your own personal history of diabetes can cause the blood sugars to be so high that a sliding scale is not enough. You may need longer acting insulin or higher doses of short acting insulin. The endocrinology (diabetes) service may be consulted.</p>
                                            <p>If you still require insulin after adjustments are made to your fluids and diet, you will receive education regarding injecting insulin at home and will practice in the hospital.</p>
                                            <p>Blood sugar control is very important, especially after pancreas surgery. Uncontrolled blood sugars can lead to organ damage, electrolyte abnormalities, and even coma. Your goal is 130 to 160. A blood sugar that is too low can also be dangerous.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic10;
