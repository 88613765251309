import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import Accordion from "react-bootstrap/Accordion";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";

const SitemanENTTGSP4_1_1 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Wound Care: What is a Trach?'}, () => {
            console.log('Keys to Success: What is a Trach?');
        });
        await dispatch(pal);
    }


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center" style={{borderBottom: "0px", marginBottom: "0px"}}>This section only applies if you will have a tracheostomy tube or feeding tube as part of your surgery</h1>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <Accordion className="moreLess">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header style={{marginLeft: "20px"}}>Click Here &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="subheadAndBulletsWithFloat">
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h1 className="tileTitleHeader centeredPageHeader text-center">Tracheostomy
                                                                    tubes</h1>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                                            <div className="w-100">
                                                                <div className="type3AcrossContainer boxes">
                                                                    <div className="typeContainer text-center">
                                                                        <p><a href="#introduction">Introduction</a></p>
                                                                    </div>
                                                                    <div className="typeContainer text-center">
                                                                        <p><a href="#caring">Caring For Your Trach</a></p>
                                                                    </div>
                                                                    <div className="typeContainer text-center">
                                                                        <p><a href="#suctioning">Suctioning Your Trach</a></p>
                                                                    </div>
                                                                    <div className="typeContainer text-center">
                                                                        <p><a href="#mucusPlugs">Mucus Plugs</a></p>
                                                                    </div>
                                                                    <div className="typeContainer text-center">
                                                                        <p><a href="#protecting">Protecting Your Trach</a></p>
                                                                    </div>
                                                                    <div className="typeContainer text-center">
                                                                        <p><a href="#doctor">Doctor/911: When to Call</a></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h3 id="caring" className="sectionHeader w-100 text-center text-uppercase">Caring For Your Tracheostomy Tube At Home.</h3>
                                                                <p>If you leave the hospital with a tracheostomy (trach) tube, this section will give you
                                                                    guidance for how to care for your trach tube at home. </p>
                                                                <p>Your nurse will provide you with instructions and show you how to care
                                                                    for your trach tube. You will be given time to practice this care so you
                                                                    will feel more comfortable. We encourage your caregiver to also learn
                                                                    trach care so they may assist you at home if needed. Remember to keep
                                                                    instructions nearby and refer to them whenever needed.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                                                <h3 className="subheadUpperAndLowerColorBarIcon">NOTE: If your doctor’s instructions differ
                                                                    in any way from those listed here, always follow your doctor’s
                                                                    instructions.</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 video">
                                                            <div className="w-100">
                                                                <div className="floatLeft width100 captionBelow">
                                                                    <div className="embedContainer originalPadding">
                                                                        <YouTube videoId="7qoFQTna1Ec" opts={opts} onPlay={playVideo} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower text-center">What is a Trach?</h2>
                                                                <p>A trach (<em>or tracheostomy</em>) is an opening through the front of
                                                                    your neck into the trachea (<em>aka ‘windpipe’</em>). It will make
                                                                    breathing easier, and help remove mucus.</p>
                                                                <p>The tube, which is placed through this opening, is called a trach
                                                                    (tracheostomy) tube. The trach tube maintains the opening into your
                                                                    windpipe.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 images3AcrossAndText">
                                                            <div className="w-100">
                                                                <div className="imageContainer">
                                                                    <div className="centerCol col-6 paddingLeft7 paddingRight7" style={{margin: "auto"}}>
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cannula_obturator.svg"
                                                                             className="img-responsive"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong><em>Most trach tubes have 3 parts:</em></strong></p>
                                                                <p><strong><em>1. Inner Cannula:</em></strong> is inserted into outer
                                                                    cannula and snapped into place. It is taken out to be cleaned or
                                                                    replaced at least twice a day (or as needed to keep the tracheostomy
                                                                    free of mucus plugs.)</p>
                                                                <p><strong><em>2. Outer Cannula:</em></strong> maintains the opening through
                                                                    your neck into your windpipe. This should not be removed.</p>
                                                                <p><strong><em>3. Obturator:</em></strong> is used to insert the outer
                                                                    cannula more easily into the opening of the trachea if needed. It is
                                                                    then removed after insertion.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <div className="col-12 typeCol noPaddingLeft">
                                                                    <h2 className="subheadUpperandLower text-center">Always start with washing your hands!</h2>
                                                                    <p className="mobileMarginBottom30">To help prevent infections, you must
                                                                        wash your hands with a mild dishwashing soap and water before and
                                                                        after any care is done to your trach. A biodegradable one is best,
                                                                        such as Ivory.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 coloredContentBox">
                                                            <div className="w-100">
                                                                <div
                                                                    className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100">
                                                                    <h2 className="subheadUpperandLower text-center" style={{color: "black", fontWeight: 600}}>Caring for the skin
                                                                        around the trach site:</h2>
                                                                    <div className="col-12">
                                                                        <div className="w-100">
                                                                            <ul className="bulletsCustom">
                                                                                <li>Wash and dry hands.</li>
                                                                                <li>Keep the skin around the site as clean and dry as
                                                                                    possible.
                                                                                </li>
                                                                                <li>Apply wound cleanser on a clean washcloth or Q tip &
                                                                                    gently clean around the trach opening first thing in the
                                                                                    morning, before going to bed at night, and in between as
                                                                                    needed. DO NOT let any cleanser or water go into the
                                                                                    trach opening.
                                                                                </li>
                                                                                <li>Wipe off any dried mucus.</li>
                                                                                <li>Using a damp washcloth rinse the area and then pat area
                                                                                    with a dry washcloth.
                                                                                </li>
                                                                                <li>Place a pre-cut gauze dressing next to the skin under
                                                                                    the trach tube.
                                                                                </li>
                                                                                <li>Change Velcro trach collar daily or when soiled.
                                                                                    The velcro trach collar prevents accidental dislodgement
                                                                                    of the trach tube. Plan to have your
                                                                                    family member or friend help by holding the trach tube
                                                                                    in place. Remove the soiled trach collar carefully without
                                                                                    dislodging the trach tube. Thread the Velcro
                                                                                    tabs of the new trach collar through the opening in the
                                                                                    trach tube neck plate on each side. Loop velcro tabs back
                                                                                    on to the soft material. Overlap soft material in back of neck with
                                                                                    Velcro strip.<img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/gauze+dressing_496x496.jpg" className="img-responsive text-center" style={{margin: "auto", float: "none", paddingTop: 20, paddingBottom: 20}}/>
                                                                                </li>
                                                                                <li>Adjust and secure snugly to fit your neck. Do not secure
                                                                                    too loosely as your trach tube could fall out
                                                                                    accidentally. Tighten to only allow one finger width
                                                                                    between the trach collar and your neck.
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower text-center">Caring
                                                                    for the Inner Cannula</h2>
                                                                <p>Cleaning or replacing the inner cannula keeps the trach tube clean, open
                                                                    and free of mucus allowing you to breathe easily. Except for when
                                                                    cleaning your trach tube, it is important that you always have an inner
                                                                    cannula in your trach tube.</p>
                                                                <p><strong><em>Note:</em></strong> trach tubes have either a plastic or a
                                                                    metal inner cannula. Some specialized trachs do not have an inner
                                                                    cannula. Ask your nurse if you are not sure.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                                            <div className="w-100">
                                                                <div className="imageContainer">
                                                                    <div className="leftCol col-5 paddingRight7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/inner+cannula+plastic.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Plastic (disposable) inner cannula</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="rightCol col-5 paddingLeft7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/inner+cannula+metal.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Metal (reusable) inner cannula</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">If you have a plastic (disposable) inner cannula:</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Wash and dry hands.</li>
                                                                    <li>Care for the inner cannula in the morning and before going to
                                                                        bed at night and in-between times as needed.
                                                                    </li>
                                                                    <li>Remove the inner cannula and discard.
                                                                    </li>
                                                                    <li>Re-insert a new inner cannula into the outer cannula and lock into place.
                                                                    </li>
                                                                    <li>Suction trach as needed.</li>
                                                                    <li>Most insurance companies will pay for one new disposable inner cannula per day.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12" style={{marginTop: 20, float: "left"}}>
                                                            <div className="w-100">
                                                                <p className="boldLeadIn">If you have a metal (reusable) inner cannula:</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Wash and dry hands.</li>
                                                                    <li>Clean/care for the inner cannula in the morning and before going to
                                                                        bed at night and in between times as needed.
                                                                    </li>
                                                                    <li> Remove inner cannula and clean the inner cannula with a trach brush or pipe cleaner
                                                                        using mild dishwashing soap and water. A biodegradable soap is best
                                                                        such as Ivory. You can use the emesis basin to place the soapy water
                                                                        in. Scrub the inside and outside of the inner cannula.
                                                                    </li>
                                                                    <li>Rinse the inner cannula well under running tap water and dry well with a clean
                                                                        towel.
                                                                    </li>
                                                                    <li>Re-insert this inner cannula into the outer cannula and lock into
                                                                        place.
                                                                    </li>
                                                                    <li>Suction trach tube as needed.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                                            <div className="w-100">
                                                                <div className="imageContainer">
                                                                    <div className="leftCol col-5 paddingRight7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/suction+machine.webp"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Yankauer Suction Device<br/>with Suction Tubing</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="rightCol col-5 paddingLeft7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Suction+Contorl+Port.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Suction Control Port<br/>with Suction Catheter</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h3 id="suctioning" className="sectionHeader w-100 text-center">How
                                                                    to suction your trach:</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p>Suctioning removes the mucus you are unable to cough out, allowing you to
                                                                    breathe easier.</p>
                                                                <p>How often you will need to suction and how much mucus you will have
                                                                    varies. Having a cold, a sore throat or a respiratory infection as well
                                                                    as dry air in your home, all can affect the amount of mucus you
                                                                    have. </p>
                                                                <p><em>Supplies to have ready:</em></p>
                                                                <p className="boldLeadIn">Yankauer Suction Device:</p>
                                                                <p>a hard-plastic tube that is used to remove mucus from the mouth, or
                                                                    around the trach tube.</p>
                                                                <p className="boldLeadIn">Suction Tubing: </p>
                                                                <p>connects suction catheter or Yankauer device to suction machine</p>
                                                                <p className="boldLeadIn">Suction Catheter: </p>
                                                                <p>a thin soft tube that is placed down the trachea (windpipe) to remove
                                                                    mucus.</p>
                                                                <p className="boldLeadIn">Suction Control Port:</p>
                                                                <p>Your thumb covers this port on the suction catheter when withdrawing the
                                                                    suction catheter from the trach tube during tracheal suctioning</p>
                                                                <p className="boldLeadIn">Suction Machine:</p>
                                                                <p>the machine connects to the suction tubing uses suction (like a vacuum)
                                                                    to remove mucus from the trach.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower text-center">Suction Your Trach – step by step</h2>
                                                                <p>The suction catheter is attached to the suction tubing that attaches to
                                                                    the suction machine and is used to remove mucus from the trach tube.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Suctioning may be done through the inner or outer cannula.</li>
                                                                    <li>Wash and dry hands.</li>
                                                                    <li>Put on clean gloves.</li>
                                                                    <li>Turn on the suction machine.</li>
                                                                    <li>If suctioning yourself, stand or sit in front of a well-lighted
                                                                        mirror to see your trach tube clearly.
                                                                    </li>
                                                                    <li>If you have a high humidity trach collar or heat-moisture exchange
                                                                        (HME), remove it.
                                                                    </li>
                                                                    <li>Connect the suction catheter to the suction tubing coming from the
                                                                        suction machine
                                                                    </li>
                                                                    <li>Take several deep breaths.</li>
                                                                    <li>Gently insert suction catheter through trach tube until coughing
                                                                        occurs. <em><strong>Do not insert the suction catheter more than the length of
                                                                            your inner cannula.</strong></em>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong><em>DO NOT</em></strong> force the catheter and <strong><em>DO
                                                                    NOT</em></strong> cover the suction control port during insertion.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Once coughing occurs, apply suction by covering the port with your
                                                                        thumb and withdraw catheter slowly and gently, for no more than 3-5
                                                                        seconds. Gently rotate or twist the catheter when withdrawing to
                                                                        help remove mucus from all sides of the trach tube.
                                                                    </li>
                                                                    <li>Recover by taking several deep breaths (with oxygen applied if you
                                                                        are using home oxygen). Repeat suctioning with the same catheter
                                                                        until your windpipe feels and sounds clear of mucus. Rest between
                                                                        EACH suctioning until you catch your breath. Keep end of catheter
                                                                        clean during this time by not allowing it to touch anything.
                                                                    </li>
                                                                    <li>Re-connect your high humidity trach collar or HME, as necessary.
                                                                    </li>
                                                                    <li>When done, suction a small amount of water through the catheter and
                                                                        suction tubing to clean it out. Discard catheter inside discarded
                                                                        gloves— <strong><em>DO NOT RE-USE CATHETER</em></strong>.
                                                                    </li>
                                                                    <li>Do not let the suction bottle on your suction machine get more than
                                                                        half full or the suction will not be as strong. Empty contents of
                                                                        the suction bottle into a toilet.
                                                                    </li>
                                                                    <li>Clean suction bottle/tubing with hot soapy water every day and as
                                                                        needed because germs can grow after 24 hours.
                                                                    </li>
                                                                    <li>Leave your suction machine plugged into the wall outlet at all times
                                                                        to keep the back-up battery charged. You can use the car adapter for
                                                                        power to suction while riding in the car.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong><em>NOTE:</em></strong> You will receive your suction machine in
                                                                    the hospital before going home as well as instructions for use from the
                                                                    company who brought it to you. This is in case you need to suction mucus
                                                                    on the way home.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr className="w-100"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h3 id="mucusPlugs" className="sectionHeader w-100 text-center">Mucus Plugs</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower text-center">What Is
                                                                    A Mucus Plug?</h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Mucus plugs form when mucus builds up in your trach tube or
                                                                        windpipe. Dry air causes the mucus to become thick and form a
                                                                        plug. <strong><em>Mucus plugs are a common problem associated with
                                                                            trach tube.</em></strong></li>
                                                                    <li><strong><em>Mucus plugs must be removed right away or the trach tube may
                                                                        become blocked and you will not be able to
                                                                        breathe. This can be life-threatening.</em></strong></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 coloredContentBox">
                                                            <div className="w-100">
                                                                <div
                                                                    className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder width100">
                                                                    <h2 class="subheadUpperandLower text-center text-black">Signs of a mucus
                                                                        plug include:</h2>
                                                                    <div className="col-12 subheadAndBullets">
                                                                        <div className="w-100">
                                                                            <ul className="bulletsCustom">
                                                                                <li>Inability to pass the catheter down into the trach tube
                                                                                </li>
                                                                                <li>Struggling for breath</li>
                                                                                <li>Breathing faster than usual</li>
                                                                                <li> High pitched or whistling sounds coming from trach</li>
                                                                                <li>Restlessness</li>
                                                                                <li>Pale sweaty skin</li>
                                                                                <li>Heart beating faster than usual</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                                                <h3 className="subheadUpperAndLowerColorBar">What should I do if a mucus plug blocks my trach tube?</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><span className="numberCircle">1</span> <strong>REMAIN
                                                                    CALM.</strong> First, try to cough out the mucus plug.</p>
                                                                <p><span className="numberCircle">2</span> If unable to cough it out, try
                                                                    suctioning as explained in the previous section.</p>
                                                                <p><span className="numberCircle" style={{marginBottom: "25px"}}>3</span> If this does not remove the mucus
                                                                    plug, use a “saline bullet” (see image below). Instill a small amount of
                                                                    saline, (approximately 1/3 of a saline bullet) into the trach tube to
                                                                    loosen secretions. The saline will cause you to start coughing, aiding
                                                                    you to clear the mucus plug. Suction trach tube as explained in the previous section.
                                                                    You will be sent home from the hospital with several of these saline bullets.</p>
                                                                <p><span className="numberCircle">4</span> If the mucus plug continues,
                                                                    remove the inner cannula and repeat the instillation of saline. Suction
                                                                    as explained in the previous section.</p>
                                                                <p>If at any point you cannot breathe, remove the inner cannula
                                                                    immediately. </p>
                                                                <p className="bold"><strong><em>If the mucus plug does not immediately clear or you continue
                                                                    to experience difficulty breathing, call 911.</em></strong></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100 text-center">
                                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/saline+bullets_200x200.jpg" className="img-responsive" style={{margin: "auto", float: "none"}}/>
                                                                <p className="captionItalic"><strong>Saline Bullet</strong></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower text-center">If you
                                                                    have used all the pink saline bullets sent home with you,
                                                                    you can obtain the saline bullets from your local medical
                                                                    supply store with a prescription from your doctor, or you can make
                                                                    your own saline solution at home.</h2>
                                                                <p><em>To make a saline solution at home:</em></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Boil water for 5 minutes, and then add 1 and 1/2 level teaspoons of
                                                                        non-iodized salt per quart of water.
                                                                    </li>
                                                                    <li>Cool to room temperature prior to use.</li>
                                                                    <li>You can use an eye/ear medicine dropper or a small 3 mL syringe (can
                                                                        be purchased at any drugstore like CVS or Walgreen’s) to instill a
                                                                        dropper full or 3 mL of this saline solution into your trach.
                                                                    </li>
                                                                    <li>Discard unused saline solution after 24 hours because germs can grow
                                                                        in the solution. Make fresh saline solution every day.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadUpperandLower text-center">Humidity can help you prevent mucus plugs!</h2>
                                                                <p>The air you breathe through your trach tube does not pass through your
                                                                    nose and mouth where normally your body warms and moistens the air.
                                                                    Mucus plugs may form due to thick and dry secretions. Extra humidity
                                                                    must be added to the trachea to prevent mucus plugs from forming. A
                                                                    healthy humidity level eases breathing and reduces coughing.</p>
                                                                <p>When you are discharged from the hospital, your doctor will prescribe a
                                                                    humidity trach collar similar to the one you wore during your stay in
                                                                    the hospital. Some collars will give extra oxygen with the humidity
                                                                    while others will give air with the humidity. These collars provide the
                                                                    humidity to add extra moisture to the trachea to thin the mucus.</p>
                                                                <p>If you need to add more moisture, you can buy a room size humidifier from
                                                                    stores like Walmart or Target. Place the humidifier in the room you are
                                                                    in during the day and at your bedside at night. This humidity will help
                                                                    to thin mucus by putting moisture into the air. It is very important to
                                                                    clean the humidifier daily with soap and water, or vinegar and water
                                                                    because germs will begin to grow after 24 hours.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                                            <div className="w-100">
                                                                <div className="imageContainer">
                                                                    <div className="leftCol col-5 paddingRight7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/trach_humidifier_496x384.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Humidity Trach Collar</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="rightCol col-5 paddingLeft7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/home_humidifier_496x384.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Home Humidifier</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong><em>Other ways to help:</em></strong></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Take in plenty of fluids to keep your airway moist (unless told
                                                                        otherwise by your physician).
                                                                    </li>
                                                                    <li>Also during the winter months, wear a loosely crossed scarf or
                                                                        handkerchief over your trach tube to warm the air. Be sure the scarf
                                                                        material is such that it does not shed any tiny fibers that may get
                                                                        in your trachea. You can also purchase trach covers (see next
                                                                        section).
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong><em>NOTE:</em></strong> During the winter, air in your home will
                                                                    be drier. <strong><em>Your mucus may become pink or blood tinged which
                                                                        indicates a lack of moisture. Increase the humidification. If blood
                                                                        tinged mucus persists, contact your doctor.</em></strong></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr className="w-100"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h3 id="protecting" className="sectionHeader w-100 text-center">Protecting your Trach:</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 images2AcrossAndText paddingOnSides">
                                                            <div className="w-100">
                                                                <div className="imageContainer">
                                                                    <div className="rightCol col-5 paddingLeft7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/trach_shower_496x384.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Trach cover for the shower</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="leftCol col-5 paddingRight7">
                                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/trach_scarf_496x384.jpg"
                                                                             className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionBold">Trach covers/scarves </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li><strong><em>DO NOT allow water, soap, food or aerosol
                                                                        sprays</em></strong> into the trach tube.
                                                                    </li>
                                                                    <li> When showering, use a hand held shower spray or adjust the shower
                                                                        head so that the stream of water hits your body below the level of
                                                                        your trach. You will be sent home with a shower guard. A shower
                                                                        guard can also be bought at many medical supply stores. Wear a
                                                                        protective cover over the trach. Some people wear a bib that is
                                                                        plastic on one side and cloth on the other side. The plastic side is
                                                                        worn on the outside, to keep water out. If taking a tub bath, keep
                                                                        the water level below nipple line. If you have any other surgical
                                                                        wounds or drains, discuss bathing with your doctor.
                                                                    </li>
                                                                    <li>Shampoo hair while bending over a tall sink such as in the kitchen
                                                                        (be sure not to block your trach when bending over). Take care to
                                                                        loosely cover the trach with a dry towel to keep soap and water out.
                                                                    </li>
                                                                    <li>When shaving, do it in front of the mirror. Take care to loosely
                                                                        cover the trach with a dry towel to keep water and shaving cream out
                                                                        of your trach. If you have any other surgical wounds or drains,
                                                                        discuss shaving with your doctor.
                                                                    </li>
                                                                    <li><strong><em>DO NOT go swimming or use a hot tub (Jacuzzi,
                                                                        whirlpool).</em></strong> Water can quickly fill into your lungs and
                                                                        you can drown.
                                                                    </li>
                                                                    <li>You will be more sensitive to smoke, dust & fumes. Covering the
                                                                        trach can help prevent particles from entering your trach. When
                                                                        getting a haircut, remember to cover your trach to avoid tiny hairs
                                                                        blowing into your trach.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 coloredContentBox">
                                                            <div className="w-100">
                                                                <div
                                                                    className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder">
                                                                    <h2 className="subheadUpperandLower text-center">The following are
                                                                        vendors where you can purchase these trach covers and scarves:</h2>
                                                                    <p><a href="http://www.stomacoversandtrachbands.com/"
                                                                          target="_blank">stomacoversandtrachbands.com</a> or contact them
                                                                        at <a href="mailto:foryourneck@gmail.com">foryourneck@gmail.com</a>
                                                                    </p>
                                                                    <p>Bruce Medical Supplier: <span>1-800-225-8446</span></p>
                                                                    <p><a
                                                                        href="https://www.bosmed.com/airway-management/tracheostomy-products/"
                                                                        target="_blank">Trachs.com</a> or <span>1-800-433-2674</span> for
                                                                        scarves and other stoma protection devices</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr className="w-100"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h3 id="doctor" className="sectionHeader w-100 text-center">When
                                                                    Should I Call My Doctor?</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p><strong><em>Call your doctor if you experience any of the following:</em></strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>If you have pink or red streaked mucus from trach tube, increase humidification and/or use gentler
                                                                        movements when using the suction catheter and don’t insert suction catheter longer than the length
                                                                        of the inner cannula. If pink or red mucus persists, then call your doctor.
                                                                    </li>
                                                                    <li>Mild changes in your breathing.</li>
                                                                    <li>Increased amount of mucus.</li>
                                                                    <li>Thick, foul-smelling mucus, or odor coming from trach.</li>
                                                                    <li>Changes to your trach site or wounds—redness, warm to touch,
                                                                        swelling, or drainage.
                                                                    </li>
                                                                    <li>Temperature more than 100 degrees F.</li>
                                                                    <li>Poor appetite, not drinking, or generally not feeling well.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <h2 className="subheadAndCopyWithIcon text-center">
                                                                    <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                                    When should you call 911?
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li> If you experience a large amount of bright red blood from trach.
                                                                        Apply pressure to the area where blood is leaking. REMAIN CALM.
                                                                    </li>
                                                                    <li>Difficulty breathing or anytime you feel in distress related to your
                                                                        breathing.
                                                                    </li>
                                                                    <li>If your trach falls out and you are unable to replace it. This can
                                                                        happen when the trach collar is too loose, or from normal coughing,
                                                                        or coughing too hard.
                                                                    </li>
                                                                    <li>If you are not immediately able to clear a mucus plug after
                                                                        suctioning with saline.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                                                <h3 className="subheadUpperAndLowerColorBarIcon">
                                                                    <i className="fas fa-comment rightPaddingQuarterEm"></i> &nbsp;When you call 911 for help or
                                                                    instructions, make sure to tell them you have a tracheostomy!
                                                                </h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <p>If 911 service is not available in your area, have emergency numbers
                                                                    posted by each telephone.<br/>
                                                                    <strong>TIP:</strong> Enter the number(s) in your mobile phone under
                                                                    contacts.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 backToTop">
                                                            <div className="w-100">
                                                                <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                                    BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP4_1_1;
