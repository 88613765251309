import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonStitchesWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonStitches">Care of Stitches (Sutures)</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStitches/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStitches/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStitches/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonStitches/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/COS_2.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Follow the doctor’s directions for what to look for and how to clean the wound</li>
                                            <li>If the person has had surgery, be sure to look at the bandage or wound when the person first comes home from the hospital. That way you will have something to compare with over the next several days. Some bandages may need to be left on for a short period of time after surgery (i.e. hip replacement) and therefore you may not look at the actual wound for a few days, you will only look at the bandage.</li>
                                            <li><span className="italic bold">Look at the wound/bandage each day</span>. Keep notes in your Care Diary. Write down what the wound looks like and any signs of infection.</li>
                                            <li><span className="italic bold">Look at the wound when the person tells you there is a change</span> such as more drainage or tenderness increasing.</li>
                                            <li>If you find a stain (bloody/yellow) on the bandage, use a pen and draw a circle around it the first day. (see photo). This way you can tell if there is additional drainage each day by checking the original circle.</li>
                                            <li>If the person complains of not feeling well or becomes less active, check their temperature. The person may have a fever, which is a sign of a wound infection.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Tips for Wound Healing</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Avoid hitting the wound on anything; it could cause the wound to break open</li>
                                            <li>Do not comb/brush hair or shave over stitches or staples</li>
                                            <li>Have the person you provide care for eat a balanced meal, with good sources of protein, fruits and vegetables needed for wound healing</li>
                                            <li>Always keep a wound and the sutures, clean and dry, unless otherwise ordered by the doctor</li>
                                            <li>Never soak a wound. This means do not let a person bathe in a tub, swim, use a hot tub, or if the person has a hand wound, wash dishes.</li>
                                            <li>Some doctors let people shower when they have a wound</li>
                                            <ul className="bulletsCustom greenBullets bulletsSafeCareIndent">
                                                <li>Many doctors suggest use of a certain type of soap such as Dial or Hibiclens&reg;</li>
                                                <li>Gently cleanse with mild soap and warm water</li>
                                                <li>Pat dry</li>
                                            </ul>
                                            <li>If you need to keep the wound covered during a shower, tape a layer of plastic wrap over the wound. Tape along all four edges of the plastic wrap to keep water out of the wound. If possible, use a hand held shower head and direct water flow away from the wound to lessen the chance of the wound becoming wet.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Wound Care</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/COS_3.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp; Always follow the doctor’s directions.</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a doctor prescribes wound care you should know:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>If the wound is to be open to air</li>
                                            <li>If the wound is to be covered with a dressing or bandage and how often that bandage should be changed. <a href="/LessonGauzeDressing" target="_blank">(See our lesson on Changing a Gauze Dressing.)</a></li>
                                            <li>When the stitches or closures will be removed by the doctor</li>
                                            <li>Special care instructions, such as cleansing, or use of an ointment</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/COS_4.png" className="img-responsive floatNone maxWidth300" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Activity</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Help the person with activities, such as laundering, lifting or moving objects weighing more than 20 pounds to avoid strain on the wound area</li>
                                            <li>Do not let the person twist or bear down, putting strain on the sutures and wound. The doctor will give directions on what activities to avoid and for how long.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>If the wound is to be open to air</li>
                                            <li>If the wound is to be covered with a dressing or bandage and how often that bandage should be changed (See our lesson on Changing a Gauze Dressing)</li>
                                            <li>When the stitches or closures will be removed by the doctor</li>
                                            <li>Special care instructions, such as cleansing, or use of an ointment</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Learn what a wound should look like and the steps to take to safely clean it.</p>
                                    </div>
                                </div>
                                <div className="col-12 imgColLeftContentColRight">
                                    <div className="w-100 videoFlexContainer">
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Looking at a Wound with Stitches 6:16
                                            </p>
                                        </div>
                                        <div className="imgCol noPaddingLeft">
                                            <div className="embedContainer">
                                                <YouTube videoId="O1UwrUW-HWk" opts={opts} />
                                            </div>
                                            <p className="captionRegular videoCaptionBox w-100 text-center">Care of Stitches (Sutures) 5:38
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="captionItalic marginBottom0">related content:</p>
                                        <p className="captionBold">Step-by-Step instructions</p>
                                        <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right"></i></a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="equipment">EQUIPMENT</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/COS_5.png" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">Looking at a Wound</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Pair of clean powder-free disposable gloves (available in drug stores). Use latex free gloves if the person you provide care for has an allergy to latex.</li>
                                            <li>Large bath towel</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/COS_6.png" className="img-responsive floatNone maxWidth300" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">Care of Stitches</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Bath towel</li>
                                            <li>Wash basin filled with warm water</li>
                                            <li>Wash cloths</li>
                                            <li>Mild hand soap or soap prescribed by doctor, like Hibiclens&reg; or Dial&reg;</li>
                                            <li>Plastic grocery bag</li>
                                            <li>Pair of clean powder-free disposable gloves (available in drug stores)</li>
                                            <li><strong><em>Option:</em></strong> Sterile normal saline and sterile Q-tips&reg; or cotton applicators (use if ordered by doctor)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonStitchesWhatToDoAndSafety;