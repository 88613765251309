import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonObservingWhatToTellYourDoctor = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100 m-auto">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonObserving">Observing General Health</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Often the doctor will tell you about things to watch for in the person you care for. Keep track of this information by writing it in your Care Diary so you can share it with the doctor at each visit.</li>
                                            <li>Keep a care diary of the different observations you make and share them with the doctor during the person’s visit.</li>
                                            <li>If you think the person you care for is having a heart attack or stroke, check the time. When did the first warning sign or symptom start? What other symptoms did you see? The doctor in the emergency room will ask you these questions.</li>
                                            <li>If you <span className="strong">call 911</span> because you observe signs of a heart attack or stroke, anticipate what the dispatcher will ask you. <span className="strong">Take note of the time when symptoms started.</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar text-center">The American Heart Association (2012) recommends that when you call 911, let the dispatcher ask you questions.<br /><br />
                                            Answer in short, specific replies, giving only the requested information.</h3>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    <div className="w-100">
                                        <p className="bold">The dispatcher will probably ask you:</p>
                                        <p className="boldLeadIn italic">"What is your emergency?"</p>
                                        <p>You might answer, "My mother had sudden chest pain and has now collapsed."</p>
                                        <p className="boldLeadIn italic">"What’s happening now?"</p>
                                        <p>"My friend is giving cardiopulmonary resuscitation."</p>
                                        <p className="boldLeadIn italic">"Where is the patient located?"</p>
                                        <p>"We are at my mother’s home, Sylvia Winston lives at 1234 Fifth Avenue NE."</p>
                                        <p className="boldLeadIn italic">"What number are you calling from?"</p>
                                        <p>"The number is 555-1313."</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonObservingWhatToTellYourDoctor;