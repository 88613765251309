import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import Register from "../../components/auth/register/index.js";

const Pritikin_video_sample = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {

            autoplay: 0,
        },
    };
    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12 video">
                                        <div className="">
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="gwTP0VL5T6c" opts={opts}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin_video_sample;