import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL12Component({ code, language }) {
    code = `{/* L12 Head, Lead In Photo Small with body copy */}
                                    <div className="lego l012">
                                        {/* L3 Subhead Upper and Lower */}
                                        <div className="lego l003">
                                            <h2 className="subHeadUpperAndLowerCase">Working with your team. </h2>
                                        </div>
                                        {/* End L3 Subhead Upper and Lower */}
                                        <div className="contentContainer">
                                            <div className="imgContainer">
                                                <img className="" alt="Image of doctors smiling" src="https://s3-alpha-sig.figma.com/img/ddc9/34bd/20a4be810f762845fc68010524845879?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ifkDRajjG2bT9m4H5mBod8NQdid5GdWOvaRf6Rmhgdir5f7T-2zaJa8asePglwQJ77lc3kmITWjSpQmg1M0lVKxkBpTon0av-Q40ozhnsDAhMwng1nEnHWzZ5qAsC~kUe7BAFF~Qu2av39c0rIv0WEfvcbE8bE3Ogz7BS0ZBzLYh6SJc1LEDFzU741D4a3Rnyp4Zp-1ldCcFagyPpR2b85AA7CrzyFDO8DU6-Q2p8ZihpU5BjqCqgu7RGKAdwAx-sI8KUVn8IbCDI9MI6bbz3yaDQquP-LqCa6ei~LJlaD7-pPI-WRer5qGshbCj6jTvYVwZuf75AnpyfBNwsTmi6A__" />
                                            </div>
                                            <div className="copyContainer">
                                                {/* L14 Paragraph */}
                                                <div className="lego l00paragraph legoMargin3">
                                                    <p>Your care team is here to provide world-class health care in a compassionate, respectful and responsive way. We are committed to help you during this journey.
                                                    </p>
                                                    <p>Teams are made up of a variety of professionals who work in combination toward your healthy outcomes.</p>
                                                </div>
                                                {/* End L14 Paragraph */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* End L12 Head, Lead In Photo Small with body copy */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL12Component;