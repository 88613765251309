import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll7_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center cyanColor cyanBorderColor">In-System
                                                Services</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Resources Available from
                                                    the Department of Otolaryngology</h3>
                                            <p className="w-100"><a href="https://oto.wustl.edu/" target="_blank">Our department</a> is dedicated to providing quality care throughout your journey. Part of that care includes a variety of resources in areas important to your overall positive outcomes. <span style={{fontWeight: "500"}}><em>Be sure to reach out to understand all the ways we can help!</em></span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="resourceContainer">
                                                <p className="resourceTitle">
                                                    <strong>Counseling: Siteman Psychology Service</strong><br />
                                                    <a type="tel" className="" href="tel:3147475587"> (314) 747-5587</a><br/>
                                                    <a href="https://siteman.wustl.edu/treatment/siteman-psychology-service"
                                                        className="" target="_blank">https://siteman.wustl.edu/treatment/siteman-psychology-service</a>
                                                </p>
                                                <p className="w-100">Provides counseling to patients, family members,
                                                    and caregivers at no charge.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="resourceContainer">
                                                <p className="resourceTitle">
                                                    <strong>Nutritional Support</strong><br/>
                                                    <a type="tel" className="" href="tel:3144547619"> (314) 454-7619</a>
                                                </p>
                                                <p className="w-100">Provides consultation to discuss your nutritional
                                                    needs during treatment at no charge. Can guide you on your diet to
                                                    promote wound healing or troubleshoot ideas on how to help gain some
                                                    weight — if required — before, during or after your cancer
                                                    treatment</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="resourceContainer">
                                                <p className="resourceTitle">
                                                    <strong>Barnard Health and Cancer Information Center</strong><br/>
                                                    <a type="tel" className="" href="tel:3143627844"> (314) 362-7844</a><br/>
                                                    <a href="https://siteman.wust.edu/visiting/barnard-health-cancer-information-center"
                                                       className=""
                                                       target="_blank">https://siteman.wustl.edu/visiting/barnard-health-cancer-information-center</a>
                                                </p>
                                                <p className="w-100">Located in the lobby of the Center for Advanced
                                                    Medicine. Offers a wide range of support services with staff that
                                                    tailors information specifically to assist you most effectively.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="resourceContainer">
                                                <p className="resourceTitle">
                                                    <strong>Social Assistance</strong><br />
                                                    <a type="tel" className="" href="tel:3145612302"> (314) 561-2302</a>
                                                </p>
                                                <p className="w-100">For useful answers to questions regarding
                                                    transportation, lodging needs, or lack of adequate insurance
                                                    coverage.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="width100"/>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight">
                                                <h2 className="subheadUpperAndLower mb-0">More Siteman
                                                    Patient &amp; Family
                                                    Services</h2>
                                            </div>
                                            <div className="withBorder">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p><span className="bold">Toll-free helpline: <a type="tel"
                                                                                                         className=""
                                                                                                         href="tel:18006003606">800.600.3606</a></span>
                                                        </p>
                                                        <p><span
                                                            className="bold">Complete list of Siteman Patient &amp; Family Services</span>
                                                        </p>
                                                        <p><a
                                                            href="https://siteman.wustl.edu/visiting/patient-and-family-services"
                                                            className=""
                                                            target="_blank">https://siteman.wustl.edu/visiting/patient-and-family-services</a>
                                                        </p>
                                                        <p>We are dedicated to helping you receive all the services
                                                            surrounding
                                                            cancer diagnosis and treatment that you might need. We
                                                            cover all the
                                                            bases from logistics to education to financial to
                                                            emotional and
                                                            spiritual support.</p>
                                                        <p><span className="bold italic">Be sure to find out all the ways we can help.</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img className="floatLeft wrapImage col-6 clearLeft"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/OPFA_image.png"
                                                 alt=""/>
                                            <p className="boldLeadIn floatNone clearNone col-6 noPaddingLeft">Office
                                                of Patient and
                                                Family Affairs</p>
                                            <p className="floatNone clearNone col-6 noPaddingLeft"><a
                                                type="tel" className=""
                                                href="tel:3143626100">(314) 362-6100</a>
                                                <br/>
                                                Monday – Friday, 8 am – 5 pm
                                                <br/><br/>
                                                <a type="tel" className=""
                                                                                href="tel:3143625000">(314) 362-5000</a>
                                                <br/>
                                                Evenings/Weekends
                                                <br/>
                                                (ask for a nursing supervisor)
                                                <br/><br/>
                                                <a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services"
                                                   className=""
                                                   target="_blank">https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services</a>
                                                <br/><br/>Email:
                                                <a href="mailto:patientliaison@bjc.org"
                                                   className="">patientliaison@bjc.org</a>
                                                <br/><br/>
                                                Located on the main floor of the South Campus and the third floor of
                                                the Center for
                                                Advanced Medicine.
                                            </p>
                                            <p className="floatNone clearNone col-6 col-12 noPaddingLeft">Should you
                                                or your family
                                                have a question or concern about your experience at our hospital, we
                                                would like to
                                                hear from you. Help us see things through your eyes as we strive to
                                                deliver
                                                exceptional care to our patients. We suggest you first discuss your
                                                concern with
                                                your nurse, the department manager, or your doctor.</p>
                                            <p className="floatNone clearNone col-6 col-12 noPaddingLeft">If the
                                                concern remains
                                                unresolved the office of Patient & Family Affairs staff is available
                                                Monday-Friday,
                                                8 am-5 pm to help you.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll7_3;
