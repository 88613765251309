import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll8_2 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Radiation Therapy</h1>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader text-dark text-center floatLeft w-100">How is radiation therapy used?</h3>
                                    <p>Doctors can use radiation therapy in a number of ways to treat cancer.</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom colorAccentLimeGreenBullets">
                                        <li><span className="bold">Early stage cancer:</span> radiation sometimes is the only treatment needed to kill cancer cells and small tumors.</li>
                                        <li><span className="bold">Before surgery:</span> radiation is sometimes used before surgery to kill cancer cells and shrink larger tumors so they can more easily be removed.</li>
                                        <li><span className="bold">After surgery:</span> radiation may be recommended after surgery to kill any cancer cells that might remain.</li>
                                        <li><span className="bold">With chemotherapy:</span> radiation may be used in combination with chemotherapy to more effectively kill cancer cells.</li>
                                        <li><span className="bold">Palliative therapy:</span> radiation may be used in patients whose cancer cannot be cured. In this setting radiation is used to decrease pain from the tumor and slow down its growth.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower floatLeft centered w-100">How exactly does radiation therapy work?</h3>
                                    <p>All cells, healthy or not, grow and divide. Cancer cells grow and divide faster. Radiation changes the DNA inside a cancer cell so that it cannot grow. Radiation therapy targets only the tumor or location of the cancer and the area that surrounds it.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower floatLeft centered w-100">What types of cancer can be treated with radiation?</h3>
                                    <p>Early stage cancer and cancer known to spread to other specific areas of the body can benefit from radiation therapy. It can also be used to help kill remaining cancer cells that could still be hidden or undetected in your body. In some cancers, radiation therapy is recommended because it can help to relieve symptoms such as swallowing or breathing difficulty due to the location of a tumor.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower floatLeft centered w-100">What types of cancer radiation are used?</h3>
                                    <p>Doctors have several forms of radiation that can be used to kill cancer cells, depending upon where cancer cells are located. The radiation can come from outside of your body or be implanted.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon text-center entAdditionalTherapiesSecondaryGreenBackgroundColor">External Beam Radiation Therapy</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>These radiation beams start outside of the body and are directed into the body to where the cancer cells are located. Higher doses of radiation typically can go deeper into the body’s tissues to reach the cancer.</p>
                                    <p>Doctors may use one or multiple radiation beams to treat cancer and can vary the amount of radiation from each beam.</p>
                                    <p>Beams also can be positioned to enter from almost any angle and be shaped to match the characteristics of a tumor. External radiation can come in the form of x-rays, gamma rays, or proton beams, among others.</p>
                                    <p className="bold italic">Understand the types that may be used on you:</p>
                                    <p><a href="https://www.mayoclinic.org/tests-procedures/intensity-modulated-radiation-therapy/about/pac-20385147" target="_blank">Intensity-Modulated Radiation Therapy (IMRT) > </a></p>
                                    <p><a href="https://www.mayoclinic.org/departments-centers/proton-beam-therapy-program/sections/overview/ovc-20185491" target="_blank">Proton Beam Therapy > </a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower floatLeft clearLeft">How much radiation will I receive?</h3>
                                    <p>Your doctor will prescribe the exact type of radiation and dose needed. All patients receiving radiation therapy will be scheduled for <span className="bold italic">pre-treatment planning sessions</span> so that the location and size of your cancer or tumor can be mapped precisely.</p>
                                    <p>Patients receiving radiation typically are scheduled for daily treatments <span className="bold italic">(usually five days a week for a set period)</span>. The number of days depends upon the type and stage of cancer and where it is located as well as whether the radiation is combined with other treatments, such as surgery or <a href="https://siteman.wustl.edu/treatment/siteman-approach/chemotherapy/" target="_blank">chemotherapy ></a></p>
                                    <p>Typically, the length of time for radiation treatments ranges from six to seven weeks.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Siteman_radiation_screenshot.png" className="img-responsive centerImage maxWidth400" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower floatLeft centered w-100">Radiation Therapy at Siteman Cancer Center</h3>
                                    <p>Our department is one of the largest in the United States, and a national leader in developing the most recent radiation treatments for cancer patients. We offer the widest range of radiation therapy options in the Midwest, allowing our physicians to tailor treatment to each individual patient’s needs.</p>
                                    <p>We are also a national leader in radiation oncology clinical trials.</p>
                                    <p><span className="bold italic">Learn more about</span> the Department of Radiation Oncology at Washington University School of Medicine, at <a href="https://radonc.wustl.edu/" target="_blank">http://radonc.wustl.edu/</a>.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP </a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll8_2;