import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll8_9 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entAdditionalTherapiesGreenColor entAdditionalTherapiesGreenBorderColor">Survivorship</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="headline text-center floatNone">Siteman Cancer Center’s Survivorship Program<br />Your
                                        partner for life.</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="typeCol">
                                        <span className="floatRight clearRight noPaddingLeft">
                                            <p>At Siteman Cancer Center, our Survivorship Program focuses on maintaining a lifelong connection with all of our cancer patients so that you can embrace life to the fullest. This includes offering services that meet the physical, psychological, social and spiritual needs of you as our patient and your family or caregivers.</p>
                                            <img className="img-responsive maxWidth400 centerImage" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/102024_LR.jpg" alt="" />
                                            <p>Our team in the Survivorship Program can link you to onsite or community resources, such as free counseling services and cancer support groups, or direct you to case managers who can assist with financial concerns. We also know about the possibility of lingering side effects after treatment, such as difficulty swallowing, dry mouth, fatigue, neck and shoulder pain, and can answer your questions about available options.</p>
                                            <p>Need help in eating healthier or in quitting smoking? We can
                                                connect you to those resources, too. The important thing to
                                                realize is that we are here for you throughout your entire
                                                lifetime. You don’t have to face anxiety, questions or concerns
                                                alone. </p>
                                            <p className="marginBtm35px"><strong>We’re here to help whenever you need
                                                    us.</strong><br /> Just call the Patient Care Coordination Center
                                                at <a href="tel:3147473046">314-747-3046</a>.</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100" />
                            <div className="col-12">
                                <div className="w-100">
                                    {/*New lego to be added here section header*/}
                                    <h3 className="font20px colorBlack bottomSpacer text-center sectionHeader">SURVIVORSHIP RESOURCES</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="resourceContainer">
                                        <p className="resourceTitle"><strong>Free Counseling & Emotional Support</strong><br />
                                            <a href="tel:3147475587">314.747.5587</a></p>
                                        <p>Through the Siteman Psychology Service, you and your family can access
                                            free counseling services for cancer related difficulties. These services
                                            are highly beneficial if you are experiencing anxiety or depression
                                            related to a cancer diagnosis, cancer related fatigue or sleep
                                            difficulties, or need to learn coping strategies.</p>
                                    </div>
                                    <div className="resourceContainer">
                                        <p className="resourceTitle"><strong>Nutritional Counseling</strong><br />
                                            <a href="tel:3144547619">314.454.7619</a></p>
                                        <p>Our licensed registered dietitians are available during and after
                                            treatment to discuss nutritional supplements, healthier food choices,
                                            and coping strategies for lingering taste or nutritional concerns
                                            post-cancer treatment. </p>
                                    </div>
                                    <div className="resourceContainer">
                                        <p className="resourceTitle"><strong>Free Smoking Cessation Resources</strong><br />
                                            <a href="tel:18007848669">1-800-QUIT-NOW (1-800-784-8669)</a></p>
                                        <p>Tobacco Quitline for individual telephone counseling: <a href="tel:18007848669">1-800-QUIT-NOW
                                            (1-800-784-8669)</a>. If you need help quitting smoking, these resources can
                                            get you started on your journey and keep you accountable when things get
                                            tough. You can also receive an On-Line Quit Plan with supportive texts,
                                            a smartphone app, and calendar by visiting <a href="https://smokefree.gov/" target="_blank">smokefree.gov <i className="fas fa-chevron-right"></i></a></p>
                                    </div>
                                    <div className="resourceContainer">
                                        <p className="resourceTitle"><strong>Pain Management Services</strong><br />
                                            <a href="tel:3143628820">314.362.8820</a></p>
                                        <p>Our dedicated Pain Management Center is a multidisciplinary team of
                                            specialists who focus solely on pain management during and after cancer
                                            treatment. </p>
                                    </div>
                                    <div className="resourceContainer">
                                        <p className="resourceTitle"><strong>Dealing with Memory Loss</strong><br />
                                            <a href="tel:3142861669">314.286.1669</a> (select option 3)</p>
                                        <p className="resourceTitle">Email: <a href="mailto:OTClinical@wustl.edu"><u>OTClinical@wustl.edu</u></a>
                                        </p>
                                        <p>Washington University Occupational Therapy provide specialists who can
                                            help you cope with cognitive changes such as short-term memory loss,
                                            trouble concentrating (sometimes called “chemo brain”), and difficulty
                                            in decision-making. Talk with your care provider for a referral for
                                            these services. </p>
                                    </div>
                                    <div className="resourceContainer">
                                        <p className="resourceTitle"><strong>Treatment for Lingering Physical Effects</strong><br />
                                            <a href="tel:3147473046">314-747-3046</a></p>
                                        <p>Talk with our Survivorship Program team about any concerns you may have
                                            related to fatigue, lymphedema (swelling of arms and legs), changes in
                                            sexual function, muscle weakness, numbness, tingling, skin changes, or
                                            anemia. While these are often lingering physical effects after cancer
                                            treatment, there are options available to help you recover from these or
                                            minimize their impact.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entAdditionalTherapiesGreenColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll8_9;