import React from "react";

function L92ComponentCode() {

    return (

        <div className="lego l093">
            <div className="contentContainer">
                <div className="contentLeft">
                    <div className="specialtyImageTitleLink legoMargin1" style={{
                        backgroundImage: "url(" + "https://s3-alpha-sig.figma.com/img/1e56/4744/6b5f8c90ce5a8d5b03616188c3394d70?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=LYqH0A9ft6n2DHHI5kNGWy0Ww-am1~BcLjdjvN9MPTjvD~wq5Tt4w7--SVDIZaGbp9zsjlIGccebxIYsTfrEOKWrCjazPS4vXnQRzIU3Tov7fQG3Yzwtten7KhCkXTo~5Wfczn931wtHgW9zZDdyj5785G9MWLFnFcKFNzNiPWxVt2solYgh-zdqI9KhKgc4YqWTM9wGw6cm-lsEE3R~jxZj7PJkTfUefxPwWOA4KY08MdksFK7yilDhD4NhYHe~m5uLdZ-8rrMFbKMLfR0jZeX0Lj9JG1XuWIe0~n6YNGXfDYdvykCqf-99sjE4F8sKmzLP0ZZjZW5HxUiDplnyYQ__" + ")"
                    }}>
                        <div className="shadeOverlay">
                            <div className="titleAndLink">
                                <h5 className="title">Foot & Ankle</h5>
                                <p><a className="consistentLinkColor" href="https://www.ortho.wustl.edu/content/Patient-Care/2489/Services/Foot-Ankle/Overview.aspx" rel="noreferrer noopener" target="_blank">visit page</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentRight">
                    <div className="specialtyImageTitleLink">
                        <div className="specialtyImageTitleLink legoMargin1" style={{
                            backgroundImage: "url(" + "https://s3-alpha-sig.figma.com/img/a04d/1841/782e2dbbadcf939ae6e7cb5e51481bfe?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MhQdKhh-ZcjQxbg-l8xdVU82Wl1-Z3wU~MxKpO3OchoUiNtCszPjyhUV~nuVy5YdDN2DimbfCQMKXbjqQIhSZiPjTyzCki~Do3cYXjLwLX3Lp8ovxl6cAlet8F0rviWQ-3B05aKzFXoTA88d5m8lWhx68D6IbU1cx5UHkqqk~dzNk3gn5K9uzUWfhbJuBKFsFEmU4jX0H5RZPIpL68PRNR2SHvEmnCFAumjrhHsyhT6ZlPA2~WVe5pHwBB8mbCC8C1SdJUpFsw2jxjiph8ESum41gqCmkQm251tX14AMw9xKR0CIhFlXoACuBbjRevgFdOpyKg2u6YOlv6meUyuN-w__" + ")"
                        }}>
                            <div className="shadeOverlay">
                                <div className="titleAndLink">
                                    <h5 className="title">HAND & WRIST</h5>
                                    <p><a className="consistentLinkColor" href="https://www.ortho.wustl.edu/content/Patient-Care/2490/Services/Hand-Microsurgery/Overview.aspx" rel="noreferrer noopener" target="_blank">visit page</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};


export default L92ComponentCode;