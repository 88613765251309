import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonFeedingAPersonWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFeedingAPerson">Feeding A Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatYouNeedToKnow#eatingRight">
                                                    <p className="whiteColor">Eating Right <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatYouNeedToKnow#helpingAPersonEat">
                                                    <p className="whiteColor">Helping a Person Eat <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatYouNeedToKnow#assitiveDevices">
                                                    <p className="whiteColor">Assistive Devices <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonFeedingAPerson/WhatYouNeedToKnow#signsOfDyspagia">
                                                    <p className="whiteColor">Signs of Dysphagia <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="eatingRight">EATING RIGHT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Whether the person you care for is on a special diet or not, it may be helpful to plan meals a week ahead. Include the person you are caring for in the planning process, so that you can learn their food likes and dislikes.</p>
                                                <p>Planning ahead gives you time to buy the foods you need to have on hand to make the meals you have decided upon. This helps you better plan to have the right foods at hand and in a variety that will make meals interesting, appealing and within the person’s budget. If possible, spend time with a dietitian to guide you in planning meals.</p>
                                                <p>This is very helpful when the person you care for is on a special diet.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder redBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower coralColor">Food Tips from the USDA (2013)</h2>
                                                <p className="text-center">The United States Department of Agriculture has tips for healthy food choices and ways to manage your diet.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare redBullets">
                                                    <li>Balance calories – find out home many calories a person needs for a day so you can plan meals</li>
                                                    <li>Enjoy meals but eat less.</li>
                                                    <li>Do not eat oversized portions. Use a smaller plate, bowl and glass. Do the same when you eat out or take home part of the meal</li>
                                                    <li>Eat calcium rich foods such as milk, yogurt, cheese, and fortified soymilk.</li>
                                                    <li>Eat 5 to 7 ounces of protein foods each day (1 ounce equals ounce lean meat, poultry, or seafood; 1 egg; ¼ cup cooked beans or peas; ½ ounce nuts or seeds; or 1 tablespoon peanut butter.</li>
                                                    <li>Eat more vegetables, fruits, and whole grains.</li>
                                                    <li>Make half your plate fruits and vegetables (red, orange and dark-green foods like sweet potatoes and broccoli)</li>
                                                    <li>Switch to fat-free or low-fat (1%) milk – you will get the same amount of calcium and other nutrients, but less saturated fat</li>
                                                    <li>Make half your grains whole grains such as whole wheat instead of white bread, brown rice instead of white rice</li>
                                                    <li>Each these foods less often: cakes, cookies, ice cream, candies, swee ened drinks, pizza and fatty meats. Eat these foods only as treats, 1 or 2 times a week</li>
                                                    <li>Always read food labels and choose low sodium options. Select canned foods labeled “low sodium” or “no salt added”</li>
                                                    <li>Drink water instead of sugary drinks</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="helpingAPersonEat">WHEN A PERSON CANNOT FEED THEMSELVES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_feeding.png" className="img-responsive floatNone maxWidth300" />
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Most of us eat without help. However, when people are ill, their condition sometimes causes fatigue and keeps them from being able to eat. Injuries and problems with swallowing can also limit what people can do when they try to eat or feed themselves.
                                                </p>
                                                <p className="boldLeadIn">This is common in people with:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>Ill fitting or damaged dentures</li>
                                                    <li>Infection or injury to the mouth</li>
                                                    <li>Diseases affecting the ability to chew or swallow certain types of thickness of food.</li>
                                                    <li>Head and neck surgeries</li>
                                                    <li>Injury to the arm and hand</li>
                                                    <li>Poor coordination of the arms and hands</li>
                                                    <li>Paralysis</li>
                                                    <li>Generalized weakness and serious fatigue</li>
                                                    <li>Serious dementia</li>
                                                    <li>Very poor vision</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A person who needs help to eat requires your patience and understanding. Let the person you are caring for do as much on his or her own as possible. <span className="bold">Do not take over feeding completely if a person can partially feed him or herself.</span> Mealtime should be a pleasant, unhurried time and kept simple.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="assitiveDevices">ASSISTIVE DEVICES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgLeftTextRight tiles">
                                        <div className="w-100">
                                            <div className="imgContainer col-3">
                                                <div className="w-100">
                                                    <img className="img-responsive marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_two_handle_cup.png" />
                                                </div>
                                            </div>
                                            <div className="typeContainer col-8">
                                                <p className="boldLeadIn">Two handled cup with spout in lid</p>
                                                <p>Makes it easier for a person to hold and lift a cup. The spout makes drinking easier with fewer spills. Most of these cups have a wide base which prevents them from tipping over.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgLeftTextRight tiles">
                                        <div className="w-100">
                                            <div className="imgContainer col-3">
                                                <div className="w-100">
                                                    <img className="img-responsive marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_plate_guard.png" />
                                                </div>
                                            </div>
                                            <div className="typeContainer col-8">
                                                <p className="boldLeadIn">Plate with plate guard and non-skid bottom</p>
                                                <p>Helpful to a person with limited flexibility of the hands, poor motor coordination, or has use of only one hand.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgLeftTextRight tiles">
                                        <div className="w-100">
                                            <div className="imgContainer col-3">
                                                <div className="w-100">
                                                    <img className="img-responsive marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_cutlery.png" />
                                                </div>
                                            </div>
                                            <div className="typeContainer col-8">
                                                <p className="boldLeadIn">Knife, fork and spoon with large handles or attached splints</p>
                                                <p>Helpful to a person with limited hand function or a weak grip.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgLeftTextRight tiles">
                                        <div className="w-100">
                                            <div className="imgContainer col-3">
                                                <div className="w-100">
                                                    <img className="img-responsive marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/FP_rocking_knife.png" />
                                                </div>
                                            </div>
                                            <div className="typeContainer col-8">
                                                <p className="boldLeadIn">Rocking style knife</p>
                                                <p>Helpful for a person with weak grasp, arthritis, or other painful condition in the hands and wrist. Rocking motion of knife makes it easier to cut food.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="signsOfDyspagia">SIGNS OF DYSPHAGIA</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Any of these signs can mean a person has dysphagia:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>A wet sounding voice</li>
                                                    <li>A change in voice</li>
                                                    <li>A weak uncontrolled cough</li>
                                                    <li>Gagging, coughing or choking on food</li>
                                                    <li>Taking a long time to swallow</li>
                                                    <li>Having pain when swallowing</li>
                                                    <li>Having food or liquids come back up though the throat, mouth, or nose after swallowing</li>
                                                    <li>Having pain or pressure in the chest or heartburn</li>
                                                    <li>Pocketing of food in the mouth</li>
                                                    <li>A slow, weak or uncoordinated speech</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="bold">Talk with a Dietitian - If you believe the person you care for has dysphagia, talk to their doctor.</p>
                                                <p>Ask to speak with a dietitian. A dietitian is trained to identify persons at risk for swallowing problems. Once the problem is identified, the dietician will be able to pick the best type of diet with proper food and liquid consistencies.</p>
                                                <p>For example, a dietitian can decide if the person will do best with a pureed or a mechanically altered diet. Liquid or pureed (pudding like) foods are often used for people who cannot chew solid or semi-solid foods or for people with certain types of dysphagia.</p>
                                                <p>There are a variety of dysphagia diets, <span className="bold">but do not choose one on your own</span>. Let a trained dietitian be your guide.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonFeedingAPersonWhatYouNeedToKnow;
