import React from "react";

function L10AComponentCode() {

    return (

        <div className="lego l00paragraph">
            <p className="boldLeadInNewUI">Bold lead in
            </p>
        </div>

    )
};


export default L10AComponentCode;