import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css'
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const ContentPancreatic6 = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Day of Your Surgery'}, () => {
            console.log('Day of Your Surgery');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Day of Your Surgery</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-1#arrival">ARRIVAL</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-1#preoperativeAreas">PREOPERATIVE
                                                        AREAS</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-1#duringSurgery">DURING SURGERY</Link>
                                                    </p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-1#postOperativeAreas">POST-OPERATIVE
                                                        AREAS</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                DAY OF YOUR SURGERY
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="_Gal0ZCiCRU" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="" className="subheadAllCaps">UNDERSTAND THE CURRENT COVID-19 POLICIES AND GUIDELINES THAT MAY AFFECT YOUR VISIT.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Get the latest details at <a href="https://siteman.wustl.edu/visiting/coronavirus" target="_blank">siteman.wustl.edu/visiting/coronavirus </a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="arrival" className="subheadAllCaps">ARRIVAL</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>In most cases you will be asked to arrive two hours prior to your
                                                    scheduled procedure.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Ideally, park in the Plaza Parking Garage</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 images3AcrossAndText">
                                        <div className="w-100">
                                            <div className="imagesAcross">

                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/parking.jpg"
                                                     className="img-responsive"/>

                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/welcome.jpg"
                                                     className="img-responsive"/>

                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJC_Campus_thumb.jpg"
                                                     className="img-responsive"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><em>For complete parking options on the BJH campus, see this <Link
                                                to="/Pancreatic_7-4"
                                                target="_blank">map</Link>.</em></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Where to buy Parking Passes Click for
                                                        details</Accordion.Header>

                                                    <Accordion.Body>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>Once parked, use the walkway to enter the main
                                                                        lobby, then follow
                                                                        the signs to “Surgery Registration.” There is an
                                                                        information desk
                                                                        next to the fountain if you are unsure where to
                                                                        go.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 subheadAndBullets">
                                                            <div className="w-100">
                                                                <ul className="bulletsCustom">
                                                                    <li>After registering, the patient and up to two
                                                                        family members will
                                                                        then be escorted to the preoperative area.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 images3AcrossAndText">
                                                            <div className="w-100">
                                                                <div className="imagesAcross">
                                                                    <div className="">
                                                                        <img
                                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Pedestrian_Bridge_Entrance.jpg"
                                                                            className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionItalic">Entrance to the
                                                                                main lobby.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <img
                                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Surgery_Registration.jpg"
                                                                            className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionItalic">Surgery
                                                                                Registration desk.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        <img
                                                                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJC_Parking_Map_Detail.jpg"
                                                                            className="img-responsive"/>
                                                                        <div className="imgCaption">
                                                                            <p className="captionItalic">Use this <Link
                                                                                to="/Pancreatic_7-4"
                                                                                target="_blank">map</Link> to
                                                                                help you navigate</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="preoperativeAreas" className="subheadAllCaps">IN THE PREOPERATIVE
                                                AREA</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>One or two family members may stay with you in the preoperative
                                                    area. You will be seen by the anesthesia team (may include CRNA,
                                                    resident, and/or the attending physician).<br/>
                                                    <img
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Family_Waiting_Area.jpg"
                                                        className="img-responsive"/></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will also be seen by members of your surgeon’s team (may include
                                                    medical student, intern, resident, physician’s assistant, chief
                                                    resident, fellow, and attending surgeon).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will be asked again about your allergies and may have to repeat
                                                    answers regarding your medical history. This is done for your
                                                    safety.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will be asked to name the procedure you are scheduled for in
                                                    your own words (for example, say “Whipple” or “part of my pancreas
                                                    removed”).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will have an IV catheter placed in your arm. Note: after you are
                                                    asleep in the operating room, other monitors and IVs will be placed.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>

                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will be connected to a monitoring device via sticky pads and
                                                    wires placed on certain areas of your chest or back to measure your
                                                    heartbeat and blood pressure.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets mb-2">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will be given a mild sedative right before you are rolled into
                                                    the operating room.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="duringSurgery" className="subheadAllCaps">DURING THE OPERATION</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Your family or friends may wait in the designated surgery waiting
                                                    area. This area will have wifi, vending, coffee available.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>They will be given a pager to receive updates about your status.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>The surgeon will speak to your family after the surgery to report
                                                    how the surgery went and will let them know when they can expect to
                                                    visit with you. Typically, this will be about two hours after you
                                                    are moved to the recovery area.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="postOperativeAreas" className="subheadAllCaps">FOLLOWING
                                                SURGERY</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will likely have your breathing tube taken out while still in
                                                    the operating room.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will be taken to the Peri-Anesthesia Care Unit (PACU) where one
                                                    or two family members may join you once you are stabilized and
                                                    awake. It is likely you will have no memory of this immediate
                                                    postoperative period.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You will spend about 2 hours in the PACU, then will be transferred
                                                    to a hospital room in the area selected based on your needs: the
                                                    Surgical Intensive Care Unit (SICU), the 16300 Progressive Care Unit
                                                    (PCU) or 16400 (our Hepatobiliary Surgery Floor).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/post-surgery-options.svg"
                                                 className="img-responsive w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 images3AcrossAndText">
                                        <div className="w-100">
                                            <div className="imagesAcross">
                                                <div>
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Elevators.jpg"
                                                         className="img-responsive"/>
                                                    <div className="imgCaption">
                                                        <p className="captionItalic">Located on the 16th floor of BJH
                                                            South Building</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16000_Entrance.jpg"
                                                         className="img-responsive"/>
                                                    <div className="imgCaption">
                                                        <p className="captionItalic">Entrance to PCU/16300</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16300_Floorplan.jpg"
                                                         className="img-responsive"/>
                                                    <div className="imgCaption">
                                                        <p className="captionItalic"><a
                                                            href="">Map</a> of
                                                            163OU and 16300 areas to help you navigate</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <p>There are limited private rooms available on 16300 and 16400. Please plan on being in a semi-private room. Should you choose to request a private room, it may be done when you arrive on the floor. In most circumstances, insurance will not cover private rooms unless it is deemed medically necessary. Should you proceed with this request, please not there will be a fee associated with this service. </p>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">IN-PATIENT LOCATIONS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Surgical Intensive Care
                                                Unit (SICU)</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>Location: 44ICU (secured entry)</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Visitor Policy:</p>
                                            <p>No Visitors in SICU between 6am – 8am and 6pm – 8pm<br/>
                                                1 person is allowed to stay overnight<br/>
                                                2 people are allowed in ICU during visiting hours<br/>
                                                Children under the age of 12y/o are not allowed in SICU<br/>
                                                Children under the age of 2y/o are not allowed to stay in waiting
                                                room<br/>
                                                No flowers or plants allowed</p>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>see more details> </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="boldLeadIn">SICU:</p>
                                                        <p>36 beds<br/>
                                                            1 Registered Nurse per 2 patients<br/>
                                                            24-hour patient monitoring</p>
                                                        <p className="boldLeadIn">Staff:</p>
                                                        <p>Intensive care physicians, Residents, Registered Nurses
                                                            (RN’s),
                                                            Respiratory Therapists, Dieticians, and Patient Care
                                                            Technicians
                                                            (PCT’s). RN’s wear navy blue scrubs, PCT’s wear maroon
                                                            scrubs and
                                                            RT’s wear gray scrubs.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">16300 Progressive Care
                                                Unit (PCU)</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>Location:</strong> 163PCU</p>
                                            <p>Secured entry</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16300-1.jpg"
                                                         className="img-responsive"/>
                                                    <div className="imgCaption">
                                                        <p className="captionBold">To enter PCU, press this gray button
                                                            at either locked door. Tell us who you are and who you are
                                                            here to see. Once verified, you will be let in.</p>
                                                    </div>
                                                </div>
                                                <div className="rightCol col-md-5 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PCU-1.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Visitor Policy:</p>
                                            <p>Visiting times are individualized to meet the needs of each
                                                patient<br/> Please talk with your nurse to determine what is best for
                                                your loved one<br/>
                                                Visitors limited to 2 per patient. Overnight visitors must secure a pass
                                                at the nurse’s station<br/>
                                                Visitor food and drink are prohibited in PCU patient rooms<br/>
                                                Children under the age of 12 y/o are not allowed to visit<br/>
                                                Children must have adult supervision at all times in all hospital areas
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>see more details</Accordion.Header>
                                                    <Accordion.Body>
                                                        <p><strong>163OU:</strong><br/>
                                                            9 beds (7 private rooms, 1 semi-private room)<br/>
                                                            1 Registered Nurse per 3 patients<br/>
                                                            24-hour patient monitoring<br/>
                                                            24-hour video monitoring (patient room activities are
                                                            monitored via camera to ensure complete safety)</p>
                                                        <p><strong>Staff:</strong><br/> Intensive care physicians,
                                                            Residents,
                                                            Registered Nurses (RN), Respiratory Therapists, Dieticians.
                                                            RN’s
                                                            wear navy blue scrubs.</p>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">16400 (Hepatobiliary
                                                Surgery Floor)</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Location:</p>
                                            <p>16th floor, south campus of Barnes-Jewish Hospital<br/>
                                                Open 11am – 9pm</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Visitor Policy:</p>
                                            <p>Visitors limited to 2 per patient (4 per patient if in a private
                                                room)<br/> Children under 12 y/o are not permitted in General Patient
                                                Care Areas<br/> Children must have adult supervision at all times in all
                                                hospital areas<br/> Overnight visitors are only allowed in private
                                                patient rooms</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16300-1.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                                <div className="rightCol col-md-5 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/16300-2.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>see more details> </Accordion.Header>
                                                    <Accordion.Body>
                                                        <p className="boldLeadIn">16300:</p>
                                                        <p>15 beds (5 private rooms, 5 semi-private rooms)<br/>
                                                            Medical needs take priority of private rooms. If available,
                                                            private
                                                            rooms are offered for an additional fee (not covered by
                                                            insurance)<br/>
                                                            1 Registered Nurse per 3 to 4 patients</p>
                                                        <p className="boldLeadIn">Staff:</p>
                                                        <p>Dedicated floor with dedicated staff experienced in caring
                                                            for our
                                                            HPB patients.</p>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic6;
