import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'

const SitemanPancreaticAll2_3_2 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Possible Complications During Your Stay</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>
                                                HIT YOUR CALL BUTTON and notify your NURSE IMMEDIATELY if you experience any of these symptoms:
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Fever, sweating, shaking chills</li>
                                                <li>Wound draining pus</li>
                                                <li>Redness, swelling at the incision</li>
                                                <li>Blood in the drain<br/>(filling up with bright-red, fresh blood)</li>
                                                <li>Sudden increase in pain</li>
                                                <li>Vomiting</li>
                                                <li>Any tube or drain that has come out</li>
                                                <li>Difficulty breathing</li>
                                                <li>Confusion</li>
                                                <li>Numbness in your arms or legs<br/>
                                                    (especially if you have an epidural)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll2_3_2;
