import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {Nav} from "react-bootstrap";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";


const LessonMovingWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving Safely: When a Person Starts to Fall'}, () => {
            console.log('Moving Safely: When a Person Starts to Fall');    
        });    
        await dispatch(pal);
    }

    const playVideo2 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving Safely: Helping a Person Who Has Fallen to Get Up'}, () => {
            console.log('Moving Safely: Helping a Person Who Has Fallen to Get Up');    
        });    
        await dispatch(pal);
    }

    const playVideo3 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving Safely: Walking Safely'}, () => {
            console.log('Moving Safely: Walking Safely');    
        });    
        await dispatch(pal);
    }

    const playVideo4 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving Safely: Helping a person sit on the side of a bed'}, () => {
            console.log('Moving Safely: Helping a person sit on the side of a bed');    
        });    
        await dispatch(pal);
    }

    const playVideo5 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving Safely: Transferring a person, who CAN stand'}, () => {
            console.log('Moving Safely: Transferring a person, who CAN stand');    
        });    
        await dispatch(pal);
    }

    const playVideo6 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Moving Safely: Transferring a person, who CANNOT stand'}, () => {
            console.log('Moving Safely: Transferring a person, who CANNOT stand');    
        });    
        await dispatch(pal);
    }

    const playVideo7 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Body Mechanics'}, () => {
            console.log('Body Mechanics');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonMoving">Moving Safely in the Home</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonMoving/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonMoving/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonMoving/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonMoving/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100 videoFlexContainer">
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="iS-OQ05OtPA" opts={opts} onPlay={playVideo}/>
                                                    </div>
                                                    <p className="captionRegular videoCaptionBox w-100 text-center">When a Person Starts to Fall
                                                    </p>
                                                </div>
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="O1UwrUW-HWk" opts={opts} onPlay={playVideo2}/>
                                                    </div>
                                                    <p className="captionRegular videoCaptionBox w-100 text-center">Helping a Person Who Has Fallen to Get Up
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow centered"><i className="fa fa-exclamation-circle"></i>CALL 911 IF THE PERSON:</h3>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom checkCircleBulletsSafeCare">
                                                        <li>Hit their head and is unconscious or difficult to arouse</li>
                                                        <li>Has a hard time breathing</li>
                                                        <li>Is bleeding from the head or face</li>
                                                        <li>Has a severe headache</li>
                                                        <li>Has slurred speech</li>
                                                        <li>Has a change in consciousness - the person becomes confused about where he or she is</li>
                                                        <li>Has repeated vomiting</li>
                                                        <li> Has a seizure
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Most falls do not involve injuries or the injuries are minor and do not require a trip to the emergency room. Yet proper care is needed. </p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>If a person falls, do not try to move them immediately. Wait and try to learn whether they are hurt.</li>
                                                <li>Ask the person to tell you if he or she feels hurt and if so where. Have them lie still for a few minutes before trying to move him or her.</li>
                                            </ul>
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">If there is any bleeding from a scrape or gash:</h2>
                                            </div>
                                            <ul className="bulletsCustom">
                                                <li>Take a clean cloth or gauze bandage and apply gentle pressure for 20 minutes. Do not release the pressure. <strong><em>If the blood spurts or flows after holding pressure, Call 911.</em></strong></li>
                                                <li>Rinse any wound with clear water. If there is dirt in the wound and it does not rinse away, call the doctor.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/helping_up_1_.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="caption mobileMarginBottom30">After a few minutes, if a person has no pain and is able to move their arms and legs: Have them crawl to a nearby couch or chair.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/helping_up_2_.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="caption mobileMarginBottom30">As the person faces the chair, help him or her to get up onto both knees into a tall kneeling position with their arms on the chair.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/helping_up_3_.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="caption mobileMarginBottom30"><br />Have the person bring their stronger leg forward into a half-kneeling position.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/helping_up_4_.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="caption mobileMarginBottom30">Then with the person using their arms to help lift their body— assist them to a standing position or a partial stand,</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight singleImageCenterTextVertically">
                                        <div className="w-100">
                                            <div className="col-4 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/helping_up_5.png" alt="" />
                                            </div>
                                            <div className="col-8 typeCol noPaddingLeft">
                                                <p className="caption mobileMarginBottom30 w-100">and pivot them into a sitting position into the chair or couch. <span className="invisible">and pivot them into a sitting position into the chair or couch.</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>After a few minutes, if a person is ready to get up from the chair, ask if he or she still hurts anywhere. If they have trouble walking or moving their arm, hand or foot, take the person to the emergency room.</p>
                                            <p>If the person gets up after a few minutes and says he or she feels fine, keep an eye on them. Watch to see if they walk or move about differently, become sleepy, or take a nap and become difficult to wake up. If you cannot wake them up, Call 911.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Prevent Falls</h2>
                                                <p>Here are some ways to help reduce a person’s risk of falling:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Have the person exercise regularly. Tai Chi is a good exercise for older adults to increase strength and improve balance.</li>
                                                    <li>Have their doctor or nurse review their medicines to reduce any drug side effects and interactions.</li>
                                                    <li>Have their eyes checked by an eye doctor each year.</li>
                                                    <li>Improve the lighting in the home.</li>
                                                    <li>Have a person regularly use assist devices (as ordered by their doctor), such as a cane or walker.</li>
                                                    <li>Be sure the person uses handrails when walking on stairs. </li>
                                                    <li>Have the person get up slowly from a lying or a sitting position.</li>
                                                    <li>Have the person wear shoes inside and outside the house.</li>
                                                    <li>Have the person avoid wearing slippers or going barefoot.</li>
                                                    <li>And follow our Safety Tips for making rooms in the home safe.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Making Rooms in the Home Safe</h2>
                                            <p>Prevention is a pound of cure. Most falls happen in the home during everyday activities. Take steps to make the home of the person you care for safer. Check each room of the home where the person lives. Have the person help to decide on ways to reduce hazards.</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar l5SubheadFollowedByChecklist">Kitchen&nbsp;</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li> It is best to remove any throw rugs. Or you can secure mats or throw rugs with nonskid backing or tacks.</li>
                                                    <li>Store dishes and food within easy reach. This may mean placing food and dishes in lower cabinets, especially if the person uses a wheelchair.</li>
                                                    <li>Install Lazy Susans or pull-out shelves for easy access.</li>
                                                    <li>Clean up grease, water and other liquid spills immediately. Do not wax floors. </li>
                                                    <li>Install direct lighting over the area where the person cooks. High intensity light is best. An older adult needs three times as much light as someone twenty years old.</li>
                                                    <li>Non-gloss finishes on cabinets and countertops is best. This reduces glare so a person can see more clearly.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar l5SubheadFollowedByChecklist">Living Room&nbsp;</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Place furniture in a way to open up the space through rooms and hallways.</li>
                                                    <li>Reduce clutter such as flower pots, magazines, foot stools, children or pet toys, electric cords.</li>
                                                    <li>Keep papers and books off of the floor.</li>
                                                    <li>Secure all carpets with nonskid backing.</li>
                                                    <li>Remove shag carpeting, which often gets caught on shoes, toenails and walkers.</li>
                                                    <li>Have enough electric outlets to be able to plug a light or TV into a nearby outlet. Secure electric cords against baseboards.</li>
                                                    <li>Install direct lighting over the area where the person reads or does hobby work. High intensity light is best.</li>
                                                    <li>Have sheer curtains in living area to reduce glare.</li>
                                                    <li>Remove glass-top tables – falling on one of these can cause serious injury. Sturdy wooden tables with round corners are best.</li>
                                                    <li>Do not let pets jump on the person.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar l5SubheadFollowedByChecklist">Stairs&nbsp;</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Paint edges of concrete stairs bright yellow, orange or white. This helps a person see the edges more clearly. Option: Use tape to cover edges.</li>
                                                    <li>Install treads with uniform depth of 9 inches and 6-inch risers – making steps equal size.</li>
                                                    <li>Install handrails along side of any stairway. Be sure stairways are well lighted with switches at top and bottom.</li>
                                                    <li>Repair any loose handrails or loose, uneven steps.</li>
                                                    <li>Put overhead lights at the top and bottom of stairs.</li>
                                                    <li>Install broad beam lighting for outside stairs and walkways.</li>
                                                    <li>Have the person take their time when going up or down stairs.</li>
                                                    <li>Do not use stairs to store boxes, tools or odds and ends.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar l5SubheadFollowedByChecklist">Bathroom&nbsp;</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Always have a night light in the bathroom.</li>
                                                    <li>Keep towels and clothing off of the floor.</li>
                                                    <li>Use bathroom rugs with nonskid backing.</li>
                                                    <li>Install grab bars by toilets and in the shower and tub. Have the person choose either vertical or horizontal placement of grab bars.</li>
                                                    <li>Vary the colors in the bathroom. An older person needs to see edges of tubs and shower stalls. Add bright decals or even red tape.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar l5SubheadFollowedByChecklist">Bedroom&nbsp;</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Keep a lighted phone within easy reach next to the person’s bed.</li>
                                                    <li>When getting up to go to the bathroom, have the person sit on the edge of the bed for a minute before getting up.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBar l5SubheadFollowedByChecklist">Outside&nbsp;</h3>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare">
                                                    <li>Keep off of ice. Many people slip on ice when trying to get to the mailbox or pick up the paper.</li>
                                                    <li>Repair any stone walkways that have cracks or holes.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 imgColLeftContentColRight">
                                            <div className="w-100 videoFlexContainer">
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="KRORX-DB6os" opts={opts} onPlay={playVideo3}/>
                                                    </div>
                                                    <p className="captionRegular videoCaptionBox w-100 text-center">Walking Safely
                                                    </p>
                                                </div>
                                                <div className="imgCol noPaddingLeft">
                                                    <div className="embedContainer">
                                                        <YouTube videoId="V2HOlR-x8Ww" opts={opts} onPlay={playVideo4}/>
                                                    </div>
                                                    <p className="captionRegular videoCaptionBox w-100 text-center">Helping a person sit on the side of a bed
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12 imgColLeftContentColRight">
                                                <div className="w-100 videoFlexContainer">
                                                    <div className="imgCol noPaddingLeft">
                                                        <div className="embedContainer">
                                                            <YouTube videoId="xfBwFiw8qVg" opts={opts} onPlay={playVideo5}/>
                                                        </div>
                                                        <p className="captionRegular videoCaptionBox w-100 text-center">Transferring a person, who CAN stand
                                                        </p>
                                                    </div>
                                                    <div className="imgCol noPaddingLeft">
                                                        <div className="embedContainer">
                                                            <YouTube videoId="99v9z0mf0Eg" opts={opts} onPlay={playVideo6}/>
                                                        </div>
                                                        <p className="captionRegular videoCaptionBox w-100 text-center">Transferring a person, who CANNOT stand
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100 videoFlexContainer">
                                                        <div className="imgCol noPaddingLeft">
                                                            <div className="embedContainer">
                                                                <YouTube videoId="25A6fi1BDpI" opts={opts} onPlay={playVideo7}/>
                                                            </div>
                                                            <p className="captionRegular videoCaptionBox w-100 text-center">Body Mechanics
                                                            </p>
                                                        </div>
                                                        <div className="imgCol noPaddingLeft">
                                                            <p>&nbsp;</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="captionItalic marginBottom0">related content:</p>
                                                        <p className="captionBold">Step-by-Step instructions</p>
                                                        <p><a href="#" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="navTopicButtonPageHref">
                                                        <a id="equipment">EQUIPMENT</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 imgColLeftContentColRight">
                                                <div className="w-100">
                                                    <div className="col-4 imgCol noPaddingLeft">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/F003_eq_a.jpg" alt="" />
                                                    </div>
                                                    <div className="col-8 typeCol subheadAndBullets rightCol">
                                                        <p className="captionBold">Transferring from bed to chair: A person who CAN stand</p>
                                                        <ul className="bulletsCustom">
                                                            <li>Transfer belt (can be purchased at medical equipment store)</li>
                                                            <li>Stable non-skid shoes, like tennis shoes</li>
                                                            <li>Chair with arms</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="col-12 imgColLeftContentColRight">
                                                <div className="w-100">
                                                    <div className="col-4 imgCol noPaddingLeft">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/F003_eq_b.jpg" alt="" />
                                                    </div>
                                                    <div className="col-8 typeCol subheadAndBullets rightCol">
                                                        <p className="captionBold">Transferring from bed to chair: A person who CANNOT stand</p>
                                                        <ul className="bulletsCustom">
                                                            <li>Transfer belt (can be purchased at medical equipment store)</li>
                                                            <li>Sliding board – different designs are available, some with hand slots for grasping and rubber pads to prevent slipping between bed and chair. The longer boards may be useful when transferring to a car or the bathtub.</li>
                                                            <li>Wheelchair (with a removable arm rest) or a chair with no arms</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <hr />
                                                </div>
                                            </div>
                                            <div className="col-12 imgColLeftContentColRight">
                                                <div className="w-100">
                                                    <div className="col-4 imgCol noPaddingLeft">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/F003_eq_c.jpg" alt=""/>
                                                    </div>
                                                    <div className="col-8 typeCol subheadAndBullets rightCol">
                                                        <p className="captionBold">Assisting with Walking</p>
                                                        <ul className="bulletsCustom">
                                                            <li>A gait belt</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 backToTop footer blueBackground">
                                                <div className="w-100">
                                                    <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonMovingWhatToDoAndSafety;
