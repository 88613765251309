import React from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css'
import YouTube from "react-youtube";
const SitemanENTAll4_2_5 = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Nutrition</h1>
                                </div>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer boxes four-across">
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#introduction">Introduction</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#calories">Increasing Calories</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#hydration">Hydration</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#issues">Common Issues</Link>
                                            </p>
                                        </div>
                                        {/*<div className="typeContainer text-center">*/}
                                        {/*    <p>*/}
                                        {/*        <Link to="#recipes">Recipes</Link>*/}
                                        {/*    </p>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon centered" id="introduction">INTRODUCTION</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Whether you are taking nutrition by mouth or have a feeding tube in your nose or stomach it is important that you get nutrition to heal your wounds.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">Healthy eating habits are important during and after cancer treatment.</h2>
                                    <p>Your nutrition plays an important part in your ability to heal, feel better and stay stronger. Making sure you are getting the proper nutrition can even help decrease side effects both during and after treatment.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">A registered dietitian (or nutritionist) is an important part of your healthcare team.</h2>
                                    <p>Dietitians will work with you, your family and the rest of the medical team to help you with your diet throughout your treatment and recovery journey.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">What you will focus on: </p>
                                    <p><span className="bold italic">Before your surgery:</span> Foods and supplements that provide good sources of high quality protein, complex carbohydrates, vitamins and minerals will ensure you are well nourished for surgery and may improve your outcome. Prevention of weight loss and adequate fluid intake before surgery is also important.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p><span className="bold italic">After surgery:</span> Starting an oral diet or tube feeding soon after surgery will ensure that you are receiving the nutrients you need to support healing and regain your strength. </p>
                                    <p><span className="bold italic">During recovery:</span> Your diet (whether by mouth or by tube feeding) will be specifically tailored to meet your nutritional needs for healing and weight management. The dietitian will ensure that you are receiving the nutrients you need as you progress from tube feeding to an oral diet or from liquid to solid foods.</p>
                                    <p><span className="bold italic">Lifelong:</span> A diet rich in fruits, vegetables, whole grains, lean meats and healthy fats will go a long way to maintaining good health.</p>
                                </div>
                            </div>
                            {/*<div className="col-12 backToTop">*/}
                            {/*    <div className="w-100">*/}
                            {/*        <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>*/}
                            {/*        <hr class="w-100" />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon centered" id="calories">INCREASING CALORIES</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Increasing your calorie intake may be important for you if you are losing weight prior to surgery. Following surgery, you will require extra calories to support healing. </p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder paleBlueBackgroundColor marginBtm50px">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">In General:</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom noBtmMargin">
                                            <li>Eat small frequent meals throughout the day. Aim for 6 to 8 small meals each day.</li>
                                            <li>Make every sip or bite count by eating foods and drinks dense in calories</li>
                                            <li>Limit food or beverages low in calories</li>
                                            <li>Eat a high calorie and protein diet, but remember to keep your diet balanced and eat a variety of foods</li>
                                            <li>Carry foods with you so that you can snack when you are waiting for an appointment or in the car driving</li>
                                            <li>As side effects from treatments worsen, it is more common to eat soft foods and liquids, so it is important that these provide adequate nutrition and calories</li>
                                            <li>Get into a habit of asking yourself, "what can I add to this to give it more calories?”</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower text-center floatNone font22px bottomPad26px">The Role of Protein in your diet.</h2>
                                    <p className="bold italic">Protein is important for growth, health and repair of your body:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Add extra meats, poultry, fish, cheese, or beans to casseroles, soups, or stews</li>
                                        <li>Meat salads such as chicken, ham, turkey, or tuna</li>
                                        <li>High protein milk</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Add 1 cup of powdered milk to 1 quart of whole milk</li>
                                            <li className="italic">Drink it as a beverage or add it into malts or shakes, or in cooking such as mashed potatoes, creamed soups, oatmeal, cream of wheat, pudding, casseroles, gravy, sauces, meatloaf</li>
                                        </ul>
                                        <li>Fairlife<sup>&reg;</sup> Milk- higher in protein and is lactose free</li>
                                        <li>Try a commercially prepared protein supplement</li>
                                        <li>Cheese</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Add to baked potatoes, vegetables, soups, noodles, meat, and fruit</li>
                                        </ul>
                                        <li>Include cream or cheese sauces of vegetables and pastas</li>
                                        <li>Add yogurt (Greek) or cottage cheese to fruits or blend into smoothies</li>
                                        <li>Eggs</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Hard-boiled eggs can be added into meat salads, casseroles, soups, or eaten alone</li>
                                        </ul>
                                        <li>Beans, legumes, nuts, and seeds (flax seeds, sunflower seeds, pumpkin seeds)</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Sprinkle seeds or nuts on desserts such as ice cream, pudding, custard, in smoothies, hot cereals, casseroles, on salads, stir fries, or pasta</li>
                                        </ul>
                                        <li>Peanut or almond butter</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Eat on toast, with fruit (apples, bananas), in shakes, with vegetables (celery), crackers or pretzels</li>
                                        </ul>
                                        <li>Choose dessert recipes that contain eggs</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic"> Sponge and angel food cake, egg custard, bread pudding, or rice pudding</li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">High Calorie Foods</h2>
                                    <p className="bold italic">Calorie dense foods are helpful as you start to eat after surgery as your ability to take in enough calories may be limited by chewing and swallowing difficulties.</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Add butter or oils to foods</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Use generously on potatoes, bread, toast, hot cereal, noodles, cooked vegetables, casseroles, pancakes, rice and in soups</li>
                                        </ul>
                                        <li>Add whipping or heavy cream or sour cream to desserts or foods</li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Pancakes, waffles, fruit, hot chocolate, soups, casseroles, baked potatoes, mashed potatoes, vegetables, pasta sauces, omelets, scrambled eggs</li>
                                        </ul>
                                        <li>Use croissants or biscuits to make sandwiches</li>
                                        <li>Add avocado or guacamole to salads or sandwiches</li>
                                        <li>Use regular mayonnaise and salad dressing on sandwiches and in dips with vegetable, fruit, or meat salads</li>
                                        <li>Add honey, jam, jelly, marmalade, syrups, and toppings to a variety of foods</li>
                                        <li>Choose calorie containing beverages such as fruit juices, lemonade, malts, floats, cocoa, milkshakes, smoothies, eggnog</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon centered" id="hydration">STAYING HYDRATED DURING TREATMENT</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">What is dehydration?</h2>
                                    <p>Dehydration is a condition that occurs when the body does not have enough water to properly function.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Two-thirds of the body is made up of water. Every cell, tissue, and organ is made up of water. It only takes a few days for a person to become very ill and require hospitalization when not drinking enough fluids.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">Function of water:</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Acts as a lubricant, cushioning joints. It also makes up saliva.</li>
                                        <li>Helps prevent constipation by moving food through the bowels.</li>
                                        <li>Keeps temperature normal.</li>
                                        <li>Helps remove wastes from the body (urination, perspiration, bowel movements).</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder paleBlueBackgroundColor marginBtm50px">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower"> <i className="fa fa-exclamation-circle hightLight entDiagnosisSurgeryRedColor"></i> &nbsp;Signs of Dehydration:</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Fatigue or tiring quickly and easily</li>
                                            <li>Nausea</li>
                                            <li>Constipation</li>
                                            <li>Sore muscles and joints</li>
                                            <li>Feeling confused or irritation</li>
                                            <li>Very dry mouth and skin</li>
                                            <li>Decreased urination — urine is darker than normal</li>
                                            <li>Feeling dizzy or lightheaded when you get up from sitting or lying down</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="italic"><span className="bold">Thirst is not a good signal for dehydration, especially during cancer treatment.</span> If you are feeling poorly or water tastes bad you may not notice you are thirsty.</p>
                                        <p className="italic noBtmMargin"><i className="fa fa-phone-square entDiagnosisSurgeryRedColor rotate90"></i> &nbsp;<span className="bold">Call your doctor if you are experiencing the above symptoms.</span><br />
                                            Keep your medical team informed on the amount of fluids you are drinking during treatment, especially chemotherapy. Staying hydrated can help flush chemo drugs from your body.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperAndLower">How much water do I need?</h2>
                                    <p><span className="bold italic">At least 64 ounces per day.</span> This is the same as 8 cups. Ask your doctor or dietitian how much water you need as an individual.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Water tastes bad. What else can I have?</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>You may drink other fluids that <span className="bold italic">do not contain caffeine</span>. Drinking other fluids can help add calories to your diet which is usually needed during treatment.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom marginBottom12">
                                                            <li>Juices (apple, orange, grape, cranberry, etc.)</li>
                                                            <li>Milk</li>
                                                            <li>Lemonade</li>
                                                            <li>Decaffeinated tea or coffee</li>
                                                            <li>Decaffeinated sodas</li>
                                                            <li>Water enhancers</li>
                                                            <li>Sports drinks (Gatorade<sup>&reg;</sup>, Powerade<sup>&reg;</sup>, Propel<sup>&reg;</sup> Electrolyte Water, Pedialyte<sup>&reg;</sup>)</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="bold italic marginBottom12">Things you can use to flavor water:</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom">
                                                            <li>Crystal Light<sup>&reg;</sup></li>
                                                            <li>Kool-Aid<sup>&reg;</sup></li>
                                                            <li>Dasani<sup>&reg;</sup> Drops</li>
                                                            <li>Hawaiian Punch<sup>&reg;</sup></li>
                                                            <li>Skinny Girl<sup>&reg;</sup> Water Enhancer</li>
                                                            <li>Mio<sup>&reg;</sup></li>
                                                            <li>Arizona Tea<sup>&reg;</sup></li>
                                                            <li>Country Time<sup>&reg;</sup> Lemonades</li>
                                                            <li>Great Value<sup>&reg;</sup> Water Enhancers</li>
                                                            <li>Propel<sup>&reg;</sup></li>
                                                            <li>Infuse your water with fresh fruit and Truvia® (Stevia)</li>
                                                            <li>Sparkling water</li>
                                                            <li>Use ice cubes frozen with fruits</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon centered" id="issues">ADDRESSING COMMON ISSUES</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="headline">Changes in Taste and Smell</h2>
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="w-100">
                                    <p>Chemotherapy and more often radiation therapy can alter your senses of taste and smell. These therapies can decrease your sense of taste and/or might even alter them so that foods taste bitter or metallic. These changes can affect your appetite. You may encounter increased sensitivity to spicy or acidic foods.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header><span className="italic">Ways to help with changes in taste and smell:</span></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom">
                                                            <li>Use plastic flatware, cups, and plates</li>
                                                            <li>Try sugar-free lemon drops, gum, or mints</li>
                                                            <li>Overcome metallic tastes with tart flavors like</li>
                                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                                                <li className="italic">lemon wedges, lemonade, citrus fruits or juices, vinegar, pickled foods, lemon custard, orange sorbet</li>
                                                                <li className="italic"><span className="bold">Do NOT</span> try these if you have a sore throat</li>
                                                            </ul>
                                                            <li>Try new tastes or spices:</li>
                                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                                                <li className="italic">onion, garlic, chili powder, basil, oregano, rosemary, tarragon, BBQ sauce, mustard, ketchup, mint</li>
                                                            </ul>
                                                            <li>Add sugar to mask the flavor of salty foods</li>
                                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                                                <li className="italic"><span className="bold">Do NOT</span> do this during or prior to radiation therapy</li>
                                                            </ul>
                                                            <li>Frozen fruit (i.e., cantaloupe, grapes, oranges, watermelon, peaches, mango)</li>
                                                            <li>Use pineapple to neutralize taste</li>
                                                            <li>If red meat tastes strange, try other protein-rich foods (i.e., chicken, fish, eggs, cheese, yogurt, ham)</li>
                                                            <li>Try marinating meats to make them tender</li>
                                                            <li>Blend fruits into shakes, ice cream, or yogurt</li>
                                                            <li>Serve foods cold or at room temperature. This can decrease the foods’ tastes and smells, making them easier to tolerate.</li>
                                                            <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                                                <li className="italic">Cheese, milkshakes, cold cuts, tuna fish, egg salad</li>
                                                            </ul>
                                                            <li>Choose foods that don’t need to be cooked</li>
                                                            <li>If the aroma is bothersome, drink the nutritional supplement cold, through a straw</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Hygiene can also help with changes in taste or smell:</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Keep your mouth clean and brush your teeth</li>
                                        <li>Rinse your mouth with baking soda and salt mouthwash before eating to help foods taste better </li>
                                        <ul className="bulletsCustom chevronRightBulletsSafeCareIndent marginBottom12">
                                            <li className="italic">Mix 1 teaspoon salt and 1 teaspoon baking soda in 1 quart of water. Shake well before swishing and spitting.</li>
                                        </ul>
                                        <li>If you are having pain while swallowing, take pain medication before meal times or before swallowing exercises</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Some suggested foods to help if you have issues with taste or smell:</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom">
                                                            <li>Oatmeal with whole milk, half and half, or heavy cream topped with whipped cream</li>
                                                            <li>Scrambled eggs cooked with butter or oil with added cheese and pieces of ham</li>
                                                            <li>Pancakes with lots of butter and syrup</li>
                                                            <li>Hard-boiled egg or deviled egg with regular mayonnaise</li>
                                                            <li>Small curd cottage cheese</li>
                                                            <li>Creamy potato salad</li>
                                                            <li>Macaroni salad</li>
                                                            <li>Cannelloni</li>
                                                            <li>Macaroni and cheese</li>
                                                            <li>Spaghetti noodles well cooked with ground beef and red sauce with parmesan cheese</li>
                                                            <li>Noodles with alfredo sauce (add extra heavy cream and parmesan)</li>
                                                            <li>Meatloaf or meatballs</li>
                                                            <li>Sloppy Joes</li>
                                                            <li>Chili</li>
                                                            <li>Swedish meatballs</li>
                                                            <li>Bologna</li>
                                                            <li>Ham/tuna/shredded chicken salad</li>
                                                            <li>Cheesy hamburger casserole</li>
                                                            <li>Tuna bake</li>
                                                            <li>Tuna broccoli casserole</li>
                                                            <li>Cheese-spinach pie</li>
                                                            <li>Mashed sweet potatoes</li>
                                                            <li>Any steamed/overcooked vegetable</li>
                                                            <li>Chili or refried beans with cheese and sour cream</li>
                                                            <li>Bananas</li>
                                                            <li>Soft canned fruits (i.e., peaches and pears)</li>
                                                            <li>Fruit with cottage cheese, sour cream, whipped cream or mayo</li>
                                                            <li>Soft cookies soaked in milk</li>
                                                            <li>Chocolate cake with chocolate pudding and whipped cream</li>
                                                            <li>Pie or pie filling with ice cream or whipped cream</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="headline">Mastication (Chewing) Problems and Dysphagia (Difficulty Swallowing)</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="marginBottom12">If you have <span className="bold italic">trouble chewing or swallowing</span>, try foods that require little or no chewing. </p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom marginBottom12">
                                        <li>Chopped, ground or soft meats and poultry</li>
                                        <li>Eggs</li>
                                        <li>Baked fish</li>
                                        <li>Overcooked pastas</li>
                                        <li>Canned fruit</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="marginBottom12">If you have <span className="bold italic">dysphagia (difficulty swallowing)</span>, try pureed or blended foods that require no chewing.</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom marginBottom12">
                                        <li>Souffles</li>
                                        <li>Pudding</li>
                                        <li>Cottage cheese</li>
                                        <li>Apple sauce</li>
                                        <li>Jello</li>
                                        <li>Ice cream</li>
                                        <li>Smoothies</li>
                                        <li>Cream soups</li>
                                        <li>Mashed potatoes</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="bold italic marginBottom12">Also:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom marginBottom12">
                                        <li>Eat small frequent meals</li>
                                        <li>Supplement with high calorie drinks</li>
                                        <li>Snack in between meals</li>
                                        <li>Eat sitting upright, take your time chewing up the foods</li>
                                        <li>Thicken liquids <span className="italic">(see below)</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder paleBlueBackgroundColor marginBtm50px">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Tips for Thickening Liquids:</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you have dysphagia, you may need to thicken the liquids you drink. Thickened liquids move more slowly than thin liquids. This gives you extra time to control the liquid while swallowing.</p>
                                        <p className="marginBottom12">Follow all instructions by your speech language pathologist (SLP) for thickening liquids. As part of those instructions, you will also be asked to remember:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare noBtmMargin">
                                            <li>Do not add ice to drinks</li>
                                            <li>Do not eat ice cream or jello as it melts to a thin liquid consistency</li>
                                            <li>Soup or milk for cereal must be thickened</li>
                                            <li>Only use the exact amount of thickener recommended per mixing table</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Xerostomia (dry mouth) and Thick Saliva</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Add gravies and sauces to foods</li>
                                        <li>Use pureed or mechanical soft and moist foods</li>
                                        <li>Casseroles, macaroni and cheese, yogurt, cream soups</li>
                                        <li>Drink 8-10 cups of fluid per day</li>
                                        <li>Always carry a bottle of water with you</li>
                                        <li>Try sucking on sugar-free sour candy, lemon drops, sugar-free gum or use citrus beverages to stimulate saliva</li>
                                        <li>Chase food with liquid</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Mucositis (sore, inflamed mouth or gut)</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Eat moist, bland foods</li>
                                        <li>Cream soups, Jello, mashed potatoes, ice cream, pureed foods</li>
                                        <li>Serve foods at room temperature</li>
                                        <li>Drink through a straw to bypass mouth sores</li>
                                        <li>Avoid alcohol and caffeine</li>
                                        <li>Avoid rough, salty, raw course foods such as crackers</li>
                                        <li>Avoid spicy foods: <span className="italic">chili powder, cloves, nutmeg, pepper, horseradish, etc.</span></li>
                                        <li>Avoid acidic foods: <span className="italic">tomato sauce, citrus fruits, juices, etc.</span></li>
                                        <li>Instead, try using fruit nectars such as apricot, mango, papaya, and guava</li>
                                        <li>Rinse with Magic Mouthwash prior to eating</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Several different medications may increase stomach acid.</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>These include progesterone (Provera, Ortho Novum, Ovral and other birth control pills), theophyllin (Theodur and other products), anti-cholinergics (Donnatol, Scopolamine, Probanthine, Bentil, and others) beta-blockers (Tenormin, and Corgard), alpha blockers (Dibenzyline), Dopamine, calcium channel blockers (Procardia, Cardizem, Isotin, and Calan), and aspirin-containing compounds and other drugs used for pain or arthritis, especially the group called non-steroidal anti-inflammatory drugs (NSAIDS) which are sold under several different brand names including Nalfon, Motrin, Rufen, Advil, Naproxen, Nuprin, Indocin, Meclomen, Feldene, Clinoril, and Tolectin.</p>
                                    <p><span className="italic bold">Vitamin C</span> is also an acid and can give stomach symptoms in large doses. </p>
                                    <p><span className="italic bold">Tobacco</span> also increases reflux. </p>
                                    <p className="italic" style={{fontSize: 18}}>Please consult your primary doctor before making any changes in your medications.</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder paleBlueBackgroundColor marginBtm50px">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Diet Changes to Help With Reflux:</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare noBtmMargin">
                                            <li>Avoid eating large meals. Eat smaller, more frequent meals rather than one or two meals per day. One should eat at least equal-sized meals or possibly even six (three small meals and three snacks).</li>
                                            <li>Sit up straight <span className="italic">(or even stand upright)</span> during and after eating.</li>
                                            <li>Do not eat for two or more hours before bedtime.</li>
                                            <li>Include a high protein food with each meal, avoid fats as much as possible. Use skim milk only.</li>
                                            <li>Certain foods are especially bothersome to the stomach and tend to cause acid production. These include caffeinated and decaffeinated coffee, tea, cola (and non-cola) carbonated beverages; chocolate, spearmint, peppermint, and alcohol.</li>
                                            <li>These foods are known to cause relaxation of the muscle between the stomach and the esophagus, stimulate acid production and allow stomach acid to come up and irritate the esophagus and throat.</li>
                                            <li>You should avoid any other foods which cause you to have heartburn. Certain foods which may cause heartburn are highly spiced foods, tomato and tomato products, and citrus fruits and juices.</li>
                                            <li>If you have some difficulty swallowing, try to select foods that are moist and easy to chew.</li>
                                            <li>Avoid hard candies, gum, breath fresheners, throat lozenges, cough drops, mouthwashes, gargles, etc. Many cough drops and lozenges contain irritants such as menthol and oil eucalyptus.</li>
                                            <li>Flavorful candies and cough drops stimulate acid and do not neutralize it as food does.</li>
                                            <li>If you are overweight, reduce to your desirable weight. <span className="italic bold">You may need the help of a dietitian and we would be happy to make a referral</span>.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col-12">*/}
                            {/*    <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">*/}
                            {/*        <h3 className="subheadUpperAndLowerColorBarIcon centered" id="recipes">RECIPES</h3>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-12">*/}
                            {/*    <div className="w-100">*/}
                            {/*        <p>For additional suggestions on recipes, check out the downloadable recipe lists:</p>*/}
                            {/*        <p><span className="bold italic">Soft and Moist High-Protein Menu Ideas</span> <a href="#"><i class="fa fa-file-o" aria-hidden="true"></i> Click to download pdf ></a></p>*/}
                            {/*        <p><span className="bold italic">High-Calorie Liquid Recipe</span> <a href="#"><i class="fa fa-file-o" aria-hidden="true"></i> Click to download pdf ></a></p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop blueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll4_2_5;
