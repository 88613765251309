import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonStockingsWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonStockings">Applying Elastic Stockings</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Causes of Deep Vein Thrombosis</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A DVT, or blood clot in a deep vein, is usually the result of a <span className="bold">combination of 3 factors:</span></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon centerBar greenColorBar w-100">
                                            1. Slow or sluggish blood flow through a deep vein
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets checkBulletsSafeCare">
                                            <li>Being restricted to bed</li>
                                            <li>Heart disease, such as congestive heart failure</li>
                                            <li>The period after surgery when activity is limited</li>
                                            <li>Long hours of being inactive</li>
                                            <li>Severe Obesity</li>
                                            <li>Long hours traveling, such as by car, bus, or air</li>
                                            <li>Sitting with the knees crossed, slowing blood flow</li>
                                            <li>Age over 60</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon centerBar greenColorBar w-100">
                                            2. Tendency for the blood to clot quickly
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets checkBulletsSafeCare">
                                            <li>Having major surgery, especially orthopedic</li>
                                            <li>Person’s with active cancer</li>
                                            <li>Smoking</li>
                                            <li>Estrogen treatment (such as birth control pills)</li>
                                            <li>Pregnancy and first 6 weeks after giving birth</li>
                                            <li>Family history of blood clots</li>
                                            <li>Dehydration</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon centerBar greenColorBar w-100">
                                            3. Irritation or inflammation of the lining of a vein
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets checkBulletsSafeCare">
                                            <li>Injury from major accident or a medical procedure</li>
                                            <li>Varicose veins</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Treatment for DVT</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are three goals of treating a DVT:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Stop a blood clot from getting bigger</li>
                                            <li>Prevent the blood clot from breaking off and moving to the lungs</li>
                                            <li>Reduce the chances of having another blood clot</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Medicines</p>
                                        <p>The medicines used to prevent and treat DVT are anticoagulants, also commonly called blood thinners. An anticoagulant decreases the blood’s ability to clot and stops existing clots from getting bigger. An anticoagulant does notbreak up or dissolve a clot that has already formed.</p>
                                        <p>The most common anticoagulants are:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Warfarin (Coumadin&reg; is a brand name), taken in a tablet form.</li>
                                            <li>Heparin, taken as an injection or shot.</li>
                                            <li>Enoxaparin (low molecular weight heparin), taken as an injection or shot. The person’s doctor will choose the drug right for his or her condition. Treatment for a DVT can last from 3 to 6 months.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Graduated Compression or Elastic Stockings</p>
                                        <p>Special compression or elastic stockings reduce two of the factors that cause a DVT:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom greenBullets">
                                            <li>Sluggish blood flow or pooling of blood</li>
                                            <li>Injury to a vein wall.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The stockings keep pressure on the outside veins of the leg to prevent pooling of blood. Instead of just staying in the veins, the blood moves up and into the veins back to the heart.</p>
                                        <p>The stockings also prevent veins from widening or enlarging, reducing the risk of tiny tears that injure the vein wall.</p>
                                        <p>A person wears a stocking from the arch of the foot to just above or below the knee (depending on the type ordered by the doctor). The stockings are tight at the ankle and then become looser as they go up the leg. The gentle pressure from the stocking keeps blood from pooling.</p>
                                        <p>Compression stockings are available over-the-counter, but prescription stockings are the best because they are specially fitted for the person.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Vena Cava Filter</p>
                                        <p>The vena cava is one of the larger veins of the body. Sometimes when a person has had more than one DVT, or if the person isn’t able to take anticoagulants, that person may have a filter placed inside the vena cava.</p>
                                        <p>The filter catches blood clots that break off in a vein before they move to the lung, therefore preventing a pulmonary embolus. The filter does not, however, stop new clots from forming.</p>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonStockingsWhatYouNeedToKnow;