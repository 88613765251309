import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import YouTube from 'react-youtube';
import '@fortawesome/fontawesome-svg-core/styles.css'
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
//const navigate = useNavigate();
const Content = () => {
    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Understanding the Whipple Procedure'}, () => {
            console.log('Understanding the Whipple Procedure');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-lg-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Understanding Your
                                                Procedure</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="headline">UNDERSTANDING THE WHIPPLE PROCEDURE</h2>
                                            <p>This video explains the Whipple procedure, which is performed to remove
                                                cancer from the head of the pancreas.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Video: <em>Understanding the Whipple Procedure</em>
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="zpQmP9LO_zk" opts={opts} onPlay={playVideo}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">What Is a Whipple Procedure?</p>
                                            <p>The Whipple procedure or pancreaticoduodenectomy, removes the head of the
                                                pancreas, the gallbladder, a portion of the bile duct, and the duodenum.
                                                After the removal of these organs, the remaining pancreas, bile duct and
                                                intestine are sutured into a new piece of small intestine to direct the
                                                gastrointestinal secretions back into the intestines.</p>
                                            <p>There are three different operation styles: open, laparoscopic, and
                                                robotic. Based on your situation, your surgeon will determine which
                                                operation style is appropriate.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Why Is This Procedure Done?</p>
                                            <p>We do this procedure for pancreatic tumors, duodenal tumors, distal bile
                                                duct tumors, and other indications where this operation is needed.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <img className="floatLeft wrapImage w-100 clearLeft"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Procedure_caption1.jpg"
                                                 alt="Diagram of Upper Abdomen"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p>The organs of your upper abdomen work as a system.</p>
                                            <p>The esophagus <strong>(1)</strong> carries food from your mouth into the
                                                stomach <strong>(2)</strong>, which leads to the
                                                intestine <strong>(3)</strong>. The liver <strong>(4)</strong> produces
                                                bile that is stored in the gallbladder <strong>(5)</strong>. When you
                                                eat a meal, the bile travels down the bile duct <strong>(6)</strong>,
                                                which runs behind the head of the pancreas <strong>(7)</strong> and
                                                joins with the pancreatic duct and drains into the intestine.</p>
                                            <p>The pancreas is shown in yellow and sits behind the stomach. It has two
                                                functions. First, it has cells that secrete hormones like insulin that
                                                regulate your blood sugar. Second, it has other cells that make enzymes
                                                that get secreted into the pancreatic duct and mix with the bile to help
                                                you digest food, in particular fatty foods.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="headline">Details of the Whipple Procedure.</h2>
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Procedure_Whipple_1.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p>Here is a closer look at the pancreas and pancreatic duct. The pancreas
                                                is divided into the head, body, and tail. Tumors in the head of the
                                                pancreas often block the bile duct and lead to bile backing up in the
                                                body, which causes jaundice (a yellowing of the skin and eyes and an
                                                elevated bilirubin level). For tumors of the head of the pancreas (the
                                                black circle), the Whipple operation (a Pancreaticoduodenectomy) is
                                                performed, which involves making a cut in the pancreas, bile duct, and
                                                intestine and removing the tumor and surrounding lymph nodes.</p>
                                            <p>In this procedure, we divide the pancreas over the portal
                                                vein <strong>(A)</strong> and remove all of the tissues to the left,
                                                including the bile duct, duodenum, and surrounding lymph nodes where the
                                                cancer can spread (outlined in the green line). If the tumor involves
                                                the portal vein, sometimes a piece of it will need to be removed and
                                                reconstructed as well.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="headline">Reconstructions During the Whipple Procedure.</h2>
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Procedure_Whipple_2.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <p>A reconstruction is then performed that creates three new connections:
                                                the remaining pancreas is sewn to the intestine <strong>(1)</strong> and
                                                the bile duct is sewn to the intestine <strong>(2)</strong> allowing
                                                pancreatic juices and bile to flow into the intestine and travel down
                                                (green arrow) to mix with food that comes from the stomach through its
                                                newly-made connection to the intestine <strong>(3)</strong>.</p>
                                            <p>Often, a drain will be left around the area of the new connections
                                                (purple line) to collect any fluid that may build up as they heal. The
                                                drain exits through the skin and connects to a bulb or bag
                                                reservoir.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    {/*<div className="col-md-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <h2 className="subheadAllCaps">WHAT CAN I EAT AFTER HAVING THE SURGERY?</h2>*/}
                                    {/*        <p>There is no restriction of your diet after the operation and your*/}
                                    {/*            recovery process is completed. In six months time, most patients find*/}
                                    {/*            they can eat normally. However, immediately post-surgery you may find*/}
                                    {/*            you can only tolerate small, frequent meals. This is common.</p>*/}
                                    {/*        <p>Some patients may not tolerate very sweet foods or high-fat foods and may*/}
                                    {/*            need to avoid these. <strong>Staying hydrated is critical. Drink plenty*/}
                                    {/*                of water when you get home.</strong> Make a habit of drinking plenty*/}
                                    {/*            of water in the weeks after your surgery. Be sure to always avoid*/}
                                    {/*            alcohol for 3 months after your surgery or until your surgeon says*/}
                                    {/*            differently.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-md-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">WHAT ARE THE COMPLICATIONS THAT ARE LIKELY TO
                                                HAPPEN IMMEDIATELY AFTER SURGERY?</h2>
                                            <p>In the hands of surgeons who are experienced with this surgical operation
                                                such as ours the complication rate is usually very low. The problems and
                                                complications that may be seen after this operation include:</p>
                                        </div>
                                    </div>
                                    {/*<div className="col-md-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <p className="boldLeadIn">Pancreatic Fistula:</p>*/}
                                    {/*        <p>After the tumor is removed from the pancreas, the cut end of the pancreas*/}
                                    {/*            is sutured back into the intestine so that pancreatic juices can go back*/}
                                    {/*            into the intestine. The pancreas is a very soft organ and in some*/}
                                    {/*            patients this suture line may not heal very well. If this happens then*/}
                                    {/*            patients develop leakage of pancreatic juice. Usually the surgeon leaves*/}
                                    {/*            behind a drainage catheter in the abdomen during the surgery. Any*/}
                                    {/*            leakage of pancreatic juice after the surgery is usually removed from*/}
                                    {/*            the body by this drainage catheter. In almost all patients who develop*/}
                                    {/*            leakage of pancreatic juice after the surgery, the leakage heals on its*/}
                                    {/*            own.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <p className="boldLeadIn">Gastroparesis (paralysis of the stomach):</p>*/}
                                    {/*        <p>For the first five to six days after the surgery, you will be provided*/}
                                    {/*            with intravenous fluids until your bowel function returns. After your*/}
                                    {/*            bowel function returns, your surgeon will begin you on a diet of clear*/}
                                    {/*            liquids and your diet will progress to a regular diet as you tolerate*/}
                                    {/*            it.</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100 text-center">Possible
                                                Complications After Surgery (Post-Operative):</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 threecolumnTable">
                                        <div className="w-100">
                                            <table className="table table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="leftCol">
                                                        <h4>Complications</h4>
                                                        <p><strong>Pancreatic Fistula (leak)</strong></p>
                                                    </th>
                                                    <th className="centerCol cyanTableBackgroundColor">
                                                        <h4>The Cause</h4>
                                                        <p>After the tumor is removed from the Pancreas, the cut end of
                                                            the pancreas is either sutured or stapled.</p>
                                                        <p>The pancreas is a very soft organ and sometimes this cut edge
                                                            may not heal very well. If this happens, you could develop
                                                            leakage of pancreatic juices.</p>
                                                    </th>
                                                    <th className="rightCol">
                                                        <h4>Treatment</h4>
                                                        <p>The surgeon leaves a drain in the abdomen during the surgery.
                                                            Any leakage of pancreatic juices after surgery is usually
                                                            removed from the body by this drain.</p>
                                                        <p>If all the juices are not removed by this drain, you may need
                                                            a procedure to place another drain after surgery, which
                                                            usually does not require another operation.</p>
                                                        <p>In almost all patients who develop leakage, it heals on its
                                                            own.</p>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="leftCol">
                                                            <p><strong>Delayed Gastric Emptying or lleus</strong></p>
                                                        </td>
                                                        <td className="centerCol cyanTableBackgroundColor">
                                                            <p>Sometimes after pancreas surgery, it takes your stomach and
                                                                intestines a little while to start functioning normally.
                                                            </p>
                                                        </td>
                                                        <td className="rightCol">
                                                            <p>You may need a nasogastric (NG) tube in your stomach. The NG
                                                                tube will be removed once your bowel function returns.
                                                                Occasionally patients require medications or a temporary
                                                                feeding tube placed through the abdomen to help with extra
                                                                nutrition. This is usually removed in the office after
                                                                surgery.</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftCol">
                                                            <p><strong>Infection</strong></p>
                                                        </td>
                                                        <td className="centerCol cyanTableBackgroundColor">
                                                            <p>Infections such as a urinary tract infection, pneumonia, or
                                                                wound infection may occur. A more severe infection can occur
                                                                that consists of a fluid collection, or abscess in your
                                                                abdomen.</p>
                                                        </td>
                                                        <td className="rightCol">
                                                            <p>Surgical infections are typically treated with
                                                                antibiotics.</p>
                                                            <p>Fluid collections typically require drain placement by
                                                                Interventional Radiology.</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100 text-center">Possible Long-Term
                                                Complications After Surgery:</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 threecolumnTable">
                                        <div className="w-100">
                                            <table className="table table-responsive">
                                                <thead>

                                                <tr>
                                                    <th className="leftCol">
                                                        <h4>Complications</h4>
                                                        <p><strong>Malabsorption (Pancreatic Insufficiency)</strong></p>
                                                    </th>
                                                    <th className="centerCol cyanTableBackgroundColor">
                                                        <h4>The Cause</h4>
                                                        <p>Removal of part of the pancreas can lead to less production
                                                            of pancreatic enzymes.</p>
                                                        <p>Patients complain of floating, oily, greasy stools or
                                                            diarrhea or abdominal discomfort especially after eating
                                                            fatty foods.</p>
                                                    </th>
                                                    <th className="rightCol">
                                                        <h4>Treatment</h4>
                                                        <p>Long-term treatment with pancreatic enzyme supplements, which
                                                            are pills taken with food usually provides relief from this
                                                            problem.</p>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="leftCol">
                                                            <p><strong>Diet Alteration and Weight Loss</strong></p>
                                                        </td>
                                                        <td className="centerCol cyanTableBackgroundColor">
                                                            <p>After pancreas surgery, you may feel bloated or become full
                                                                after eating. This usually will improve over the first few
                                                                weeks after surgery.
                                                            </p>
                                                        </td>
                                                        <td className="rightCol">
                                                            <p>Eat 6 small meals per day. Try to drink 2-3 protein shakes
                                                                daily. Eat foods that are high in protein and calories.
                                                                Notify our office if you lose greater than 10% of your body
                                                                weight.</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="leftCol">
                                                            <p><strong>Diabetes</strong></p>
                                                        </td>
                                                        <td className="centerCol cyanTableBackgroundColor">
                                                            <p>Removal of part of the pancreas can lead to less production
                                                                of insulin, and the risk of developing diabetes is
                                                                increased.</p>
                                                            <p>If you are diabetic or have a high blood sugar level
                                                                controlled by diet prior to surgery, you have a higher
                                                                chance of developing diabetes after surgery.</p>
                                                            <p>If you have a normal blood sugar level with no history of
                                                                diabetes prior to surgery, you have a fairly low risk of
                                                                developing diabetes after surgery.</p>
                                                        </td>
                                                        <td className="rightCol">
                                                            <p>Possible diabetes management with oral medication or
                                                                Insulin.</p>
                                                            <p>Please follow up with your Primary Care Provider or
                                                                Endocrinologist regarding long-term blood sugar
                                                                management</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="col-md-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default Content;
