import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonTemperatureWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonTemperature">Taking a Temperature</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonTemperature/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Reducing a Fever</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>When a person has a fever, you want to promote the body’s normal ways to reduce heat.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>The person will normally sweat, so replace lost fluid by having the person drink liquids. Have the person drink 8 to 10 8-ounce glasses of liquids each day (unless otherwise ordered by their doctor).</li>
                                            <li>Remove extra layers of clothing or bed linen.</li>
                                            <li>Keep a room cool, use a fan or lower the thermostat. Do not let the temperature of a room become so cold that it causes the person to shiver. Shivering causes body temperature to become higher.</li>
                                            <li>Bathe or shower with lukewarm, not cool water. Cool water might cause shivering, which will make the fever go higher.</li>
                                            <li>When the person begins to feel uncomfortable, give a fever-reducing medicine like acetaminophen (Tylenol&reg;) or ibuprofen unless the doctor advises against it. Note: Some doctors will not recommend fever-reducing medicine because fever is the body’s way to fight infection. Do not give aspirin to anyone younger than age 20 because of the risk of Reye’s syndrome.</li>
                                            <li>Reduce the person’s physical activity. The more active the person is, the higher the body temperature.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Treating Heat Exhaustion</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You might find the person outside when you notice the signs of heat exhaustion. Signs and symptoms often develop suddenly.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp;If fever is greater than 102 F (38.9 C), fainting, confusion or seizures occur, call 911 or emergency medical help.</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Move the person out of the sun to the shade or a shelter.</li>
                                            <li>Give the person cool, non-alcoholic liquids to drink.</li>
                                            <li>Have the person take a cool shower or bath. Prepare a basin of room temperature water and give a sponge bath.</li>
                                            <li>Place the person in a room with fans or air conditioning.</li>
                                            <li>Remove extra layers of clothing.</li>
                                            <li>If you need to keep the wound covered during a shower, tape a layer of plastic wrap over the wound. Tape along all four edges of the plastic wrap to keep water out of the wound. If possible use a hand-held shower head and direct water flow away from the wound to lessen the chance of the wound becoming wet.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Treating Heat Stroke</h2>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i className="fa fa-exclamation-circle">&nbsp;</i>This condition can be fatal. Call 911 for emergency assistance as you begin cooling the person.
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Move the person out of the sun to the shade or a shelter.</li>
                                            <li>Cool the person quickly. Ways to cool include: placing the person in a tub of cool water or into a cool shower, spraying the person with cool water from a garden hose, sponging the person with cool water.</li>
                                            <li>If the person can drink, give cool non-alcoholic liquids.</li>
                                            <li>Continue to take the temperature until it drops to 101 to 102 degrees Fahrenheit (38.3 to 38.8 degrees Centigrade).</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="safetytips">SAFETY TIPS</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom checkBulletsSafeCare">
                                                <li>When taking an oral or rectal temperature, protect yourself from infection by wearing clean gloves. Body secretions carry germs.</li>
                                                <li>Never force a rectal thermometer into the person’s rectum.</li>
                                                <li>If a person is uncooperative or confused, do not use an oral thermometer.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="showMeHow">SHOW ME HOW</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If the person cannot hold the thermometer in their mouth or if they are uncooperative, use a different route.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 videoFlexContainer">
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Taking a Temperature by Mouth
                                                </p>
                                            </div>
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="O1UwrUW-HWk" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Taking a Temperature by Rectum
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100 videoFlexContainer">
                                            <div className="imgCol noPaddingLeft">
                                                <div className="embedContainer">
                                                    <YouTube videoId="iS-OQ05OtPA" opts={opts} />
                                                </div>
                                                <p className="captionRegular videoCaptionBox w-100 text-center">Taking a Temperature by Armpit
                                                </p>
                                            </div>
                                            <div className="imgCol noPaddingLeft">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step Instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">Temperature by Mouth
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>An oral thermometer</li>
                                                    <li>Clean disposable gloves</li>
                                                    <li>Facial tissue</li>
                                                    <li>Soap and water</li>
                                                    <li>Wristwatch or clock</li>
                                                    <li>Pen or pencil and log book</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">Rectal Temperature
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Rectal thermometer</li>
                                                    <li>Facial tissue</li>
                                                    <li>A lubricant like KY&reg; Jelly</li>
                                                    <li>Clean disposable gloves</li>
                                                    <li>Alcohol pad</li>
                                                    <li>Soap and water</li>
                                                    <li>Diary or log book</li>
                                                    <li>Pencil</li>
                                                    <li>Wristwatch or clock</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">Temperature in the armpit (axilla)
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Oral thermometer</li>
                                                    <li>Facial tissue</li>
                                                    <li>Diary or log book</li>
                                                    <li>Wristwatch or clock</li>
                                                    <li>Pencil</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBarIcon">Temperature in the Ear
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Ear (tympanic membrane) thermometer</li>
                                                    <li>Disposable thermometer probe cover</li>
                                                    <li>Diary or log book</li>
                                                    <li>Pen</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonTemperatureWhatToDoAndSafety;