import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic9 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Working With Your
                                                Team</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">WORKING WITH YOUR HOSPITAL STAFF</h2>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Screen+Shot+2023-01-02+at+10.31.48+AM.png"
                                                className="img-responsive maxWidth500"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Hepatobiliary Surgeon</p>
                                            <p>Your surgeon is a full-time Washington University specialist who will
                                                guide your care and perform your surgery. As part of a world-class
                                                academic medical center, they will work alongside a varying team of
                                                medical professionals.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Anesthesia Team</p>
                                            <p>Anesthesiologist or Nurse Practitioner/Physician Assistant will evaluate
                                                your surgery needs.
                                                This team is specially trained to keep you safe during surgery and in
                                                recovery. They are key
                                                team members to manage your pain. You will meet a member of this team at
                                                your surgical evaluation
                                                at the Center for Preoperative Assessment and Planning (CPAP) and on the
                                                day of your
                                                surgery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Fellow</p>
                                            <p>Fellows are board certified physicians and surgeons pursuing additional
                                                specialized surgery
                                                training before beginning to practice.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Residents</p>
                                            <p>Residents are licensed physicians who have completed medical school and
                                                are undergoing additional
                                                training to become board certified in specialties such as surgery,
                                                oncology or internal
                                                medicine. If you are an inpatient, you will see resident physicians on
                                                daily rounds, checking your
                                                progress.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Advanced Practicing Providers (APPs)</p>
                                            <p>APPs are licensed Physician Assistants or Nurse Practicing Practitioners
                                                who are considered
                                                mid-level health care providers. They will provide direct patient care
                                                and attend daily rounds.
                                                They are an active part in your car</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Registered Nurses</p>
                                            <p>Registered Nurses are trained in surgical care and will follow your
                                                surgeon’s plan to assist in
                                                your recovery. They will teach you how to stay safe and healthy during
                                                every step of your recovery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your team will also consult with <strong>specialty services</strong> as
                                                needed to provide you optimal care. Examples of these additional
                                                collaborators include cardiology, endocrinology and teams specializing
                                                in managing pain. These teams have their own structures and procedures
                                                for making “rounds” with each unit. They will communicate with your
                                                surgeon’s team directly.</p>
                                            <p>You’ll be checked often for <strong>vital</strong> signs like blood
                                                pressure, heart rate, respiratory rate and temperature. It will be done
                                                more frequently immediately after surgery, then gradually shifting as
                                                you progress to at least every 4 hours.</p>
                                            <p>Around 10pm each evening, we will draw what are called <strong>routine
                                                “labs”</strong> (for example, taking a blood sample for a lab test).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">INVOLVING YOUR FAMILY</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Your team very much wants to involve your family
                                                in understanding your progress and how you and they can help improve
                                                your outcome.</p>
                                            <p>When you pre-registered for your surgery, you should have provided
                                                reliable contact information for yourself and one to two designated
                                                family members or friends.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/familyes.jpg"
                                                 className="img-responsive w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>For clarity’s sake during your stay, and to ensure your privacy, we ask
                                                each patient to determine a “family password” which we will note in your
                                                medical record for our staff to see. Then, designate one family member
                                                to call for updates (using the family password) and then have that
                                                person contact other family and friends with those updates. This allows
                                                your nurse to spend more time caring for you, the patient, while still
                                                giving your family and friends a way to get timely, consistent news
                                                about your progress.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Daily Rounds</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6-730-dailyround.svg"
                                                                 alt=""/>
                                                            <p>6-7:30am</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">MORNING
                                                            </p>
                                                            <p className="mobileMarginBottom30">With Morning Rounds, all
                                                                of your team (except the attending surgeon) will visit
                                                                you sometime typically between 6 am and 7:30am.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/attending-physician-dailyround.svg"
                                                                 alt=""/>
                                                            <p>Variable</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">ATTENDING SURGEON
                                                            </p>
                                                            <p className="mobileMarginBottom30">The attending surgeon
                                                                makes rounds at varying times throughout the day, as
                                                                they have to schedule their rounds before and after
                                                                surgeries and clinics.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/3-7-dailyround.svg"
                                                                 alt=""/>
                                                            <p>3-7pm</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">AFTERNOON
                                                            </p>
                                                            <p className="mobileMarginBottom30">The team revisits you
                                                                during Afternoon Rounds which occur anytime from 3pm to
                                                                7pm.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxPriority grayBackground text-center w-100 pb-0">
                                                <div className="col-12 imgColLeftContentColRight">
                                                    <div className="w-100">
                                                        <div className="col-1 imgCol noPaddingLeft">
                                                            <img className="img-responsive"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PA-dailyround.svg"
                                                                 alt=""/>
                                                            <p>Constant</p>
                                                        </div>
                                                        <div className="col-11 typeCol noPaddingLeft">
                                                            <p className="boldLeadIn">24/7 CARE
                                                            </p>
                                                            <p className="mobileMarginBottom30">Note that a member of
                                                                the team is available at all times for questions or
                                                                concerns not addressed on rounds and for immediate,
                                                                urgent matters.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Nursing rounds</p>
                                            <p>You can expect that your nurse will visit you each hour during the day to
                                                promote your safety and comfort.</p>
                                            <p>For your convenience, you and your nurse will determine a plan for making
                                                rounds at night (for example, visiting every 2 hours).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Daily Care Acceleration Meeting (DCAM)</p>
                                            <p>Between 10 – 11am every morning your care team meets to discuss your
                                                progress and to ensure that your needs are being addressed. As such,
                                                response times from these professionals may be delayed during this time
                                                (though, of course, any urgent matters will still be addressed
                                                immediately).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">OTHER SERVICES FOR YOU AND YOUR FAMILY</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Case Management</p>
                                            <p>Coordinate home health care, special services, check insurance benefits,
                                                make referrals, communicate with primary providers, discharge
                                                planning.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Social Work</p>
                                            <p>Admission planning, lodging assistance, placement assistance (Skilled
                                                Nursing Facility, Inpatient Rehabilitation, Long Term Acute Care unit),
                                                psychosocial assessment, advanced directives, community resources.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Spiritual Care</p>
                                            <p>Spiritual support is available to patients, families, and staff 24/7.
                                                Spiritual Care Services is an interfaith department that provides
                                                spiritual care to patients, families and staff of all faith traditions
                                                and with no specific religious affiliation.<br/>For more information <a
                                                    href="https://www.barnesjewish.org/patients-visitors/spiritual-care"
                                                    target="_blank">click here</a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Hospitality Services</p>
                                            <p>We provide information and direct assistance in a variety of everyday
                                                areas like food, lodging, area activities, parking, facility
                                                information, gifts and other amenities. To learn more about our liaisons
                                                and concierge resources services <a
                                                    href="https://www.barnesjewish.org/patients-visitors/"
                                                    target="_blank">click here</a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic9;
