import {postRequest} from "../../src/api/index.js";
import {
    AUTH_ERROR,
    AUTH_ROLE,
    AUTH_USER,
    CURRENT_USER,
    UPDATE_LOGO,
    HOME,
    TERMS_CONDITIONS_COMPLETED
} from "../../src/actions/types.js";
import {setTermsConditionsCompleted} from "./home.js";

export const loginUser = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await postRequest(`/login`, "user", data, (status) => {
            return (status >= 200 && status < 300) || status === 400 || status === 500; // default
        });

        if (res.status === 500 || res.status === 400) {
            dispatch({
                type: AUTH_ERROR,
                payload: 'We are sorry. The username or password you used is incorrect.'
            });
        } else if (res.status !== 400) {
            dispatch({
                type: 'RESET'
            });
            localStorage.clear();
            dispatch({type: AUTH_USER, payload: res.data});
            dispatch({type: CURRENT_USER, payload: res.data.user});
            dispatch({type: AUTH_ROLE, payload: res.data.user.roles});
            dispatch({type: UPDATE_LOGO, payload: {favIconAttributes: res.data.favIconAttributes, orgImageUrl: res.data.orgImageUrl, orbitCssClass: res.data.orbitCssClass}});

            if (res.data.user?.termsConditionsCompleted)
                dispatch(setTermsConditionsCompleted(res.data.user?.termsConditionsCompleted));

            localStorage.setItem("user", JSON.stringify(res.data.user));
            localStorage.setItem("token", res.data.jwt);
            localStorage.setItem("expiresIn", new Date(res.data.expiresIn).getTime());
            cb();
        }
    } catch (err) {
        if (err && err?.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                dispatch({
                    type: AUTH_ERROR,
                    payload: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                dispatch({
                    type: AUTH_ERROR,
                    payload: err.message
                });
            }
        } else if (err && err?.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: AUTH_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: AUTH_ERROR,
                payload: err?.message
            });
        } else {
            // Some other errors
            dispatch({
                type: AUTH_ERROR,
                payload: "Something went wrong."
            });
        }
        dispatch({
            type: AUTH_ERROR,
            payload: err && err?.response && err?.response?.data && err?.response?.data?.errors[0] ? err && err?.response && err?.response.data && err?.response?.data?.errors[0] : "Something went wrong"
    });
    }
};
