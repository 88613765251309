import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const HomeCluster = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-1-0">
                                        <div className="tile Height1RowTall width2Column t_001 sitemanENTYellowBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Your Cancer Journey ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-2-0">
                                        <div className="tile Height1RowTall width2Column t_009 sitemanENTOrangeBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Understanding
                                                        Your<br/>Diagnosis &amp; Surgery ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-3-0">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanENTPreparingSurgeryBackgroundImg">
                                            <div className="contentCenter">
                                                <div
                                                    className="centerTextTileBanner greenBlueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Preparing for <br/>Your
                                                                Surgery ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-4-0">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanENTCareAtHomeSurgeryBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Care at Home<br/>
                                                                After Surgery ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/profile">
                                        <div className="tile t_022 Height1RowTall width1Column tileCenter ">
                                            <div>
                                                <div className="text-center orbitTitle">
                                                    <p>My Support Orbit</p>
                                                </div>
                                            </div>
                                            <div className="centerCircleContainer">
                                                <div className="centerCircle">
                                                    <img alt="Responsive image" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/generic_avatar.svg"/>
                                                </div>
                                            </div>
                                            <div className="hidden-xs">
                                                <div className="profileBottomText text-right">
                                                    <h4 className="whiteColor noMarginBottom">My Profile &#187;</h4>
                                                </div>
                                            </div>
                                            <div className="visible-xs">
                                                <div className="text-center whiteColor">
                                                    <h3>My Profile</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-6-0">
                                        <div className="tile t_003 Height1RowTall width1Column sitemanENTPurpleBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0043_learning-gallery-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Learning Gallery ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-7-0">
                                        <div className="tile t_003 Height1RowTall width1Column sitemanENTLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0025_resources-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Support &amp; Resources
                                                        ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-8-0">
                                        <div className="tile Height1RowTall width1Column t_007 sitemanENTLightGreenBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="h3Container">
                                                        <h3 className="text-center whiteColor">Additional Therapies
                                                            ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-9-0">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanENTHospitalInfoBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Hospital Info ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default HomeCluster;