import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import Accordion from "react-bootstrap/Accordion";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog.js";

const SitemanENTTGSP2_3_3 = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
// https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Oral Cavity, Tongue, & Throat Cancer: Oral Cavity Surgery'}, () => {
            console.log('Oral Cavity, Tongue, & Throat Cancer: Oral Cavity Surgery');
        });
        await dispatch(pal);
    }

    const playVideo1 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Oral Cavity, Tongue, & Throat Cancer: Tracheostomy Procedure'}, () => {
            console.log('Oral Cavity, Tongue, & Throat Cancer: Tracheostomy Procedure');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center entBaseRedColor">Oral
                                                Cavity, Tongue, & Throat Cancer</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div
                                            className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                            <h3 id="cancerRemoval"
                                                className="subheadUpperAndLowerColorBarIcon darkOrangeBackground">ORAL
                                                CAVITY, TONGUE, THROAT (PHARYNX) CANCER REMOVAL</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center entNavBaseYellowBackground">
                                                    <a href="#cancerRemoval">
                                                        <div className="w-100">
                                                            <p className="text-center width100 floatLeft smaller">ABOUT
                                                                YOUR</p>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="text-center width100 floatLeft">CANCER
                                                                REMOVAL</p>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="text-center width100 floatLeft clickHere">continue
                                                                scrolling</p>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="typeContainer text-center entNavBaseBlueBackground">
                                                    <a href="#dayOfSurgery">
                                                        <div className="w-100">
                                                            <p className="text-center width100 floatLeft smaller">GO
                                                                TO</p>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="text-center width100 floatLeft">DAY OF
                                                                SURGERY</p>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="text-center width100 floatLeft clickHere">click
                                                                here ></p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p>The recommended treatment for most oral cavity cancers and many throat
                                                cancers is surgery. This requires removing the cancer with a cuff of
                                                normal tissue (or margin) around the cancer. There are many important
                                                structures in the mouth and throat. The extent and impact of your
                                                surgery on everyday functions like speaking and swallowing will depend
                                                on the size and location of your specific cancer. You can learn more
                                                about the anatomy and function of your mouth and throat here
                                                (<em><strong>see</strong></em> <a href="/siteman-ent-tgsp-2-1"> Your
                                                    Anatomy</a>).</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="">
                                            <h3 className="blueColor videoTitle w-100 text-center">Oral Cavity Surgery</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="66hNJhvgfEs" opts={opts} onPlay={playVideo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLower">Tongue/floor
                                                of mouth cancer:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12 w-100 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>When a portion of the tongue is removed due to
                                                                    cancer, it is called a glossectomy. The tongue is
                                                                    important in speaking, swallowing, feeling, and
                                                                    tasting. When only a small portion of the tongue is
                                                                    removed, there are usually no long-term problems.
                                                                    However, the larger the portion of the tongue that
                                                                    needs to be removed, the greater the difficulty with
                                                                    speech and swallowing afterwards.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="w-100 flexContainer justifyCenter column">
                                                                <div
                                                                    className="imageContainer  flexContainer justifyCenter">
                                                                    <img className="maxWidth500"
                                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Tongue_mouth_floor.jpg"
                                                                         alt=""/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 w-100 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>The floor of mouth is the U-shaped soft tissue
                                                                    located under your tongue that connects your tongue
                                                                    to your lower jaw. It is very flexible tissue which
                                                                    allows the tongue to move for speaking and
                                                                    swallowing.</p>
                                                                <p>Your surgeon may recommend that parts or all of your
                                                                    tongue and/or floor of mouth be reconstructed with
                                                                    tissue from another part of your body to optimize
                                                                    your speaking and swallowing ability following
                                                                    surgery. This reconstructive procedure is called a
                                                                    free flap (<em><strong>see</strong></em> <a
                                                                        href="siteman-ent-all-2-4">Your
                                                                        Reconstruction</a>) and is usually performed at
                                                                    the same time as the cancer removal. </p>
                                                                <p>Even though the free flap tissue cannot taste or move
                                                                    like normal tongue tissue, it will greatly help in
                                                                    speaking and swallowing. After surgery, you will
                                                                    work with a Speech Language Pathologist who will
                                                                    help you with speaking and swallowing
                                                                    rehabilitation.</p>
                                                                <p>The amount of tongue and floor of mouth tissue that
                                                                    will be removed is based on the size and location of
                                                                    the cancer.</p>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-12">*/}
                                                        {/*<div className="w-100">*/}
                                                        {/*
                                                        <hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                        {/*</div>*/}
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLower">Inner cheek
                                                (buccal) cancer:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12 w-100 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>The inner cheek tissue is referred to as the buccal
                                                                    part of the mouth. The flexible buccal tissue allows
                                                                    the mouth to open and close easily.</p>
                                                                <p>The parotid (<em>salivary</em>) gland also releases
                                                                    saliva into your mouth in this region, at the
                                                                    parotid duct opening. If a large portion of the
                                                                    buccal tissue is removed, your surgeon may recommend
                                                                    reconstructing this area with tissue from another
                                                                    part of your body to allow you to continue to open
                                                                    and close your mouth. This reconstructive procedure
                                                                    is called a free flap (<em><strong>see</strong></em>
                                                                    &nbsp;<a href="/siteman-ent-all-2-4">Your
                                                                        Reconstruction</a>) and is usually performed at
                                                                    the same time as the cancer removal.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="w-100 flexContainer justifyCenter column">
                                                                <div
                                                                    className="imageContainer  flexContainer justifyCenter">
                                                                    <img className="maxWidth500"
                                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Buccal_cancer.jpg"
                                                                         alt="Buccal Cancer Image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-12">*/}
                                                        {/*<div className="w-100">*/}
                                                        {/*<hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                        {/*</div>*/}
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLower">Soft palate
                                                cancer:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12 w-100 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>The soft palate is the muscular part at the back of
                                                                    the roof of the mouth. At the very back of the soft
                                                                    palate is the uvula, which is a small round
                                                                    extension of tissue that hangs down in the back of
                                                                    your throat. The soft palate separates the mouth
                                                                    from the nose. It is important in speaking as well
                                                                    as keeping food and liquid from going up into your
                                                                    nose when you eat or drink.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="w-100 flexContainer justifyCenter column">
                                                                <div
                                                                    className="imageContainer  flexContainer justifyCenter">
                                                                    <img className="maxWidth500"
                                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/40-1-soft_palate-1a.jpg"
                                                                         alt="Soft Palate Image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 w-100 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>Occasionally part, or all, of the soft palate has to
                                                                    be removed due to cancer. To help you regain
                                                                    speaking and swallowing function, your surgeon may
                                                                    recommend reconstructing this area with tissue from
                                                                    another part of your body. This reconstructive
                                                                    procedure is called a free flap
                                                                    (<em><strong>see</strong></em> <a
                                                                        href="/siteman-ent-all-2-4">Your
                                                                        Reconstruction</a>) and is usually performed at
                                                                    the same time as the cancer removal.</p>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-12">*/}
                                                        {/*<div className="w-100">*/}
                                                        {/*<hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                        {/*</div>*/}
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLower">Throat
                                                (pharyngeal) cancer:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>MORE &nbsp; <i
                                                        className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="col-12 w-100 floatLeft clearLeft">
                                                            <div className="w-100">
                                                                <p>Throat cancer often refers to cancer of the <span
                                                                    className="bold">tonsils</span> or the back of your
                                                                    tongue (<span className="bold">base of tongue</span>).
                                                                    When large portions of the throat must be removed
                                                                    for cancer or a patient has already had radiation to
                                                                    the throat, your surgeon may recommend
                                                                    reconstructing that area with tissue from another
                                                                    part of the body. This reconstructive procedure is
                                                                    called a free flap
                                                                    (<em><strong>see</strong></em> <a
                                                                        href="/siteman-ent-all-2-4">Your
                                                                        Reconstruction</a>) and
                                                                    is usually performed at the same time as the cancer
                                                                    removal.</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="w-100 flexContainer justifyCenter column">
                                                                <div
                                                                    className="imageContainer  flexContainer justifyCenter">
                                                                    <img className="maxWidth500"
                                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Tonsils+base+of+tongue.jpg"
                                                                         alt="Soft Palate Image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="w-100">
                                                                <hr className="w-100 afterImage"/>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-12">*/}
                                                        {/*<div className="w-100">*/}
                                                        {/*<hr className="w-100" />*/}
                                                        {/*</div>*/}
                                                        {/*</div>*/}
                                                        <div className="less">
                                                            <Accordion.Header>LESS &nbsp; <i
                                                                className="fa fa-chevron-right"></i></Accordion.Header>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Removal of lymph
                                                nodes (neck
                                                dissection)</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p>Lymph nodes (<em>sometimes referred to as glands</em>) are small
                                                bean-shaped
                                                structures that may be part of your immune system. Lymph nodes filter
                                                your blood
                                                and help you fight off infections. You have many lymph nodes in your
                                                head and
                                                neck. Sometimes cancers can spread to lymph nodes.</p>
                                            <p>Based on your type of cancer, your surgeon may recommend removal of
                                                certain lymph
                                                nodes in your neck, which is called a <strong>neck dissection</strong>.
                                                This
                                                would be performed at the same time as your cancer removal surgery.</p>
                                            <p>During a neck dissection, great care is taken to safely remove the
                                                necessary
                                                lymph nodes from around important nerves and blood vessels. Even though
                                                these
                                                lymph nodes are removed, your immune system will still work well after
                                                surgery.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer  flexContainer justifyCenter">
                                                <img className="maxWidth500"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/73-neck-lymph_nodes.jpg"
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="imgCaption">
                                        <p className="captionItalic centered"><strong>Lymph nodes that are removed
                                            during neck
                                            dissection</strong></p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer flexContainer justifyCenter">
                                                <img className="maxWidth500"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Neck_healed+incision.jpg"
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="imgCaption">
                                        <p className="captionItalic centered"><strong>Example of a healed neck
                                            dissection
                                            incision after removal of lymph nodes</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100 noTopMargin"/>
                                        </div>
                                    </div>

                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Tracheostomy</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p>At times when surgery is performed in the mouth or throat, you can have a
                                                lot of swelling that can make breathing through your mouth difficult. If
                                                this is the case, your surgeon may perform a tracheostomy to bypass the
                                                swelling in your mouth and throat. </p>
                                            <p>A tracheostomy involves making a small opening in your windpipe (trachea)
                                                and placing a tube (tracheostomy tube). In most cases, the tracheostomy
                                                is temporary and will be removed before you leave the hospital after
                                                surgery. You can ask your surgeon about your particular surgery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="">
                                            <h3 className="blueColor videoTitle w-100 text-center">Tracheostomy Procedure</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="RBH_T7v_GKE" opts={opts} onPlay={playVideo1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer flexContainer justifyCenter">
                                                <img className="maxWidth500"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Throat_Airway_Trach.jpg"
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-12 backToTop w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop">
                                                BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>

                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center">Feeding Tube</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p>Patients who undergo surgery for cancers in their mouth or throat
                                                that involve a free flap reconstruction will often need a feeding tube
                                                after surgery.</p>
                                            <p>For most patients, this means you will have a tube that goes through your
                                                nose, down the back of your throat, and into your stomach (<em>called a
                                                    nasogastric tube, or NG tube</em>).</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer  flexContainer justifyCenter">
                                                <img className="maxWidth500"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nasogastric%2Btube-png.png"
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="imgCaption">
                                        <p className="captionItalic centered">Nasogastric tube (NG tube)</p>
                                    </div>


                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p>A feeding tube allows us to give your body the nutrition it needs to
                                                recover from your surgery while giving your surgical site time to heal.
                                                Because many cancers of the mouth and throat affect swallowing, many
                                                patients are at increased risk for aspiration (<em>i.e., food/drink
                                                    going
                                                    down the wrong pipe</em>).</p>
                                            <p>You may be evaluated by one of our speech and language pathologists
                                                (SLPs) who will assess your swallow function and may recommend
                                                modifications to your diet to ensure you swallow safely.</p>
                                            <p>Drinking or eating by mouth before clearance from your surgeon can lead
                                                to wound breakdown and/or pneumonia. </p>
                                            <p>For the majority of patients, the feeding tube is temporary.
                                                Unfortunately, some patients will not be able to swallow safely for a
                                                while after surgery. For such patients we often recommend placing a
                                                feeding tube directly into the stomach which is called a <em>gastrostomy
                                                    tube or a G-tube for short.</em></p>
                                            <p>For more details on feeding tubes <a href="/siteman-ent-all-4-1-2">click
                                                here</a>.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer  flexContainer justifyCenter">
                                                <img className="maxWidth500"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/G%2Btube%2Bpercutaneous%2Bdiagram.jpg"
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="w-100 flexContainer justifyCenter column">
                                            <div className="imageContainer flexContainer justifyCenter">
                                                <img className="maxWidth500"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/GTube_percutaneous_inset.jpg"
                                                     alt=""/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="imgCaption">
                                        <p className="captionItalic centered">Gastrostomy tube (G-tube)</p>
                                    </div>
                                    <div className="col-12 backToTop w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop">
                                                BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <h3 className="sectionHeader w-100 text-center" id="dayOfSurgery">The Day of Surgery</h3>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Check-in</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>Check in at the surgery waiting area on the main floor of
                                                        Barnes-Jewish Hospital south campus. If you need directions,
                                                        visit the information desk located on the main level.
                                                    </li>
                                                    <li>After you check-in, you will go through hospital registration.
                                                        Upon completion, your care team member will take you to the
                                                        pre-surgery holding area.
                                                    </li>
                                                    <li>The surgical receptionist will give your family member or coach
                                                        a beeper to allow us to update them about your surgery.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div
                                            className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Before
                                                Going into Surgery</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>We will review your medications and health history with you.
                                                    </li>
                                                    <li>We will start an IV in your arm. <strong>If you were instructed
                                                        by your
                                                        surgeon to keep one of your arms free of IVs, please remind the
                                                        nurse.</strong>
                                                    </li>
                                                    <li>Your anesthesiologist will talk about the most appropriate
                                                        anesthetic for you and the plan to best manage your pain after
                                                        surgery.
                                                    </li>
                                                    <li>Your surgeon will meet with you to review the surgical plan and
                                                        mark where the surgery will occur.
                                                    </li>
                                                    <li>You will meet with the nursing staff who will be caring for you
                                                        in the operating room.
                                                    </li>
                                                    <li>Before going to the operating room, you will be given medication
                                                        for comfort.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div
                                            className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">During
                                                Your Surgery</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>Throughout your surgery your surgical team will monitor your
                                                        breathing, heart rate and blood pressure.
                                                    </li>
                                                    <li>A catheter, used to drain urine from your bladder, may be placed
                                                        during your surgery.
                                                    </li>
                                                    <li>Updates about your progress will be given to the person who
                                                        holds the beeper in the surgical waiting room.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div
                                            className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Right
                                                After Surgery</h3>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                        <div className="col-md-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                                    <li>Usually, you will wake up in the recovery room when your surgery
                                                        is complete.
                                                    </li>
                                                    <li>Specially trained nurses and an anesthesiologist will monitor
                                                        you. They will keep you safe and comfortable while you heal.
                                                    </li>
                                                    <li>Your care team of specialists will be with you during your
                                                        recovery.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div
                                            className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                            <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">
                                                <i className="fa-solid fa-comment"></i>&nbsp; Communicating After
                                                Surgery
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12 w-100 floatLeft clearLeft">
                                        <div className="w-100">
                                            <p>Because your mouth/throat will feel much different after your
                                                reconstructive surgery, you will need to use other ways to communicate
                                                aside from speaking. These include writing on paper pads/dry erase
                                                boards, apps on smartphones/tablets, and gestures. With time, most
                                                patients adapt to their new anatomy and are able to speak normally
                                                again.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop blueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default SitemanENTTGSP2_3_3;
