import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonGlucoseWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Glucose: Show Me How'}, () => {
            console.log('Glucose: Show Me How');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGlucose">Measuring Blood Glucose</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGlucose/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon">Blood Glucose Meter and Lancets</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Selecting a Blood Glucose Meter</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_meter.png" className="img-responsive maxWidth300 floatNone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The types of meters available for checking blood glucose change almost daily. A person with diabetes should work closely with their doctor or nurse to decide on the best meter for them. A medical equipment company can also be helpful in the choice of a glucose meter.</p>
                                                <p>A glucose meter is a small machine that works with one or more lithium or AAA batteries. Some meters are rechargeable. The meter reads the reaction of blood mixed with chemicals on a test strip. A number comes up on a small screen to show the blood glucose level.</p>
                                                <p><span className="bold">Meters have a variety of features:</span></p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li><span className="bold">Size of the meter</span><br />
                                                        Meters can be as small as a pen or credit card, but most fit easily in the palm of a hand. Smaller meters have small display screens that might make it hard to read numbers.</li>
                                                    <li><span className="bold">Timing</span><br />
                                                        Most meters give a test result in 3 to 30 seconds.</li>
                                                    <li><span className="bold">Cost of meter and strips</span><br />
                                                        Medicare covers all meters and strips equally. Commercial insurance often have “preferred meter systems”. Using the preferred meter gives the person the best pricing.</li>
                                                    <li><span className="bold">Size and color of display screens</span><br />
                                                        People who have trouble seeing or who are color blind should select a meter they can easily read.</li>
                                                    <li><span className="bold">Voice readouts</span></li>
                                                    <li><span className="bold">Memories to display multiple readings</span></li>
                                                    <li><span className="bold">Graphics to display readings on a computer</span></li>
                                                    <li><span className="bold">Dual meters that measure blood glucose and blood pressure</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_lancets.png" className="img-responsive maxWidth300 floatNone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Using a Lancet or Lancing Device</p>
                                                <p>Every blood glucose meter comes with a lancing device and lancets. A lancet is a very short, sharp-tipped metal cutting device. It is used to puncture the skin and produce a blood drop. Lancets vary by the diameter or width, and by the length of the metal. You place a new lancet into the device with each use. When you trigger the lancing device, it delivers the lancet at the same depth each time you prick the skin. Certain lancing devices will only use lancets that fit that model.</p>
                                                <p>Lancets come in different sizes (see photo). Smaller diameter lancets and sharper lancets hurt the least and enter the skin most easily. Lancing devices usually can be adjusted to alter the depth that the lancet goes into the skin. The purpose is to obtain a small amount of blood for testing with the least discomfort.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Testing Meters and Test Strips</p>
                                                <p>Many meters come with a bottle of control solution. The control solution contains glucose. It allows you to check the meter when you open a new box of test strips and any time you question the accuracy of the meter.</p>
                                                <p>Follow the manufacturer’s directions for proper set up. Always check the expiration date when opening the control solution. For example, many solutions need to be discarded 1 month after opening. If you need more control solution, the manufacturer will usually provide a new bottle if you call the 1-800 number on the package.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Prevent Infection</p>
                                                <p>When you stick the skin for a drop of blood, the break in the skin causes a chance of a skin infection. An infection does not happen often.</p>
                                                <p>Be cautious:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Have the person wash their hands with warm water thoroughly (15 seconds) before you do a finger stick.</li>
                                                    <li>Wash your hands thoroughly (15 seconds) or use a hand disinfectant.</li>
                                                    <li>Do not reuse a lancet.</li>
                                                    <li>Clean the glucose meter regularly (refer to the glucose meter operating manual).</li>
                                                    <li>Do not share a meter with someone else in the family.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How to Get Correct Readings from a Meter</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_testing.png" className="img-responsive floatNone maxWidth300" />
                                                <p className="captionItalic w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Make sure that the antiseptic has dried on the skin before you stick the skin with the lancet.</li>
                                                    <li>Always have a good “hanging” drop of blood to apply to the test strip (see photo).</li>
                                                    <li>Make sure that the test strip is fully inserted into the meter.</li>
                                                    <li>If the lancet did not pierce the skin at the correct depth, do not squeeze the finger with excessive force to get the drop of blood. Instead, start again with a new site/different finger.</li>
                                                    <li>To avoid damage to the meter, never leave diabetic equipment/supplies in the car on hot or cold days.</li>
                                                    <li>Never allow your finger or any other surface to touch the testing pad on the testing strip.</li>
                                                    <li>Do not try to take a blood sample from an area of the skin you stuck the last time you measured the blood glucose.</li>
                                                    <li>Never bend or cut the test strips. Do not reuse them.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h2 className="subheadUpperandLower">Storage of Meter and Test Strips</h2>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom">
                                                        <li>Store the meter and test strips together in a cool dry area. For example, store in a plastic container that you can seal, in the bedroom.</li>
                                                        <li>Always keep the test strips in the original closed vial.</li>
                                                        <li>Keeping test strips in direct light and humidity may make them unstable.</li>
                                                        <li>Do not refrigerate the test strips and do not use them after their expiration date. Check each meter’s operating manual for the specifics about storage of the meter and test strips.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">Giving Insulin Injections</p>
                                                    <p>If the person you care for becomes ill and is unable to give themselves their insulin, you need to know how to give a shot or injection. View the video on <a href="safecare_4-5-1-Subcutaneous_Injections_page.html">Giving a Subcutaneous Injection</a>.</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">Giving Glucagon</p>
                                                    <p>Glucagon is a medicine that raises blood sugar. You give it in the form of a shot (into a vein, muscle, or under the skin) when a person’s blood glucose level gets so low (hypoglycemia) that he or she passes out or cannot swallow or eat sugar or sugar-sweetened products. This often occurs when the person has taken too much insulin. Test the person’s blood sugar before giving glucagon, if possible. The medicine is safe and there is no danger of taking too much. But, it only should be given in an emergency.</p>
                                                    <p>See our lesson on <a href="safecare_4-5-SubCluster.html" target="_blank">Giving a Subcutaneous Injection</a> – to know how to give glucagon.</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">Accidental Needle Stick</p>
                                                    <p>A dirty lancet can carry infection. You are at risk if you accidentally cut yourself with the lancet or if you touch a cut or open sore on your body with the lancet. You can get any number of different infections, such as Hepatitis or AIDS, if you do not handle medical waste the right way.</p>
                                                    <p>View the video on <a href="safecare_1-1-1-Disposal_Introduction_page.html">Disposal of Medical Waste</a>.</p>
                                                </div>
                                            </div>


                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom">
                                                        <li>If you accidentally stick yourself on a dirty needle or lancet, immediately wash the injured area with soap and water. Rinse thoroughly.</li>
                                                        <li>Call your doctor as soon as possible to inform him or her about the needle stick.</li>
                                                        <li>If you provide care for a person routinely, you might want to be vaccinated for Hepatitis B.</li>
                                                        <li>Dispose of dirty lancets in special plastic sharps containers or in a hard plastic container, like a soda bottle or large liquid detergent bottle. Since the containers are not recyclable, you may be able to dispose of them in the trash. Check with the trash company to know how to dispose of a container. Some hospitals and pharmacies have sharps disposal programs. Many waste management companies allow you to mail the containers for disposal.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="navTopicButtonPageHref">
                                                    <a id="safetytips">SAFETY TIPS</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom">
                                                        <li><span className="bold">Do not</span> reuse a lancet.</li>
                                                        <li>When checking a new box of test strips or the meter for accuracy, be sure the control solution has not expired.</li>
                                                        <li>Wear clean gloves when checking a blood glucose level to avoid touching the person’s blood and to lower the risk of a lancet prick.</li>
                                                        <li>If you get a blood glucose reading above or below the person’s normal range, know what to do. Some persons are able to manage changes in their blood glucose (See the section on <a href="safecare_2-5-3-What_You_Need_to_Know_page.html">Need to Know</a>). Others should notify their doctor or home health nurse immediately.</li>
                                                        <li>Some doctors want a person to regularly fax or call in their blood glucose values so that medicine changes can be made.</li>
                                                        <li>If you <span className="bold">find a person with diabetes unconscious, call 911 immediately</span>. If you know the person has lost consciousness in the past from a low blood sugar, check their blood glucose level.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="navTopicButtonPageHref">
                                                    <a id="showMeHow">SHOW ME HOW</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p>Measuring blood glucose is a relatively safe and easy procedure.</p>
                                                </div>
                                            </div>
                                            <div className="col-12 video">
                                                <div className="w-100">
                                                    <h3 className="blueColor videoTitle width100">
                                                        Video: Measuring Blood Glucose
                                                    </h3>
                                                    <div className="floatLeft width100 captionBelow">
                                                        <div className="embedContainer">
                                                            <YouTube videoId="zKQElxcJrx8" opts={opts} onPlay={playVideo}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="captionItalic marginBottom0">related content:</p>
                                                    <p className="captionBold">Step-by-Step Instructions</p>
                                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="navTopicButtonPageHref">
                                                        <a id="equipment">EQUIPMENT</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                <div className="col-12">
                                                    <div className="w-100 text-center">
                                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/BG_equipment.png" className="img-responsive floatNone maxWidth300" />
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom">
                                                            <li>Alcohol wipe</li>
                                                            <li>Cotton ball or small gauze pad</li>
                                                            <li>A sterile lancet to stick the finger</li>
                                                            <li>Blood glucose meter</li>
                                                            <li>Blood glucose test strips</li>
                                                            <li>Pair of clean disposable gloves</li>
                                                            <li>Plastic grocery bag</li>
                                                            <li>Sharps container</li>
                                                            <li>Blood glucose log</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 backToTop footer blueBackground">
                                                <div className="w-100">
                                                    <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGlucoseWhatToDoAndSafety;
