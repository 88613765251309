import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonAspirationFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonAspiration">Aspiration Precaution</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonAspiration/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonAspiration/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is most important to know when feeding a person?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>You need to know the steps to take to prevent choking. This is very important in a person who has trouble swallowing.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How can I tell if a person has trouble swallowing?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The person might be able to tell you. If you think the person is having trouble, tell their doctor. You may need a referral to a dietitian. Also, observe for these signs of dysphagia:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>A wet sounding voice</li>
                                                    <li>Change in voice, such as hoarseness after swallowing</li>
                                                    <li>A weak uncontrolled cough</li>
                                                    <li>Gagging, coughing or choking on food</li>
                                                    <li>Taking a long time to swallow</li>
                                                    <li>Pain when swallowing</li>
                                                    <li>Having food or liquids come back up though the throat, mouth, or nose after swallowing</li>
                                                    <li>Pain or pressure in the chest or heartburn with eating</li>
                                                    <li>Pocketing of food in the mouth (Food remains in mouth after person has finished eating)</li>
                                                    <li>Slow, weak or uncoordinated speech.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What if the person I care for decides not to take their medicine?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Try to find out the reason they refuse, they may not feel well; they may believe the medicine is causing unpleasant side effects. Never force a person to take a medicine. If the person continues to refuse a medicine for more than one dose, call the doctor.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What do I do if a person starts to choke on their food?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Stay calm, stop feeding the person and be sure he or she is sitting upright.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Is the Heimlich Maneuver the same for everyone?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>No, only use the maneuver on conscious adults or children over age 1.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What type of foods should I prepare?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When a person has repeated trouble with swallowing, his or her doctor should recommend you meet with a dietitian to be sure you choose the proper foods and liquids.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Is there a way to help a person swallow?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Have the person sit up straight and use a chin tuck position when swallowing. When feeding the person, start with sips of liquids, then a bit more liquid, and then follow with bites of food.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">I usually feed the person I take care of. Is there a special technique to use?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Try placing 1/2 teaspoon of food on the unaffected side of their mouth (this means the side where sensation and movement is normal). Have the fork or spoon touch the mouth or tongue. Alternate bites of food with small sips of water.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo-mayoclinic.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Mayo Clinic</h2>
                                                        <p>The website is owned by the Mayo Foundation for Medical Education and Research. The Mayo Clinic’s mission is to empower people to manage their health. The website offers useful and up-to-date information and tools on a wide variety of health topics. Website includes information on difficulty swallowing.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.mayoclinic.com" target="_blank">www.mayoclinic.com</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Medline Plus</h2>
                                                        <p>A service of the United States National Library of Medicine and the National Institutes of Health, MedlinePlus brings together authoritative information from the National Institutes of Health (NIH), and other government agencies and health-related organizations. Information about Swallowing difficulty is on the web site.</p>
                                                        <p className="resourceLink">visit: <a href="https://medlineplus.gov" target="_blank">medlineplus.gov</a></p>
                                                        <hr className="w-100 floatLeft clearLeft" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12 col-12">
                                                <div className="w-100">
                                                    <div className="w-100 sidebarNoFill noBorder marginRight minHeight1" >
                                                        <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png"  />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The Ohio State University Medical Center Patient Education Materials</h2>
                                                        <p>The patient education web site has information about dysphagia.</p>
                                                        <p className="resourceLink">visit: <a href="https://medicalcenter.osu.edu/patientcare/patient_education" target="_blank">medicalcenter.osu.edu/patientcare/patient_education</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonAspirationFAQResources;
