import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonFeedingAPersonWhatToTellYourDoctor = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonFeedingAPerson">Feeding A
                                                            Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell the Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom redBullets">
                                                    <li>If the person you care for has been having trouble eating, the
                                                        doctor will want to know how much and the type of foods eaten.
                                                        Take a food diary that includes what the person ate over a week
                                                        to the next doctor’s visit.
                                                    </li>
                                                    <li>If you suspect a person has trouble swallowing (dysphagia)
                                                        report this to the doctor immediately.
                                                    </li>
                                                    <li>Let the doctor know if the person has lost interest in food and
                                                        does not drink liquids.
                                                    </li>
                                                    <li>If the person has any stomach problems such as indigestion,
                                                        stomach pain or has diarrhea, let the doctor know.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg"
                                                     className="img-responsive floatNone maxWidth300"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i
                                                    className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonFeedingAPersonWhatToTellYourDoctor;
