import React from "react";

function L1ComponentCode({title}) {
    title = 'L1 Title Header';

    return (

        <div className="lego l001 legoMargin5">
            <h1 className="tileTitle">{title}</h1>
            <hr className="w-100 l001Rule"/>
        </div>

    )
};


export default L1ComponentCode;