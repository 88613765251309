import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog";
const SitemanENTTL2_5 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Your Hospital Stay: What to expect as an In-patient'}, () => {
            console.log('Your Hospital Stay: What to expect as an In-patient');
        });
        await dispatch(pal);
    }

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entDiagnosisSurgeryRedColor entDiagnosisSurgeryRedBorderColor">Your
                                        Hospital Stay</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center floatNone">Your Surgical Care Team</h3>
                                    <p>Your primary surgical team is a group of physicians (medical doctors)
                                        and physician extenders who will manage care while you are in the
                                        hospital. Your team of physicians is listed below. At night, a team
                                        of physicians is on-call and available for urgent issues.</p>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Attending Surgeon</p>
                                                        <p>A physician who has completed training. An attending
                                                            physician supervises resident physicians and fellow
                                                            physicians. They are ultimately responsible for your
                                                            care.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Fellows</p>
                                                        <p>A physician who has completed medical school and their
                                                            residency. A fellowship is a year of additional training in
                                                            the area of head and neck cancer surgery and free flap
                                                            surgery.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Residents</p>
                                                        <p>A resident is a physician who has completed their medical
                                                            degree and is now training in the field of Otolaryngology
                                                            (ENT). Residents work closely with your attending surgeon in
                                                            the operating room as well as on the hospital floor. They
                                                            will round on you daily and provide post-operative care.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Physician Extender</p>
                                                        <p>Physician extenders (also known as advanced practice
                                                            providers) are physician assistants or nurse practitioners.
                                                            They work closely with your attending physician and resident
                                                            physicians to manage your care while in the hospital. Your
                                                            physician extender will see you daily and be your main
                                                            contact during your hospital stay.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Nurse</p>
                                                        <p>You will have nurses who are specialized and have experience
                                                            in caring for patients who have had head and neck surgeries
                                                            similar to yours. They will provide care for you during your
                                                            hospitalization as well as provide education to you and your
                                                            caregivers during your stay.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Speech-Language Pathologist (SLP)</p>
                                                        <p>Speech-language pathologists are professionals trained to
                                                            identify, assess, and rehabilitate your speech and
                                                            swallowing ability. They perform bedside evaluation and
                                                            education as well as radiologic swallow studies. </p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Physical Therapist (PT)</p>
                                                        <p>Physical therapists are health care professionals who provide
                                                            services to help you regain or improve your physical
                                                            abilities through exercises, hands-on care, and education.
                                                            It is very important for you to begin moving and walking as
                                                            soon as possible after surgery.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Occupational Therapist (OT)</p>
                                                        <p>Occupational therapists are health care professionals who
                                                            provide services designed to restore your self-care, work,
                                                            and leisure skills.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Dietician</p>
                                                        <p>Dieticians are experts making sure you have adequate
                                                            nutrition during your hospital stay and when you go home.
                                                            Dieticians manage all tube feeding regimens for
                                                            patients.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Case Manager/Social Worker</p>
                                                        <p>These are specially trained professionals who work with you
                                                            and your caregivers to ensure that you have the appropriate
                                                            support following your discharge from the hospital.
                                                            They will assist you in facility placement, transportation needs,
                                                            and arranging home health support and supplies. </p>
                                                    </div>
                                                </div>
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center floatNone">What to expect during your stay</h3>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <div className="floatLeft w-100">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="pNjD3WTIbmQ" opts={opts} onPlay={playVideo} />
                                        </div>
                                        <h3 className="videoTitle w-100 text-center text-dark">Watch: <span className="videoTitleTreatment">What to expect as an In-patient</span></h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will see your team of doctors every morning and evening.</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkboxBullets colorAccentRedBullets">
                                            <li>If you have questions throughout the day, write them down so you remember to ask your team when they come to visit you.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You will begin your stay in the Progressive Care Unit (PCU) on the
                                            ENT
                                            floor (16300) or an Intensive Care Unit (ICU) where you will be
                                            watched
                                            closely by nurses who are specially trained to take care of patients
                                            who
                                            have had your type of surgery. </p>
                                        <p>After a few days you will move to our nursing division on the ENT
                                            floor (17300) where nurses are also specially trained to take care
                                            of
                                            laryngectomy patients.</p>
                                        <p>If everything goes as expected, you will be in the hospital about 7
                                            to 10
                                            days.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">‘Flap Checks’</h2>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>If you have a free flap, you will have nurses and doctors checking on
                                            your free flap frequently while you are in the hospital, especially
                                            the
                                            first 3 days after surgery. Flap checks involve looking at the flap,
                                            feeling it to make sure it is warm and soft, and checking its
                                            pulse.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Medications</h2>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentRedBullets">
                                            <li>Bring a list of your current home medications with you to the
                                                hospital (do not bring the actual medications; you will not need
                                                them).
                                            </li>
                                            <li>Your nurse will give you your medications.</li>
                                            <li> Pain medications will be available to you as needed.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Pain</h2>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>We will work with you to best manage your pain. We will ask you to
                                            rate
                                            the pain you feel after surgery on a scale of 1 to 10. It is
                                            important
                                            to let us know when you first start feeling uncomfortable. Telling
                                            us
                                            right away will help keep the uncomfortable feeling from getting
                                            worse.</p>
                                        <p>For more information see <a href="/siteman-ent-all-4-4">Managing Pain</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">KEY CHANGES YOU WILL NOTICE AFTER SURGERY</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will only breathe through a permanent hole in your neck, called a
                                        “laryngectomy stoma.” You will no longer breathe through your mouth
                                        or
                                        nose.</p>
                                </div>
                            </div>
                            <div className="col-12 paddingBottomSpacer26px">
                                <div className="w-100 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2.5_stoma_before.png" className="img-responsive m-auto maxWidth300 floatNone" />
                                    <div className="imgCaptionCenter text-center"><em>Stoma shortly
                                                after
                                                surgery</em>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentRedBullets">
                                            <li>You will not be able to speak.</li>
                                            <li>You will not be able to warm, moisten or filter air.</li>
                                            <li>You will have a decreased sense of smell and taste.</li>
                                            <li>You will not be able to blow your nose.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">During recovery, you will learn to communicate
                                        without speaking using:</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentRedBullets">
                                            <li>Pencil and paper</li>
                                            <li>Reusable writing tablet such as a Boogie Board<sup>&reg;</sup>
                                            </li>
                                            <li>Dry erase boards</li>
                                            <li>Picture boards</li>
                                            <li>Programs on smartphones</li>
                                            <li>Gestures</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will learn about additional options to rehabilitate your voice after
                                        your hospital discharge, when you visit your speech pathologist.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader text-center floatNone">Nutrition</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>While you are asleep during surgery, your surgeon will place a feeding tube, also called a nasogastric tube, that
                                        goes through your nose and down into your stomach. This tube allows your
                                        mouth or throat to heal properly after surgery. Most people are able to
                                        swallow within 1 to 2 weeks of surgery.</p>
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nasogastric%2Btube-png.png" className="img-responsive maxWidth500 centerImage" />
                                    <div className="imgCaptionCenter text-center">
                                        <p className="italic text-center floatNone">Nasogastric tube (NG tube)</p>
                                    </div>
                                    <p style={{marginBottom:26}}><strong><em>If you require nutrition through a feeding tube for a longer
                                                period</em></strong>, you may undergo a procedure to place the tube
                                        directly into the stomach through the skin (called a “G-tube” or
                                        “gastrostomy tube”).</p>
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/G%2Btube%2Bpercutaneous%2Bdiagram.jpg" className="img-responsive maxWidth500 centerImage" />
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/GTube_percutaneous_inset.jpg" className="img-responsive maxWidth500 centerImage last" />
                                    <div className="imgCaptionCenter text-center">
                                        <p className="italic text-center floatNone">Gastrostomy tube (G-tube)</p>
                                    </div>
                                    <p>Your doctor will discuss how long you will have your feeding tube based
                                        on your specific results.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center floatLeft">Preventing Blood Clots</h3>
                                    <p>Blood clots are a possible complication after surgery. Ways we decrease
                                        those chances include:</p>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom colorAccentRedBullets">
                                            <li>Heparin or Lovenox administered by your nurse</li>
                                            <li>Walking early in your hospital stay</li>
                                            <li>A Sequential Compression Device (SCD). SCDs massage your calf
                                                muscles to keep your blood flowing while you are lying in your
                                                hospital bed.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">YOUR STOMA</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will learn to care for your new stoma beginning during your hospital
                                        stay.</p>
                                </div>
                            </div>
                            <div className="col-12 images2AcrossAndText paddingOnSides pb-0">
                                <div className="w-100">
                                    <div className="imageContainer topContainer4images">
                                        <div className="leftCol col-5 col-offset-1 paddingRight7">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2.5_stoma_before.png" className="img-responsive" />
                                            <div className="imgCaptionCenter ">
                                                <p className="captionBold text-center floatNone"><em>Stoma shortly after surgery</em></p>
                                            </div>
                                        </div>
                                        <div className="rightCol col-5 paddingLeft7 paddingBottomSpacer26px">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2.5_stoma_after.png" className="img-responsive" />
                                            <div className="imgCaptionCenter ">
                                                <p className="captionBold text-center floatNone"><em>Mature/healed
                                                        stoma</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 ">
                                    <h3 className="subheadUpperAndLowerColorBarIcon text-center entDiagnosisSurgeryRedBackground">
                                        <i className="fas fa-exclamation-circle"></i>&nbsp;Because hands may
                                        spread germs, everyone (you, your caregivers, all hospital staff) MUST
                                        wash their hands before and after ALL care. Cleaning supplies MUST be
                                        used.</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNone">Cleaning Your Stoma</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>You will learn how to clean your new stoma using a cotton tip applicator
                                        or a damp washcloth to gently remove secretions and crusting around the
                                        stoma. </p>
                                    <p>Clean your laryngectomy tube (if you have one) at least twice a day using
                                        a soft brush or pipe cleaner, mild soap and water. Rinse well under
                                        running tap water. Learn more at <a href="/siteman-ent-tl-4-1-1">Laryngectomy Care</a>.</p>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower text-center floatNoneNoClear">Humidifying air and suctioning your stoma</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Normally, air is humidified when a person breathes through their mouth or
                                        nose. As a laryngectomy patient breathing through your neck, air is not
                                        humidified, so you will require an external device — an HME (Heat
                                        Moisture Exchange Device) or High Humidity Trach Collar – that adds
                                        humidity to the air you breathe.</p>
                                </div>
                            </div>
                            <div className="col-12 images2AcrossAndText">
                                <div className="w-100">
                                    <div className="imageContainer">
                                        <div className="leftCol col-5 col-offset-1 paddingRight7">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/HME.png" className="img-responsive" />
                                            <div className="imgCaptionCenter ">
                                                <p className="captionBold text-center floatNone"><em>Heat and Moisture Exchanger
                                                        (HME)</em></p>
                                            </div>
                                        </div>
                                        <div className="rightCol col-5 paddingLeft7">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/HHTC.jpg" className="img-responsive" />
                                            <div className="imgCaptionCenter ">
                                                <p className="captionBold text-center floatNone"><em>High Humidity Trach Collar
                                                        (HHTC)</em></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Breathing humidified air is extremely important after your surgery to
                                        keep “mucus plugs” from forming that would block your breathing and can
                                        even be life threatening. </p>
                                    <p>Suctioning removes secretions that you cannot cough out, helping keep
                                        your airway clear and helping you breathe easier. </p>
                                    <p>You will have a suction machine available for use at your home. You will
                                        be trained on how to use it. Make sure you have your machine and that it
                                        is charged before leaving the hospital.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTL2_5;
