import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTL4_5 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Swallowing &amp; Aspiration</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You may have had difficulty choking on food and drink before your total laryngectomy surgery. However, after you are healed from surgery, you can’t aspirate food and liquid into your lungs anymore.</li>
                                                <li>After surgery you will receive nutrition through a feeding tube that will be placed in your nose, stomach, or stoma. Feeding through a tube allows the sutures in your throat to heal. Your surgeon will let you know when you will be able to start drinking and eating by mouth.</li>
                                                <li>Sometimes healing after surgery is delayed (especially if you have had previous radiation). A connection between the inside of your throat and your outer neck can develop – this is called a fistula. Your surgeon will guide you on the wound care that is needed to help you heal. You usually cannot eat or drink until a fistula is healed and closed.</li>
                                                <li>Once you are sufficiently healed, most patients are able to eat and drink much better than before surgery.</li>
                                                <li>Sometimes your throat can scar down over time and make it difficult to swallow solid foods (esophageal stenosis). If this happens, notify your surgeon immediately.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL4_5;
