import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonBloodPressureIntroduction = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonBloodPressure">Taking a Blood Pressure</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonBloodPressure/Introduction#introduction">
                                        <p className="whiteColor">INTRODUCTION
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonBloodPressure/Introduction#hearTheExpert">
                                        <p className="whiteColor">HEAR THE EXPERT
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="introduction">INTRODUCTION</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Taking a Blood Pressure</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Blood is carried from the heart to all parts of your body in blood vessels called arteries. With each heart beat, blood pumps out into your arteries. Blood pressure is the force of the blood pushing against the walls of the arteries, when the heart beats and when the heart relaxes. This is similar to the pressure of water in a garden hose.</p>
                                        <p className="bold">Sometimes a person has problems with their blood pressure because of:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Diseases of the blood vessels</li>
                                            <li>Heart disease</li>
                                            <li>Kidney disease</li>
                                            <li>Diabetes</li>
                                            <li>Pre-eclampsia (high blood pressure that develops during pregnancy)</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Sometimes doctors cannot find the cause of high blood pressure; this is called essential hypertension.</p>
                                        <p>When blood pressure problems develop, the person will often have to check their blood pressure regularly, depending on their doctor’s recommendations. If the person has difficulty holding and handling the equipment or is not able to remember to check the blood pressure, you might become the one who takes the blood pressure measurements.</p>
                                        <p>The doctor will decide how often the person you care for requires blood pressure monitoring. In order for you to measure a person’s blood pressure correctly, you will need to know the following:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>How often to check the blood pressure</li>
                                            <li>The correct measurement technique</li>
                                            <li>Factors that normally affect blood pressure</li>
                                            <li>The person’s normal blood pressure range</li>
                                            <li>What readings to report to the doctor</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You will record each blood pressure measurement in a care diary. Also, be sure to write down what the person was doing just before having the blood pressure checked. Have the person take the diary to each doctor’s visit so that the doctor can see changes in blood pressure over a period of time. The doctor will use the information to adjust the person’s blood pressure medicines.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="hearTheExpert">HEAR THE EXPERT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Before you measure a blood pressure, make sure the person is relaxed and comfortable.</p>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100">
                                        Video: Blood Pressure
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="YYKUCGfhsWc" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonBloodPressureIntroduction;