import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic22 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Nausea,
                                                Constipation and Other Digestive Issues</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">NAUSEA</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Nausea is a common side effect as your digestive system is in the process
                                                of recovery.</p>
                                            <p>Nausea can be caused by a variety of issues relating to how well food is
                                                being tolerated and digested as it moves through your system. Many
                                                people also have difficulty tolerating fatty foods in the early weeks
                                                after their surgery.</p>
                                            <p>Nausea can be addressed by <strong>focusing on having frequent small
                                                meals throughout the day, avoiding fatty foods and making sure you have
                                                proper hydration.</strong></p>
                                            <p>If symptoms don’t improve with these measures, there are medications that
                                                will help. Let us know if you are experiencing persistent nausea and we
                                                can work to alleviate those symptoms.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100 thickWhiteBorder"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">CONSTIPATION</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><strong>Constipation is a common issue during recovery.</strong> If often
                                                takes a while for your bowels to normalize after surgery. Every patient
                                                is different when it comes to best treating any constipation they may
                                                experience.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">If you experience constipation, try the
                                                following</h2>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ol className="bulletsCustom greenBullets">
                                                    <li>Increase your fluid intake; make sure you’re getting the
                                                        recommended 48 to 64 ounces a day
                                                    </li>
                                                    <li>Try increasing high-fiber foods in your diet. Good options
                                                        include apples, apricots, bananas, blackberries, nectarines,
                                                        oranges, grapefruit, pears, plums, strawberries, tangerines,
                                                        asparagus, broccoli, brussel sprouts, carrots, kale, okra,
                                                        spinach, mustard greens, sweet potato, oatmeal, oat bran, beans
                                                        (black, kidney, lima, navy).
                                                    </li>
                                                    <li>Try over-the-counter treatments including:</li>
                                                    <ul className="bulletsCustom greenBullets">
                                                        <li>Prune Juice</li>
                                                        <li>Stool softeners: Docusate Sodium (colace), Senna/Sennakot
                                                        </li>
                                                        <li>Fiber supplements: Metamucil</li>
                                                    </ul>
                                                    <li>If you haven’t had a bowel movement in 2 to 3 days you may try
                                                        these stronger laxatives.
                                                    </li>
                                                    <ul className="bulletsCustom greenBullets">
                                                        <li>Laxatives:, Bisacodyl (Dulcolax, Correctol, Exlax), MiraLAX,
                                                            Magnesium hydroxide (milk of magnesia), Magnesium Citrate.
                                                        </li>
                                                        <li>Stool softeners: Docusate Sodium (colace), Senna/Sennakot
                                                        </li>
                                                        <li>Enema or suppository</li>
                                                    </ul>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon text-black">
                                                <i className="fa fa-caret-right hightLight middleGreenColor"></i> If
                                                these do not work please call the office or exchange.
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100 thickWhiteBorder"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">OTHER DIGESTIVE ISSUES</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">Bloating or Feeling of Excess
                                                Gas</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox w-100">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Symptoms:</p>
                                                        <p>Bloating, feeling “full,” belching</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100 thickWhiteBorder"/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>Use over-the-counter Simethicon (e.g. GasX) for
                                                                    relief.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">Diarrhea</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox w-100">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground ">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>Limit any foods or beverages (or gum) that contain
                                                                    sugar, lactose, high-fructose corn syrup or
                                                                    sorbitol.
                                                                </li>
                                                                <li>Avoid foods with caffeine.</li>
                                                                <li>Avoid spicy foods if they make symptoms worse.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">Vomiting</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox w-100">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>After vomiting stops, you may begin to drink small
                                                                    amounts of clear liquids such as broth or apple
                                                                    juice.
                                                                </li>
                                                                <li>Once you have gone at least 4 hours without
                                                                    vomiting,
                                                                    you can start eating solid foods:
                                                                    <ul>
                                                                        <li>Start with one food at a time and eat only a
                                                                            few
                                                                            bites
                                                                        </li>
                                                                        <li>Begin with odorless foods that are low in
                                                                            fat
                                                                            and fiber (like crackers, toast, yogurt,
                                                                            sherbet, applesauce, banana, baked chicken)
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">“Delayed Gastric Emptying”</h2>
                                            <p>Stomach empties too slowly after eating</p>
                                            <p>This may occur in one-quarter of patients after surgery, and it usually
                                                gets better within a few weeks to months.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Symptoms:</p>
                                                        <p>Nausea, vomiting of undigested food, bloating, early
                                                            fullness, and abdominal pain.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100 thickWhiteBorder"/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>Eat small, frequent meals and chew foods very
                                                                    well.
                                                                </li>
                                                                <li>Liquids may work better than solids.</li>
                                                                <li>Low-fat, low-fiber soft foods may work better than
                                                                    high-fat, high-fiber tough foods.
                                                                </li>
                                                                <li>Take a walk after eating to help move food through
                                                                    your
                                                                    system faster.
                                                                </li>
                                                                <li>Ask your doctor if you will need medicine to help
                                                                    your
                                                                    stomach empty faster.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">“Dumping Syndrome”</h2>
                                            <p>Food from the stomach (especially sugar) passes too quickly into the
                                                intestine.</p>
                                            <p>Early symptoms may occur 30 to 60 minutes after eating.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Symptoms:</p>
                                                        <p>Dizziness, sweating, fast heart rate, bloating, nausea, and
                                                            diarrhea (30 to 60 minutes after eating). Later symptoms (2
                                                            to 3 hours after a meal) include feelings of weakness,
                                                            hunger, and fast heart rate.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100 thickWhiteBorder"/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>Avoid foods high in sugar (not more than 12 grams of
                                                                    sugar per serving).
                                                                </li>
                                                                <li>Drink fluid 30 minutes before or after meals, not
                                                                    with
                                                                    meals.
                                                                </li>
                                                                <li>Eat 5 to 6 small meals per day.</li>
                                                                <li>Try foods high in soluble fiber, including apples,
                                                                    apricots, bananas, blackberries, nectarines,
                                                                    oranges,
                                                                    grapefruit, pears, plums, strawberries, tangerines,
                                                                    asparagus, broccoli, brussel sprouts, carrots, kale,
                                                                    okra, spinach, mustard greens, sweet potato,
                                                                    oatmeal,
                                                                    oat bran, beans (black, kidney, lima, navy), or
                                                                    commercial fiber supple ments.
                                                                </li>
                                                                <li>Take a walk after eating to help move food through
                                                                    your
                                                                    system faster.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">Lactose Intolerance</h2>
                                            <p>Caused by a decrease in the enzyme in your intestine that digests
                                                lactose.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Symptoms:</p>
                                                        <p>Gas, bloating, or diarrhea after drinking milk or eating milk
                                                            products</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100 thickWhiteBorder"/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>Try lactose-free milk choices (such as soy milk or
                                                                    almond milk).
                                                                </li>
                                                                <li>Try reduced-lactose products (such as Lactaid or
                                                                    Dairy
                                                                    Ease products).
                                                                </li>
                                                                <li>Try lactase enzyme tablets when you eat dairy
                                                                    products.
                                                                </li>
                                                                <li>Try yogurt or cheese instead of milk.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">Bacteria Growth in the Small
                                                Intestine</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority w-100 middleGreenBorderColor middleGreenBackground">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Symptoms:</p>
                                                        <p>Nausea, gas, bloating, diarrhea, low vitamin B-12 and high
                                                            folate in the blood.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <hr className="w-100 thickWhiteBorder"/>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadAndCopyWithIcon text-black">
                                                            <i className="fa fa-square-check hightLight text-white"></i> Tips
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom blackBullets">
                                                                <li>Ask your doctor if treatment with antibiotics will
                                                                    be
                                                                    needed.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower w-100">Bloating or Feeling of Excess
                                                Gas</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic22;
