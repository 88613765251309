import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL90Component({ code, language }) {
    code = `{/* L90 Color Top Bar with special icon list */}
                                    <div className="lego l090 legoMargin3">
                                        <div className="headerColorBar">
                                            <h3>COMPLICATIONS:</h3>
                                        </div>
                                        <div className="contentContainer">
                                            <ul>
                                                <li>The most common risks <span className="bold italic">are skin irritation and redness</span> that
                                                    usually
                                                    go away within a few days without the need for medical care.
                                                </li>
                                                <li><span className="bold italic">Infection</span> (may be indicated by
                                                    a fever of 100.4o or greater, heat,
                                                    redness, or drainage at the injection site)
                                                </li>
                                                <li>Review the <span className="bold italic">Warnings, Precautions & Risks section</span> in
                                                    the <span className="bold italic">Patient
                                                    Instructions for Use</span> for potential hazard that may cause
                                                    injury,
                                                    discomfort, or damage to your system. Examples include MRI &
                                                    diathermy.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* End L90 Color Top Bar with special icon list */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL90Component;