import {ORBIT_CLUSTER, ORBIT_CLUSTER_ERROR} from "../actions/types";

const INITIAL_STATE = {
    errorMessage: '',
    tileAttributes: [],
    clusterType: '',
    clusterCssArray: [],
    useFlexContainer: false
};

export const orbitClusterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORBIT_CLUSTER:
            return {
                ...state,
                errorMessage: '',
                tileAttributes: action.payload.tileAttributes,
                clusterType: action.payload.clusterType,
                useFlexContainer: action.payload.useFlexContainer,
                clusterCssArray: action.payload.clusterCssArray
            };

        case ORBIT_CLUSTER_ERROR:
            return {
                ...state,
                errorMessage: action.payload
            };

        default:
            return state;
    }
};
