export const getUser = () => {
    return localStorage.getItem("user") ? localStorage.getItem("user") : null;
}

export const setOrbitList = (list) => {
    localStorage.setItem('orbitList', JSON.stringify(list));
}

export const getOrbitList = () => {
    return JSON.parse(localStorage.getItem('orbitList'));
}

export const setOrbitId = (orbitId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    user.defaultOrbit = orbitId;

    localStorage.setItem("user", JSON.stringify(user));
}

export const setDisplayTiles = (value) => {
    localStorage.setItem("displayTiles", value);
}

export const getDisplayTiles = () => {
    return localStorage.getItem("displayTiles") ? JSON.parse(localStorage.getItem("displayTiles")) : false;
}

export const setDiagnosticId = (diagnosticId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    user.dIdList = diagnosticId;

    localStorage.setItem("user", JSON.stringify(user));
}

export const setDiagnosticList = (diagnosticList) => {
    const user = JSON.parse(localStorage.getItem("user"));
    user.diagnosticList = diagnosticList;

    localStorage.setItem("user", JSON.stringify(user));
}

export const getExpiration = () => {
    return localStorage.getItem("expiresIn") ? localStorage.getItem("expiresIn") : null;
}

export const getLoginScreenStatus = () => {
    return localStorage.getItem("loginScreen") ? localStorage.getItem("loginScreen")  === 'true' : null;
}

export const setLoginScreenStatus = (loginScreen) => {
    localStorage.setItem("loginScreen", loginScreen);
}


