import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin4_4 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorBrightGreen pritikinBorderBrightGreen">Celebrating
                                                Successes</h1>
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 contentBoxWithTopBorder pritikinBorderDullGreen pritikinBackgroundNeonGreen">
                                        <div className="w-100">
                                            <p>There will be many milestones to celebrate during your cardiac rehab
                                                journey, starting with the moment you enroll and start down the path to
                                                a longer, healthier life!</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Unknown.jpeg"
                                                 className="img-responsive centerImage w-100
                                 maxWidth400 text-center"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Your care team will work with you to track progress every step of the way
                                                – session-to-session. But that’s just the start of how you can celebrate
                                                along that way. After all, you’re not going to cardiac rehab simply to
                                                be told what your goals should be. You’ll be an active participant in
                                                setting the goals that matter most to you and your family!
                                                You’ll be encouraged to truly make the many successes in your cardiac
                                                rehab your own!</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div
                                            className="w-100 justify-content flex-column align-items-center justify-content-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/101034.jpg" className="img-responsive centerImage w-100
                                 maxWidth400 text-center"/>
                                            <p className="captionItalic maxWidth400 centered">You’ll track progress
                                                throughout your sessions with your care team in many ways, including
                                                ways to set and review short-term goals week after week!</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps w-100 text-center pritikinColorBrightGreen">SOME
                                                KEYS TO SUCCESS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Tens of thousands of patients have already been on this journey and have
                                                learned many ways to help focus them toward success, including:</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100 text-center"><i
                                                className="fa fa-check-circle pritikinColorBrightGreen "></i>&nbsp;&nbsp;Do
                                                think: “Progress, not perfection.”</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Each step or change you can take to improve your health is progress. If
                                                you can enjoy Pritikin living 100% of the time, that is fantastic! If
                                                50% works best for you, go for it. If 25% helps you move in the right
                                                direction, pat yourself on the back and focus on your progress.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100 text-center"><i
                                                className="fa fa-check-circle pritikinColorBrightGreen "></i>&nbsp;&nbsp;Do
                                                take small steps and celebrate each success.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>As you begin incorporating the Pritikin program into your life, there may
                                                be times when you think, “Gee, this is hard.” That’s only natural.
                                                You’ve taken on a big task. You’re changing habits you’ve had for a long
                                                time, so it takes time to learn new ones.</p>
                                            <p>Small steps can ultimately lead to big strides towards an overall
                                                healthier lifestyle. There will likely be challenges, but when they
                                                happen, just keep at it. Keep practicing. After a few weeks, your new
                                                lifestyle will get easier, and the rewards you’ll achieve will make it
                                                all worthwhile.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100 text-center"><i
                                                className="fa fa-check-circle pritikinColorBrightGreen "></i>&nbsp;&nbsp;Do
                                                practice patience.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Habits are learned behaviors, and science tells us that it takes time
                                                (about three weeks) for a behavior to turn into a habit. So, practice
                                                patience. Allow yourself, day by day, to get into the rhythm of your
                                                healthy new life. The more you immerse yourself in the Pritikin program,
                                                the easier it gets.</p>
                                            <p>Before long, you’ll likely find your attitude shifting from “I should” to
                                                “I want.” You’ll look forward to eating wholesome foods, trying new
                                                recipes, and incorporating exercise into your daily routine. Pretty
                                                soon, you’ll be feeling fantastic and wonder, “Why did I ever live any
                                                other way?”</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100 text-center"><i
                                                className="fa fa-check-circle pritikinColorBrightGreen "></i>&nbsp;&nbsp;Do
                                                remain optimistic.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>With cardiac rehab, getting healthy can often happen very, very quickly.
                                                Within just two to three weeks of adopting a Pritikin lifestyle,
                                                numerous studies have found that all kinds of amazing benefits are
                                                happening within your body. Blood sugar, for example, often starts
                                                falling into normal ranges. Cholesterol levels, particularly LDL (“bad”)
                                                cholesterol, improve. Blood pressure and levels of inflammatory
                                                chemicals linked with heart health get better as well.</p>
                                            <p>Remember, too, that not only will you reap these internal health benefits
                                                quickly, but you’ll start seeing changes on the outside. Your clothes
                                                may start fitting better, and you will very likely have more energy.
                                                What a gift! Less pain means you may be able to resume sports and other
                                                activities you have enjoyed in the past.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin4_4;