import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import '@fortawesome/fontawesome-svg-core/styles.css';

const LessonDisposalFAQResources = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDisposal">Disposal
                                                            of Medical Waste</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/FAQResources#questions">
                                                    <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDisposal/FAQResources#resources">
                                                    <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="questions">QUESTIONS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">What is the best way to protect myself when I have
                                                    to handle medical waste?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Your best protection is to always wear disposable gloves. You can buy these gloves in a drug store or medical equipment store.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">How do I throw away used needles?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Throw away any used needles or sharp lancets used for blood testing into a sharps container. You can buy these sturdy, non-recyclable containers at a medical equipment store or you can use a heavy-duty laundry detergent bottle.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">The person I care for always soils the bed linens with urine. What do I do?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Launder the soiled linens separate from regular linens or clothes. When you remove the linens from the bed, place them in a plastic bag. When it is time to launder, remove the linens from the bag while wearing disposable gloves. Wash the soiled linens separately in the washer and add some chlorine bleach to the wash cycle. Dry the linens in your regular clothes dryer.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">The person I care for has HIV. What do I do if I get stuck by a needle?</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Wash the injured area with soap and water and rinse well. The chance of you getting HIV is very low, but call your doctor. The doctor may decide to have you tested for HIV. If you know the person you are caring for has HIV, get a “baseline” blood test before you provide care so that future blood tests can be compared to the baseline.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="resources">RESOURCES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 twoSideBars">
                                        <div className="w-100">
                                            <div className="w-100">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill sidebarNoFillborderColor withSideIcon marginRight w-100">
                                                        <img className="img-responsive maxWidth75" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo.png" />
                                                        <p className="resourcePartner"><strong>Medline Plus. A Service of the United States National Library of Medicine</strong></p>
                                                        <p>Provides information on a variety of health topics, including hepatitis and HIV.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.nlm.nih.gov/medlineplus" target="_blank">www.nlm.nih.gov/medlineplus</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill sidebarNoFillborderColor withSideIcon marginRight w-100">
                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logoats.jpg" />
                                                        <p className="resourcePartner"><strong>The Occupational Health And Safety Administration</strong></p>
                                                        <p>A division of the United States Department of Labor. OSHA’s mission is to prevent work-related injuries, illnesses, and deaths. The website has information about how to prevent accidental needle sticks.</p>
                                                        <p className="resourceLink">visit: <a href="https://www.osha.gov/SLTC/bloodbornepathogens/" target="_blank">www.osha.gov/SLTC/bloodbornepathogens/</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDisposalFAQResources;
