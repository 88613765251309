import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll4_1C = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <a href="">
                                        <div className="tile Height1RowTall width2Column t_009 t_009_2x1 sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Tracheostomy Tube ></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <a href="">
                                        <div className="tile Height1RowTall width1Column blueBackground t_001 sitemanDarkerBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Feeding Tubes ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <a href="">
                                        <div className="tile Height1RowTall width2Column blueBackground t_009 t_009_2x1 sitemanLightestBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor sitemanT_25FontSize">Wound Care ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <a href="" className="">
                                        <div className="tile Height1RowTall width1Column t_001 sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Drains ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <a href="">
                                        <div className="tile Height1RowTall width2Column t_009 t_009_2x1 sitemanMiddleBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor sitemanT_25FontSize">Your New Normal ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <a href="">
                                        <div className="tile Height1RowTall width1Column t_001 sitemanLightestBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">Devices and<br/>Supplies >
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll4_1C;