import React from 'react';
import Container from 'react-bootstrap/Container';
import '@fortawesome/fontawesome-svg-core/styles.css'

const SitemanPancreaticAll7_5 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-lg-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Finding Activities
                                                Nearby</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <p>We provide a variety of services and information to help you navigate and
                                                take advantage of our facility’s amenities.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight backgroundAccentBlue">
                                                <h2 className="subheadUpperAndLower mb-0">Concierge Services Can
                                                    Help!</h2>
                                            </div>
                                            <div className="withBorder borderAccentBlue entBaseBlueBorderColor">
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <p>While visiting patients at Barnes-Jewish Hospital, concierge
                                                            representatives are available to help assist you with
                                                            information, services and special requests, including: </p>
                                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                            <div className="col-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom colorAccentLightGreenBullets checkboxBullets">
                                                                        <li>Your initial diagnosis</li>
                                                                        <li>Names of your doctors</li>
                                                                        <li>Surgical findings, if any</li>
                                                                        <li>Specifics about what treatments you
                                                                            received
                                                                        </li>
                                                                        <li>What ongoing medications you will take for
                                                                            your cancer diagnosis
                                                                        </li>
                                                                        <li>Schedule of recommended follow-up visits and
                                                                            tests
                                                                        </li>
                                                                        <li>Potential side effects from your cancer
                                                                            treatment
                                                                        </li>
                                                                        <li>Recommended lifestyle and behaviors</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>Concierge assistance is offered at no charge. Services
                                                            provided by outside vendors may include a fee. Concierge
                                                            representatives are located at the information desks on the
                                                            hospital’s South Campus and in the Center for Advanced
                                                            Medicine.</p>
                                                        <p className="text-center"><span>314-362-5194</span>
                                                            <a href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Grand_Basin.png" className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h2 className="headline">THE NEIGHBORHOOD</h2>
                                            <p>The neighborhood surrounding the medical campus is called the <em>Central
                                                West End</em> Visit <a href="https://thecwe.org/">thecwe.org</a> or <a
                                                href="https://marylandplaza.com/">marylandplaza.com</a> for details on
                                                options throughout the neighborhood which include a big variety of
                                                restaurants, shopping, theaters and much more.</p>
                                            <p>The campus is also beside Forest Park, one of the finest urban parks in
                                                the country, filled with things to do for individuals and families, many
                                                of which are free. These include free admissions to the world-class St.
                                                Louis Zoo, as well as art and history museums and a science
                                                center/planetarium perfect for kids and adults. The park also offers
                                                recreational activities like miles of jogging/biking/nature paths and
                                                trails, paddleboats, a racquet sports center, a boathouse restaurant, an
                                                outdoor theater, multiple golf courses and a full calendar of special
                                                events.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="imagesAcross">

                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Mo_History_Museum_logo.jpeg"
                                                         className=""/>

                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/SLAM_logo.jpg"
                                                         className=""/>

                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/zoo.gif" className=""/>

                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/STL_Science_Center_logo.jpg"
                                                         className=""/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <p>Find out more about Forest Park <a
                                                href="https://forestparkmap.org/">here.</a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-2 col-3 imgCol noPaddingLeft">
                                                <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/FP_Map_Detail.png"
                                                     alt=""/>
                                            </div>
                                            <div className="col-10 col-9 typeCol noPaddingLeft">
                                                <h2 className="headline"><a
                                                    href="https://forestparkmap.org/">forestparkmap.org</a></h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h2 className="headline w-100">AREA RESTAURANTS</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/CWE_street.jpg" className="img-responsive w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <p>There are over 100 restaurants of all varieties in the neighborhood, many
                                                within walking distance. These range from quick, inexpensive lunch
                                                options to more formal dining.</p>
                                            <p>An Applebee’s is directly across the street from the Center for Advanced
                                                Medicine and a Panera Bread café is across Forest Park Parkway. Many
                                                other selections are a short walk north on Euclid Avenue. The
                                                Information/Concierge Desks have brochures on this area</p>
                                            <p>For authentic Italian cuisine, patients and visitors may be interested in
                                                driving to The Hill, a local neighborhood known for its
                                                nationally-acclaimed restaurants, specialty groceries and
                                                bakeries. <strong>The Hill</strong> neighborhood is about 1.5 miles
                                                south of the hospital, off of Kingshighway Boulevard.</p>
                                            <p>Other dining options can be found at <a
                                                href="https://stldining.com/">stldining.com</a> or <a
                                                href="https://marylandplaza.com/">marylandplaza.com.</a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h2 className="headline">ADDITIONAL ACTIVITIES</h2>
                                            <p><em><a href="tel:3143620750">314-362-0750</a></em></p>
                                            <p className="width100">Visit <a href="https://explorestlouis.com/">explorestlouis.com</a> for
                                                more options from throughout the St. Louis area.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Explore_StLouis_logo.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanPancreaticAll7_5;
