import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LessonDressingIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDressing">Dressing A Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">Introduction</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation ">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDressing/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonDressing/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/WLK_woman_with_walker.png" className="img-responsive floatNone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>The way people dress often tells us how they think about themselves. We choose the clothes we wear based on comfort and the image we wish to make with others. However, illness can make it difficult for a person to dress.</p>
                                                <p>For example, people with neurological problems, like Parkinson’s disease or those affected by a stroke, lose strength and the ability to use their fingers to put on or fasten clothing. Also, a person with arthritis may find it too painful or have stiffness that makes it hard to pull on underwear or stockings.</p>
                                                <p>Those with dementia might not be able to make choices on what to wear or they may not remember how to put on their clothes. A person with serious lung disease or chronic fatigue may not want to dress or change clothes.</p>
                                                <p className="boldLeadIn">When you care for a person who needs help with dressing, follow these guidelines:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Keep the person as independent as possible</li>
                                                    <li>Make the person comfortable</li>
                                                    <li>Select clothing that the person can put on easily</li>
                                                    <li>Follow a routine for dressing</li>
                                                    <li>Give the person time to dress, do not rush</li>
                                                    <li>Change clothes regularly</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>The way people dress often tells us how they think about themselves.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="z03U-jWz8DY" opts={opts}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDressingIntroduction;
