import React from "react";

function L95ComponentCode() {

    return (

        <div className="lego l095">
            <div className="contentBox box1">
                <p>ACUPUNCTURE</p>
            </div>
            <div className="contentBox box2">
                <p>PHYSICAL THERAPY</p>
            </div>
            <div className="contentBox box3">
                <p>NUTRITIONAL COUNSELING</p>
            </div>
            <div className="contentBox box4">
                <p>MEDICAL MASSAGE</p>
            </div>
        </div>

    )
};


export default L95ComponentCode;