import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll9_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-12">
                                    <div className="w-100 hospitalInfoClusterFlexContainer">
                                        <div className="col-4 paddingLR5 hvr-grow">
                                            <Nav.Link href="/siteman-ent-all-9-1">
                                                <div
                                                    className="tile t_003 Height1RowTall width1Column sitemanDarkerBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="centerCircle">
                                                            <img alt="" className="img-responsive img-circle"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0041_key-contacts-icon.svg"/>
                                                        </div>
                                                        <div className="textBelowIcon">
                                                            <h3 className="text-center whiteColor">Contact Info ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-4 paddingLR5 hvr-grow">
                                            <Nav.Link href="https://www.google.com/maps/dir/''//@38.7112903,-90.5478191,11z/data=!4m8!4m7!1m5!1m1!1s0x87d8b52094fa988d:0xfcfea69622af0ea3!2m2!1d-90.2634221!2d38.6384816!1m0">
                                                <div
                                                    className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemaGoogleMapBackgroundImg"
                                                    sitemaGoogleMapBackgroundImg>
                                                    <div className="contentCenter">
                                                        <div
                                                            className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                            <div className="centeringContainer">
                                                                <div className="text-center">
                                                                    <h3 className="whiteColor text-center">Live Google
                                                                        Map ></h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-8 paddingLR5 hvr-grow">
                                            <Nav.Link href="/siteman-ent-all-9-4">
                                                <div
                                                    className="tile Height1RowTall width2Column t_004 t_004_2x1 sitemanLightestBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="centerCircle">
                                                            <img alt="" className="img-responsive img-circle"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0057_pin-map-icon.svg"/>
                                                        </div>
                                                        <div className="textBelowIcon">
                                                            <h3 className="text-center whiteColor">Directions &amp; Maps
                                                                ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-4 paddingLR5 hvr-grow hospitalInfoTileAlign">
                                            <Nav.Link href="/siteman-ent-all-9-3">
                                                <div
                                                    className="tile Height2RowTall width1Column t_010 t_010_1x2 sitemanLightBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="tileJustText">
                                                            <div className="textBorderTopBorderBottom">
                                                                <h3 className="text-center whiteColor">Logistics and
                                                                    <br/>Concierge ></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                    </div>
                                    <div className="w-100 wow fadeInRight animated">
                                        <div className="col-4 paddingLR5 hvr-grow">
                                            <Nav.Link href="/siteman-ent-all-9-5">
                                                <div
                                                    className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemaNearbyBackgroundImg">
                                                    <div className="contentCenter">
                                                        <div
                                                            className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                            <div className="centeringContainer">
                                                                <div className="text-center">
                                                                    <h3 className="whiteColor text-center">Nearby ></h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-4 paddingLR5 hvr-grow">
                                            <Nav.Link href="/siteman-ent-all-9-6">
                                                <div
                                                    className="tile t_003 Height1RowTall width1Column sitemanMiddleBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="centerCircle">
                                                            <img alt="" className="img-responsive img-circle"
                                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0007_facility-icon.svg"/>
                                                        </div>
                                                        <div className="textBelowIcon">
                                                            <h3 className="text-center whiteColor">Lodging &amp;<br/>Transportation
                                                                ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                        <div className="col-4 paddingLR5 hvr-grow">
                                            <Nav.Link href="https://covid19.bjc.org/Portals/0/PDF%20Documents/General%20Updates/530986_C19_ICC_VisitorPolicy.pdf?ver=IyE4mCg3kpElARP9dbDATw%3d%3d">
                                                <div
                                                    className="tile Height1RowTall width2Column t_009 sitemanDarkerBlueBackground">
                                                    <div className="contentCenter">
                                                        <div className="tileJustText">
                                                            <h3 className="text-center whiteColor">COVID-19<br/>
                                                                Requirements ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll9_0;