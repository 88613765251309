import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic26 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center middleGreenColor middleGreenBorderColor">Support</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">RECOMMENDED LIVER AND PANCREAS CANCER PATIENT RESOURCES</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill marginRight minMaxHeight">
                                                        <img className="img-responsive maxWidth200" style={{
                                                            minWidth: '400px',
                                                            maxWidth: '500px', }}  src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nci-logo-full.svg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">The National Cancer Institute website</h2>
                                                        <p>Search for your cancer type. There is detailed information regarding the diagnosis, staging, treatment and follow-up for patients with tumors of the liver and pancreas. Includes information about clinical trials.</p>
                                                        <p className="resourceLink">visit: <a href="#" target="_blank">Cancer.gov (specific pancreatic link to come)</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill marginRight minMaxHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">PanCan (Pancreatic Cancer Action Network).</h2>
                                                        <p>An excellent place for patients to learn about the diagnosis and treatment of pancreatic cancer. Has detailed information about surgical management, chemotherapy and research activities.</p>
                                                        <p className="resourceLink">visit: <a href="#" target="_blank">Pancan.org (specific pancreatic link to come)</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill marginRight minMaxHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/asco-logo.jpg" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Gastrointestinal Cancers of the American Society of Clinical Oncology (ASCO) and Journal of Clinical Oncology (JCO) website</h2>
                                                        <p>Latest information about results of clinical trails, new information, new treatments and news about gastrointestinal (including tumors of the liver and pancreas). This information can be more technical/medical than the other websites, but contains an extensive amount of information.</p>
                                                        <p className="resourceLink">visit: <a href="#" target="_blank">gi.jco.org (specific pancreatic link to come)</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill marginRight minMaxHeight">
                                                        <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/1124394_20101498295097.gif" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">Lustgarten Foundation</h2>
                                                        <p>A foundation dedicated to advancing the scientific and medical research related to the diagnosis, treatment and cure of pancreatic cancer.</p>
                                                        <p className="resourceLink">visit: <a href="https://lustgarten.org" target="_blank">lustgarten.org</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars">
                                        <div className="w-100">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="sidebarNoFill marginRight minMaxHeight">
                                                        <img className="img-responsive" style={{
                                                            minWidth: '120px',
                                                            maxWidth: '150px', }} src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/American_Cancer_Society_Logo.png" />
                                                        <h2 className="subheadUpperAndLower withBodyCopy">American Cancer Society website</h2>
                                                        <p>Search for your cancer type. There is detailed information regarding the diagnosis, staging, treatment and follow-up for patients with tumors of the liver and pancreas. Includes information about clinical trials.</p>
                                                        <p className="resourceLink">visit: <a href="#" target="_blank">Cancer.org/cancer/index (specific pancreatic link to come)</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop middleGreenColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic26;
