import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll9_6 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center">Lodging and
                                        Transportation</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">ARRANGING FOR LODGING</h2>
                                    <p>You have many options when locating affordable, convenient lodging. Barnes Jewish offers help with questions regarding lodging options by calling our <a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">concierge services</a> at <a href="tel:314-362-5196">314-362-5196</a> or emailing <a href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a>. Your physician’s office can also provide printouts with more details as
                                        needed.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Barnes Lodge</h2>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 vertMiddle">
                                    <div className="col-3 col-4 imgCol noPaddingLeft">
                                        <img className="img-responsive img-border noMarginBottom" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BarnesLodge_entry.jpg" alt="" />
                                    </div>
                                    <div className="col-9 col-8 typeCol noPaddingLeft">
                                        <p>4520 Clayton Ave.<br />
                                            St Louis, MO 63110<br />
                                            <a href="tel:3146524319">314-652-4319</a><br />
                                            <a href="http://www.barnesjewish.org/patients-visitors/lodging/barnes-lodge" target="_blank">website <i className="fa fa-chevron-right"></i></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p><em>Located on the Washington University Medical Center campus.</em></p>
                                    <p>Barnes Lodge is a 20-room guesthouse for outpatients and families of patients receiving medical services at Barnes-Jewish Hospital. In addition to sleeping rooms, Barnes Lodge offers a dining room, a kitchen, television rooms, laundry facilities and quiet rooms. To be eligible to stay at Barnes Lodge, you must live at least 50 miles from St. Louis. Guests are served on a first-come, first-served basis – reservations are not accepted.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Hope Lodge Center</h2>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 vertMiddle">
                                    <div className="col-3 col-4 imgCol noPaddingLeft">
                                        <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/acspc-028052.jpg" alt="" />
                                    </div>
                                    <div className="col-9 col-8 typeCol noPaddingLeft">
                                        <p>4207 Lindell Blvd.<br />
                                            St Louis, MO 63108<br />
                                            <a href="tel:3142868150">314-286-8150</a><br />
                                            <a href="http://www.cancer.org/treatment/supportprogramsservices/hopelodge/stlouis/index" target="_blank">website <i className="fa fa-chevron-right"></i></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>The Hope Lodge Center, located about seven blocks from the hospital, serves cancer patients who live at least 50 miles from St. Louis. There is no charge for lodging. Referrals must be made by a physician, social worker or nurse care manager. Patients must complete a referral form. This is a smoke-free facility. Please call for eligibility requirements. Shuttle service to the hospital is provided.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">DoubleTree by Hilton St. Louis Forest Park</h2>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 vertMiddle">
                                    <div className="col-3 col-4 imgCol noPaddingLeft">
                                        <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Parkway_Hotel.png" alt="" />
                                    </div>
                                    <div className="col-9 col-8 typeCol noPaddingLeft">
                                        <p>4550 Forest Park Avenue<br />
                                            St. Louis, MO 63108<br />
                                            <a href="tel:3142567777">314-256-7777</a> / <a href="tel:8663147700">866-314-7700</a><br />
                                            <a href="https://www.hilton.com/en/hotels/stlpkdt-doubletree-st-louis-forest-park/" target="_blank">website <i className="fa fa-chevron-right"></i></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>The DoubleTree by Hilton St. Louis Forest Park is connected to the Center for Advanced Medicine by the skywalk in the hospital’s north garage. The hotel is two blocks from the Central West End Metro Link station. When making a reservation, ask for the Barnes-Jewish Hospital patient/family rate.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">RV Parking</h2>
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 vertMiddle">
                                    <div className="col-3 col-4 imgCol noPaddingLeft">
                                        <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/RV_Parking.png" alt="" />
                                    </div>
                                    <div className="col-9 col-8 typeCol noPaddingLeft">
                                        <p>600 S. Taylor Ave.<br />
                                            St. Louis, MO 63110<br />
                                            <a href="tel:3143620750">314-362-0750</a><br />
                                            <a href="https://www.barnesjewish.org/Patients-Visitors/Locations-Directions/Parking-Information" target="_blank">more information <i className="fa fa-chevron-right"></i></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>There are electric-only hook-ups at no charge for RVs on the Barnes-Jewish Hospital campus. <a href="https://www.barnesjewish.org/Patients-Visitors/Hospitality-Services" target="_blank">Concierge services</a> has a list of other local RV parking areas. Stop by the information desk, call <a href="tel:3143625196">314-362-5196</a> or email us at <a href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a>.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">PARKING</h2>
                                    <p>There are multiple parking garages to serve patients and visitors at Barnes-Jewish Hospital on the Washington University Medical Campus. Because of the size of the hospital, you will want to park in the garage nearest the area you will be visiting or receiving care. Our off-campus locations offer convenient parking lots directly attached to the facilities.</p>
                                    <p>Family members and visitors of patients staying overnight (inpatients) can purchase a discounted parking pass card to use at any of the garages. More than one family member or visitor of an inpatient can use the pass, which has a two-year expiration date. Passes are $20 for 5 exits and are sold in the Plaza Tower gift shop, the valet desks and in the cafeterias in the evenings and on weekends.</p>
                                    <p>Review <a href="https://www.barnesjewish.org/Patients-Visitors/Locations-Directions/Parking-Information" target="_blank">Up-to-date Parking Information</a> including campus garages, valet services available, rates and discounted parking pass cards.</p>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">EXTENDED STAY PARKING FOR INPATIENTS</h2>
                                    <p><em><a href="tel:3143620750">314-362-0750</a></em><br />
                                        Inpatients who drive their vehicle to the hospital, and will have an
                                        extended stay, may call <a href="tel:3143620750">314-
                                            362-0750</a> and a member of public safety will bring them a parking
                                        voucher, which will waive all fees if the vehicle is not moved during
                                        the inpatient’s stay. Anyone needing assistance may also contact the
                                        hospital’s department of public safety at <a href="tel:3143620750">314-362-0750</a>. For emergencies,
                                        call <a href="tel:3143620911">314-362-0911</a>.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 imgColLeftContentColRight">
                                <div className="w-100 vertMiddle">
                                    <div className="col-2 imgCol noPaddingLeft">
                                        <img className="img-responsive img-border" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/in-route_icon.svg" alt="" />
                                    </div>
                                    <div className="col-10 typeCol noPaddingLeft">
                                        <h2 className="headline">IN-ROUTE
                                            APPOINTMENT HOTLINE</h2>
                                        <h2 className="headline"> <a href="tel:8665149940">866-51-HWY40 (866-514-9940)</a></h2>
                                        <p>If you are unexpectedly delayed due to traffic, <strong><em>don’t worry</em></strong>. Call us and we will notify your physician that
                                            you may be late. Hotline operators are available Monday through
                                            Friday during normal business hours to notify your physician and
                                            provide alternate directions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">SHUTTLE SERVICE</h2>
                                    <p><em><a href="tel:3143620750">314-362-0750</a></em><br /> The hospital offers a free, wheelchair-accessible shuttle to patients and visitors from 7 a.m.-11
                                        p.m. Monday through Friday. The route includes the hospital’s north and
                                        south campus, Barnes Lodge, Hope Lodge and the Ronald McDonald House.
                                        The shuttle picks up every 20 minutes.</p>
                                    <p><a href="https://www.barnesjewish.org/Patients-Visitors/Locations-Directions/Shuttle-Service" target="_)blank">Weekday Shuttle Schedule ></a></p>
                                    <p>On weekends and holidays, transportation is available via a hospital security vehicle every two even hours from 8 a.m. – 8 p.m. Please call the shuttle number <a href="tel:3143620750">(314-362-0750)</a> to confirm a pick-up at a certain time. Weekend and holiday hours do not include Hope Lodge or the Ronald McDonald House.</p>
                                    <p className="bold">Shuttle busses stop in the following order on the Washington University Medical Center campus:</p>
                                    <p className="italic">Barnes-Jewish Plaza Tower (entrance located off Barnes-Jewish Hospital Plaza Drive)</p>
                                    <p className="italic">St. Louis Children’s Hospital (entrance located off Children’s Place)</p>
                                    <p className="italic">Barnes-Jewish Parkview Tower</p>
                                    <p className="italic">Center for Advanced Medicine (entrance located off Parkview Place)</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" href="#">BACK TO TOP</a></p>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll9_6;
