import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic18 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Nutrition</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">IN GENERAL</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-md-2 col-xs-3 imgCol noPaddingLeft">
                                                <img className="img-responsive"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/food_items.jpg" alt=""/>
                                            </div>
                                            <div className="col-md-10 col-xs-9 typeCol noPaddingLeft">
                                                <p>As a result of your surgery, the amount of food you can eat at one
                                                    time and how your body handles food will change. Weight loss after
                                                    your surgery is to be expected. Your goal is to limit that weight
                                                    loss, provide your body the nutrients it needs to heal and help
                                                    avoid complications that can arise by not following a proper diet.
                                                </p>
                                                <p className="mobileMarginBottom30">Proper nutrition is vital to healing
                                                    after your surgery, though there could be some challenges in the
                                                    early stages of recovery at home.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">You may not have much of an appetite
                                                once you return home after your operation. This is common.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Though you’ll have no food restrictions, we encourage you to eat well
                                                with balanced, low fat meals. Go slowly and eat only what feels
                                                comfortable to you.</p>
                                            <p>You should avoid alcoholic beverages while still on any pain meds.
                                                Typically, assume you can resume three months after your operation,
                                                unless otherwise instructed by your physician. It will likely take some
                                                time to sort out which foods agree best with your body after this type
                                                of surgery. Often, people report that the taste of some foods seems
                                                different. For some patients fatty foods are difficult to digest, and
                                                may cause diarrhea and abdominal cramping. Some patients are lactose
                                                intolerant for a couple weeks after surgery.</p>
                                            <p>In general, a trial and error approach towards eating food is
                                                recommended. Some patients report that it takes <strong>approximately
                                                    4-6 weeks until they feel their appetite has returned to
                                                    normal.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority middleGreenBorderColor text-center">
                                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                    <div className="col-12 subheadAndBullets">
                                                        <div className="w-100">
                                                            <ul className="bulletsCustom checkBullets greenBullets">
                                                                <li className="text-start">Pre-Operative Tests</li>
                                                                <li className="text-start">Make a goal of eating an
                                                                    adequate amount of a variety of foods.
                                                                </li>
                                                                <li className="text-start">Foods high in fat or sugar
                                                                    may cause distress.
                                                                </li>
                                                                <li className="text-start">Try 6 small meals that are
                                                                    high in calories and protein.
                                                                </li>
                                                                <li className="text-start">To help with healing, eat
                                                                    foods high in protein, such as tender meats,
                                                                    poultry, fish, dairy products, eggs, peanut butter,
                                                                    and beans.
                                                                </li>
                                                                <li className="text-start">Relax at mealtime and chew
                                                                    food well. Stop eating when you feel full.
                                                                </li>
                                                                <li className="text-start">For the first few weeks,
                                                                    avoid drinking large amounts of fluid with meals.
                                                                    Small sips are okay. Drink most fluids 30 minutes
                                                                    before and after meals. Drink 48 to 64 oz. (6 to 8
                                                                    cups) of fluid throughout the day.
                                                                </li>
                                                                <li className="text-start">Keep healthy snacks and
                                                                    supplements (e.g. Boost, Ensure, Glucerna, Carnation
                                                                    Instant Breakfast) handy for easy small meals.
                                                                </li>
                                                                <li className="text-start">Take enzymes properly to help
                                                                    supplement your digestion and absorption of food
                                                                    when needed.
                                                                </li>
                                                                <li className="text-start">Try not to lose weight, even
                                                                    if you are overweight, because it can make you feel
                                                                    weaker and can delay healing. Your registered
                                                                    dietitian or nutritionist can help you with ideas
                                                                    for maintaining your weight if needed.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Digestive Issues</p>
                                            <p>As your digestive system heals, a variety of common issues can arise that
                                                can cause you some discomfort and delay your recovery. Please review the
                                                sections within this reference tool called <strong><em>“Common Issues &
                                                    Medical Complications”</em></strong> for details on what to watch
                                                for, and what steps can help address any problems.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">FOODS TO STOCK AND FOODS TO AVOID</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Tolerance for food usually improves over time. You may eat foods that you
                                                like and can tolerate. High-fat and high-sugar foods may not be
                                                tolerated well and can cause or increase a variety of problems in the
                                                early weeks of your recovery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 threecolumnTable">
                                        <div className="w-100">
                                            <table className="table table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="leftCol">
                                                        <h4>Dairy</h4>
                                                    </th>
                                                    <th className="centerCol cyanTableBackgroundColor">
                                                        <h4>Recommended in<br/>
                                                            the first few weeks:</h4>
                                                    </th>
                                                    <th className="rightCol">
                                                        <h4>May cause distress:</h4>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p>&nbsp;</p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Milk products as tolerated, lactose- free or lactose-reduced
                                                            products, sugar-free yogurt, sugar- free pudding, cheese,
                                                            sugar- free ice cream, low-fat cottage cheese, low-fat
                                                            yogurt, cheese
                                                        </p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Heavy creams, cheese sauce, cocoa mixes, regular ice cream,
                                                            chocolate milk, sweetened custard or pudding, regular
                                                            yogurt, milkshakes
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Protein</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Lean chicken and turkey breast, beef (round, loin or
                                                            tenderloin), pork (tenderloin loin, chop, Canadian bacon),
                                                            fish, eggs, cooked dry beans, smooth peanut butter, peas,
                                                            cheese, cottage cheese</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>All fried or highly-seasoned meat, poultry, pork and fish,
                                                            lunch meats, bologna, salami, hot dogs, bacon, tough/stringy
                                                            meats, nuts, chunky peanut butter
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Grains<br/>
                                                            (Breads, Cereals)</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Crackers, pasta, plain breads and rolls, pretzels, rice,
                                                            unsweetened cereals (dry or cooked), English muffin, bagels,
                                                            baked chips</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Pastries, doughnuts, muffins, biscuits, sugar-coated cereals,
                                                            sweet rolls</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Vegetables</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Cooked vegetables (other than those listed to avoid),
                                                            vegetable juice</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Fried vegetables, French fries, raw vegetables, cooked
                                                            vegetables including beets, broccoli, brussel sprouts,
                                                            cabbage, mustard and turnip greens, cauliflower, corn,
                                                            potato skins</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Fruits</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Soft fresh fruit, fruit canned in natural juices, unsweetened
                                                            fruit juice</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Tough fresh fruits, avocado, dried fruits, canned or frozen
                                                            fruits in syrup, sweetened fruit</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Desserts</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Low-fat ice cream, sherbet, angel food cake, vanilla wafers,
                                                            low-calorie gelatin, low-calorie popsicles, sugar-free
                                                            desserts</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Regular popsicles, regular gelatin, high-sugar desserts,
                                                            cake, pie. Use fatty desserts in moderation</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Beverages</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Non-carbonated/sugar-free or low sugar beverages, water,
                                                            diluted fruit juice, 2%-1%-1/2% or skim milk if tolerated,
                                                            unsweetened iced tea</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Carbonated beverages (even diet) due to gas formation,
                                                            regular soft drinks, sugared drink mixes, sugar- containing
                                                            fruit-flavored beverages, sweetened iced tea or similar
                                                            drinks, alcohol, chocolate or high-fat milks, eggnog,
                                                            regular coffee</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol">
                                                        <p><strong>Condiments</strong></p>
                                                    </td>
                                                    <td className="centerCol cyanTableBackgroundColor">
                                                        <p>Salt, pepper, mild-flavored sauces and gravies, other spices
                                                            as tolerated, low-fat salad dressing, mustard, catsup,
                                                            marinara sauce, artificial sweeteners, low-calorie jelly</p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>Heavy gravies, hollandaise sauce, mayonnaise, cheese sauce,
                                                            white sauce, sugar, regular jams and jellies</p>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">TAKING ENZYMES (CREON, ZENPEP, VIOKACE,
                                                PANCREALIPASE)</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>A healthy pancreas secretes a variety of enzymes that aid in the
                                                digestion of your food. When your pancreas is not functioning correctly
                                                you may need to supplement your digestion with enzyme pills to improve
                                                digestion and absorption of food and thus improving healing.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Signs you need enzymes</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you are having greasy, oily stools that float in the toilet rather
                                                than sink, accompanied by weight loss because you are not absorbing your
                                                nutrients properly.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">How and when to take:</h2>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>Take enzymes in the middle of every meal or snack (about 5
                                                        minutes
                                                        after you’ve begun eating).
                                                    </li>
                                                    <li>Swallow tablets and capsules intact. The tablets should not be
                                                        crushed or chewed unless you are directed to do that by a member
                                                        of
                                                        your medical team.
                                                    </li>
                                                    <li>Enzymes should not be taken at the same time as calcium- or
                                                        magnesium-containing antacids such as Maalox, Tums, Mylanta or
                                                        Rolaids.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic18;
