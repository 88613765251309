import React from "react";

function L14DComponentCode() {

    return (

        <div className="lego l00paragraph">
            <p><a className="consistentLinkColor" href="https://www.SprintPNS.com" rel="noreferrer noopener" target="_blank">www.SprintPNS.com</a></p>
        </div>

    )
}

export default L14DComponentCode;