import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {HashLink as Link} from 'react-router-hash-link';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic7 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">After Your Surgery</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-2#immediatelyAfter">Immediately After
                                                        Your Surgery</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-2#restOfDay">The Rest of the Day/Night
                                                        at Your Room</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-2-2#devices">Tubes/Drains/Lines and Other
                                                        Devices</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="immediatelyAfter" className="subheadAllCaps">WHAT TO EXPECT
                                                IMMEDIATELY AFTER YOUR SURGERY</h2>
                                            <p>Your family will have been given a pager to receive updates during your
                                                procedure. After the procedure, the surgeon will have reported to your
                                                family how the surgery went.</p>
                                            <p>From the Operating Room, you will be taken to the Postoperative Acute
                                                Care Unit (PACU) for about two hours. One or two of your family will be
                                                allowed to visit you there once you’ve been stabilized and are awake.
                                                It’s likely you’ll have no memory of this immediate postoperative
                                                time.</p>
                                            <p>Based on your needs after the surgery, you will then be taken to your
                                                hospital room in one of three possible locations on the campus. The
                                                determination of which location will happen after your surgery and will
                                                be communicated to your family (as well as the visitor rules for that
                                                location).</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="restOfDay" className="subheadAllCaps">THE REST OF YOUR DAY/NIGHT
                                                AFTER SURGERY</h2>
                                            <p>Once in your room, you’ll meet part of your staff. Activities are
                                                primarily bedrest overnight. You will be asked to start some deep
                                                breathing and controlled coughing exercises to help your progress.</p>
                                            <p>You’ll be informed of any “warning signs” that you should immediately
                                                report to your nurses by using your call button. A list is also
                                                available in this reference tool.</p>
                                            <p>Pain will be managed this day via an epidural and a Patient-Controlled
                                                Analgesia (PCA, also known as the “pain button”).</p>
                                            <p>You won’t eat (by-mouth) for the remainder of the day/evening. Your
                                                attached lines will provide the needed nutrition and fluids.</p>
                                            <p>Your incision will be covered with a dressing. It may have some blood on
                                                it. It will be removed on Day 2 after your operation.</p>
                                            <p>In addition to the dressing, you will see a variety of tubes, drains,
                                                lines and other devices attached to your body.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 id="devices" className="subheadAllCaps">TUBES, DRAINS, LINES,
                                                DEVICES</h2>
                                            <p>A variety of equipment will be in use to help your recovery, increase
                                                your safety and improve your progress. As you improve, equipment will be
                                                removed or replaced with other methods that allow you to get closer to
                                                your normal activity and comfort.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-md-4 col-xs-12 imgCol noPaddingLeft">
                                                <img className="img-responsive"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/human.jpg" alt=""/>
                                            </div>
                                            <div className="col-md-8 col-xs-12 typeCol noPaddingLeft">
                                                <p className="boldLeadIn">Tubes and Drains</p>
                                                <p className="boldLeadIn">Nasogastric tube (NGT)</p>
                                                <p className="boldLeadIn">Blake or Jackson-Pratt (JP) Surgical Drain</p>
                                                <p className="boldLeadIn">Externalized Pancreatic Duct Stent
                                                    Catheter</p>
                                                <p className="boldLeadIn">Foley Catheter</p>
                                                <p className="boldLeadIn">Lines</p>
                                                <p className="boldLeadIn">Central Venous Line</p>
                                                <p className="boldLeadIn">Peripherally Inserted Central Catheter
                                                    (PICC)</p>
                                                <p className="boldLeadIn">Peripheral Venous Line (IV)</p>
                                                <p className="boldLeadIn">Arterial Line</p>
                                                <p className="boldLeadIn">Other Devices</p>
                                                <p className="boldLeadIn">Telemetry</p>
                                                <p className="boldLeadIn">Sequential Compression Devices (SCD’s)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">TUBES AND DRAINS</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Nasogastric tube (NGT)</p>
                                            <p>This is a tube to drain your stomach so as to not create a lot of
                                                pressure on your new connection between your stomach and small bowel. It
                                                is inserted in the OR through your nose while you are asleep. It will be
                                                connected to a suction device on the wall or to a bag hanging on your
                                                bed. This is generally removed within 24 to 28 hours of your
                                                surgery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/NGT-1.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                                <div className="rightCol col-md-5 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/NGT-2.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Blake or Jackson-Pratt (JP) Surgical Drain</p>
                                            <p>This is a device to drain and monitor the fluid around your surgery site.
                                                This can be a way to detect a leak or bleeding early. It is a thin
                                                flexible tube that is floating in your abdomen (not connected to
                                                anything inside) and exits through your abdominal wall. It is sewn at
                                                the skin. The end of the tube is connected to a grenade-shaped bulb with
                                                suction or a gravity (flat) bag. The fluid may appear bloody at first
                                                and should change to a light pink-lemonade appearance. It may be checked
                                                for lab tests prior to removal to ensure the new internal connections
                                                between the pancreas, bile duct, stomach, and intestines are healing
                                                well. Removal of the tube is not generally painful and is done at the
                                                bedside.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images3AcrossAndText">
                                        <div className="w-100">
                                            <div className="imagesAcross">

                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/JP-drain-1.jpg"
                                                     className="img-responsive"/>

                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/JP-drain-2.jpg"
                                                     className="img-responsive"/>

                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/JP-drain-3.jpg"
                                                     className="img-responsive"/>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Externalized Pancreatic Duct Stent Catheter</p>
                                            <p>Some patients have catheters which are placed within the pancreatic duct,
                                                go through the bowel, and exit through the skin. The tubing is very thin
                                                and they are connected to a gravity bag. Because they go through the
                                                bowel, they must remain in place for at least 6 weeks. They are usually
                                                capped (bag is taken off) and coiled and taped to your body at
                                                discharge.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/duct-stent.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Foley Catheter</p>
                                            <p>This is a catheter which is inserted in your bladder in the operative
                                                room while you are asleep. It connects to a bag that hangs on the side
                                                of your bed. In most cases this catheter is removed on post-operative
                                                Day 1.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/foley-catheder.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">LINES</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Central Venous Line</p>
                                            <p>A long, thin, flexible tube used to give medicines, fluids, nutrients, or
                                                blood products. The catheter is often inserted in the neck or chest
                                                through the skin into a large vein. The catheter is threaded through
                                                this vein until it reaches a large vein near the heart. These are
                                                usually placed in the OR and are temporary (less than 10 days). Certain
                                                medications may only be given through a central line such as medications
                                                that affect heart rate or rhythm, blood pressure, or nutrition
                                                (TPN).</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/central-venous-line.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Peripherally Inserted Central Catheter (PICC)</p>
                                            <p>A catheter inserted into a peripheral vein in the arm then advanced
                                                towards the heart through larger veins. These may be placed in the
                                                operating room or at bedside under sterile conditions by certified
                                                personnel. A PICC may remain in longer than a central line and similar
                                                medications or nutrients may be infused through it.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PICC-1.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                                <div className="rightCol col-md-5 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/PICC-2.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Peripheral Venous Line (IV)</p>
                                            <p className="w-100">A small, flexible tube placed in a vein in your hand, arm, or neck. These
                                                are changed every 4 days.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/periphrial-venous-line.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Arterial Line</p>
                                            <p>A small flexible tube inserted into an artery in your wrist typically in
                                                the OR. These are used to monitor the blood pressure real-time, and to
                                                obtain samples. These lines only remain in place while in the SICU or
                                                PCU. They are removed prior to transferring to the floor.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/arterial-line.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">OTHER DEVICES</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Telemetry</p>
                                            <p>This is a monitoring device that transmits information about your heart
                                                rate, rhythm, blood pressure, and oxygen levels to a screen located in a
                                                monitoring center as well as the nursing unit. There are 5 sticky pads
                                                attached to your chest and upper abdomen. These are connected to wires
                                                and a box that is worn in your hospital gown pocket. In the SICU and
                                                PCU, these wires are connected directly to a monitor in your room.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images2AcrossAndText paddingOnSides">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-md-5 col-md-offset-1 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/telemetry-2.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                                <div className="rightCol col-md-5 paddingLeft7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/telemtry-1.jpg"
                                                         className="img-responsive"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Sequential Compression Devices (SCD’s)</p>
                                            <p>These are inflatable jackets worn around the calves and intermittently
                                                squeeze the legs to help prevent blood clots. It is important that these
                                                are worn at all times when you are lying in bed or sitting. They are
                                                removed when you walk around</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/SCD.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic7;
