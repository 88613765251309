import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonFootCareWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonFootCare">Foot Care</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonFootCare/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonFootCare/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonFootCare/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonFootCare/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Look at the Feet</p>
                                        <p>When you care for a person at home, especially an older adult, a diabetic, or a person who has poor circulation to the legs – look at their feet daily. Have the person look at their feet the same time you do. Use of a mirror helps. Look at the top, bottom, sides, and between the toes of each foot. Look for:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>New areas of redness</li>
                                            <li>Swelling</li>
                                            <li>Cuts</li>
                                            <li>Scratches</li>
                                            <li>Blisters</li>
                                            <li>Calluses, corns, or soft areas of the skin</li>
                                            <li>Discolored areas anywhere on the foot, or around the nails</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Take your hands to feel the surface of the feet. If the skin is dry, use a good skin lotion to protect the feet from cracking and drying. Rub the lotion in well and make sure the feet feel dry after applying the lotion. Excess lotion left on the skin or between the toes often leaves moisture, which can cause an infection.</p>
                                        <p>Also, look closely at the heels, which can dry out and crack. Soft areas on the heels are common in people who are in bed a lot of the time. The areas form from friction between the heels and bed sheets and pressure of keeping the heels in one position. These soft areas need to be watched closely, because they can be a sign of skin breakdown and pressure ulcer formation.</p>
                                        <p className="boldLeadIn">Watch How a Person Walks</p>
                                        <p>When caring for another person, watch how he or she walks. Have the person walk a straight line or down a hallway. If the person limps or has an unnatural step, it may mean he or she has a foot problem.</p>
                                        <p className="boldLeadIn">Care of Cuts</p>
                                        <p>If the person has a minor cut on the foot or toes, wash it thoroughly with soap and warm water. After drying, apply a mild over-the-counter antiseptic (e.g. Neosporin ointment) to the cut. Call the doctor for serious cuts or lacerations or if the minor cut does not seem to be healing or shows signs of infection (e.g. redness, pain, drainage).</p>
                                        <p className="boldLeadIn">Ingrown or Thick Nails</p>
                                        <p>If the person you care for has an ingrown toenail or thick, heavy nails that are hard to file down, make an appointment with a podiatrist.</p>
                                        <p className="boldLeadIn">Corns and Calluses</p>
                                        <p>A corn is a raised, cone shaped area that forms on the foot as a result of friction and pressure from shoes. A corn is tender and can be painful when a person walks. A callus is a thickened portion of skin also caused by friction and pressure. Discomfort is common from tight-fitting shoes placing pressure on a callus. Do not use caustic over-the-counter chemicals on the feet to remove corns or calluses. Make an appointment with a podiatrist.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Callus_on_bottom.png" className="img-responsive maxWidth300 floatNone" />
                                        <p className="captionItalic text-center w-100">Callus on bottom of foot</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Select the Right Shoes</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Always buy shoes near the end of the day when the feet are normally swollen from standing most of the day.</li>
                                            <li>Have the person buy shoes with non-skid soles that are flexible. A good shoe is sturdy, closed-toed, and does not restrict movement of the feet.</li>
                                            <li>If the person has a bony deformity like a bunion, extra wide or extra deep shoes with cushioned insoles are best.</li>
                                            <li>Do not wear new shoes for an extended time. Wear them for short periods over several days so as to break them in.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="safetytips">SAFETY TIPS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Here are some tips for good foot health:
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Wear shoes that are long enough, wide enough and deep enough to cover the feet without rubbing, causing pressure, or cutting off circulation.</li>
                                            <li>Always wear clean, dry socks or stockings with shoes. Cotton socks provide warmth and absorb moisture or sweat.</li>
                                            <li>Wear socks free of holes or darns.</li>
                                            <li>Always check the inside of a shoe before putting it on a person. Something might have fallen (or crawled) inside the shoe, or the lining may be torn, which can cause injury.</li>
                                            <li>Keep the feet warm and dry.</li>
                                            <li>Do not wear elastic stockings, knee-high hose or constricting garters.</li>
                                            <li>Wear loose-fitting socks if the feet become cold.</li>
                                            <li>Never use a heating pad or hot water bottle on the feet as this can cause burns.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Here are some ways to help reduce a person’s risk of falling:
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Have the person exercise regularly. Tai Chi is a good exercise for older adults to increase strength and improve balance.</li>
                                            <li>Have their doctor or nurse review their medicines to reduce any drug side effects and interactions</li>
                                            <li>Have their eyes checked by an eye doctor each year</li>
                                            <li>Improve the lighting in the home</li>
                                            <li>Have a person regularly use assist devices (as ordered by their doctor), such as a cane or walker</li>
                                            <li>Be sure the person uses handrails when walking on stairs </li>
                                            <li>Have the person get up slowly from a lying or a sitting position</li>
                                            <li>Have the person wear shoes inside and outside the house</li>
                                            <li>Have the person avoid wearing slippers or going barefoot</li>
                                            <li>And follow our <a href="/LessonMoving/WhatToDoAndSafety#safetytips" target="_blank">Safety Tips</a> for Making Rooms in the Home Safe</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="showMeHow">SHOW ME HOW</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>When giving foot care, the main thing to think about is to not injure the skin.</p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="w-100">
                                    <h3 className="blueColor videoTitle width100 text-center">Video: Care Of The Feet
                                    </h3>
                                    <div className="floatLeft width100 captionBelow">
                                        <div className="embedContainer">
                                            <YouTube videoId="A2nWHUTjpaA" opts={opts} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="captionItalic marginBottom0">related content:</p>
                                    <p className="captionBold">Step-by-Step instructions</p>
                                    <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="equipment">EQUIPMENT</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Wash basin</li>
                                            <li>Washcloth</li>
                                            <li>2 towels</li>
                                            <li>A bathmat or extra towel</li>
                                            <li>Soft cuticle brush or nail brush</li>
                                            <li>Nail file</li>
                                            <li>Nail clipper (optional)</li>
                                            <li>Orange wood stick</li>
                                            <li>Skin lotion</li>
                                            <li>Dry unscented foot powder or anti-fungal foot powder (optional)</li>
                                            <li>A pair of clean disposable gloves if the person has any open sores on their feet</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonFootCareWhatToDoAndSafety;