import {ui_config} from "../../config.js";

export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;
export const ELEVEN = 11;
export const TWELVE = 12;
export const THIRTEEN = 13;
export const FOURTEEN = 14;
export const FIFTEEN = 15;
export const SIXTEEN = 16;
export const SEVENTEEN = 17;
export const EIGHTEEN = 18;
export const NINETEEN = 19;
export const TWENTY = 20;
export const TWENTY_ONE = 21;
export const TWENTY_TWO = 22;
export const TWENTY_THREE = 23;
export const TWENTY_FOUR = 24;
export const TWENTY_FIVE = 25;
export const CLUSTER_URL = ui_config.url;
export const TILE_PAGE_URL = ui_config.url;
