import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonSuppositoriesWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonSuppositories">Rectal Suppositories</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Purpose of Suppositories</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Rectal suppositories are given to persons who cannot take medicines by mouth. Suppositories are commonly given to treat:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Fever</li>
                                            <li>Nausea</li>
                                            <li>Pain</li>
                                            <li>Constipation</li>
                                            <li>Inflammation or infection of the rectum</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who Should Not Be Given a Suppository?</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Persons with newly developed stomach pain, when the cause is unknown.</li>
                                            <li>Persons who have had recent surgery on the rectum, bowel, or prostate gland.</li>
                                            <li>Persons who have rectal bleeding or a prolapse (dropping) of rectal tissue from the rectal opening.</li>
                                            <li>Persons who have very low platelet counts (e.g. cancer patients) who might bleed easily if you place something in the rectum.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Rectal Bleeding</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Insertion of a suppository normally should not cause rectal bleeding. However, a small amount of bleeding from a hemorrhoid might occur when you give a suppository. If you notice blood on your gloved fingers after giving a suppository, look for the source.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>If the blood is from a hemorrhoid, the bleeding should stop in a short time. Remember with internal hemorrhoids, you will not see the bleeding but it will appear on the glove tip.</li>
                                            <li>If the person is taking a blood thinner (anti-coagulant) a small amount of bleeding is not uncommon.</li>
                                            <li>Bleeding that <span className="bold">does not stop within 2 to 3 minutes</span> is not normal</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="bold">If you are not sure of the source of the bleeding and if it has occurred more than once, notify the person’s doctor.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What is a Hemorrhoid?</h2>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/RS_Hemerrhoid.png" className="img-responsive floatNone maxWidth300" />
                                            <p className="captionItalic text-center w-100">External Hemorrhoid</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A hemorrhoid forms either within the anal canal (called internal) or through the opening of the anus (called external) as a result of pressure within the blood vessels in the canal.</p>
                                        <p>Normally when a person has a bowel movement, the blood vessels in the anus fill with blood. If you strain to have a bowel movement, pressure causes the blood vessels to stretch. Repeated straining, when having a bowel movement, is a common cause of hemorrhoids. For example, a person who becomes constipated often is at risk for forming hemorrhoids.</p>
                                        <p>Hemorrhoids also form from increased pressure in the veins in women near the later stages of pregnancy and in people who are overweight.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder orangeBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Symptoms of a Hemorrhoid:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Bleeding with a bowel movement. After wiping, toilet paper may be spotted bright red with blood.</li>
                                                    <li>Itching or burning</li>
                                                    <li>Pain when rubbed or after wiping with toilet paper.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Many medicines are not safe to take after they have been split. Some are coated to be long-acting or to protect the stomach. Always talk with the person’s doctor or pharmacist before splitting medicines. If the doctor decides a pill can be safely split, have the doctor write the prescription to reflect that, "Take 1/2 tab daily." Also ask the pharmacist to split the pills for you. They have the tools to correctly split medicines.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonSuppositoriesWhatYouNeedToKnow;