import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {resetEnhancer, rootReducer} from './reducers';

const persistConfig = {
    key: 'root',
    storage: storage,
}

export default function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);
    const persistedReducer = persistReducer(persistConfig, resetEnhancer(rootReducer));

    const store = createStore(persistedReducer, preloadedState, composedEnhancers);
    const persistor = persistStore(store);

    return { store, persistor }
}
