import React from "react";

function L88BComponentCode() {
  return (
    <div className="lego l088B legoMargin5">
      <div className="contentContainer">
        <p>
          <span className="highlightedText">Results often include:</span>
          <br />
          less pain
          <br />
          improved strength and stamina <br />
          weight loss,
          <br />
          controlled diabetes and/or lower blood pressure. 
        </p>
        {/* <p style={{ fontWeight: 400 }}>90-120 days. </p>*/}
      </div>
    </div>
  );
}

export default L88BComponentCode;
