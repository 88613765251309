import React from 'react';
import Container from 'react-bootstrap/Container';
import YouTube from 'react-youtube';
import '@fortawesome/fontawesome-svg-core/styles.css'

const PancreaticVideoIncentiveSpirometry = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">WHIPPLE Procedure</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                Video: Incentive Spirometry
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="lLj4Mm_37cs" opts={opts}  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )

};

export default PancreaticVideoIncentiveSpirometry;
