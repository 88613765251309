import React from "react";

function L85ComponentCode() {

    return (

        <div className="lego l085">
            <div className="headerColorBar">
                <h3>Consult your physician:</h3>
            </div>
            <div className="contentContainer">
                <p>If you are taking any of the following medications, consult with the
                    prescribing physician for directions on stopping prior to your
                    injection:</p>
                <ul>
                    <li>Aggregnox (Aspirin and Dipyridamole)</li>
                    <li>Heparin</li>
                    <li>Lovenox (Enoxaparin)</li>
                    <li>Fragmin (Dalteparin)</li>
                </ul>
                <p><span className="bold">Do NOT stop any other medication</span> (blood pressure meds, diabetes meds,
                    etc.) not listed above.</p>
            </div>
        </div>

    )
};


export default L85ComponentCode;