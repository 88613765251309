import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonAfterSurgeryFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonAfterSurgery">Care After Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonAfterSurgery/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonAfterSurgery/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Can the person I care for take a bath or shower after surgery?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>This will depend on the type of surgery and the surgeon’s orders. If the person is allowed to bathe, do not soak the wound until after stitches are removed. Do not allow a gauze dressing to become wet – talk with your doctor about how to protect the wound (such as cover with saran wrap).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">My husband says he feels really good and wants to try to drive.
                                            <br />The doctor’s nurse said he should not drive for 4 weeks.
                                            <br />Is it okay for him to go ahead and drive?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>No. Follow the surgeon’s orders. If your husband is recovering well, call the surgeon and talk with him about this.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">My wife has a pain medicine prescribed by the surgeon.
                                            <br />In between doses she seems a bit uncomfortable still.
                                            <br />Can I give her some acetaminophen?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>No, your wife’s pain medicines may not be offering good pain relief. Be sure you are giving the medicines as directed. If pain persists after giving a dose of pain medicine, call the surgeon. Also, some pain medicines contain acetaminophen, do not give extra doses unless ordered by the surgeon.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">My father has to wear elastic stockings.
                                            <br />How often can I help him take them off?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Generally speaking, elastic stockings are worn through-out the day and removed at bedtime.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/American-College-of-surgeons-logo.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American College of Surgeons (ACS)</h2>
                                                <p>This professional society offers information on a wide variety of surgeries and medical conditions. The web site has a patient education section, describing a wide variety of surgeries and what to expect.</p>
                                                <p className="resourceLink">visit: <a href="http://www.facs.org/patienteducation/" target="_blank">www.facs.org/patienteducation/</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/OSUWexMedCtr.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">The Ohio State University Medical Center Patient Education Materials</h2>
                                                <p>The Ohio State University Medical Center is one of the largest and most diverse academic medical centers in the country. The web site provides a library of health information on a variety of surgeries and home care procedures such as wound care.</p>
                                                <p className="resourceLink">visit: <a href="https://medicalcenter.osu.edu/patientcare/patient_education" target="_blank">http://medicalcenter.osu.edu/patientcare/patient_education/</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo-mayoclinic.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">The Ohio State University Medical Center Patient Education Materials</h2>
                                                <p>The website is owned by the Mayo Foundation for Medical Education and Research. The Mayo Clinic’s mission is to empower people to manage their health. The website offers useful and up-to-date information and tools on various types of surgery and after surgery care.</p>
                                                <p className="resourceLink">visit: <a href="https://www.mayoclinic.org/" target="_blank">www.mayoclinic.com</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer greenBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonAfterSurgeryFAQResources;