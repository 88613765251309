import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonObservingWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonObserving">Observing General Health</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Need to Know</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>To become a good observer, you need to look for many of the same things doctors and nurses look for in patients. Begin by knowing what is normal. As you become comfortable as an observer, you will begin to notice more about the person you care for. <span className="bold">Make it a habit to observe the person you care for each day as they take part in their daily routines</span>.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBar text-center">The What to Observe</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Physical Appearance</p>
                                        <p>When you first look at someone, notice how alert and attentive they are and how they respond to your questions.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Look at their skin. Is the color tone usual for them over the arms and legs? Is the skin smooth and even? When you touch their skin, is it warm and dry?</li>
                                            <li>Are there any skin lesions - obvious cuts, sores, bruises, bumps, rashes, moles, or discolored areas you have not seen before?</li>
                                            <li>When the person talks with you, are their facial features equal on both sides or do you notice any drooping of the eyelids, mouth, or muscles of the face? Does the person drool while eating?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Your first thoughts are important. Do you notice any signs of the person being in distress? For example, does the person breathe normally with little movement of their chest or does he or she gasp for air?</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Weight</p>
                                        <p>A person’s weight can change suddenly or slowly over time. For example, a change in appetite due to depression, pain or disease such as cancer, is a common cause of weight loss. A gain in weight often occurs when a person retains fluids due to heart failure.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Have a person weigh each day; the same time each day.</li>
                                            <li>It is best if the person weighs just before taking a bath or shower. If not, have the person wear the same amount or type of clothes, each time he or she weighs.</li>
                                            <li>Be sure the scale is balanced and giving you an accurate reading.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Posture and Position</p>
                                        <p>The way a person holds their body tells you a lot about their health.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Look at the person’s posture. Normally a person stands with their head erect and their back is comfortably straight. An exception is older adults who commonly have a stooped curve of the spine called Kyphosis. Posture often reflects a person’s energy level, mood or if they are in pain.</li>
                                            <li>Look at the person’s body position while he or she sits in a chair. Normally a person sits comfortably in a chair with arms relaxed at their sides and their shoulders usually a bit rounded.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 images3AcrossAndText">
                                    <div className="w-100">
                                        <div className="imageContainer d-flex">
                                            <div className="leftCol col-4 paddingLeft7 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Quadricepts-setting.jpg" className="img-responsive" />
                                                <div className="imgCaption">
                                                    <p className="captionBold">Quadricepts (thigh) setting
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="centerCol col-4 paddingLeft7 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Hip-and-knee-movements.jpg" className="img-responsive" />
                                                <div className="imgCaption">
                                                    <p className="captionBold">Hip and knee movements</p>
                                                </div>
                                            </div>
                                            <div className="rightCol col-4 paddingLeft7 paddingRight7">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Foot-circles.jpg" className="img-responsive" />
                                                <div className="imgCaption">
                                                    <p className="captionBold">Foot circles</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Mobility</p>
                                        <p>The way a person moves about will tell you if there are problems with the bones, muscles or nerves in the body.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare">
                                            <li>Watch the gait or how a person walks. Normally the feet are spaced as wide as the shoulders. A person places their feet down heel to toe and straight, the arms swing equally back and forth, and their gait is even and well balanced. Also, notice if the person begins holding onto the furniture or the walls for support while they walk.</li>
                                            <li>When a person moves, the joints in their body should be able to go through a full range of motion. This means for example, when you move your lower arm, you can bend and flex fully at the elbow and you can rotate the arm back and forth. Every muscle and joint has a normal range of motion.</li>
                                            <li>Observe a person as he or she grasps a medicine container, washes the dishes, eats food, puts on clothes or sweeps the floor.</li>
                                            <li>Can the person move joints fully and freely, without pain?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Behavior</p>
                                        <p>A person’s behavior is always changing, but you should be a good judge of what is normal for the person you care for. A change in behavior can be an early sign of a problem. <span className="bold">Remember, all behavior has meaning</span>.</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/behavior-faces.jpg" className="img-responsive maxWidth300" />
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Observe facial expressions for clues as to how a person is feeling. For example, if a person is tired, you may notice that they sigh a lot, have a listless expression and often are looking down. You may also notice an anxious person looks around a lot with their eyes wide open. Or, if they are sad, the person looks down, has poor eye contact, and shows a blank facial expression.</li>
                                            <li>Notice the person’s mood. Is the person you care for usually happy, cooperative and interacting pleasantly? Or is the person usually down, irritable and unwilling to talk? Each person has his or her own normal mood. What you look for is a change, which may be very gradual.</li>
                                            <li>Without warning, is the person hostile towards you, distrustful, suspicious or crying?</li>
                                            <li>Has the person lost interest in favorite activities?</li>
                                            <li>Does the person only answer questions without starting or continuing conversations?</li>
                                            <li>A person’s speech can quickly let you know if there is a problem. Normally a person forms words clearly and you can understand what they are saying. The stream of talking back and forth is evenly paced. A person is able to share ideas clearly.</li>
                                            <li>Notice how a person reacts to you when talking. Hearing loss is something that often develops gradually. A person with a hearing loss might have a blank expression when asked a question, or simply smile and nod their head even though they do not hear what you say. The person might not react to loud noises or he or she might raise their voice when speaking.</li>
                                            <li>How a person dresses can tell you how well they take care of themselves, and not just what style of clothes they choose to wear or can afford to buy. Examples of signs of progressive dementia include: A person stops bathing routinely, hair is unkempt, clothes are not clean or laundered, and they wear wrong clothes for the weather outside.</li>
                                            <li>A person’s hygiene, such as the frequency of bathing, how they groom their hair or shave, or how a woman uses makeup, should match their age, job and social lifestyle. If a person who used to carefully groom himself or herself now has a gradual unkempt appearance or absent makeup, this may be a sign something is wrong.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Mental Status</p>
                                        <p>During the daily activities you spend with a person, you will talk together. Pay attention to what the person says. It will tell you about their mental status, their ability to think clearly, remember and make decisions.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>Is the person oriented? Meaning, does he or she usually know the time of day, day of week and where they are?</li>
                                            <li>Check a person’s ability to concentrate and pay attention. Do they complete a thought, wander back and forth with ideas or doze more often? Are they easily distracted by something? A poor attention span is common in someone who is anxious, very tired or who is starting to become ill.</li>
                                            <li>Is the person you care for able to remember events that have happened in the last few days? For example, a visit with a grandchild, a story on the evening news, the rain storm from last night? Recent memory loss is common in persons with dementia.</li>
                                            <li>Be alert when a person changes the way he or she makes decisions or problem solves. You might notice that the person has poor judgment when dealing with money or what to buy for groceries. The person may not remember how to do familiar tasks.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonObservingWhatYouNeedToKnow;