import {getRequest, postRequest} from "../../src/api/index.js";
import {
    FORGOT_PASSWORD_ERROR,
    HOME,
    RESET_PASSWORD_ASSIGN_OTP,
    USER_PROFILE,
    USER_PROFILE_ERROR
} from "../../src/actions/types.js";

export const userProfile = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await getRequest(`/user/`, "user");
        dispatch({
            type: USER_PROFILE,
            payload: res.data
        })

        cb(res.data);
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                dispatch({
                    type: USER_PROFILE_ERROR,
                    payload: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                dispatch({
                    type: USER_PROFILE_ERROR,
                    payload: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.reqeust
            dispatch({
                type: USER_PROFILE_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: USER_PROFILE_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: USER_PROFILE_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};
