import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {useDispatch} from "react-redux";
import {useEffectForVideoAccess, videoAccessLog} from "../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin2_2 = () => {

    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Pritikin 2-2 video'}, () => {
            console.log('Pritikin 2-2 video');
        });
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorLightRed pritikinBorderLightRed">Personalized
                                                Exercise Plan</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Regular exercise following a heart event is a crucial aspect of the
                                                recovery process. Think of your exercise program as medicine to help you
                                                heal and become stronger.</p>
                                            <p>Your cardiac rehab team will work with you to develop a
                                                medically-appropriate, individualized exercise plan. Then, you’ll get started together and track your progress throughout each
                                                session.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn newSection">Your customized rehab plan will include:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 images3AcrossAndText circleImagesControlImageSize">
                                        <div className="w-100">
                                            <div className="imageContainer">
                                                <div className="leftCol col-4 paddingLeft7 paddingRight7">
                                                    <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cardio_icon.svg"
                                                         className="img-responsive" alt="Cardio Icon"/>
                                                    <div className="imgCaption text-center w-100 p-0">
                                                        <p className="text-center w-100"><strong>Cardiovascular
                                                            Conditioning</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="centerCol col-4 paddingLeft7 paddingRight7">
                                                    <img
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/resistence_icon.svg"
                                                        className="img-responsive" alt="Resistance Icon"/>
                                                    <div className="imgCaption text-center w-100 p-0">
                                                        <p className="text-center w-100"><strong>Resistance
                                                            Training</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="rightCol col-4 paddingLeft7 paddingRight7">
                                                    <img
                                                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/flexibility_icon.svg"
                                                        className="img-responsive" alt="Flexibility Icon"/>
                                                    <div className="imgCaption text-center w-100 p-0">
                                                        <p className="text-center w-100"><strong>Flexibility
                                                            Exercise</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">There with you every step of the way!</p>
                                            <p>As you exercise in the safe and controlled environment of your cardiac
                                                rehab, you will be monitored by a care team who can serve as the eyes
                                                and ears of your physician.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Making exercise a life-long health benefit</p>
                                            <p>When you’re ready to graduate from cardiac rehab, your cardiac rehab team
                                                will work with you to create a long-term, sustainable home exercise plan
                                                to continue what you learned and build upon your skills.</p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower text-center w-100 text-dark spacer36px">How Exercise Made a
                                                Difference for Me</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“The progress from the time I started until now is unbelievable. I had a heart attack and when I came to the hospital, I was weak. I couldn’t walk very far without running out of air. I was in bad shape. And I started the rehab program here. I got along good and worked out fine. And today, I’m getting stronger every day.”</p>
                                            <p className="name">— Randy F, Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“My advice is to just jump right in and go for it right now. This is the best program that I have ever found. I’m so grateful we have it because I don’t know where I would be without it.”</p>
                                            <p className="name">— Jane F., Cardiac Rehab Patient</p>
                                        </div>
                                        <div className="w-100 ruleWidth66">
                                            <hr className="quoteRule" />
                                        </div>
                                    </div>
                                    <div className="col-12 quotesNewLego">
                                        <div className="w-100">
                                            <p className="quote">“Day one, I had no arm usage. I was on restrictions. By the end, I was on the treadmill, on an incline, breaking a sweat. It’s all in your mindset on what do you want for your life.”</p>
                                            <p className="name">— Anna M., Cardiac Rehab Patient</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin2_2;