import React, {useEffect, useState, useContext} from 'react';
import {Route, Navigate, Routes, useLocation} from 'react-router-dom';
import { useNavigate, Outlet } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getExpiration, getLoginScreenStatus, getUser} from '../../../helpers/index.js';
import TopNav from "../topNav/index.js";
import PageTracker from "../../pageTracker/index.js";
import FavIconLoader from "../../favIconLoader/index.js";
import { AppContext } from '../../../context/AppContext.js';
import jwt_decode from "jwt-decode";
import UnauthorizedError from "../Errors/UnauthorizedError";
import Breadcrumb from "../../breadcrumb/index.js";
import {externalPathToFilter} from "../../../context/externalPathToFilter.js";
import {AUTH_ERROR} from "../../../actions/types";
//const logoutFunc = logout(true)(navigate);

const PrivateRoute = ({ children }) => {
    const [errorMesssage, setErrorMessage] = useState(null);
    const user = JSON.parse(getUser());
    const token = localStorage.getItem('token');
    const tokenExpired = token ? (jwt_decode(token).exp * 1000) < Date.now() ? !token : null : false;
    const url = "/register/tempuser/posttrial" + (user?.isTemporaryOrbitAccessUser ? "/" + user?.isTemporaryOrbitAccessUser : "") + (user?.accessToken ? "/" + user?.accessToken : "") +  "" + (user?.counterToken ? "/" + user?.counterToken : "") + "" + (user?.hideRenew ? "/" + user?.hideRenew : "/false");
    const loginScreen = getLoginScreenStatus();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const {logout} = React.useContext(AppContext);
    const logoutFunc = logout(true)(navigate);

    useEffect(() => {
        setCurrentLocation(location.pathname);
    }, [location])

    useEffect(async () => {
        if (!externalPathToFilter.includes(currentLocation.replace('/',''))) {
            if (!(user && token && !tokenExpired) && !(user && user?.isTemporaryOrbitAccessUser)) {
                await dispatch(logoutFunc);
            }
        }
    })

    return (
        (user && token && !tokenExpired) ?
            //<TopNav key={"topNav"}><PageTracker><FavIconLoader><Outlet/></FavIconLoader></PageTracker></TopNav> :
            [<TopNav key={"topNav"}/>, <PageTracker><Breadcrumb/><FavIconLoader><Outlet/></FavIconLoader></PageTracker>] :
            (user && user?.isTemporaryOrbitAccessUser) ? <Navigate to={url} /> :
            //(user && user?.isTemporaryOrbitAccessUser) ? <Navigate to={url} /> :
                loginScreen && !token && window.location.href.split(window.location.host + '/')[1].length > 0  ?
                    //<UnauthorizedError/> :
                    <Navigate to="/login" /> :
                    <Navigate to="/login" />

    )
}

export default PrivateRoute;
