import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTTGSP4_5 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center">Swallowing &amp; Aspiration</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entYellowColorBarBackground text-black">
                                        <i className="fa fa-exclamation-circle"
                                           style={{marginBottom: "20px"}}></i>&nbsp;&nbsp;If you have had
                                        surgery in your mouth/throat, then parts of your mouth, tongue, and
                                        throat can be numb. Swallowing food or drink can be dangerous!
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Following surgery in your mouth or throat, you will have changes in how
                                        you chew and swallow foods and liquids. After surgery, you may be at
                                        increased risk for accidentally having food or liquids go into your
                                        lungs (called "aspiration"), which could lead to a life-threatening
                                        pneumonia.
                                    </p>
                                    <p>Once your surgeon says that you have healed enough from surgery, your
                                        speech-language pathologist (called "SLP" for short) will assess whether
                                        or not it is safe for you to take food and drink by mouth. </p>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p className="boldLeadIn">What to expect:</p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom">
                                        <li>Some patients may be able to return to a normal diet.</li>
                                        <li>Others may have a diet that is restricted to food or drinks of only
                                            a certain consistency.
                                        </li>
                                        <li>Still some may not be able to swallow safely, even after healing
                                            completely. In this case, a patient will continue to require
                                            nutrition from a feeding tube.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Your SLP will work with you to develop an exercise program to help you
                                        improve your ability to swallow safely and comfortably.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop"
                                          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                        TOP <i className="fa fa-chevron-up"></i></a></p>
                                    <hr className="w-100 blueColorBorder"/>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP4_5;