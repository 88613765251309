import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import Accordion from "react-bootstrap/Accordion";
import {useDispatch} from "react-redux";
import {videoAccessLog} from "../../actions/videoAccessLog.js";
const SitemanENTTGSP2_3_4 = () => {

const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};

const dispatch = useDispatch();
const playVideo = async (e) => {
    const pal = await videoAccessLog({videoName: 'Removal of Jaw Bone: Jaw Reconstruction after Cancer Surgery'}, () => {
        console.log('Removal of Jaw Bone: Jaw Reconstruction after Cancer Surgery');
    });
    await dispatch(pal);
}

const dispatch1 = useDispatch();
const playVideo1 = async (e) => {
    const pal = await videoAccessLog({videoName: 'Removal of Jaw Bone: Dental Restoration'}, () => {
        console.log('Removal of Jaw Bone: Dental Restoration');
    });
    await dispatch(pal);
}

const dispatch2 = useDispatch();
const playVideo2 = async (e) => {
    const pal = await videoAccessLog({videoName: 'Removal of Jaw Bone: Tracheostomy Procedure'}, () => {
        console.log('Removal of Jaw Bone: Tracheostomy Procedure');
    });
    await dispatch(pal);
}

return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader text-center entBaseRedColor">Removal of Jaw Bone</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer bottomSpacer">
                                    <h3 id="cancerRemoval" className="subheadUpperAndLowerColorBarIcon darkOrangeBackground centered">REMOVAL OF JAW BONE</h3>
                                </div>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer boxes">
                                        <div className="typeContainer text-center entNavBaseYellowBackground">
                                            <a href="#cancerRemoval">
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft smaller">ABOUT
                                                        YOUR</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft">CANCER
                                                        REMOVAL</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft clickHere">continue
                                                        scrolling</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="typeContainer text-center entNavBaseBlueBackground">
                                            <a href="#dayOfSurgery">
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft smaller">GO
                                                        TO</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft">DAY OF
                                                        SURGERY</p>
                                                </div>
                                                <div className="w-100">
                                                    <p className="text-center width100 floatLeft clickHere">click
                                                        here ></p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Jaw Reconstruction after Cancer Surgery</h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="IilR4sOukVw" opts={opts} onPlay={playVideo} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p>Within the mouth is the <span className="bold">upper jaw (maxilla)</span> and the <span className="bold">lower jaw (mandible)</span>.
                                        Your jaws provide shape and support to your face and keep your teeth
                                        secure. </p>
                                    <p>When cancers invade into the bone of the jaws, a portion of the jaw needs
                                        to be removed.</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Dental Restoration</h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="h6UGz0OoBZk" opts={opts} onPlay={playVideo1} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower">MANDIBULECTOMY: Removal of a portion of the lower jaw</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-md-12">
                                                    <div className="w-100 flexContainer justifyCenter column">
                                                        <div className="imageContainer  flexContainer justifyCenter">
                                                            <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Segmental+mandibulectomy.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 w-100 floatLeft clearLeft">
                                                    <div className="w-100">
                                                        <p> When tumors invade into the lower jaw bone (mandible) it is necessary to remove a portion of the bone. This is called a mandibulectomy. Based on the size of your tumor, your surgeon may also need to remove other surrounding structures as well such as the inner cheek or tongue (<em><strong>see</strong></em> <a href="/siteman-ent-tgsp-2-3-3">Oral Cavity, Tongue, & Throat Cancer</a> <em>for more information about removal of other non-bone areas of the mouth and throat)</em>. Removal of just the lower jaw usually does not significantly impact speaking and swallowing long term, however it is important for the bone to be replaced. Your surgeon may recommend that your lower jaw be reconstructed with bone from another part of your body.</p>
                                                        <p>This reconstructive procedure is called a <span className="bold">free flap</span> and is usually performed at the same time as the cancer removal. Bone can be taken from your leg (<em>fibula</em>), forearm (<em>radius</em>), or shoulder blade (<em>scapula</em>) (<em><strong>see</strong></em> <a href="siteman-ent-all-2-4">Your Reconstruction</a>). </p>
                                                        <p>After this type of surgery, most patients are unable to use dentures to replace any teeth that are removed. Many patients function quite well on a soft diet afterwards or use remaining teeth to chew food. In some patients, placing dental implants into the new bone allows for dental restoration following this type of surgery. Not every patient can receive dental implants. You can ask your surgeon if you are a candidate for dental implants. </p>
                                                    </div>
                                                </div>
                                                {/*<div className="col-12">*/}
                                                    {/*<div className="w-100">*/}
                                                        {/*<hr className="w-100" />*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h3 className="subheadUpperAndLower">MAXILLECTOMY: Removal of a portion of the upper jaw</h3>
                                </div>
                            </div>
                            <div className="col-12 questionAndAnswer">
                                <div className="w-100">
                                    <Accordion className="moreLess">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>MORE &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                            <Accordion.Body>
                                                <div className="col-md-12">
                                                    <div className="w-100 flexContainer justifyCenter column">
                                                        <div className="imageContainer  flexContainer justifyCenter">
                                                            <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/96-sq-maxillectomy.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 w-100 floatLeft clearLeft">
                                                    <div className="w-100">
                                                        <p className="floatNone clearNone col-6 col-12 noPaddingLeft">The maxilla is the roof of your mouth and separates your mouth from your nose and sinuses. When tumors invade into the upper jaw bone (maxilla) it is necessary to remove a portion of the bone. This is called a maxillectomy. Based on the size of your tumor, your surgeon may also need to remove other surrounding structures as well such as the inner cheek or tongue (<em><strong>see</strong></em> <a href="/siteman-ent-tgsp-2-3-3">Oral Cavity, Tongue, & Throat Cancer</a><em> for more information about removal of other non-bone areas of the mouth and throat</em>). Removal of the upper jaw results in a communication or hole between your mouth and nose that needs to be closed.</p>
                                                        <p><span className="bold">There are two ways to close the hole following a maxillectomy: <em>obturator</em></span> or <span className="bold"><em>free flap</em>.</span></p>
                                                        <p>An <span className="bold">obturator</span> (see below) is a specialized denture that you can take in and out of your mouth. This closes the hole between the nose and mouth as well as provides upper teeth. </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="w-100 flexContainer justifyCenter column">
                                                        <div className="imageContainer  flexContainer justifyCenter">
                                                            <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/obturator.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 w-100 floatLeft clearLeft">
                                                    <div className="w-100">
                                                        <p>A <strong>free flap</strong> is a procedure where bone or soft tissue is brought from another part of your body to close off the hole between the mouth and nose. Bone can be taken from your leg (<em>fibula</em>), forearm (<em>radius</em>), or shoulder blade (<em>scapula</em>) (<em><strong>see</strong></em> <a href="siteman-ent-all-2-4">Your Reconstruction</a>). </p>
                                                        <p>Sometimes dental implants can be placed in the new bone to provide future teeth. Your surgeon will discuss with you the different options for your reconstruction. </p>
                                                    </div>
                                                </div>
                                                 {/*<div className="col-12">*/}
                                                    {/*<div className="w-100">*/}
                                                        {/*<hr className="w-100" />*/}
                                                    {/*</div>*/}
                                                {/*</div>*/}
                                                <div className="less">
                                                    <Accordion.Header>LESS &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">Removal of lymph nodes (neck dissection)</h3>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p>Lymph nodes (<em>sometimes referred to as glands</em>) are small bean-shaped structures that may be part of your immune system. Lymph nodes filter your blood and help you fight off infections. You have many lymph nodes in your head and neck. Sometimes cancers can spread to lymph nodes.</p>
                                    <p>Based on your type of cancer, your surgeon may recommend removal of certain lymph nodes in your neck, which is called a <strong>neck dissection</strong>. This would be performed at the same time as your cancer removal surgery.</p>
                                    <p>During a neck dissection, great care is taken to safely remove the necessary lymph nodes from around important nerves and blood vessels. Even though these lymph nodes are removed, your immune system will still work well after surgery.</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer  flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/73-neck-lymph_nodes.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="imgCaption">
                                <p className="captionItalic centered"><strong>Lymph nodes that are removed during neck dissection</strong></p>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Neck_healed+incision.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="imgCaption">
                                <p className="captionItalic centered"><strong>Example of a healed neck dissection incision after removal of lymph nodes</strong></p>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100 noTopMargin" />
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">Tracheostomy</h3>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p>At times when surgery is performed in the mouth or throat, you can have a
                                    lot of swelling that can make breathing through your mouth difficult. If
                                    this is the case, your surgeon may perform a tracheostomy to bypass the
                                    swelling in your mouth and throat. </p>
                                    <p>A tracheostomy involves making a small opening in your windpipe (trachea)
                                    and placing a tube (tracheostomy tube). In most cases, the tracheostomy
                                    is temporary and will be removed before you leave the hospital after
                                    surgery. You can ask your surgeon about your particular surgery.</p>
                                </div>
                            </div>
                            <div className="col-12 video">
                                <div className="">
                                    <h3 className="blueColor videoTitle w-100 text-center">Tracheostomy Procedure</h3>
                                    <div className="floatLeft w-100 captionBelow">
                                        <div className="embedContainer originalPadding">
                                            <YouTube videoId="RBH_T7v_GKE" opts={opts} onPlay={playVideo2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Throat_Airway_Trach.jpg" alt="" />
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 backToTop w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop">
                                            BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>

                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <h3 className="sectionHeader w-100 text-center">Feeding Tube</h3>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p>Patients who undergo surgery for cancers in their mouth or throat
                                    that involve a free flap reconstruction will often need a feeding tube
                                    after surgery.</p>
                                    <p>For most patients, this means you will have a tube that goes through your
                                    nose, down the back of your throat, and into your stomach (<em>called a
                                    nasogastric tube, or NG tube</em>).</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer  flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/nasogastric%2Btube-png.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="imgCaption">
                                <p className="captionItalic centered">Nasogastric tube (NG tube)</p>
                            </div>


                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p>A feeding tube allows us to give your body the nutrition it needs to
                                    recover from your surgery while giving your surgical site time to heal.
                                    Because many cancers of the mouth and throat affect swallowing, many
                                    patients are at increased risk for aspiration (<em>i.e., food/drink going
                                    down the wrong pipe</em>).</p>
                                    <p>You may be evaluated by one of our speech and language pathologists
                                    (SLPs) who will assess your swallow function and may recommend
                                    modifications to your diet to ensure you swallow safely.</p>
                                    <p>Drinking or eating by mouth before clearance from your surgeon can lead
                                    to wound breakdown and/or pneumonia. </p>
                                    <p>For the majority of patients, the feeding tube is temporary.
                                    Unfortunately, some patients will not be able to swallow safely for a
                                    while after surgery. For such patients we often recommend placing a
                                    feeding tube directly into the stomach which is called a <em>gastrostomy
                                    tube or a G-tube for short.</em></p>
                                    <p>For more details on feeding tubes <a href="/siteman-ent-all-4-1-2">click here</a>.</p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer  flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/G%2Btube%2Bpercutaneous%2Bdiagram.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="w-100 flexContainer justifyCenter column">
                                    <div className="imageContainer flexContainer justifyCenter">
                                        <img className="maxWidth500" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/GTube_percutaneous_inset.jpg" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="imgCaption">
                                <p className="captionItalic centered">Gastrostomy tube (G-tube)</p>
                            </div>
                            <div className="col-12 backToTop w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop">
                                            BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <h3 id="dayOfSurgery" className="sectionHeader w-100 text-center">The Day of Surgery</h3>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Check-in</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>Check in at the surgery waiting area on the main floor of
                                                Barnes-Jewish Hospital south campus. If you need directions,
                                                visit the information desk located on the main level.
                                            </li>
                                            <li>After you check-in, you will go through hospital registration.
                                                Upon completion, your care team member will take you to the
                                                pre-surgery holding area.
                                            </li>
                                            <li>The surgical receptionist will give your family member or coach
                                                a beeper to allow us to update them about your surgery.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Before
                                        Going into Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>We will review your medications and health history with you.
                                            </li>
                                            <li>We will start an IV in your arm. <strong>If you were instructed by your
                                                surgeon to keep one of your arms free of IVs, please remind the
                                                nurse.</strong>
                                            </li>
                                            <li>Your anesthesiologist will talk about the most appropriate
                                                anesthetic for you and the plan to best manage your pain after
                                                surgery.
                                            </li>
                                            <li>Your surgeon will meet with you to review the surgical plan and
                                                mark where the surgery will occur.
                                            </li>
                                            <li>You will meet with the nursing staff who will be caring for you
                                                in the operating room.
                                            </li>
                                            <li>Before going to the operating room, you will be given medication
                                                for comfort.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">During
                                        Your Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>Throughout your surgery your surgical team will monitor your
                                                breathing, heart rate and blood pressure.
                                            </li>
                                            <li>A catheter, used to drain urine from your bladder, may be placed
                                                during your surgery.
                                            </li>
                                            <li>Updates about your progress will be given to the person who
                                                holds the beeper in the surgical waiting room.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">Right
                                        After Surgery</h3>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule w-100 floatLeft clearLeft">
                                <div className="col-md-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom checkBulletsSafeCare colorAccentRedBullets">
                                            <li>Usually, you will wake up in the recovery room when your surgery
                                                is complete.
                                            </li>
                                            <li>Specially trained nurses and an anesthesiologist will monitor
                                                you. They will keep you safe and comfortable while you heal.
                                            </li>
                                            <li>Your care team of specialists will be with you during your
                                                recovery.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBarIcon entDiagnosisSurgeryRedBackground centered">
                                        <i class="fa-solid fa-comment"></i>&nbsp; Communicating After Surgery
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12 w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p>Because your mouth/throat will feel much different after your
                                        reconstructive surgery, you will need to use other ways to communicate
                                        aside from speaking. These include writing on paper pads/dry erase
                                        boards, apps on smartphones/tablets, and gestures. With time, most
                                        patients adapt to their new anatomy and are able to speak normally
                                        again.</p>
                                </div>
                            </div>
                            <div className="col-12 backToTop w-100 floatLeft clearLeft">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop entDiagnosisSurgeryRedColor">
                                            BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTTGSP2_3_4;
