import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll4_4 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center paleBlueColor paleBlueColorBorder">Managing
                                                Pain</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">During your stay at the hospital</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div>
                                                <p>We will work with you to best manage your pain. We will ask you to
                                                    rate pain on a scale of 1 to 10 <em>(see chart)</em>. Your care team
                                                    will assess how to best help you feel comfortable and tailor your
                                                    medicines to meet your needs.</p>
                                                <p>It is important to let us know when you first start feeling
                                                    uncomfortable. Telling us right away will help us keep the
                                                    uncomfortable feeling from getting worse. </p>
                                                <p><strong><em>Please contact us with the call light any time you need
                                                    help.</em></strong></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img
                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ENT_Pain_Scale.svg"
                                                className="img-responsive" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="headline">At Discharge</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div>
                                                <p className="boldLeadIn">Controlling your surgical pain at home.</p>
                                                <p>Your care team will give you a plan on how to manage your pain when
                                                    you go home. You have <strong><em>two options to get your pain
                                                        medication prescriptions</em></strong> filled:</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Go to your regular pharmacy.</li>
                                                <li>Have them filled by the Mobile Pharmacy or Barnes-Jewish Hospital.
                                                    This will take the hassle out of getting your prescriptions filled
                                                    after you leave. Discuss this option with your nurse and the
                                                    hospital’s in-house pharmacy will deliver your prescriptions to your
                                                    bedside before you leave the hospital.
                                                </li>
                                                {/*<li>Start by taking your pain medication as prescribed. As your pain lessens, take less pain medication.</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Start by taking your pain medication as prescribed. As your pain lessens,
                                                take less pain medication.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop blueColor" href="#">BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll4_4;
