import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {HashLink as Link} from 'react-router-hash-link';
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic3 = () => {
    const dispatch = useDispatch();

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Getting Ready For Your Surgery'}, () => {
            console.log('Getting Ready For Your Surgery');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Getting Ready for
                                                Surgery</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer boxes">
                                                <div className="typeContainer text-center">
                                                    <p><Link
                                                        to="/Pancreatic_2-1-1#activityBeforeSurgery">ACTIVITY</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-1#usingYourIncentiveSpriometry">BREATHING
                                                        (INCENTIVE SPIROMETER)</Link></p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link
                                                        to="/Pancreatic_2-1-1#smokingAndDrinking">SMOKING/DRINKING</Link>
                                                    </p>
                                                </div>
                                                <div className="typeContainer text-center">
                                                    <p><Link to="/Pancreatic_2-1-1#nutrition">NUTRITION</Link></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-12 typeCol noPaddingLeft">
                                                <p className="mobileMarginBottom30">Working to optimize your strength,
                                                    endurance and nutrition prior to your surgery is important for
                                                    better outcomes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100" id="video">
                                                GETTING READY FOR YOUR SURGERY
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="jj1xfwg-L54" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="activityBeforeSurgery">ACTIVITY BEFORE
                                                SURGERY</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Start with brisk walking and work up to 30 minutes of continuous
                                                    walking three times daily.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You may continue any activities or exercise you were previously
                                                    doing including lifting weights, swimming, etc.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">*/}
                                    {/*    <div className="w-100">*/}
                                    {/*        <hr className="w-100"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Sexual activity is OK.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>If your surgeon feels you would benefit from additional “Pre-Habilitation”, you may be referred to our our <a href="https://surgery.wustl.edu/patient-care/surgery-prehabilitation-program/" target="_blank">Surgical Prehabilitation and Readiness (SPAR)</a> program</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadAllCaps" id="usingYourIncentiveSpriometry">USING
                                                    YOUR INCENTIVE SPIROMETER</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom w-100">
                                                    <li>You will be given an incentive spirometer both in clinic and in
                                                        the hospital. Using it is important both before and after
                                                        surgery to keep your lungs expanded and preventing lung collapse
                                                        and pneumonia.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100"/>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom w-100">
                                                    <li><Link to="/Pancreatic_Video-Incentive-Spirometry">Watch
                                                        this video</Link> to familiarize yourself with your incentive
                                                        spirometer.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100"/>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom w-100">
                                                    <li>You should have a goal (e.g. 2000ml) when using the device, but
                                                        it is even more important to be able to hold your breath in at a
                                                        consistent level for 3 to 5 seconds.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr className="w-100"/>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom w-100">
                                                    <li>Before surgery, <strong>you should use the spirometer at least
                                                        10 times daily.</strong> Get familiar with this activity; after
                                                        surgery you will be using the spirometer <strong>10 times per
                                                            hour,</strong> to achieve needed progress.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 imagesAcross">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/spirometer_screengrab.png"
                                                 className="img-responsive"/>
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/I001_eq_a.jpg"
                                                 className="img-responsive"/>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="smokingAndDrinking">LIFESTYLE
                                                MODIFICATION</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>If you smoke, stop. Smoking significantly affects wound healing,
                                                    bleeding risk, ability to come off of the ventilator, and incidence
                                                    of postoperative pneumonia.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>It is best to stop smoking at least 4 weeks before surgery, but if
                                                    your surgery is scheduled within this time period it is best to stop
                                                    immediately.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>It is best to avoid alcoholic beverages before surgery. Though, if
                                                    you prefer, a glass of wine or beer once daily is OK.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps" id="nutrition">NUTRITION BEFORE SURGERY</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Because most patients struggle with eating and losing weight after
                                                    their surgery, it is important to optimize nutrition before surgery.
                                                    Work to adapt healthy eating habits now to improve your recovery
                                                    process.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-10 d-flex justify-content-end twocolumnTable">
                                        <div className="w-100">
                                            <table className="ml-auto table-responsive">
                                                <tbody>
                                                <tr>
                                                    <td className="leftCol cyanTableBackgroundColor">
                                                        <p><strong>Eat at least 60 grams or 6 oz of protein
                                                            daily.</strong><br/><em>
                                                            Protein needs are elevated after surgery, radiation, and
                                                            chemotherapy.</em></p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p><strong>Good sources:</strong> Eggs, egg whites, skinless
                                                            poultry, fish, shellfish, lean beef, pork (round or loin
                                                            cuts), soy products (such as tofu, soy milk, edamame),
                                                            beans, peas, lentils, nuts, seeds, nut-butters, nut-milks,
                                                            protein powders (from soy, rice, whey, egg whites)</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol cyanTableBackgroundColor">
                                                        <p><strong>Eat 2.5 to 3 cups of fruits and vegetables per
                                                            day.</strong></p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p><strong>Good sources:</strong> Fresh or frozen are preferred
                                                            over canned (ideally lightly steamed or raw as overcooking
                                                            can result in loss of nutrients). Juicing fruits and
                                                            vegetables is a good way to consume these items, especially
                                                            if you feel full quickly with small amounts of food. You can
                                                            even add protein powder and yogurt to make a delicious meal
                                                            replacement shake.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol cyanTableBackgroundColor">
                                                        <p><strong>Eat 3 to 6 oz of whole grains daily.</strong></p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p><strong>Good sources:</strong> Limit your intake of refined
                                                            carbohydrates. You can also add wheat germ or ground
                                                            flaxseed to foods to increase fiber.</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol cyanTableBackgroundColor">
                                                        <p><strong>Eat no more than 5-6 teaspoons of added oils
                                                            daily</strong></p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>&nbsp;</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol cyanTableBackgroundColor">
                                                        <p><strong>Consider stocking up on Meal Supplement
                                                            shakes.</strong><br/>
                                                            <em>These shakes are helpful in providing calories, protein
                                                                and vitamins in a liquid form that does not cause a
                                                                feeling of fullness.</em></p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p><strong>Good sources:</strong> Ensure, Boost, Carnation
                                                            Breakfast Essentials (regular and no sugar added), Glucerna.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="leftCol cyanTableBackgroundColor">
                                                        <p><strong>Consider stocking up to make your own
                                                            smoothies/shakes using juiced fruits, vegetables, yogurt,
                                                            protein powder, peanut butter, etc.</strong></p>
                                                    </td>
                                                    <td className="rightCol">
                                                        <p>These can be an even better source of nutrition and less
                                                            expensive than the commercially prepared versions as
                                                            well.</p>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxNoBackgroundPriority defaultBorder text-center">
                                                <h2>A note about glucose intolerance and diabetes.</h2>
                                                <h2><strong>Individuals with pancreatic cancer or pancreatitis are at
                                                    risk for glucose intolerance or diabetes.</strong> If you are
                                                    already diabetic, eat a carbohydrate-consistent diet.</h2>
                                                <h2>Poorly controlled diabetes can affect your body’s ability to heal
                                                    after surgery, can cause electrolyte abnormalities, and can affect
                                                    heart and kidney function.</h2>
                                                <h2>Keep careful track of your blood sugars both before and after
                                                    surgery.</h2>
                                                <h2>If you have not already seen a dietician, contact your primary care
                                                    physician or endocrinologist regarding seeing a dietician familiar
                                                    with diabetes.</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic3;
