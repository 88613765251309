import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const LessonDressingWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonDressing">Dressing A Person</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDressing/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDressing/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDressing/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonDressing/WhatToDoAndSafety#equipment">
                                                    <p className="whiteColor">Equipment <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Let the Person Make Choices</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/DP_choosing.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Help the person stay as independent as possible:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Allow the person to have choices in what to wear.</li>
                                                    <li>Allow the person with dementia to choose from a limited selection of outfits. If the person has a favorite outfit, consider buying several sets of the same clothes.</li>
                                                    <li>Make it simple for the person to choose clothing to wear. Keep closets free of excess and off-season clothing. Arrange clothing in sets that match.</li>
                                                    <li>Let the person dress him or herself as much as possible. Do not pressure or rush the person.</li>
                                                    <li>Do not criticize or correct the person with dementia, even if the clothing is mismatched.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Make the Person Comfortable</h2>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="w-100">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/DP_comfortable.png" className="img-responsive" />
                                                <p className="captionItalic text-center w-100">&nbsp;&nbsp;</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Give the person as much privacy as possible during dressing.</li>
                                                    <li>It is easier to dress in a room that is not too cold and has good lighting.</li>
                                                    <li>Reduce any embarrassment the person might feel by letting him or her know you will leave the room, but stay close by if they need help.</li>
                                                    <li>Compliment the person on the way he or she looks.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Choose Clothes That are Easy to Put On</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Help the person select clothes that are loose-fitting, especially around the waist and hips. A person recovering from surgery for example, will likely want to wear loose-fitting casual clothes.</li>
                                                    <li>Choose fabrics that are lightweight and smooth in texture.</li>
                                                    <li>Choose clothing that is easy to put on and remove such as:
                                                        <ul className="bulletsCustom checkCircleBulletsSafeCare">
                                                            <li>Sweaters that button</li>
                                                            <li>Pants with elastic waists</li>
                                                            <li>Shirts and blouses that button in the front</li>
                                                            <li>Pullover shirts with loose necklines</li>
                                                            <li>Half-ankle socks</li>
                                                            <li>Slip-on shoes or shoes with Velcro<sup>&reg;</sup> fasteners</li>
                                                        </ul>
                                                    </li>
                                                    <li>Try using Velcro<sup>&reg;</sup> instead of buttons, snaps and zippers which may be difficult for the person to handle or grasp.</li>
                                                    <li>Men can more easily put on boxer shorts that have Y-fronts.</li>
                                                    <li>Have women wear a sports bra or a tank without fasteners.</li>
                                                    <li>If a person cannot get out of bed, have them wear a gown with a back closure.</li>
                                                    <li>A warm, wool cape may be better than a coat as it is easier to put on.</li>
                                                    <li>Remind the person that labels in the garment are the best reminder as to the right and wrong side of the garment.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Follow a Routine</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Encourage the person to dress at the same time each day so he or she knows it is part of a daily routine.</li>
                                                    <li>Arrange clothes in the order that the person typically puts them on.</li>
                                                    <li>Help the person move through each step of dressing. Give the person one item of clothing at a time.</li>
                                                    <li>If the person has a condition affecting their memory or thinking, use short, simple instructions such as: “Put on your shirt” rather than “Get dressed” or “Now put this arm through the sleeve” instead of “Put this blouse on your weak arm”.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Do Not Rush</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Plan dressing so that <span className="bold italic">you and the person have plenty of time.</span></li>
                                                    <li>Have the person use the bathroom before beginning to dress so that you will not have to stop in the middle of dressing.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Change Clothes Regularly</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Not all people want to change their clothes each day. For example, a person with dementia may not want to change clothes before going to bed or not realize their clothing is dirty. A person who is always fatigued will have little energy and may be unwilling to change clothes.</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Provide a clean set of clothes for the person to put on after a shower or bath. Remove the old set of clothes. Then, place clean clothes in the order that they are to be put on (e.g. socks on top, followed by underwear, etc.).</li>
                                                    <li>Make changing clothes a pleasant event, such as getting ready for someone’s visit or putting on something new.</li>
                                                    <li>Check the person’s clothing during the day to make sure it is not soiled.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="safetytips">SAFETY TIPS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/DP_equipment.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Dress a person while he or she is sitting. This prevents loss of balance during standing.</li>
                                                    <li>Help the person select clothing that fits properly. With illness or a change in physical activity, weight loss or gain is not uncommon. People with osteoporosis actually lose height. A pair of pants that are too long, for example, might cause a person to trip and fall.</li>
                                                    <li>A person with dementia who wishes to help with cooking, should not wear loose sleeves that could catch fire.</li>
                                                    <li>Have the person wear comfortable, non-slip shoes. The exception is people who shuffle, like those with Parkinson’s disease and late-stage Alzheimer’s disease. They often do better with smooth, leather soles that glide. Rubber non-slip shoes may catch and stop, pitching the person forward and off balance.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="showMeHow">SHOW ME HOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Learn the proper way to dress people.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="z03U-jWz8DY" opts={opts}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/DP_equipment.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Set of clothing</li>
                                                    <li>Shoe horn (option)</li>
                                                    <li>Adaptive equipment for pulling on clothes</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonDressingWhatToDoAndSafety;
