import React, { useEffect } from "react";
import Tile from '../tile/index.js'
import {ONE, TWO, THREE, FOUR, FIVE, SIX, SEVEN, EIGHT, NINE, TEN, ELEVEN, TWELVE,
    THIRTEEN, FOURTEEN, FIFTEEN, SIXTEEN, SEVENTEEN, EIGHTEEN} from './constants.js'

const Cluster = ({
    clusterType,
    useFlexContainer,
    tileAttributes,
    profileUrl,
    dIdList,
    clusterCssArray,
    useLeftRight = false,
    tileClickable
}) => {

    useEffect(() => {
    }, []);

    return tileAttributes && tileAttributes.length > 0 ? constructHtmlBody({
        clusterType,
        tileAttributes,
        useLeftRight,
        profileUrl,
        dIdList,
        clusterCssArray,
        useFlexContainer,
        tileClickable
    }) : null
};

const constructHtmlBody = ({
   clusterType,
   tileAttributes,
   useLeftRight,
   profileUrl,
   dIdList,
   clusterCssArray,
   useFlexContainer,
   tileClickable
}) => {

    switch (clusterType) {
        case ONE:
        case TWO:
        case THREE:
        case FOUR:
        case FIVE:
        case SIX:
        case SEVEN:
        case EIGHT:
        case NINE:
        case ELEVEN:
        case TWELVE:
        case FOURTEEN:
        case SEVENTEEN: return constructClusterOne({tileAttributes, profileUrl, dIdList, useFlexContainer, clusterCssArray, tileClickable})
        case TEN:
        case THIRTEEN:
        case FIFTEEN:
        case SIXTEEN:
        case EIGHTEEN: return constructClusterTwo({tileAttributes, useLeftRight, profileUrl, dIdList, useFlexContainer, clusterCssArray, tileClickable})
    }
}

const constructOuterContainerBody = ({
    container,
    counter
}) => {
    return (
        <div key={"outerContainer" + counter} className={'container hidden-sm hidden-xs'}>
            <div key={"fadeContainer" + counter} className={'row wow fadeInLeft animated'}>
                <div key={"innerContainer" + counter} className={'col-lg-10 col-lg-offset-1'}>
                    {container}
                </div>
            </div>
        </div>
    )
}

const constructOuterContainerFlexBody = ({
    container,
    counter
}) => {
    return (
        <div key={"outerContainer" + counter} className="w-100">
            <div key={"betweenOuterAndFadeContainer" + counter} className="col-10 m-auto sitemanEntCluster">
                <div key={"fadeContainer" + counter} className="w-100 wow fadeInRight animated">
                    <div key={"betweenFadeInnerContainer" + counter} className="col-12">
                        {container}
                    </div>
                </div>
            </div>
        </div>
    )
}

const constructOuterContainer = ({
    tiles,
    counter
}) => {
    return (
        <div key={"outerContainerCluster" + counter} className={'row'}>
            {tiles}
        </div>
    )
}

const constructOuterContainerNonCluster = ({
   tiles,
    counter
}) => {
    return (
        <div key={"outerContainerNonCluster" + counter} className={'col-md-12'}>
            <div key={"innerContainerNonCluster" + counter} className={'row'}>
                {tiles}
            </div>
        </div>
    )
}

const constructClusterOne = ({
    tileAttributes,
    profileUrl,
    dIdList,
    useFlexContainer,
    clusterCssArray,
    tileClickable
}) => {
    let outerContainer = [];
    let tileContainer = [];
    let colCounter = 0;

    return (
        tileAttributes && tileAttributes.length > 0 ? tileAttributes.map((attribute, i) => {
            let {attributes: {lineOneText, lineTwoText, imageUrl, tilePageId, backgroundCss, tileLevelCss, linkUrl, childClusterId, displayProfileImage, targetType, linkPath, linkTilePage, addChevron}, tileType, outerContainerCss, rows, cols} = attribute;
            colCounter = colCounter + cols;

            if (dIdList && dIdList.length > 0) {
                const linkSettings = buildUrlFromLinkSettings(linkPath, linkTilePage, dIdList);

                if (linkSettings) {
                    if ('tilePageId' in linkSettings)
                        tilePageId = linkSettings.tilePageId;

                    if ('linkUrl' in linkSettings)
                        linkUrl = linkSettings.linkUrl;

                    if ('childClusterId' in linkSettings)
                        childClusterId = linkSettings.childClusterId;

                    if ('lineOneText' in linkSettings)
                        lineOneText = linkSettings.lineOneText;

                    if ('lineTwoText' in linkSettings)
                        lineTwoText = linkSettings.lineTwoText;
                }
            }

            if ((colCounter % 3 === 0) || (i + 1 === tileAttributes.length)) {
                colCounter = 0;
                tileContainer.push(
                    <Tile
                        attributes={{lineOneText, lineTwoText, imageUrl, tilePageId, backgroundCss, tileLevelCss, linkUrl, childClusterId, displayProfileImage, profileUrl, targetType, addChevron}}
                        rows={rows}
                        cols={cols}
                        tileType={tileType}
                        outerContainerCss={outerContainerCss}
                        key={'id_' + i}
                        useAsIndividual={true}
                        tileClickable={tileClickable}
                    />
                )

                outerContainer.push(constructOuterContainer({tiles: tileContainer, counter: i}))

                tileContainer = [];
            } else {
                tileContainer.push(
                    <Tile
                        attributes={{lineOneText, lineTwoText, imageUrl, tilePageId, backgroundCss, tileLevelCss, linkUrl, childClusterId, displayProfileImage, profileUrl, targetType, addChevron}}
                        rows={rows}
                        cols={cols}
                        tileType={tileType}
                        outerContainerCss={outerContainerCss}
                        key={'id_' + i}
                        useAsIndividual={true}
                        tileClickable={tileClickable}
                    />
                )
            }

            if (i + 1 === tileAttributes.length) {
                if (!useFlexContainer)
                    return constructOuterContainerBody({container: outerContainer, counter: i});
                else
                    return constructOuterContainerFlexBody({container: outerContainer, counter: i})
            }
        }) :
        <div>
            <h1>
                The selected orbit is currently under construction.  Please feel free to try again later.
            </h1>
        </div>
    )
}

const constructColumnForChain = ({
     tileAttributes,
     highestColumn,
     removePadding = false,
     removeAllPadding = false,
     floatCluster = false
}) => {
    return (
        highestColumn === 1 ?
            <div className={'col-md-4 ' + (!removePadding ? 'paddingLR5' : '')} style={{padding: (removeAllPadding ? '0px' : ''), float: (floatCluster ? 'left' : 'none')}}>
                {tileAttributes}
            </div> : highestColumn === 2 ?
            <div className={'col-md-8 ' + (!removePadding ? 'paddingLR5' : '')} style={{padding: (removeAllPadding ? '0px' : ''), float: (floatCluster ? 'left' : 'none')}}>
                {tileAttributes}
            </div> :
            <div className={'col-md-12 ' + (!removePadding ? 'paddingLR5' : '')} style={{padding: (removeAllPadding ? '0px' : ''), float: (floatCluster ? 'left' : 'none')}}>
                {tileAttributes}
            </div>
    )
}

const constructColumnForChainCssClassOnly = ({
    tileAttributes,
    highestColumn,
    cssClass
}) => {
    return (
        highestColumn === 1 ?
            <div className={(cssClass ? ' ' + cssClass : '')}>
                {tileAttributes}
            </div> : highestColumn === 2 ?
            <div className={(cssClass ? ' ' + cssClass : '')}>
                {tileAttributes}
            </div> :
            <div className={(cssClass ? ' ' + cssClass : '')}>
                {tileAttributes}
            </div>
    )
}

//col-md-6 noPadding variableHeightClusterPaddingLeft
const nonChainedCluster = ({
   attribute,
   index,
   useOuterContainer,
   profileUrl,
   dIdList,
   tileClickable
}) => {
    let {attributes: {lineOneText, lineTwoText, imageUrl, backgroundCss, tileLevelCss, tilePageId, linkUrl, childClusterId, displayProfileImage, targetType, linkPath, linkTilePage, addChevron}, tileType, outerContainerCss, rows, cols} = attribute;

    if (dIdList) {
        const linkSettings = buildUrlFromLinkSettings(linkPath, linkTilePage, dIdList);
        if (linkSettings) {
            if ('tilePageId' in linkSettings)
                tilePageId = linkSettings.tilePageId;

            if ('linkUrl' in linkSettings)
                linkUrl = linkSettings.linkUrl;

            if ('childClusterId' in linkSettings)
                childClusterId = linkSettings.childClusterId;

            if ('lineOneText' in linkSettings)
                lineOneText = linkSettings.lineOneText;

            if ('lineTwoText' in linkSettings)
                lineTwoText = linkSettings.lineTwoText;
        }
    }

    return (
        <Tile
            attributes={{lineOneText, lineTwoText, imageUrl, backgroundCss, tileLevelCss, tilePageId, linkUrl, childClusterId, displayProfileImage, profileUrl, targetType, addChevron}}
            rows={rows}
            cols={cols}
            tileType={tileType}
            outerContainerCss={outerContainerCss}
            key={'id_' + index}
            useAsIndividual={true}
            useInChain={false}
            useOuterContainer={useOuterContainer}
            tileClickable={tileClickable}
        />
    )
}

const chainedCluster = ({
    chainedSubList,
    highestColumn,
    profileUrl,
    dIdList,
    cssClass,
    tileClickable,
    useOuterContainer = true,
    useAsIndividual = true,
    useInChain = true,
    floatCluster = false,
}) => {
    let outerContainer = [];
    let tileContainer = [];


    chainedSubList.map((attribute, i) => {
        let {attributes: {lineOneText, lineTwoText, imageUrl, tilePageId, backgroundCss, tileLevelCss, linkUrl, displayProfileImage, targetType, linkPath, linkTilePage, addChevron}, tileType, outerContainerCss, rows, cols, useLeft, useRight, childClusterId} = attribute;

        if (dIdList) {
            const linkSettings = buildUrlFromLinkSettings(linkPath, linkTilePage, dIdList);
            if (linkSettings) {
                if ('tilePageId' in linkSettings)
                    tilePageId = linkSettings.tilePageId;

                if ('linkUrl' in linkSettings)
                    linkUrl = linkSettings.linkUrl;

                if ('childClusterId' in linkSettings)
                    childClusterId = linkSettings.childClusterId;

                if ('lineOneText' in linkSettings)
                    lineOneText = linkSettings.lineOneText;

                if ('lineTwoText' in linkSettings)
                    lineTwoText = linkSettings.lineTwoText;
            }
        }

        if ((i + 1 === chainedSubList.length)) {
            tileContainer.push(
                <Tile
                    attributes={{lineOneText, lineTwoText, imageUrl, tilePageId, backgroundCss, tileLevelCss, linkUrl, childClusterId, displayProfileImage, profileUrl, targetType, linkPath, linkTilePage, addChevron}}
                    rows={rows}
                    cols={cols}
                    tileType={tileType}
                    outerContainerCss={outerContainerCss}
                    key={'id_' + i}
                    useAsIndividual={useAsIndividual}
                    useInChain={useInChain}
                    useOuterContainer={useOuterContainer}
                    useLeft={useLeft ? useLeft : false}
                    useRight={useRight ? useRight : false}
                    addHoverToTile={true}
                    tileClickable={tileClickable}
                />
            )

            outerContainer.push(cssClass ? constructColumnForChainCssClassOnly({tileAttributes: tileContainer, highestColumn: highestColumn, floatCluster, cssClass}) : constructColumnForChain({tileAttributes: tileContainer, highestColumn: highestColumn, floatCluster}));
        } else {
            tileContainer.push(
                <Tile
                    attributes={{lineOneText, lineTwoText, imageUrl, tilePageId, backgroundCss, tileLevelCss, linkUrl, childClusterId, displayProfileImage, profileUrl, targetType, linkPath, linkTilePage, addChevron}}
                    rows={rows}
                    cols={cols}
                    tileType={tileType}
                    outerContainerCss={outerContainerCss}
                    key={'id_' + i}
                    useAsIndividual={true}
                    useInChain={true}
                    useOuterContainer={useOuterContainer}
                    useLeft={useLeft ? useLeft : false}
                    useRight={useRight ? useRight : false}
                    addHoverToTile={true}
                    tileClickable={tileClickable}
                />
            )
        }
    })

    return outerContainer

}

const constructOuterContainerRow = ({
    tiles
}) => {
    return (
        <div className={'col-md-12'} style={{padding: '0px'}}>
            {tiles}
        </div>
    )
}

const constructOutermostContainer = ({
 tiles
}) => {
    return (
        <div className={'row'}>
            {tiles}
        </div>
    )
}

const countNumberOfDistinctChains = ({
    tileAttributes
}) => new Set(tileAttributes.map((attribute) => attribute.chain)).size

const numberOfClustersInChain = ({
    tileAttributes
}) => {
    return tileAttributes.reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue), []
    ).filter(item => item.chain).length
}

const constructClusterTwo = ({
    tileAttributes,
    useLeftRight,
    profileUrl,
    dIdList,
    clusterCssArray,
    useFlexContainer,
    tileClickable
}) => {
    let modifiedTileAttributes = searchForChains({tileAttributes});
    let {newTileAttributes} = modifiedTileAttributes;
    let colCounter = 0;
    let tiles = [];
    let outerContainer = [];
    let tempChain = '';
    let chainIndex = 0;
    let numberOfClusteredItems = numberOfClustersInChain({tileAttributes: newTileAttributes});
    let numberOfDistinctChains = countNumberOfDistinctChains({tileAttributes: newTileAttributes});

    newTileAttributes && newTileAttributes.length > 0 ? newTileAttributes.map((tileAttribute, i) => {
        colCounter = colCounter + tileAttribute.cols;
        if (!tileAttribute.chain) {
            tempChain = '';
            if (colCounter % 3 === 0) {
                tiles.push(nonChainedCluster({
                    attribute: tileAttribute,
                    index: i,
                    useOuterContainer: !tileAttribute.removeOuterContainer,
                    profileUrl,
                    dIdList,
                    tileClickable
                }));

                outerContainer.push(constructOuterContainerRow({tiles}));
                tiles = [];
                colCounter = 0;
            } else if (i + 1 === newTileAttributes.length) {
                tiles.push(nonChainedCluster({
                    attribute: tileAttribute,
                    index: i,
                    useOuterContainer: !tileAttribute.removeOuterContainer,
                    profileUrl,
                    dIdList,
                    tileClickable
                }));

                outerContainer.push(constructOuterContainerRow({tiles}));
                tiles = [];
                colCounter = 0;
            } else {
                tiles.push(nonChainedCluster({
                    attribute: tileAttribute,
                    index: i,
                    useOuterContainer: !tileAttribute.removeOuterContainer,
                    profileUrl,
                    dIdList,
                    tileClickable
                }));
            }
        } else {
            if (tileAttribute.chain !== tempChain) {
                tempChain = tileAttribute.chain;
                let {floatCluster} = tileAttribute;
                chainIndex++;

                let chainedSubList = newTileAttributes.filter((item) => item.chain === tileAttribute.chain);
                colCounter = findHighestColumnWidth({tileAttributes: chainedSubList});

                if (numberOfClusteredItems === newTileAttributes.length) {
                    tiles.push(chainedCluster({
                        chainedSubList,
                        highestColumn: colCounter,
                        profileUrl,
                        floatCluster,
                        cssClass: clusterCssArray[chainIndex-1],
                        dIdList,
                        tileClickable
                    }))

                    outerContainer.push(tiles);

                    colCounter = 0;
                    tiles = [];
                } else if (chainedSubList.length + i === newTileAttributes.length) {
                    tiles.push(chainedCluster({
                        chainedSubList,
                        highestColumn: colCounter,
                        cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[chainIndex-1] : null,
                        profileUrl,
                        floatCluster,
                        dIdList,
                        tileClickable
                    }));

                    outerContainer.push(clusterCssArray && clusterCssArray.length > 0 ? constructColumnForChainCssClassOnly({tileAttributes: tiles, highestColumn: 3, removePadding: true, removeAllPadding: true, floatCluster, cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[chainIndex-1] : null}) : constructColumnForChain({tileAttributes: tiles, highestColumn: 3, removePadding: true, removeAllPadding: true, floatCluster}));
                    colCounter = 0;
                    tiles = [];
                    return useFlexContainer ? constructOuterContainerFlexBody({container: outerContainer, counter: i}) : constructOuterContainerBody({container: outerContainer, counter: i});
                } else {
                    tiles.push(chainedCluster({
                        chainedSubList,
                        highestColumn: colCounter,
                        cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[chainIndex-1] : null,
                        useOuterContainer: true,
                        profileUrl,
                        floatCluster,
                        dIdList,
                        tileClickable
                    }))
                }
            }
        }
    }) :
    <div>
        <h1>
            The selected orbit is currently under construction.  Please feel free to try again later.
        </h1>
    </div>

    if (useLeftRight && chainIndex === numberOfDistinctChains)
        return useFlexContainer ? constructOuterContainerFlexBody({container: constructOuterContainerNonCluster({tiles: outerContainer})}) : constructOuterContainerBody({container: constructOuterContainer({tiles: constructOuterContainerNonCluster({tiles: outerContainer, counter: 1}), counter: 1}), counter: 1});
    else
        return useFlexContainer ? constructOuterContainerFlexBody({container: outerContainer, counter: 1}) : constructOuterContainerBody({container: constructOuterContainer({tiles: outerContainer, counter: 1}), counter: 1});
    /*
    if (useLeftRight && chainIndex === numberOfDistinctChains)
        return useFlexContainer ? constructOuterContainerFlexBody({container: constructOuterContainer({tiles: constructOuterContainerNonCluster({tiles: outerContainer, cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[i] : null})})}) : constructOuterContainerBody({container: constructOuterContainer({tiles: constructOuterContainerNonCluster({tiles: outerContainer, cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[i] : null})})});
    else
        return useFlexContainer ? constructOuterContainerFlexBody({container: constructOuterContainer({tiles: outerContainer, cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[i] : null})}) : constructOuterContainerBody({container: constructOuterContainer({tiles: outerContainer, cssClass: clusterCssArray && clusterCssArray.length > 0 ? clusterCssArray[i] : null})});
     */
}

const findHighestColumnWidth = ({
    tileAttributes
}) => {
    return [...tileAttributes].sort((a,b)=>b.cols-a.cols)[0].cols;
}

const searchForChains = ({
    tileAttributes
}) => {
    let newTileAttributes = [];
    let attributeArrayPos = [];
    let chainedNameArray = [];


    newTileAttributes = tileAttributes.sort(
        (a,b) => {
            if ( isNaN(a.chain)&&isNaN(b.chain)) return a.chain<b.chain?-1:a.chain === b.chain?0:1;//both are string
            else if (isNaN(a.chain)) return 1;//only a is a string
            else if (isNaN(b.chain)) return -1;//only b is a string
            else return a.chain-b.chain;//both are num
        }
    )

    chainedNameArray = [...new Set(newTileAttributes.reduce((a, c) => [...a, c.chain], []))]
    chainedNameArray.forEach((name) => {
        newTileAttributes.forEach((tileAttribute, i) => {
            if (tileAttribute.chain === name)
                attributeArrayPos.push(i);
        });
    });

    if (chainedNameArray.length === 0)
        newTileAttributes.concat(tileAttributes);

    return {newTileAttributes, attributeArrayPos};
}

const buildUrlFromLinkSettings = (
    linkPath,
    linkTilePage,
    dIdList
) => {
    if (dIdList && dIdList.length > 0) {
        if (linkPath && linkPath.length > 0)
            return linkPath.find((item) =>
                dIdList.map(item2 => item2.toString()).includes(item.dIdList.toString())
            );
        else if (linkTilePage && linkTilePage.length > 0)
            return linkTilePage.find((item) =>
                dIdList.map(item2 => item2.toString()).includes(item.dIdList.toString())
            );
        else
            return null;
    } else {
        return null;
    }
}

/*const buildUrlFromLinkSettings = ({
  linkPath,
  linkTilePage,
  dIdList
}) => {
    if (dIdList && dIdList.length > 0) {
        if (linkPath && linkPath.length > 0) {
            return linkPath.find((item) =>
                dIdList.map((val) => parseInt(val)).includes(parseInt(item.dIdList))
            );
        } else if (linkTilePage && linkTilePage.length > 0) {
            return linkTilePage.find((item) =>
                dIdList.map((val) => parseInt(val)).includes(parseInt(item.dIdList))
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}*/

export default Cluster;
