import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import LessonSuppositoriesWhatToDoAndSafety from "./what-to-do-and-safety";
const LessonSuppositoriesFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation orangeImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonSuppositories">Recal Suppositories</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonSuppositories/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonSuppositories/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How do I know if I inserted the suppository far enough?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>You want to insert the suppository about 4 inches up into the rectum. You should feel the person’s internal anal sphincter close slightly around your finger as you move it up into the rectum.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Which end of the suppository do I insert first?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Always insert the blunt rounded end and be sure it is covered in lubricating jelly.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Does the foil come off before I insert the suppository?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Yes. Always take the foil off before insertion. There are persons who have had to visit emergency rooms to have suppositories removed because they forgot to remove the foil.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">I give my grandmother a suppository every now and then for constipation. Why does it slip out so often?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Older adults often have a loss of internal sphincter control, making it difficult for them to hold a suppository. You might try to gently hold the buttocks together and coach the person to hold the suppository for several minutes, to be sure it is absorbed..</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/USP_logo.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">United States Pharmacopeial (USP)</h2>
                                                <p>An official public authority that sets standards for all prescription and over–the–counter medicines and other health care products made or sold in the United States.</p>
                                                <p className="resourceLink">visit: <a href="http://www.usp.org/" target="_blank">www.usp.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ISMP_logo.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">The Institute for Safe Medication Practices (ISMP)</h2>
                                                <p>The nation’s only nonprofit organization devoted entirely to medicine error prevention and safe medicine use. The ISMP educates consumers about safe medicine practices.</p>
                                                <p className="resourceLink">visit: <a href="http://www.ismp.org/" target="_blank">www.ismp.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/mplus_fb.png" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">Medline Plus</h2>
                                                <p>MedlinePlus brings together authoritative information from NLM, the National Institutes of Health (NIH), and other government agencies and health-related organizations. This is a resource for specific medicines.</p>
                                                <p className="resourceLink">visit: <a href="https://medlineplus.gov/" target="_blank">http://nlm.nih.gov/medlineplus</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1 w-100">
                                                <img className="img-responsive maxWidth200" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/Safe_Medication_logo.jpg" />
                                                <h2 class="subheadUpperAndLower withBodyCopy">Safe Medication</h2>
                                                <p>Web site of the American society of Health-system Pharmacists. This is a resource for medicine administration.</p>
                                                <p className="resourceLink">visit: <a href="http://www.safemedication.com/" target="_blank">www.safemedication.com</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonSuppositoriesFAQResources;