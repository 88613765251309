import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonBloodPressureFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonBloodPressure">Taking a Blood Pressure</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonBloodPressure/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonBloodPressure/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Is there a best time of day to take a blood pressure measurement?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Taking the blood pressure at the same time each day makes it easier to compare the blood pressure over time and determine if there are changes. Be sure the person has not done anything that may alter the reading abnormally.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What should I do if I cannot hear the blood pressure well?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>This problem is common. You may need to switch to an automatic device. Always practice hearing with the stethoscope of an aneroid device before you begin measurements.</p>
                                        <p>Place the ear pieces in your ears so that you can hear through the flat diaphragm of the stethoscope. Stethoscopes rely on an airtight seal in order to transmit body sounds from the person to your ears. If the parts are loose or cracked, they will not work properly.</p>
                                        <p>Rubbing or tapping the diaphragm of the stethoscope gently can help you practice hearing sounds.Be sure the room is quiet when you take a blood pressure reading.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Can the person stop taking their medicine if I measure a normal blood pressure reading?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The doctor will decide how the person is to take their medicines. Usually the person will continue to take their medicine regardless of the blood pressure reading you get.</p>
                                        <p>However, if the pressure is higher, ask the doctor when to call about abnormal blood pressure readings and at what reading medicines should be held.</p>
                                        <p>There are going to be times, especially with older adults, that you may need to hold the blood pressure medicine. Dehydration, for example will decrease someone’s blood pressure and giving the medicine may cause dizziness, falls and fainting.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How does measuring a blood pressure help a person?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Regular checking of the blood pressure will tell you if it is under control and at the level recommended by the doctor. Taking a blood pressure regularly gives the person better control and more motivation to take medicines correctly, eat properly and exercise.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Is it okay to check blood pressure in a public place, like a drug store?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Public blood pressure machines, such as those found in drug stores, may not be accurate. Often the devices are not properly maintained, and the cuff may not be the correct size for you, depending on the size of your arm. You shouldn’t rely on public blood pressure machines to regularly measure a person’s blood pressure.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-12">
                                <div className="navTopicButtonPageHref">
                                    <a id="resources">RESOURCES</a>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NHLBI_Logo.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">The National High Blood Pressure Education Program</h2>
                                                <p>The National High Blood Pressure Education Program (NHBPEP) is a cooperative effort among professional and voluntary health agencies, state health departments, and many community groups. The program is administered and coordinated by the National Heart, Lung, and Blood Institute (NHLBI). This program distributes recommendations for managing high blood pressure to consumers.</p>
                                                <p className="resourceLink">visit: <a href="https://www.nhlbi.nih.gov/health-topics/education-and-awareness/high-blood-pressure" target="_blank">www.nhlbi.nih.gov</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AHA_Full.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American Heart Associationr</h2>
                                                <p>Provides information on how to respond when symptoms of a heart attack develop.</p>
                                                <p className="resourceLink">visit: <a href="https://www.heart.org/" target="_blank">www.heart.org</a>
                                                </p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/logo-mayoclinic.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">Mayo Clinic</h2>
                                                <p>The Mayo Clinic’s mission is to empower people to manage their health. The website offers useful and up-to-date information and tools on hypertension and how to measure blood pressure.</p>
                                                <p className="resourceLink">visit: <a href="https://www.mayoclinic.org/" target="_blank">www.mayoclinic.com</a>
                                                </p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/NHLBI_Logo.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">The National High Blood Pressure Education Program</h2>
                                                <p>One of the National Institutes of Health, the NHLBI provides global leadership for a research, training, and education program to promote the prevention and treatment of heart, lung, and blood disorders and enhance the health of all individuals so that they can live longer and more fulfilling lives.</p>
                                                <p className="resourceLink">visit: <a href="https://www.nhlbi.nih.gov/about" target="_blank">https://www.nhlbi.nih.gov/about</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                            TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonBloodPressureFAQResources;