import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTAll4_2_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center blueColor blueColorBorder">Well-Being</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="imageLeftCopyRight w-100">
                                            <div className="imgCol">
                                                <img className="floatLeft wrapImage"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/101006.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="typeCol">
                                                <p
                                                    className="floatNone clearNone boldLeadIn">A cancer diagnosis is a life-changing event. 
                                                </p>
                                                <p className="floatNone clearNone">It’s easy to feel overwhelmed by all of the changes that come with treatment, recovery and managing your ongoing health.
                                                </p>
                                                <p className="floatNone clearNone">It is important to understand that, alongside the treatments for your cancer, paying attention to the well-being of yourself and your family plays a big part in your ability to cope with challenges and thrive on the journey.
                                                </p>
                                                <p className="floatNone clearNone">Well-being has many different aspects. And there are many different forms of support available to you right now to help you with issues that may arise.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="sectionHeader text-center floatNone">Patient and Family Services</h3>
                                            <p>Visit our <a  href="https://siteman.wustl.edu/visiting/patient-and-family-services/"
                                                target="_blank">Patient and Family Services</a> site to learn about and connect with our full range of assistance to help you and your family emotionally, physically, spiritually, and logistically.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower floatNone text-center">The Barnard Health and Cancer Information Center</h2>
                                            <p>The <a  href="https://siteman.wustl.edu/visiting/barnard-health-cancer-information-center/"
                                                target="_blank">Barnard Health and Cancer Information Center</a> (BHCIC) is available to help Siteman patients navigate their cancer journey by providing information about a range of topics, from coping with chemotherapy to finding parking on our campus. They strive to be a peaceful, restful area where patients and their families can relax. The BHCIC also distributes resources such as nutritional supplements and head coverings.</p>
                                                <p>The office houses the <span className="italic bold">Concierge Services for Barnes-Jewish Hospital</span>. They work to deliver prompt, accurate information to visitors. In addition, the Center serves as the treatment location for <span className="italic bold">Siteman Psychology Service</span>, offering a secure space to patients struggling with mental and emotional distress.</p>
                                                <p>At the BHCIC, staff members will speak with you to assess your needs. Cancer patients are often inundated with information, and it can feel difficult to remember it all. The staff will tailor information to your needs in order to assist you more effectively.</p>
                                                <p>Services are also available on a limited basis at Siteman’s satellite cancer centers.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 unsetSpace">
                                        <div className="imageTextStack w-100 unsetSpace">
                                            <div className="imgCol resourceGuide">
                                                <img className="floatLeft  maxWidth200"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Supportive-Resource-Guide_cover.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="typeCol">
                                                <p
                                                    className="floatNone clearNone boldLeadIn">For more quick reference, download our <a href="https://siteman.wustl.edu/wp-content/uploads/2020/01/409601_SCC-Supportive-Resource-Guide_rv7.pdf"
                                                target="_blank">Supportive Resource Guide ></a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop blueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                className="fa fa-chevron-up"></i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTAll4_2_3;
