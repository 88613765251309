import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
//const navigate = useNavigate();
const ContentPancreatic25 = () => {


    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Caring
                                                for your Emotional Health</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">IN GENERAL</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-2 imgCol noPaddingLeft">
                                                <img className="img-responsive"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/3-2-4_couple_image1.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="col-10 typeCol noPaddingLeft">
                                                <p className="mobileMarginBottom30">Transitioning from the hospital to
                                                    home, or a rehabilitation facility, can be both emotionally
                                                    satisfying and challenging.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">The first four months after a major medical event
                                                is a time of adjustment and uncertainty.</p>
                                            <p>Your emotions may range from deep appreciation to doubt and fear. You may
                                                have unsettling questions about your sense of meaning and purpose in
                                                life and will likely notice a loss of self-confidence.</p>
                                            <p className="boldLeadIn">These are normal reactions that will resolve with
                                                time, though it may take several months.</p>
                                            <p>During that period, please inform your care team of any of the
                                                following:</p>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>A history of depression, anxiety, panic attacks or other mental
                                                        health concerns
                                                    </li>
                                                    <li>Two weeks of feeling sad or loss of interest in activities that
                                                        you normally enjoy
                                                    </li>
                                                    <li>Feeling hopeless or having a desire to die</li>
                                                    <li>Increasing levels of anxiety or worry</li>
                                                    <li>Difficulty sleeping or an increasing need for sleep</li>
                                                    <li>A growing desire to be left alone</li>
                                                    <li>Irritability, anger or mood swings that are out of character
                                                    </li>
                                                    <li>Increasing fatigue, loss of appetite or loss of will to fight
                                                        for your recovery
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>We will work with you to help provide whatever care and support you or
                                                your family need.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            {/*<p className="boldLeadIn">Expert Guides For Patients and Families</p>*/}
                                            {/*<p><strong><em><a href="tel:3147475587">314-747-5587</a></em></strong> or visit Barnard Health and Cancer Information Center</p>*/}
                                            {/*<p>The Siteman Cancer Center provides state-of-the-art medical care. And because we believe in treating the whole patient, we also provide counseling support to help patients and families through the cancer journey. Cancer patients and their family members can schedule an appointment to talk about their situation and discuss which services might suit their needs best. One appointment may be all that is needed to receive the necessary support.</p>*/}
                                            <p className="boldLeadIn">Expert Guides, Counseling and Emotional Support</p>
                                            <p><strong><em><a href="tel:3147475587">314-747-5587</a></em></strong> or visit Barnard Health and Cancer Information Center</p>
                                            <p>The Siteman Cancer Center provides state-of-the-art medical care. And because we believe in treating the whole patient, we also provide counseling support to help patients and families through the cancer journey. Cancer patients and their family members can schedule an appointment to talk about their situation and discuss which services might suit their needs best. One appointment may be all that is needed to receive the necessary support.</p>
                                            <p>Our psychologists:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Provide education, counseling and referrals to specific resources.</li>
                                                <li>Teach coping skills, help with problem solving and provide an outlet for talking about difficulties.</li>
                                                <li>Information about emotional concerns associated with cancer treatment.</li>
                                                <ul>
                                                    <li>Anxiety Disorder</li>
                                                    <li>Depression</li>
                                                    <li>Normal Adjustment and the Adjustment Disorders</li>
                                                    <li>Post-traumatic Stress Disorder</li>
                                                </ul>
                                                <li>Offer counseling services to address grief or concerns for couples or families</li>
                                                <li>Help connect patients with support groups, peer support and community resources.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>To fully meet patient and family needs, we collaborate with specialists in case management, financial counseling, genetic counseling and spiritual care. We also work with the Oncologic Psychiatry Service, which provides treatment for Siteman patients who need psychiatric care.</p>
                                            <p>Counseling services are also available on a limited basis at our satellite facilities in west and south St. Louis County and in St. Charles County.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div
                                                className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight middleGreenBackground">
                                                <h2 className="subheadUpperAndLower mb-0">Services Designed to Help You
                                                    and Your Family</h2>
                                            </div>
                                            <div className="withBorder">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p>Find more about how Barnes-Jewish Hospital and Siteman Cancer
                                                            Center can help:</p>
                                                    </div>
                                                </div>

                                                <div className="col-12 images2AcrossAndText paddingOnSides">
                                                    <div className="w-100">
                                                        <div className="imagesAcross">

                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/BJH_logo_152x60.jpg"
                                                                className="img-responsive"/>

                                                            <img
                                                                src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/siteman_logo_178x60.png"
                                                                className="img-responsive"/>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Addressing emotional needs of patient
                                                            with cancer</p>
                                                        <p><a
                                                            href="https://www.barnesjewish.org/Newsroom/Publications/Innovate-Physician/Addressing-the-Emotional-Needs-of-Cancer-Patients"
                                                            target="_blank">learn more></a></p>
                                                        <p className="boldLeadIn">Counseling, Crisis Intervention and
                                                            Support Groups</p>
                                                        <p><a
                                                            href="https://www.barnesjewish.org/patients-visitors/social-work"
                                                            target="_blank">learn more></a></p>
                                                        <p className="boldLeadIn">Spiritual Services and Counseling</p>
                                                        <p><a
                                                            href="https://www.barnesjewish.org/patients-visitors/spiritual-care"
                                                            target="_blank">learn more></a></p>
                                                        <p className="boldLeadIn">Assisting Your And Your Family With
                                                            Logistics and Hospitality Services</p>
                                                        <p><a
                                                            href="https://www.barnesjewish.org/patients-visitors/services"
                                                            target="_blank">learn more></a></p>
                                                        <p className="boldLeadIn">Psychiatry Services</p>
                                                        <p>Contact the Siteman Cancer Psychiatry Service at <a href="tel:3142861700">314-286-1700</a>&nbsp;
                                                             for out-patient consultations and review the various
                                                            additional services offered by Barnes-Jewish hospital.</p>
                                                        <p><a href="https://www.barnesjewish.org/psychiatry"
                                                              target="_blank">learn more></a></p>
                                                        <p className="boldLeadIn">Caring Bridge</p>
                                                        <p><a
                                                            href="https://www.barnesjewish.org/patients-visitors/caringbridge"
                                                            target="_blank">learn more></a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                            <hr className="width100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">USEFUL ADVICE FOR FAMILY AND FRIENDS SEEKING
                                                TO HELP</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 imgColLeftContentColRight">
                                        <div className="w-100">
                                            <div className="col-2 imgCol noPaddingLeft">
                                                <img className="img-responsive w-100"
                                                     src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/3-2-4_couple_image2.jpg"
                                                     alt=""/>
                                            </div>
                                            <div className="col-10 typeCol noPaddingLeft">
                                                <p className="mobileMarginBottom30">The support of your family and
                                                    friends is a key part of your recovery in so many ways. Sometimes,
                                                    however, as they seek to help they can inadvertently leave you
                                                    feeling hurt, angry or depressed.<br/>Though every patient and
                                                    family is unique, the following is compiled from years of advice
                                                    heard from patients and families who have been through this, written
                                                    from the patient’s perspective.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower"><em>“Please don’t tell me to have a
                                                positive attitude.”</em></h2>
                                            <p>Just because I don’t feel good, cry, express unhappiness, anger or
                                                confusion does not mean that I have an attitude problem. When you tell
                                                me to have a “positive attitude” I feel alone with my fears, concerns
                                                and confusion. It takes a lot of “positive attitude” for me to keep my
                                                doctor appointments, have tests, take medications, treatments, and
                                                endure long days and nights in the hospital. I love and need you. So,
                                                please do not tell me to be positive, strong, optimistic, or how to
                                                feel. I do the best I can with what I have at the time. I always have.
                                                Always will.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower"><em>“Don’t tell me to eat.”</em></h2>
                                            <p>I promise to eat as much as I can. If I don’t eat enough, it’s only
                                                because I can’t. I understand that I need food to recover. You need to
                                                know that pushing me to eat can be upsetting and makes eating more
                                                difficult.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower"><em>“I know it comes from you caring,
                                                but please don’t ask me how I feel or how I’m doing.”</em></h2>
                                            <p>All day long, I work very hard at forgetting how I feel. I just want to
                                                enjoy being with you and doing the things that help me reconnect with
                                                “normal life.” If I want to talk about how I feel, or how I’m doing, I
                                                will let you know!</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower"><em>“Please don’t give me advice.”</em>
                                            </h2>
                                            <p>Everyone gives me advice, some of which is contradictory. Unwanted advice
                                                leaves me feeling confused, overwhelmed and worried that things are
                                                spinning out of control. I have a team of dedicated professionals to
                                                whom I turn for medical advice. If I want your advice, I will ask for
                                                it.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower"><em>“Please don’t speak for me.”</em>
                                            </h2>
                                            <p>If I want your help answering questions I’ll let you know. Until then, I
                                                appreciate your patience.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower"><em>“I have the right to remind you of
                                                these rules.”</em></h2>
                                            <p>I have the right to request that you follow these rules. I have the right
                                                to surround myself with people who help me. And, I have the right to
                                                avoid people who do not respect my wishes.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 coloredContentBox">
                                        <div className="w-100">
                                            <div
                                                className="roundedColorBackgroundBoxPriority middleGreenBackground text-center w-100">
                                                <h1><u>You are never alone.</u><br/>
                                                    We are here to help you recover physically <u>and</u> emotionally.
                                                </h1>
                                                <h2 className="text-white">Please reach out to your care team when you
                                                    feel the need.<br/>We have many ways, large and small, to help you
                                                    and your family.</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop middleGreenColor"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic25;
