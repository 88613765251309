import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonAspirationIntroduction = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Aspiration: Hear the Expert'}, () => {
            console.log('Aspiration: Hear the Expert');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader redImgBackground withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonAspiration">Aspiration
                                                            Precaution</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonAspiration/Introduction#introduction">
                                                    <p className="whiteColor">INTRODUCTION
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center twoAcross"
                                                      to="/LessonAspiration/Introduction#hearTheExpert">
                                                    <p className="whiteColor">HEAR THE EXPERT
                                                        <i className="fa fa-chevron-right">&nbsp;</i>
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="introduction">INTRODUCTION</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/AP_mouth_diagram.png"
                                                 className="img-responsive floatNone maxWidth300 m-auto"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Aspiration is the accidental entrance of food or saliva into a person’s
                                                windpipe (trachea). It usually occurs when a person has difficulty
                                                swallowing due to problems with the muscles or nerves in the mouth and
                                                throat.</p>
                                            <p>When we chew food and drink fluids, our mouth, tongue and throat work to
                                                move the fluid
                                                and food down a tube called the esophagus. The esophagus leads to the
                                                stomach. Our windpipe
                                                or trachea is the tube next to the esophagus that we breathe air in and
                                                out of, which leads
                                                to the lungs. When a person is not able to move fluids and/or solid food
                                                from the mouth to
                                                the stomach, the food or fluid accidentally enters the person’s
                                                windpipe. Then, as he or she
                                                breathes in, the food or liquid ends up in the lungs.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Swallowing is complex</p>
                                            <p>It requires nerves in the face, mouth and muscles of the tongue, throat,
                                                larynx (voice box), and jaw to all work together. A variety of medical
                                                problems can make swallowing difficult.
                                            </p>
                                            <p>If you care for a person who has difficulty swallowing, you must take
                                                steps to protect that person from aspiration when eating or drinking
                                                liquids. Aspiration is serious as it can lead to pneumonia, which is
                                                life threatening.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="hearTheExpert">HEAR THE EXPERT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Aspiration is the accidental entrance of food or saliva into a person's
                                                windpipe.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle w-100 text-center">Video:
                                                Aspiration</h3>
                                            <div className="floatLeft w-100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="g_aHkcQn3ag" opts={opts} onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="captionItalic marginBottom0">related content:</p>
                                                <p className="captionBold">Step-by-Step instructions</p>
                                                <p><a href="" target="_blank">Open File<i
                                                    className="fa fa-chevron-right">&nbsp;</i></a></p>
                                            </div>
                                        </div>
                                        <div className="col-12 backToTop footer">
                                            <div className="w-100">
                                                <p><a className="specialUseBackToTop"
                                                      onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                    TO TOP <i
                                                        className="fa fa-chevron-up"></i></a></p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
)
};

export default LessonAspirationIntroduction;
