import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGivingMedicinesWhatYouNeedToKnow = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGauzeDressing">Changing a
                                                            Gauze Dressing</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know About
                                            Giving Meds Safely</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center"
                                                      to="/LessonGauzeDressing/WhatYouNeedToKnow#whatToKnow">
                                                    <p className="whiteColor">What to Know <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center"
                                                      to="/LessonGauzeDressing/WhatYouNeedToKnow#sideEffects">
                                                    <p className="whiteColor">Side Effects <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center"
                                                      to="/LessonGauzeDressing/WhatYouNeedToKnow#allergies">
                                                    <p className="whiteColor">Allergies <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="whatToKnow">WHAT TO KNOW</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Do Not Split Pills</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Let’s face it, medicines cost a lot and often people look for short
                                                    cuts to save money on medicines. Often it costs the same to buy a
                                                    supply of medicine that comes in 40 mg as one that comes in 20 mg.
                                                    People will then cut the pills as a way to save money.</p>
                                                <p>Here is a tip from the Institute of Safe Medicine Practices (ISMP).
                                                    Avoid pill splitting.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadAndCopyWithIcon exclaim"><i
                                                    className="fa fa-exclamation-circle hightLight redColor"></i>Pill
                                                    splitting can be dangerous.</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Many medicines are not safe to take after they have been split. Some
                                                    are coated to be long-acting or to protect the stomach. Always talk
                                                    with the person’s doctor or pharmacist before splitting medicines.
                                                    If the doctor decides a pill can be safely split, have the doctor
                                                    write the prescription to reflect that, "Take 1/2 tab daily." Also
                                                    ask the pharmacist to split the pills for you. They have the tools
                                                    to correctly split medicines.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SCCG_medicine_cup.png"
                                                     className="img-responsive floatNone maxWidth300"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="sideEffects">SIDE EFFECTS</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Be Aware of Side Effects</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>A side effect is a predictable, often unwanted effect of a medicine.
                                                    A side effect is either harmless or it can cause injury.</p>
                                                <p>Side effects vary by the type of medicine. For example:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Codeine commonly causes a person to become constipated.</li>
                                                    <li>Antibiotics often cause diarrhea.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>When side effects occur, the doctor will decide whether to stop the
                                                    medicine or prescribe a different one. In some cases, the medicine
                                                    prescribed is the only one for a person’s condition, so the doctor
                                                    may ask the person to keep taking the medicine. There is a danger of
                                                    a person not wanting to take a medicine any longer because of side
                                                    effects.</p>
                                                <p>A doctor will adjust the dosage or prescribe a new medicine when side
                                                    effects are severe. Lifestyle or dietary changes may help to lessen
                                                    side effects. Never let the person stop taking a medicine because of
                                                    side effects without calling the doctor.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a href="#" id="allergies">ALLERGIES</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Drug Allergies</p>
                                                <p>Allergies are unpredictable responses to medicine. A person may be
                                                    allergic to a specific medicine (e.g. penicillin) or a class of
                                                    medicines (such as antibiotics). A person allergic to one drug in a
                                                    class is more likely to be allergic to other drugs in the class.
                                                    Allergies can happen with the first dose of a drug but also after a
                                                    person has taken a medicine for a long time or in the past. Always
                                                    check to be sure the doctor has not ordered a medicine that the
                                                    person is allergic to.</p>
                                                <p>Allergy symptoms will vary.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/SCCG_skin_rash.png"
                                                     className="img-responsive floatNone maxWidth300"/>
                                                <p className="captionItalic text-center w-100">Skin rash</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>Giving oxygen is a relatively easy skill if you understand why the
                                                    person you care for needs oxygen and how to act when an oxygen
                                                    delivery system does not function properly. This lesson will prepare
                                                    you to safely administer oxygen.</p>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">Severe allergic reactions include</p>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom orangeBullets">
                                                        <li>A rash on the arms, legs, and trunk of the body (see
                                                            photo)
                                                        </li>
                                                        <li>Itching of the skin</li>
                                                        <li>Watering of the eyes</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p className="boldLeadIn">Severe allergic reactions include</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Feeling of warmth</li>
                                                    <li>Flushing of the skin</li>
                                                    <li>Tingling in the mouth or lips</li>
                                                    <li>Light-headedness</li>
                                                    <li>Shortness of breath, wheezing, trouble breathing</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGivingMedicinesWhatYouNeedToKnow;
