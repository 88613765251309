import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanENTTL4_3 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center">Signs to Watch For</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="question">
                                                <h3 className="sectionHeader w-100 text-center">Staying safe at home.</h3>
                                                <p>Knowing signs to watch for helps you stay safe during your recovery and helps keep small problems from developing into larger ones.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr className="w-100"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="question">
                                                <h3 className="sectionHeader w-100 text-center">When you return home:</h3>
                                                <p>Have the person who is acting as your laryngectomy coach contact your local police and fire department to notify them that you cannot speak. They will know to send someone out to you check on you if they receive a 911 call from your home.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon"><i className="fa fa-exclamation-circle fa-2x hightLight redColor"></i>&nbsp;&nbsp;<span>Call 911 immediately if you have:</span></h2>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Chest pain/pressure or sharp pain with deep breath</li>
                                                <li>Shortness of breath that does not improve with removal of laryngectomy tube or suctioning</li>
                                                <li>Bleeding from your neck incisions, stoma or mouth</li>
                                                <li>Other life-threatening concerns</li>
                                                <li><strong><em>DO NOT drive yourself to the emergency room!</em></strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2>Call your Surgeon immediately if:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>You have chills or a temperature above 101<sup>o</sup> F or 38.5<sup>o</sup> C</li>
                                                    <li>Your incision becomes red or warm</li>
                                                    <li>There is an opening in your incision</li>
                                                    <li>There is new or increased drainage from the incision</li>
                                                    <li>The drainage from the incision has an odor</li>
                                                    <li>Either calf becomes painful, swollen or tender</li>
                                                    <li>Increasing, persistent swelling of legs or feet</li>
                                                    <li>Increasing pain that is not well-controlled by current pain medication</li>
                                                    <li>If you remove your laryngectomy tube and you can’t get it back in</li>
                                                    <li>If a feeding tube in your neck, stomach or nose falls out</li>
                                                    <li>Your JP drain stitches come loose or break</li>
                                                    <li>A change in position of the JP tube or your JP drain comes out</li>
                                                    <li>If you see holes or cracks in the JP tubing or bulb or the drain is leaking</li>
                                                    <li>If you have questions about your JP drain care</li>
                                                    <li>Poor appetite, not drinking, or generally not feeling well</li>
                                                    <li>If you have other concerns</li>
                                                </ul>
                                                <p className="boldLeadIn"><em>If you have a feeding tube:</em></p>
                                                <ul className="bulletsCustom">
                                                    <li>Poor appetite, not drinking, or generally not feeling well</li>
                                                    <li>Nausea, vomiting, upset stomach for more than 1 day</li>
                                                    <li>Tasting the tube feeds in the back of your throat</li>
                                                    <li>Heartburn</li>
                                                    <li>Constipation (more than 3 days without a bowel movement)</li>
                                                    <li>Diarrhea for more than 2 days</li>
                                                    <li>Signs of dehydration: more thirst than usual, dry mouth and eyes, sunken eyes, urinating less, smaller amounts of urine, dark urine</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                            <hr className="w-100 blueColorBorder"  />
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTL4_3;
