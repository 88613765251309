import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const SitemanENTAll3_7 = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground floatLeft clearLeft wow fadeInUp animated">
                        <section>
                            <div className="col-12">
                                <div className="w-100">
                                    <h1 className="tileTitleHeader centeredPageHeader middleGreenColor middleGreenBorderColor">Help In Planning</h1>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <p>Making all the necessary arrangements, providing needed information and notifications, even simply preparing logistics for where to go, what to bring, what to organize for the patient and family can get confusing and stressful. We’ve compiled some key guidance and information to help you plan for success.</p>
                                </div>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation bordersOff">
                                <div className="w-100">
                                    <div className="type3AcrossContainer boxes">
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#preoperativeTestsDiscussion">PREOPERATIVE
                                                TESTS DISCUSSION</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#dayBeforeSurgeryInstructions">DAY
                                                BEFORE SURGERY INSTRUCTIONS</Link>
                                            </p>
                                        </div>
                                        <div className="typeContainer text-center">
                                            <p>
                                                <Link to="#lodgingOptions">LODGING
                                                OPTIONS</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps">KEY AREAS OF ADVANCE PLANNING:</h2>
                                </div>
                            </div>
                            <div className="col-12 coloredContentBox">
                                <div className="w-100">
                                    <div className="roundedColorBackgroundBoxPriority paleGreenBackground defaultBorder text-center">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom checkBoxBulletsSafeCare colorAccentBlackBullets">
                                                    <li className="text-start">Preoperative tests</li>
                                                    <li className="text-start">Having key patient information
                                                        ready to provide when
                                                        asked
                                                    </li>
                                                    <li className="text-start">Making sure to follow pre-surgery
                                                        instructions (e.g., fitness, incentive spirometer use)<br />
                                                    </li>
                                                    <li className="text-start">Following the "day before
                                                        surgery" guidelines provided;
                                                        having checklists ready to make sure you don’t forget
                                                        anything
                                                    </li>
                                                    <li className="text-start">Arranging for any needed family
                                                        lodging (and knowing
                                                        what services we offer that can assist you in lodging,
                                                        transportation and other services)
                                                    </li>
                                                    <li className="text-start">Knowing when and where to go,
                                                        park, stay during and
                                                        after the procedure
                                                    </li>
                                                    <li className="text-start">Getting all needed information
                                                        you’ll be using after the
                                                        surgery and once you return home (contact information,
                                                        instructions, education, plans)
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>
                                        BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps" id="preoperativeTestsDiscussion">YOUR PREOPERATIVE VISIT WITH THE ANESTHESIA TEAM</h2>
                                    <p>You will visit the anesthesiology team before surgery for tests that will likely include blood work, EKG, chest X-ray and answering questions about your medical and surgery history. You will also be asked to bring with you a list of key information listed below. This visit may be the same day as your clinic visit or you may come back on a different day. This visit will not require you to be without food or drink. <span className="
                                        bold">Please review the <a href="https://anesthesiology.wustl.edu/patient-care/perioperative-medicine/center-for-preoperative-assessment-and-planning/" target="_blank">Center for Preoperative Assessment (CPAP) website</a> for more information on this important preoperative visit, including directions on how to get to our 3 locations.</span></p>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>Make sure to let the anesthesiologists know about any allergies or adverse reactions to any medications in the past as well as any difficulties with anesthesia in the past.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>You may be asked to have additional testing such as stress test, echocardiogram, ultrasound, pulmonary function testing, or catheterization.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>This is a good time to discuss postoperative pain including narcotic medications, adjunctive therapies, etc.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>If you have a cardiologist or pulmonologist it is helpful to see him/her prior and let them know you are being scheduled for surgery and require clearance.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>You may be asked to stop, change, or start certain medications before surgery. It is important that you share these recommendations with your surgeon and your primary care physician so they know about these changes as well.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadUpperandLower">Please bring the following to your
                                        visit:</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom checkBulletsSafeCare limeGreenBullets">
                                        <li>It is important that you bring your medications or a Home Medication Record (with dose and how often you take them). Include all over-the-counter items – vitamins, herbs, aspirin, inhalers, etc.
                                        </li>
                                        <li>Any advanced testing (e.g. stress test or echocardiogram) results or the testing site’s phone numbers.
                                        </li>
                                        <li>Your implant/information card for devices such as a pacemaker, defibrillator, or blood vessel stent.
                                        </li>
                                        <li>If you have recently seen your cardiologist, ask him/her for a letter regarding your heart history, any implanted heart devices, and your most recent test results.
                                        </li>
                                        <li>Your Primary Care Physician's office phone &amp; fax numbers.</li>
                                        <li>The name and/or phone number of your laboratory, MD office or hospital where any recent blood testing, EKG or chest x-rays have been done. If you have a copy of your own, please bring.
                                        </li>
                                        <li>Your Advanced Directive or Living Will.</li>
                                        <li>If applicable, the paperwork showing Power of Attorney, guardianship or adoption for the patient. This is necessary for us to render care.
                                        </li>
                                        <li>All current insurance cards.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>
                                        BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps" id="dayBeforeSurgeryInstructions">ON THE DAY
                                        BEFORE SURGERY</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>Be sure that you understand and follow your Checklists leading up to surgery. You can find details at <Link to="/siteman-ent-tgsp-3-6" target="_blank">Checklists</Link>.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>If you have any questions or concerns about what you need to do prior to your surgery and the day of surgery, reach out to your doctor’s office.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100 subheadUpperAndLowerColorBarIconFlexContainer marginBottom12">
                                    <h3 className="subheadUpperAndLowerColorBar limeGreenBackground centered">UNDERSTAND THE CURRENT COVID-19 POLICIES AND GUIDELINES THAT MAY AFFECT YOUR VISIT.</h3>
                                </div>
                            </div>
                             <div className="col-12">
                                <div className="w-100">
                                    <p className="centered">Get the latest details at <a href="https://www.barnesjewish.org/Patients-Visitors/Visiting-Hours" target="_blank">www.barnesjewish.org/Patients-Visitors/Visiting-Hours</a></p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <h2 className="subheadAllCaps" id="lodgingOptions">ARRANGING FOR
                                        LODGING</h2>
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>You have many options when locating affordable, convenient lodging. Barnes-Jewish offers help with questions regarding lodging options by calling our concierge services at  <a href="tel:3143625196">314-362-5196</a> or emailing <a href="mailto:conciergeservices@bjc.org">conciergeservices@bjc.org</a>.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <hr className="w-100" />
                                </div>
                            </div>
                            <div className="col-12 subheadAndBullets">
                                <div className="w-100">
                                    <ul className="bulletsCustom limeGreenBullets">
                                        <li>Details on lodging options can be found at <a href="https://www.barnesjewish.org/patients-visitors/lodging" target="_blank">barnesjewish.org/patients-visitors/lodging</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 backToTop">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>
                                            BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default SitemanENTAll3_7;
