import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL92Component({ code, language }) {
    code = `{/* L92 Bold Lead In Title, Name, Job Title, phone number and URL */}
                                    <div className="lego l092">
                                        {/* L10A Bold Lead In */}
                                        <div className="lego l00paragraph">
                                            <p className="boldLeadInNewUI">SPR Therapeutics Representative
                                            </p>
                                        </div>
                                        {/* End L10A Bold Lead In */}

                                        {/* L14 Paragraph */}
                                        <div className="lego l00paragraph">
                                            <p><span className="bold italic">Kimberlee Griffey</span><br/>
                                                Regional Sales Manager<br/>
                                                <a href="tel:636-667-7493">636-667-7493</a><br/>
                                                <a className="consistentLinkColor" href="https://www.SprintPNS.com" rel="noreferrer noopener" target="_blank">www.SprintPNS.com</a>
                                            </p>
                                        </div>
                                        {/* End L14 Paragraph */}
                                    </div>
                                    {/* L92 Bold Lead In Title, Name, Job Title, phone number and URL */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL92Component;