import React from "react";

function L6ComponentCode() {

    return (

        <div className="lego l006 legoMargin3">
            <div className="backgroundImage" style={{
                backgroundImage: "url(" + "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/93a16df86d1db0ea41026dd5db201ab8.png" + ")"
            }}>
                <div className="transparentBarTypeContainer" style={{
                    backgroundColor: "#0181E2" }}>&nbsp;&nbsp;</div>
                <h2 className="l6SubheadWithBackground">About Us</h2>
            </div>
        </div>

    )
};


export default L6ComponentCode;