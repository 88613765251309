import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

//import {useNavigate, useParams} from "react-router-dom";

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import '@fortawesome/fontawesome-svg-core/styles.css'
import {HashLink as Link} from "react-router-hash-link";
//const navigate = useNavigate();
const ContentPancreatic11 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Therapy Basics For Your Hospital Stay'}, () => {
            console.log('Therapy Basics For Your Hospital Stay');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader">Activities</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">KEY ACTIVITIES</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Turning and Moving in Bed.</li>
                                                <li>Bed mobility is a very important aspect of moving after surgery.
                                                </li>
                                                <li>Focus on using muscles like your arms and legs (rather than your
                                                    abdominal muscles).
                                                </li>
                                                <li>"Log Rolling” is a great activity to practice. You will be shown how to do this during your hospital stay.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>If you are unable to move immediately after surgery for various reasons
                                                it is important that you allow your nurse to rotate you in the bed from
                                                side to side every few hours to prevent the development of skin
                                                breakdown and pressure ulcers.</p>
                                            <p>Additionally, when in bed it is important to wear your sequential
                                                compression devices (SCDs) on your calves, to decrease the occurrence of
                                                blood clots.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Using Your Incentive Spirometer (IS) and Controlled Coughing.</p>
                                            <p>Incentive Spirometry is the number 1 way to reduce the occurrence of
                                                pneumonia and wean off of supplemental oxygen. After abdominal surgery,
                                                patients do not take deep breaths, so the lower lobes of the lungs are
                                                not utilized. If not being used, the air sacks in those lobes called
                                                alveoli fill with fluid. That is how pneumonia starts. When you use your
                                                Incentive Spirometer often and correctly, it forces you to utilize those
                                                lower lobes, forcing air into those portions of your lungs, improving
                                                your chance of avoiding pneumonia. Set a goal of using your IS 10x per hour. Make it a consistent habit. If you're watching a television show with commercials, make it a habit of using your IS during the commercial breaks. </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            {/*<p className="boldLeadIn">Controlled Coughing.</p>*/}
                                            <p>Using your IS makes you cough, which is a good thing. Coughing will help
                                                you get the phlegm that developed in your lungs while you were under
                                                anesthesia up and out. This coughing, while it is productive and good,
                                                can be very painful, which keeps patients from doing it. “Controlled
                                                Coughing” is a way to ease this pain. Practice it as shown in <Link to="/Pancreatic_Video-Incentive-Spirometry">
                                                    this video</Link>.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">MOVING AROUND IN YOUR ROOM AND NEARBY</h2>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Movement is vital to improving your progress. Starting the day after your
                                                surgery, you should work to increase your movement every day, in-between
                                                sessions with your Incentive Spirometer.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAndCopyWithIcon">
                                                <i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp;PLEASE CALL YOUR NURSE FOR
                                                ASSISTANCE WHEN GETTING OUT OF BED! WE WANT TO ENSURE THAT YOU DO NOT
                                                FALL.</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You are at risk for falling (and becoming injured) because of anesthesia,
                                                medications, weakness and equipment you received.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li><strong>Use your call light whenever you need to get up.</strong>
                                                </li>
                                                <li><strong>Be patient and wait for someone to help you.</strong></li>
                                                <li><strong>We have the time and are happy to help you!</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Day 1</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You may still be a bit sleepy from the anesthesia (which takes a full 24
                                                hours to leave your body) but you should still be spending some time
                                                sitting up, preferably in the recliner in your room. The longer you can
                                                sit up the better; it will prepare you best for walking tomorrow.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h3 className="subheadUpperAndLowerColorBar widthAuto">Day 2 and forward:</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Today is the day you venture out of your room and visit us all at the
                                                nurse’s station! Day 2 you should do a minimum of 3 laps around the
                                                unit. You should increase the number of laps and length of the laps each
                                                time you go out to walk.</p>
                                            <p>Remember the goal here is to go home, and the staff won’t be there with
                                                you to help. <strong>So, the more you can do when we are with you, the
                                                    better!</strong> You should also be continuing your IS and
                                                controlled coughing exercises as well.</p>
                                            <p><strong>During daylight hours, you should not be in your bed. When you
                                                are not walking you should be sitting in the recliner or sofa in your
                                                room and only be in bed at night time.</strong></p>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps">PHYSICAL THERAPY</h2>
                                            <p>Physical and Occupational therapy services will come to see you early on
                                                in your stay with us.</p>
                                            <p>They will teach you safe bed mobility, and safe walking techniques. They
                                                will ask you questions about your home and your life prior to
                                                surgery.</p>
                                            <p>All of these things will help them determine if you need more help from
                                                them during your stay and what type of care you will need at home when
                                                you get discharged.</p>
                                            <p>Here’s a look at what you expect from therapy</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100">
                                                THERAPY BASICS FOR YOUR HOSPITAL STAY
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer originalPadding">
                                                    <YouTube videoId="XziT-L4BRVg" opts={opts}  onPlay={playVideo}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">HYGIENE IN THE HOSPITAL</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">For people visiting you in your room:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>Do not allow people who are sick to visit you in the hospital.</li>
                                                <li>Ask that all visitors practice good hand hygiene when visiting you
                                                    in the hospital (washing/cleaning their hands before coming into the
                                                    room and when leaving). Hand sanitizer is available at stations
                                                    throughout the floor.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/venti.jpg"
                                                 className="img-responsive"/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Bathing/ showering:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>You may shower 48 hours after surgery once the physician has removed
                                                    the OR dressing.
                                                </li>
                                                <li>It is important to bathe or take a shower each day you are in the
                                                    hospital to reduce your chances of getting an infection.
                                                </li>
                                                <li>Your nurse will provide you with CHG (chlorhexidine gluconate) soap
                                                    to bathe with - use the entire bottle each time!
                                                </li>
                                                <li>If you have an epidural, you will not be able to get in the shower;
                                                    however, your nurse can help set you up with a bed bath instead.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="boldLeadIn">Brushing Your Teeth:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li>It is important to continue brushing your teeth each morning and
                                                    evening while you are in the hospital.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop careOrbitBlueColor" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                                                BACK TO TOP&nbsp;<i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default ContentPancreatic11;
