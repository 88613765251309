import {getRequest, postRequest} from "../api/index.js";
import {REGISTER, REGISTER_ERROR} from "./types.js";

export const rolesList = async (data, cb) => async (
    dispatch
) => {
    try {
        const res = await getRequest(`/user/roles/${data.organizationId}`, "user", data);

        cb(res.data);
    } catch (err) {
        if (err.response) {
            // There is an error response from the server
            // You can anticipate error.response.data here
            if (err.response.data && err.response.data.errors[0]) {
                dispatch({
                    type: REGISTER_ERROR,
                    payload: err.response.data.errors[0]
                });
            } else {
                const error = err.response.data;
                dispatch({
                    type: REGISTER_ERROR,
                    payload: err.message
                });
            }
        } else if (err.request) {
            // The request was made but no response was received
            // Error details are stored in error.request
            dispatch({
                type: REGISTER_ERROR,
                payload: err.request
            });
        } else if (err) {
            dispatch({
                type: REGISTER_ERROR,
                payload: err.message
            });
        } else {
            // Some other errors
            dispatch({
                type: REGISTER_ERROR,
                payload: "Something went wrong."
            });
        }
    }
};
