import React from 'react';
import Container from 'react-bootstrap/Container';
import { Nav } from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import { HashLink as Link } from "react-router-hash-link";
const LessonInhalersWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader orangeImgBackground withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonInhalers">Inhalers</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need to Know</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInhalers/WhatYouNeedToKnow#uses">
                                        <p className="whiteColor">Uses of an Inhaler
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonInhalers/WhatYouNeedToKnow#effects">
                                        <p className="whiteColor">Effects and Side Effects
                                            <i className="fa fa-chevron-right">&nbsp;</i>
                                        </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="uses">USES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Reasons for Using an Inhaler</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are a number of lung problems that require a person to use an inhaler. Some of the benefits to using an inhaler, compared with medicines you take by mouth, include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>An inhaled drug reaches the affected part of the lung more quickly.</li>
                                            <li>There are fewer side effects on other parts of the body.</li>
                                            <li>Relief of any sudden breathing problem is rapid.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>An understanding of how a person’s disease affects breathing will help in your understanding how inhaled medicines work.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeColorBar">Asthma</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <img className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/INH_1.png" alt="" />
                                        <p className="floatNone clearNone col-6"><span className="bold">Asthma</span> is a chronic (long-term) inflammation of a person’s airways. It is common in children and adults. Typically a person will have a sudden asthma attack when he or she breathes in substances (allergens) they are allergic to (such as pollens, dust, and animal dander). The airways are hyper-reactive or overly sensitive to allergens, so attacks can occur often. An asthma reaction can be triggered by polluted air, smoke, cold air, vigorous exercise or emotional stress. When an attack occurs, the bronchi (large airways) constrict or narrow. An attack causes inflammation of the airways and mucus buildup, making it hard to breathe. With proper treatment, asthma can be controlled.</p>
                                        <p className="boldLeadIn">Signs of an asthma attack include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Wheezing</li>
                                            <li>Difficulty breathing</li>
                                            <li>Coughing</li>
                                            <li>Chest pain or pressure</li>
                                            <li>Feeling of anxiety</li>
                                            <li>Thick mucus secretions from the lungs</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeColorBar">Emphysema</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <img className="floatLeft wrapImage col-6 clearLeft maxWidth300 marginLeft20px" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/INH_2.png" alt="" />
                                        <p className="floatNone clearNone col-6"><span className="bold">Emphysema</span> is an abnormal, permanent enlargement of air spaces within the lungs. These spaces are where the lung normally takes in oxygen and removes carbon dioxide when a person breathes in and out. This disease results in the lungs being less elastic and less able to exchange oxygen. Air gets trapped and the person uses more energy to breathe. The most common cause of emphysema is cigarette smoking. Living or working around toxic fumes, pollution, and second hand smoke can also put a person at risk . Persons with emphysema may also have asthma. Often a person with emphysema</p>
                                        <p className="boldLeadIn">Signs of emphysema include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Difficulty breathing during exercise or at rest</li>
                                            <li>Coughing (usually a dry cough)</li>
                                            <li>Difficulty breathing while lying flat</li>
                                            <li>Use of muscles in the shoulders and upper chest to breathe in and out</li>
                                            <li>An enlarged or barrel chest</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon lightOrangeColorBar">Chronic Bronchitis</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p><span className="bold">Chronic Bronchitis</span> is an abnormal, permanent enlargement of air spaces within the lungs. These spaces are where the lung normally takes in oxygen and removes carbon dioxide when a person breathes in and out. This disease results in the lungs being less elastic and less able to exchange oxygen. Air gets trapped and the person uses more energy to breathe. The most common cause of emphysema is cigarette smoking. Living or working around toxic fumes, pollution, and second hand smoke can also put a person at risk . Persons with emphysema may also have asthma. Often a person with emphysema</p>
                                        <p className="boldLeadIn">Signs of bronchitis include:</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>A productive cough (coughs up mucus). Often, you can hear the mucus “rattling” in the lungs</li>
                                            <li>The mucus is usually thick and yellowish green</li>
                                            <li>Wheezing</li>
                                            <li>Over time, the person develops difficulty breathing during exercise</li>
                                            <li>Heart failure is a common complication</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 backToTop">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        <hr className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="effects">EFFECTS &amp; SIDE EFFECTS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Watch for Expected Effects of Medicines</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>People take medicines to create an expected response. A bronchodilator enlarges a person’s breathing passages making it easier to breathe in and out. A mucolytic thins the mucus in the lungs making the mucus watery and easier to cough up.</p>
                                        <p>When you know the expected effect of a medicine you can judge how a person responds to that medicine. This is important. For example, if a medicine is for a person’s breathing, you will watch the person take the medicine and later ask if he or she feels like it is easier to breathe. You will also watch how they breathe. If a bronchodilator is effective, the person will breathe more slowly and use fewer muscles to breathe. Knowing what to expect makes you a better judge of whether a medicine is working.</p>
                                        <p>For more about giving medicines, see the lesson <a href="/LessonGivingMedicines" target="_blank">Giving Medicines Safely.</a></p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>Side Effects</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Inhaled medicines absorb rapidly through millions of tiny blood vessels in the lungs. In addition to widening airways and thinning mucus, the medicines can also cause side effects such as heart palpitations, tremors, and a rapid heart rate. Thus, as a caregiver you need to be able to recognize these side effects and know when to contact a home health nurse or doctor.</p>
                                        <p>Steroids are a common type of inhaled medicine. These drugs can change the normal bacteria that live in a person’s mouth, leading to a fungal infection.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder orangeBorder">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Signs of a fungal infection of the mouth include:</h2>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom orangeBullets">
                                            <li>Thick white or cream-colored patches on the tongue or lining of the mouth.</li>
                                            <li>The area of the mouth is reddened and slightly swollen.</li>
                                            <li>The mouth is tender.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer orangeBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonInhalersWhatYouNeedToKnow;