import React from "react";

function L87ComponentCode() {

    return (

    <div className="lego l087 legoMargin5">
        <h4 className="legoMargin2">Dianogstic Injection</h4>
        <table className="table table-bordered legoTableStyle2">
            <thead>
            <tr>
                <th>&nbsp;</th>
                <th>NOT Improved</th>
                <th>Improved a Little</th>
                <th>Improved a Little More</th>
                <th>Improved a LOT</th>
                <th>NO PAIN</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Immediately</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            <tr>
                <td>2 hours AFTER</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            <tr>
                <td>4 hours AFTER</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            <tr>
                <td>6 hours AFTER</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            <tr>
                <td>12 hours AFTER</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            <tr>
                <td>24 hours AFTER</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            <tr>
                <td>36 hours AFTER</td>
                <td>0%</td>
                <td>25%</td>
                <td>50%</td>
                <td>75%</td>
                <td>100%</td>
            </tr>
            </tbody>

        </table>
    </div>

    )
};


export default L87ComponentCode;