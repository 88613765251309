import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
const LessonPulseWhatToDoAndSafety = () => {
const opts = {
height: '390',
width: '640',
playerVars: {
// https://developers.google.com/youtube/player_parameters
autoplay: 0,
},
};
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonPulse">Taking a Pulse</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonPulse/WhatToDoAndSafety#whatToDo">
                                        <p className="whiteColor">What to Do <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonPulse/WhatToDoAndSafety#safetytips">
                                        <p className="whiteColor">Safety Tips <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonPulse/WhatToDoAndSafety#showMeHow">
                                        <p className="whiteColor">Show Me How <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center fiveAcross" to="/LessonPulse/WhatToDoAndSafety#equipment">
                                        <p className="whiteColor">Equipment <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="whatToDo">WHAT TO DO</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>There are abnormal conditions that affect a person’s heart rate. Know these conditions so that you can be alert for them when you take a person’s pulse.</p>
                                    </div>
                                </div>
                                <div className="col-12 subheadAndBullets">
                                    <div className="w-100">
                                        <ul className="bulletsCustom">
                                            <li>When a person is not getting enough oxygen to breathe, the heart rate rises. People with asthma and chronic lung disease such as bronchitis and emphysema normally have a faster heart rate.</li>
                                            <li>Persons who are having a heart attack or a disturbance in the rhythm of heartbeats, have a dysrhythmia [disrith´mē ə]. You will feel the pulse as weaker, irregular, and it may have a faster or slower rate than normal. <span className="bold">When a new dysrhythmia develops, and the person is not in distress, call the doctor</span>.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Some people have dysrhythmias that are chronic and you can feel all the time. Atrial flutter, atrial fibrillation, and mitral valve prolapse are examples of such conditions. In these cases, know what the person’s dysrhythmia feels like. If you note a change, call the doctor.</p>
                                        <p>Remember: An abnormally slow, rapid, weaker, or irregular pulse means the heart has become a less effective pump and circulation of blood to the parts of the body will be affected.</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i className="fa fa-exclamation-circle">&nbsp;</i>If the person is in distress (such as having chest pain) and you feel an irregular pulse call 911.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="safetytips">SAFETY TIPS</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom">
                                                <li><span className="bold italic">Be Accurate</span>. The most important safety tip for taking a pulse is being sure you have counted the right pulse rate. If you feel unsure or count a pulse that is too slow, too fast, irregular and hard to count, take the pulse a second time.</li>
                                                <li>Try to take the resting pulse rate at the same time each day. If the pulse is very different (too slow, too fast, very irregular) from the person’s normal pulse, call the doctor.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <div className="navTopicButtonPageHref">
                                            <a id="showMeHow">SHOW ME HOW</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>The most important safety tip for taking a pulse is being sure you have counted the right pulse rate.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 video">
                                        <div className="w-100">
                                            <h3 className="blueColor videoTitle width100 text-center">
                                                Video: Taking A Pulse
                                            </h3>
                                            <div className="floatLeft width100 captionBelow">
                                                <div className="embedContainer">
                                                    <YouTube videoId="eNTC20Q6SnI" opts={opts} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="captionItalic marginBottom0">related content:</p>
                                            <p className="captionBold">Step-by-Step Instructions</p>
                                            <p><a href="" target="_blank">Open File<i className="fa fa-chevron-right">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="equipment">EQUIPMENT</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Wristwatch or wall clock with second hand or digital second count</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/TP_equipment.png" className="img-responsive floatNone maxWidth300" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonPulseWhatToDoAndSafety;