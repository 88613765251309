import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import {pageAccessLog} from "../../actions/pageAccessLog.js";

const Pritikin4_2 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center pritikinColorBrightGreen pritikinBorderBrightGreen">I'm
                                                Ready to Get Started</h1>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadAllCaps"><i
                                                className="fa fa-chevron-circle-right pritikinColorBrightGreen "></i>&nbsp;&nbsp;STARTING
                                                YOUR PRITIKIN CARDIAC REHAB PROGRAM IS EASY!</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>You have already been prescribed cardiac rehab by your physician as a
                                                vital part of your care plan after your heart event or procedure.</p>
                                            <p>So, you can make arrangements to begin your cardiac rehab journey by
                                                simply contacting your cardiac rehab team directly.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 twoSideBars doctorInfo noPadding">
                                        <div className="w-100 cardSideBySideFlex">
                                            <div className="col-6 minAndMaxHeightEqual paddingRight7">
                                                <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight pritikinBackgroundBrightGreen borderRadiusNone">
                                                    <h2 className="subheadUpperAndLower mb-0">Enroll now:</h2>
                                                </div>
                                                <div className="withBorder pritikinBorderBrightGreen minAndMaxHeightEqual borderRadiusNone borderThin">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <div className="doctorContactInfo">
                                                                <div className="medicalCenterName mb-4">
                                                                    <h2 className="subheadUpperAndLower phoneNumberLarge"><i
                                                                        className="fa fa-chevron-right pritikinColorOrange"></i>&nbsp;<a className="text-black" href="tel:3345554567">334-555-4567</a>
                                                                    </h2>
                                                                    <p><span className="bold">Office Hours</span></p>
                                                                    <p>8:30 am - 5:00 pm M - F</p>
                                                                    <p>8:30 am - 12:00 pm Sat</p>
                                                                    <p>Closed Sunday</p>
                                                                </div>
                                                                <div className="medicalCenterName mb-4">
                                                                    <p><span className="bold">For questions</span> about
                                                                        your enrollment, contact us at: <a
                                                                            href="tel:3345554568">334-555-4568</a>
                                                                    </p>
                                                                </div>
                                                                <div className="medicalCenterName mb-4">
                                                                    <p>Or online:</p>
                                                                    <p><a href="#"
                                                                          target="_blank">northlakemedcenter.com/ICR</a>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 minAndMaxHeightEqual paddingLeft7">
                                                <div className="sidbarFill withBodyCopy sidebarFillBackgroundColor noMinHeight pritikinBackgroundBrightGreen borderRadiusNone">
                                                    <h2 className="subheadUpperAndLower mb-0">Your cardiac rehab sessions will take place at:</h2>
                                                </div>
                                                <div className="withBorder pritikinBorderBrightGreen minAndMaxHeightEqual borderRadiusNone borderThin">
                                                    <div className="col-12">
                                                        <div className="w-100">
                                                            <div className="doctorContactInfo">
                                                                <div className="medicalCenterName mb-4">
                                                                    <p className="medicalCenterInfo"><span className="bold">Northlake Medical Center</span></p>
                                                                    <p>1234 Northland Parkway</p>
                                                                    <p>Metropolis, ST, 43210</p>
                                                                    <p><a href="#"
                                                                          target="_blank">https://northlakemedcenter.com/westtower</a>
                                                                    </p>
                                                                </div>

                                                                <div className="medicalCenterName mb-4">
                                                                    <p><span className="bold">For questions</span> about
                                                                        your enrollment, contact us at: <a
                                                                            href="tel:3345554568">334-555-4568</a>
                                                                    </p>
                                                                </div>
                                                                <div className="medicalCenterName mb-4">
                                                                    <p>Visit our <a href="/pritikin-6-0" target="_blank">Frequently
                                                                        Asked Questions</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperAndLower w-100 text-center">Congratulations on getting started on your road to lifelong better health!</h2>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP</a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};
export default Pritikin4_2;