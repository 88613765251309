import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";
import {videoAccessLog} from "../../../actions/videoAccessLog.js";
import {pageAccessLog} from "../../../actions/pageAccessLog.js";
import {useDispatch} from "react-redux";

const LessonGivingMedicinesWhatToDoAndSafety = () => {

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const dispatch = useDispatch();
    const playVideo = async (e) => {
        const pal = await videoAccessLog({videoName: 'Giving Medicines: Preparing medicines'}, () => {
            console.log('Giving Medicines: Preparing medicines');    
        });    
        await dispatch(pal);
    }

    const playVideo2 = async (e) => {
        const pal = await videoAccessLog({videoName: 'Giving Medicines: Giving medicine by mouth'}, () => {
            console.log('Giving Medicines: Giving medicine by mouth');    
        });    
        await dispatch(pal);
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader withNavigation">
                                        <div
                                            className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGivingMedicines">Giving
                                                            Medicines</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Do and Safety</h1>
                                    </div>
                                    <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                        <div className="w-100">
                                            <div className="type3AcrossContainer">
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGivingMedicines/WhatToDoAndSafety#whatToDo">
                                                    <p className="whiteColor">What to Do <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGivingMedicines/WhatToDoAndSafety#showMeHow">
                                                    <p className="whiteColor">Show Me How <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                                <Link className="typeContainer safeCareNav text-center fiveAcross"
                                                      to="/LessonGivingMedicines/WhatToDoAndSafety#safetytips">
                                                    <p className="whiteColor">Safety Tips <i
                                                        className="fa fa-chevron-right">&nbsp;</i></p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <div className="navTopicButtonPageHref">
                                                <a id="whatToDo">WHAT TO DO</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Take a Medicine at the specific
                                                    times prescribed</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li>Some medicines are ordered for only once a day, for example in
                                                        the morning or before you go to bed.
                                                    </li>
                                                    <li>The purpose of a medicine determines when to take it. For
                                                        example, diuretics (called water pills) are usually taken in the
                                                        morning to avoid waking up in the middle of the night to go to
                                                        the bathroom.
                                                    </li>
                                                    <li>The number of times a person needs to take their medicine is on
                                                        their prescription bottle. Follow this schedule. In many cases,
                                                        all doses of medicine can be taken during waking hours.
                                                    </li>
                                                    <li>When a doctor prescribes a medicine for every 6 or 12 hours,
                                                        keep the suggested amount of time spaced between doses
                                                        throughout a day. For example, take a medicine ordered for every
                                                        6 hours, at 6 am, Noon, 6 pm, and Midnight.
                                                    </li>
                                                    <li>Medicines ordered "as needed" are to be taken only if a person
                                                        is having a problem the medicine was prescribed to help control.
                                                        For example, pain medicine as medicine for nausea are often
                                                        ordered this way.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Allergic Reaction</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h3 className="subheadUpperAndLowerColorBarIcon safecareYellow"><i
                                                    className="fa fa-exclamation-circle"></i>When a person has a severe
                                                    allergic reaction, know what to do.</h3>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If the person has any of the problems listed below after taking a
                                                    medicine, <strong>Call 911 immediately</strong> or seek emergency
                                                    medical attention:</p>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom orangeBullets">
                                                    <li><strong><em>breathing difficulties</em></strong></li>
                                                    <li><strong><em>wheezing</em></strong></li>
                                                    <li><strong><em>difficulty swallowing after taking a
                                                        medicine</em></strong></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr/>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">Wear an Allergy Alert</h2>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <p>If the person you care for has an allergy, be sure he or she wears an
                                                    identification bracelet or medal. The person should never take any
                                                    medicine he or she is allergic to.</p>
                                                <p>If the person you care for develops a rash, call their doctor. This
                                                    may occur after taking a new medicine, or it can happen without
                                                    warning even if the person has been taking a medicine for a long
                                                    time.</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <hr/>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h2 className="subheadUpperandLower">Make it Easy When Taking
                                                        Medicines</h2>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">Trouble swallowing</p>
                                                    <p>If a person has difficulty swallowing a pill or capsule, ask the
                                                        doctor to order a liquid form instead. If the medicine does not
                                                        come as a liquid ask the doctor or pharmacist if you can crush
                                                        the medicine and place it in a small portion of the person’s
                                                        food (like applesauce or mashed potatoes).</p>
                                                </div>
                                            </div>
                                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Can I Crush Pills and mix in Food?</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom orangeBullets">
                                                            <li>Warning - Check with the pharmacist to be sure it is ok
                                                                to crush a pill or tablet. Some medicines should never
                                                                be crushed.
                                                            </li>
                                                        </ul>
                                                        <p><strong><em>Do not crush:</em></strong></p>
                                                        <ul className="bulletsCustom chevronRightBulletsSafeCare orangeBullets">
                                                            <li>Enteric coated medicines</li>
                                                            <li>Long-acting medicines</li>
                                                            <li>Slow-release medicines</li>
                                                            <li>Capsules</li>
                                                        </ul>
                                                        <ul className="bulletsCustom orangeBullets">
                                                            <li>Certain capsules should not be opened and mixed with
                                                                food because this will alter the action of the drug.
                                                            </li>
                                                            <li>Check with your pharmacist before opening any capsule to
                                                                be sure that the person receives the full benefit of the
                                                                medicine.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">When a Person Decides to Not Take a
                                                        Medicine </p>
                                                    <p>There are times when a person may refuse to take a medicine. Try
                                                        to find out the reason they refuse. Never force a person to take
                                                        a medicine. If the person continues to refuse a medicine for
                                                        more than one dose, call the doctor.</p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p className="boldLeadIn">When a Person Forgets to take
                                                        Medicines? </p>
                                                    <p>Older adults and those with conditions that affect memory may not
                                                        be able to remember when to take their medicines. Counting doses
                                                        taken over a period of time is one way to know if they are
                                                        taking their medicines correctly</p>
                                                    <p>For example, let’s say you want to check how well a person does
                                                        over a period of three days. If the person takes 4 different
                                                        medicines – determine how many times each medicine is to be
                                                        taken daily. Then for each medicine, multiply the total number
                                                        of daily doses by 3. Add the totals for the total number of
                                                        medicines to give over 3 days. Check each medicine bottle or
                                                        container after three days and determine if they have taken too
                                                        many, too few, or just the right number of medicines.</p>
                                                </div>
                                            </div>
                                            <div
                                                className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder orangeBorder">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower">Example:</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom orangeBullets">
                                                            <li>Medicine A is taken 1 time a day = 3 over 3 days</li>
                                                            <li>Medicine B is taken 3 times a day= 9 over 3 days</li>
                                                            <li>Medicine C is taken 2 times a day = 6 over 3 day</li>
                                                            <li>Medicine D is taken just before sleep = 3 over 3 days
                                                            </li>
                                                        </ul>
                                                        <p><strong><em>Total = 21 doses of medicines over 3
                                                            days</em></strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <p>Count the medicines in each pill container to be sure after 3
                                                        days that the correct number have been taken.</p>
                                                </div>
                                            </div>
                                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower">Things your Pharmacy Can Do
                                                            to Help You</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Provide easy to open container
                                                            lids</p>
                                                        <p>Weak grasp or pain in the hands or fingers make it hard to
                                                            open a medicine container. Have your local pharmacy place
                                                            medicines in a screw-top container instead of a child-proof
                                                            container.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Provide easier to read labels on
                                                            containers </p>
                                                        <p>Have the pharmacy make larger typed labels on containers when
                                                            a person has difficulty reading a label.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Advise about possible drug
                                                            interactions</p>
                                                        <p>Use the same pharmacy for all of your prescriptions. Then the
                                                            pharmacist will know if the person you are caring for is
                                                            taking certain medicines that should not be given together.
                                                            The pharmacist can contact your doctor when this occurs.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <p className="boldLeadIn">Split Pills</p>
                                                        <p>The pharmacist will know if it is ok to split a pill. If so,
                                                            ask the pharmacist to split all of the medicines in a
                                                            container for you.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 backToTop">
                                                    <div className="w-100">
                                                        <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i
                                                            className="fa fa-chevron-up"></i></a></p>
                                                        <hr className="width100"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="w-100">
                                                <div className="navTopicButtonPageHref">
                                                    <a id="safetytips">SAFETY TIPS</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <h2 className="subheadUpperandLower">Taking Medicines Safely </h2>
                                                </div>
                                            </div>
                                            <div className="col-12 subheadAndBullets">
                                                <div className="w-100">
                                                    <ul className="bulletsCustom orangeBullets">
                                                        <li>Never take another person’s medicine.</li>
                                                        <li>Make sure each of the doctors your family member visits
                                                            knows all of the medicines he or she is taking. Take a list
                                                            of medicines with you.
                                                        </li>
                                                        <li>Use one pharmacy for all your prescriptions. The pharmacist
                                                            will help you keep track of all the medicines the person you
                                                            care for is taking.
                                                        </li>
                                                        <li>Take all medicines for the full time prescribed.</li>
                                                        <li>Do not give a medicine past the expiration date or more than
                                                            a year old.
                                                        </li>
                                                        <li>Do not place different medicines in the same storage
                                                            container. Exception: you can mix medicines when you use a
                                                            weekly pill box
                                                        </li>
                                                        <li>Often persons take multiple medicines. Use a color coding
                                                            system to keep track of the time of day you need to take
                                                            which medicine.
                                                        </li>
                                                        <li>You can also use a permanent colored marking pen to label
                                                            the tops of containers for medicines that are to be taken at
                                                            the same time.
                                                        </li>
                                                        <li>Give all of a prescribed medicine. This is especially
                                                            important for antibiotics and blood pressure medicines. Do
                                                            not save for a future illness.
                                                        </li>
                                                        <li>Be sure all medicine containers are clearly labeled. If not,
                                                            take the container to the pharmacist for a new label. You
                                                            can also ask for larger print on the labels.
                                                        </li>
                                                        <li>Check to see if a medicine needs to be given alone or if it
                                                            can be given with other medicines and food. Certain foods,
                                                            drinks, and medicines cannot be taken together.
                                                        </li>
                                                        <li>Have the person you care for keep a current list of all
                                                            medicines and any medicine allergies on a wallet card. Have
                                                            them keep the list with them at all times
                                                        </li>
                                                        <li>Store medicines in a safe place, away from children. If
                                                            children live in or visit the home often, place the medicine
                                                            in a place out of their reach or in a locked box or cabinet.
                                                        </li>
                                                        <li>Use the kitchen to store medicines. Avoid the bathroom since
                                                            moisture can cause medicines to soften. Do not store
                                                            medicines on a windowsill, in direct sun light or an area of
                                                            extreme temperature.
                                                        </li>
                                                        <li>Keep liquids and injection medicines such as insulin or
                                                            heparin in a cool place for storage.
                                                        </li>
                                                        <li>Store medical supplies such as syringes or measurement
                                                            devices in an airtight container, such as a plastic storage
                                                            bin. Choose a cool place like your bedroom closet.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-12 backToTop">
                                                <div className="w-100">
                                                    <p><a className="specialUseBackToTop"
                                                          onClick="scrollToTop();return false">BACK TO TOP <i
                                                        className="fa fa-chevron-up"></i></a></p>
                                                    <hr className="width100"/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="navTopicButtonPageHref">
                                                        <a id="showMeHow">SHOW ME HOW</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 imgColLeftContentColRight">
                                                <div className="w-100">
                                                    <div className="videoFlexContainer">
                                                    <div
                                                        className="imgCol noPaddingLeft">
                                                        <div className="embedContainer">
                                                            <YouTube videoId="PS_o90Qoo24" opts={opts} onPlay={playVideo}/>
                                                        </div>
                                                        <p className="captionRegular videoCaptionBox width100 text-center">Preparing
                                                            medicines
                                                        </p>
                                                    </div>
                                                    <div className="imgCol noPaddingLeft">
                                                        <div className="embedContainer">
                                                            <YouTube videoId="1yxiiC72LW8" opts={opts} onPlay={playVideo2}/>
                                                        </div>
                                                        <p className="captionRegular videoCaptionBox width100 text-center">Giving
                                                            medicine by mouth
                                                        </p>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div
                                                        className="col-10 imgCol noPaddingLeft">
                                                        <p className="captionItalic marginBottom0">related content:</p>
                                                        <p className="captionBold">Step-by-Step instructions</p>
                                                        <p><a href="" target="_blank">Open
                                                            File<i className="fa fa-chevron-right"></i></a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <hr/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100">
                                                    <div className="navTopicButtonPageHref">
                                                        <a id="equipment">EQUIPMENT</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                <div className="col-12 text-center">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower">Preparing Medicines</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom checkBulletsSafeCare orangeBullets">
                                                            <li>Medicine bottles</li>
                                                            <li>Glass of water</li>
                                                            <li>Paper bathroom cup</li>
                                                            <li>Pill crusher (optional)</li>
                                                            <li>Kitchen Tablespoon or teaspoon</li>
                                                            <li>Weekly pill container</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                                <div className="col-12 text-center">
                                                    <div className="w-100">
                                                        <h2 className="subheadUpperandLower">Giving a Medicine</h2>
                                                    </div>
                                                </div>
                                                <div className="col-12 subheadAndBullets">
                                                    <div className="w-100">
                                                        <ul className="bulletsCustom checkBulletsSafeCare orangeBullets">
                                                            <li>Prepared medicines</li>
                                                            <li>Glass of water</li>
                                                            <li>Paper bathroom cup</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 backToTop footer blueBackground">
                                                <div className="w-100">
                                                    <p><a className="specialUseBackToTop"
                                                          onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK
                                                        TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGivingMedicinesWhatToDoAndSafety;
