import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonFootCareWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonFootCare">Foot Care</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Loss of Sensation in the Feet</p>
                                        <p>The loss of feeling or sensation in the hands and feet is called peripheral neuropathy. It is most often noticed as a numbness and pain in the hands and feet. People describe the pain as tingling or burning. Often a person compares the loss of sensation to the feeling of wearing a thin stocking or glove.</p>
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <div className="w-100">
                                        <h3 className="subheadUpperAndLowerColorBarIcon subhead26px">Peripheral neuropathy is due to nerve damage. Damage to the nerves can result from injuries, infections, and metabolic problems, the most common being diabetes mellitus.</h3>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The risk of having peripheral neuropathy is the inability to feel temperature changes or any injury to the skin. For that reason it is important to check the feet frequently for the presence of any minor cuts, bruises or ulcers. An area that goes unnoticed can quickly turn into an infection.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Circulation (Blood Flow) to the Feet</p>
                                        <p>When a person has poor blood flow to the feet, this increases the risk of foot problems. The cause of poor blood flow to the feet is a narrowing of the arteries, or peripheral artery disease. The blood vessels become very narrow, decreasing blood flow to the tissues, such as the skin and soft tissue of the feet. Also, the blood vessels are stiffer and cannot normally widen to allow greater blood flow when needed (such as during exercise).</p>
                                        <p>When a person has an injury such as a cut or blister, the area will not heal as quickly. Blood cannot deliver the nutrients and cells that promote healing. Thus if it is not treated, serious infections can form. <span className="bold italic">Severe tissue injury might even lead to toe, foot or leg amputation</span>.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <p className="boldLeadIn">Symptoms</p>
                                        <p>A person with poor blood flow to the feet will have pain, achiness, fatigue, burning, or discomfort in the muscles of the feet, calves, or thighs. The symptoms often start during exercise and go away after several minutes of rest. When peripheral artery disease is severe, the person might have hair loss over the toes and feet; thick toenails; shiny and tight skin; and painful, nonbleeding ulcers on the feet or toes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" href="#">BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonFootCareWhatYouNeedToKnow;