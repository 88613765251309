import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import Accordion from "react-bootstrap/Accordion";

const SitemanENTTGSP4_1_6 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft">
                                <section>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h1 className="tileTitleHeader centeredPageHeader text-center" style={{borderBottom: "0px", marginBottom: "0px"}}>This section only applies if you will have a tracheostomy tube or feeding tube as part of your surgery.</h1>
                                        </div>
                                    </div>
                                    <div className="col-12 questionAndAnswer">
                                        <div className="w-100">
                                            <Accordion className="moreLess">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header style={{marginLeft: "20px"}}>Click Here &nbsp; <i className="fa fa-chevron-right"></i></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="subheadAndBulletsWithFloat">
                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <h1 className="tileTitleHeader centeredPageHeader text-center">Devices &amp; Supplies</h1>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <h2 className="subheadUpperandLower text-center">Supplies if You Have Had a Tracheostomy</h2>
                                                                    <p>To help you with your tracheostomy care, you will be sent home with a variety of equipment and supplies for the first couple of days. Make sure you work with your home health company to replenish these supplies. Your home health company and case manager can help you identify where you can obtain the equipment you need.</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 backToTop">
                                                                <div className="w-100">
                                                                    <hr className="width100" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <h2 className="subheadUpperandLower text-center">Upon Discharge</h2>
                                                                    <p>You will be sent home with enough supplies to last a few days until the home health delivery.</p>
                                                                    <p><strong><em>These include:</em></strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom colorAccentRedBullets">
                                                                        <li>Suction Machine</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 imgColLeftContentColRight">
                                                                <div className="w-100">
                                                                    <div className="col-md-4 imgCol ">
                                                                        <img className="img-responsive" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/Suction_machine.png" alt="" />
                                                                    </div>
                                                                    <div className="col-md-8 typeCol noPaddingLeft">
                                                                        <p className="italic">A machine that uses suction (like a vacuum) to remove mucus. A suction machine will be delivered to your hospital room before you discharge. </p>
                                                                        <p className="italic">Make sure you and the person you have designated as your caregiver know how to use this machine! Make sure the machine is charged before leaving the hospital. </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom colorAccentRedBullets">
                                                                        <li>Humidified Oxygen<br/>
                                                                            <em>This is extremely important to use after your surgery to keep mucus plugs from forming in your trach tube that would block your breathing.</em></li>
                                                                        <li>Trach tube</li>
                                                                        <li>1 replacement trach tube</li>
                                                                        <li>Soft Velcro collar</li>
                                                                        <li>High humidity trach stand (delivered to home unless otherwise advised)</li>
                                                                        <li>2 Yankauer suction devices (used to remove mucus from the mouth)</li>
                                                                        <li>10 suction catheters (used to remove mucus from the trachea)</li>
                                                                        <li>9 disposable inner cannulas</li>
                                                                        <li>5 cleaning brushes for non-disposable inner cannulas</li>
                                                                        <li>1 emesis basin (to soak metal cannulas)</li>
                                                                        <li>Box of gloves</li>
                                                                        <li>14 saline bullets (to install in trach for mucus plugs)</li>
                                                                        <li>2 Velcro trach ties</li>
                                                                        <li>1 shower guard</li>
                                                                        <li>1 bottle of wound cleaner</li>
                                                                        <li>10 drain sponges/trach dressings</li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <p className="bold" style={{marginTop: "20px"}}>To supplement these provided supplies, you should also have handy:</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom colorAccentRedBullets">
                                                                        <li>Brushes/pipe cleaners</li>
                                                                        <li>Small basin or clean bowl</li>
                                                                        <li>Clean wash cloth</li>
                                                                        <li>Mild dishwashing liquid soap and tap water</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <hr className="w-100" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <h2 className="subheadUpperandLower text-center">Supplies if You Have a Feeding Tube</h2>
                                                                    <p>To help you with your tube feeding, you will be sent home with a variety of supplies for
                                                                       the first couple of days. Make sure you work with your home health company to replenish
                                                                       these supplies. Your home health company and case manager can help you identify where
                                                                       you can obtain the supplies you need.</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="w-100">
                                                                    <h2 className="subheadUpperandLower text-center">Upon Discharge</h2>
                                                                    <p>You will be sent home with enough supplies to last a few days until the home health delivery.</p>
                                                                    <p><strong><em>These include:</em></strong></p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 subheadAndBullets">
                                                                <div className="w-100">
                                                                    <ul className="bulletsCustom colorAccentRedBullets">
                                                                        <li>Tube feeding bags</li>
                                                                        <li>Tube feeding pump (if applicable)</li>
                                                                        <li>Tube feeding syringes</li>
                                                                        <li>Medication straw</li>
                                                                        <li>G-tube dressings (if applicable)</li>
                                                                        <li>Tube feeding formula</li>
                                                                        <li>Toothbrush</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 backToTop">
                                                                <div className="w-100">
                                                                    <p><a className="specialUseBackToTop"  href="#">BACK TO TOP <i className="fa fa-chevron-up"></i></a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>

    )
};

export default SitemanENTTGSP4_1_6;
