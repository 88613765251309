import React from 'react';
import { CodeBlock } from 'react-code-block';
import { useCopyToClipboard } from 'react-use';
import '@fortawesome/fontawesome-svg-core/styles.css';
import YouTube from "react-youtube";

function LegoL82Component({ code, language }) {
    code = `{/* L82? Content 2 sides 50% width */}
                                            <div className="lego l082">
                                                <div className="contentLeftSide">
                                                    <div className="lego l00paragraph legoMargin3">
                                                        <p>It may take 2-4 weeks for this procedure to provide you with
                                                            pain
                                                            relief. The nerve will regenerate, but this typically takes
                                                            at least
                                                            6 months.</p>
                                                    </div>
                                                </div>
                                                <div className="contentRightSide">
                                                    <div className="lego l00paragraph legoMargin3">
                                                        <p>The goals of the (RFA) are to:</p>
                                                    </div>
                                                    <div className="lego l053 legoMargin3">
                                                        <ul className="w-100">
                                                            <li className="w-100">Stop or reduce pain.</li>
                                                            <li className="w-100">Improve function.</li>
                                                            <li className="w-100">Reduce the number of pain medications
                                                                taken.
                                                            </li>
                                                            <li className="w-100">Avoid or delay surgery.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End L82? Content 2 sides 50% width */}`;
    language = 'jsx';
    const [state, copyToClipboard] = useCopyToClipboard();

    const copyCode = () => {
        // Logic to copy `code`
        copyToClipboard(code);
    };

    return (
        <CodeBlock code={code} language={language}>
            <div className="relative">
                <CodeBlock.Code className="bg-black">
                    <div className="table-row">
                        <CodeBlock.LineNumber className="table-cell pr-4 text-sm text-gray-500 text-right select-none" />
                        <CodeBlock.LineContent className="table-cell">
                            <CodeBlock.Token />
                        </CodeBlock.LineContent>
                    </div>
                </CodeBlock.Code>
                <div className="buttonContainer">
                    <button className="btn btn-primary ml-auto" onClick={copyCode}>
                        <i className="fa-solid fa-copy"></i> {state.value ? 'Copied!' :  'Copy code'}
                    </button>
                </div>
            </div>
        </CodeBlock>
    );
}

export default LegoL82Component;