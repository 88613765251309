import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonAfterSurgeryWhatYouNeedToKnow = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader greenImgBackground">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonAfterSurgery">Care After Surgery</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">What You Need To Know</h1>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Returning Home</h2>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder greenBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">After Outpatient Surgery:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>The person will feel drowsy and a bit weak. The effects of anesthesia may make the person feel a bit queasy or sick to their stomach. These feelings should pass by the next morning.</li>
                                                    <li>By the next day, most people are able to eat a normal diet and slowly begin to return to normal activity.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule listWithBorder greenBorder">
                                        <div className="col-12">
                                            <div className="w-100">
                                                <h2 className="subheadUpperandLower">After Major Surgery:</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom greenBullets">
                                                    <li>The first two weeks after discharge from the hospital can be difficult and the person may not feel like he or she has improved very much. He or she may still tire easily after walking or exercise.</li>
                                                    <li>It may take a few days before they have the desire to eat a full meal.</li>
                                                    <li>It is important for the person to rest frequently between activities. This will allow the body time to heal more easily.</li>
                                                    <li>In many cases, problems from surgery develop in the first two weeks after returning home, but they also can occur any time during the recovery period.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Common Medicines After Surgery</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>At the time of discharge from a hospital or outpatient surgery center, a person’s medicines may differ from what he or she was taking before surgery. All medicines must be taken on time and in the dose prescribed, until the prescriptions are stopped by the doctor.</p>
                                            <p>Ask the doctor if the person should resume any of the medicines the person was taking before surgery. Then ask to review any new medicines, their purpose and actions. Common medicines prescribed after surgery include:</p>
                                            <p><span className="bold">Antibiotics:</span> given to prevent a wound infection. Antibiotics are powerful medicines that fight bacterial infection.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Not taking antibiotics exactly as prescribed can lead to problems. For example, if a person takes an antibiotic for only a few days — instead of the total number of days prescribed — the antibiotic may kill some but not all of the bacteria. The surviving bacteria become more resistant and can be spread to other people. When bacteria become resistant to first line treatments, the risk of complications and death is increased.</li>
                                                <li>When the person you provide care for takes antibiotics, follow the doctor’s directions carefully. It is important to finish the medicine even if the person feels better. Do not save antibiotics for later or use someone else’s prescription.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Anticoagulants:</span> given if the surgery poses a risk for the formation of clots in the person’s legs. This is seen after orthopedic, heart, and vascular surgery.</p>
                                            <p><span className="bold">Analgesics:</span> given for severe to moderate pain. Oral opioid analgesics work well on severe pain, but there can be side effects, including drowsiness, nausea and constipation.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>The person will need to drink plenty of fluids and eat fruits and vegetables (if allowed) to prevent constipation. Many opioids are combined with acetaminophen (Tylenol®, Tempra®, Anacin AF®). So, be careful not to take other medicines that contain acetaminophen, such as over-the-counter pain relievers, cold medicines and sleep aids. (If the person you care for is elderly, many doctors will tell you to limit acetaminophen to 3 grams (3000mg) daily.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Stool softeners:</span> frequently given to help the person avoid constipation.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Constipation may occur for various reasons including reduced mobility, a reduced appetite and taking certain medicines (such as pain medicines). An increase in dietary fiber helps to prevent constipation.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>After discharge from a hospital or surgery clinic, the person’s primary doctor will decide which medicines he or she will need. The doctor may have to adjust the doses on routine medicines because of the effects of surgery. <span className="bold">If the person you care for thinks a medicine is bothering him or her and wants to stop it, call the doctor immediately.</span></p>
                                            <p>See our lesson on <a href="/LessonGivingMedicines" target="_blank">Giving Medicines Safely</a>, for more information.</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Pain</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>Even after minor surgery, a person will have some pain. Pain results from injury to the skin, muscles, and nerves during an operation. Pain is what the person says it is. So, do not judge whether you think it is severe or mild. Act on the basis of what the person tells you.</p>
                                            <p>Keeping pain under control will increase the person’s comfort and speed their recovery. With pain relief a person is able to move more easily and stay active. The doctor will prescribe strong pain-killers after the surgery. Be sure the person takes them as directed. Over time, the person will be given weaker pain medicines, with the goal of eventually weaning off of them completely.</p>
                                            <p>Remember, <span className="bold">pain is very real to a person.</span> Thus, when you provide care for a person in pain:</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Respect their feelings</li>
                                                <li>Accept their pain</li>
                                                <li>Do what you can to make them more comfortable</li>
                                                <li>Determine the level of pain they are having.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                                <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h2 className="subheadUpperandLower">Types of Postoperative Complications</h2>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 text-center">
                                            <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/CAS_02.png" className="img-responsive floatNone maxWidth300" />
                                            <p className="captionItalic text-center w-100">Wound Infection</p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p>After surgery the doctor will explain the complications to look for.</p>
                                            <p><span className="bold">Bleeding:</span> After surgery, there can be a loss of blood because of a broken suture (stitch) or because the person’s blood does not clot normally.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>The bleeding may be internal (inside the body, in the area of surgery) or external (seeping out through the incision). By the time a person goes home, bleeding risks are small.</li>
                                                <li>At times, a wound may bleed if the gauze dressing adheres or sticks to the tissue when a dressing is removed. However, if the gauze becomes saturated with blood – call the doctor.</li>
                                                <li>Ask the doctor or the nurse at the hospital how much bleeding should occur after the surgery and when to call and report bleeding. Call the doctor immediately if unexpected bleeding develops.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Wound Infection: </span> Is a common complication after surgery.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Germs enter the skin or tissue around an incision and cause an infection. Signs include redness of the skin around the stitches, swelling of the skin, drainage (either yellow or green), and fever. A fever is 100.5 degrees Fahrenheit (oral) or higher. If using a rectal thermometer, a fever is 101.5 degrees Fahrenheit or higher.</li>
                                                <li>Do not give an antipyretic medicine unless ordered by the doctor. Fever is the way the body responds to infection, and in some cases should not be treated. Report these signs immediately to the doctor.</li>
                                                <li>Over-the-counter medicines should not be used without a doctor’s instruction. These products usually do not work and will delay the start of other medicine if the doctor has not been told of the symptoms.</li>
                                                <li>The person may tell you that the wound feels sore or has an increase in tenderness when the area is touched.</li>
                                                <li>A wound infection may develop during the first week after surgery, but also could happen weeks after discharge.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Dehiscence(wound separation):</span> When the edges of the wound separate, this is often due to obesity, poor nutrition, poor blood flow to tissues, or strain on the stitches from coughing or lifting.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Dehiscence will lengthen the time it takes for a wound to heal. Signs include increased drainage and you can see tissue between the wound edges. <span className="bold">Call the surgeon immediately.</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Evisceration:</span> When the edges of the wound separate, this is often due to obesity, poor nutrition, poor blood flow to tissues, or strain on the stitches from coughing or lifting.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Although this occurs rarely, it is a medical emergency. Call the surgeon immediately.</li>
                                                <li>Take clean moistened gauze pads (if available) or washcloths and place them over the open wound until you get to the doctor’s office. (Ideally, use sterile saline, like that used for a wound irrigation. But if it’s not available, use warm water on the wash cloths.)</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Breathing problems:</span> Breathing problems after surgery are more common in people who smoke or who have some form of lung disease.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>Pneumonia, or an infection of the lung, can develop if mucoussettles in the lungs and is not removed during normal coughing. If a person is having trouble getting their breath, if they seem to be breathing faster, or if they begin to cough up thick, colored mucous, call their surgeon.</li>
                                                <li>Difficulty breathing may also cause a person’s lips, nailbeds and skin to turn a pale blue, or dusky color. If this occurs, <span className="bold">contact emergency services 911</span>.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p><span className="bold">Circulation problems:</span> If a person does not stay active or follow exercises ordered by their surgeon, circulation problems can develop. Blood flow slows when a person is inactive.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 subheadAndBullets">
                                        <div className="w-100">
                                            <ul className="bulletsCustom greenBullets">
                                                <li>In certain people, this creates the risk of blood clots forming in the legs. Blood clots are dangerous, because they can break off from a blood vessel and go to the lungs or heart.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 backToTop footer greenBackground">
                                    <div className="w-100">
                                        <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonAfterSurgeryWhatYouNeedToKnow;