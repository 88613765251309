import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
const LessonMouthCareFAQResources = () => {
return (
<div>
    <div className="w-100">
        <Container>
            <div className="w-100">
                <div className="col-10 m-auto">
                    <div className="whiteBackground noPadMarg floatLeft clearLeft">
                        <section>
                            <div className="col-12 safeCareLessonNameHeader withNavigation">
                                <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                    <div className="w-100">
                                        <div className="type3AcrossContainer">
                                            <div className="typeContainer text-center">
                                                <p>
                                                    <Nav.Link className="" href="/LessonMouthCare">Mouth Care</Nav.Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h1 className="tileTitleHeader centeredPageHeader">FAQ and Resources</h1>
                            </div>
                            <div className="col-12 logosAndType3Across internalNavigation safeCareNavigation">
                                <div className="w-100">
                                    <div className="type3AcrossContainer">
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonMouthCare/FAQResources#questions">
                                        <p className="whiteColor">Questions <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                        <Link className="typeContainer safeCareNav text-center twoAcross" to="/LessonMouthCare/FAQResources#resources">
                                        <p className="whiteColor">Resources <i className="fa fa-chevron-right">&nbsp;</i></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="questions">QUESTIONS</a>
                                    </div>
                                </div>
                            </div>
                            <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">What Kind of Person is at Risk of Choking?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>A person who drools liquids and chewed food or coughs during or after swallowing is at risk for choking. Or, if the person pockets food and saliva in the side of their mouth, or takes a long time to eat, he or she should be watched for choking during mouth care.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100 text-center">
                                        <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/MC_choking_risk.png" className="img-responsive maxWidth300 floatNone" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">If a person suddenly begins to cough and make choking noises during mouth care, what should I do?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Have the person spit out the secretions and sit up straight. If you have a gravy baster or large bulb syringe, remove secretions from the mouth. Use caution: Do not squeeze the baster and force fluid back into the mouth. See the <a href="/LessonMouthCare/WhatToDoAndSafety#showMeHow">Show Me How</a> section of this lesson. Or, if a person is lying on their side, keep them in that position with their head turned down. This aids breathing.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadAndCopyWithIcon text-center exclaim"><i className="fa fa-exclamation-circle hightLight redColor"></i>&nbsp; If choking continues, call 911 and then perform the Heimlich Maneuver</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>(See our lesson on <a href="/LessonFeedingAPerson" target="_blank">Feeding a Person</a>).</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How often should you replace a toothbrush?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Replace a toothbrush every 3 or 4 months, or after a person has been sick from a cold or sore throat. It is also good to replace a toothbrush when the bristles become worn or frayed.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">How often should I give mouth care?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>Give mouth care at least twice a day, when a person wakes up and just before they go to bed. However, if the person has a dry mouth or discomfort in the mouth, offer mouth care every three hours, or as the person chooses.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Can I hurt someone by giving mouth care?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>No, not if you follow the steps we suggest in the <a href="/LessonMouthCare/WhatToDoAndSafety#showMeHow">Show Me How</a> section of this lesson. If a person is at risk for choking, follow the steps we suggest that prevent spit and liquid from pooling in the back of the throat.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <h2 className="subheadUpperandLower">Who is at higher risk of getting oral cancer?</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <p>The American Cancer Society tells us that men are twice as likely to have oral cancer as women. Men over age 50 are at greatest risk. Cigarette, cigar and pipe smokers are 6 times more likely than non-smokers to develop oral cancer. Use of smokeless tobacco and the excess ingestion of alcohol also increase the risk for oral cancer.</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="w-100">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="w-100">
                                    <div className="navTopicButtonPageHref">
                                        <a id="resources">RESOURCES</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/ada-logo.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American Dental Association</h2>
                                                <p>The association offers a special website “Mouth Healthy” designed for consumers. There are numerous resources about oral health on this site.</p>
                                                <p className="resourceLink">visit: <a href="https://www.mouthhealthy.org/" target="_blank">www.mouthhealthy.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/adha_logo.jpg" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">American Dental Hygienists’ Association</h2>
                                                <p>This professional organization offers information on oral health.</p>
                                                <p className="resourceLink">visit: <a href="https://www.adha.org/" target="_blank">www.adha.org</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 twoSideBars">
                                <div className="w-100">
                                    <div className="col-12 col-12">
                                        <div className="w-100">
                                            <div className="sidebarNoFill noBorder marginRight minHeight1">
                                                <img className="img-responsive maxWidth140" src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/nci-logo-full.__v1.png" />
                                                <h2 className="subheadUpperAndLower withBodyCopy">National Cancer Institute</h2>
                                                <p>The website offers information on chemotherapy side effects and tips for dry mouth.</p>
                                                <p className="resourceLink">visit: <a href="https://www.cancer.gov/about-cancer?redirect=true" target="_blank">www.cancer.gov/cancertopics</a></p>
                                                <hr className="w-100 floatLeft clearLeft" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 backToTop footer blueBackground">
                                <div className="w-100">
                                    <p><a className="specialUseBackToTop" onClick={()=> window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Container>
    </div>
</div>
)
};
export default LessonMouthCareFAQResources;