import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const SitemanEntAll7_0 = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto sitemanEntCluster">
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-7-1">
                                        <div className="tile Height1RowTall width1Column t_009 sitemanBrightLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor">The Power of Help ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-7-2">
                                        <div className="tile Height1RowTall width2Column t_010 t_010_2x1 sitemanLightestBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <div className="textBorderTopBorderBottom">
                                                        <h3 className="text-center whiteColor">Advice from Patients
                                                            <br/>and Families</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInLeft animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-7-3">
                                        <div className="tile t_003 Height1RowTall width1Column sitemanDarkerLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0008_facility_alt_icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">In-System Services ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-tl-7-4">
                                        <div className="tile Height1RowTall width2Column t_004 t_004_2x1 sitemanBrightLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="centerCircle">
                                                    <img alt="" className="img-responsive img-circle"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0024_active-patient-icon.svg"/>
                                                </div>
                                                <div className="textBelowIcon">
                                                    <h3 className="text-center whiteColor">Communities ></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                            <div className="w-100 wow fadeInRight animated">
                                <div className="col-4 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-7-5">
                                        <div
                                            className="tile t_013 t_013_1x1 tileWithCenterBanner Height1RowTall width1Column sitemanEmotionalSupportBackgroundImg">
                                            <div className="contentCenter">
                                                <div className="centerTextTileBanner blueBackgroundColorWithOpacity">
                                                    <div className="centeringContainer">
                                                        <div className="text-center">
                                                            <h3 className="whiteColor text-center">Emotional Support
                                                                ></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                                <div className="col-8 paddingLR5 hvr-grow">
                                    <Nav.Link href="/siteman-ent-all-7-6">
                                        <div className="tile Height1RowTall width1Column t_010 t_010_2x1 sitemanDarkerLightBlueBackground">
                                            <div className="contentCenter">
                                                <div className="tileJustText">
                                                    <h3 className="text-center whiteColor sitemanT_25FontSize">Vendors</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default SitemanEntAll7_0;