import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";

const LessonGlucoseWhatToTellYourDoctor = () => {

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100 m-auto">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground noPadMarg floatLeft clearLeft">
                                <section>
                                    <div className="col-12 safeCareLessonNameHeader">
                                        <div className="col-12 logosAndType3Across internalNavigation safeCareLessonName">
                                            <div className="w-100">
                                                <div className="type3AcrossContainer">
                                                    <div className="typeContainer text-center">
                                                        <p><Nav.Link className="" href="/LessonGlucose">Measuring Blood Glucose</Nav.Link></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="tileTitleHeader centeredPageHeader">What to Tell Your Doctor</h1>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12 subheadAndBullets">
                                            <div className="w-100">
                                                <ul className="bulletsCustom">
                                                    <li>Ask the doctor if you should receive the Hepatitis B vaccination, to protect you in the case of an accidental lancet or needle stick.</li>
                                                    <li>Be sure the person takes their daily record or log of blood glucose readings with them to their doctor.</li>
                                                    <li>Let the doctor know how the person acts when their blood glucose is too low or high. For example do they become dizzy or lightheaded, confused?</li>
                                                    <li>Let the doctor know if the person has changed eating habits or exercise patterns.</li>
                                                    <li>If the person’s blood sugar rises above 200 mg/dl, (or above the range recommended by their doctor) call their doctor.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="l3SubheadUpperAndLowerBodyCopyAndRule">
                                        <div className="col-12">
                                            <div className="w-100 text-center">
                                                <img src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/caringboost/10159.jpg" className="img-responsive maxWidth300 floatNone" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 backToTop footer blueBackground">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO TOP <i className="fa fa-chevron-up">&nbsp;</i></a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default LessonGlucoseWhatToTellYourDoctor;
